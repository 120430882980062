import axios from "axios";
import { MyMaintenanceTaskInterface } from "module/maintenanceTask/types";
import { mapApiMaintenanceTaskToApp } from "module/maintenanceTask/helper/mapApiMaintenanceTaskToApp";

const getMaintenanceTaskDetails = async (
  maintenanceTaskId: number
): Promise<MyMaintenanceTaskInterface> => {
  const response = await axios.get(
    `/api/maintenance-tasks/${maintenanceTaskId}`
  );
  return mapApiMaintenanceTaskToApp(response.data);
};

export default getMaintenanceTaskDetails;
