import React, { useContext } from "react";
import AssessmentToBeSignedDetails from "pages/assessmentToBeSignedDetails/AssessmentToBeSignedDetails";
import AssessmentContext from "module/assessment/context/AssessmentContext";

const AssessmentToBeSignedDetailsContainer: React.FC = () => {
  const { entity } = useContext(AssessmentContext);

  return (
    <AssessmentToBeSignedDetails
      {...{
        assessmentId: entity.id,
      }}
    />
  );
};

export default React.memo(AssessmentToBeSignedDetailsContainer);
