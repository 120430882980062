import { useContext, useCallback } from "react";
import { toastError, toastSuccess } from "layout/toast/helper";
import t from "module/translations";
import { EntityIdentifier } from "module/entity/types";
import apiSign from "api/sign/sign";
import FullScreenLoaderContext from "module/fullScreenLoader/context/FullScreenLoaderContext";
import {
  displayValidationErrors,
  getValidEntityIdentifiers,
} from "module/validation/helper";

interface Result {
  signOne: (entityIdentifier: EntityIdentifier) => Promise<void>;
}

const useSign = (): Result => {
  const { turnOn: turnLoaderOn, turnOff: turnLoaderOff } = useContext(
    FullScreenLoaderContext
  );

  const signOne = useCallback(
    async (entityIdentifier: EntityIdentifier) => {
      turnLoaderOn();
      try {
        const entityIdentifiers = [entityIdentifier];
        const { validationErrors } = await apiSign(entityIdentifiers);
        const validEntityIdentifiers = getValidEntityIdentifiers(
          entityIdentifiers,
          validationErrors
        );

        if (validationErrors) {
          displayValidationErrors(validationErrors);
        }

        if (validEntityIdentifiers.length) {
          toastSuccess(t.sign.signSuccessText);
        }
      } catch (error) {
        toastError(t.api.generalError);
      }
      turnLoaderOff();
    },
    [turnLoaderOff, turnLoaderOn]
  );

  return {
    signOne,
  };
};

export default useSign;
