// eslint-disable-next-line max-classes-per-file
import t from "module/translations";
import {
  ValidatorInterface,
  ValidatorSerializedDataInterface,
  XValidatorInterface,
} from "module/validation/types";

export interface RangeValidatorProps {
  fieldName: string;
  value: number;
  min?: number;
  max?: number;
  message?: string;
}

export interface RangeValidatorSerializedInterface
  extends ValidatorSerializedDataInterface {
  fieldName: string;
  value: number;
  min?: number;
  max?: number;
  message?: string;
}

class RangeValidator implements ValidatorInterface {
  private readonly fieldName: string;

  private readonly value: number;

  private readonly min?: number;

  private readonly max?: number;

  static validatorIdentifier = "range";

  public message: string;

  constructor({ fieldName, value, min, max, message }: RangeValidatorProps) {
    this.fieldName = fieldName;
    this.value = value;
    this.min = min;
    this.max = max;
    this.message =
      message ??
      t.validation.fieldValueIsNotInRange({
        min: this.min,
        max: this.max,
      });
  }

  serialize(): RangeValidatorSerializedInterface {
    return {
      validatorIdentifier: RangeValidator.validatorIdentifier,
      fieldName: this.fieldName,
      value: this.value,
      min: this.min,
      max: this.max,
      message: this.message,
    };
  }
}

interface XRangeValidatorProps {
  min?: number;
  max?: number;
}

export class XRangeValidator implements XValidatorInterface {
  public message: string;

  constructor({ min, max }: XRangeValidatorProps) {
    this.message = t.validation.fieldValueIsNotInRange({
      min,
      max,
    });
  }
}

export default RangeValidator;
