import { PayloadAction } from "@reduxjs/toolkit";
import { StaffDataState } from "module/staff/redux/types";
import { MyEntityInterface } from "module/entity/types";
import { getEntityIdentifierKey } from "module/entity/helper/getEntityIdentifierKey";
import { createNestedPropertyIfNotExist } from "toolkit/createNestedPropertyIfNotExist";

interface CreateEntityActionTypeProps {
  staffId: number;
  systemShortName: string;
  myEntity: MyEntityInterface;
}

const createEntityAction = (
  state: StaffDataState,
  action: PayloadAction<CreateEntityActionTypeProps>
): void => {
  const { staffId, systemShortName, myEntity } = action.payload;

  const defaultValue = {
    entities: {},
    openEntityIdentifiers: [],
  };

  // eslint-disable-next-line no-param-reassign
  state = createNestedPropertyIfNotExist(
    state,
    `${staffId}.${systemShortName}`,
    defaultValue
  );

  state[staffId][systemShortName].entities[
    getEntityIdentifierKey(myEntity.entityIdentifier)
  ] = myEntity;
};

export default createEntityAction;
