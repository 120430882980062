import React, { useContext, useMemo } from "react";
import { EntityIdentifier } from "module/entity/types";
import { getEntityIdentifierKey } from "module/entity/helper/getEntityIdentifierKey";
import MaintenanceTaskContext from "module/maintenanceTask/context/MaintenanceTaskContext";
import { MaintenanceTaskInterface } from "module/maintenanceTask/types";
import EntityContextProvider from "module/entity/context/entity/EntityContextProvider";
import MaintenanceTaskPersonnelAssignmentExternalStaffContext from "module/maintenanceTaskPersonnelAssignment/externalStaff/context/MaintenanceTaskPersonnelAssignmentExternalStaffContext";
import { MaintenanceTaskPersonnelAssignmentExternalStaffInterface } from "module/maintenanceTaskPersonnelAssignment/externalStaff/types";

interface Props {
  entityIdentifier: EntityIdentifier;
  children: React.ReactNode;
}

const MaintenanceTaskPersonnelAssignmentExternalStaffContextProvider: React.FC<Props> =
  ({ entityIdentifier, children }) => {
    const { entity } = useContext(MaintenanceTaskContext);

    const maintenanceTask = entity as MaintenanceTaskInterface;

    const externalStaff = useMemo(
      () =>
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        maintenanceTask.relationships.get(entityIdentifier)
          .data as MaintenanceTaskPersonnelAssignmentExternalStaffInterface,
      [entityIdentifier, maintenanceTask.relationships]
    );
    return (
      <EntityContextProvider
        {...{
          entity: externalStaff,
          context: MaintenanceTaskPersonnelAssignmentExternalStaffContext,
          key: getEntityIdentifierKey(entityIdentifier),
        }}
      >
        {children}
      </EntityContextProvider>
    );
  };

export default MaintenanceTaskPersonnelAssignmentExternalStaffContextProvider;
