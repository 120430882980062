import { useMemo } from "react";
import { useAppSelector } from "redux/hooks";
import usePermissions from "module/permission/usePermissions";
import { isAssessmentStateScheduledOrLower } from "module/assessment/helper/isAssessmentStateScheduledOrLower";
import { AssessmentInterface, AssessmentState } from "module/assessment/types";
import selectOpenItemByEntityType from "module/staff/redux/selector/selectOpenItemByEntityType";
import { EntityType } from "module/entity/types";

interface Result {
  options: AssessmentState[];
  isEditable: boolean;
  isStateScheduledOrLower: boolean;
}

const useAssessmentState = (assessment: AssessmentInterface): Result => {
  const { canCompleteAssessment } = usePermissions();
  const openId = useAppSelector(
    selectOpenItemByEntityType(EntityType.assessment)
  )?.id;

  const isStateScheduledOrLower = isAssessmentStateScheduledOrLower(assessment);

  const options = useMemo(() => {
    const result = [];
    if (
      isStateScheduledOrLower ||
      (assessment.state === AssessmentState.completed && canCompleteAssessment)
    ) {
      result.push(AssessmentState.scheduled);
    }
    if (
      (assessment.state === AssessmentState.completed ||
        isStateScheduledOrLower) &&
      canCompleteAssessment
    ) {
      result.push(AssessmentState.completed);
    }
    return result;
  }, [assessment.state, canCompleteAssessment, isStateScheduledOrLower]);

  const isEditable = assessment.id === openId && options.length > 1;

  return {
    options,
    isEditable,
    isStateScheduledOrLower,
  };
};

export default useAssessmentState;
