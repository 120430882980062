import React from "react";
import SideMenuSection from "component/sidemenu/SideMenuSection";
import SideMenuItem from "component/sidemenu/SideMenuItem";
import t from "module/translations";
import routes from "routes/routes";
import { useAppSelector } from "redux/hooks";
import selectOpenItemByEntityType from "module/staff/redux/selector/selectOpenItemByEntityType";
import { EntityType } from "module/entity/types";
import InventoryIcon from "@mui/icons-material/Inventory";
import MenuIcon from "layout/menu/MenuIcon";

const InventoryCheckMenuSection: React.FC = () => {
  const openedInventoryCheckId = useAppSelector(
    selectOpenItemByEntityType(EntityType.inventoryCheck)
  )?.id;

  return (
    <SideMenuSection
      {...{
        name: t.sideMenu.inventoryCheckSection,
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        icon: <MenuIcon {...{ Icon: InventoryIcon }} />,
      }}
    >
      <SideMenuItem
        {...{
          title: routes.inventoryChecks.title || "",
          path: routes.inventoryChecks.path,
          key: routes.inventoryChecks.path,
        }}
      />
      <SideMenuItem
        {...{
          title: routes.myInventoryChecks.title || "",
          path: routes.myInventoryChecks.path,
          key: routes.myInventoryChecks.path,
        }}
      />
      {openedInventoryCheckId && (
        <SideMenuItem
          {...{
            title: routes.myOpenInventoryCheckDetails.title || "",
            path: routes.myOpenInventoryCheckDetails.path,
            key: routes.myOpenInventoryCheckDetails.path,
          }}
        />
      )}
      <SideMenuItem
        {...{
          title: routes.inventory.title || "",
          path: routes.inventory.path,
          key: routes.inventory.path,
        }}
      />
    </SideMenuSection>
  );
};

export default React.memo(InventoryCheckMenuSection);
