import React from "react";
import makeStyles from "@mui/styles/makeStyles";
import t from "module/translations";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import TableContainer from "@mui/material/TableContainer";
import StickyDiv from "component/sticky/StickyDiv";
import MyMaintenanceTaskDetailsPersonnelAssignmentsInternalStaffContainer from "pages/myMaintenanceTaskDetails/personnelAssignment/internalList/MyMaintenanceTaskDetailsPersonnelAssignmentsInternalStaffContainer";
import MyMaintenanceTaskDetailsPersonnelAssignmentsExternalStaffContainer from "pages/myMaintenanceTaskDetails/personnelAssignment/externalList/MyMaintenanceTaskDetailsPersonnelAssignmentsExternalStaffContainer";
import MyMaintenanceTaskDetailsAssistantContainer from "pages/myMaintenanceTaskDetails/personnelAssignment/assistant/MyMaintenanceTaskDetailsAssistantContainer";

const useStyles = makeStyles(() => ({
  paperContainer: {
    padding: "24px",
    marginBottom: "24px",
  },
  tableContainer: {
    marginBottom: "24px",
  },
}));

const MyMaintenanceTaskDetailsPersonnelAssignments: React.FC = () => {
  const classes = useStyles();

  return (
    <StickyDiv>
      <Typography variant="h5" component="div">
        {t.maintenanceTask.personnelAssignmentsText}
      </Typography>
      <Typography variant="h6" component="div">
        {t.maintenanceTask.personnelAssignments.type.internalText}
      </Typography>
      <TableContainer component={Paper} className={classes.tableContainer}>
        <MyMaintenanceTaskDetailsPersonnelAssignmentsInternalStaffContainer />
      </TableContainer>
      <Typography variant="h6" component="div">
        {t.maintenanceTask.personnelAssignments.type.externalText}
      </Typography>
      <TableContainer component={Paper} className={classes.tableContainer}>
        <MyMaintenanceTaskDetailsPersonnelAssignmentsExternalStaffContainer />
      </TableContainer>
      <Typography variant="h5" component="div">
        {t.maintenanceTask.personnelAssignmentsSecondSetOfEyesText}
      </Typography>
      <Typography variant="caption" component="div">
        {t.maintenanceTask.personnelAssignmentsSecondSetOfEyesSubtitleText}
      </Typography>
      <TableContainer component={Paper} className={classes.tableContainer}>
        <MyMaintenanceTaskDetailsAssistantContainer />
      </TableContainer>
    </StickyDiv>
  );
};

export default React.memo(MyMaintenanceTaskDetailsPersonnelAssignments);
