import React from "react";
import { getEntityIdentifierKey } from "module/entity/helper/getEntityIdentifierKey";
import MyEntityContextProvider from "module/entity/context/entity/MyEntityContextProvider";
import MaintenanceTaskProcessStepQuickFixContext from "module/maintenanceTaskProcessStepQuickFix/context/MaintenanceTaskProcessStepQuickFixContext";
import MyEntityValidationResultContextProvider from "module/entity/context/validationResult/MyEntityValidationResultContextProvider";
import MyMaintenanceTaskProcessStepQuickFixFieldMetaDataContextProvider from "module/maintenanceTaskProcessStepQuickFix/validation/fieldMetaData/MyMaintenanceTaskProcessStepQuickFixFieldMetaDataContextProvider";
import MyEntityChangeContextProvider from "module/entity/context/change/MyEntityChangeContextProvider";
import MaintenanceTaskProcessStepQuickFixChangeContext from "module/maintenanceTaskProcessStepQuickFix/change/MaintenanceTaskProcessStepQuickFixChangeContext";
import MaintenanceTaskProcessStepQuickFixValidationResultContext from "module/maintenanceTaskProcessStepQuickFix/validation/MaintenanceTaskProcessStepQuickFixValidationResultContext";
import { EntityIdentifier } from "module/entity/types";

interface Props {
  entityIdentifier: EntityIdentifier;
  children: React.ReactNode;
}

const MyMaintenanceTaskProcessStepQuickFixContextProvider: React.FC<Props> = ({
  entityIdentifier,
  children,
}) => {
  return (
    <MyEntityContextProvider
      {...{
        entityIdentifier,
        context: MaintenanceTaskProcessStepQuickFixContext,
        key: getEntityIdentifierKey(entityIdentifier),
      }}
    >
      <MyEntityValidationResultContextProvider
        {...{
          entityIdentifier,
          context: MaintenanceTaskProcessStepQuickFixValidationResultContext,
        }}
      >
        <MyMaintenanceTaskProcessStepQuickFixFieldMetaDataContextProvider>
          <MyEntityChangeContextProvider
            {...{
              entityIdentifier,
              context: MaintenanceTaskProcessStepQuickFixChangeContext,
            }}
          >
            {children}
          </MyEntityChangeContextProvider>
        </MyMaintenanceTaskProcessStepQuickFixFieldMetaDataContextProvider>
      </MyEntityValidationResultContextProvider>
    </MyEntityContextProvider>
  );
};

export default MyMaintenanceTaskProcessStepQuickFixContextProvider;
