import React, { useState, useCallback } from "react";
import CollapsedListContext, {
  CollapsedListContextInterface,
} from "module/collapsedList/context/CollapsedListContext";

interface Props {
  initialValue: boolean;
  children: React.ReactNode;
}

const CollapsedListContextProvider: React.FC<Props> = ({
  initialValue,
  children,
}) => {
  const [areAllExpanded, setAreAllExpanded] = useState<boolean>(initialValue);

  const toggleAll = useCallback(() => {
    setAreAllExpanded(!areAllExpanded);
  }, [areAllExpanded]);

  const contextValue: CollapsedListContextInterface = {
    areAllExpanded,
    toggleAll,
  };

  return (
    <CollapsedListContext.Provider value={contextValue}>
      {children}
    </CollapsedListContext.Provider>
  );
};

export default CollapsedListContextProvider;
