import { AssessmentInterface, AssessmentState } from "module/assessment/types";

export const canSignAssessment = ({
  assessment,
  loggedInStaffId,
}: {
  assessment: AssessmentInterface;
  loggedInStaffId: number;
}): boolean =>
  assessment.state === AssessmentState.completed &&
  (assessment.assigneeId === loggedInStaffId ||
    assessment.internalTrainerId === loggedInStaffId);
