import React, { useContext, useMemo, useCallback } from "react";
import NumberField from "component/numberField/NumberField";
import { useAppSelector } from "redux/hooks";
import { selectStockArticleById } from "module/auth/redux/authSlice";
import XLabelField from "component/labelField/XLabelField";
import MaintenanceTaskPartContext from "module/maintenanceTaskPart/context/MaintenanceTaskPartContext";
import MaintenanceTaskPartChangeContext from "module/maintenanceTaskPart/change/MaintenanceTaskPartChangeContext";
import MaintenanceTaskPartFieldMetaDataContext from "module/maintenanceTaskPart/validation/fieldMetaData/MaintenanceTaskPartFieldMetaDataContext";
import { MaintenanceTaskPartInterface } from "module/maintenanceTaskPart/types";
import { getFieldMetaDataWithErrorHandling } from "module/fieldMetaData/getFieldMetaData";
import { MAINTENANCE_TASK_PART_AMOUNT_FIELD_NAME } from "module/maintenanceTaskPart/constants";

const MaintenanceTaskPartAmountContainer: React.FC = () => {
  const { entity } = useContext(MaintenanceTaskPartContext);
  const { change: changeHelper } = useContext(MaintenanceTaskPartChangeContext);
  const { getFieldMetaData } = useContext(
    MaintenanceTaskPartFieldMetaDataContext
  );

  const part = entity as MaintenanceTaskPartInterface;

  const getStockArticle = useAppSelector(selectStockArticleById);

  const value = part.amount;
  const stockArticle = useMemo(
    () =>
      part.stockArticleId != null
        ? getStockArticle(part.stockArticleId)
        : undefined,
    [getStockArticle, part.stockArticleId]
  );
  const unit = stockArticle != null ? stockArticle.unit : undefined;

  const change = useCallback(
    (amount?: number): void => {
      changeHelper({ amount });
    },
    [changeHelper]
  );

  const { warnings, errors, isEditable } = getFieldMetaDataWithErrorHandling(
    () => getFieldMetaData(MAINTENANCE_TASK_PART_AMOUNT_FIELD_NAME)
  );

  if (isEditable) {
    return (
      <NumberField
        {...{
          value,
          errors,
          warnings,
          change,
          unit,
        }}
      />
    );
  }

  const label = `${value ?? "-"} ${unit ?? ""}`;

  return <XLabelField {...{ label, errors, warnings }} />;
};

export default React.memo(MaintenanceTaskPartAmountContainer);
