import axios from "axios";
import moment from "moment";
import { SortType } from "module/sort/types";
import {
  GetCompetencyManagementsResponse,
  mapApiResponse,
} from "api/competencyManagement/mappers";
import { CompetencyManagementListFilters } from "pages/competencyManagementList/types";

export const getCompetencyManagements = async (
  filters: CompetencyManagementListFilters,
  sort: SortType
): Promise<GetCompetencyManagementsResponse> => {
  const response = await axios.get(
    `/api/competency-managements?` +
      `exactDate=${moment(filters.scheduledDate).format("YYYY-MM-DD")}&` +
      `types=${filters.types?.filter((type) => type != null).join(",")}&` +
      `sort=${sort.fieldName}&` +
      `sort_direction=${sort.direction}`
  );
  return mapApiResponse(response.data);
};

export const areCompetencyManagementsFiltersValid = (
  filters: CompetencyManagementListFilters
): boolean => {
  return moment(filters.scheduledDate).isValid();
};
