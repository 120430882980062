import React, { useContext, useCallback, useMemo } from "react";
import DropdownButtonField from "component/dropdownButtonField/DropdownButtonField";
import InventoryCheckContext from "module/inventoryCheck/context/InventoryCheckContext";
import InventoryCheckChangeContext from "module/inventoryCheck/change/InventoryCheckChangeContext";
import InventoryCheckStateContext from "module/inventoryCheck/state/context/InventoryCheckStateContext";
import {
  InventoryCheckInterface,
  InventoryCheckState,
} from "module/inventoryCheck/types";
import { getInventoryCheckStateTranslation } from "module/inventoryCheck/helper/getInventoryCheckStateTranslation";

const InventoryCheckStateEditableFieldContainer: React.FC = () => {
  const { entity } = useContext(InventoryCheckContext);
  const { change } = useContext(InventoryCheckChangeContext);
  const { stateOptions: stateOptionsHelper } = useContext(
    InventoryCheckStateContext
  );

  const inventoryCheck = entity as InventoryCheckInterface;
  const stateOptions = stateOptionsHelper as InventoryCheckState[];

  const onChange = useCallback(
    (newState: unknown) => {
      change({
        state: newState as InventoryCheckState,
      });
    },
    [change]
  );

  const options = useMemo(
    () =>
      stateOptions.map((state: InventoryCheckState) => ({
        value: state,
        text: getInventoryCheckStateTranslation(state),
      })),
    [stateOptions]
  );

  const value = inventoryCheck.state;

  return (
    <DropdownButtonField
      {...{
        options,
        onChange,
        value,
      }}
    />
  );
};

export default React.memo(InventoryCheckStateEditableFieldContainer);
