import React, { useContext, useMemo } from "react";
import TrainingCustomContext from "module/trainingCustom/context/TrainingCustomContext";
import {
  MyTrainingCustomAssigneeInterface,
  TrainingCustomInterface,
} from "module/trainingCustom/types";
import { useAppSelector } from "redux/hooks";
import { selectStaffById } from "module/auth/redux/authSlice";
import selectEntity from "module/staff/redux/selector/selectEntity";
import t from "module/translations";

const TrainingCustomAssigneesLabelContainer: React.FC = () => {
  const { entity } = useContext(TrainingCustomContext);

  const trainingCustom = entity as TrainingCustomInterface;

  const selectStaffByIdHelper = useAppSelector(selectStaffById);
  const selectEntityHelper = useAppSelector(selectEntity);

  const trainingCustomAssigneesNames: string[] = useMemo(
    () =>
      trainingCustom.assignees.map((entityIdentifier) => {
        const assignee =
          trainingCustom.relationships != null
            ? (trainingCustom.relationships?.get(
                entityIdentifier
              ) as MyTrainingCustomAssigneeInterface)
            : (selectEntityHelper(
                entityIdentifier
              ) as MyTrainingCustomAssigneeInterface);
        try {
          if (assignee.data.staffId) {
            return selectStaffByIdHelper(assignee.data.staffId).name;
          }
          return assignee.data.externalAssigneeName || "";
        } catch (e) {
          return t.staffDeletedText;
        }
      }),
    [
      selectEntityHelper,
      selectStaffByIdHelper,
      trainingCustom.assignees,
      trainingCustom.relationships,
    ]
  );

  if (!trainingCustom.assignees.length) {
    return <>-</>;
  }

  return <>{trainingCustomAssigneesNames.join(", ")}</>;
};

export default React.memo(TrainingCustomAssigneesLabelContainer);
