import { EntityIdentifier, EntityType } from "module/entity/types";

export const getMapFromCollection = <Entity>(
  collection: Entity[],
  type: EntityType
): Map<EntityIdentifier, Entity> => {
  const map = new Map<EntityIdentifier, Entity>();

  // eslint-disable-next-line array-callback-return
  collection.map((item) => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    map.set({ id: item.id, type }, item);
  });

  return map;
};
