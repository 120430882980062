import axios from "axios";

const getImsToken = async (accessToken: string): Promise<string> => {
  const response = await axios.post("/login-by-ims-access-token", {
    accessToken,
  });
  return response.data.token;
};

export default getImsToken;
