import React from "react";
import { EntityIdentifier } from "module/entity/types";

export interface ToggleContextInterface {
  toggleOne: (item: EntityIdentifier) => void;
  toggleAll: (checked: boolean) => void;
  isSelected: (item: EntityIdentifier) => boolean;
  areAllSelected: boolean;
  isAnySelected: boolean;
  selectedItems: EntityIdentifier[];
  selectedItemsCount: number;
  itemsAllowedToToggleCount: number;
  setSelectedItems: (items: EntityIdentifier[]) => void;
  unselectOne: (item: EntityIdentifier) => void;
  canAnyBeSelected: boolean;
}

const ToggleContext = React.createContext(<ToggleContextInterface>{});

export default ToggleContext;
