import React from "react";
import { EntityFieldMetaDataContextInterface } from "module/entity/context/fieldMetaData/EntityFieldMetaDataContext";

export type MyTrainingCustomFieldMetaDataContextInterface =
  EntityFieldMetaDataContextInterface;

const TrainingCustomFieldMetaDataContext = React.createContext(
  <MyTrainingCustomFieldMetaDataContextInterface>{}
);

export default TrainingCustomFieldMetaDataContext;
