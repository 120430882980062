import { useCallback, useContext, useMemo } from "react";
import usePermissions from "module/permission/usePermissions";
import {
  MaintenanceTaskAssistantType,
  MaintenanceTaskInterface,
} from "module/maintenanceTask/types";
import MaintenanceTaskContext from "module/maintenanceTask/context/MaintenanceTaskContext";
import MaintenanceTaskOpenCloseContext from "module/maintenanceTask/openClose/context/MaintenanceTaskOpenCloseContext";
import { canAddMaintenanceTaskAssistant } from "module/maintenanceTask/helper/canAddMaintenanceTaskAssistant";
import MaintenanceTaskChangeContext from "module/maintenanceTask/change/MaintenanceTaskChangeContext";

interface Result {
  add: () => void;
  canAdd: boolean;
}

const useMaintenanceTaskAssistantAdd = (): Result => {
  const { entity } = useContext(MaintenanceTaskContext);
  const maintenanceTask = entity as MaintenanceTaskInterface;

  const { change: changeHelper } = useContext(MaintenanceTaskChangeContext);
  const { isOpen } = useContext(MaintenanceTaskOpenCloseContext);
  const { canEditMaintenanceTask } = usePermissions();

  const canAdd = useMemo(
    () =>
      canAddMaintenanceTaskAssistant({
        isMaintenanceTaskOpen: isOpen,
        maintenanceTask,
        canEditMaintenanceTask,
      }),
    [canEditMaintenanceTask, isOpen, maintenanceTask]
  );

  const add = useCallback(() => {
    changeHelper({
      assistantType: MaintenanceTaskAssistantType.internal,
      assistantInternalStaffId: undefined,
      assistantExternalStaffName: undefined,
      assistantExternalStaffCompany: undefined,
    });
  }, [changeHelper]);

  return {
    canAdd,
    add,
  };
};

export default useMaintenanceTaskAssistantAdd;
