import React, { useContext } from "react";
import t from "module/translations";
import TextareaField from "component/textareaField/TextareaField";
import XLabelField from "component/labelField/XLabelField";
import { getFieldMetaDataWithErrorHandling } from "module/fieldMetaData/getFieldMetaData";
import TrainingCustomContext from "module/trainingCustom/context/TrainingCustomContext";
import TrainingCustomChangeContext from "module/trainingCustom/change/TrainingCustomChangeContext";
import TrainingCustomFieldMetaDataContext from "module/trainingCustom/validation/fieldMetaData/TrainingCustomFieldMetaDataContext";
import { TrainingCustomInterface } from "module/trainingCustom/types";
import { TRAINING_CUSTOM_REMARKS_FIELD_NAME } from "module/trainingCustom/constants";

const TrainingCustomRemarksFieldContainer: React.FC = () => {
  const { entity } = useContext(TrainingCustomContext);
  const { change: changeHelper } = useContext(TrainingCustomChangeContext);
  const { getFieldMetaData } = useContext(TrainingCustomFieldMetaDataContext);

  const trainingCustom = entity as TrainingCustomInterface;

  const change = (remarks?: string) => {
    changeHelper({ remarks });
  };

  const { warnings, errors, isEditable } = getFieldMetaDataWithErrorHandling(
    () => getFieldMetaData(TRAINING_CUSTOM_REMARKS_FIELD_NAME)
  );

  const label = t.competencyManagement.trainingCustom.remarksText;

  return isEditable ? (
    <TextareaField
      {...{
        value: trainingCustom.remarks,
        change,
        label,
        errors,
      }}
    />
  ) : (
    <XLabelField
      {...{ caption: label, label: trainingCustom.remarks, errors, warnings }}
    />
  );
};

export default React.memo(TrainingCustomRemarksFieldContainer);
