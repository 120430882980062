import React from "react";
import { EntityIdentifier } from "module/entity/types";
import MyEntityContextProvider from "module/entity/context/entity/MyEntityContextProvider";
import TrainingCustomAssigneeContext from "module/trainingCustomAssignee/context/TrainingCustomAssigneeContext";
import MyTrainingCustomAssigneeFieldMetaDataContextProvider from "module/trainingCustomAssignee/validation/fieldMetaData/MyTrainingCustomAssigneeFieldMetaDataContextProvider";
import MyEntityValidationResultContextProvider from "module/entity/context/validationResult/MyEntityValidationResultContextProvider";
import TrainingCustomAssigneeValidationResultContext from "module/trainingCustomAssignee/validation/TrainingCustomAssigneeValidationResultContext";
import MyEntityChangeContextProvider from "module/entity/context/change/MyEntityChangeContextProvider";
import MyTrainingCustomAssigneeChangeContext from "module/trainingCustomAssignee/change/TrainingCustomAssigneeChangeContext";

interface Props {
  entityIdentifier: EntityIdentifier;
  children: React.ReactNode;
}

const MyTrainingCustomDetailsAssigneeContextProvider: React.FC<Props> = ({
  children,
  entityIdentifier,
}) => {
  return (
    <MyEntityContextProvider
      {...{
        entityIdentifier,
        context: TrainingCustomAssigneeContext,
      }}
    >
      <MyEntityValidationResultContextProvider
        {...{
          entityIdentifier,
          context: TrainingCustomAssigneeValidationResultContext,
        }}
      >
        <MyTrainingCustomAssigneeFieldMetaDataContextProvider>
          <MyEntityChangeContextProvider
            {...{
              entityIdentifier,
              context: MyTrainingCustomAssigneeChangeContext,
            }}
          >
            {children}
          </MyEntityChangeContextProvider>
        </MyTrainingCustomAssigneeFieldMetaDataContextProvider>
      </MyEntityValidationResultContextProvider>
    </MyEntityContextProvider>
  );
};

export default MyTrainingCustomDetailsAssigneeContextProvider;
