import React, { useContext } from "react";
import MaintenanceTaskContext from "module/maintenanceTask/context/MaintenanceTaskContext";
import { MaintenanceTaskInterface } from "module/maintenanceTask/types";
import MyMaintenanceTaskPartList from "pages/myMaintenanceTaskDetails/partList/MyMaintenanceTaskPartList";
import useMaintenanceTaskPartAdd from "module/maintenanceTaskPart/add/useMaintenanceTaskPartAdd";

const MyMaintenanceTaskPartListContainer: React.FC = () => {
  const { entity } = useContext(MaintenanceTaskContext);
  const maintenanceTask = entity as MaintenanceTaskInterface;

  const { canAdd, add } = useMaintenanceTaskPartAdd();

  return (
    <MyMaintenanceTaskPartList
      {...{ entityIdentifiers: maintenanceTask.parts, canAdd, add }}
    />
  );
};

export default React.memo(MyMaintenanceTaskPartListContainer);
