import routes from "routes/routes";

export const getPathToMaintenanceTaskDetails = (
  maintenanceTaskId: string
): string =>
  routes.myMaintenanceTaskDetails.path.replace(
    ":maintenanceTaskId",
    maintenanceTaskId.toString()
  );
export const getPathToMaintenanceTaskDetailsOverview = (
  maintenanceTaskId: string
): string =>
  routes.myMaintenanceTaskDetailsOverview.path.replace(
    ":maintenanceTaskId",
    maintenanceTaskId.toString()
  );

export const getPathToMaintenanceTaskDetailsProcessSteps = (
  maintenanceTaskId: string
): string =>
  routes.myMaintenanceTaskDetailsProcessSteps.path.replace(
    ":maintenanceTaskId",
    maintenanceTaskId.toString()
  );

export const getPathToMaintenanceTaskProcessStepDetails = ({
  maintenanceTaskId,
  processStepId,
}: {
  maintenanceTaskId: string;
  processStepId: string;
}): string =>
  routes.myMaintenanceTaskProcessStepsDetails.path
    .replace(":maintenanceTaskId", maintenanceTaskId.toString())
    .replace(":processStepId", processStepId.toString());

export const getPathToMaintenanceTaskDetailsParts = (
  maintenanceTaskId: string
): string =>
  routes.myMaintenanceTaskDetailsParts.path.replace(
    ":maintenanceTaskId",
    maintenanceTaskId.toString()
  );

export const getPathToMaintenanceTaskDetailsPersonnelAssignments = (
  maintenanceTaskId: string
): string =>
  routes.myMaintenanceTaskDetailsPersonnelAssignments.path.replace(
    ":maintenanceTaskId",
    maintenanceTaskId.toString()
  );

export const getPathToMaintenanceTaskToBeSignedDetails = (id: string): string =>
  routes.maintenanceTaskToBeSignedDetails.path.replace(
    ":maintenanceTaskId",
    id
  );

export const getPathToMaintenanceTaskToBeSignedDetailsOverview = (
  maintenanceTaskId: string
): string =>
  routes.maintenanceTaskToBeSignedDetailsOverview.path.replace(
    ":maintenanceTaskId",
    maintenanceTaskId.toString()
  );

export const getPathToMaintenanceTaskToBeSignedDetailsProcessSteps = (
  maintenanceTaskId: string
): string =>
  routes.maintenanceTaskToBeSignedDetailsProcessSteps.path.replace(
    ":maintenanceTaskId",
    maintenanceTaskId.toString()
  );

export const getPathToMaintenanceTaskToBeSignedProcessStepDetails = ({
  maintenanceTaskId,
  processStepId,
}: {
  maintenanceTaskId: string;
  processStepId: string;
}): string =>
  routes.maintenanceTaskToBeSignedDetailsProcessStepsDetails.path
    .replace(":maintenanceTaskId", maintenanceTaskId.toString())
    .replace(":processStepId", processStepId.toString());

export const getPathToMaintenanceTaskToBeSignedDetailsParts = (
  maintenanceTaskId: string
): string =>
  routes.maintenanceTaskToBeSignedDetailsParts.path.replace(
    ":maintenanceTaskId",
    maintenanceTaskId.toString()
  );

export const getPathToMaintenanceTaskToBeSignedDetailsPersonnelAssignments = (
  maintenanceTaskId: string
): string =>
  routes.maintenanceTaskToBeSignedDetailsPersonnelAssignments.path.replace(
    ":maintenanceTaskId",
    maintenanceTaskId.toString()
  );
