import React from "react";
import { EntityOpenCloseContextInterface } from "module/entity/context/openClose/EntityOpenCloseContext";

export type AssessmentOpenCloseContextInterface =
  EntityOpenCloseContextInterface;

const AssessmentOpenCloseContext = React.createContext(
  <AssessmentOpenCloseContextInterface>{}
);

export default AssessmentOpenCloseContext;
