import React, { useContext } from "react";
import MaintenanceTaskProcessStepContext from "module/maintenanceTaskProcessStep/context/MaintenanceTaskProcessStepContext";
import { MaintenanceTaskProcessStepInterface } from "module/maintenanceTaskProcessStep/types";
import MaintenanceTaskProcessStepCorrectiveAddButtonContainer from "../addButton/MaintenanceTaskProcessStepCorrectiveAddButtonContainer";
import MyMaintenanceTaskProcessStepCorrectiveLinkContainer from "../linkField/MyMaintenanceTaskProcessStepCorrectiveLinkContainer";

const MyMaintenanceTaskProcessStepCorrectiveContainer: React.FC = () => {
  const { entity: processStepEntity } = useContext(
    MaintenanceTaskProcessStepContext
  );
  const processStep = processStepEntity as MaintenanceTaskProcessStepInterface;
  const { quickFix, correctiveMaintenanceTask } = processStep;

  if (quickFix !== null) {
    return null;
  }

  if (correctiveMaintenanceTask) {
    return <MyMaintenanceTaskProcessStepCorrectiveLinkContainer />;
  }

  return <MaintenanceTaskProcessStepCorrectiveAddButtonContainer />;
};

export default React.memo(MyMaintenanceTaskProcessStepCorrectiveContainer);
