import { useContext, useMemo, useCallback } from "react";
import { useAppDispatch, useAppSelector } from "redux/hooks";
import { selectSystem, selectUser } from "module/auth/redux/authSlice";
import { EntityIdentifier, EntityType } from "module/entity/types";
import MaintenanceTaskContext from "module/maintenanceTask/context/MaintenanceTaskContext";
import { MaintenanceTaskInterface } from "module/maintenanceTask/types";
import MaintenanceTaskOpenCloseContext from "module/maintenanceTask/openClose/context/MaintenanceTaskOpenCloseContext";
import {
  removeFromEntityPropertyList,
  removeFromMyEntities,
} from "module/staff/redux/staffDataSlice";
import { MaintenanceTaskProcessStepInterface } from "module/maintenanceTaskProcessStep/types";
import { canRemoveMaintenanceTaskProcessStepAttachment } from "module/maintenanceTaskProcessStepAttachment/helper/canRemoveMaintenanceTaskProcessStepAttachment";
import MaintenanceTaskProcessStepContext from "module/maintenanceTaskProcessStep/context/MaintenanceTaskProcessStepContext";

interface Result {
  canRemove: boolean;
  remove: () => void;
}

const useMaintenanceTaskProcessStepAttachmentRemove = (
  entityIdentifier: EntityIdentifier
): Result => {
  const { entity: maintenanceTaskEntity } = useContext(MaintenanceTaskContext);
  const maintenanceTask = maintenanceTaskEntity as MaintenanceTaskInterface;

  const { entity: processStepEntity } = useContext(
    MaintenanceTaskProcessStepContext
  );
  const maintenanceTaskProcessStep =
    processStepEntity as MaintenanceTaskProcessStepInterface;

  const { isOpen } = useContext(MaintenanceTaskOpenCloseContext);

  const dispatch = useAppDispatch();
  const staffId = useAppSelector(selectUser).id;
  const systemShortName = useAppSelector(selectSystem).shortName;

  const canRemove = useMemo(
    () =>
      canRemoveMaintenanceTaskProcessStepAttachment({
        maintenanceTask,
        isMaintenanceTaskOpen: isOpen,
      }),
    [isOpen, maintenanceTask]
  );

  const remove = useCallback(() => {
    const maintenanceTaskProcessStepEntityIdentifier = {
      id: maintenanceTaskProcessStep.id,
      type: EntityType.maintenanceTaskProcessStep,
    };

    dispatch(
      removeFromEntityPropertyList({
        staffId,
        systemShortName,
        baseEntityIdentifier: maintenanceTaskProcessStepEntityIdentifier,
        propertyName: "attachments",
        entityIdentifierToRemove: entityIdentifier,
      })
    );

    dispatch(
      removeFromMyEntities({
        staffId,
        systemShortName,
        entityIdentifiers: [entityIdentifier],
      })
    );
  }, [
    dispatch,
    entityIdentifier,
    maintenanceTaskProcessStep.id,
    staffId,
    systemShortName,
  ]);

  return {
    canRemove,
    remove,
  };
};

export default useMaintenanceTaskProcessStepAttachmentRemove;
