import React, { useContext } from "react";
import CompetencyManagementListTableRowAssessmentContainer from "pages/competencyManagementList/table/row/assessment/CompetencyManagementListTableRowAssessmentContainer";
import CompetencyManagementListTableRowTrainingContainer from "pages/competencyManagementList/table/row/training/CompetencyManagementListTableRowTrainingContainer";
import {
  EntityIdentifier,
  EntityType,
  MyEntityInterface,
} from "module/entity/types";
import MyEntityCollectionContext from "module/entity/context/entityCollection/MyEntityCollectionContext";
import TrainingContext from "module/training/context/TrainingContext";
import TrainingCustomContext from "module/trainingCustom/context/TrainingCustomContext";
import AssessmentContext from "module/assessment/context/AssessmentContext";
import CompetencyManagementListTableRowTrainingCustomContainer from "pages/competencyManagementList/table/row/trainingCustom/CompetencyManagementListTableRowTrainingCustomContainer";

interface Props {
  entityIdentifier: EntityIdentifier;
}

const CompetencyManagementListTableRowContainer: React.FC<Props> = ({
  entityIdentifier,
}) => {
  const { myEntityCollection } = useContext(MyEntityCollectionContext);
  const { data } = myEntityCollection.get(
    entityIdentifier
  ) as MyEntityInterface;

  if (entityIdentifier.type === EntityType.training) {
    return (
      <TrainingContext.Provider value={{ entity: data }}>
        <CompetencyManagementListTableRowTrainingContainer />
      </TrainingContext.Provider>
    );
  }

  if (entityIdentifier.type === EntityType.trainingCustom) {
    return (
      <TrainingCustomContext.Provider value={{ entity: data }}>
        <CompetencyManagementListTableRowTrainingCustomContainer />
      </TrainingCustomContext.Provider>
    );
  }

  if (entityIdentifier.type === EntityType.assessment) {
    return (
      <AssessmentContext.Provider value={{ entity: data }}>
        <CompetencyManagementListTableRowAssessmentContainer />
      </AssessmentContext.Provider>
    );
  }

  return null;
};

export default React.memo(CompetencyManagementListTableRowContainer);
