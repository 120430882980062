import React, { useContext, useCallback, useMemo } from "react";
import ToggleContext from "module/toggle/context/ToggleContext";
import TableToggleCell from "component/table/toggleCell/TableToggleCell";
import { EntityIdentifier } from "module/entity/types";

interface Props {
  entityIdentifier: EntityIdentifier;
}

const TableToggleCellContainer: React.FC<Props> = ({ entityIdentifier }) => {
  const { isSelected: isSelectedHelper, toggleOne } = useContext(ToggleContext);
  const isSelected = useMemo(
    () => isSelectedHelper(entityIdentifier),
    [entityIdentifier, isSelectedHelper]
  );
  const toggle = useCallback(
    () => toggleOne(entityIdentifier),
    [entityIdentifier, toggleOne]
  );

  return <TableToggleCell {...{ isSelected, toggle }} />;
};

export default React.memo(TableToggleCellContainer);
