import { useMemo } from "react";
import { useAppSelector } from "redux/hooks";
import { selectStaffPermissions } from "module/auth/redux/authSlice";

interface UsePermissionsResult {
  canEditMaintenanceTask: boolean;
  canCompleteMaintenanceTask: boolean;
  canScheduleMaintenanceTask: boolean;
  canEditTraining: boolean;
  canCompleteTraining: boolean;
  canEditAssessment: boolean;
  canCompleteAssessment: boolean;
  canEditInventoryCheck: boolean;
  canCompleteInventoryCheck: boolean;
}

const usePermissions = (): UsePermissionsResult => {
  const permissions = useAppSelector(selectStaffPermissions);

  const canEditMaintenanceTask = useMemo(
    () => permissions.includes("PERMISSION_MAINTENANCE_TASK_EDIT"),
    [permissions]
  );

  const canCompleteMaintenanceTask = useMemo(
    () => permissions.includes("PERMISSION_MAINTENANCE_TASK_COMPLETE"),
    [permissions]
  );

  const canScheduleMaintenanceTask = useMemo(
    () => permissions.includes("PERMISSION_MAINTENANCE_TASK_SCHEDULE"),
    [permissions]
  );

  const canEditTraining = useMemo(
    () => permissions.includes("PERMISSION_TRAINING_ASSESSMENT_EDIT"),
    [permissions]
  );

  const canCompleteTraining = useMemo(
    () => permissions.includes("PERMISSION_TRAINING_ASSESSMENT_COMPLETE"),
    [permissions]
  );

  const canEditAssessment = useMemo(
    () => permissions.includes("PERMISSION_TRAINING_ASSESSMENT_EDIT"),
    [permissions]
  );

  const canCompleteAssessment = useMemo(
    () => permissions.includes("PERMISSION_TRAINING_ASSESSMENT_COMPLETE"),
    [permissions]
  );

  const canEditInventoryCheck = useMemo(
    () => permissions.includes("PERMISSION_INVENTORY_CHECK_EDIT"),
    [permissions]
  );

  const canCompleteInventoryCheck = useMemo(
    () => permissions.includes("PERMISSION_INVENTORY_CHECK_COMPLETE"),
    [permissions]
  );

  return {
    canEditMaintenanceTask,
    canCompleteMaintenanceTask,
    canScheduleMaintenanceTask,
    canEditTraining,
    canCompleteTraining,
    canEditAssessment,
    canCompleteAssessment,
    canEditInventoryCheck,
    canCompleteInventoryCheck,
  };
};

export default usePermissions;
