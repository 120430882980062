import { useCallback, useContext, useMemo } from "react";
import usePermissions from "module/permission/usePermissions";
import { MaintenanceTaskInterface } from "module/maintenanceTask/types";
import MaintenanceTaskContext from "module/maintenanceTask/context/MaintenanceTaskContext";
import MaintenanceTaskOpenCloseContext from "module/maintenanceTask/openClose/context/MaintenanceTaskOpenCloseContext";
import MaintenanceTaskChangeContext from "module/maintenanceTask/change/MaintenanceTaskChangeContext";
import { canRemoveMaintenanceTaskAssistant } from "module/maintenanceTask/helper/canRemoveMaintenanceTaskAssistant";

interface Result {
  remove: () => void;
  canRemove: boolean;
}

const useMaintenanceTaskAssistantRemove = (): Result => {
  const { entity } = useContext(MaintenanceTaskContext);
  const maintenanceTask = entity as MaintenanceTaskInterface;

  const { change: changeHelper } = useContext(MaintenanceTaskChangeContext);

  const { isOpen } = useContext(MaintenanceTaskOpenCloseContext);

  const { canEditMaintenanceTask } = usePermissions();

  const canRemove = useMemo(
    () =>
      canRemoveMaintenanceTaskAssistant({
        isMaintenanceTaskOpen: isOpen,
        maintenanceTask,
        canEditMaintenanceTask,
      }),
    [canEditMaintenanceTask, isOpen, maintenanceTask]
  );

  const remove = useCallback(() => {
    changeHelper({
      assistantType: undefined,
      assistantInternalStaffId: undefined,
      assistantExternalStaffName: undefined,
      assistantExternalStaffCompany: undefined,
    });
  }, [changeHelper]);

  return {
    canRemove,
    remove,
  };
};

export default useMaintenanceTaskAssistantRemove;
