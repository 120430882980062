import React, { useContext, useMemo } from "react";
import MaintenanceTaskPartListTableRow from "module/maintenanceTaskPart/enrichedComponent/table/MaintenanceTaskPartListTableRow";
import useMaintenanceTaskPartRemove from "module/maintenanceTaskPart/remove/useMaintenanceTaskPartRemove";
import MaintenanceTaskPartContext from "module/maintenanceTaskPart/context/MaintenanceTaskPartContext";
import { MaintenanceTaskPartInterface } from "module/maintenanceTaskPart/types";
import { EntityIdentifier, EntityType } from "module/entity/types";

const MyMaintenanceTaskPartListTableRowContainer: React.FC = () => {
  const { entity } = useContext(MaintenanceTaskPartContext);
  const part = entity as MaintenanceTaskPartInterface;

  const entityIdentifier: EntityIdentifier = useMemo(
    () => ({ id: part.id, type: EntityType.maintenanceTaskPart }),
    [part.id]
  );
  const { canRemove, remove } = useMaintenanceTaskPartRemove(entityIdentifier);

  return (
    <MaintenanceTaskPartListTableRow
      {...{ isActionAvailable: true, canRemove, remove }}
    />
  );
};

export default React.memo(MyMaintenanceTaskPartListTableRowContainer);
