import React, { useMemo, useCallback } from "react";
import { useAppSelector } from "redux/hooks";
import { selectStaffs } from "module/auth/redux/authSlice";
import { filterStaffVisibleOnList } from "module/staff/helper";
import AutocompleteField from "component/autocompleteField/AutocompleteField";
import t from "module/translations";
import StaffNameText from "module/staff/StaffNameText";
import { AutocompleteOption } from "component/autocompleteField/type";

interface Props {
  selectedStaffId?: number;
  onChange: (staffId: number) => void;
  excludedStaffIds?: number[];
  errors?: string[];
}

const StaffSelectContainer: React.FC<Props> = ({
  selectedStaffId,
  onChange,
  excludedStaffIds = [],
  errors,
}) => {
  const staffs = useAppSelector(selectStaffs);
  const options: AutocompleteOption[] = useMemo(
    () =>
      staffs
        .filter(filterStaffVisibleOnList)
        .filter(
          (availableStaff) => !excludedStaffIds.includes(availableStaff.id)
        )
        .map((staff) => ({
          value: staff.id,
          text: staff.name,
        })),
    [excludedStaffIds, staffs]
  );

  const handleChange = useCallback(
    (newValue: unknown) => {
      onChange(newValue as number);
    },
    [onChange]
  );

  const selected: AutocompleteOption | undefined = useMemo(
    () => options.find((option) => option.value === selectedStaffId),
    [options, selectedStaffId]
  );

  if (selectedStaffId && selected == null) {
    return <StaffNameText {...{ staffId: selectedStaffId }} />;
  }

  return (
    <AutocompleteField
      {...{
        selected,
        options,
        errors,
        onChange: handleChange,
        placeholder: t.maintenanceTask.personnelAssignments.selectStaffText,
      }}
    />
  );
};

export default React.memo(StaffSelectContainer);
