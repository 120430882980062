import React from "react";
import { EntityContextInterface } from "module/entity/context/entity/EntityContext";

export type AssessmentObjectiveContextInterface = EntityContextInterface;

const AssessmentObjectiveContext = React.createContext(
  <AssessmentObjectiveContextInterface>{}
);

export default AssessmentObjectiveContext;
