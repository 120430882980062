import React, { useCallback, useContext } from "react";
import AddButton from "component/addButton/AddButton";
import t from "module/translations";
import useMaintenanceTaskProcessStepCorrectiveAdd from "module/maintenanceTaskProcessStepCorrective/add/useMaintenanceTaskProcessStepCorrectiveAdd";
import FullPageDialog from "component/dialog/FullPageDialog";
import MaintenanceTaskProcessStepContext from "module/maintenanceTaskProcessStep/context/MaintenanceTaskProcessStepContext";
import { MaintenanceTaskProcessStepInterface } from "module/maintenanceTaskProcessStep/types";
import MaintenanceTaskProcessStepCorrectiveGenerate from "../generateCorrective/MaintenanceTaskProcessStepCorrectiveGenerate";
import useMaintenanceTaskCreateFromTemplate from "../../../maintenanceTask/template/useMaintenanceTaskCreateFromTemplate";
import { MaintenanceTaskType } from "../../../maintenanceTask/types";
import { addToEntityRelationships } from "../../../staff/redux/staffDataSlice";
import { EntityIdentifier, EntityType } from "../../../entity/types";
import { useAppDispatch, useAppSelector } from "../../../../redux/hooks";
import { selectSystem, selectUser } from "../../../auth/redux/authSlice";
import MaintenanceTaskProcessStepChangeContext from "../../../maintenanceTaskProcessStep/change/MaintenanceTaskProcessStepChangeContext";

const MaintenanceTaskProcessStepCorrectiveAddButtonContainer: React.FC = () => {
  const { entity: processStepEntity } = useContext(
    MaintenanceTaskProcessStepContext
  );
  const { change: changeMaintenanceTaskProcessStep } = useContext(
    MaintenanceTaskProcessStepChangeContext
  );
  const dispatch = useAppDispatch();
  const staffId = useAppSelector(selectUser).id;
  const systemShortName = useAppSelector(selectSystem).shortName;
  const { canAdd, openModal, closeModal, isModalOpen } =
    useMaintenanceTaskProcessStepCorrectiveAdd();
  const { create: createFromTemplate } = useMaintenanceTaskCreateFromTemplate();

  const processStep = processStepEntity as MaintenanceTaskProcessStepInterface;

  const onGenerate = useCallback(
    (templateId: number) => {
      const correctiveId = createFromTemplate(
        templateId,
        MaintenanceTaskType.corrective,
        processStep
      );
      const correctiveEntityIdentifier = {
        id: correctiveId,
        type: EntityType.maintenanceTask,
      };

      const maintenanceTaskProcessStepEntityIdentifier: EntityIdentifier = {
        id: processStep.id,
        type: EntityType.maintenanceTaskProcessStep,
      };

      dispatch(
        addToEntityRelationships({
          staffId,
          systemShortName,
          baseEntityIdentifier: maintenanceTaskProcessStepEntityIdentifier,
          entityIdentifierToAdd: correctiveEntityIdentifier,
        })
      );
      changeMaintenanceTaskProcessStep({
        correctiveMaintenanceTask: correctiveEntityIdentifier,
      });

      closeModal();
    },
    [
      changeMaintenanceTaskProcessStep,
      closeModal,
      createFromTemplate,
      dispatch,
      processStep,
      staffId,
      systemShortName,
    ]
  );

  if (!canAdd) {
    return null;
  }

  return (
    <>
      <AddButton
        {...{
          onClick: openModal,
          label: t.maintenanceTask.processStep.correctiveText,
        }}
      />
      {isModalOpen && (
        <FullPageDialog
          {...{
            title: t.maintenanceTask.processStep.addCorrectiveText(
              processStep.name
            ),
            onClose: closeModal,
          }}
        >
          <MaintenanceTaskProcessStepCorrectiveGenerate {...{ onGenerate }} />
        </FullPageDialog>
      )}
    </>
  );
};

export default React.memo(
  MaintenanceTaskProcessStepCorrectiveAddButtonContainer
);
