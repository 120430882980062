import t from "module/translations";
import { useAppSelector } from "redux/hooks";
import { selectStaffById as selectStaffByIdHelper } from "module/auth/redux/authSlice";
import { MaintenanceTaskInterface } from "module/maintenanceTask/types";

const useGetMaintenanceTaskBlockedReasonText = (
  maintenanceTask: MaintenanceTaskInterface
): string | null => {
  const {
    isBlockedDetails: {
      blockedByStaffId,
      isBlockedByOtherStaff,
      isBlockedByMeOnOtherDevice,
      isBlockedByMeOnThisDevice,
      isBlockedBecauseItIsValidated,
      isBlockedBecauseIsOutOfPMWindow,
      isBlockedBecauseStaffIsNotTrained,
      isBlockedBecauseStaffIsNotTrainedMessage,
    },
  } = maintenanceTask;

  const selectStaffById = useAppSelector(selectStaffByIdHelper);

  if (isBlockedByMeOnThisDevice) {
    return t.maintenanceTask.blocked.byMeOnThisDeviceText;
  }
  if (isBlockedByMeOnOtherDevice) {
    return t.maintenanceTask.blocked.byMeOnOtherDeviceText;
  }
  if (isBlockedByOtherStaff && blockedByStaffId) {
    const { name: staffName } = selectStaffById(blockedByStaffId);
    return t.maintenanceTask.blocked.byOtherStaffText(staffName);
  }
  if (isBlockedBecauseItIsValidated) {
    return t.maintenanceTask.blocked.isBlockedBecauseItIsValidatedText;
  }

  if (isBlockedBecauseIsOutOfPMWindow) {
    return t.maintenanceTask.blocked.outOfPMWindowText;
  }

  if (isBlockedBecauseStaffIsNotTrained) {
    return isBlockedBecauseStaffIsNotTrainedMessage as string;
  }

  return null;
};

export default useGetMaintenanceTaskBlockedReasonText;
