import React from "react";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import DeleteButton from "component/button/DeleteButton";
import MaintenanceTaskPersonnelAssignmentExternalStaffNameFieldContainer from "module/maintenanceTaskPersonnelAssignment/externalStaff/enrichedComponent/staffNameField/MaintenanceTaskPersonnelAssignmentExternalStaffNameFieldContainer";
import MaintenanceTaskPersonnelAssignmentExternalStaffCompanyFieldContainer from "module/maintenanceTaskPersonnelAssignment/externalStaff/enrichedComponent/staffCompanyField/MaintenanceTaskPersonnelAssignmentExternalStaffCompanyFieldContainer";
import MaintenanceTaskPersonnelAssignmentExternalStaffResponsibilityContainer from "module/maintenanceTaskPersonnelAssignment/externalStaff/enrichedComponent/responsibilityField/MaintenanceTaskPersonnelAssignmentExternalStaffResponsibilityContainer";
import { useDownloadedMaintenanceTaskDetailsPersonnelAssignmentsExternalStyles } from "module/maintenanceTaskPersonnelAssignment/externalStaff/enrichedComponent/table/styles";

interface Props {
  isActionAvailable: boolean;
  canRemove?: boolean;
  remove?: () => void;
}

const MaintenanceTaskDetailsPersonnelAssignmentExternalStaffListTableRow: React.FC<Props> =
  ({ canRemove, remove, isActionAvailable }) => {
    const classes =
      useDownloadedMaintenanceTaskDetailsPersonnelAssignmentsExternalStyles();

    return (
      <TableRow>
        <TableCell className={classes.externalStaffName}>
          <MaintenanceTaskPersonnelAssignmentExternalStaffNameFieldContainer />
        </TableCell>
        <TableCell className={classes.externalStaffCompany}>
          <MaintenanceTaskPersonnelAssignmentExternalStaffCompanyFieldContainer />
        </TableCell>
        <TableCell className={classes.responsibility}>
          <MaintenanceTaskPersonnelAssignmentExternalStaffResponsibilityContainer />
        </TableCell>
        {isActionAvailable && (
          <TableCell className={classes.actions}>
            {canRemove && remove != null && (
              <DeleteButton {...{ onClick: remove }} />
            )}
          </TableCell>
        )}
      </TableRow>
    );
  };

export default React.memo(
  MaintenanceTaskDetailsPersonnelAssignmentExternalStaffListTableRow
);
