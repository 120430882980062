import React from "react";
import makeStyles from "@mui/styles/makeStyles";
import MUIAppBar from "@mui/material/AppBar";
import clsx from "clsx";
import { DRAWER_WIDTH } from "component/sidemenu/constants";
import { ENV } from "toolkit/environment";
import { appBarColoursByEnvironment } from "const";

const useStyles = makeStyles((theme) => ({
  appBar: {
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    backgroundColor:
      appBarColoursByEnvironment[ENV] ?? theme.palette.primary.main,
  },
  appBarShift: {
    width: `calc(100% - ${DRAWER_WIDTH}px)`,
    marginLeft: DRAWER_WIDTH,
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
}));

interface Props {
  isMenuOpen: boolean;
  children: React.ReactNode;
}

const AppBar: React.FC<Props> = ({ isMenuOpen, children }) => {
  const classes = useStyles();
  return (
    <MUIAppBar
      position="fixed"
      className={clsx(classes.appBar, {
        [classes.appBarShift]: isMenuOpen,
      })}
    >
      {children}
    </MUIAppBar>
  );
};

export default React.memo(AppBar);
