import React, { useContext } from "react";
import TrainingContext from "module/training/context/TrainingContext";
import { TrainingInterface } from "module/training/types";
import useDateFormat from "toolkit/useDateFormat";
import TrainingCompletionStartDateTimeLabelField from "module/training/enrichedComponent/completionStartDateTimeField/TrainingCompletionStartDateTimeLabelField";

const TrainingCompletionStartDateTimeFieldContainer: React.FC = () => {
  const { entity } = useContext(TrainingContext);
  const { formatDateTime } = useDateFormat();

  const { completionStartDate } = entity as TrainingInterface;

  return (
    <TrainingCompletionStartDateTimeLabelField
      {...{ completionStartDate, formatDateTime }}
    />
  );
};

export default React.memo(TrainingCompletionStartDateTimeFieldContainer);
