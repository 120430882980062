import React from "react";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import { useMaintenanceTaskPartListStyles } from "module/maintenanceTaskPart/enrichedComponent/table/styles";
import DeleteButton from "component/button/DeleteButton";
import MaintenanceTaskPartStockArticleContainer from "module/maintenanceTaskPart/enrichedComponent/stockArticleField/MaintenanceTaskPartStockArticleContainer";
import MaintenanceTaskPartAmountContainer from "module/maintenanceTaskPart/enrichedComponent/amountField/MaintenanceTaskPartAmountContainer";

interface Props {
  isActionAvailable: boolean;
  canRemove?: boolean;
  remove?: () => void;
}

const MaintenanceTaskPartListTableRow: React.FC<Props> = ({
  isActionAvailable,
  canRemove,
  remove,
}) => {
  const classes = useMaintenanceTaskPartListStyles();

  return (
    <TableRow hover className={classes.stockArticleTableCell}>
      <TableCell align="left" className={classes.stockArticleTableCell}>
        <MaintenanceTaskPartStockArticleContainer />
      </TableCell>
      <TableCell align="left" className={classes.amountTableCell}>
        <MaintenanceTaskPartAmountContainer />
      </TableCell>
      {isActionAvailable && (
        <TableCell align="right" className={classes.actionsTableCell}>
          {canRemove && remove != null && (
            <DeleteButton {...{ onClick: remove }} />
          )}
        </TableCell>
      )}
    </TableRow>
  );
};

export default React.memo(MaintenanceTaskPartListTableRow);
