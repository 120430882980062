import t from "module/translations";
import { MaintenanceTaskSyncUnavailableReason } from "module/maintenanceTask/types";

export const getMaintenanceTaskSyncUnavailableTranslation = (
  unavailableReason: MaintenanceTaskSyncUnavailableReason
): string => {
  return {
    [MaintenanceTaskSyncUnavailableReason.notValid]:
      t.maintenanceTask.sync.unavailableReason.notValidText,
    [MaintenanceTaskSyncUnavailableReason.isOpen]:
      t.maintenanceTask.sync.unavailableReason.isOpenText,
    [MaintenanceTaskSyncUnavailableReason.offline]:
      t.maintenanceTask.sync.unavailableReason.offlineText,
    [MaintenanceTaskSyncUnavailableReason.noPermissions]:
      t.maintenanceTask.sync.unavailableReason.noPermissionsText,
    [MaintenanceTaskSyncUnavailableReason.hasNotChanged]:
      t.maintenanceTask.sync.unavailableReason.hasNotChangedText,
    [MaintenanceTaskSyncUnavailableReason.notProcessable]:
      t.maintenanceTask.sync.unavailableReason.notProcessableText,
    [MaintenanceTaskSyncUnavailableReason.stateIsNotSupported]:
      t.maintenanceTask.sync.unavailableReason.stateIsNotSupportedText,
    [MaintenanceTaskSyncUnavailableReason.someMaintenanceTasksCreatedForMaintenanceTaskProcessStepsAreOpen]:
      t.maintenanceTask.sync.unavailableReason
        .someMaintenanceTasksCreatedForMaintenanceTaskProcessStepsAreOpen,
  }[unavailableReason];
};
