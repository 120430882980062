import { createSlice } from "@reduxjs/toolkit";
import { v4 as uuid } from "uuid";
import { RootState } from "redux/store";
import { getStateSliceFromLocalStorage } from "module/storage/storage";

export const sliceKey = "device";

export interface DeviceState {
  identifier: string | null;
}

export const defaultState: DeviceState = {
  identifier: null,
};

const initialState: DeviceState =
  getStateSliceFromLocalStorage(sliceKey) ?? defaultState;

const slice = createSlice({
  name: sliceKey,
  initialState,
  reducers: {
    initDevice: (state) => {
      if (state.identifier === null) {
        return { ...state, identifier: uuid() };
      }
      return state;
    },
  },
});

export const { reducer, actions } = slice;
export const { initDevice } = actions;

export const selectDeviceId = (state: RootState): string =>
  state.device.identifier ?? "";
