import React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import StickyPaperTableContainer from "component/sticky/StickyPaperTableContainer";
import { EntityIdentifier } from "module/entity/types";
import MyTrainingCustomDetailsAssigneeContextProvider from "pages/myTrainingCustomDetails/assignees/MyTrainingCustomDetailsAssigneeContextProvider";
import { getEntityIdentifierKey } from "module/entity/helper/getEntityIdentifierKey";
import TrainingCustomAssigneeTableHead from "module/trainingCustomAssignee/enrichedComponent/tableHead/TrainingCustomAssigneeTableHead";
import TrainingCustomAssigneeTableRowContainer from "module/trainingCustomAssignee/enrichedComponent/tableRow/TrainingCustomAssigneeTableRowContainer";

interface Props {
  assigneesIdentifiers: EntityIdentifier[];
}

const MyTrainingCustomDetailsAssignees: React.FC<Props> = ({
  assigneesIdentifiers,
}) => {
  return (
    <StickyPaperTableContainer>
      <Table stickyHeader>
        <TrainingCustomAssigneeTableHead />
        <TableBody>
          {assigneesIdentifiers.map((assigneesIdentifier) => (
            <MyTrainingCustomDetailsAssigneeContextProvider
              {...{
                entityIdentifier: assigneesIdentifier,
                key: getEntityIdentifierKey(assigneesIdentifier),
              }}
            >
              <TrainingCustomAssigneeTableRowContainer />
            </MyTrainingCustomDetailsAssigneeContextProvider>
          ))}
        </TableBody>
      </Table>
    </StickyPaperTableContainer>
  );
};

export default React.memo(MyTrainingCustomDetailsAssignees);
