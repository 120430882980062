import React from "react";

interface Props {
  messages: string[];
}

const XValidationMessage: React.FC<Props> = ({ messages }) => {
  return <>{messages.map((message) => message)}</>;
};

export default React.memo(XValidationMessage);
