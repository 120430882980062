import React from "react";
import { toast } from "react-toastify";
import MuiAlert from "@mui/material/Alert";

enum Severity {
  success = "success",
  warning = "warning",
  error = "error",
  info = "info",
}

interface Props {
  severity: Severity;
  children: React.ReactNode;
}

const Toast: React.FC<Props> = ({ severity, children }) => {
  return (
    <MuiAlert severity={severity} icon={false}>
      {children}
    </MuiAlert>
  );
};

const MemoedToast = React.memo(Toast);

export const toastSuccess = (content: React.ReactNode): void => {
  toast.success(
    <MemoedToast severity={Severity.success}>{content}</MemoedToast>
  );
};

export const toastError = (content: React.ReactNode): void => {
  toast.error(<MemoedToast severity={Severity.error}>{content}</MemoedToast>);
};

export const toastWarning = (content: React.ReactNode): void => {
  toast.warn(<MemoedToast severity={Severity.warning}>{content}</MemoedToast>);
};

export const toastInfo = (content: React.ReactNode): void => {
  toast.info(<MemoedToast severity={Severity.info}>{content}</MemoedToast>);
};
