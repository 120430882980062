import { createSlice } from "@reduxjs/toolkit";
import { StaffDataState } from "module/staff/redux/types";
import deleteAllDataAction from "module/staff/redux/action/deleteAllDataAction";
import addToMyEntitiesAction from "module/staff/redux/action/addToMyEntitiesAction";
import removeFromMyEntitiesAction from "module/staff/redux/action/removeFromMyEntitiesAction";
import openEntityAction from "module/staff/redux/action/openEntityAction";
import closeEntityAction from "module/staff/redux/action/closeEntityAction";
import changeEntityAction from "module/staff/redux/action/changeEntityAction";
import validateEntitiesAction from "module/staff/redux/action/validateEntitiesAction";
import changeEntityCollectionAction from "module/staff/redux/action/changeEntityCollectionAction";
import openEntityCollectionAction from "module/staff/redux/action/openEntityCollectionAction";
import closeEntityCollectionAction from "module/staff/redux/action/closeEntityCollectionAction";
import createEntityAction from "module/staff/redux/action/createEntityAction";
import removeFromEntityRelationshipsAction from "module/staff/redux/action/removeFromEntityRelationshipsAction";
import addToEntityRelationshipsAction from "module/staff/redux/action/addToEntityRelationshipsAction";
import removeFromEntityPropertyListAction from "module/staff/redux/action/removeFromEntityPropertyListAction";
import addToEntityPropertyListAction from "module/staff/redux/action/addToEntityPropertyListAction";
import initializeAction from "module/staff/redux/action/initializeAction";

export const sliceKey = "staffData";

export const defaultState: StaffDataState = {};

const slice = createSlice({
  name: sliceKey,
  initialState: defaultState,
  reducers: {
    initialize: initializeAction,
    deleteAllData: deleteAllDataAction,

    addToMyEntities: addToMyEntitiesAction,
    removeFromMyEntities: removeFromMyEntitiesAction,
    openEntity: openEntityAction,
    openEntityCollection: openEntityCollectionAction,
    closeEntity: closeEntityAction,
    closeEntityCollection: closeEntityCollectionAction,
    changeEntity: changeEntityAction,
    changeEntityCollection: changeEntityCollectionAction,
    validateEntities: validateEntitiesAction,
    // could be replaced by addToMyEntities
    createEntity: createEntityAction,
    removeFromEntityRelationships: removeFromEntityRelationshipsAction,
    removeFromEntityPropertyList: removeFromEntityPropertyListAction,
    addToEntityRelationships: addToEntityRelationshipsAction,
    addToEntityPropertyList: addToEntityPropertyListAction,
  },
});

export const { reducer, actions } = slice;
export const {
  initialize,
  deleteAllData,
  addToMyEntities,
  removeFromMyEntities,
  openEntity,
  openEntityCollection,
  closeEntity,
  closeEntityCollection,
  changeEntity,
  changeEntityCollection,
  validateEntities,
  createEntity,
  removeFromEntityRelationships,
  removeFromEntityPropertyList,
  addToEntityRelationships,
  addToEntityPropertyList,
} = actions;
