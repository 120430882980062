import React, { useContext } from "react";
import InventoryCheckContext from "module/inventoryCheck/context/InventoryCheckContext";
import { InventoryCheckInterface } from "module/inventoryCheck/types";
import MyInventoryCheckArticleList from "./MyInventoryCheckArticleList";

const MyInventoryCheckArticleListContainer: React.FC = () => {
  const { entity } = useContext(InventoryCheckContext);
  const inventoryCheck = entity as InventoryCheckInterface;

  return (
    <MyInventoryCheckArticleList
      {...{ entityIdentifiers: inventoryCheck.inventoryCheckArticles }}
    />
  );
};

export default React.memo(MyInventoryCheckArticleListContainer);
