import { createNestedPropertyIfNotExist } from "toolkit/createNestedPropertyIfNotExist";
import { canProcess } from "module/maintenanceTask/helper/canProcess";
import {
  MaintenanceTaskAssistantType,
  MaintenanceTaskInterface,
  MaintenanceTaskType,
} from "module/maintenanceTask/types";
import { XValidatorErrorsInterface } from "module/validation/types";
import { XRequiredValidator } from "module/validation/validator/requiredValidator";
import {
  MAINTENANCE_TASK_ASSISTANT_EXTERNAL_STAFF_COMPANY_FIELD_NAME,
  MAINTENANCE_TASK_ASSISTANT_EXTERNAL_STAFF_NAME_FIELD_NAME,
  MAINTENANCE_TASK_ASSISTANT_INTERNAL_STAFF_FIELD_NAME,
  MAINTENANCE_TASK_EXPECTED_END_DATE_FIELD_NAME,
  MAINTENANCE_TASK_EXPECTED_START_DATE_FIELD_NAME,
  MAINTENANCE_TASK_MAINTAINED_AT_VALUE_FIELD_NAME,
  MAINTENANCE_TASK_NAME_FIELD_NAME,
  MAINTENANCE_TASK_OBSERVATIONAL_INDICATORS_FIELD_NAME,
  MAINTENANCE_TASK_PRESUMED_CAUSES_FIELD_NAME,
  MAINTENANCE_TASK_SCHEDULED_END_DATE_FIELD_NAME,
  MAINTENANCE_TASK_SCHEDULED_START_DATE_FIELD_NAME,
} from "module/maintenanceTask/constants";
import { isMaintenanceTaskMaintainedAtValueAvailable } from "module/maintenanceTask/helper/isMaintenanceTaskMaintainedAtValueAvailable";
import { isObservationalIndicatorsAvailable } from "../helper/isObservationalIndicatorsAvailable";
import { isPresumedCausesAvailable } from "../helper/isPresumedCausesAvailable";

export interface MaintenanceTaskValidationResult {
  errors: null | XValidatorErrorsInterface;
  warnings: null | XValidatorErrorsInterface;
}

export const validateMaintenanceTask = (
  maintenanceTask: MaintenanceTaskInterface
): MaintenanceTaskValidationResult => {
  let errors: XValidatorErrorsInterface = {};
  const warnings: XValidatorErrorsInterface = {};

  if (!canProcess(maintenanceTask)) {
    throw new Error("Maintenance task is not processable");
  }

  const isNew = !maintenanceTask.backendId;

  if (
    isNew &&
    ![MaintenanceTaskType.corrective, MaintenanceTaskType.preventive].includes(
      maintenanceTask.type
    )
  ) {
    throw new Error("Type is not supported");
  }

  if (isNew && maintenanceTask.type === MaintenanceTaskType.corrective) {
    if (maintenanceTask.name == null || maintenanceTask.name.length === 0) {
      errors = createNestedPropertyIfNotExist(
        errors,
        MAINTENANCE_TASK_NAME_FIELD_NAME,
        []
      );
      errors[MAINTENANCE_TASK_NAME_FIELD_NAME].push(
        new XRequiredValidator().message
      );
    }
  }

  if (
    !isNew &&
    maintenanceTask.type === MaintenanceTaskType.preventive &&
    maintenanceTask.assistantType != null
  ) {
    if (
      maintenanceTask.assistantType === MaintenanceTaskAssistantType.external
    ) {
      const isNameEmpty =
        maintenanceTask.assistantExternalStaffName == null ||
        !maintenanceTask.assistantExternalStaffName.length;
      const isCompanyEmpty =
        maintenanceTask.assistantExternalStaffCompany == null ||
        !maintenanceTask.assistantExternalStaffCompany.length;

      if (isNameEmpty) {
        errors = createNestedPropertyIfNotExist(
          errors,
          MAINTENANCE_TASK_ASSISTANT_EXTERNAL_STAFF_NAME_FIELD_NAME,
          []
        );
        errors[MAINTENANCE_TASK_ASSISTANT_EXTERNAL_STAFF_NAME_FIELD_NAME].push(
          new XRequiredValidator().message
        );
      }

      if (isCompanyEmpty) {
        errors = createNestedPropertyIfNotExist(
          errors,
          MAINTENANCE_TASK_ASSISTANT_EXTERNAL_STAFF_COMPANY_FIELD_NAME,
          []
        );
        errors[
          MAINTENANCE_TASK_ASSISTANT_EXTERNAL_STAFF_COMPANY_FIELD_NAME
        ].push(new XRequiredValidator().message);
      }
    }

    if (
      maintenanceTask.assistantType === MaintenanceTaskAssistantType.internal &&
      maintenanceTask.assistantInternalStaffId == null
    ) {
      errors = createNestedPropertyIfNotExist(
        errors,
        MAINTENANCE_TASK_ASSISTANT_INTERNAL_STAFF_FIELD_NAME,
        []
      );
      errors[MAINTENANCE_TASK_ASSISTANT_INTERNAL_STAFF_FIELD_NAME].push(
        new XRequiredValidator().message
      );
    }
  }

  if (
    isMaintenanceTaskMaintainedAtValueAvailable(maintenanceTask) &&
    maintenanceTask.maintainedAtValue == null
  ) {
    errors = createNestedPropertyIfNotExist(
      errors,
      MAINTENANCE_TASK_MAINTAINED_AT_VALUE_FIELD_NAME,
      []
    );
    errors[MAINTENANCE_TASK_MAINTAINED_AT_VALUE_FIELD_NAME].push(
      new XRequiredValidator().message
    );
  }

  if (
    isObservationalIndicatorsAvailable(maintenanceTask) &&
    maintenanceTask.source !== "serviceRequest" &&
    !maintenanceTask.observationalIndicators?.length
  ) {
    errors = createNestedPropertyIfNotExist(
      errors,
      MAINTENANCE_TASK_OBSERVATIONAL_INDICATORS_FIELD_NAME,
      []
    );
    errors[MAINTENANCE_TASK_OBSERVATIONAL_INDICATORS_FIELD_NAME].push(
      new XRequiredValidator().message
    );
  }

  if (
    isPresumedCausesAvailable(maintenanceTask) &&
    maintenanceTask.source !== "serviceRequest" &&
    !maintenanceTask.presumedCauses?.length
  ) {
    errors = createNestedPropertyIfNotExist(
      errors,
      MAINTENANCE_TASK_PRESUMED_CAUSES_FIELD_NAME,
      []
    );
    errors[MAINTENANCE_TASK_PRESUMED_CAUSES_FIELD_NAME].push(
      new XRequiredValidator().message
    );
  }

  if (!maintenanceTask.expectedStartDate) {
    errors = createNestedPropertyIfNotExist(
      errors,
      MAINTENANCE_TASK_EXPECTED_START_DATE_FIELD_NAME,
      []
    );
    errors[MAINTENANCE_TASK_EXPECTED_START_DATE_FIELD_NAME].push(
      new XRequiredValidator().message
    );
  }

  if (!maintenanceTask.expectedEndDate) {
    errors = createNestedPropertyIfNotExist(
      errors,
      MAINTENANCE_TASK_EXPECTED_END_DATE_FIELD_NAME,
      []
    );
    errors[MAINTENANCE_TASK_EXPECTED_END_DATE_FIELD_NAME].push(
      new XRequiredValidator().message
    );
  }

  if (!maintenanceTask.scheduledStartDate) {
    errors = createNestedPropertyIfNotExist(
      errors,
      MAINTENANCE_TASK_SCHEDULED_START_DATE_FIELD_NAME,
      []
    );
    errors[MAINTENANCE_TASK_SCHEDULED_START_DATE_FIELD_NAME].push(
      new XRequiredValidator().message
    );
  }

  if (!maintenanceTask.scheduledEndDate) {
    errors = createNestedPropertyIfNotExist(
      errors,
      MAINTENANCE_TASK_SCHEDULED_END_DATE_FIELD_NAME,
      []
    );
    errors[MAINTENANCE_TASK_SCHEDULED_END_DATE_FIELD_NAME].push(
      new XRequiredValidator().message
    );
  }

  return {
    errors: Object.keys(errors).length ? errors : null,
    warnings: Object.keys(warnings).length ? warnings : null,
  };
};
