import React from "react";
import { EntityChangeContextInterface } from "module/entity/context/change/EntityChangeContext";

export type TrainingChangeContextInterface = EntityChangeContextInterface;

const TrainingChangeContext = React.createContext(
  <TrainingChangeContextInterface>{}
);

export default TrainingChangeContext;
