import React, { useState, useEffect } from "react";
import SideMenuItem from "component/sidemenu/SideMenuItem";
import routes from "routes/routes";
import getToBeSigned from "api/toBeSigned/getToBeSigned";
import SideMenuSection from "component/sidemenu/SideMenuSection";

const ToBeSignedMenuSection: React.FC = () => {
  const [count, setCount] = useState<number | undefined>();

  useEffect(() => {
    async function getToBeSignedCount() {
      const fromApi = await getToBeSigned();
      setCount(fromApi.count);
    }
    getToBeSignedCount();
  }, []);

  return (
    <SideMenuSection>
      <SideMenuItem
        {...{
          title: routes.toBeSigned.title || "",
          path: routes.toBeSigned.path,
          key: routes.toBeSigned.path,
          badgeContent: count || undefined,
        }}
      />
    </SideMenuSection>
  );
};

export default React.memo(ToBeSignedMenuSection);
