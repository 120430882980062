import React, { useContext } from "react";
import { TrainingCustomAssigneeInterface } from "module/trainingCustom/types";
import TrainingCustomAssigneeTableRow from "module/trainingCustomAssignee/enrichedComponent/tableRow/TrainingCustomAssigneeTableRow";
import TrainingCustomAssigneeContext from "module/trainingCustomAssignee/context/TrainingCustomAssigneeContext";

const TrainingCustomAssigneeTableRowContainer: React.FC = () => {
  const { entity } = useContext(TrainingCustomAssigneeContext);
  const trainingCustomAssignee = entity as TrainingCustomAssigneeInterface;

  return <TrainingCustomAssigneeTableRow {...{ trainingCustomAssignee }} />;
};

export default React.memo(TrainingCustomAssigneeTableRowContainer);
