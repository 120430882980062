import React, { useContext } from "react";
import TrainingContext from "module/training/context/TrainingContext";
import { TrainingInterface } from "module/training/types";
import TrainingModuleLabelField from "module/training/enrichedComponent/moduleField/TrainingModuleLabelField";

const TrainingModuleFieldContainer: React.FC = () => {
  const { entity } = useContext(TrainingContext);
  const { moduleText } = entity as TrainingInterface;

  return <TrainingModuleLabelField {...{ module: moduleText }} />;
};

export default React.memo(TrainingModuleFieldContainer);
