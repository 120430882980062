import React from "react";
import { EntityValidationResultContextInterface } from "module/entity/context/validationResult/EntityValidationResultContext";

export type MaintenanceTaskPartValidationResultContextInterface =
  EntityValidationResultContextInterface;

const MaintenanceTaskPartPartValidationResultContext = React.createContext(
  <MaintenanceTaskPartValidationResultContextInterface>{}
);

export default MaintenanceTaskPartPartValidationResultContext;
