import React, { useContext } from "react";
import TrainingToBeSignedDetails from "pages/trainingToBeSignedDetails/TrainingToBeSignedDetails";
import TrainingContext from "module/training/context/TrainingContext";

const TrainingToBeSignedDetailsContainer: React.FC = () => {
  const { entity } = useContext(TrainingContext);

  return (
    <TrainingToBeSignedDetails
      {...{
        trainingId: entity.id,
      }}
    />
  );
};

export default React.memo(TrainingToBeSignedDetailsContainer);
