import { IsEntitySyncAvailableResult } from "module/entity/useEntityAbstractFactory";
import { canProcess } from "module/maintenanceTask/helper/canProcess";
import {
  MaintenanceTaskInterface,
  MaintenanceTaskState,
  MaintenanceTaskSyncUnavailableReason,
} from "module/maintenanceTask/types";

export interface IsMaintenanceTaskSyncAvailableProps {
  maintenanceTask: MaintenanceTaskInterface;
  isOnline: boolean;
  hasPermissions: boolean;
  isOpen: boolean;
  isValid: boolean;
  hasChanged: boolean;
  isAnyMaintenanceTaskCreatedForMaintenanceTaskProcessStepsOpen: boolean;
}

const isMaintenanceTaskSyncAvailable = ({
  maintenanceTask,
  isOnline,
  hasPermissions,
  isOpen,
  isValid,
  hasChanged,
  isAnyMaintenanceTaskCreatedForMaintenanceTaskProcessStepsOpen,
}: IsMaintenanceTaskSyncAvailableProps): IsEntitySyncAvailableResult => {
  if (!isOnline) {
    return {
      isAvailable: false,
      unavailableReason: MaintenanceTaskSyncUnavailableReason.offline,
    };
  }

  if (!hasPermissions) {
    return {
      isAvailable: false,
      unavailableReason: MaintenanceTaskSyncUnavailableReason.noPermissions,
    };
  }

  if (isOpen) {
    return {
      isAvailable: false,
      unavailableReason: MaintenanceTaskSyncUnavailableReason.isOpen,
    };
  }

  if (!isValid) {
    return {
      isAvailable: false,
      unavailableReason: MaintenanceTaskSyncUnavailableReason.notValid,
    };
  }

  if (!hasChanged) {
    return {
      isAvailable: false,
      unavailableReason: MaintenanceTaskSyncUnavailableReason.hasNotChanged,
    };
  }

  if (!canProcess(maintenanceTask)) {
    return {
      isAvailable: false,
      unavailableReason: MaintenanceTaskSyncUnavailableReason.notProcessable,
    };
  }

  if (
    ![
      MaintenanceTaskState.requested,
      MaintenanceTaskState.scheduled,
      MaintenanceTaskState.completed,
    ].includes(maintenanceTask.state)
  ) {
    return {
      isAvailable: false,
      unavailableReason:
        MaintenanceTaskSyncUnavailableReason.stateIsNotSupported,
    };
  }

  if (isAnyMaintenanceTaskCreatedForMaintenanceTaskProcessStepsOpen) {
    return {
      isAvailable: false,
      unavailableReason:
        MaintenanceTaskSyncUnavailableReason.someMaintenanceTasksCreatedForMaintenanceTaskProcessStepsAreOpen,
    };
  }

  return {
    isAvailable: true,
    unavailableReason: null,
  };
};

export default isMaintenanceTaskSyncAvailable;
