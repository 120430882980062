import React, { useContext, useMemo, useCallback } from "react";
import ToggleContext from "module/toggle/context/ToggleContext";
import { useAppSelector } from "redux/hooks";
import { selectUser } from "module/auth/redux/authSlice";
import { EntityType } from "module/entity/types";
import usePermissions from "module/permission/usePermissions";
import TrainingCustomContext from "module/trainingCustom/context/TrainingCustomContext";
import { TrainingCustomInterface } from "module/trainingCustom/types";
import CompetencyManagementListTableRowTrainingCustom from "pages/competencyManagementList/table/row/trainingCustom/CompetencyManagementListTableRowTrainingCustom";

const CompetencyManagementListTableRowTrainingContainer: React.FC = () => {
  const { entity } = useContext(TrainingCustomContext);
  const { isSelected: isSelectedHelper, toggleOne } = useContext(ToggleContext);

  const { id: staffId } = useAppSelector(selectUser);

  const { canEditTraining } = usePermissions();

  const trainingCustom = entity as TrainingCustomInterface;
  const isAssignedToMe = trainingCustom.internalTrainerId === staffId;
  const toggleItem = useMemo(
    () => ({
      id: trainingCustom.id,
      type: EntityType.trainingCustom,
    }),
    [trainingCustom.id]
  );
  const rowClick = useCallback(() => {
    if (canEditTraining && !trainingCustom.isBlockedDetails.isBlocked) {
      toggleOne(toggleItem);
    }
  }, [
    canEditTraining,
    toggleItem,
    toggleOne,
    trainingCustom.isBlockedDetails.isBlocked,
  ]);
  const isSelected = useMemo(
    () => isSelectedHelper(toggleItem),
    [isSelectedHelper, toggleItem]
  );

  return (
    <CompetencyManagementListTableRowTrainingCustom
      {...{
        trainingCustom,
        isSelected,
        rowClick,
        isHighlighted: isAssignedToMe,
        hasPermissionToDownloadTraining: canEditTraining,
      }}
    />
  );
};

export default React.memo(CompetencyManagementListTableRowTrainingContainer);
