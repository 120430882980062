import React, { useContext, useCallback, useMemo } from "react";
import RadioGroupField from "component/radioGroupField/RadioGroupField";
import { RadioGroupOption } from "component/radioGroupField/type";
import { getFieldMetaDataWithErrorHandling } from "module/fieldMetaData/getFieldMetaData";
import XLabelField from "component/labelField/XLabelField";
import InventoryCheckArticleContext from "module/inventoryCheckArticle/context/InventoryCheckArticleContext";
import InventoryCheckArticleChangeContext from "module/inventoryCheckArticle/change/InventoryCheckArticleChangeContext";
import InventoryCheckArticleFieldMetaDataContext from "module/inventoryCheckArticle/validation/fieldMetaData/InventoryCheckArticleFieldMetaDataContext";
import {
  InventoryCheckArticleHasChanged,
  InventoryCheckArticleInterface,
} from "module/inventoryCheckArticle/types";
import { INVENTORY_CHECK_ARTICLE_HAS_CHANGED_FIELD_NAME } from "module/inventoryCheckArticle/constants";
import {
  getInventoryCheckArticleAmountCorrectTranslation,
  getInventoryCheckArticleHasChangedTranslation,
} from "module/inventoryCheckArticle/helper/getInventoryCheckArticleHasChangedTranslation";

const InventoryCheckArticleHasChangedFieldContainer: React.FC = () => {
  const { entity } = useContext(InventoryCheckArticleContext);
  const { change: changeHelper } = useContext(
    InventoryCheckArticleChangeContext
  );
  const { getFieldMetaData } = useContext(
    InventoryCheckArticleFieldMetaDataContext
  );

  const inventoryCheckArticle = entity as InventoryCheckArticleInterface;

  const { warnings, errors, isEditable } = getFieldMetaDataWithErrorHandling(
    () => getFieldMetaData(INVENTORY_CHECK_ARTICLE_HAS_CHANGED_FIELD_NAME)
  );

  const change = useCallback(
    (rawHasChanged: unknown) => {
      const hasChanged = rawHasChanged as InventoryCheckArticleHasChanged;
      let changes: Partial<InventoryCheckArticleInterface> = {
        hasChanged,
      };

      if (hasChanged === InventoryCheckArticleHasChanged.yes) {
        changes = { ...changes, description: "" };
      } else {
        changes = { ...changes, description: undefined, newAmount: undefined };
      }

      changeHelper(changes);
    },
    [changeHelper]
  );

  const options: RadioGroupOption[] = useMemo(
    () => [
      {
        value: InventoryCheckArticleHasChanged.no,
        label: getInventoryCheckArticleAmountCorrectTranslation(
          InventoryCheckArticleHasChanged.no
        ),
      },
      {
        value: InventoryCheckArticleHasChanged.yes,
        label: getInventoryCheckArticleAmountCorrectTranslation(
          InventoryCheckArticleHasChanged.yes
        ),
      },
      {
        value: InventoryCheckArticleHasChanged.notSet,
        label: getInventoryCheckArticleAmountCorrectTranslation(
          InventoryCheckArticleHasChanged.notSet
        ),
      },
    ],
    []
  );

  const selectedOption = useMemo(
    () =>
      inventoryCheckArticle.hasChanged == null
        ? undefined
        : (options.find(
            (option) => option.value === inventoryCheckArticle.hasChanged
          ) as RadioGroupOption),
    [inventoryCheckArticle.hasChanged, options]
  );

  if (isEditable) {
    return (
      <RadioGroupField
        {...{ options, selectedOption, change, errors, warnings }}
      />
    );
  }
  return (
    <XLabelField
      {...{
        errors,
        warnings,
        label: getInventoryCheckArticleHasChangedTranslation(
          inventoryCheckArticle.hasChanged
        ),
      }}
    />
  );
};
export default React.memo(InventoryCheckArticleHasChangedFieldContainer);
