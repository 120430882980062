import React, { useContext, useCallback } from "react";
import useDateFormat from "toolkit/useDateFormat";
import MaintenanceTaskContext from "module/maintenanceTask/context/MaintenanceTaskContext";
import { MaintenanceTaskInterface } from "module/maintenanceTask/types";
import XLabelField from "component/labelField/XLabelField";
import t from "module/translations";
import MaintenanceTaskChangeContext from "module/maintenanceTask//change/MaintenanceTaskChangeContext";
import MaintenanceTaskFieldMetaDataContext from "module/maintenanceTask//validation/fieldMetaData/MaintenanceTaskFieldMetaDataContext";
import { getFieldMetaDataWithErrorHandling } from "module/fieldMetaData/getFieldMetaData";
import { MAINTENANCE_TASK_SCHEDULED_START_DATE_FIELD_NAME } from "module/maintenanceTask/constants";
import DateTimePickerField from "component/dateTimePickerField/DateTimePickerField";

const MaintenanceTaskScheduledStartDateFieldContainer: React.FC = () => {
  const { entity } = useContext(MaintenanceTaskContext);
  const { change: changeHelper } = useContext(MaintenanceTaskChangeContext);
  const { getFieldMetaData } = useContext(MaintenanceTaskFieldMetaDataContext);
  const { formatDateTime, getDateTimeStringFromDate } = useDateFormat();

  const maintenanceTask = entity as MaintenanceTaskInterface;

  const change = useCallback(
    (newDate: Date | null) => {
      const newDateString = newDate ? getDateTimeStringFromDate(newDate) : null;
      changeHelper({
        scheduledStartDate: newDateString,
      });
    },
    [changeHelper, getDateTimeStringFromDate]
  );

  const isNew = maintenanceTask.backendId == null;
  const { warnings, errors, isEditable } = getFieldMetaDataWithErrorHandling(
    () => getFieldMetaData(MAINTENANCE_TASK_SCHEDULED_START_DATE_FIELD_NAME)
  );

  const label = t.maintenanceTask.scheduledStartText;

  return isNew && isEditable ? (
    <DateTimePickerField
      {...{
        change,
        label,
        value: maintenanceTask.scheduledStartDate
          ? new Date(maintenanceTask.scheduledStartDate)
          : null,
        errors,
      }}
    />
  ) : (
    <XLabelField
      {...{
        caption: label,
        label: maintenanceTask.scheduledStartDate
          ? formatDateTime(maintenanceTask.scheduledStartDate)
          : "-",
        errors,
        warnings,
      }}
    />
  );
};

export default React.memo(MaintenanceTaskScheduledStartDateFieldContainer);
