import React from "react";
import { createRoot } from "react-dom/client";
import CssBaseline from "@mui/material/CssBaseline";
import {
  createTheme,
  StyledEngineProvider,
  Theme,
  ThemeProvider,
} from "@mui/material/styles";
import * as Sentry from "@sentry/react";
import ReduxProvider from "redux/ReduxProvider";
import App from "layout/App";
import OnlineDetectorContextProvider from "module/onlineDetector/OnlineDetecotrContextProvider";
import FullScreenLoaderContextProvider from "module/fullScreenLoader/context/FullScreenLoaderContextProvider";
import NewVersionAvailable from "module/newVersionAvailable/NewVersionAvailable";
import ReduxPersistGate from "redux/ReduxPersistGate";
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";
import reportWebVitals from "./reportWebVitals";
import "axios/init";
import "module/storage/init";
import "styles/init";
import "error/sentry/sentryInit";

declare module "@mui/styles/defaultTheme" {
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  interface DefaultTheme extends Theme {}
}

const theme = createTheme({
  palette: {
    primary: {
      main: "#051e6e",
    },
    secondary: {
      main: "#051e6e",
    },
  },
});

const FallbackComponent = () => <div>An error has occurred</div>;

const container = document.getElementById("root");
// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
const root = createRoot(container!);
root.render(
  <React.StrictMode>
    <CssBaseline />
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        <FullScreenLoaderContextProvider>
          <NewVersionAvailable />
          <OnlineDetectorContextProvider>
            <ReduxProvider>
              <Sentry.ErrorBoundary fallback={FallbackComponent} showDialog>
                <ReduxPersistGate>
                  <App />
                </ReduxPersistGate>
              </Sentry.ErrorBoundary>
            </ReduxProvider>
          </OnlineDetectorContextProvider>
        </FullScreenLoaderContextProvider>
      </ThemeProvider>
    </StyledEngineProvider>
  </React.StrictMode>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.register();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
