import React from "react";
import AppInfo from "pages/appInfo/AppInfo";
import { useAppSelector } from "redux/hooks";
import { selectDeviceId } from "module/device/redux/deviceSlice";
import GitInfo from "react-git-info/macro";
import useDeleteAllUserData from "module/staff/useDeleteAllUserData";
import { selectUser } from "module/auth/redux/authSlice";

const AppInfoContainer: React.FC = () => {
  const deviceId = useAppSelector(selectDeviceId);
  const gitCommitHash = GitInfo().commit.hash;
  const deleteAllUserData = useDeleteAllUserData();
  const staffId = useAppSelector(selectUser).id;

  const localStorageSize = () => {
    let total = 0;
    // eslint-disable-next-line no-restricted-syntax
    for (const item in localStorage) {
      // eslint-disable-next-line no-prototype-builtins
      if (localStorage.hasOwnProperty(item)) {
        total += (localStorage[item].length + item.length) * 2;
      }
    }

    return (total / 1024 / 1024).toFixed(2);
  };

  const localStorageUsage = localStorageSize();

  return (
    <AppInfo
      {...{
        deviceId,
        gitCommitHash,
        deleteAllUserData,
        staffId,
        localStorageUsage,
      }}
    />
  );
};

export default React.memo(AppInfoContainer);
