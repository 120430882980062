import React from "react";
import { EntityFieldMetaDataContextInterface } from "module/entity/context/fieldMetaData/EntityFieldMetaDataContext";

export type AssessmentObjectiveFieldMetaDataContextInterface =
  EntityFieldMetaDataContextInterface;

const AssessmentObjectiveFieldMetaDataContext = React.createContext(
  <AssessmentObjectiveFieldMetaDataContextInterface>{}
);

export default AssessmentObjectiveFieldMetaDataContext;
