import React, { useContext, useMemo } from "react";
import ToggleContext from "module/toggle/context/ToggleContext";
import { EntityType } from "module/entity/types";
import InventoryCheckContext from "module/inventoryCheck/context/InventoryCheckContext";
import { InventoryCheckInterface } from "module/inventoryCheck/types";
import MyInventoryCheckListTableRow from "./MyInventoryCheckListTableRow";

const MyInventoryCheckListTableRowContainer: React.FC = () => {
  const { entity } = useContext(InventoryCheckContext);
  const { isSelected: isSelectedHelper } = useContext(ToggleContext);

  const inventoryCheck = entity as InventoryCheckInterface;

  const isSelected = useMemo(
    () =>
      isSelectedHelper({
        id: inventoryCheck.id,
        type: EntityType.inventoryCheck,
      }),
    [isSelectedHelper, inventoryCheck.id]
  );

  if (inventoryCheck == null) {
    return null;
  }

  return <MyInventoryCheckListTableRow {...{ inventoryCheck, isSelected }} />;
};

export default React.memo(MyInventoryCheckListTableRowContainer);
