import React, { useContext, useCallback } from "react";
import StockArticleSelectContainer from "module/stockArticle/enrichedComponent/StockArticleSelectContainer";
import StockArticleTextContainer from "module/stockArticle/enrichedComponent/StockArticleTextContainer";
import MaintenanceTaskPartContext from "module/maintenanceTaskPart/context/MaintenanceTaskPartContext";
import MaintenanceTaskPartChangeContext from "module/maintenanceTaskPart/change/MaintenanceTaskPartChangeContext";
import MaintenanceTaskPartFieldMetaDataContext from "module/maintenanceTaskPart/validation/fieldMetaData/MaintenanceTaskPartFieldMetaDataContext";
import { MaintenanceTaskPartInterface } from "module/maintenanceTaskPart/types";
import { MAINTENANCE_TASK_PART_STOCK_ARTICLE_FIELD_NAME } from "module/maintenanceTaskPart/constants";
import { getFieldMetaDataWithErrorHandling } from "module/fieldMetaData/getFieldMetaData";

const MaintenanceTaskPartStockArticleContainer: React.FC = () => {
  const { entity } = useContext(MaintenanceTaskPartContext);
  const { change: changeHelper } = useContext(MaintenanceTaskPartChangeContext);
  const { getFieldMetaData } = useContext(
    MaintenanceTaskPartFieldMetaDataContext
  );

  const part = entity as MaintenanceTaskPartInterface;

  const change = useCallback(
    (stockArticleId?: number) => {
      changeHelper({ stockArticleId });
    },
    [changeHelper]
  );

  const { warnings, errors, isEditable } = getFieldMetaDataWithErrorHandling(
    () => getFieldMetaData(MAINTENANCE_TASK_PART_STOCK_ARTICLE_FIELD_NAME)
  );

  const value = part.stockArticleId;

  if (isEditable) {
    return (
      <StockArticleSelectContainer
        {...{
          value,
          errors,
          update: change,
        }}
      />
    );
  }

  return <StockArticleTextContainer {...{ value, errors, warnings }} />;
};

export default React.memo(MaintenanceTaskPartStockArticleContainer);
