import React from "react";
import { EntityIdentifier, MyEntityInterface } from "module/entity/types";
import MyEntityCollectionContext, {
  MyEntityCollectionContextInterface,
} from "module/entity/context/entityCollection/MyEntityCollectionContext";

interface Props {
  myEntityCollection: Map<EntityIdentifier, MyEntityInterface>;
  children: React.ReactNode;
}

const EntityCollectionContextProvider: React.FC<Props> = ({
  myEntityCollection,
  children,
}) => {
  const contextValue: MyEntityCollectionContextInterface = {
    myEntityCollection,
  };

  return (
    <MyEntityCollectionContext.Provider value={contextValue}>
      {children}
    </MyEntityCollectionContext.Provider>
  );
};

export default EntityCollectionContextProvider;
