import {
  TrainingSessionToSyncInterface,
  TrainingToSyncInterface,
  MyTrainingInterface,
  MyTrainingSessionInterface,
} from "module/training/types";
import { EntityType } from "module/entity/types";

const mapSessionToSync = (
  objective: MyTrainingSessionInterface
): TrainingSessionToSyncInterface => ({
  identifier: {
    type: objective.entityIdentifier.type as EntityType.trainingSession,
    id: objective.entityIdentifier.backendId,
    frontendId: objective.entityIdentifier.id,
  },
  data: {
    ...objective.data,
    id: objective.data.backendId,
  },
  raw: objective.raw,
});

export const mapTrainingToSync = (
  training: MyTrainingInterface,
  objectives: MyTrainingSessionInterface[]
): TrainingToSyncInterface => {
  return {
    identifier: {
      type: training.entityIdentifier.type as EntityType.training,
      id: training.entityIdentifier.backendId ?? null,
      frontendId: training.entityIdentifier.id,
    },
    data: {
      ...training.data,
      id: training.data.backendId,
      sessions: objectives.map(mapSessionToSync),
    },
    raw: training.raw,
  };
};
