import React, { useContext, useCallback } from "react";
import ToggleContext from "module/toggle/context/ToggleContext";
import useEntitySync from "module/sync/useEntitySync";
import ListToolbarButton from "component/listToolbar/ListToolbarButton";
import t from "module/translations";
import PublishIcon from "@mui/icons-material/Publish";

const ListToolbarSyncButtonContainer: React.FC = () => {
  const { selectedItems, setSelectedItems } = useContext(ToggleContext);
  const { sync: syncHelper } = useEntitySync();

  const onClick = useCallback(async () => {
    await syncHelper(selectedItems);
    setSelectedItems([]);
  }, [selectedItems, setSelectedItems, syncHelper]);

  return (
    <ListToolbarButton
      {...{ title: t.syncText, icon: <PublishIcon />, onClick }}
    />
  );
};

export default React.memo(ListToolbarSyncButtonContainer);
