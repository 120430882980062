import React, { useCallback } from "react";
import TableCell from "@mui/material/TableCell";
import Checkbox from "@mui/material/Checkbox";
import t from "module/translations";
import TableHeadRow from "component/table/tableHeadRow/TableHeadRow";
import TableHead from "@mui/material/TableHead";

interface Props {
  toggleAll: (checked: boolean) => void;
  areAllSelected: boolean;
  isAnySelected: boolean;
  hasPermissionToDownload: boolean;
}

const CompetencyManagementListTableHead: React.FC<Props> = ({
  toggleAll,
  areAllSelected,
  isAnySelected,
  hasPermissionToDownload,
}) => {
  const handleToggleAll = useCallback(
    (event: { target: { checked: boolean } }) =>
      toggleAll(event.target.checked),
    [toggleAll]
  );

  return (
    <TableHead>
      <TableHeadRow>
        {hasPermissionToDownload && (
          <TableCell padding="checkbox">
            <Checkbox
              indeterminate={isAnySelected}
              checked={areAllSelected}
              onChange={handleToggleAll}
            />
          </TableCell>
        )}
        <TableCell align="left">
          {t.competencyManagement.reportingNumber}
        </TableCell>
        <TableCell align="left">{t.competencyManagement.nameText}</TableCell>
        <TableCell>{t.competencyManagement.assigneeText}</TableCell>
        <TableCell align="left">{t.competencyManagement.trainerText}</TableCell>
        <TableCell align="left">{t.competencyManagement.typeText}</TableCell>
        <TableCell>{t.competencyManagement.scheduledDateText}</TableCell>
        <TableCell>{t.competencyManagement.stateText}</TableCell>
      </TableHeadRow>
    </TableHead>
  );
};

export default React.memo(CompetencyManagementListTableHead);
