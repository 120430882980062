import React, { useState, useCallback, useMemo } from "react";
import ToggleContext, {
  ToggleContextInterface,
} from "module/toggle/context/ToggleContext";
import { EntityIdentifier } from "module/entity/types";

interface ToggleContextProviderProps {
  itemsAllowedToToggle: EntityIdentifier[];
  children: React.ReactNode;
}

const ToggleContextProvider: React.FC<ToggleContextProviderProps> = ({
  itemsAllowedToToggle,
  children,
}) => {
  const [selectedItems, setSelectedItems] = useState<EntityIdentifier[]>([]);
  const selectedItemsCount = selectedItems.length;
  const itemsAllowedToToggleCount = itemsAllowedToToggle.length;

  const areAllSelected = useMemo(
    () =>
      itemsAllowedToToggleCount > 0 &&
      selectedItemsCount === itemsAllowedToToggleCount,
    [itemsAllowedToToggleCount, selectedItemsCount]
  );
  const isAnySelected = useMemo(
    () =>
      selectedItemsCount > 0 && selectedItemsCount < itemsAllowedToToggleCount,
    [itemsAllowedToToggleCount, selectedItemsCount]
  );

  const areEqual = useCallback(
    (item1: EntityIdentifier, item2: EntityIdentifier) =>
      item1.id === item2.id && item1.type === item2.type,
    []
  );

  const isSelected = useCallback(
    (item: EntityIdentifier) =>
      selectedItems.find((selectedItem) => areEqual(selectedItem, item)) !=
      null,
    [areEqual, selectedItems]
  );

  const toggleOne = useCallback(
    (item: EntityIdentifier) => {
      // eslint-disable-next-line @typescript-eslint/no-unused-expressions
      const newSelectedItems = isSelected(item)
        ? selectedItems.filter((selectedItem) => !areEqual(selectedItem, item))
        : [...selectedItems, item];
      setSelectedItems(newSelectedItems);
    },
    [areEqual, isSelected, selectedItems]
  );

  const toggleAll = useCallback(
    (checked: boolean) => {
      const newSelectedItems = checked ? itemsAllowedToToggle : [];
      setSelectedItems(newSelectedItems);
    },
    [itemsAllowedToToggle]
  );

  const unselectOne = useCallback(
    (item: EntityIdentifier) => {
      const newSelectedItems = selectedItems.filter(
        (selectedItem) => !areEqual(selectedItem, item)
      );
      setSelectedItems(newSelectedItems);
    },
    [areEqual, selectedItems]
  );

  const canAnyBeSelected = Boolean(itemsAllowedToToggleCount);

  const contextValue: ToggleContextInterface = {
    toggleOne,
    toggleAll,
    isSelected,
    areAllSelected,
    isAnySelected,
    selectedItems,
    selectedItemsCount,
    itemsAllowedToToggleCount,
    setSelectedItems,
    unselectOne,
    canAnyBeSelected,
  };

  return (
    <ToggleContext.Provider value={contextValue}>
      {children}
    </ToggleContext.Provider>
  );
};

export default ToggleContextProvider;
