import axios from "axios";
import { mapTraining } from "api/competencyManagement/mappers";
import { MyTrainingInterface } from "module/training/types";

const getTrainingDetails = async (
  trainingId: number
): Promise<MyTrainingInterface> => {
  const response = await axios.get(
    `/api/competency-managements/trainings/${trainingId}`
  );
  return mapTraining(response.data);
};

export default getTrainingDetails;
