import React, { useContext } from "react";
import useMyTrainingState from "module/training/state/useMyTrainingState";
import { TrainingInterface } from "module/training/types";
import TrainingContext from "module/training/context/TrainingContext";
import MyTrainingStateContext, {
  TrainingStateContextInterface,
} from "module/training/state/context/TrainingStateContext";

interface Prop {
  children: React.ReactNode;
}
const MyTrainingStateContextProvider: React.FC<Prop> = ({ children }) => {
  const { entity } = useContext(TrainingContext);

  const {
    isStateScheduledOrLower,
    isEditable: isStateEditable,
    options: stateOptions,
  } = useMyTrainingState(entity as TrainingInterface);

  const contextValue: TrainingStateContextInterface = {
    isStateScheduledOrLower,
    isStateEditable,
    stateOptions,
  };

  return (
    <MyTrainingStateContext.Provider value={contextValue}>
      {children}
    </MyTrainingStateContext.Provider>
  );
};

export default MyTrainingStateContextProvider;
