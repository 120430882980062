import React, { useEffect, useState, useCallback } from "react";
import useDebounce from "toolkit/useDebounce";
import InputAdornment from "@mui/material/InputAdornment";
import Input from "@mui/material/Input";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import XValidationErrorMessage from "component/validationErrorMessage/XValidationErrorMessage";
import XValidationWarningMessage from "component/validationWarningMessage/XValidationWarningMessage";

interface Props {
  label?: string;
  value?: number;
  change: (newValue?: number) => void;
  errors: string[];
  warnings: string[];
  unit?: string;
}

const NumberField: React.FC<Props> = ({
  label,
  value,
  change,
  errors,
  warnings,
  unit,
}) => {
  const [tempValue, setTempValue] = useState<number | undefined>(value);

  useEffect(() => {
    setTempValue(value);
  }, [value]);

  const debounceHandleChange = useDebounce(change);

  const handleChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const newValue =
        event.target.value == null || !event.target.value.length
          ? undefined
          : Number(event.target.value);
      setTempValue(newValue);
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      debounceHandleChange(newValue);
    },
    [debounceHandleChange]
  );

  const hasError = Boolean(errors.length);

  return (
    <FormControl error={hasError} variant="standard">
      {label && <InputLabel>{label}</InputLabel>}
      <Input
        {...{
          type: "number",
          value: tempValue,
          fullWidth: true,
          onChange: handleChange,
          error: hasError,
          ...(unit
            ? {
                endAdornment: (
                  <InputAdornment position="end">{unit}</InputAdornment>
                ),
              }
            : {}),
        }}
      />
      {errors && <XValidationErrorMessage {...{ errors }} />}
      {warnings && <XValidationWarningMessage {...{ warnings }} />}
    </FormControl>
  );
};

export default React.memo(NumberField);
