import { useCallback } from "react";
import { useAppSelector } from "redux/hooks";
import selectEntity from "module/staff/redux/selector/selectEntity";
import { EntityType } from "module/entity/types";
import { MyAssessmentInterface } from "module/assessment/types";

interface Result {
  getReadableIdentifier: (id: string) => string;
}

const useMyAssessmentReadableIdentifier = (): Result => {
  const selectEntityHelper = useAppSelector(selectEntity);
  const getReadableIdentifier = useCallback(
    (id: string): string => {
      const myEntity = selectEntityHelper({
        id,
        type: EntityType.assessment,
      }) as MyAssessmentInterface;
      return myEntity.data.name;
    },
    [selectEntityHelper]
  );

  return {
    getReadableIdentifier,
  };
};

export default useMyAssessmentReadableIdentifier;
