import { useCallback } from "react";
import { EntityFactory } from "module/entity/useEntityAbstractFactory";
import { getPathToAssessmentDetails } from "module/assessment/helper/getPath";
import useAssessmentIsSyncAvailable from "module/assessment/sync/useAssessmentIsSyncAvailable";
import useAssessmentSyncHelper from "module/assessment/sync/useAssessmentSyncHelper";
import useMyAssessmentReadableIdentifier from "module/assessment/readableIdentifier/useMyAssessmentReadableIdentifier";
import t from "module/translations";
import { getAssessmentSyncUnavailableReasonTranslation } from "module/assessment/helper/getAssessmentSyncUnavailableReasonTranslation";
import { AssessmentSyncUnavailableReason } from "module/assessment/types";

const useAssessmentFactory = (): EntityFactory => {
  const { isAssessmentSyncAvailable } = useAssessmentIsSyncAvailable();
  const { geAssessmentMappedToSync } = useAssessmentSyncHelper();
  const { getReadableIdentifier } = useMyAssessmentReadableIdentifier();

  const getPathToMyGroupedDetails = useCallback((): string => {
    throw new Error("Not supported.");
  }, []);

  const areSimilar = useCallback((): boolean => {
    throw new Error("Not supported.");
  }, []);

  const getSyncUnavailableReasonText = useCallback(
    (reason: string): string =>
      getAssessmentSyncUnavailableReasonTranslation(
        reason as AssessmentSyncUnavailableReason
      ),
    []
  );

  return {
    getPathToMyDetails: getPathToAssessmentDetails,
    getPathToMyGroupedDetails,
    areSimilar,
    isSyncAvailable: isAssessmentSyncAvailable,
    getMappedToSync: geAssessmentMappedToSync,
    getReadableIdentifier,
    readableType: t.competencyManagement.assessmentText,
    getSyncUnavailableReasonText,
  };
};

export default useAssessmentFactory;
