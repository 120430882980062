import React from "react";
import t from "module/translations";
import StickyPaper from "component/sticky/StickyPaper";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import TrainingNameFieldContainer from "module/training/enrichedComponent/nameField/TrainingNameFieldContainer";
import TrainingAssigneeContainer from "module/training/enrichedComponent/assigneeField/TrainingAssigneeContainer";
import TrainingModuleFieldContainer from "module/training/enrichedComponent/moduleField/TrainingModuleFieldContainer";
import TrainingTrainerFieldContainer from "module/training/enrichedComponent/trainerField/TrainingTrainerFieldContainer";
import TrainingRemarksFieldContainer from "module/training/enrichedComponent/remarksField/TrainingRemarksFieldContainer";
import TrainingStateLabelFieldContainer from "module/training/enrichedComponent/stateField/TrainingStateLabelFieldContainer";
import TrainingExpectedDateFieldContainer from "module/training/enrichedComponent/expectedDateField/TrainingExpectedDateFieldContainer";
import TrainingScheduledDateFieldContainer from "module/training/enrichedComponent/scheduledDateField/TrainingScheduledDateFieldContainer";
import TrainingCompletionStartDateTimeFieldContainer from "module/training/enrichedComponent/completionStartDateTimeField/TrainingCompletionStartDateTimeFieldContainer";
import TrainingCompletionEndDateTimeFieldContainer from "module/training/enrichedComponent/completionEndDateTimeField/TrainingCompletionEndDateTimeFieldContainer";
import TrainingReportingNumberFieldContainer from "module/training/enrichedComponent/reportingNumberField/TrainingReportingNumberFieldContainer";

interface Props {
  isStateEditable: boolean;
}

const MyTrainingDetailsOverview: React.FC<Props> = ({ isStateEditable }) => {
  return (
    <StickyPaper>
      <Grid container spacing={3}>
        <Grid item xs={6}>
          <TrainingReportingNumberFieldContainer />
        </Grid>
        <Grid item xs={6}>
          <TrainingNameFieldContainer />
        </Grid>
        <Grid item xs={6}>
          <TrainingAssigneeContainer />
        </Grid>
        <Grid item xs={6}>
          <TrainingModuleFieldContainer />
        </Grid>
        <Grid item xs={6}>
          <TrainingTrainerFieldContainer />
        </Grid>
      </Grid>
      <TrainingRemarksFieldContainer />
      <Typography variant="h6" gutterBottom>
        {t.competencyManagement.training.scheduledDateHeader}
      </Typography>
      <Grid container spacing={3}>
        <Grid item xs={6}>
          <TrainingExpectedDateFieldContainer />
        </Grid>
        <Grid item xs={6}>
          <TrainingScheduledDateFieldContainer />
        </Grid>
      </Grid>
      <Typography variant="h6" gutterBottom>
        {t.competencyManagement.training.completionDateHeader}
      </Typography>
      <Grid container spacing={3}>
        <Grid item xs={6}>
          <TrainingCompletionStartDateTimeFieldContainer />
        </Grid>
        <Grid item xs={6}>
          <TrainingCompletionEndDateTimeFieldContainer />
        </Grid>
      </Grid>
      {!isStateEditable && <TrainingStateLabelFieldContainer />}
    </StickyPaper>
  );
};

export default React.memo(MyTrainingDetailsOverview);
