import React, { useContext } from "react";
import useMyAssessmentState from "module/assessment/state/useMyAssessmentState";
import { AssessmentInterface } from "module/assessment/types";
import AssessmentContext from "module/assessment/context/AssessmentContext";
import MyAssessmentStateContext, {
  AssessmentStateContextInterface,
} from "module/assessment/state/context/AssessmentStateContext";

interface Prop {
  children: React.ReactNode;
}
const MyAssessmentStateContextProvider: React.FC<Prop> = ({ children }) => {
  const { entity } = useContext(AssessmentContext);

  const {
    isStateScheduledOrLower,
    isEditable: isStateEditable,
    options: stateOptions,
  } = useMyAssessmentState(entity as AssessmentInterface);

  const contextValue: AssessmentStateContextInterface = {
    isStateScheduledOrLower,
    isStateEditable,
    stateOptions,
  };

  return (
    <MyAssessmentStateContext.Provider value={contextValue}>
      {children}
    </MyAssessmentStateContext.Provider>
  );
};

export default MyAssessmentStateContextProvider;
