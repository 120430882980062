import React, { useContext, useCallback, useMemo } from "react";
import AutocompleteField from "component/autocompleteField/AutocompleteField";
import t from "module/translations";
import {
  MaintenanceTaskAssistantType,
  MaintenanceTaskInterface,
} from "module/maintenanceTask/types";
import MaintenanceTaskContext from "module/maintenanceTask/context/MaintenanceTaskContext";
import MaintenanceTaskChangeContext from "module/maintenanceTask/change/MaintenanceTaskChangeContext";
import MaintenanceTaskFieldMetaDataContext from "module/maintenanceTask/validation/fieldMetaData/MaintenanceTaskFieldMetaDataContext";
import { getFieldMetaDataWithErrorHandling } from "module/fieldMetaData/getFieldMetaData";
import { MAINTENANCE_TASK_ASSISTANT_TYPE_FIELD_NAME } from "module/maintenanceTask/constants";
import XLabelField from "component/labelField/XLabelField";
import { AutocompleteOption } from "component/autocompleteField/type";

const MaintenanceTaskAssistantTypeFieldContainer: React.FC = () => {
  const { entity } = useContext(MaintenanceTaskContext);
  const { change: changeHelper } = useContext(MaintenanceTaskChangeContext);
  const { getFieldMetaData } = useContext(MaintenanceTaskFieldMetaDataContext);

  const maintenanceTask = entity as MaintenanceTaskInterface;

  const { warnings, errors, isEditable } = getFieldMetaDataWithErrorHandling(
    () => getFieldMetaData(MAINTENANCE_TASK_ASSISTANT_TYPE_FIELD_NAME)
  );

  const change = useCallback(
    (assistantType?: unknown) => {
      changeHelper({
        assistantType: assistantType as MaintenanceTaskAssistantType,
        assistantExternalStaffName: undefined,
        assistantExternalStaffCompany: undefined,
        assistantInternalStaffId: undefined,
      });
    },
    [changeHelper]
  );

  const options: AutocompleteOption[] = useMemo(
    () => [
      {
        text: t.maintenanceTask.assistant.type.internalText,
        value: MaintenanceTaskAssistantType.internal,
      },
      {
        text: t.maintenanceTask.assistant.type.externalText,
        value: MaintenanceTaskAssistantType.external,
      },
    ],
    []
  );

  const selected = useMemo(
    () =>
      options.find((o) => o.value === maintenanceTask.assistantType) ??
      undefined,
    [maintenanceTask.assistantType, options]
  );

  return isEditable ? (
    <AutocompleteField
      {...{ options, selected, onChange: change, errors, warnings }}
    />
  ) : (
    <XLabelField
      {...{
        label:
          maintenanceTask.assistantType ===
          MaintenanceTaskAssistantType.internal
            ? t.maintenanceTask.assistant.type.internalText
            : t.maintenanceTask.assistant.type.externalText,
        errors,
        warnings,
      }}
    />
  );
};

export default React.memo(MaintenanceTaskAssistantTypeFieldContainer);
