import React from "react";
import { EntityChangeContextInterface } from "module/entity/context/change/EntityChangeContext";

export type InventoryCheckArticleChangeContextInterface =
  EntityChangeContextInterface;

const InventoryCheckArticleChangeContext = React.createContext(
  <InventoryCheckArticleChangeContextInterface>{}
);

export default InventoryCheckArticleChangeContext;
