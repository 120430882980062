import {
  ApiMaintenanceTaskInterface,
  ApiMaintenanceTaskPartInterface,
} from "api/maintenanceTask/types";
import {
  MaintenanceTaskPartInterface,
  MyMaintenanceTaskPartInterface,
} from "module/maintenanceTaskPart/types";
import { EntityType } from "module/entity/types";

export const mapApiMaintenanceTaskPartToApp = (
  rawMaintenanceTask: ApiMaintenanceTaskInterface,
  raw: ApiMaintenanceTaskPartInterface
): MyMaintenanceTaskPartInterface => {
  const data: MaintenanceTaskPartInterface = {
    ...raw,
    id: `${raw.id}`,
    backendId: raw.id,
    maintenanceTaskId: `${rawMaintenanceTask.id}`,
    relationshipIdentifiers: [],
    relationships: undefined,
  };

  return {
    entityIdentifier: {
      type: EntityType.maintenanceTaskPart,
      id: `${raw.id}`,
      backendId: raw.id,
    },
    data,
    dataWithoutChanges: data,
    raw,
    validationErrors: null,
    validationWarnings: null,
  };
};
