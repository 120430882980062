import React from "react";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import useDateFormat from "toolkit/useDateFormat";
import MaintenanceTaskListTableRowSelectTableCellContainer from "pages/maintenanceTaskList/table/row/selectTableCell/MaintenanceTaskListTableRowSelectTableCellContainer";
import { MaintenanceTaskInterface } from "module/maintenanceTask/types";
import { getMaintenanceTaskStateTranslation } from "module/maintenanceTask/helper/getMaintenanceTaskStateTranslation";
import { getTypeTranslation } from "module/maintenanceTask/helper/getTypeTranslation";
import makeStyles from "@mui/styles/makeStyles";

const useStyles = makeStyles({
  cell: {
    fontWeight: (props: { isHighlighted: boolean; color: string | null }) =>
      props.isHighlighted ? "bold" : "normal",
  },
  markCell: {
    width: "6px",
    backgroundColor: (props: { color: string | null }) => props.color ?? "none",
    padding: 0,
  },
});

interface Props {
  maintenanceTask: MaintenanceTaskInterface;
  rowClick: () => void;
  isSelected: boolean;
  hasPermissionToDownload: boolean;
  isHighlighted: boolean;
  color: string | null;
}

const MaintenanceTaskListTableRow: React.FC<Props> = ({
  maintenanceTask,
  rowClick,
  isSelected,
  hasPermissionToDownload,
  isHighlighted,
  color,
}) => {
  const classes = useStyles({ isHighlighted, color });
  const { formatDateTime } = useDateFormat();

  return (
    <TableRow
      {...{
        hover: true,
        tabIndex: -1,
        role: "checkbox",
        "aria-checked": isSelected,
        selected: isSelected,
        key: maintenanceTask.id,
        onClick: rowClick,
      }}
    >
      <TableCell className={classes.markCell} />
      {hasPermissionToDownload && (
        <MaintenanceTaskListTableRowSelectTableCellContainer />
      )}
      <TableCell className={classes.cell}>
        {maintenanceTask.reportingNumber}
      </TableCell>
      <TableCell align="left" className={classes.cell}>
        {maintenanceTask.name}
      </TableCell>
      <TableCell align="left" className={classes.cell}>
        {getTypeTranslation(maintenanceTask.type)}
      </TableCell>
      <TableCell align="left" className={classes.cell}>
        {maintenanceTask.scheduledStartDate
          ? formatDateTime(maintenanceTask.scheduledStartDate)
          : "-"}
      </TableCell>
      <TableCell className={classes.cell}>{maintenanceTask.window}</TableCell>
      <TableCell className={classes.cell}>
        {maintenanceTask.timeDeviation || "-"}
      </TableCell>
      <TableCell align="left" className={classes.cell}>
        {getMaintenanceTaskStateTranslation(maintenanceTask.state)}
      </TableCell>
    </TableRow>
  );
};
export default MaintenanceTaskListTableRow;
