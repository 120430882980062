import React from "react";
import Divider from "@mui/material/Divider";
import SideMenu from "component/sidemenu/SideMenu";
import ToBeSignedMenuItemContainer from "layout/menu/ToBeSignedMenuSection";
import InventoryCheckMenuSection from "./InventoryCheckMenuSection";
import MaintenanceMenuSection from "./MaintenanceMenuSection";
import CompetencyMenuSection from "./CompetencyMenuSection";
import SafetyMenuSection from "./SafetyMenuSection";

interface Props {
  isOpen: boolean;
  close: () => void;
}

const Menu: React.FC<Props> = ({ isOpen, close }) => {
  return (
    <SideMenu {...{ isOpen, close }}>
      <ToBeSignedMenuItemContainer />
      <Divider />
      <MaintenanceMenuSection />
      <Divider />
      <InventoryCheckMenuSection />
      <Divider />
      <CompetencyMenuSection />
      <Divider />
      <SafetyMenuSection />
    </SideMenu>
  );
};

export default React.memo(Menu);
