import React, { useContext, useEffect, useState, useMemo } from "react";
import MyMaintenanceTaskList from "pages/myMaintenanceTaskList/MyMaintenanceTaskList";
import ToggleContextProvider from "module/toggle/context/ToggleContextProvider";
import SortContext from "module/sort/context/SortContext";
import { sort as sortHelper } from "module/sort/helper";
import useMaintenanceTaskIsSyncAvailable from "module/maintenanceTask/sync/useMaintenanceTaskIsSyncAvailable";
import { getMapFromCollection } from "module/entity/helper/getMapFromCollection";
import { EntityIdentifier, EntityType } from "module/entity/types";
import { MaintenanceTaskInterface } from "module/maintenanceTask/types";
import { MaintenanceTaskSortProperty } from "pages/maintenanceTaskList/types";

interface Props {
  maintenanceTasks: MaintenanceTaskInterface[];
}

const MyMaintenanceTaskListContainer: React.FC<Props> = ({
  maintenanceTasks,
}) => {
  const { sort } = useContext(SortContext);

  const [items, setItems] = useState<MaintenanceTaskInterface[]>([]);

  const { isMaintenanceTaskSyncAvailable } =
    useMaintenanceTaskIsSyncAvailable();

  useEffect(() => {
    const newItems = sortHelper(
      maintenanceTasks,
      sort,
      MaintenanceTaskSortProperty.reportingNumber
    );
    setItems(newItems);
  }, [maintenanceTasks, sort]);

  const entities = useMemo(
    () =>
      getMapFromCollection<MaintenanceTaskInterface>(
        items,
        EntityType.maintenanceTask
      ),
    [items]
  );

  const entityIdentifiers = useMemo(
    () => Array.from(entities.keys()),
    [entities]
  );

  const itemsAllowedToToggle = useMemo(
    () =>
      entityIdentifiers.reduce((result, key) => {
        const canSync = isMaintenanceTaskSyncAvailable(
          (entities.get(key) as MaintenanceTaskInterface).id
        ).isAvailable;
        if (canSync) {
          return [...result, key];
        }
        return result;
      }, [] as EntityIdentifier[]),
    [entities, entityIdentifiers, isMaintenanceTaskSyncAvailable]
  );

  return (
    <ToggleContextProvider {...{ itemsAllowedToToggle }}>
      <MyMaintenanceTaskList {...{ entities }} />
    </ToggleContextProvider>
  );
};

export default React.memo(MyMaintenanceTaskListContainer);
