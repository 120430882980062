import React from "react";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import t from "module/translations";
import useDateFormat from "toolkit/useDateFormat";
import { getTrainingTypeTranslation } from "module/training/helper/getTrainingTypeTranslation";
import StaffNameText from "module/staff/StaffNameText";
import { TrainingInterface } from "module/training/types";
import { getPathToTrainingToBeSignedDetails } from "module/training/helper/getPath";
import LinkWithIcon from "component/link/LinkWithIcon";

interface Props {
  training: TrainingInterface;
}

const ToBeSignedListTableRowTraining: React.FC<Props> = ({ training }) => {
  const { formatDateTime } = useDateFormat();

  return (
    <TableRow
      {...{
        hover: true,
        tabIndex: -1,
        key: training.id,
      }}
    >
      <TableCell>{t.competencyManagement.trainingText}</TableCell>
      <TableCell>
        <LinkWithIcon
          {...{
            label: training.name,
            path: getPathToTrainingToBeSignedDetails(training.id),
          }}
        />
        {training.assigneeId && (
          <StaffNameText {...{ staffId: training.assigneeId }} />
        )}
      </TableCell>
      <TableCell>
        {`${getTrainingTypeTranslation(training.type)} ${
          t.competencyManagement.trainingText
        }`}
      </TableCell>
      <TableCell>{formatDateTime(training.scheduledStartDate)}</TableCell>
    </TableRow>
  );
};

export default React.memo(ToBeSignedListTableRowTraining);
