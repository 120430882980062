import React from "react";
import DeleteButton from "component/button/DeleteButton";
import useMaintenanceTaskProcessStepQuickFixRemove from "module/maintenanceTaskProcessStepQuickFix/remove/useMaintenanceTaskProcessStepQuickFixRemove";

const MaintenanceTaskProcessStepQuickFixRemoveButtonContainer: React.FC =
  () => {
    const { canRemove, remove } = useMaintenanceTaskProcessStepQuickFixRemove();

    if (!canRemove) {
      return null;
    }

    return <DeleteButton {...{ onClick: remove }} />;
  };

export default React.memo(
  MaintenanceTaskProcessStepQuickFixRemoveButtonContainer
);
