import { MaintenanceTaskProcessStepCheckResult } from "module/maintenanceTaskProcessStep/types";
import t from "module/translations";

export const getMaintenanceTaskProcessStepCheckResultTranslation = (
  checkResult: MaintenanceTaskProcessStepCheckResult
): string =>
  ({
    [MaintenanceTaskProcessStepCheckResult.not_set]:
      t.maintenanceTask.processStep.checkResult.notSetText,
    [MaintenanceTaskProcessStepCheckResult.good_yes]:
      t.maintenanceTask.processStep.checkResult.goodYesText,
    [MaintenanceTaskProcessStepCheckResult.good_no]:
      t.maintenanceTask.processStep.checkResult.goodNoText,
  }[checkResult]);
