import { useCallback } from "react";
import { useAppSelector } from "redux/hooks";
import { selectSystemConfiguration } from "module/auth/redux/authSlice";
import moment from "moment";
import { API_DATE_TIME_FORMAT } from "../api/date";

interface Result {
  dateFormat: string;
  dateFormatForMUIPicker: string;
  dateTimeFormatForMUIPicker: string;
  dateFormatForMoment: string;
  dateTimeFormat: string;
  formatDate: (date: string) => string;
  formatDateTime: (date: string) => string;
  getDateStringFromDate: (date: Date) => string;
  getDateTimeStringFromDate: (date: Date) => string;
}

const dateFormatForMUIPickerMap: { [key: string]: string } = {
  "dd-mm-yyyy": "dd-MM-yyyy",
  default: "dd-MM-yyyy",
};

const dateTimeFormatForMUIPickerMap: { [key: string]: string } = {
  "dd-mm-yyyy H:i:s": "dd-MM-yyyy HH:mm:ss",
  default: "dd-MM-yyyy H:i:s",
};

const dateFormatForMomentMap: { [key: string]: string } = {
  "dd-mm-yyyy": "DD-MM-YYYY",
  default: "DD-MM-YYYY",
};

const dateTimeFormatForMomentMap: { [key: string]: string } = {
  "dd-mm-yyyy H:i:s": "DD-MM-YYYY HH:mm:ss",
  default: "DD-MM-YYYY HH:mm:ss",
};

const useDateFormat = (): Result => {
  const { dateFormat, dateTimeFormat } = useAppSelector(
    selectSystemConfiguration
  );

  const dateFormatForMUIPicker =
    dateFormatForMUIPickerMap[dateFormat] ?? dateFormatForMUIPickerMap.default;

  const dateTimeFormatForMUIPicker =
    dateTimeFormatForMUIPickerMap[dateTimeFormat] ??
    dateTimeFormatForMUIPickerMap.default;

  const dateFormatForMoment =
    dateFormatForMomentMap[dateFormat] ?? dateFormatForMomentMap.default;

  const dateTimeFormatForMoment =
    dateTimeFormatForMomentMap[dateTimeFormat] ??
    dateTimeFormatForMomentMap.default;

  const formatDate = useCallback(
    (date: string): string => moment(date).format(dateFormatForMoment),
    [dateFormatForMoment]
  );

  const formatDateTime = useCallback(
    (date: string): string => moment(date).format(dateTimeFormatForMoment),
    [dateTimeFormatForMoment]
  );

  const getDateStringFromDate = useCallback((date: Date): string => {
    return moment(
      `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`
    ).format(API_DATE_TIME_FORMAT);
  }, []);

  const getDateTimeStringFromDate = useCallback((date: Date): string => {
    return moment(
      `${date.getFullYear()}-${
        date.getMonth() + 1
      }-${date.getDate()} ${date.getHours()}:${date.getMinutes()}:${date.getSeconds()}`
    ).format(API_DATE_TIME_FORMAT);
  }, []);

  return {
    dateFormat,
    dateTimeFormat,
    dateFormatForMUIPicker,
    dateTimeFormatForMUIPicker,
    dateFormatForMoment,
    formatDate,
    formatDateTime,
    getDateStringFromDate,
    getDateTimeStringFromDate,
  };
};

export default useDateFormat;
