import React, { useContext, useCallback, useEffect } from "react";
import { useAppDispatch, useAppSelector } from "redux/hooks";
import {
  selectSystem,
  setSystemConfiguration,
} from "module/auth/redux/authSlice";
import OnlineDetectorContext from "module/onlineDetector/OnlineDetectorContext";
import moment from "moment";
import getSystem from "api/auth/getSystem";
import FullScreenLoaderContext from "module/fullScreenLoader/context/FullScreenLoaderContext";

interface Props {
  children: React.ReactNode;
}
const KeepSystemConfigurationFresh: React.FC<Props> = ({ children }) => {
  const dispatch = useAppDispatch();
  const { isOnline } = useContext(OnlineDetectorContext);
  const { shortName } = useAppSelector(selectSystem);
  const { configuration } = useAppSelector(selectSystem);

  const { turnOn: turnLoaderOn, turnOff: turnLoaderOff } = useContext(
    FullScreenLoaderContext
  );

  const shouldRefreshSystemConfig = useCallback((): boolean => {
    if (configuration == null) {
      return true;
    }
    const { dateOfRetrievingData } = configuration;

    return (
      dateOfRetrievingData == null ||
      moment(dateOfRetrievingData).add(1, "hour") < moment()
    );
  }, [configuration]);

  const refreshSystemConfig = useCallback((): void => {
    async function refresh() {
      turnLoaderOn();
      const system = await getSystem(shortName);
      dispatch(setSystemConfiguration(system));
      turnLoaderOff();
    }
    refresh();
  }, [dispatch, shortName, turnLoaderOff, turnLoaderOn]);

  useEffect(() => {
    if (isOnline) {
      if (shouldRefreshSystemConfig()) {
        refreshSystemConfig();
      }
    }
  }, [isOnline, refreshSystemConfig, shouldRefreshSystemConfig]);

  if (configuration == null) {
    return null;
  }
  return <>{children}</>;
};

export default React.memo(KeepSystemConfigurationFresh);
