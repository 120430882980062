import React from "react";
import { useAppSelector } from "redux/hooks";
import { selectArticles } from "module/auth/redux/authSlice";
import XLabelField from "component/labelField/XLabelField";
import t from "module/translations";

interface Props {
  value?: number;
  errors?: string[];
  warnings?: string[];
}

const ArticleTextContainer: React.FC<Props> = ({ value, errors, warnings }) => {
  const articles = useAppSelector(selectArticles);
  const article =
    articles == null || value == null ? undefined : articles[value];
  const label = article ? article.text : undefined;

  return (
    <XLabelField
      {...{
        caption: t.maintenanceTask.processStep.inventoryText,
        label,
        errors,
        warnings,
      }}
    />
  );
};

export default React.memo(ArticleTextContainer);
