import React from "react";
import WifiOffIcon from "@mui/icons-material/WifiOff";
import WifiIcon from "@mui/icons-material/Wifi";

interface Props {
  isOnline: boolean;
}

const InternetConnectionIcon: React.FC<Props> = ({ isOnline }) => {
  return isOnline ? <WifiIcon /> : <WifiOffIcon />;
};

export default React.memo(InternetConnectionIcon);
