import React, { useMemo } from "react";
import MyCompetencyManagementList from "pages/myCompetencyManagementList/MyCompetencyManagementList";
import ToggleContextProvider from "module/toggle/context/ToggleContextProvider";
import { useAppSelector } from "redux/hooks";
import { sortCompetencyManagementItems } from "pages/myCompetencyManagementList/helper";
import { EntityType } from "module/entity/types";
import selectEntitiesByTypes from "module/staff/redux/selector/selectEntitiesByTypes";
import { MyCompetencyManagementInterface } from "module/competencyManagement/types";

const MyCompetencyManagementListContainer: React.FC = () => {
  const myCompetencyManagementItems = useAppSelector(
    selectEntitiesByTypes([
      EntityType.training,
      EntityType.assessment,
      EntityType.trainingCustom,
    ])
  ) as MyCompetencyManagementInterface[];

  const sortedCompetencyManagementItems = useMemo(
    () => sortCompetencyManagementItems(myCompetencyManagementItems),
    [myCompetencyManagementItems]
  );

  const entityIdentifiers = useMemo(
    () =>
      sortedCompetencyManagementItems.map(
        (myCompetencyManagementItem) =>
          myCompetencyManagementItem.entityIdentifier
      ),
    [sortedCompetencyManagementItems]
  );

  return (
    <ToggleContextProvider {...{ itemsAllowedToToggle: entityIdentifiers }}>
      <MyCompetencyManagementList {...{ entityIdentifiers }} />
    </ToggleContextProvider>
  );
};

export default MyCompetencyManagementListContainer;
