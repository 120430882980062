import React, { useContext, useCallback } from "react";
import t from "module/translations";
import { TRAINING_SESSION_ACHIEVED_FIELD_NAME } from "module/trainingSession/constants";
import CheckboxField from "component/checkboxField/CheckboxField";
import XLabelField from "component/labelField/XLabelField";
import { TrainingSessionInterface } from "module/training/types";
import TrainingSessionContext from "module/trainingSession/context/TrainingSessionContext";
import TrainingSessionChangeContext from "module/trainingSession/change/TrainingSessionChangeContext";
import TrainingSessionFieldMetaDataContext from "module/trainingSession/validation/fieldMetaData/TrainingSessionFieldMetaDataContext";
import { getFieldMetaDataWithErrorHandling } from "module/fieldMetaData/getFieldMetaData";

const TrainingSessionAchievedFieldContainer: React.FC = () => {
  const { entity } = useContext(TrainingSessionContext);
  const { change: changeHelper } = useContext(TrainingSessionChangeContext);
  const { getFieldMetaData } = useContext(TrainingSessionFieldMetaDataContext);

  const trainingSessions = entity as TrainingSessionInterface;

  const change = useCallback(
    (achieved: boolean) => {
      changeHelper({ achieved });
    },
    [changeHelper]
  );

  const { warnings, errors, isEditable } = getFieldMetaDataWithErrorHandling(
    () => getFieldMetaData(TRAINING_SESSION_ACHIEVED_FIELD_NAME)
  );

  return isEditable ? (
    <CheckboxField
      {...{
        isChecked: trainingSessions.achieved,
        change,
        errors,
        warnings,
      }}
    />
  ) : (
    <XLabelField
      {...{
        ...(trainingSessions.achieved
          ? {
              label: t.yesText,
            }
          : {}),
        warnings,
        errors,
      }}
    />
  );
};

export default React.memo(TrainingSessionAchievedFieldContainer);
