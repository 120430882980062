import React, { useMemo } from "react";
import SideMenuSection from "component/sidemenu/SideMenuSection";
import SideMenuItem from "component/sidemenu/SideMenuItem";
import t from "module/translations";
import routes from "routes/routes";
import { useAppSelector } from "redux/hooks";
import { EntityIdentifier, EntityType } from "module/entity/types";
import { ReactComponent as SafetyIcon } from "assets/slide.svg";
import MenuIcon from "layout/menu/MenuIcon";
import selectEntity from "module/staff/redux/selector/selectEntity";
import selectOpenItemsByEntityType from "module/staff/redux/selector/selectOpenItemsByEntityType";
import { MyMaintenanceTaskInterface } from "module/maintenanceTask/types";
import { MaintenanceTaskDefinitionType } from "pages/maintenanceTaskList/types";

const SafetyMenuSection: React.FC = () => {
  const selectEntityHelper = useAppSelector(selectEntity);
  const openMaintenanceTasksEntityIdentifier = useAppSelector(
    selectOpenItemsByEntityType(EntityType.maintenanceTask)
  ) as EntityIdentifier[];

  const openedMaintenanceTaskSafetyId = useMemo(
    () =>
      openMaintenanceTasksEntityIdentifier.reduce(
        (result, openEntityIdentifier) => {
          if (result == null) {
            const myMaintenanceTask = selectEntityHelper(
              openEntityIdentifier
            ) as MyMaintenanceTaskInterface;
            if (
              [
                MaintenanceTaskDefinitionType.equipment,
                MaintenanceTaskDefinitionType.sms,
                MaintenanceTaskDefinitionType.audit,
              ].includes(
                myMaintenanceTask.data
                  .definitionType as MaintenanceTaskDefinitionType
              )
            ) {
              return openEntityIdentifier;
            }
          }
          return result;
        },
        null as EntityIdentifier | null
      ),
    [openMaintenanceTasksEntityIdentifier, selectEntityHelper]
  );

  return (
    <SideMenuSection
      {...{
        name: t.sideMenu.safetySection,
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        icon: <MenuIcon {...{ Icon: SafetyIcon }} />,
      }}
    >
      <SideMenuItem
        {...{
          title: routes.safetyMaintenanceTasks.title || "",
          path: routes.safetyMaintenanceTasks.path,
          key: routes.safetyMaintenanceTasks.path,
        }}
      />
      <SideMenuItem
        {...{
          title: routes.mySafetyMaintenanceTasks.title || "",
          path: routes.mySafetyMaintenanceTasks.path,
          key: routes.mySafetyMaintenanceTasks.path,
        }}
      />
      {openedMaintenanceTaskSafetyId && (
        <SideMenuItem
          {...{
            title: routes.myOpenSafetyMaintenanceTaskDetails.title || "",
            path: routes.myOpenSafetyMaintenanceTaskDetails.path,
            key: routes.myOpenSafetyMaintenanceTaskDetails.path,
          }}
        />
      )}
    </SideMenuSection>
  );
};

export default React.memo(SafetyMenuSection);
