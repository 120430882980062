import { EntityIdentifier } from "module/entity/types";
import { MyCompetencyManagementInterface } from "module/competencyManagement/types";

const sortCompetencyManagements = (
  rawCompetencyManagements: MyCompetencyManagementInterface[],
  myStaffId: number
): Map<EntityIdentifier, MyCompetencyManagementInterface> => {
  const competencyManagementItems: Map<
    EntityIdentifier,
    MyCompetencyManagementInterface
  > = new Map();
  const myItems: Map<EntityIdentifier, MyCompetencyManagementInterface> =
    new Map();
  const unassignedItems: Map<
    EntityIdentifier,
    MyCompetencyManagementInterface
  > = new Map();

  rawCompetencyManagements.map(
    // eslint-disable-next-line array-callback-return
    (rawCompetencyManagement) => {
      const key: EntityIdentifier = {
        id: rawCompetencyManagement.entityIdentifier.id,
        type: rawCompetencyManagement.entityIdentifier.type,
      };

      // assigned to me first
      if (rawCompetencyManagement.data.internalTrainerId === myStaffId) {
        myItems.set(key, rawCompetencyManagement);
      } else {
        unassignedItems.set(key, rawCompetencyManagement);
      }
    }
  );

  Array.from(myItems.keys()).map((key) =>
    competencyManagementItems.set(
      key,
      myItems.get(key) as MyCompetencyManagementInterface
    )
  );

  Array.from(unassignedItems.keys()).map((key) =>
    competencyManagementItems.set(
      key,
      unassignedItems.get(key) as MyCompetencyManagementInterface
    )
  );

  return competencyManagementItems;
};

export default sortCompetencyManagements;
