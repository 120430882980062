import t from "module/translations";
import { TrainingState } from "module/training/types";

export const getTrainingStateTranslation = (state: TrainingState): string =>
  ({
    [TrainingState.initScheduled]:
      t.competencyManagement.training.state.scheduledText,
    [TrainingState.scheduled]:
      t.competencyManagement.training.state.scheduledText,
    [TrainingState.completed]:
      t.competencyManagement.training.state.completedText,
  }[state]);
