import React, { useContext, useCallback } from "react";
import MaintenanceTaskContext from "module/maintenanceTask/context/MaintenanceTaskContext";
import { MaintenanceTaskInterface } from "module/maintenanceTask/types";
import MaintenanceTaskProcessStepContext from "module/maintenanceTaskProcessStep/context/MaintenanceTaskProcessStepContext";
import {
  MaintenanceTaskProcessStepCheckResult,
  MaintenanceTaskProcessStepInterface,
} from "module/maintenanceTaskProcessStep/types";
import { getFieldMetaDataWithErrorHandling } from "module/fieldMetaData/getFieldMetaData";
import MaintenanceTaskProcessStepChangeContext from "module/maintenanceTaskProcessStep/change/MaintenanceTaskProcessStepChangeContext";
import MaintenanceTaskProcessStepFieldMetaDataContext from "module/maintenanceTaskProcessStep/validation/fieldMetaData/MaintenanceTaskProcessStepFieldMetaDataContext";
import { calculateChangesAfterMaintenanceTaskProcessStepMeasureValueChange } from "module/maintenanceTaskProcessStep/helper/calculateChangesAfterMaintenanceTaskProcessStepMeasureValueChange";
import { MAINTENANCE_TASK_PROCESS_STEP_MEASURE_VALUE_FIELD_NAME } from "module/maintenanceTaskProcessStep/constants";
import MaintenanceTaskProcessStepMeasureValueEditableField from "module/maintenanceTaskProcessStep/enrichedComponent/measureValueField/MaintenanceTaskProcessStepMeasureValueEditableField";
import XLabelField from "component/labelField/XLabelField";
import Typography from "@mui/material/Typography";
import { EntityIdentifier, EntityType } from "module/entity/types";
import {
  removeFromEntityRelationships,
  removeFromMyEntities,
} from "module/staff/redux/staffDataSlice";
import { useAppDispatch, useAppSelector } from "redux/hooks";
import { selectSystem, selectUser } from "module/auth/redux/authSlice";

const MaintenanceTaskProcessStepMeasureFieldContainer: React.FC = () => {
  const { entity: maintenanceTaskEntity } = useContext(MaintenanceTaskContext);
  const maintenanceTask = maintenanceTaskEntity as MaintenanceTaskInterface;

  const { entity } = useContext(MaintenanceTaskProcessStepContext);
  const { change: changeHelper } = useContext(
    MaintenanceTaskProcessStepChangeContext
  );
  const { getFieldMetaData } = useContext(
    MaintenanceTaskProcessStepFieldMetaDataContext
  );

  const maintenanceTaskProcessStep =
    entity as MaintenanceTaskProcessStepInterface;

  const dispatch = useAppDispatch();
  const staffId = useAppSelector(selectUser).id;
  const systemShortName = useAppSelector(selectSystem).shortName;

  const change = useCallback(
    (measureValue?: number): void => {
      let changes =
        calculateChangesAfterMaintenanceTaskProcessStepMeasureValueChange(
          maintenanceTask,
          {
            measureValue,
            measureValueMin: maintenanceTaskProcessStep.measureValueMin,
            measureValueMax: maintenanceTaskProcessStep.measureValueMax,
          }
        );

      if (changes.checkResult != null) {
        if (
          changes.checkResult === MaintenanceTaskProcessStepCheckResult.good_yes
        ) {
          changes = { ...changes, remarks: "" };
        }

        if (
          changes.checkResult !==
            MaintenanceTaskProcessStepCheckResult.good_no &&
          maintenanceTaskProcessStep.quickFix != null
        ) {
          const maintenanceTaskProcessStepEntityIdentifier: EntityIdentifier = {
            id: maintenanceTaskProcessStep.id,
            type: EntityType.maintenanceTaskProcessStep,
          };

          changes = { ...changes, quickFix: null };
          dispatch(
            removeFromEntityRelationships({
              staffId,
              systemShortName,
              baseEntityIdentifier: maintenanceTaskProcessStepEntityIdentifier,
              entityIdentifierToRemove: maintenanceTaskProcessStep.quickFix,
            })
          );
          dispatch(
            removeFromMyEntities({
              staffId,
              systemShortName,
              entityIdentifiers: [maintenanceTaskProcessStep.quickFix],
            })
          );
        }
      }
      changeHelper(changes);
    },
    [
      changeHelper,
      dispatch,
      maintenanceTask,
      maintenanceTaskProcessStep.id,
      maintenanceTaskProcessStep.measureValueMax,
      maintenanceTaskProcessStep.measureValueMin,
      maintenanceTaskProcessStep.quickFix,
      staffId,
      systemShortName,
    ]
  );

  const { warnings, errors, isEditable } = getFieldMetaDataWithErrorHandling(
    () =>
      getFieldMetaData(MAINTENANCE_TASK_PROCESS_STEP_MEASURE_VALUE_FIELD_NAME)
  );

  if (maintenanceTaskProcessStep.measureName == null) {
    return <>-</>;
  }

  if (isEditable) {
    return (
      <MaintenanceTaskProcessStepMeasureValueEditableField
        {...{
          name: maintenanceTaskProcessStep.measureName,
          value: maintenanceTaskProcessStep.measureValue,
          change,
          unit: maintenanceTaskProcessStep.measureUnit,
          errors,
          warnings,
        }}
      />
    );
  }

  return (
    <XLabelField {...{ warnings, errors }}>
      <Typography variant="body2" gutterBottom>
        <div>{maintenanceTaskProcessStep.measureName}</div>
        <div>
          {maintenanceTaskProcessStep.measureValue == null
            ? "- "
            : maintenanceTaskProcessStep.measureValue}
          {maintenanceTaskProcessStep.measureUnit}
        </div>
      </Typography>
    </XLabelField>
  );
};
export default React.memo(MaintenanceTaskProcessStepMeasureFieldContainer);
