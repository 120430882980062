import React from "react";

interface Props {
  Icon: React.ReactNode;
}

const MenuIcon: React.FC<Props> = ({ Icon }) => {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  return <Icon {...{ height: 20, width: 20, fill: "gray" }} />;
};

export default React.memo(MenuIcon);
