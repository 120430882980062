import React from "react";
import Caption from "component/caption/Caption";
import { Theme } from "@mui/material/styles";
import makeStyles from "@mui/styles/makeStyles";
import t from "module/translations";
import { EntityIdentifier } from "module/entity/types";
import MaintenanceTaskAttachmentListItemContainer from "module/maintenanceTaskAttachment/enrichedComponent/list/item/MaintenanceTaskAttachmentListItemContainer";
import AddAttachmentButton from "module/attachment/enrichedComponent/AddAttachmentButton";
import { getEntityIdentifierKey } from "module/entity/helper/getEntityIdentifierKey";

const useStyles = makeStyles((theme: Theme) => ({
  list: {
    width: "100%",
    display: "flex",
    "& > *": {
      margin: theme.spacing(2),
    },
    justifyContent: "flex-start",
    flexWrap: "wrap",
  },
}));

interface Props {
  canAdd: boolean;
  add: (mime: string, data: string) => void;
  entityIdentifiers: EntityIdentifier[];
}

const MaintenanceTaskAttachmentList: React.FC<Props> = ({
  canAdd,
  add,
  entityIdentifiers,
}) => {
  const classes = useStyles();
  return (
    <div>
      <Caption {...{ text: t.attachment.attachmentsText }} />
      <div className={classes.list}>
        {entityIdentifiers.map((entityIdentifier) => (
          <MaintenanceTaskAttachmentListItemContainer
            {...{
              entityIdentifier,
              key: getEntityIdentifierKey(entityIdentifier),
            }}
          />
        ))}
        {canAdd && <AddAttachmentButton {...{ add }} />}
      </div>
    </div>
  );
};

export default React.memo(MaintenanceTaskAttachmentList);
