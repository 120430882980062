import {
  ApiAssessmentInterface,
  ApiTrainingCustomInterface,
  ApiTrainingInterface,
} from "api/competencyManagement/types";
import { EntityType } from "module/entity/types";
import {
  mapAssessment,
  mapTraining,
  mapTrainingCustom,
} from "api/competencyManagement/mappers";
import { MyTrainingInterface } from "module/training/types";
import { MyAssessmentInterface } from "module/assessment/types";
import { MyTrainingCustomInterface } from "module/trainingCustom/types";
import { MyMaintenanceTaskInterface } from "module/maintenanceTask/types";
import { mapApiMaintenanceTaskToApp } from "module/maintenanceTask/helper/mapApiMaintenanceTaskToApp";
import { ApiMaintenanceTaskInterface } from "api/maintenanceTask/types";

const mapItem = (raw: ApiToBeSignedInterface): MyToBeSignedInterface => {
  const { entityType } = raw;
  if (entityType === EntityType.training) {
    return mapTraining(raw as ApiTrainingInterface);
  }
  if (entityType === EntityType.assessment) {
    return mapAssessment(raw as ApiAssessmentInterface);
  }
  if (entityType === EntityType.trainingCustom) {
    return mapTrainingCustom(raw as ApiTrainingCustomInterface);
  }
  if (entityType === EntityType.maintenanceTask) {
    return mapApiMaintenanceTaskToApp(raw as ApiMaintenanceTaskInterface);
  }
  throw Error("Type is not supported");
};

type ApiToBeSignedInterface =
  | ApiAssessmentInterface
  | ApiTrainingInterface
  | ApiTrainingCustomInterface
  | ApiMaintenanceTaskInterface;

type MyToBeSignedInterface =
  | MyTrainingInterface
  | MyAssessmentInterface
  | MyTrainingCustomInterface
  | MyMaintenanceTaskInterface;

interface ApiGetResponse {
  items: ApiToBeSignedInterface[];
  count: number;
}

export interface GetResponse {
  data: MyToBeSignedInterface[];
  count: number;
}

export const mapApiResponse = (rawData: ApiGetResponse): GetResponse => {
  return {
    data: rawData.items.map(mapItem),
    count: rawData.count,
  };
};
