import {
  AttachmentInterface,
  AttachmentToSyncInterface,
} from "module/attachment/types";

export const mapAttachmentToSync = (
  attachment: AttachmentInterface
): AttachmentToSyncInterface => {
  if (attachment.backendId) {
    return {
      id: attachment.backendId,
    };
  }

  return {
    base64: attachment.base64 as string,
  };
};
