import React, { useContext, useMemo } from "react";
import XLabelField from "component/labelField/XLabelField";
import t from "module/translations";
import InventoryCheckContext from "module/inventoryCheck/context/InventoryCheckContext";
import { InventoryCheckInterface } from "module/inventoryCheck/types";
import { useAppSelector } from "redux/hooks";
import { selectStaffById } from "module/auth/redux/authSlice";

const InventoryCheckAssigneeFieldContainer: React.FC = () => {
  const { entity } = useContext(InventoryCheckContext);
  const { assigneeId } = entity as InventoryCheckInterface;

  const selectStaffByIdHelper = useAppSelector(selectStaffById);
  const staff = useMemo(
    () => (assigneeId ? selectStaffByIdHelper(assigneeId) : null),
    [selectStaffByIdHelper, assigneeId]
  );

  const label =
    staff == null
      ? undefined
      : `${staff.name} ${staff.isActive ? "" : ` (${t.staffDeletedText})`}`;

  return (
    <XLabelField
      {...{
        caption: t.inventoryCheck.assigneeText,
        label,
      }}
    />
  );
};

export default React.memo(InventoryCheckAssigneeFieldContainer);
