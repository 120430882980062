import React from "react";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import { Link as RouterLink } from "react-router-dom";
import SideMenuItemBadge from "component/sidemenu/SideMenuItemBadge";

interface Props {
  title: string;
  path: string;
  icon?: JSX.Element;
  badgeContent?: string | number;
}

const SideMenuItem: React.FC<Props> = ({ title, path, icon, badgeContent }) => {
  return (
    <ListItemButton component={RouterLink} to={path}>
      {icon && <ListItemIcon>{icon}</ListItemIcon>}

      <ListItemText primary={title} />
      {badgeContent && <SideMenuItemBadge text={badgeContent} />}
    </ListItemButton>
  );
};

export default React.memo(SideMenuItem);
