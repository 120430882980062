import React from "react";
import { EntityValidationResultContextInterface } from "module/entity/context/validationResult/EntityValidationResultContext";

export type TrainingValidationResultContextInterface =
  EntityValidationResultContextInterface;

const TrainingValidationResultContext = React.createContext(
  <TrainingValidationResultContextInterface>{}
);

export default TrainingValidationResultContext;
