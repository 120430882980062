import React from "react";
import Paper from "@mui/material/Paper";
import makeStyles from "@mui/styles/makeStyles";
import clsx from "clsx";
import { useStickyStyles } from "component/sticky/styles";

const useStyles = makeStyles(() => ({
  root: {
    padding: "24px",
  },
}));

interface Props {
  children: React.ReactNode;
}

const StickyPaper: React.FC<Props> = ({ children }) => {
  const classes = useStyles();
  const stickyClasses = useStickyStyles();
  return (
    <Paper className={clsx(classes.root, stickyClasses.sticky)}>
      {children}
    </Paper>
  );
};

export default React.memo(StickyPaper);
