import { EntityIdentifier, MyEntityInterface } from "module/entity/types";

export const mergeEntityMaps = (
  array: Map<EntityIdentifier, MyEntityInterface>[]
): Map<EntityIdentifier, MyEntityInterface> => {
  const result: Map<EntityIdentifier, MyEntityInterface> = new Map();

  // eslint-disable-next-line array-callback-return
  array.map((singleMap) => {
    // eslint-disable-next-line array-callback-return
    Array.from(singleMap.keys()).map((singleMapSingleKey) => {
      result.set(
        singleMapSingleKey,
        singleMap.get(singleMapSingleKey) as MyEntityInterface
      );
    });
  });

  return result;
};
