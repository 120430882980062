import React from "react";
import TableBody from "@mui/material/TableBody";
import TableContainer from "component/table/TableContainer";
import Table from "component/table/Table";
import ListToolbarContainer from "module/listToolbar/ListToolbarContainer";
import t from "module/translations";
import { StockArticle } from "../../module/stockArticle/types";
import StockArticleListTableRow from "../../module/stockArticle/enrichedComponent/StockArticleListTableRow";
import StockArticleListTableHead from "../../module/stockArticle/enrichedComponent/StockArticleListTableHead";
import InventoryListFilters from "./InventoryListFilters";

interface Props {
  stockArticles: StockArticle[];
}

const InventoryList: React.FC<Props> = ({ stockArticles }) => {
  return (
    <TableContainer>
      <ListToolbarContainer
        {...{
          title: t.routes.inventory,
          isDownloadAvailable: false,
          filters: <InventoryListFilters />,
        }}
      />
      <Table>
        <StockArticleListTableHead />
        <TableBody>
          <>
            {stockArticles.map((stockArticle) => (
              <StockArticleListTableRow
                {...{
                  key: stockArticle.id,
                  stockArticle,
                }}
              />
            ))}
          </>
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default React.memo(InventoryList);
