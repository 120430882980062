import React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import StickyPaperTableContainer from "component/sticky/StickyPaperTableContainer";
import t from "module/translations";
import TrainingSessionContextProvider from "module/trainingSession/context/TrainingSessionContextProvider";
import { EntityIdentifier } from "module/entity/types";
import ButtonsContainer from "component/button/ButtonsContainer";
import ElementWithConfirmation from "component/elementWithConfirmation/ElementWithConfirmation";
import SignButton from "component/button/SignButton";
import TrainingSessionTableRowContainer from "module/trainingSession/enrichedComponent/tableRow/TrainingSessionTableRowContainer";
import TrainingSessionTableHead from "module/trainingSession/enrichedComponent/tableHead/TrainingSessionTableHead";
import { getEntityIdentifierKey } from "module/entity/helper/getEntityIdentifierKey";

interface Props {
  sessionsIdentifiers: EntityIdentifier[];
  sign: () => Promise<void>;
}

const TrainingToBeSignedDetailsSessions: React.FC<Props> = ({
  sessionsIdentifiers,
  sign,
}) => {
  return (
    <>
      <StickyPaperTableContainer>
        <Table stickyHeader>
          <TrainingSessionTableHead />
          <TableBody>
            {sessionsIdentifiers.map((session) => (
              <TrainingSessionContextProvider
                {...{
                  entityIdentifier: session,
                  key: getEntityIdentifierKey(session),
                }}
              >
                <TrainingSessionTableRowContainer />
              </TrainingSessionContextProvider>
            ))}
          </TableBody>
        </Table>
      </StickyPaperTableContainer>
      <ButtonsContainer>
        <ElementWithConfirmation
          {...{
            confirmationDialogTitle:
              t.competencyManagement.signConfirmationText,
            asyncAction: sign,
          }}
        >
          {(props: { onClick: () => void }) => (
            <SignButton {...{ onClick: props.onClick }} />
          )}
        </ElementWithConfirmation>
      </ButtonsContainer>
    </>
  );
};

export default React.memo(TrainingToBeSignedDetailsSessions);
