import React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import StickyPaperTableContainer from "component/sticky/StickyPaperTableContainer";
import { EntityIdentifier } from "module/entity/types";
import MyTrainingGroupDetailsSessionContextProvider from "pages/myTrainingGroupDetails/sessions/MyTrainingGroupDetailsSessionContextProvider";
import TrainingSessionTableRowContainer from "module/trainingSession/enrichedComponent/tableRow/TrainingSessionTableRowContainer";
import TrainingSessionTableHead from "module/trainingSession/enrichedComponent/tableHead/TrainingSessionTableHead";
import { getEntityIdentifierKey } from "module/entity/helper/getEntityIdentifierKey";

interface Props {
  trainingGroupSessionsIdentifiers: EntityIdentifier[][];
}

const MyTrainingGroupDetailsSessions: React.FC<Props> = ({
  trainingGroupSessionsIdentifiers,
}) => {
  return (
    <StickyPaperTableContainer>
      <Table stickyHeader>
        <TrainingSessionTableHead />
        <TableBody>
          {trainingGroupSessionsIdentifiers.map(
            (trainingGroupSessionEntityIdentifiers) => (
              <MyTrainingGroupDetailsSessionContextProvider
                {...{
                  entityIdentifiers: trainingGroupSessionEntityIdentifiers,
                  key: getEntityIdentifierKey(
                    trainingGroupSessionEntityIdentifiers[0]
                  ),
                }}
              >
                <TrainingSessionTableRowContainer />
              </MyTrainingGroupDetailsSessionContextProvider>
            )
          )}
        </TableBody>
      </Table>
    </StickyPaperTableContainer>
  );
};

export default React.memo(MyTrainingGroupDetailsSessions);
