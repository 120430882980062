import React from "react";
import withOnlineDetector from "module/onlineDetector/withOnlineDetector";
import CompetencyManagementListContainer from "pages/competencyManagementList/CompetencyManagementListContainer";
import SortContextProvider from "module/sort/context/SortContextProvider";
import { CompetencyManagementListSortingProperty } from "pages/competencyManagementList/types";
import { SortDirection } from "module/sort/types";
import FilterContextProvider from "module/filter/context/FilterContextProvider";

const CompetencyManagementListPage: React.FC = () => {
  return (
    <SortContextProvider
      {...{
        initialValue: {
          fieldName: CompetencyManagementListSortingProperty.scheduledDate,
          direction: SortDirection.asc,
        },
      }}
    >
      <FilterContextProvider
        {...{
          initialValue: {
            scheduledDate: new Date(),
          },
        }}
      >
        <CompetencyManagementListContainer />
      </FilterContextProvider>
    </SortContextProvider>
  );
};

export default withOnlineDetector(React.memo(CompetencyManagementListPage));
