import { useCallback, useContext, useMemo } from "react";
import { useAppDispatch, useAppSelector } from "redux/hooks";
import { selectSystem, selectUser } from "module/auth/redux/authSlice";
import { MaintenanceTaskInterface } from "module/maintenanceTask/types";
import { MaintenanceTaskProcessStepInterface } from "module/maintenanceTaskProcessStep/types";
import { canAddQuickFixToMaintenanceTaskProcessStep } from "module/maintenanceTaskProcessStepQuickFix/helper/canAddQuickFixToMaintenanceTaskProcessStep";
import { v4 as uuid } from "uuid";
import {
  addToEntityRelationships,
  createEntity,
} from "module/staff/redux/staffDataSlice";
import MaintenanceTaskContext from "module/maintenanceTask/context/MaintenanceTaskContext";
import MaintenanceTaskOpenCloseContext from "module/maintenanceTask/openClose/context/MaintenanceTaskOpenCloseContext";
import MaintenanceTaskStateContext from "module/maintenanceTask/state/context/MaintenanceTaskStateContext";
import MaintenanceTaskProcessStepContext from "module/maintenanceTaskProcessStep/context/MaintenanceTaskProcessStepContext";
import { EntityIdentifier, EntityType } from "module/entity/types";

import { API_DATE_TIME_FORMAT } from "api/date";
import moment from "moment";
import {
  MaintenanceTaskProcessStepQuickFixInterface,
  MyMaintenanceTaskProcessStepQuickFixInterface,
} from "module/maintenanceTaskProcessStepQuickFix/types";
import MaintenanceTaskProcessStepChangeContext from "module/maintenanceTaskProcessStep/change/MaintenanceTaskProcessStepChangeContext";

interface Result {
  canAdd: boolean;
  add: () => void;
}

const useMaintenanceTaskProcessStepQuickFixAdd = (): Result => {
  const { entity: maintenanceTaskEntity } = useContext(MaintenanceTaskContext);
  const maintenanceTask = maintenanceTaskEntity as MaintenanceTaskInterface;

  const { isOpen: isMaintenanceTaskOpen } = useContext(
    MaintenanceTaskOpenCloseContext
  );

  const { isStateScheduledOrLower: isMaintenanceTaskStateScheduledOrLower } =
    useContext(MaintenanceTaskStateContext);

  const { entity: maintenanceTaskProcessStepEntity } = useContext(
    MaintenanceTaskProcessStepContext
  );
  const maintenanceTaskProcessStep =
    maintenanceTaskProcessStepEntity as MaintenanceTaskProcessStepInterface;
  const { change: changeMaintenanceTaskProcessStep } = useContext(
    MaintenanceTaskProcessStepChangeContext
  );

  const canAdd = useMemo(
    () =>
      canAddQuickFixToMaintenanceTaskProcessStep({
        isMaintenanceTaskOpen,
        isMaintenanceTaskStateScheduledOrLower,
        maintenanceTask,
        maintenanceTaskProcessStep,
      }),
    [
      isMaintenanceTaskOpen,
      isMaintenanceTaskStateScheduledOrLower,
      maintenanceTask,
      maintenanceTaskProcessStep,
    ]
  );

  const dispatch = useAppDispatch();
  const staffId = useAppSelector(selectUser).id;
  const systemShortName = useAppSelector(selectSystem).shortName;

  const add = useCallback((): string => {
    const maintenanceTaskProcessStepEntityIdentifier: EntityIdentifier = {
      id: maintenanceTaskProcessStep.id,
      type: EntityType.maintenanceTaskProcessStep,
    };

    const id = uuid();
    const entityIdentifier = {
      type: EntityType.maintenanceTaskProcessStepQuickFix,
      id,
    };

    const today = moment()
      .set({ hour: 0, minute: 0, second: 0 })
      .format(API_DATE_TIME_FORMAT);

    const data: MaintenanceTaskProcessStepQuickFixInterface = {
      id,
      maintenanceTaskProcessStepId: maintenanceTaskProcessStep.id,
      type: undefined,
      relationships: undefined,
      relationshipIdentifiers: [],
      changedDateTime: today,
    };

    const myEntity: MyMaintenanceTaskProcessStepQuickFixInterface = {
      entityIdentifier,
      data,
      dataWithoutChanges: data,
      validationErrors: null,
      validationWarnings: null,
    };

    dispatch(
      createEntity({
        staffId,
        systemShortName,
        myEntity,
      })
    );

    dispatch(
      addToEntityRelationships({
        staffId,
        systemShortName,
        baseEntityIdentifier: maintenanceTaskProcessStepEntityIdentifier,
        entityIdentifierToAdd: entityIdentifier,
      })
    );
    changeMaintenanceTaskProcessStep({ quickFix: entityIdentifier });

    return id;
  }, [
    changeMaintenanceTaskProcessStep,
    dispatch,
    maintenanceTaskProcessStep.id,
    staffId,
    systemShortName,
  ]);

  return {
    canAdd,
    add,
  };
};

export default useMaintenanceTaskProcessStepQuickFixAdd;
