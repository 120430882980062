import {
  ApiMaintenanceTaskProcessStep,
  ApiMaintenanceTaskProcessStepQuickFix,
} from "api/maintenanceTask/types";
import { EntityType } from "module/entity/types";
import {
  MaintenanceTaskProcessStepQuickFixInterface,
  MyMaintenanceTaskProcessStepQuickFixInterface,
} from "module/maintenanceTaskProcessStepQuickFix/types";

export const mapApiMaintenanceTaskProcessStepQuickFixToApp = (
  rawMaintenanceTaskProcessStep: ApiMaintenanceTaskProcessStep,
  raw: ApiMaintenanceTaskProcessStepQuickFix
): MyMaintenanceTaskProcessStepQuickFixInterface => {
  const data: MaintenanceTaskProcessStepQuickFixInterface = {
    ...raw,
    id: `${raw.id}`,
    backendId: raw.id,
    maintenanceTaskProcessStepId: `${rawMaintenanceTaskProcessStep.id}`,
    relationshipIdentifiers: [],
    relationships: undefined,
  };

  return {
    entityIdentifier: {
      type: EntityType.maintenanceTaskProcessStepQuickFix,
      id: `${raw.id}`,
      backendId: raw.id,
    },
    data,
    dataWithoutChanges: data,
    raw,
    validationErrors: null,
    validationWarnings: null,
  };
};
