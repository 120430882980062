import React from "react";
import { EntityContextInterface } from "module/entity/context/entity/EntityContext";

export type TrainingCustomAssigneeContextInterface = EntityContextInterface;

const TrainingCustomAssigneeContext = React.createContext(
  <TrainingCustomAssigneeContextInterface>{}
);

export default TrainingCustomAssigneeContext;
