import React from "react";
import FormHelperText from "@mui/material/FormHelperText";
import XValidationMessage from "module/validation/XValidationMessage";

interface Props {
  errors: string[];
}

const XValidationErrorMessage: React.FC<Props> = ({ errors }) => {
  const hasError = Boolean(errors.length);

  if (hasError) {
    return (
      <FormHelperText error>
        <XValidationMessage messages={errors} />
      </FormHelperText>
    );
  }
  return null;
};

export default React.memo(XValidationErrorMessage);
