import { useCallback, useContext, useMemo } from "react";
import { useAppDispatch, useAppSelector } from "redux/hooks";
import { selectSystem, selectUser } from "module/auth/redux/authSlice";
import MaintenanceTaskContext from "module/maintenanceTask/context/MaintenanceTaskContext";
import { MaintenanceTaskInterface } from "module/maintenanceTask/types";
import MaintenanceTaskOpenCloseContext from "module/maintenanceTask/openClose/context/MaintenanceTaskOpenCloseContext";
import MaintenanceTaskStateContext from "module/maintenanceTask/state/context/MaintenanceTaskStateContext";
import MaintenanceTaskProcessStepContext from "module/maintenanceTaskProcessStep/context/MaintenanceTaskProcessStepContext";
import { MaintenanceTaskProcessStepInterface } from "module/maintenanceTaskProcessStep/types";
import {
  removeFromEntityRelationships,
  removeFromMyEntities,
} from "module/staff/redux/staffDataSlice";
import { EntityIdentifier, EntityType } from "module/entity/types";
import MaintenanceTaskProcessStepChangeContext from "module/maintenanceTaskProcessStep/change/MaintenanceTaskProcessStepChangeContext";
import { canRemoveCorrectiveToMaintenanceTaskProcessStep } from "../helper/canRemoveCorrectiveToMaintenanceTaskProcessStep";

interface Result {
  canRemove: boolean;
  remove: () => void;
}

const useMaintenanceTaskProcessStepCorrectiveRemove = (): Result => {
  const { entity: maintenanceTaskEntity } = useContext(MaintenanceTaskContext);
  const maintenanceTask = maintenanceTaskEntity as MaintenanceTaskInterface;

  const { isOpen: isMaintenanceTaskOpen } = useContext(
    MaintenanceTaskOpenCloseContext
  );

  const { isStateScheduledOrLower: isMaintenanceTaskStateScheduledOrLower } =
    useContext(MaintenanceTaskStateContext);

  const { entity: maintenanceTaskProcessStepEntity } = useContext(
    MaintenanceTaskProcessStepContext
  );
  const maintenanceTaskProcessStep =
    maintenanceTaskProcessStepEntity as MaintenanceTaskProcessStepInterface;

  const { change: changeMaintenanceTaskProcessStep } = useContext(
    MaintenanceTaskProcessStepChangeContext
  );
  const canRemove = useMemo(
    () =>
      canRemoveCorrectiveToMaintenanceTaskProcessStep({
        isMaintenanceTaskOpen,
        isMaintenanceTaskStateScheduledOrLower,
        maintenanceTask,
        maintenanceTaskProcessStep,
      }),
    [
      isMaintenanceTaskOpen,
      isMaintenanceTaskStateScheduledOrLower,
      maintenanceTask,
      maintenanceTaskProcessStep,
    ]
  );

  const dispatch = useAppDispatch();
  const staffId = useAppSelector(selectUser).id;
  const systemShortName = useAppSelector(selectSystem).shortName;

  const remove = useCallback((): void => {
    if (maintenanceTaskProcessStep.correctiveMaintenanceTask) {
      const maintenanceTaskProcessStepEntityIdentifier: EntityIdentifier = {
        id: maintenanceTaskProcessStep.id,
        type: EntityType.maintenanceTaskProcessStep,
      };

      const entityIdentifier: EntityIdentifier =
        maintenanceTaskProcessStep.correctiveMaintenanceTask;

      changeMaintenanceTaskProcessStep({ correctiveMaintenanceTask: null });
      dispatch(
        removeFromEntityRelationships({
          staffId,
          systemShortName,
          baseEntityIdentifier: maintenanceTaskProcessStepEntityIdentifier,
          entityIdentifierToRemove: entityIdentifier,
        })
      );
      dispatch(
        removeFromMyEntities({
          staffId,
          systemShortName,
          entityIdentifiers: [entityIdentifier],
        })
      );
    }
  }, [
    changeMaintenanceTaskProcessStep,
    dispatch,
    maintenanceTaskProcessStep.correctiveMaintenanceTask,
    maintenanceTaskProcessStep.id,
    staffId,
    systemShortName,
  ]);

  return {
    canRemove,
    remove,
  };
};

export default useMaintenanceTaskProcessStepCorrectiveRemove;
