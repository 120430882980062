import React, { useContext } from "react";
import { useAppSelector } from "redux/hooks";
import { selectStaffById as selectStaffByIdHelper } from "module/auth/redux/authSlice";
import MaintenanceTaskContext from "module/maintenanceTask/context/MaintenanceTaskContext";
import { MaintenanceTaskInterface } from "module/maintenanceTask/types";
import XLabelField from "component/labelField/XLabelField";

const MaintenanceTaskAssistantInternalStaffPositionContainer: React.FC = () => {
  const { entity } = useContext(MaintenanceTaskContext);

  const maintenanceTask = entity as MaintenanceTaskInterface;

  const selectStaffById = useAppSelector(selectStaffByIdHelper);
  const staff =
    maintenanceTask.assistantInternalStaffId == null
      ? undefined
      : selectStaffById(maintenanceTask.assistantInternalStaffId);
  return (
    <XLabelField
      {...{
        label: staff?.position,
      }}
    />
  );
};

export default React.memo(
  MaintenanceTaskAssistantInternalStaffPositionContainer
);
