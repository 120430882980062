import React from "react";
import { EntityContextInterface } from "module/entity/context/entity/EntityContext";

export type MaintenanceTaskPersonnelAssignmentExternalStaffContextInterface =
  EntityContextInterface;

const MaintenanceTaskPersonnelAssignmentExternalStaffContext =
  React.createContext(
    <MaintenanceTaskPersonnelAssignmentExternalStaffContextInterface>{}
  );

export default MaintenanceTaskPersonnelAssignmentExternalStaffContext;
