import axios from "axios";

export const convertFileIntoBase64 = async (
  path: string,
  mimeType: string
): Promise<string> => {
  return axios
    .get(path, {
      responseType: "arraybuffer",
    })
    .then(
      (response) =>
        `data:${mimeType};base64,${Buffer.from(
          response.data,
          "binary"
        ).toString("base64")}`
    );
};
