import React from "react";
import t from "module/translations";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";

const TrainingSessionTableHead: React.FC = () => {
  return (
    <TableHead>
      <TableRow>
        <TableCell align="left">
          {t.competencyManagement.training.session.nameHeader}
        </TableCell>
        <TableCell align="left">
          {t.competencyManagement.training.session.achievedHeader}
        </TableCell>
        <TableCell align="left">
          {t.competencyManagement.training.session.remarksHeader}
        </TableCell>
      </TableRow>
    </TableHead>
  );
};

export default React.memo(TrainingSessionTableHead);
