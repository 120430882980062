import React, { useContext } from "react";
import ToggleContext from "module/toggle/context/ToggleContext";
import usePermissions from "module/permission/usePermissions";
import InventoryCheckListTableHead from "./InventoryCheckListTableHead";

const InventoryCheckListTableHeadContainer: React.FC = () => {
  const { canEditInventoryCheck } = usePermissions();
  const { toggleAll, areAllSelected, isAnySelected } =
    useContext(ToggleContext);

  return (
    <InventoryCheckListTableHead
      {...{
        areAllSelected,
        isAnySelected,
        toggleAll,
        hasPermissionToDownload: canEditInventoryCheck,
      }}
    />
  );
};

export default InventoryCheckListTableHeadContainer;
