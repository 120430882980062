import React from "react";
import { EntityIdentifier } from "module/entity/types";
import MyEntityContextProvider from "module/entity/context/entity/MyEntityContextProvider";
import AssessmentObjectiveContext from "module/assessmentObjective/context/AssessmentObjectiveContext";
import MyAssessmentObjectiveFieldMetaDataContextProvider from "module/assessmentObjective/valiadation/fieldMetaData/MyAssessmentObjectiveFieldMetaDataContextProvider";
import MyEntityValidationResultContextProvider from "module/entity/context/validationResult/MyEntityValidationResultContextProvider";
import AssessmentObjectiveValidationResultContext from "module/assessmentObjective/valiadation/AssessmentObjectiveValidationResultContext";
import MyEntityChangeContextProvider from "module/entity/context/change/MyEntityChangeContextProvider";
import MyAssessmentObjectiveChangeContext from "module/assessmentObjective/change/AssessmentObjectiveChangeContext";

interface AssessmentMyDetailsObjectiveContextProviderProps {
  entityIdentifier: EntityIdentifier;
  children: React.ReactNode;
}

const MyAssessmentDetailsObjectiveContextProvider: React.FC<AssessmentMyDetailsObjectiveContextProviderProps> =
  ({ children, entityIdentifier }) => {
    return (
      <MyEntityContextProvider
        {...{
          entityIdentifier,
          context: AssessmentObjectiveContext,
        }}
      >
        <MyEntityValidationResultContextProvider
          {...{
            entityIdentifier,
            context: AssessmentObjectiveValidationResultContext,
          }}
        >
          <MyAssessmentObjectiveFieldMetaDataContextProvider>
            <MyEntityChangeContextProvider
              {...{
                entityIdentifier,
                context: MyAssessmentObjectiveChangeContext,
              }}
            >
              {children}
            </MyEntityChangeContextProvider>
          </MyAssessmentObjectiveFieldMetaDataContextProvider>
        </MyEntityValidationResultContextProvider>
      </MyEntityContextProvider>
    );
  };

export default MyAssessmentDetailsObjectiveContextProvider;
