import React from "react";
import TableBody from "@mui/material/TableBody";
import TableContainer from "component/table/TableContainer";
import CompetencyManagementListTableHeadContainer from "pages/competencyManagementList/table/head/CompetencyManagementListTableHeadContainer";
import CompetencyManagementListTableRowContainer from "pages/competencyManagementList/table/row/CompetencyManagementListTableRowContainer";
import Table from "component/table/Table";
import ListToolbarContainer from "module/listToolbar/ListToolbarContainer";
import t from "module/translations";
import CompetencyManagementListToolbarFilterTypeContainer from "module/competencyManagement/enrichedComponent/typeFilterField/CompetencyManagementListToolbarFilterTypeContainer";
import CompetencyManagementListToolbarFilterScheduledDateContainer from "module/competencyManagement/enrichedComponent/scheduledDateFilterField/CompetencyManagementListToolbarFilterScheduledDateContainer";
import { EntityIdentifier } from "module/entity/types";
import { getEntityIdentifierKey } from "module/entity/helper/getEntityIdentifierKey";

interface Props {
  entityIdentifiers: EntityIdentifier[];
}

const CompetencyManagementList: React.FC<Props> = ({ entityIdentifiers }) => {
  return (
    <TableContainer>
      <ListToolbarContainer
        {...{
          title: t.routes.competencyManagementOverview,
          filters: (
            <>
              <CompetencyManagementListToolbarFilterTypeContainer />
              <CompetencyManagementListToolbarFilterScheduledDateContainer />
            </>
          ),
          isDownloadAvailable: true,
        }}
      />
      <Table>
        <CompetencyManagementListTableHeadContainer />
        <TableBody>
          <>
            {entityIdentifiers.map((entityIdentifier) => (
              <CompetencyManagementListTableRowContainer
                {...{
                  key: getEntityIdentifierKey(entityIdentifier),
                  entityIdentifier,
                }}
              />
            ))}
          </>
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default React.memo(CompetencyManagementList);
