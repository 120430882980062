import { useCallback, useContext, useMemo } from "react";
import { useAppDispatch, useAppSelector } from "redux/hooks";
import { selectSystem, selectUser } from "module/auth/redux/authSlice";
import MaintenanceTaskContext from "module/maintenanceTask/context/MaintenanceTaskContext";
import { MaintenanceTaskInterface } from "module/maintenanceTask/types";
import { EntityType } from "module/entity/types";
import MaintenanceTaskOpenCloseContext from "module/maintenanceTask/openClose/context/MaintenanceTaskOpenCloseContext";
import { canRemoveMaintenanceTaskPersonnelAssignmentExternalStaff } from "module/maintenanceTaskPersonnelAssignment/externalStaff/helper/canRemoveMaintenanceTaskPersonnelAssignmentExternalStaff";
import MaintenanceTaskPersonnelAssignmentExternalStaffContext from "module/maintenanceTaskPersonnelAssignment/externalStaff/context/MaintenanceTaskPersonnelAssignmentExternalStaffContext";
import { MaintenanceTaskPersonnelAssignmentExternalStaffInterface } from "module/maintenanceTaskPersonnelAssignment/externalStaff/types";
import usePermissions from "module/permission/usePermissions";
import {
  removeFromEntityPropertyList,
  removeFromMyEntities,
} from "module/staff/redux/staffDataSlice";

interface Result {
  canRemove: boolean;
  remove: () => void;
}

const useMaintenanceTaskPersonnelAssignmentExternalStaffRemove = (): Result => {
  const { entity: maintenanceTaskEntity } = useContext(MaintenanceTaskContext);
  const maintenanceTask = maintenanceTaskEntity as MaintenanceTaskInterface;

  const { entity: maintenanceTaskPersonnelAssignmentExternalStaffEntity } =
    useContext(MaintenanceTaskPersonnelAssignmentExternalStaffContext);

  const maintenanceTaskPersonnelAssignmentExternalStaff =
    maintenanceTaskPersonnelAssignmentExternalStaffEntity as MaintenanceTaskPersonnelAssignmentExternalStaffInterface;

  const { isOpen } = useContext(MaintenanceTaskOpenCloseContext);

  const { canEditMaintenanceTask } = usePermissions();

  const dispatch = useAppDispatch();
  const staffId = useAppSelector(selectUser).id;
  const systemShortName = useAppSelector(selectSystem).shortName;

  const canRemove = useMemo(
    () =>
      canRemoveMaintenanceTaskPersonnelAssignmentExternalStaff({
        isMaintenanceTaskOpen: isOpen,
        canEditMaintenanceTask,
      }),
    [canEditMaintenanceTask, isOpen]
  );

  const remove = useCallback(() => {
    const maintenanceTaskEntityIdentifier = {
      id: maintenanceTask.id,
      type: EntityType.maintenanceTask,
    };

    const entityIdentifier = {
      id: maintenanceTaskPersonnelAssignmentExternalStaff.id,
      type: EntityType.maintenanceTaskPersonnelAssignmentExternalStaff,
    };

    dispatch(
      removeFromEntityPropertyList({
        staffId,
        systemShortName,
        baseEntityIdentifier: maintenanceTaskEntityIdentifier,
        propertyName: "personnelAssignments",
        entityIdentifierToRemove: entityIdentifier,
      })
    );

    dispatch(
      removeFromMyEntities({
        staffId,
        systemShortName,
        entityIdentifiers: [entityIdentifier],
      })
    );
  }, [
    dispatch,
    maintenanceTask.id,
    maintenanceTaskPersonnelAssignmentExternalStaff.id,
    staffId,
    systemShortName,
  ]);

  return {
    canRemove,
    remove,
  };
};

export default useMaintenanceTaskPersonnelAssignmentExternalStaffRemove;
