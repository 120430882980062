import {
  AbstractMyEntityInterface,
  EntityChangedDate,
  EntityIdentifier,
  EntityType,
  EntityWithId,
  EntityWithRelationships,
} from "module/entity/types";
import { ApiMaintenanceTaskInterface } from "api/maintenanceTask/types";
import { IsBlockedInterface } from "module/block/types";
import { MaintenanceTaskProcessStepToSyncInterface } from "module/maintenanceTaskProcessStep/types";
import { MaintenanceTaskPersonnelAssignmentInternalStaffToSyncInterface } from "module/maintenanceTaskPersonnelAssignment/internalStaff/types";
import { MaintenanceTaskPersonnelAssignmentExternalStaffToSyncInterface } from "module/maintenanceTaskPersonnelAssignment/externalStaff/types";
import { MaintenanceTaskPartToSyncInterface } from "module/maintenanceTaskPart/types";
import { AttachmentToSyncInterface } from "module/attachment/types";
import {
  MaintenanceTaskAssistantExternalStaffToSyncInterface,
  MaintenanceTaskAssistantInternalStaffToSyncInterface,
} from "module/maintenanceTaskAssisstant/types";
import { MaintenanceTaskDefinitionType } from "pages/maintenanceTaskList/types";
import { ParameterTypeInterface } from "module/parameter/types";

export enum MaintenanceTaskType {
  preventive = "preventive",
  corrective = "corrective",
  failure = "failure",
  carp = "carp",
  modification = "modification",
  ppeInspection = "ppe_inspection",
  eqInspection = "eq_inspection",
}

export enum MaintenanceTaskScheduleType {
  allXValues = "all_x_values",
  allXValuesSerialized = "all_x_values_serialized",
  // rest does not matter right now
}

export enum MaintenanceTaskAssistantType {
  internal = "internal",
  external = "external",
}

export interface MaintenanceTaskDefinitionInterface {
  id: number;
  type?: MaintenanceTaskDefinitionType;
  scheduleType?: string;
  parameterType?: ParameterTypeInterface;
  serializedArticleId?: number | null;
  serializedArticleLocations?: string[] | null;
  color?: string | null;
}

export type MaintenanceTaskSourceInterface = "template" | "serviceRequest";

type OpenCloseHistoryItem = {
  type: "open" | "close";
  staffId: number;
  dateTime: string;
};

export interface MaintenanceTaskInterface
  extends EntityWithId,
    EntityWithRelationships,
    EntityChangedDate {
  name?: string;
  reportingNumber?: string;
  description?: string;
  expectedStartDate: string | null;
  expectedEndDate: string | null;
  scheduledStartDate: string | null;
  scheduledEndDate: string | null;
  completionStartDate?: string;
  completionEndDate?: string;
  state: MaintenanceTaskState;
  type: MaintenanceTaskType;
  remarks?: string;
  observationalIndicators?: string;
  presumedCauses?: string;
  isBlockedDetails: MaintenanceTaskIsBlockedInterface;
  processSteps: EntityIdentifier[];
  personnelAssignments: EntityIdentifier[];
  assistantType?: MaintenanceTaskAssistantType;
  assistantInternalStaffId?: number;
  assistantExternalStaffName?: string;
  assistantExternalStaffCompany?: string;
  parts: EntityIdentifier[];
  attachments: EntityIdentifier[];
  definitionType?: MaintenanceTaskDefinitionType;
  maintainedAtValue?: number;
  scheduledValue?: number;
  definition?: MaintenanceTaskDefinitionInterface;
  color?: string | null;
  source?: MaintenanceTaskSourceInterface;
  window?: string | null;
  timeDeviation?: string | null;
  originalMaintenanceTaskProcessStepId?: string | null;
  serializedArticleLocations?: string[] | null;
  openCloseHistory?: OpenCloseHistoryItem[];
}

export type MyMaintenanceTaskInterface = AbstractMyEntityInterface<
  ApiMaintenanceTaskInterface,
  MaintenanceTaskInterface
>;

export interface MaintenanceTaskIsBlockedInterface extends IsBlockedInterface {
  isBlockedBecauseIsOutOfPMWindow: boolean;
  isBlockedBecauseItIsValidated: boolean;
  isBlockedBecauseStaffIsNotTrained?: boolean;
  isBlockedBecauseStaffIsNotTrainedMessage?: string | null;
}

export enum MaintenanceTaskSyncUnavailableReason {
  noPermissions = "noPermissions",
  offline = "offline",
  notValid = "notValid",
  isOpen = "isOpen",
  hasNotChanged = "hasNotChanged",
  notProcessable = "notProcessable",
  stateIsNotSupported = "stateIsNotSupported",
  someMaintenanceTasksCreatedForMaintenanceTaskProcessStepsAreOpen = "someMaintenanceTasksCreatedForMaintenanceTaskProcessStepsAreOpen",
}

export enum MaintenanceTaskState {
  initScheduled = "init_sched",
  requested = "requested",
  scheduled = "scheduled",
  completed = "completed",
  validated = "validated",
}

export interface MaintenanceTaskToSyncInterface {
  identifier: {
    type: EntityType.maintenanceTask;
    id: number | null;
    frontendId?: string;
  };
  data: {
    id?: number;
    name?: string;
    description?: string;
    color?: string | null;
    remarks?: string;
    completionStartDate: string;
    completionEndDate?: string;
    observationalIndicators?: string;
    presumedCauses?: string;
    state: MaintenanceTaskState;
    type: MaintenanceTaskType;
    processSteps: MaintenanceTaskProcessStepToSyncInterface[];
    personnelAssignments: Array<
      | MaintenanceTaskPersonnelAssignmentInternalStaffToSyncInterface
      | MaintenanceTaskPersonnelAssignmentExternalStaffToSyncInterface
    >;
    articleMovements: MaintenanceTaskPartToSyncInterface[];
    attachments: AttachmentToSyncInterface[];
    assistant:
      | MaintenanceTaskAssistantInternalStaffToSyncInterface
      | MaintenanceTaskAssistantExternalStaffToSyncInterface
      | null;
    changedDateTime?: string;
    maintainedAtValue?: number;
    expectedStartDate: string;
    expectedEndDate: string;
    scheduledStartDate: string;
    scheduledEndDate: string;
    openCloseHistory?: OpenCloseHistoryItem[];
  };
  raw?: ApiMaintenanceTaskInterface;
}
