import { useCallback } from "react";
import { useAppDispatch, useAppSelector } from "redux/hooks";
import { selectUser, setDefaultState } from "module/auth/redux/authSlice";
import { removeAuthData, removeStaffDataData } from "module/storage/storage";
import { deleteAllData } from "module/staff/redux/staffDataSlice";

type UseDeleteAllUserDataResult = () => void;

const useDeleteAllUserData = (): UseDeleteAllUserDataResult => {
  const dispatch = useAppDispatch();
  const userId = useAppSelector(selectUser).id;

  return useCallback(async () => {
    dispatch(deleteAllData({ staffId: userId }));
    dispatch(setDefaultState());
    await removeAuthData();
    await removeStaffDataData(`${userId}`);
  }, [dispatch, userId]);
};

export default useDeleteAllUserData;
