import {
  ApiMaintenanceTaskInterface,
  ApiMaintenanceTaskPersonnelAssignmentExternalStaffInterface,
  ApiMaintenanceTaskPersonnelAssignmentStaffInterface,
  ApiMaintenanceTaskPersonnelAssignmentType,
} from "api/maintenanceTask/types";
import { MyMaintenanceTaskPersonnelAssignmentInternalStaffInterface } from "module/maintenanceTaskPersonnelAssignment/internalStaff/types";
import { MyMaintenanceTaskPersonnelAssignmentExternalStaffInterface } from "module/maintenanceTaskPersonnelAssignment/externalStaff/types";
import { mapApiMaintenanceTaskPersonnelAssignmentExternalStaffToApp } from "module/maintenanceTaskPersonnelAssignment/externalStaff/helper/mapApiMaintenanceTaskPersonnelAssignmentExternalStaffToApp";
import { mapApiMaintenanceTaskPersonnelAssignmentInternalStaffToApp } from "module/maintenanceTaskPersonnelAssignment/internalStaff/helper/mapApiMaintenanceTaskPersonnelAssignmentInternalStaffToApp";

export const mapApiMaintenanceTaskPersonnelAssignmentToApp = (
  rawMaintenanceTask: ApiMaintenanceTaskInterface,
  raw: ApiMaintenanceTaskPersonnelAssignmentType
):
  | MyMaintenanceTaskPersonnelAssignmentInternalStaffInterface
  | MyMaintenanceTaskPersonnelAssignmentExternalStaffInterface => {
  if (raw.internalOrExternal === "internal") {
    return mapApiMaintenanceTaskPersonnelAssignmentInternalStaffToApp(
      rawMaintenanceTask,
      raw as ApiMaintenanceTaskPersonnelAssignmentStaffInterface
    );
  }
  if (raw.internalOrExternal === "external") {
    return mapApiMaintenanceTaskPersonnelAssignmentExternalStaffToApp(
      rawMaintenanceTask,
      raw as ApiMaintenanceTaskPersonnelAssignmentExternalStaffInterface
    );
  }
  throw new Error("Can not map");
};
