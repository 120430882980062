import React, { useCallback } from "react";
import TableCell from "@mui/material/TableCell";
import TableSortLabel from "@mui/material/TableSortLabel";
import makeStyles from "@mui/styles/makeStyles";
import { SortType, SortDirection } from "module/sort/types";

const useStyles = makeStyles(() => ({
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1,
  },
}));

interface Props {
  fieldName: string;
  currentSort: SortType;
  onClick: (sort: SortType) => void;
  className?: string;
  children: React.ReactNode;
}

const TableSortCell: React.FC<Props> = ({
  fieldName,
  currentSort,
  onClick,
  children,
  className,
}) => {
  const handleOnClick = useCallback(
    () =>
      onClick({
        fieldName,
        direction:
          currentSort.direction === SortDirection.asc
            ? SortDirection.desc
            : SortDirection.asc,
      }),
    [currentSort.direction, fieldName, onClick]
  );
  const classes = useStyles();
  const isActive = fieldName === currentSort.fieldName;

  return (
    <TableCell
      align="left"
      sortDirection={isActive ? currentSort.direction : false}
      className={className}
    >
      <TableSortLabel
        active={isActive}
        direction={isActive ? currentSort.direction : "asc"}
        onClick={handleOnClick}
      >
        {children}
        {isActive ? (
          <span className={classes.visuallyHidden}>
            {currentSort.direction === SortDirection.desc
              ? "sorted descending"
              : "sorted ascending"}
          </span>
        ) : null}
      </TableSortLabel>
    </TableCell>
  );
};

export default React.memo(TableSortCell);
