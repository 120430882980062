import React from "react";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import DeleteButton from "component/button/DeleteButton";
import { useDownloadedMaintenanceTaskDetailsPersonnelAssignmentsInternalStyles } from "pages/myMaintenanceTaskDetails/personnelAssignment/internalList/styles";
import MaintenanceTaskPersonnelAssignmentInternalStaffPositionContainer from "module/maintenanceTaskPersonnelAssignment/internalStaff/enrichedComponent/staffPositionField/MaintenanceTaskPersonnelAssignmentInternalStaffPositionContainer";
import MaintenanceTaskPersonnelAssignmentInternalStaffResponsibilityContainer from "module/maintenanceTaskPersonnelAssignment/internalStaff/enrichedComponent/responsibilityField/MaintenanceTaskPersonnelAssignmentInternalStaffResponsibilityContainer";
import MaintenanceTaskPersonnelAssignmentInternalStaffContainer from "module/maintenanceTaskPersonnelAssignment/internalStaff/enrichedComponent/staffField/MaintenanceTaskPersonnelAssignmentInternalStaffContainer";
import MaintenanceTaskPersonnelAssignmentInternalStaffDurationFieldContainer from "../durationField/MaintenanceTaskPersonnelAssignmentInternalStaffDurationFieldContainer";

interface Props {
  isActionAvailable: boolean;
  canRemove?: boolean;
  remove?: () => void;
}

const MaintenanceTaskDetailsPersonnelAssignmentInternalStaffListTableRow: React.FC<Props> =
  ({ canRemove, remove, isActionAvailable }) => {
    const classes =
      useDownloadedMaintenanceTaskDetailsPersonnelAssignmentsInternalStyles();

    return (
      <TableRow>
        <TableCell className={classes.staffName}>
          <MaintenanceTaskPersonnelAssignmentInternalStaffContainer />
        </TableCell>
        <TableCell className={classes.staffPosition}>
          <MaintenanceTaskPersonnelAssignmentInternalStaffPositionContainer />
        </TableCell>
        <TableCell className={classes.responsibility}>
          <MaintenanceTaskPersonnelAssignmentInternalStaffResponsibilityContainer />
        </TableCell>
        <TableCell className={classes.duration}>
          <MaintenanceTaskPersonnelAssignmentInternalStaffDurationFieldContainer />
        </TableCell>
        {isActionAvailable && (
          <TableCell className={classes.actions}>
            {canRemove && remove != null && (
              <DeleteButton {...{ onClick: remove }} />
            )}
          </TableCell>
        )}
      </TableRow>
    );
  };

export default React.memo(
  MaintenanceTaskDetailsPersonnelAssignmentInternalStaffListTableRow
);
