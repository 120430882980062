import React from "react";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";
import NestedElement from "component/nestedElement/NestedElement";
import { Theme } from "@mui/material/styles";
import makeStyles from "@mui/styles/makeStyles";

const useStyles = makeStyles((theme: Theme) => ({
  row: {
    backgroundColor: theme.palette.grey[100],
  },
}));

interface Props {
  name: string;
  level: number;
}

const AssessmentObjectiveTableRowCategory: React.FC<Props> = ({
  name,
  level,
}) => {
  const classes = useStyles();
  return (
    <TableRow className={classes.row}>
      <TableCell align="left">
        <Typography variant="body2" gutterBottom>
          <NestedElement {...{ level }}>{name}</NestedElement>
        </Typography>
      </TableCell>
      <TableCell />
      <TableCell />
    </TableRow>
  );
};

export default React.memo(AssessmentObjectiveTableRowCategory);
