import React from "react";
import { Route } from "react-router-dom";
import FormContainsErrors from "component/form/FormContainsErrors";
import AssessmentDetailsNavigation from "module/assessment/enrichedComponent/detailsNavigation/AssessmentDetailsNavigation";
import ButtonsContainer from "component/button/ButtonsContainer";
import OpenButton from "component/button/OpenButton";
import CloseButton from "component/button/CloseButton";
import AssessmentStateEditableFieldContainer from "module/assessment/enrichedComponent/stateField/AssessmentStateEditableFieldContainer";
import routes from "routes/routes";
import {
  getPathToAssessmentDetailsOverview,
  getPathToAssessmentDetailsObjectives,
} from "module/assessment/helper/getPath";

interface Props {
  assessmentId: string;
  hasError: boolean;
  open?: () => void;
  close?: () => void;
  isStateEditable: boolean;
}

const MyAssessmentDetails: React.FC<Props> = ({
  assessmentId,
  hasError,
  open,
  close,
  isStateEditable,
}) => {
  return (
    <>
      {hasError && <FormContainsErrors />}
      <AssessmentDetailsNavigation
        {...{
          listPath: routes.myCompetencyManagementResources.path,
          overviewPath: getPathToAssessmentDetailsOverview(assessmentId),
          objectivesPath: getPathToAssessmentDetailsObjectives(assessmentId),
        }}
      />
      <Route
        {...{
          key: routes.myAssessmentDetailsOverview.path,
          path: routes.myAssessmentDetailsOverview.path,
          render: () => <routes.myAssessmentDetailsOverview.mainComponent />,
        }}
      />
      <Route
        {...{
          key: routes.myAssessmentDetailsObjectives.path,
          path: routes.myAssessmentDetailsObjectives.path,
          render: () => <routes.myAssessmentDetailsObjectives.mainComponent />,
        }}
      />
      {(open || close) && (
        <ButtonsContainer>
          {open && <OpenButton {...{ onClick: open }} />}
          {close && <CloseButton {...{ onClick: close }} />}
          {isStateEditable && <AssessmentStateEditableFieldContainer />}
        </ButtonsContainer>
      )}
    </>
  );
};

export default React.memo(MyAssessmentDetails);
