import React, { useContext } from "react";
import TrainingCustomContext from "module/trainingCustom/context/TrainingCustomContext";
import { TrainingCustomInterface } from "module/trainingCustom/types";
import TrainingCustomStateLabelField from "module/trainingCustom/enrichedComponent/stateField/TrainingCustomStateLabelField";

const TrainingCustomStateLabelFieldContainer: React.FC = () => {
  const { entity } = useContext(TrainingCustomContext);
  const { state } = entity as TrainingCustomInterface;

  return <TrainingCustomStateLabelField {...{ state }} />;
};

export default React.memo(TrainingCustomStateLabelFieldContainer);
