import React from "react";
import { EntityContextInterface } from "module/entity/context/entity/EntityContext";

export type MaintenanceTaskPartContextInterface = EntityContextInterface;

const MaintenanceTaskPartContext = React.createContext(
  <MaintenanceTaskPartContextInterface>{}
);

export default MaintenanceTaskPartContext;
