import React, { useContext } from "react";
import { getFieldMetaData as getFieldMetaDataHelper } from "module/fieldMetaData/getFieldMetaData";
import MaintenanceTaskOpenCloseContext from "module/maintenanceTask/openClose/context/MaintenanceTaskOpenCloseContext";
import MaintenanceTaskStateContext from "module/maintenanceTask/state/context/MaintenanceTaskStateContext";
import MaintenanceTaskPersonnelAssignmentExternalStaffValidationResultContext from "module/maintenanceTaskPersonnelAssignment/externalStaff/validation/MaintenanceTaskPersonnelAssignmentExternalStaffValidationResultContext";
import MaintenanceTaskPersonnelAssignmentExternalStaffFieldMetaDataContext, {
  MaintenanceTaskPersonnelAssignmentExternalStaffFieldMetaDataContextInterface,
} from "module/maintenanceTaskPersonnelAssignment/externalStaff/validation/fieldMetaData/MaintenanceTaskPersonnelAssignmentExternalStaffFieldMetaDataContext";

interface Prop {
  children: React.ReactNode;
}

const MyMaintenanceTaskPersonnelAssignmentExternalStaffFieldMetaDataContextProvider: React.FC<Prop> =
  ({ children }) => {
    const { validationWarnings, validationErrors } = useContext(
      MaintenanceTaskPersonnelAssignmentExternalStaffValidationResultContext
    );
    const { isOpen } = useContext(MaintenanceTaskOpenCloseContext);
    const { isStateScheduledOrLower } = useContext(MaintenanceTaskStateContext);

    const getFieldMetaData = getFieldMetaDataHelper({
      validationWarnings,
      validationErrors,
      isOpen,
      isStateScheduledOrLower,
    });

    const contextValue: MaintenanceTaskPersonnelAssignmentExternalStaffFieldMetaDataContextInterface =
      {
        getFieldMetaData,
      };

    return (
      <MaintenanceTaskPersonnelAssignmentExternalStaffFieldMetaDataContext.Provider
        value={contextValue}
      >
        {children}
      </MaintenanceTaskPersonnelAssignmentExternalStaffFieldMetaDataContext.Provider>
    );
  };

export default MyMaintenanceTaskPersonnelAssignmentExternalStaffFieldMetaDataContextProvider;
