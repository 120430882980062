import React, { useContext, useMemo } from "react";
import ToggleContext from "module/toggle/context/ToggleContext";
import MyCompetencyManagementListTableRowTraining from "pages/myCompetencyManagementList/table/row/training/MyCompetencyManagementListTableRowTraining";
import TrainingContext from "module/training/context/TrainingContext";
import { TrainingInterface } from "module/training/types";
import { EntityIdentifier, EntityType } from "module/entity/types";

const MyCompetencyManagementListTableRowTrainingContainer: React.FC = () => {
  const { entity } = useContext(TrainingContext);
  const { isSelected: isSelectedHelper } = useContext(ToggleContext);

  const training = entity as TrainingInterface;

  const entityIdentifier: EntityIdentifier = useMemo(
    () => ({
      id: training.id,
      type: EntityType.training,
    }),
    [training.id]
  );

  const isSelected = useMemo(
    () => isSelectedHelper(entityIdentifier),
    [entityIdentifier, isSelectedHelper]
  );

  return (
    <MyCompetencyManagementListTableRowTraining
      {...{ training, isSelected, entityIdentifier }}
    />
  );
};

export default React.memo(MyCompetencyManagementListTableRowTrainingContainer);
