import React, { useContext } from "react";
import AssessmentContext from "module/assessment/context/AssessmentContext";
import { AssessmentInterface } from "module/assessment/types";
import useDateFormat from "toolkit/useDateFormat";
import AssessmentCompletionStartDateTimeLabelField from "module/assessment/enrichedComponent/completionStartDateTimeField/AssessmentCompletionStartDateTimeLabelField";

const AssessmentCompletionStartDateTimeFieldContainer: React.FC = () => {
  const { entity } = useContext(AssessmentContext);
  const { formatDateTime } = useDateFormat();

  const { completionStartDate } = entity as AssessmentInterface;

  return (
    <AssessmentCompletionStartDateTimeLabelField
      {...{ completionStartDate, formatDateTime }}
    />
  );
};

export default React.memo(AssessmentCompletionStartDateTimeFieldContainer);
