import React, { useMemo } from "react";
import { useAppSelector } from "redux/hooks";
import { useHistory } from "react-router-dom";
import ErrorPage from "error/ErrorPage";
import { EntityIdentifier, EntityType } from "module/entity/types";
import { getPathToMaintenanceTaskDetails } from "module/maintenanceTask/helper/getPath";
import { MyMaintenanceTaskInterface } from "module/maintenanceTask/types";
import { MaintenanceTaskDefinitionType } from "pages/maintenanceTaskList/types";
import selectEntity from "module/staff/redux/selector/selectEntity";
import selectOpenItemsByEntityType from "module/staff/redux/selector/selectOpenItemsByEntityType";

const MyOpenMaintenanceTaskDetailsPage: React.FC = () => {
  const selectEntityHelper = useAppSelector(selectEntity);
  const openMaintenanceTasksEntityIdentifier = useAppSelector(
    selectOpenItemsByEntityType(EntityType.maintenanceTask)
  ) as EntityIdentifier[];

  const openSystemMaintenanceTaskEntityIdentifier = useMemo(
    () =>
      openMaintenanceTasksEntityIdentifier.reduce(
        (result, openMaintenanceTaskEntityIdentifier) => {
          if (result == null) {
            const myMaintenanceTask = selectEntityHelper(
              openMaintenanceTaskEntityIdentifier
            ) as MyMaintenanceTaskInterface;
            if (
              myMaintenanceTask.data.definitionType == null ||
              myMaintenanceTask.data.definitionType ===
                MaintenanceTaskDefinitionType.system
            ) {
              return openMaintenanceTaskEntityIdentifier;
            }
          }
          return result;
        },
        null as EntityIdentifier | null
      ),
    [openMaintenanceTasksEntityIdentifier, selectEntityHelper]
  );

  const history = useHistory();
  if (openSystemMaintenanceTaskEntityIdentifier) {
    history.push(
      getPathToMaintenanceTaskDetails(
        openSystemMaintenanceTaskEntityIdentifier.id
      )
    );
  }

  return <ErrorPage />;
};

export default React.memo(MyOpenMaintenanceTaskDetailsPage);
