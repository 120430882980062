import t from "module/translations";
import { useAppSelector } from "redux/hooks";
import { selectStaffById as selectStaffByIdHelper } from "module/auth/redux/authSlice";
import { AssessmentInterface } from "module/assessment/types";

const useGetAssessmentBlockedReasonText = (
  assessment: AssessmentInterface
): string | null => {
  const {
    isBlockedDetails: {
      blockedByStaffId,
      isBlockedByOtherStaff,
      isBlockedByMeOnOtherDevice,
      isBlockedByMeOnThisDevice,
      isBlockedBecauseItIsCompleted,
      isBlockedBecauseUserHasNoPermissions,
    },
  } = assessment;

  const selectStaffById = useAppSelector(selectStaffByIdHelper);

  if (isBlockedByMeOnThisDevice) {
    return t.competencyManagement.assessment.blocked.byMeOnThisDeviceText;
  }
  if (isBlockedByMeOnOtherDevice) {
    return t.competencyManagement.assessment.blocked.byMeOnOtherDeviceText;
  }
  if (isBlockedByOtherStaff && blockedByStaffId) {
    const { name: staffName } = selectStaffById(blockedByStaffId);
    return t.competencyManagement.assessment.blocked.byOtherStaffText(
      staffName
    );
  }
  if (isBlockedBecauseItIsCompleted) {
    return t.competencyManagement.assessment.blocked
      .isBlockedBecauseItIsCompletedText;
  }

  if (isBlockedBecauseUserHasNoPermissions) {
    return t.competencyManagement.assessment.blocked
      .isBlockedBecauseUserHasNoPermissions;
  }

  return null;
};

export default useGetAssessmentBlockedReasonText;
