import React from "react";
import TableBody from "@mui/material/TableBody";
import TableContainer from "component/table/TableContainer";
import ToBeSignedListTableHead from "pages/toBeSignedList/table/head/ToBeSignedListTableHead";
import ToBeSignedListTableRowContainer from "pages/toBeSignedList/table/row/ToBeSignedListTableRowContainer";
import Table from "component/table/Table";
import t from "module/translations";
import { EntityIdentifier } from "module/entity/types";
import ListToolbarContainer from "module/listToolbar/ListToolbarContainer";
import { getEntityIdentifierKey } from "module/entity/helper/getEntityIdentifierKey";

interface Props {
  entityIdentifiers: EntityIdentifier[];
}

const ToBeSignedList: React.FC<Props> = ({ entityIdentifiers }) => {
  return (
    <TableContainer>
      <ListToolbarContainer title={t.routes.toBeSigned} />
      <Table>
        <ToBeSignedListTableHead />
        <TableBody>
          {entityIdentifiers.map((entityIdentifier) => (
            <ToBeSignedListTableRowContainer
              {...{
                key: getEntityIdentifierKey(entityIdentifier),
                entityIdentifier,
              }}
            />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default React.memo(ToBeSignedList);
