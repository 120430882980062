import React from "react";
import Typography from "@mui/material/Typography";
import MUIToolbar from "@mui/material/Toolbar";
import { useToolbarStyles } from "component/listToolbar/styles";

interface Props {
  title: string;
  children: React.ReactNode;
}

const ListToolbar: React.FC<Props> = ({ title, children }) => {
  const classes = useToolbarStyles();

  return (
    <MUIToolbar className={classes.root}>
      <Typography className={classes.title} variant="h6" component="div">
        {title}
      </Typography>
      {children}
    </MUIToolbar>
  );
};

export default React.memo(ListToolbar);
