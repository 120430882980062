import React, { useContext } from "react";
import MyAssessmentDetailsOverview from "pages/myAssessmentDetails/overview/MyAssessmentDetailsOverview";
import AssessmentStateContext from "module/assessment/state/context/AssessmentStateContext";

const MyAssessmentDetailsOverviewContainer: React.FC = () => {
  const { isStateEditable } = useContext(AssessmentStateContext);

  return (
    <MyAssessmentDetailsOverview
      {...{
        isStateEditable,
      }}
    />
  );
};

export default React.memo(MyAssessmentDetailsOverviewContainer);
