import React, { useContext, useCallback, useMemo } from "react";
import ToggleContext from "module/toggle/context/ToggleContext";
import MaintenanceTaskListItemTableRow from "pages/maintenanceTaskList/table/row/MaintenanceTaskListTableRow";
import MyEntityCollectionContext from "module/entity/context/entityCollection/MyEntityCollectionContext";
import { EntityIdentifier, MyEntityInterface } from "module/entity/types";
import usePermissions from "module/permission/usePermissions";
import { MaintenanceTaskInterface } from "module/maintenanceTask/types";
import MaintenanceTaskContext from "module/maintenanceTask/context/MaintenanceTaskContext";
import { useAppSelector } from "redux/hooks";
import { selectUser } from "module/auth/redux/authSlice";
import { ApiMaintenanceTaskInterface } from "api/maintenanceTask/types";
import { isApiMaintenanceTaskAssignedToStaff } from "pages/maintenanceTaskList/isApiMaintenanceTaskAssignedToStaff";

interface Props {
  entityIdentifier: EntityIdentifier;
}

const MaintenanceTaskListTableRowContainer: React.FC<Props> = ({
  entityIdentifier,
}) => {
  const { myEntityCollection } = useContext(MyEntityCollectionContext);
  const { isSelected: isSelectedHelper, toggleOne } = useContext(ToggleContext);

  const { id: staffId } = useAppSelector(selectUser);

  const { canEditMaintenanceTask } = usePermissions();

  const { data, raw } = useMemo(
    () => myEntityCollection.get(entityIdentifier) as MyEntityInterface,
    [entityIdentifier, myEntityCollection]
  );
  const maintenanceTask = data as MaintenanceTaskInterface;
  const apiMaintenanceTask = raw as ApiMaintenanceTaskInterface;

  const isAssignedToMe = useMemo(
    () => isApiMaintenanceTaskAssignedToStaff(apiMaintenanceTask, staffId),
    [apiMaintenanceTask, staffId]
  );

  const rowClick = useCallback(() => {
    if (canEditMaintenanceTask && !maintenanceTask.isBlockedDetails.isBlocked) {
      toggleOne(entityIdentifier);
    }
  }, [
    canEditMaintenanceTask,
    entityIdentifier,
    maintenanceTask.isBlockedDetails.isBlocked,
    toggleOne,
  ]);
  const isSelected = isSelectedHelper(entityIdentifier);

  return (
    <MaintenanceTaskContext.Provider value={{ entity: maintenanceTask }}>
      <MaintenanceTaskListItemTableRow
        {...{
          maintenanceTask,
          isSelected,
          rowClick,
          hasPermissionToDownload: canEditMaintenanceTask,
          isHighlighted: isAssignedToMe,
          color: maintenanceTask.color ?? null,
        }}
      />
    </MaintenanceTaskContext.Provider>
  );
};

export default MaintenanceTaskListTableRowContainer;
