import React from "react";
import clsx from "clsx";
import makeStyles from "@mui/styles/makeStyles";
import { DRAWER_WIDTH } from "component/sidemenu/constants";

const HEADER_HEIGHT = "64px";

const useStyles = makeStyles((theme) => ({
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: -DRAWER_WIDTH,
    marginTop: HEADER_HEIGHT,
    minHeight: `calc(100vh - ${HEADER_HEIGHT})`,
  },
  contentShift: {
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  },
}));

interface Props {
  isMenuOpen: boolean;
  children: React.ReactNode;
}

const PageContainer: React.FC<Props> = ({ isMenuOpen, children }) => {
  const classes = useStyles();
  return (
    <main
      className={clsx(classes.content, {
        [classes.contentShift]: isMenuOpen,
      })}
    >
      {children}
    </main>
  );
};

export default React.memo(PageContainer);
