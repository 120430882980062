import React from "react";
import LabelField from "component/labelField/LabelField";
import t from "module/translations";

interface Props {
  completionEndDate?: string;
  formatDateTime: (date: string) => string;
}

const TrainingCompletionEndDateTimeLabelField: React.FC<Props> = ({
  completionEndDate,
  formatDateTime,
}) => {
  return (
    <LabelField
      {...{
        caption: t.competencyManagement.training.completionEndDateTimeText,
        label: completionEndDate ? formatDateTime(completionEndDate) : "",
      }}
    />
  );
};

export default React.memo(TrainingCompletionEndDateTimeLabelField);
