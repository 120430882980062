import React, { useContext, useEffect, useState, useMemo } from "react";
import ToggleContextProvider from "module/toggle/context/ToggleContextProvider";
import SortContext from "module/sort/context/SortContext";
import { sort as sortHelper } from "module/sort/helper";
import { getMapFromCollection } from "module/entity/helper/getMapFromCollection";
import { EntityIdentifier, EntityType } from "module/entity/types";
import { InventoryCheckInterface } from "module/inventoryCheck/types";
import useInventoryCheckIsSyncAvailable from "module/inventoryCheck/sync/useInventoryCheckIsSyncAvailable";
import { InventoryCheckSortProperty } from "pages/inventoryCheckList/types";
import MyInventoryCheckList from "./MyInventoryCheckList";

interface Props {
  inventoryChecks: InventoryCheckInterface[];
}

const MyInventoryCheckListContainer: React.FC<Props> = ({
  inventoryChecks,
}) => {
  const { sort } = useContext(SortContext);

  const [items, setItems] = useState<InventoryCheckInterface[]>([]);

  const { isInventoryCheckSyncAvailable } = useInventoryCheckIsSyncAvailable();

  useEffect(() => {
    const newItems = sortHelper(
      inventoryChecks,
      sort,
      InventoryCheckSortProperty.reportingNumber
    );
    setItems(newItems);
  }, [inventoryChecks, sort]);

  const entities = useMemo(
    () =>
      getMapFromCollection<InventoryCheckInterface>(
        items,
        EntityType.inventoryCheck
      ),
    [items]
  );

  const entityIdentifiers = useMemo(
    () => Array.from(entities.keys()),
    [entities]
  );

  const itemsAllowedToToggle = useMemo(
    () =>
      entityIdentifiers.reduce((result, key) => {
        const canSync = isInventoryCheckSyncAvailable(
          (entities.get(key) as InventoryCheckInterface).id
        ).isAvailable;
        if (canSync) {
          return [...result, key];
        }
        return result;
      }, [] as EntityIdentifier[]),
    [entities, entityIdentifiers, isInventoryCheckSyncAvailable]
  );

  return (
    <ToggleContextProvider {...{ itemsAllowedToToggle }}>
      <MyInventoryCheckList {...{ entities }} />
    </ToggleContextProvider>
  );
};

export default React.memo(MyInventoryCheckListContainer);
