import React from "react";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import t from "module/translations";
import useDateFormat from "toolkit/useDateFormat";
import { getAssessmentTypeTranslation } from "module/assessment/helper/getAssessmentTypeTranslation";
import StaffNameText from "module/staff/StaffNameText";
import LinkWithIcon from "component/link/LinkWithIcon";
import { getPathToAssessmentDetails } from "module/assessment/helper/getPath";
import AssessmentDeleteButtonContainer from "module/assessment/enrichedComponent/deleteButton/AssessmentDeleteButtonContainer";
import { AssessmentInterface } from "module/assessment/types";
import TableToggleCellContainer from "module/toggle/TableToggleCellContainer";
import { EntityIdentifier } from "module/entity/types";
import { getAssessmentStateTranslation } from "module/assessment/helper/getAssessmentStateTranslation";

interface Props {
  assessment: AssessmentInterface;
  isSelected: boolean;
  entityIdentifier: EntityIdentifier;
}

const MyCompetencyManagementListTableRowAssessment: React.FC<Props> = ({
  isSelected,
  assessment,
  entityIdentifier,
}) => {
  const { formatDateTime } = useDateFormat();
  return (
    <TableRow
      {...{
        hover: true,
        tabIndex: -1,
        role: "checkbox",
        "aria-checked": isSelected,
        selected: isSelected,
        key: assessment.id,
      }}
    >
      <TableToggleCellContainer {...{ entityIdentifier }} />
      <TableCell align="left">{assessment.reportingNumber}</TableCell>
      <TableCell align="left">
        <LinkWithIcon
          {...{
            label: assessment.name,
            path: getPathToAssessmentDetails(assessment.id),
          }}
        />
      </TableCell>
      <TableCell align="left">
        {assessment.assigneeId ? (
          <StaffNameText {...{ staffId: assessment.assigneeId }} />
        ) : (
          "-"
        )}
      </TableCell>
      <TableCell align="left">
        {assessment.internalTrainerId && (
          <StaffNameText {...{ staffId: assessment.internalTrainerId }} />
        )}
        {assessment.externalTrainer ? assessment.externalTrainer : ""}
        {assessment.internalTrainerId == null &&
          assessment.externalTrainer == null &&
          "-"}
      </TableCell>
      <TableCell align="left">
        {assessment.type
          ? `${getAssessmentTypeTranslation(assessment.type)} ${
              t.competencyManagement.assessmentText
            }`
          : "-"}
      </TableCell>
      <TableCell align="left">
        {formatDateTime(assessment.scheduledStartDate)}
      </TableCell>
      <TableCell align="left">
        {getAssessmentStateTranslation(assessment.state)}
      </TableCell>
      <TableCell align="right">
        <AssessmentDeleteButtonContainer />
      </TableCell>
    </TableRow>
  );
};

export default React.memo(MyCompetencyManagementListTableRowAssessment);
