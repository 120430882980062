import React, { useContext } from "react";
import { getFieldMetaData as getFieldMetaDataHelper } from "module/fieldMetaData/getFieldMetaData";
import InventoryCheckOpenCloseContext from "module/inventoryCheck/openClose/context/InventoryCheckOpenCloseContext";
import InventoryCheckStateContext from "module/inventoryCheck/state/context/InventoryCheckStateContext";
import MyInventoryCheckArticleFieldMetaDataContext, {
  InventoryCheckArticleFieldMetaDataContextInterface,
} from "module/inventoryCheckArticle/validation/fieldMetaData/InventoryCheckArticleFieldMetaDataContext";

import InventoryCheckArticleValidationResultContext from "module/inventoryCheckArticle/validation/InventoryCheckArticleValidationResultContext";

interface Prop {
  children: React.ReactNode;
}

const MyInventoryCheckArticleFieldMetaDataContextProvider: React.FC<Prop> = ({
  children,
}) => {
  const { validationWarnings, validationErrors } = useContext(
    InventoryCheckArticleValidationResultContext
  );
  const { isOpen } = useContext(InventoryCheckOpenCloseContext);
  const { isStateScheduledOrLower } = useContext(InventoryCheckStateContext);

  const getFieldMetaData = getFieldMetaDataHelper({
    validationWarnings,
    validationErrors,
    isOpen,
    isStateScheduledOrLower,
  });

  const contextValue: InventoryCheckArticleFieldMetaDataContextInterface = {
    getFieldMetaData,
  };

  return (
    <MyInventoryCheckArticleFieldMetaDataContext.Provider value={contextValue}>
      {children}
    </MyInventoryCheckArticleFieldMetaDataContext.Provider>
  );
};

export default MyInventoryCheckArticleFieldMetaDataContextProvider;
