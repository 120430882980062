import React, { useContext } from "react";
import MaintenanceTaskContext from "module/maintenanceTask/context/MaintenanceTaskContext";
import { MaintenanceTaskInterface } from "module/maintenanceTask/types";
import XLabelField from "component/labelField/XLabelField";
import t from "module/translations";
import { getMaintenanceTaskStateTranslation } from "module/maintenanceTask/helper/getMaintenanceTaskStateTranslation";

const MaintenanceTaskStateLabelFieldContainer: React.FC = () => {
  const { entity } = useContext(MaintenanceTaskContext);
  const { state } = entity as MaintenanceTaskInterface;

  return (
    <XLabelField
      {...{
        caption: t.maintenanceTask.stateText,
        label: getMaintenanceTaskStateTranslation(state),
      }}
    />
  );
};

export default React.memo(MaintenanceTaskStateLabelFieldContainer);
