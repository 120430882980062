import React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import StickyPaperTableContainer from "component/sticky/StickyPaperTableContainer";
import { EntityIdentifier } from "module/entity/types";
import { useMaintenanceTaskPartListStyles } from "module/maintenanceTaskPart/enrichedComponent/table/styles";
import { getEntityIdentifierKey } from "module/entity/helper/getEntityIdentifierKey";
import MaintenanceTaskPartContextProvider from "module/maintenanceTaskPart/context/MaintenanceTaskPartContextProvider";
import MaintenanceTaskProcessStepListTableHead from "module/maintenanceTaskPart/enrichedComponent/table/MaintenanceTaskPartListTableHead";
import MaintenanceTaskPartListTableRow from "module/maintenanceTaskPart/enrichedComponent/table/MaintenanceTaskPartListTableRow";

interface Props {
  entityIdentifiers: EntityIdentifier[];
}

const MaintenanceTaskToBeSignedPartList: React.FC<Props> = ({
  entityIdentifiers,
}) => {
  const classes = useMaintenanceTaskPartListStyles();
  const isActionAvailable = false;
  return (
    <StickyPaperTableContainer>
      <Table stickyHeader className={classes.table}>
        <MaintenanceTaskProcessStepListTableHead {...{ isActionAvailable }} />
        <TableBody>
          {entityIdentifiers.map((entityIdentifier) => (
            <MaintenanceTaskPartContextProvider
              {...{
                key: getEntityIdentifierKey(entityIdentifier),
                entityIdentifier,
              }}
            >
              <MaintenanceTaskPartListTableRow {...{ isActionAvailable }} />
            </MaintenanceTaskPartContextProvider>
          ))}
        </TableBody>
      </Table>
    </StickyPaperTableContainer>
  );
};

export default React.memo(MaintenanceTaskToBeSignedPartList);
