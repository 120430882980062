import React, { useContext } from "react";
import useMyTrainingCustomOpenClose from "module/trainingCustom/openClose/useMyTrainingCustomOpenClose";
import TrainingCustomOpenCloseContext, {
  TrainingCustomOpenCloseContextInterface,
} from "module/trainingCustom/openClose/context/TrainingCustomOpenCloseContext";
import TrainingCustomContext from "module/trainingCustom/context/TrainingCustomContext";
import { TrainingCustomInterface } from "module/trainingCustom/types";

interface Prop {
  children: React.ReactNode;
}

const MyTrainingCustomOpenCloseContextProvider: React.FC<Prop> = ({
  children,
}) => {
  const { entity } = useContext(TrainingCustomContext);

  const { open, close } = useMyTrainingCustomOpenClose(
    entity as TrainingCustomInterface
  );

  const contextValue: TrainingCustomOpenCloseContextInterface = {
    isOpen: close !== undefined,
    open,
    close,
  };

  return (
    <TrainingCustomOpenCloseContext.Provider value={contextValue}>
      {children}
    </TrainingCustomOpenCloseContext.Provider>
  );
};

export default MyTrainingCustomOpenCloseContextProvider;
