import React, { useContext } from "react";
import MaintenanceTaskPersonnelAssignmentInternalStaffContext from "module/maintenanceTaskPersonnelAssignment/internalStaff/context/MaintenanceTaskPersonnelAssignmentInternalStaffContext";
import { MaintenanceTaskPersonnelAssignmentInternalStaffInterface } from "module/maintenanceTaskPersonnelAssignment/internalStaff/types";
import XLabelField from "component/labelField/XLabelField";
import { getFieldMetaDataWithErrorHandling } from "module/fieldMetaData/getFieldMetaData";
import MaintenanceTaskPersonnelAssignmentInternalStaffFieldMetaDataContext from "module/maintenanceTaskPersonnelAssignment/internalStaff/validation/fieldMetaData/MaintenanceTaskPersonnelAssignmentInternalStaffFieldMetaDataContext";
import { useAppSelector } from "redux/hooks";
import { selectStaffById } from "module/auth/redux/authSlice";
import { MAINTENANCE_TASK_PERSONNEL_ASSIGNMENT_INTERNAL_STAFF_POSITION_FIELD_NAME } from "module/maintenanceTaskPersonnelAssignment/internalStaff/constants";
import t from "module/translations";

const MaintenanceTaskPersonnelAssignmentInternalStaffPositionContainer: React.FC =
  () => {
    const { entity } = useContext(
      MaintenanceTaskPersonnelAssignmentInternalStaffContext
    );
    const maintenanceTaskPersonnelAssignmentInternalStaff =
      entity as MaintenanceTaskPersonnelAssignmentInternalStaffInterface;

    const selectStaffByIdHelper = useAppSelector(selectStaffById);

    const { getFieldMetaData } = useContext(
      MaintenanceTaskPersonnelAssignmentInternalStaffFieldMetaDataContext
    );
    const { warnings, errors } = getFieldMetaDataWithErrorHandling(() =>
      getFieldMetaData(
        MAINTENANCE_TASK_PERSONNEL_ASSIGNMENT_INTERNAL_STAFF_POSITION_FIELD_NAME
      )
    );

    let label;
    if (maintenanceTaskPersonnelAssignmentInternalStaff.staffId != null) {
      try {
        const staff = selectStaffByIdHelper(
          maintenanceTaskPersonnelAssignmentInternalStaff.staffId
        );
        label = staff.position;
      } catch (e) {
        label = t.staffDeletedText;
      }
    }

    return <XLabelField {...{ label, errors, warnings }} />;
  };

export default React.memo(
  MaintenanceTaskPersonnelAssignmentInternalStaffPositionContainer
);
