import React from "react";
import { EntityChangeContextInterface } from "module/entity/context/change/EntityChangeContext";

export type MaintenanceTaskPersonnelAssignmentInternalStaffChangeContextInterface =
  EntityChangeContextInterface;

const MaintenanceTaskPersonnelAssignmentInternalStaffChangeContext =
  React.createContext(
    <MaintenanceTaskPersonnelAssignmentInternalStaffChangeContextInterface>{}
  );

export default MaintenanceTaskPersonnelAssignmentInternalStaffChangeContext;
