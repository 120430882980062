import React from "react";
import { EntityIdentifier } from "module/entity/types";
import MyEntityContextProvider from "module/entity/context/entity/MyEntityContextProvider";
import { getEntityIdentifierKey } from "module/entity/helper/getEntityIdentifierKey";
import MyEntityValidationResultContextProvider from "module/entity/context/validationResult/MyEntityValidationResultContextProvider";
import MyEntityChangeContextProvider from "module/entity/context/change/MyEntityChangeContextProvider";
import MaintenanceTaskPersonnelAssignmentInternalStaffContext from "module/maintenanceTaskPersonnelAssignment/internalStaff/context/MaintenanceTaskPersonnelAssignmentInternalStaffContext";
import MaintenanceTaskPersonnelAssignmentInternalStaffValidationResultContext from "module/maintenanceTaskPersonnelAssignment/internalStaff/validation/MaintenanceTaskPersonnelAssignmentInternalStaffValidationResultContext";
import MyMaintenanceTaskPersonnelAssignmentInternalStaffFieldMetaDataContextProvider from "module/maintenanceTaskPersonnelAssignment/internalStaff/validation/fieldMetaData/MyMaintenanceTaskPersonnelAssignmentInternalStaffFieldMetaDataContextProvider";
import MaintenanceTaskPersonnelAssignmentInternalStaffChangeContext from "module/maintenanceTaskPersonnelAssignment/internalStaff/change/MaintenanceTaskPersonnelAssignmentInternalStaffChangeContext";

interface Props {
  entityIdentifier: EntityIdentifier;
  children: React.ReactNode;
}

const MyMaintenanceTaskPersonnelAssignmentInternalListRowContextProvider: React.FC<Props> =
  ({ entityIdentifier, children }) => {
    return (
      <MyEntityContextProvider
        {...{
          entityIdentifier,
          context: MaintenanceTaskPersonnelAssignmentInternalStaffContext,
          key: getEntityIdentifierKey(entityIdentifier),
        }}
      >
        <MyEntityValidationResultContextProvider
          {...{
            entityIdentifier,
            context:
              MaintenanceTaskPersonnelAssignmentInternalStaffValidationResultContext,
          }}
        >
          <MyMaintenanceTaskPersonnelAssignmentInternalStaffFieldMetaDataContextProvider>
            <MyEntityChangeContextProvider
              {...{
                entityIdentifier,
                context:
                  MaintenanceTaskPersonnelAssignmentInternalStaffChangeContext,
              }}
            >
              {children}
            </MyEntityChangeContextProvider>
          </MyMaintenanceTaskPersonnelAssignmentInternalStaffFieldMetaDataContextProvider>
        </MyEntityValidationResultContextProvider>
      </MyEntityContextProvider>
    );
  };

export default MyMaintenanceTaskPersonnelAssignmentInternalListRowContextProvider;
