import React, { useContext } from "react";
import { TrainingSessionInterface } from "module/training/types";
import TrainingSessionContext from "module/trainingSession/context/TrainingSessionContext";
import TrainingSessionTableRowItem from "module/trainingSession/enrichedComponent/tableRow/item/TrainingSessionTableRowItem";

const TrainingSessionTableRowItemContainer: React.FC = () => {
  const { entity } = useContext(TrainingSessionContext);

  const trainingSession = entity as TrainingSessionInterface;
  const { name, level } = trainingSession;

  return <TrainingSessionTableRowItem {...{ name, level }} />;
};

export default React.memo(TrainingSessionTableRowItemContainer);
