import React from "react";
import { EntityIdentifier } from "module/entity/types";
import MyEntityContextProvider from "module/entity/context/entity/MyEntityContextProvider";
import MyEntityChangeContextProvider from "module/entity/context/change/MyEntityChangeContextProvider";
import MyAssessmentStateContextProvider from "module/assessment/state/context/MyAssessmentStateContextProvider";
import AssessmentContext from "module/assessment/context/AssessmentContext";
import MyAssessmentFieldMetaDataContextProvider from "module/assessment/validation/fieldMetaData/MyAssessmentFieldMetaDataContextProvider";
import AssessmentChangeContext from "module/assessment/change/AssessmentChangeContext";
import MyAssessmentOpenCloseContextProvider from "module/assessment/openClose/context/MyAssessmentOpenCloseContextProvider";
import MyEntityValidationResultContextProvider from "module/entity/context/validationResult/MyEntityValidationResultContextProvider";
import AssessmentValidationResultContext from "module/assessment/validation/AssessmentValidationResultContext";

interface MyAssessmentDetailsContextProviderInterface {
  entityIdentifier: EntityIdentifier;
  children: React.ReactNode;
}

const MyAssessmentDetailsContextProvider: React.FC<MyAssessmentDetailsContextProviderInterface> =
  ({ children, entityIdentifier }) => {
    return (
      <MyEntityContextProvider
        {...{ entityIdentifier, context: AssessmentContext }}
      >
        <MyEntityValidationResultContextProvider
          {...{ entityIdentifier, context: AssessmentValidationResultContext }}
        >
          <MyAssessmentOpenCloseContextProvider>
            <MyAssessmentStateContextProvider>
              <MyAssessmentFieldMetaDataContextProvider>
                <MyEntityChangeContextProvider
                  {...{ entityIdentifier, context: AssessmentChangeContext }}
                >
                  {children}
                </MyEntityChangeContextProvider>
              </MyAssessmentFieldMetaDataContextProvider>
            </MyAssessmentStateContextProvider>
          </MyAssessmentOpenCloseContextProvider>
        </MyEntityValidationResultContextProvider>
      </MyEntityContextProvider>
    );
  };

export default MyAssessmentDetailsContextProvider;
