import axios from "axios";
import { UserInterface } from "module/auth/types";
import { mapApiResponse } from "api/auth/getMe/mappers";

const getMe = async (): Promise<UserInterface> => {
  const response = await axios.get("/api/me");
  return mapApiResponse(response.data);
};

export default getMe;
