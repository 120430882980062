import { useCallback } from "react";
import { useAppDispatch, useAppSelector } from "redux/hooks";
import { selectSystem, selectUser } from "module/auth/redux/authSlice";
import { closeEntity, openEntity } from "module/staff/redux/staffDataSlice";
import { EntityIdentifier } from "module/entity/types";
import selectIsEntityOpen from "module/staff/redux/selector/selectIsEntityOpen";

interface Result {
  canOpen: boolean;
  open: () => void;
  canClose: boolean;
  close: () => void;
}

const useEntityOpenClose = (entityIdentifier: EntityIdentifier): Result => {
  const dispatch = useAppDispatch();
  const staffId = useAppSelector(selectUser).id;
  const systemShortName = useAppSelector(selectSystem).shortName;
  const isOpen = useAppSelector(selectIsEntityOpen)(entityIdentifier);

  const canOpen = !isOpen;
  const open = useCallback(() => {
    dispatch(
      openEntity({
        systemShortName,
        staffId,
        entityIdentifier,
      })
    );
  }, [dispatch, entityIdentifier, staffId, systemShortName]);

  const canClose = isOpen;
  const close = useCallback(() => {
    dispatch(
      closeEntity({
        systemShortName,
        staffId,
        entityIdentifier,
      })
    );
  }, [dispatch, entityIdentifier, staffId, systemShortName]);

  return {
    canOpen,
    open,
    canClose,
    close,
  };
};

export default useEntityOpenClose;
