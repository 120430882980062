import React, { useEffect, useMemo } from "react";
import { useHistory, useParams, useRouteMatch } from "react-router-dom";
import routes from "routes/routes";
import { getPathToMaintenanceTaskDetailsOverview } from "module/maintenanceTask/helper/getPath";
import { EntityType } from "module/entity/types";
import MyMaintenanceTaskDetailsContainer from "pages/myMaintenanceTaskDetails/MyMaintenanceTaskDetailsContainer";
import MyMaintenanceTaskDetailsContextProvider from "pages/myMaintenanceTaskDetails/MyMaintenanceTaskDetailsContextProvider";

const MyMaintenanceTaskDetailsPage: React.FC = () => {
  const { maintenanceTaskId: id } = useParams<{ maintenanceTaskId: string }>();
  const entityIdentifier = useMemo(
    () => ({
      id,
      type: EntityType.maintenanceTask,
    }),
    [id]
  );
  const history = useHistory();
  const match = useRouteMatch({
    path: routes.myMaintenanceTaskDetails.path,
    exact: true,
  });

  useEffect(() => {
    if (id && match) {
      history.push(getPathToMaintenanceTaskDetailsOverview(id));
    }
  }, [history, id, match]);

  return (
    <MyMaintenanceTaskDetailsContextProvider {...{ entityIdentifier }}>
      <MyMaintenanceTaskDetailsContainer />
    </MyMaintenanceTaskDetailsContextProvider>
  );
};

export default React.memo(MyMaintenanceTaskDetailsPage);
