import { useCallback } from "react";
import { useAppSelector } from "redux/hooks";
import { EntityType } from "module/entity/types";
import selectEntityHelper from "module/staff/redux/selector/selectEntity";
import {
  MaintenanceTaskToSyncInterface,
  MyMaintenanceTaskInterface,
} from "module/maintenanceTask/types";
import { MaintenanceTaskProcessStepInterface } from "module/maintenanceTaskProcessStep/types";
import { mapMaintenanceTaskToSync } from "module/maintenanceTask/sync/mapper";
import {
  MaintenanceTaskProcessStepQuickFixInterface,
  MyMaintenanceTaskProcessStepQuickFixInterface,
} from "module/maintenanceTaskProcessStepQuickFix/types";
import {
  MaintenanceTaskPartInterface,
  MyMaintenanceTaskPartInterface,
} from "module/maintenanceTaskPart/types";
import { MyMaintenanceTaskPersonnelAssignmentInterface } from "module/maintenanceTaskPersonnelAssignment/types";
import {
  AttachmentInterface,
  MyAttachmentInterface,
} from "module/attachment/types";

interface Result {
  geMaintenanceTaskMappedToSync: (id: string) => MaintenanceTaskToSyncInterface;
}

const useMaintenanceTaskSyncHelper = (): Result => {
  const selectEntity = useAppSelector(selectEntityHelper);

  const getMaintenanceTaskProcessSteps = useCallback(
    (myMaintenanceTask: MyMaintenanceTaskInterface) => {
      return myMaintenanceTask.data.processSteps.map(
        (processStepEntityIdentifier) =>
          selectEntity(processStepEntityIdentifier)
            .data as MaintenanceTaskProcessStepInterface
      );
    },
    [selectEntity]
  );

  const getMaintenanceTaskProcessStepQuickFixesByMaintenanceTaskProcessStepId =
    useCallback(
      (maintenanceTaskProcessSteps: MaintenanceTaskProcessStepInterface[]) => {
        return maintenanceTaskProcessSteps.reduce(
          (result, maintenanceTaskProcessStep) => {
            if (maintenanceTaskProcessStep.quickFix != null) {
              const myMaintenanceTaskProcessStepQuickFix = selectEntity(
                maintenanceTaskProcessStep.quickFix
              ) as MyMaintenanceTaskProcessStepQuickFixInterface;

              return {
                ...result,
                [maintenanceTaskProcessStep.id]:
                  myMaintenanceTaskProcessStepQuickFix.data,
              };
            }
            return result;
          },
          {} as {
            [
              maintenanceTaskProcessStepId: string
            ]: MaintenanceTaskProcessStepQuickFixInterface;
          }
        );
      },
      [selectEntity]
    );

  const getMyMaintenanceTaskProcessStepsCorrectivesByMaintenanceTaskProcessStepId =
    useCallback(
      (maintenanceTaskProcessSteps: MaintenanceTaskProcessStepInterface[]) =>
        maintenanceTaskProcessSteps.reduce(
          (result, maintenanceTaskProcessStep) => {
            if (maintenanceTaskProcessStep.correctiveMaintenanceTask != null) {
              const myMaintenanceTaskProcessStepCorrective = selectEntity(
                maintenanceTaskProcessStep.correctiveMaintenanceTask
              ) as MyMaintenanceTaskInterface;

              return {
                ...result,
                [maintenanceTaskProcessStep.id]:
                  myMaintenanceTaskProcessStepCorrective,
              };
            }
            return result;
          },
          {} as {
            [maintenanceTaskProcessStepId: string]: MyMaintenanceTaskInterface;
          }
        ),
      [selectEntity]
    );

  const getMaintenanceTaskProcessStepsAttachmentsByMaintenanceTaskProcessStepId =
    useCallback(
      (maintenanceTaskProcessSteps: MaintenanceTaskProcessStepInterface[]) =>
        maintenanceTaskProcessSteps.reduce(
          (result, maintenanceTaskProcessStep) => {
            const maintenanceTaskProcessStepAttachments =
              maintenanceTaskProcessStep.attachments == null
                ? []
                : maintenanceTaskProcessStep.attachments.map(
                    (attachmentEntityIdentifier) => {
                      const myAttachment = selectEntity(
                        attachmentEntityIdentifier
                      ) as MyAttachmentInterface;

                      return myAttachment.data;
                    }
                  );

            return {
              ...result,
              [maintenanceTaskProcessStep.id]:
                maintenanceTaskProcessStepAttachments,
            };
          },
          {} as {
            [maintenanceTaskProcessStepId: string]: AttachmentInterface[];
          }
        ),
      [selectEntity]
    );

  const getMaintenanceTaskParts = useCallback(
    (
      myMaintenanceTask: MyMaintenanceTaskInterface
    ): MaintenanceTaskPartInterface[] =>
      myMaintenanceTask.data.parts.map(
        (maintenanceTaskPartEntityIdentifier) => {
          const myMaintenanceTaskPart = selectEntity(
            maintenanceTaskPartEntityIdentifier
          ) as MyMaintenanceTaskPartInterface;

          return myMaintenanceTaskPart.data;
        }
      ),
    [selectEntity]
  );

  const getMyMaintenanceTaskPersonnelAssignments = useCallback(
    (
      myMaintenanceTask: MyMaintenanceTaskInterface
    ): MyMaintenanceTaskPersonnelAssignmentInterface[] =>
      myMaintenanceTask.data.personnelAssignments.map(
        (personnelAssignmentEntityIdentifier) => {
          return selectEntity(
            personnelAssignmentEntityIdentifier
          ) as MyMaintenanceTaskPersonnelAssignmentInterface;
        }
      ),
    [selectEntity]
  );

  const getAttachments = useCallback(
    (myMaintenanceTask: MyMaintenanceTaskInterface) =>
      myMaintenanceTask.data.attachments.map((attachmentEntityIdentifier) => {
        const myAttachment = selectEntity(
          attachmentEntityIdentifier
        ) as MyAttachmentInterface;

        return myAttachment.data;
      }),
    [selectEntity]
  );

  const geMaintenanceTaskMappedToSync = useCallback(
    (id: string): MaintenanceTaskToSyncInterface => {
      const myMaintenanceTask = selectEntity({
        id,
        type: EntityType.maintenanceTask,
      }) as MyMaintenanceTaskInterface;
      const maintenanceTaskProcessSteps =
        getMaintenanceTaskProcessSteps(myMaintenanceTask);

      const maintenanceTaskProcessStepsQuickFixesByMaintenanceTaskProcessStepId =
        getMaintenanceTaskProcessStepQuickFixesByMaintenanceTaskProcessStepId(
          maintenanceTaskProcessSteps
        );

      const myMaintenanceTaskProcessStepsCorrectivesByMaintenanceTaskProcessStepId =
        getMyMaintenanceTaskProcessStepsCorrectivesByMaintenanceTaskProcessStepId(
          maintenanceTaskProcessSteps
        );

      const maintenanceTaskProcessStepsAttachmentsByMaintenanceTaskProcessStepId =
        getMaintenanceTaskProcessStepsAttachmentsByMaintenanceTaskProcessStepId(
          maintenanceTaskProcessSteps
        );

      const maintenanceTaskParts = getMaintenanceTaskParts(myMaintenanceTask);

      const myMaintenanceTaskPersonnelAssignments =
        getMyMaintenanceTaskPersonnelAssignments(myMaintenanceTask);

      const attachments = getAttachments(myMaintenanceTask);

      // without process step correctives
      const maintenanceTaskToSync = mapMaintenanceTaskToSync(
        myMaintenanceTask,
        maintenanceTaskProcessSteps,
        maintenanceTaskProcessStepsQuickFixesByMaintenanceTaskProcessStepId,
        maintenanceTaskProcessStepsAttachmentsByMaintenanceTaskProcessStepId,
        maintenanceTaskParts,
        myMaintenanceTaskPersonnelAssignments,
        attachments
      );

      // with process step correctives
      return {
        ...maintenanceTaskToSync,
        data: {
          ...maintenanceTaskToSync.data,
          processSteps: maintenanceTaskToSync.data.processSteps.map(
            (processStep) => {
              const myCorrectiveMaintenanceTask =
                myMaintenanceTaskProcessStepsCorrectivesByMaintenanceTaskProcessStepId[
                  processStep.id
                ];

              if (myCorrectiveMaintenanceTask == null) {
                return processStep;
              }

              const correctiveMaintenanceTaskProcessSteps =
                getMaintenanceTaskProcessSteps(myCorrectiveMaintenanceTask);

              const correctiveMaintenanceTaskProcessStepsQuickFixesByMaintenanceTaskProcessStepId =
                getMaintenanceTaskProcessStepQuickFixesByMaintenanceTaskProcessStepId(
                  correctiveMaintenanceTaskProcessSteps
                );

              const correctiveMaintenanceTaskProcessStepsAttachmentsByMaintenanceTaskProcessStepId =
                getMaintenanceTaskProcessStepsAttachmentsByMaintenanceTaskProcessStepId(
                  correctiveMaintenanceTaskProcessSteps
                );

              const correctiveMaintenanceTaskParts = getMaintenanceTaskParts(
                myCorrectiveMaintenanceTask
              );

              const myCorrectiveMaintenanceTaskPersonnelAssignments =
                getMyMaintenanceTaskPersonnelAssignments(
                  myCorrectiveMaintenanceTask
                );

              const correctiveAttachments = getAttachments(
                myCorrectiveMaintenanceTask
              );

              const correctiveMaintenanceTaskToSync =
                myCorrectiveMaintenanceTask
                  ? mapMaintenanceTaskToSync(
                      myCorrectiveMaintenanceTask,
                      correctiveMaintenanceTaskProcessSteps,
                      correctiveMaintenanceTaskProcessStepsQuickFixesByMaintenanceTaskProcessStepId,
                      correctiveMaintenanceTaskProcessStepsAttachmentsByMaintenanceTaskProcessStepId,
                      correctiveMaintenanceTaskParts,
                      myCorrectiveMaintenanceTaskPersonnelAssignments,
                      correctiveAttachments
                    )
                  : null;

              return {
                ...processStep,
                correctiveMaintenanceTask: correctiveMaintenanceTaskToSync,
              };
            }
          ),
        },
      };
    },
    [
      getAttachments,
      getMaintenanceTaskParts,
      getMaintenanceTaskProcessStepQuickFixesByMaintenanceTaskProcessStepId,
      getMaintenanceTaskProcessSteps,
      getMaintenanceTaskProcessStepsAttachmentsByMaintenanceTaskProcessStepId,
      getMyMaintenanceTaskProcessStepsCorrectivesByMaintenanceTaskProcessStepId,
      getMyMaintenanceTaskPersonnelAssignments,
      selectEntity,
    ]
  );

  return {
    geMaintenanceTaskMappedToSync,
  };
};

export default useMaintenanceTaskSyncHelper;
