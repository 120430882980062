import { MaintenanceTaskPersonnelAssignmentResponsibility } from "module/maintenanceTaskPersonnelAssignment/types";
import t from "module/translations";

export const getMaintenanceTaskPersonnelAssignmentResponsibilityTranslation = (
  responsibility: MaintenanceTaskPersonnelAssignmentResponsibility
): string =>
  ({
    [MaintenanceTaskPersonnelAssignmentResponsibility.LEAD]:
      t.maintenanceTask.personnelAssignments.responsibility.leadText,
    [MaintenanceTaskPersonnelAssignmentResponsibility.SUPPORT]:
      t.maintenanceTask.personnelAssignments.responsibility.supportText,
    [MaintenanceTaskPersonnelAssignmentResponsibility.HELPER]:
      t.maintenanceTask.personnelAssignments.responsibility.helperText,
    [MaintenanceTaskPersonnelAssignmentResponsibility.TRAINEE]:
      t.maintenanceTask.personnelAssignments.responsibility.traineeText,
  }[responsibility]);
