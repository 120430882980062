import React from "react";
import { EntityChangeContextInterface } from "module/entity/context/change/EntityChangeContext";

export type MaintenanceTaskProcessStepQuickFixChangeContextInterface =
  EntityChangeContextInterface;

const MaintenanceTaskProcessStepQuickFixChangeContext = React.createContext(
  <MaintenanceTaskProcessStepQuickFixChangeContextInterface>{}
);

export default MaintenanceTaskProcessStepQuickFixChangeContext;
