import React from "react";
import { Route } from "react-router-dom";
import FormContainsErrors from "component/form/FormContainsErrors";
import ButtonsContainer from "component/button/ButtonsContainer";
import OpenButton from "component/button/OpenButton";
import CloseButton from "component/button/CloseButton";
import TrainingStateEditableFieldContainer from "module/training/enrichedComponent/stateField/TrainingStateEditableFieldContainer";
import TrainingDetailsNavigation from "module/training/enrichedComponent/detailsNavigation/TrainingDetailsNavigation";
import routes from "routes/routes";
import {
  getPathToTrainingGroupDetailsOverview,
  getPathToTrainingGroupDetailsSessions,
} from "module/training/helper/getPath";

interface Props {
  trainingsIds: string[];
  hasError: boolean;
  open?: () => void;
  close?: () => void;
  isStateEditable: boolean;
}

const MyTrainingGroupDetails: React.FC<Props> = ({
  trainingsIds,
  hasError,
  open,
  close,
  isStateEditable,
}) => {
  return (
    <>
      {hasError && <FormContainsErrors />}
      <TrainingDetailsNavigation
        {...{
          listPath: routes.myCompetencyManagementResources.path,
          overviewPath: getPathToTrainingGroupDetailsOverview(trainingsIds),
          sessionsPath: getPathToTrainingGroupDetailsSessions(trainingsIds),
        }}
      />
      <Route
        {...{
          key: routes.myTrainingGroupDetailsOverview.path,
          path: routes.myTrainingGroupDetailsOverview.path,
          render: () => <routes.myTrainingGroupDetailsOverview.mainComponent />,
        }}
      />
      <Route
        {...{
          key: routes.myTrainingGroupDetailsSessions.path,
          path: routes.myTrainingGroupDetailsSessions.path,
          render: () => <routes.myTrainingGroupDetailsSessions.mainComponent />,
        }}
      />
      {(open || close) && (
        <ButtonsContainer>
          {open && <OpenButton {...{ onClick: open }} />}
          {close && <CloseButton {...{ onClick: close }} />}
          {isStateEditable && <TrainingStateEditableFieldContainer />}
        </ButtonsContainer>
      )}
    </>
  );
};

export default React.memo(MyTrainingGroupDetails);
