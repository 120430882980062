import React, { useContext, useMemo } from "react";
import TrainingOpenCloseContext from "module/training/openClose/context/TrainingOpenCloseContext";
import TrainingStateContext from "module/training/state/context/TrainingStateContext";
import TrainingValidationResultContext from "module/training/validation/TrainingValidationResultContext";
import MyTrainingGroupDetails from "pages/myTrainingGroupDetails/MyTrainingGroupDetails";
import EntityCollectionContext from "module/entity/context/entityCollection/EntityCollectionContext";

const MyTrainingGroupDetailsContainer: React.FC = () => {
  const { entityCollection } = useContext(EntityCollectionContext);
  const { hasError } = useContext(TrainingValidationResultContext);
  const { open, close } = useContext(TrainingOpenCloseContext);
  const { isStateEditable } = useContext(TrainingStateContext);

  const trainingsIds = useMemo(
    () =>
      Array.from(entityCollection.keys()).map(
        (entityIdentifier) => entityIdentifier.id
      ),
    [entityCollection]
  );

  return (
    <MyTrainingGroupDetails
      {...{
        trainingsIds,
        hasError,
        open,
        close,
        isStateEditable,
      }}
    />
  );
};

export default React.memo(MyTrainingGroupDetailsContainer);
