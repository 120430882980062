import React from "react";
import { EntityFieldMetaDataContextInterface } from "module/entity/context/fieldMetaData/EntityFieldMetaDataContext";

export type InventoryCheckFieldMetaDataContextInterface =
  EntityFieldMetaDataContextInterface;

const InventoryCheckFieldMetaDataContext = React.createContext(
  <InventoryCheckFieldMetaDataContextInterface>{}
);

export default InventoryCheckFieldMetaDataContext;
