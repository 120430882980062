import React, { useContext } from "react";
import useDateFormat from "toolkit/useDateFormat";
import t from "module/translations";
import XLabelField from "component/labelField/XLabelField";
import TrainingCustomContext from "module/trainingCustom/context/TrainingCustomContext";
import { TrainingCustomInterface } from "module/trainingCustom/types";

const TrainingCustomScheduledDateFieldContainer: React.FC = () => {
  const { entity } = useContext(TrainingCustomContext);
  const { formatDate } = useDateFormat();

  const { scheduledDate } = entity as TrainingCustomInterface;

  return (
    <XLabelField
      {...{
        caption: t.competencyManagement.trainingCustom.scheduledText,
        label: formatDate(scheduledDate),
      }}
    />
  );
};

export default React.memo(TrainingCustomScheduledDateFieldContainer);
