import React, { useEffect, useState, useCallback } from "react";
import MUITextField from "@mui/material/TextField";
import useDebounce from "toolkit/useDebounce";
import XValidationMessage from "module/validation/XValidationMessage";

interface Props {
  label?: string;
  value?: number; // in seconds
  change: (newValue?: number) => void; // in seconds
  errors: string[];
}

const convertSecondsToString = (valueInSeconds: number | undefined) => {
  if (valueInSeconds == null) {
    return "";
  }
  const hours = Math.floor(valueInSeconds / (60 * 60));
  const minutes = Math.floor((valueInSeconds - hours * 60 * 60) / 60);
  return `${hours.toString().padStart(2, "0")}:${minutes
    .toString()
    .padStart(2, "0")}`;
};

const convertStringToSeconds = (valueAsString: string) => {
  const [hours, minutes] = valueAsString.split(":").map(Number);
  return hours * 60 * 60 + minutes * 60;
};

const DurationField: React.FC<Props> = ({ label, value, change, errors }) => {
  const [tempValue, setTempValue] = useState<string | undefined>(
    convertSecondsToString(value)
  );

  useEffect(() => {
    setTempValue(convertSecondsToString(value));
  }, [value]);

  const debounceHandleChange = useDebounce(change, 1500);

  const handleChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const newValue = convertStringToSeconds(event.target.value) || undefined;
      setTempValue(event.target.value);
      if (newValue) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        debounceHandleChange(newValue);
      }
    },
    [debounceHandleChange]
  );

  return (
    <MUITextField
      {...{
        label,
        value: tempValue,
        fullWidth: true,
        onChange: handleChange,
        variant: "standard",
        ...(errors.length
          ? {
              helperText: <XValidationMessage messages={errors} />,
              error: true,
            }
          : {}),
      }}
    />
  );
};

export default React.memo(DurationField);
