import React, { useContext } from "react";
import ToggleContext from "module/toggle/context/ToggleContext";
import usePermissions from "module/permission/usePermissions";
import MaintenanceTaskListTableHead from "pages/maintenanceTaskList/table/head/MaintenanceTaskListTableHead";

const MaintenanceTaskListTableHeadContainer: React.FC = () => {
  const { canEditMaintenanceTask } = usePermissions();
  const { toggleAll, areAllSelected, isAnySelected } =
    useContext(ToggleContext);

  return (
    <MaintenanceTaskListTableHead
      {...{
        areAllSelected,
        isAnySelected,
        toggleAll,
        hasPermissionToDownload: canEditMaintenanceTask,
      }}
    />
  );
};

export default MaintenanceTaskListTableHeadContainer;
