import { useCallback } from "react";
import { EntityFactory } from "module/entity/useEntityAbstractFactory";
import t from "module/translations";
import { getPathToTrainingCustomDetails } from "module/trainingCustom/helper/getPath";
import useMyTrainingCustomReadableIdentifier from "module/trainingCustom/readableIdentifier/useMyTrainingCustomReadableIdentifier";
import useTrainingCustomSyncHelper from "module/trainingCustom/sync/useTrainingCustomSyncHelper";
import useTrainingCustomIsSyncAvailable from "module/trainingCustom/sync/useTrainingCustomIsSyncAvailable";
import { getTrainingCustomSyncUnavailableReasonTranslation } from "module/trainingCustom/helper/getTrainingCustomSyncUnavailableReasonTranslation";
import { TrainingCustomSyncUnavailableReason } from "module/trainingCustom/types";

const useTrainingFactory = (): EntityFactory => {
  const { isTrainingCustomSyncAvailable } = useTrainingCustomIsSyncAvailable();
  const { geTrainingCustomMappedToSync } = useTrainingCustomSyncHelper();
  const { getReadableIdentifier } = useMyTrainingCustomReadableIdentifier();

  const getPathToMyGroupedDetails = useCallback((): string => {
    throw new Error("Not supported.");
  }, []);

  const areSimilar = useCallback((): boolean => {
    throw new Error("Not supported.");
  }, []);

  const getSyncUnavailableReasonText = useCallback(
    (reason: string): string =>
      getTrainingCustomSyncUnavailableReasonTranslation(
        reason as TrainingCustomSyncUnavailableReason
      ),
    []
  );

  return {
    getPathToMyDetails: getPathToTrainingCustomDetails,
    getPathToMyGroupedDetails,
    areSimilar,
    isSyncAvailable: isTrainingCustomSyncAvailable,
    getMappedToSync: geTrainingCustomMappedToSync,
    getReadableIdentifier,
    readableType: t.competencyManagement.trainingCustomText,
    getSyncUnavailableReasonText,
  };
};

export default useTrainingFactory;
