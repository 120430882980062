import React from "react";
import { EntityContextInterface } from "module/entity/context/entity/EntityContext";

export type TrainingSessionContextInterface = EntityContextInterface;

const TrainingSessionContext = React.createContext(
  <TrainingSessionContextInterface>{}
);

export default TrainingSessionContext;
