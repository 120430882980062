import React from "react";
import { EntityIdentifier } from "module/entity/types";
import MyEntityContextProvider from "module/entity/context/entity/MyEntityContextProvider";
import TrainingSessionContext from "module/trainingSession/context/TrainingSessionContext";
import MyTrainingSessionFieldMetaDataContextProvider from "module/trainingSession/validation/fieldMetaData/MyTrainingSessionFieldMetaDataContextProvider";
import MyEntityValidationResultContextProvider from "module/entity/context/validationResult/MyEntityValidationResultContextProvider";
import TrainingSessionValidationResultContext from "module/trainingSession/validation/TrainingSessionValidationResultContext";
import MyEntityChangeContextProvider from "module/entity/context/change/MyEntityChangeContextProvider";
import MyTrainingSessionChangeContext from "module/trainingSession/change/TrainingSessionChangeContext";

interface Prop {
  entityIdentifier: EntityIdentifier;
  children: React.ReactNode;
}

const MyTrainingDetailsSessionContextProvider: React.FC<Prop> = ({
  children,
  entityIdentifier,
}) => {
  return (
    <MyEntityContextProvider
      {...{
        entityIdentifier,
        context: TrainingSessionContext,
      }}
    >
      <MyEntityValidationResultContextProvider
        {...{
          entityIdentifier,
          context: TrainingSessionValidationResultContext,
        }}
      >
        <MyTrainingSessionFieldMetaDataContextProvider>
          <MyEntityChangeContextProvider
            {...{
              entityIdentifier,
              context: MyTrainingSessionChangeContext,
            }}
          >
            {children}
          </MyEntityChangeContextProvider>
        </MyTrainingSessionFieldMetaDataContextProvider>
      </MyEntityValidationResultContextProvider>
    </MyEntityContextProvider>
  );
};

export default MyTrainingDetailsSessionContextProvider;
