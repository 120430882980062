import { isStateScheduledOrLower } from "module/maintenanceTask/helper/isStateScheduledOrLower";
import { MaintenanceTaskInterface } from "module/maintenanceTask/types";

interface Props {
  isMaintenanceTaskOpen: boolean;
  maintenanceTask: MaintenanceTaskInterface;
}

export const canAddMaintenanceTaskPart = ({
  isMaintenanceTaskOpen,
  maintenanceTask,
}: Props): boolean =>
  isMaintenanceTaskOpen && isStateScheduledOrLower(maintenanceTask);
