import React from "react";
import Typography from "@mui/material/Typography";

interface Props {
  text: string;
}

const HeaderTitle: React.FC<Props> = ({ text }) => {
  return (
    <Typography variant="h6" noWrap>
      {text}
    </Typography>
  );
};

export default React.memo(HeaderTitle);
