import React, { useContext, useCallback } from "react";
import ToggleContext from "module/toggle/context/ToggleContext";
import ListToolbarButton from "component/listToolbar/ListToolbarButton";
import t from "module/translations";
import GetAppIcon from "@mui/icons-material/GetApp";
import FullScreenLoaderContext from "module/fullScreenLoader/context/FullScreenLoaderContext";
import EntityDownloadContext from "module/entity/context/download/EntityDownloadContext";

const ListToolbarDownloadButtonContainer: React.FC = () => {
  const { turnOn: turnLoaderOn, turnOff: turnLoaderOff } = useContext(
    FullScreenLoaderContext
  );
  const { download: downloadHelper } = useContext(EntityDownloadContext);
  const { selectedItems, setSelectedItems } = useContext(ToggleContext);

  const onClick = useCallback(async () => {
    turnLoaderOn();
    await downloadHelper(selectedItems);
    setSelectedItems([]);
    turnLoaderOff();
  }, [
    downloadHelper,
    selectedItems,
    setSelectedItems,
    turnLoaderOff,
    turnLoaderOn,
  ]);

  return (
    <ListToolbarButton
      {...{ title: t.downloadText, icon: <GetAppIcon />, onClick }}
    />
  );
};

export default React.memo(ListToolbarDownloadButtonContainer);
