import t from "module/translations";
import { useAppSelector } from "redux/hooks";
import { selectStaffById as selectStaffByIdHelper } from "module/auth/redux/authSlice";
import { InventoryCheckInterface } from "module/inventoryCheck/types";

const useGetInventoryCheckBlockedReasonText = (
  inventoryCheck: InventoryCheckInterface
): string | null => {
  const {
    isBlockedDetails: {
      blockedByStaffId,
      isBlockedByOtherStaff,
      isBlockedByMeOnOtherDevice,
      isBlockedByMeOnThisDevice,
      isBlockedBecauseItIsValidated,
      isBlockedBecauseItIsCompleted,
    },
  } = inventoryCheck;

  const selectStaffById = useAppSelector(selectStaffByIdHelper);

  if (isBlockedByMeOnThisDevice) {
    return t.inventoryCheck.blocked.byMeOnThisDeviceText;
  }
  if (isBlockedByMeOnOtherDevice) {
    return t.inventoryCheck.blocked.byMeOnOtherDeviceText;
  }
  if (isBlockedByOtherStaff && blockedByStaffId) {
    const { name: staffName } = selectStaffById(blockedByStaffId);
    return t.inventoryCheck.blocked.byOtherStaffText(staffName);
  }
  if (isBlockedBecauseItIsValidated) {
    return t.inventoryCheck.blocked.isBlockedBecauseItIsValidatedText;
  }

  if (isBlockedBecauseItIsCompleted) {
    return t.inventoryCheck.blocked.isBlockedBecauseItIsCompletedText;
  }

  return null;
};

export default useGetInventoryCheckBlockedReasonText;
