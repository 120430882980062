import React from "react";
import { Theme } from "@mui/material/styles";
import makeStyles from "@mui/styles/makeStyles";

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    padding: "24px 0 0 0",
    "& > *": {
      margin: theme.spacing(1),
    },
  },
}));

interface Props {
  children: React.ReactNode;
}

const ButtonsContainer: React.FC<Props> = ({ children }) => {
  const classes = useStyles();
  return <div className={classes.root}>{children}</div>;
};

export default React.memo(ButtonsContainer);
