import React from "react";
import Table from "@mui/material/Table";
import StickyPaperTableContainer from "component/sticky/StickyPaperTableContainer";
import { EntityIdentifier } from "module/entity/types";
import MaintenanceTaskProcessStepListTableHeadContainer from "module/maintenanceTaskProcessStep/enrichedComponent/tableHead/MaintenanceTaskProcessStepListTableHeadContainer";
import MaintenanceTaskProcessStepListRowContainer from "module/maintenanceTaskProcessStep/enrichedComponent/tableRow/MaintenanceTaskProcessStepListRowContainer";
import MyMaintenanceTaskProcessStepContextProvider from "module/maintenanceTaskProcessStep/context/MyMaintenanceTaskProcessStepContextProvider";
import { getEntityIdentifierKey } from "module/entity/helper/getEntityIdentifierKey";
import TableBody from "component/table/tableBody/TableBody";

interface Props {
  entityIdentifiers: EntityIdentifier[];
}

const MyMaintenanceTaskProcessStepList: React.FC<Props> = ({
  entityIdentifiers,
}) => {
  return (
    <StickyPaperTableContainer>
      <Table stickyHeader>
        <MaintenanceTaskProcessStepListTableHeadContainer />
        <TableBody>
          {entityIdentifiers.map((entityIdentifier) => (
            <MyMaintenanceTaskProcessStepContextProvider
              {...{
                entityIdentifier,
                key: getEntityIdentifierKey(entityIdentifier),
              }}
            >
              <MaintenanceTaskProcessStepListRowContainer
                {...{ key: getEntityIdentifierKey(entityIdentifier) }}
              />
            </MyMaintenanceTaskProcessStepContextProvider>
          ))}
        </TableBody>
      </Table>
    </StickyPaperTableContainer>
  );
};

export default React.memo(MyMaintenanceTaskProcessStepList);
