import React from "react";
import { EntityChangeContextInterface } from "module/entity/context/change/EntityChangeContext";

export type TrainingCustomChangeContextInterface = EntityChangeContextInterface;

const TrainingCustomChangeContext = React.createContext(
  <TrainingCustomChangeContextInterface>{}
);

export default TrainingCustomChangeContext;
