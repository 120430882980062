import React, { useMemo } from "react";
import MyMaintenanceTaskListContainer from "pages/myMaintenanceTaskList/MyMaintenanceTaskListContainer";
import SortContextProvider from "module/sort/context/SortContextProvider";
import { SortDirection } from "module/sort/types";
import {
  MaintenanceTaskDefinitionType,
  MaintenanceTaskSortProperty,
} from "pages/maintenanceTaskList/types";
import { useAppSelector } from "redux/hooks";
import selectEntitiesByTypes from "module/staff/redux/selector/selectEntitiesByTypes";
import { EntityType } from "module/entity/types";
import { MyMaintenanceTaskInterface } from "module/maintenanceTask/types";
import { filterOutMaintenanceTaskCreatedForOtherMaintenanceTaskProcessStep } from "module/maintenanceTask/helper/filterOutMaintenanceTaskCreatedForOtherMaintenanceTaskProcessStep";

const MySafetyMaintenanceTaskListPage: React.FC = () => {
  const myMaintenanceTasks = useAppSelector(
    selectEntitiesByTypes([EntityType.maintenanceTask])
  ) as MyMaintenanceTaskInterface[];

  const maintenanceTasks = useMemo(
    () =>
      myMaintenanceTasks
        .filter((myMaintenanceTask) =>
          [
            MaintenanceTaskDefinitionType.equipment,
            MaintenanceTaskDefinitionType.sms,
            MaintenanceTaskDefinitionType.audit,
          ].includes(
            myMaintenanceTask.data
              .definitionType as MaintenanceTaskDefinitionType
          )
        )
        .map(({ data }) => data)
        .filter(
          filterOutMaintenanceTaskCreatedForOtherMaintenanceTaskProcessStep
        ),
    [myMaintenanceTasks]
  );

  return (
    <SortContextProvider
      {...{
        initialValue: {
          fieldName: MaintenanceTaskSortProperty.scheduled,
          direction: SortDirection.asc,
        },
      }}
    >
      <MyMaintenanceTaskListContainer
        {...{
          maintenanceTasks,
        }}
      />
    </SortContextProvider>
  );
};

export default React.memo(MySafetyMaintenanceTaskListPage);
