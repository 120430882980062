import React from "react";
import MUIMenu from "@mui/material/Menu";

interface Props {
  isOpen: boolean;
  close: () => void;
  children: React.ReactNode;
}

const Menu: React.FC<Props> = ({ isOpen, close, children }) => {
  return (
    <MUIMenu
      anchorOrigin={{ vertical: "top", horizontal: "right" }}
      keepMounted
      transformOrigin={{ vertical: "top", horizontal: "right" }}
      open={isOpen}
      onClose={close}
    >
      {children}
    </MUIMenu>
  );
};

export default React.memo(Menu);
