import React, { useContext, useMemo } from "react";
import { EntityIdentifier } from "module/entity/types";
import {
  TrainingInterface,
  TrainingSessionInterface,
} from "module/training/types";
import TrainingContext from "module/training/context/TrainingContext";
import TrainingSessionContext from "module/trainingSession/context/TrainingSessionContext";
import EntityContextProvider from "module/entity/context/entity/EntityContextProvider";

interface TrainingSessionContextProviderProps {
  entityIdentifier: EntityIdentifier;
  children: React.ReactNode;
}

const TrainingSessionContextProvider: React.FC<TrainingSessionContextProviderProps> =
  ({ entityIdentifier, children }) => {
    const { entity } = useContext(TrainingContext);

    const training = entity as TrainingInterface;

    const session = useMemo(
      () =>
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        training.relationships.get(entityIdentifier)
          .data as TrainingSessionInterface,
      [entityIdentifier, training.relationships]
    );

    return (
      <EntityContextProvider
        {...{ entity: session, context: TrainingSessionContext }}
      >
        {children}
      </EntityContextProvider>
    );
  };

export default TrainingSessionContextProvider;
