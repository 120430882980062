import { XValidatorErrorsInterface } from "module/validation/types";
import {
  TrainingCustomAssigneeInterface,
  TrainingCustomInterface,
} from "module/trainingCustom/types";

export interface TrainingCustomAssigneeValidationResult {
  errors: null | XValidatorErrorsInterface;
  warnings: null | XValidatorErrorsInterface;
}

export const validateTrainingCustomAssignee = (
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  trainingCustom: TrainingCustomInterface,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  assignee: TrainingCustomAssigneeInterface
): TrainingCustomAssigneeValidationResult => {
  const errors: XValidatorErrorsInterface = {};
  const warnings: XValidatorErrorsInterface = {};

  return {
    errors: Object.keys(errors).length ? errors : null,
    warnings: Object.keys(warnings).length ? warnings : null,
  };
};
