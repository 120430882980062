import React, { useContext, useCallback } from "react";
import { useHistory } from "react-router-dom";
import ToggleContext from "module/toggle/context/ToggleContext";
import ListToolbarButton from "component/listToolbar/ListToolbarButton";
import t from "module/translations";
import EditIcon from "@mui/icons-material/Edit";
import { toastWarning } from "layout/toast/helper";
import useIsGroupEditPossible from "module/groupEdit/useIsGroupEditPossible";

const ListToolbarGroupEditButtonContainer: React.FC = () => {
  const history = useHistory();
  const { selectedItems } = useContext(ToggleContext);
  const { isGroupEditPossible } = useIsGroupEditPossible();

  const onClick = useCallback(() => {
    const isGroupEditPossibleResult = isGroupEditPossible(selectedItems);
    if (isGroupEditPossibleResult.areEntitiesSimilar) {
      history.push(isGroupEditPossibleResult.path);
    } else {
      toastWarning(isGroupEditPossibleResult.entitiesAreNotSimilarReasonText);
    }
  }, [history, isGroupEditPossible, selectedItems]);

  return (
    <ListToolbarButton
      {...{ title: t.groupEditText, icon: <EditIcon />, onClick }}
    />
  );
};

export default React.memo(ListToolbarGroupEditButtonContainer);
