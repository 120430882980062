import React, { useContext, useMemo, useCallback } from "react";
import AutocompleteField from "component/autocompleteField/AutocompleteField";
import MaintenanceTaskPersonnelAssignmentExternalStaffContext from "module/maintenanceTaskPersonnelAssignment/externalStaff/context/MaintenanceTaskPersonnelAssignmentExternalStaffContext";
import MaintenanceTaskPersonnelAssignmentExternalStaffChangeContext from "module/maintenanceTaskPersonnelAssignment/externalStaff/change/MaintenanceTaskPersonnelAssignmentExternalStaffChangeContext";
import MaintenanceTaskPersonnelAssignmentExternalStaffFieldMetaDataContext from "module/maintenanceTaskPersonnelAssignment/externalStaff/validation/fieldMetaData/MaintenanceTaskPersonnelAssignmentExternalStaffFieldMetaDataContext";
import { MaintenanceTaskPersonnelAssignmentExternalStaffInterface } from "module/maintenanceTaskPersonnelAssignment/externalStaff/types";
import { getMaintenanceTaskPersonnelAssignmentResponsibilityTranslation } from "module/maintenanceTaskPersonnelAssignment/helper/getMaintenanceTaskPersonnelAssignmentResponsibilityTranslation";
import { MaintenanceTaskPersonnelAssignmentResponsibility } from "module/maintenanceTaskPersonnelAssignment/types";
import XLabelField from "component/labelField/XLabelField";
import { MAINTENANCE_TASK_PERSONNEL_ASSIGNMENT_EXTERNAL_STAFF_RESPONSIBILITY_FIELD_NAME } from "module/maintenanceTaskPersonnelAssignment/externalStaff/constants";
import { getFieldMetaDataWithErrorHandling } from "module/fieldMetaData/getFieldMetaData";
import { AutocompleteOption } from "component/autocompleteField/type";

const MaintenanceTaskPersonnelAssignmentExternalStaffResponsibilityContainer: React.FC =
  () => {
    const { entity } = useContext(
      MaintenanceTaskPersonnelAssignmentExternalStaffContext
    );
    const { change: changeHelper } = useContext(
      MaintenanceTaskPersonnelAssignmentExternalStaffChangeContext
    );
    const { getFieldMetaData } = useContext(
      MaintenanceTaskPersonnelAssignmentExternalStaffFieldMetaDataContext
    );

    const maintenanceTaskPersonnelAssignment =
      entity as MaintenanceTaskPersonnelAssignmentExternalStaffInterface;

    const options: AutocompleteOption[] = useMemo(
      () => [
        {
          text: getMaintenanceTaskPersonnelAssignmentResponsibilityTranslation(
            MaintenanceTaskPersonnelAssignmentResponsibility.SUPPORT
          ),
          value: MaintenanceTaskPersonnelAssignmentResponsibility.SUPPORT,
        },
        {
          text: getMaintenanceTaskPersonnelAssignmentResponsibilityTranslation(
            MaintenanceTaskPersonnelAssignmentResponsibility.HELPER
          ),
          value: MaintenanceTaskPersonnelAssignmentResponsibility.HELPER,
        },
        {
          text: getMaintenanceTaskPersonnelAssignmentResponsibilityTranslation(
            MaintenanceTaskPersonnelAssignmentResponsibility.TRAINEE
          ),
          value: MaintenanceTaskPersonnelAssignmentResponsibility.TRAINEE,
        },
      ],
      []
    );

    const selected = useMemo(
      () =>
        options.find(
          (option) =>
            option.value === maintenanceTaskPersonnelAssignment.responsibility
        ),
      [maintenanceTaskPersonnelAssignment.responsibility, options]
    );

    const change = useCallback(
      (responsibility: unknown): void => {
        changeHelper({
          responsibility:
            responsibility as MaintenanceTaskPersonnelAssignmentResponsibility,
        });
      },
      [changeHelper]
    );

    const { warnings, errors, isEditable } = getFieldMetaDataWithErrorHandling(
      () =>
        getFieldMetaData(
          MAINTENANCE_TASK_PERSONNEL_ASSIGNMENT_EXTERNAL_STAFF_RESPONSIBILITY_FIELD_NAME
        )
    );

    if (isEditable) {
      return (
        <AutocompleteField
          {...{ selected, options, onChange: change, errors }}
        />
      );
    }

    const label =
      maintenanceTaskPersonnelAssignment.responsibility != null
        ? getMaintenanceTaskPersonnelAssignmentResponsibilityTranslation(
            maintenanceTaskPersonnelAssignment.responsibility
          )
        : undefined;

    return (
      <XLabelField
        {...{
          label,
          warnings,
          errors,
        }}
      />
    );
  };

export default React.memo(
  MaintenanceTaskPersonnelAssignmentExternalStaffResponsibilityContainer
);
