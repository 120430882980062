import { MaintenanceTaskInterface } from "module/maintenanceTask/types";
import {
  MaintenanceTaskPersonnelAssignmentResponsibility,
  MyMaintenanceTaskPersonnelAssignmentInterface,
} from "module/maintenanceTaskPersonnelAssignment/types";

export const isStaffLeadOfMaintenanceTask = ({
  maintenanceTask,
  loggedInStaffId,
}: {
  maintenanceTask: MaintenanceTaskInterface;
  loggedInStaffId: number;
}): boolean => {
  return Boolean(
    maintenanceTask.personnelAssignments
      .map((personnelAssignmentEntityIdentifier) => {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        return maintenanceTask.relationships.get(
          personnelAssignmentEntityIdentifier
        ) as MyMaintenanceTaskPersonnelAssignmentInterface;
      })
      .filter(
        (personnelAssignment) =>
          personnelAssignment.data.responsibility ===
          MaintenanceTaskPersonnelAssignmentResponsibility.LEAD
      )
      .filter((personnelAssignment) => {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        return personnelAssignment.data.staffId === loggedInStaffId;
      }).length
  );
};
