import React from "react";
import MenuItem from "@mui/material/MenuItem";
import t from "module/translations";
import routes from "routes/routes";
import { Link as RouterLink } from "react-router-dom";

const AppInfoMenuItem: React.FC = () => {
  return (
    <MenuItem
      {...{ to: routes.appInfo.path, component: RouterLink, underline: "none" }}
    >
      {t.routes.appInfo}
    </MenuItem>
  );
};

export default React.memo(AppInfoMenuItem);
