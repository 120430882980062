import { useCallback } from "react";
import { useAppDispatch, useAppSelector } from "redux/hooks";
import { selectSystem, selectUser } from "module/auth/redux/authSlice";
import moment from "moment";
import { API_DATE_TIME_FORMAT } from "api/date";
import { changeEntityCollection } from "module/staff/redux/staffDataSlice";
import { EntityIdentifier } from "module/entity/types";
import useMyEntityValidation from "module/entity/useMyEntityValidation";

interface Result {
  change: <EntityInterface>(changes: Partial<EntityInterface>) => void;
}

const useEntityCollectionChange = (
  entityIdentifiers: EntityIdentifier[]
): Result => {
  const dispatch = useAppDispatch();
  const staffId = useAppSelector(selectUser).id;
  const systemShortName = useAppSelector(selectSystem).shortName;
  const { validateMyEntities } = useMyEntityValidation();

  const change = useCallback(
    <EntityInterface>(rawChanges: Partial<EntityInterface>) => {
      const changes = {
        ...rawChanges,
        changedDateTime: moment().format(API_DATE_TIME_FORMAT),
      };
      dispatch(
        changeEntityCollection({
          changes,
          entityIdentifiers,
          staffId,
          systemShortName,
        })
      );

      validateMyEntities(entityIdentifiers);
    },
    [dispatch, entityIdentifiers, staffId, systemShortName, validateMyEntities]
  );

  return { change };
};

export default useEntityCollectionChange;
