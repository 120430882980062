import React from "react";
import TrainingContext from "module/training/context/TrainingContext";
import { EntityIdentifier } from "module/entity/types";
import MyEntityContextProvider from "module/entity/context/entity/MyEntityContextProvider";
import TrainingValidationResultContext from "module/training/validation/TrainingValidationResultContext";
import MyEntityValidationResultContextProvider from "module/entity/context/validationResult/MyEntityValidationResultContextProvider";
import MyTrainingStateContextProvider from "module/training/state/context/MyTrainingStateContextProvider";
import MyTrainingFieldMetaDataContextProvider from "module/training/validation/fieldMetaData/MyTrainingFieldMetaDataContextProvider";
import MyTrainingGroupDetailsContainer from "pages/myTrainingGroupDetails/MyTrainingGroupDetailsContainer";
import MyEntityCollectionChangeContextProvider from "module/entity/context/change/MyEntityCollectionChangeContextProvider";
import EntityCollectionContextProviderRetrievedFromStore from "module/entity/context/entityCollection/EntityCollectionContextProviderRetrievedFromStore";
import TrainingChangeContext from "module/training/change/TrainingChangeContext";
import MyTrainingGroupOpenCloseContextProvider from "module/training/openClose/context/MyTrainingGroupOpenCloseContextProvider";

interface MyTrainingGroupDetailsContextProviderInterface {
  entityIdentifiers: EntityIdentifier[];
}

const MyTrainingGroupDetailsContextProvider: React.FC<MyTrainingGroupDetailsContextProviderInterface> =
  ({ entityIdentifiers }) => {
    const entityIdentifier = entityIdentifiers[0];

    return (
      <EntityCollectionContextProviderRetrievedFromStore
        {...{ entityIdentifiers }}
      >
        <MyEntityContextProvider
          {...{
            entityIdentifier,
            context: TrainingContext,
          }}
        >
          <MyEntityValidationResultContextProvider
            {...{
              entityIdentifier,
              context: TrainingValidationResultContext,
            }}
          >
            <MyTrainingGroupOpenCloseContextProvider>
              <MyTrainingStateContextProvider>
                <MyTrainingFieldMetaDataContextProvider>
                  <MyEntityCollectionChangeContextProvider
                    {...{ entityIdentifiers, context: TrainingChangeContext }}
                  >
                    <MyTrainingGroupDetailsContainer />
                  </MyEntityCollectionChangeContextProvider>
                </MyTrainingFieldMetaDataContextProvider>
              </MyTrainingStateContextProvider>
            </MyTrainingGroupOpenCloseContextProvider>
          </MyEntityValidationResultContextProvider>
        </MyEntityContextProvider>
      </EntityCollectionContextProviderRetrievedFromStore>
    );
  };

export default MyTrainingGroupDetailsContextProvider;
