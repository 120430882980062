import { useMemo } from "react";
import { useAppSelector } from "redux/hooks";
import usePermissions from "module/permission/usePermissions";
import {
  MaintenanceTaskInterface,
  MaintenanceTaskState,
} from "module/maintenanceTask/types";
import { isStateScheduledOrLower as isStateScheduledOrLowerHelper } from "module/maintenanceTask/helper/isStateScheduledOrLower";
import { EntityIdentifier, EntityType } from "module/entity/types";
import selectOpenItemsByEntityType from "module/staff/redux/selector/selectOpenItemsByEntityType";

interface Result {
  options: MaintenanceTaskState[];
  isEditable: boolean;
  isStateScheduledOrLower: boolean;
}

const useMyMaintenanceTaskState = (
  maintenanceTask: MaintenanceTaskInterface
): Result => {
  const { canCompleteMaintenanceTask } = usePermissions();
  const openMaintenanceTasksEntityIdentifier = useAppSelector(
    selectOpenItemsByEntityType(EntityType.maintenanceTask)
  ) as EntityIdentifier[];

  const isOpen = useMemo(
    () =>
      openMaintenanceTasksEntityIdentifier.reduce(
        (result, entityIdentifier) => {
          if (entityIdentifier.id === maintenanceTask.id) {
            return true;
          }
          return result;
        },
        false
      ),
    [maintenanceTask.id, openMaintenanceTasksEntityIdentifier]
  );

  const isStateScheduledOrLower =
    isStateScheduledOrLowerHelper(maintenanceTask);

  const options = useMemo(() => {
    const result = [];
    if (
      isStateScheduledOrLower ||
      (maintenanceTask.state === MaintenanceTaskState.completed &&
        canCompleteMaintenanceTask)
    ) {
      result.push(MaintenanceTaskState.scheduled);
    }
    if (
      maintenanceTask.state === MaintenanceTaskState.completed ||
      (isStateScheduledOrLower && canCompleteMaintenanceTask)
    ) {
      result.push(MaintenanceTaskState.completed);
    }
    return result;
  }, [
    canCompleteMaintenanceTask,
    isStateScheduledOrLower,
    maintenanceTask.state,
  ]);

  const isEditable = isOpen && options.length > 1;

  return {
    options,
    isEditable,
    isStateScheduledOrLower,
  };
};

export default useMyMaintenanceTaskState;
