import { EntityType } from "module/entity/types";
import {
  MyTrainingCustomAssigneeInterface,
  MyTrainingCustomInterface,
  TrainingCustomAssigneeToSyncInterface,
  TrainingCustomToSyncInterface,
} from "module/trainingCustom/types";

const mapAssigneeToSync = (
  assignee: MyTrainingCustomAssigneeInterface
): TrainingCustomAssigneeToSyncInterface => ({
  identifier: {
    type: assignee.entityIdentifier.type as EntityType.trainingCustomAssignee,
    id: assignee.entityIdentifier.backendId as number,
    frontendId: assignee.entityIdentifier.id,
  },
  data: {
    ...assignee.data,
    id: assignee.data.backendId as number,
    remarks: assignee.data.remarks == null ? "" : assignee.data.remarks,
  },
  raw: assignee.raw,
});

export const mapTrainingCustomToSync = (
  trainingCustom: MyTrainingCustomInterface,
  assignees: MyTrainingCustomAssigneeInterface[]
): TrainingCustomToSyncInterface => {
  return {
    identifier: {
      type: trainingCustom.entityIdentifier.type as EntityType.trainingCustom,
      id: trainingCustom.entityIdentifier.backendId as number,
      frontendId: trainingCustom.entityIdentifier.id,
    },
    data: {
      ...trainingCustom.data,
      id: trainingCustom.data.backendId as number,
      assignees: assignees.map(mapAssigneeToSync),
    },
    raw: trainingCustom.raw,
  };
};
