import { useCallback } from "react";
import { useAppDispatch, useAppSelector } from "redux/hooks";
import { selectSystem, selectUser } from "module/auth/redux/authSlice";
import moment from "moment";
import { API_DATE_TIME_FORMAT } from "api/date";
import { changeEntity } from "module/staff/redux/staffDataSlice";
import { EntityIdentifier } from "module/entity/types";
import useMyEntityValidation from "module/entity/useMyEntityValidation";

interface Result {
  change: <EntityInterface>(
    changes: Partial<EntityInterface>,
    entityIdentifier: EntityIdentifier
  ) => void;
}

const useEntityChangeLateCoupling = (): Result => {
  const dispatch = useAppDispatch();
  const staffId = useAppSelector(selectUser).id;
  const systemShortName = useAppSelector(selectSystem).shortName;
  const { validateMyEntities } = useMyEntityValidation();

  const change = useCallback(
    <EntityInterface>(
      changes: Partial<EntityInterface>,
      entityIdentifier: EntityIdentifier
    ) => {
      dispatch(
        changeEntity({
          changes: {
            ...changes,
            changedDateTime: moment().format(API_DATE_TIME_FORMAT),
          },
          entityIdentifier,
          staffId,
          systemShortName,
        })
      );

      validateMyEntities([entityIdentifier]);
    },
    [dispatch, staffId, systemShortName, validateMyEntities]
  );

  return { change };
};

export default useEntityChangeLateCoupling;
