import React from "react";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import t from "module/translations";
import useDateFormat from "toolkit/useDateFormat";
import { getAssessmentTypeTranslation } from "module/assessment/helper/getAssessmentTypeTranslation";
import StaffNameText from "module/staff/StaffNameText";
import { AssessmentInterface } from "module/assessment/types";
import { getPathToAssessmentToBeSignedDetails } from "module/assessment/helper/getPath";
import LinkWithIcon from "component/link/LinkWithIcon";

interface Props {
  assessment: AssessmentInterface;
}

const ToBeSignedListTableRowAssessment: React.FC<Props> = ({ assessment }) => {
  const { formatDateTime } = useDateFormat();
  return (
    <TableRow hover tabIndex={-1} key={assessment.id}>
      <TableCell>{t.competencyManagement.assessmentText}</TableCell>
      <TableCell>
        <LinkWithIcon
          {...{
            label: assessment.name,
            path: getPathToAssessmentToBeSignedDetails(assessment.id),
          }}
        />
        {assessment.assigneeId && (
          <StaffNameText {...{ staffId: assessment.assigneeId }} />
        )}
      </TableCell>
      <TableCell>
        {assessment.type
          ? `${getAssessmentTypeTranslation(assessment.type)} ${
              t.competencyManagement.assessmentText
            }`
          : "-"}
      </TableCell>
      <TableCell>{formatDateTime(assessment.scheduledStartDate)}</TableCell>
    </TableRow>
  );
};

export default React.memo(ToBeSignedListTableRowAssessment);
