import React, { useEffect, useState, useCallback } from "react";
import TextField from "@mui/material/TextField";
import useDebounce from "toolkit/useDebounce";
import XValidationMessage from "module/validation/XValidationMessage";
import FormControl from "@mui/material/FormControl";
import makeStyles from "@mui/styles/makeStyles";

const useStyles = makeStyles(() => ({
  wrapper: {
    width: "100%",
    padding: "8px 0",
  },
}));
interface Props {
  label?: string;
  value?: string;
  change: (newValue?: string) => void;
  errors: string[];
}

const XTextareaField: React.FC<Props> = ({ label, value, change, errors }) => {
  const classes = useStyles();
  const hasError = errors != null && Boolean(errors.length);

  const [tempValue, setTempValue] = useState<string | undefined>(value);

  useEffect(() => {
    setTempValue(value);
  }, [value]);

  const debounceHandleChange = useDebounce(change);

  const handleChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const newValue = event.target.value;
      setTempValue(newValue);
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      debounceHandleChange(newValue);
    },
    [debounceHandleChange]
  );

  return (
    <FormControl
      error={hasError}
      className={classes.wrapper}
      variant="standard"
    >
      <TextField
        {...{
          label,
          multiline: true,
          value: tempValue,
          fullWidth: true,
          onChange: handleChange,
          variant: "standard",
          ...(errors.length
            ? {
                helperText: <XValidationMessage messages={errors} />,
                error: true,
              }
            : {}),
        }}
      />
    </FormControl>
  );
};

export default React.memo(XTextareaField);
