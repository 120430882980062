import React, { useCallback, useState } from "react";
import { BrowserRouter } from "react-router-dom";
import Menu from "layout/menu/Menu";
import UserMenu from "layout/useMenu/UserMenu";
import Header from "layout/header/Header";
import Main from "layout/main/Main";
import AppWrapper from "component/appWrapper/AppWrapper";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import Toast from "layout/toast/Toast";
import Sentry from "error/sentry/Sentry";
import KeepSystemConfigurationFresh from "module/systemConfiguration/KeepSystemConfigurationFresh";

const AppForLoggedInUser: React.FC = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isUserMenuOpen, setIsUserMenuOpen] = useState(false);

  const openUserMenu = useCallback(() => {
    setIsUserMenuOpen(true);
  }, []);

  const closeUserMenu = useCallback(() => {
    setIsUserMenuOpen(false);
  }, []);

  const openMenu = useCallback(() => {
    setIsMenuOpen(true);
  }, []);

  const closeMenu = useCallback(() => {
    setIsMenuOpen(false);
  }, []);

  return (
    <AppWrapper>
      <Sentry />
      <KeepSystemConfigurationFresh>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <BrowserRouter>
            <Header {...{ isMenuOpen, openMenu, openUserMenu }} />
            <Menu {...{ isOpen: isMenuOpen, close: closeMenu }} />
            <UserMenu {...{ isOpen: isUserMenuOpen, close: closeUserMenu }} />
            <Main {...{ isMenuOpen }} />
            <Toast />
          </BrowserRouter>
        </LocalizationProvider>
      </KeepSystemConfigurationFresh>
    </AppWrapper>
  );
};

export default React.memo(AppForLoggedInUser);
