import React, { useCallback } from "react";
import DatePicker from "@mui/lab/DatePicker";
import useDateFormat from "toolkit/useDateFormat";
import TextField from "@mui/material/TextField";
import XValidationErrorMessage from "../validationErrorMessage/XValidationErrorMessage";

interface Props {
  label: string;
  change: (newDate: Date | null) => void;
  value: Date | null;
  errors?: string[];
}

const DatePickerField: React.FC<Props> = ({ label, change, value, errors }) => {
  const { dateFormatForMUIPicker } = useDateFormat();

  const handleChange = useCallback(
    (newDate: unknown) => {
      change(newDate ? new Date(newDate as string) : null);
    },
    [change]
  );

  return (
    <>
      <DatePicker
        label={label}
        inputFormat={dateFormatForMUIPicker}
        value={value}
        onChange={handleChange}
        renderInput={(params) => <TextField {...params} variant="standard" />}
      />
      {errors && <XValidationErrorMessage {...{ errors }} />}
    </>
  );
};

export default React.memo(DatePickerField);
