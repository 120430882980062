import React, { useMemo } from "react";
import PageContainer from "component/pageContainer/PageContainer";
import { Switch, Route } from "react-router-dom";
import routes from "routes/routes";
import { filterMainLevelRoutes } from "routes/helper";

interface Props {
  isMenuOpen: boolean;
}

const Main: React.FC<Props> = ({ isMenuOpen }) => {
  const mainLevelRoutes = useMemo(
    () => filterMainLevelRoutes(Object.values(routes)),
    []
  );

  return (
    <PageContainer {...{ isMenuOpen }}>
      <Switch>
        {mainLevelRoutes.map(({ path, mainComponent, exact }) => (
          <Route {...{ key: path, exact, path, component: mainComponent }} />
        ))}
      </Switch>
    </PageContainer>
  );
};

export default React.memo(Main);
