export const ENV = process.env.REACT_APP_ENVIRONMENT as string;

export const ENV_LOCAL = "local";
export const isLocal = ENV === ENV_LOCAL;

export const ENV_DEVELOPMENT = "development";
export const isDevelopment = ENV === ENV_DEVELOPMENT;

export const ENV_PRODUCTION = "production";
export const isProduction = ENV === ENV_PRODUCTION;
