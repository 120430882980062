import React from "react";
import LabelField from "component/labelField/LabelField";
import t from "module/translations";

interface Props {
  expectedDate: string;
  formatDate: (date: string) => string;
}

const AssessmentExpectedDateLabelField: React.FC<Props> = ({
  expectedDate,
  formatDate,
}) => {
  return (
    <LabelField
      {...{
        caption: t.competencyManagement.assessment.expectedText,
        label: formatDate(expectedDate),
      }}
    />
  );
};

export default React.memo(AssessmentExpectedDateLabelField);
