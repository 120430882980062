import React, { useContext } from "react";
import Menu from "component/menu/Menu";
import Divider from "@mui/material/Divider";
import UserNameMenuItem from "layout/useMenu/UserNameMenuItem";
import SystemMenuItem from "layout/useMenu/SystemMenuItem";
import UserLogoutMenuItem from "layout/useMenu/UserLogoutMenuItem";
import AppInfoMenuItem from "layout/useMenu/AppInfoMenuItem";
import OnlineDetectorContext from "module/onlineDetector/OnlineDetectorContext";

interface Props {
  isOpen: boolean;
  close: () => void;
}

const UserMenu: React.FC<Props> = ({ isOpen, close }) => {
  const { isOnline } = useContext(OnlineDetectorContext);
  return (
    <Menu {...{ isOpen, close }}>
      <UserNameMenuItem />
      {isOnline && <SystemMenuItem {...{ close }} />}
      <AppInfoMenuItem />
      <Divider />
      <UserLogoutMenuItem />
    </Menu>
  );
};

export default React.memo(UserMenu);
