import React, { useContext, useCallback } from "react";
import StaffSelectContainer from "module/staff/StaffSelectContainer";
import { useAppSelector } from "redux/hooks";
import MaintenanceTaskContext from "module/maintenanceTask/context/MaintenanceTaskContext";
import MaintenanceTaskChangeContext from "module/maintenanceTask/change/MaintenanceTaskChangeContext";
import MaintenanceTaskFieldMetaDataContext from "module/maintenanceTask/validation/fieldMetaData/MaintenanceTaskFieldMetaDataContext";
import { MaintenanceTaskInterface } from "module/maintenanceTask/types";
import { getFieldMetaDataWithErrorHandling } from "module/fieldMetaData/getFieldMetaData";
import { MAINTENANCE_TASK_ASSISTANT_INTERNAL_STAFF_FIELD_NAME } from "module/maintenanceTask/constants";
import XLabelField from "component/labelField/XLabelField";
import { selectStaffById } from "module/auth/redux/authSlice";
import t from "module/translations";

const MaintenanceTaskAssistantInternalStaffNameContainer: React.FC = () => {
  const { entity } = useContext(MaintenanceTaskContext);
  const { change: changeHelper } = useContext(MaintenanceTaskChangeContext);
  const { getFieldMetaData } = useContext(MaintenanceTaskFieldMetaDataContext);

  const maintenanceTask = entity as MaintenanceTaskInterface;

  const { warnings, errors, isEditable } = getFieldMetaDataWithErrorHandling(
    () => getFieldMetaData(MAINTENANCE_TASK_ASSISTANT_INTERNAL_STAFF_FIELD_NAME)
  );

  const change = useCallback(
    (assistantInternalStaffId?: number) => {
      changeHelper({ assistantInternalStaffId });
    },
    [changeHelper]
  );

  const selectStaffByIdHelper = useAppSelector(selectStaffById);
  let staffName;
  if (maintenanceTask.assistantInternalStaffId != null) {
    try {
      const staff = selectStaffByIdHelper(
        maintenanceTask.assistantInternalStaffId
      );
      staffName = staff.name;
    } catch (e) {
      staffName = t.staffDeletedText;
    }
  }

  return isEditable ? (
    <StaffSelectContainer
      {...{
        selectedStaffId: maintenanceTask.assistantInternalStaffId,
        onChange: change,
        errors,
      }}
    />
  ) : (
    <XLabelField
      {...{
        label: staffName,
        errors,
        warnings,
      }}
    />
  );
};

export default React.memo(MaintenanceTaskAssistantInternalStaffNameContainer);
