import { PayloadAction } from "@reduxjs/toolkit";
import { StaffDataState } from "module/staff/redux/types";
import { EntityIdentifier } from "module/entity/types";
import { getEntityIdentifierKey } from "module/entity/helper/getEntityIdentifierKey";

interface Props {
  staffId: number;
  systemShortName: string;
  baseEntityIdentifier: EntityIdentifier;
  entityIdentifierToRemove: EntityIdentifier;
}

const removeFromEntityRelationshipsAction = (
  state: StaffDataState,
  action: PayloadAction<Props>
): void => {
  const {
    staffId,
    systemShortName,
    baseEntityIdentifier,
    entityIdentifierToRemove,
  } = action.payload;

  const { relationshipIdentifiers } =
    state[staffId][systemShortName].entities[
      getEntityIdentifierKey(baseEntityIdentifier)
    ].data;

  state[staffId][systemShortName].entities[
    getEntityIdentifierKey(baseEntityIdentifier)
  ].data.relationshipIdentifiers = relationshipIdentifiers.filter(
    (entityIdentifier) =>
      getEntityIdentifierKey(entityIdentifier) !==
      getEntityIdentifierKey(entityIdentifierToRemove)
  );
};

export default removeFromEntityRelationshipsAction;
