import React, { useContext } from "react";
import MaintenanceTaskContext from "module/maintenanceTask/context/MaintenanceTaskContext";
import { MaintenanceTaskInterface } from "module/maintenanceTask/types";
import MyMaintenanceTaskDetailsOverviewMaintainedAtValue from "pages/myMaintenanceTaskDetails/overview/maintainedAtValue/MyMaintenanceTaskDetailsOverviewMaintainedAtValue";

const MyMaintenanceTaskDetailsOverviewMaintainedAtValueContainer: React.FC =
  () => {
    const { entity } = useContext(MaintenanceTaskContext);

    const maintenanceTask = entity as MaintenanceTaskInterface;

    const parameterName = maintenanceTask.definition?.parameterType?.fullName;
    const { scheduledValue } = maintenanceTask;

    return (
      <MyMaintenanceTaskDetailsOverviewMaintainedAtValue
        {...{
          parameterName,
          scheduledValue: scheduledValue as number,
        }}
      />
    );
  };

export default React.memo(
  MyMaintenanceTaskDetailsOverviewMaintainedAtValueContainer
);
