import React from "react";
import LabelField from "component/labelField/LabelField";
import t from "module/translations";
import { getTrainingCustomStateTranslation } from "module/trainingCustom/helper/getTrainingCustomStateTranslation";
import { TrainingCustomState } from "module/trainingCustom/types";

interface Props {
  state: TrainingCustomState;
}

const TrainingCustomNameLabelField: React.FC<Props> = ({ state }) => {
  return (
    <LabelField
      {...{
        caption: t.competencyManagement.training.stateText,
        label: getTrainingCustomStateTranslation(state),
      }}
    />
  );
};

export default React.memo(TrainingCustomNameLabelField);
