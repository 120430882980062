import axios from "axios";
import { mapAssessment } from "api/competencyManagement/mappers";
import { MyAssessmentInterface } from "module/assessment/types";

const getAssessmentDetails = async (
  assessmentId: number
): Promise<MyAssessmentInterface> => {
  const response = await axios.get(
    `/api/competency-managements/assessments/${assessmentId}`
  );
  return mapAssessment(response.data);
};

export default getAssessmentDetails;
