import React, { useContext, useState, useCallback, useMemo } from "react";
import DropdownButtonField from "component/dropdownButtonField/DropdownButtonField";
import { getAssessmentStateTranslation } from "module/assessment/helper/getAssessmentStateTranslation";
import t from "module/translations";
import ElementWithConfirmation from "component/elementWithConfirmation/ElementWithConfirmation";
import { AssessmentInterface, AssessmentState } from "module/assessment/types";
import AssessmentContext from "module/assessment/context/AssessmentContext";
import AssessmentChangeContext from "module/assessment/change/AssessmentChangeContext";
import AssessmentStateContext from "module/assessment/state/context/AssessmentStateContext";

const AssessmentStateEditableFieldContainer: React.FC = () => {
  const { entity } = useContext(AssessmentContext);
  const { change } = useContext(AssessmentChangeContext);
  const { stateOptions: stateOptionsHelper } = useContext(
    AssessmentStateContext
  );

  const assessment = entity as AssessmentInterface;
  const stateOptions = stateOptionsHelper as AssessmentState[];

  const [temporaryState, setTemporaryState] = useState<AssessmentState>(
    assessment.state
  );

  const onChange = useCallback(async () => {
    await new Promise(() => {
      change({
        state: temporaryState,
      });
    });
  }, [change, temporaryState]);

  const onChangeHelper = useCallback(
    (newState: unknown, onClick: () => void) => {
      setTemporaryState(newState as AssessmentState);
      if (newState === AssessmentState.completed) {
        onClick();
      } else {
        change({
          state: newState as AssessmentState,
        });
      }
    },
    [change]
  );

  const options = useMemo(
    () =>
      stateOptions.map((state: AssessmentState) => ({
        value: state,
        text: getAssessmentStateTranslation(state),
      })),
    [stateOptions]
  );

  const value = assessment.state;

  const confirmationDialogTitle =
    t.competencyManagement.completedConfirmationText;

  return (
    <ElementWithConfirmation {...{ confirmationDialogTitle, action: onChange }}>
      {(props: { onClick: () => void }) => (
        <DropdownButtonField
          {...{
            options,
            onChange: (newState) => {
              onChangeHelper(newState, props.onClick);
            },
            value,
          }}
        />
      )}
    </ElementWithConfirmation>
  );
};

export default React.memo(AssessmentStateEditableFieldContainer);
