import axios from "axios";
import { ValidationError } from "api/block/types";
import { mapValidationErrorGeneralMessagesToString } from "api/block/mapper";
import { EntityIdentifier } from "module/entity/types";

interface UnblockResponse {
  validationError?: ValidationError;
  validationGeneralError: string[];
}

const unblock = async (
  entities: EntityIdentifier[]
): Promise<UnblockResponse> => {
  let validationError;
  let validationGeneralError: string[] = [];
  try {
    await axios.post(`/api/unblock-entities`, { entities });
  } catch (error) {
    if (error.response.status === 422) {
      validationError = error.response.data;
      validationGeneralError =
        mapValidationErrorGeneralMessagesToString(validationError);
    } else {
      throw new Error(error);
    }
  }
  return {
    validationError,
    validationGeneralError,
  };
};

export default unblock;
