import React from "react";
import { Link as RouterLink } from "react-router-dom";
import Tab from "@mui/material/Tab";
import Link from "@mui/material/Link";

interface Props {
  path: string;
  title: string;
}

const NavigationBarItem: React.FC<Props> = ({ path, title }) => {
  return (
    <Link
      {...{
        to: path,
        component: RouterLink,
        underline: "none",
      }}
    >
      <Tab label={title} />
    </Link>
  );
};

export default React.memo(NavigationBarItem);
