import { RootState } from "redux/store";
import selectConfigurationForMeForCurrentSystem from "module/staff/redux/selector/selectConfigurationForMeForCurrentSystem";
import { EntityIdentifier, EntityType } from "module/entity/types";

const selectOpenItemsByEntityType =
  (entityType: EntityType) =>
  (state: RootState): EntityIdentifier[] =>
    selectConfigurationForMeForCurrentSystem(
      state
    )?.openEntityIdentifiers?.filter((item) => item.type === entityType) ?? [];

export default selectOpenItemsByEntityType;
