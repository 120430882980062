import React from "react";
import Typography from "@mui/material/Typography";
import t from "module/translations";
import makeStyles from "@mui/styles/makeStyles";
import WifiOffIcon from "@mui/icons-material/WifiOff";

const useStyles = makeStyles(() => ({
  wrapper: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: "calc(100vh - 120px)",
    textAlign: "center",
  },
  icon: {
    fontSize: "200px",
  },
}));

const Offline: React.FC = () => {
  const classes = useStyles();
  return (
    <div className={classes.wrapper}>
      <div>
        <WifiOffIcon className={classes.icon} />
        <Typography variant="h5" gutterBottom>
          {t.offline.text}
        </Typography>
      </div>{" "}
    </div>
  );
};

export default React.memo(Offline);
