import React, { useEffect } from "react";
import { useAppSelector } from "redux/hooks";
import { selectSystem, selectUser } from "module/auth/redux/authSlice";
import { selectDeviceId } from "module/device/redux/deviceSlice";
import * as SentryLib from "@sentry/react";
import GitInfo from "react-git-info/macro";

const Sentry: React.FC = () => {
  const user = useAppSelector(selectUser);
  const system = useAppSelector(selectSystem);
  const deviceId = useAppSelector(selectDeviceId);
  const gitCommitHash = GitInfo().commit.hash;

  useEffect(() => {
    SentryLib.setUser({
      id: `${user.id}`,
      username: `${user.firstName} ${user.lastName}`,
    });
    SentryLib.setTag("system", system.shortName);
    SentryLib.setTag("deviceId", deviceId);
    SentryLib.setTag("gitCommitHash", gitCommitHash);
  }, [
    user.firstName,
    user.lastName,
    system.shortName,
    deviceId,
    user.id,
    gitCommitHash,
  ]);
  return null;
};

export default Sentry;
