import React from "react";
import { EntityValidationResultContextInterface } from "module/entity/context/validationResult/EntityValidationResultContext";

export type MaintenanceTaskPersonnelAssignmentExternalStaffValidationResultContextInterface =
  EntityValidationResultContextInterface;

const MaintenanceTaskPersonnelAssignmentExternalStaffValidationResultContext =
  React.createContext(
    <
      MaintenanceTaskPersonnelAssignmentExternalStaffValidationResultContextInterface
    >{}
  );

export default MaintenanceTaskPersonnelAssignmentExternalStaffValidationResultContext;
