import { ApiMaintenanceTaskInterface } from "api/maintenanceTask/types";
import { MaintenanceTaskPersonnelAssignmentResponsibility } from "module/maintenanceTaskPersonnelAssignment/types";

export const isApiMaintenanceTaskAssignedToStaff = (
  apiMaintenanceTask: ApiMaintenanceTaskInterface,
  staffId: number
): boolean =>
  Boolean(
    apiMaintenanceTask.personnelAssignments.filter((personnelAssignment) => {
      return (
        personnelAssignment.responsibility ===
          MaintenanceTaskPersonnelAssignmentResponsibility.LEAD &&
        "staff" in personnelAssignment &&
        personnelAssignment.staff?.id === staffId
      );
    }).length
  );
