import React from "react";
import Avatar from "@mui/material/Avatar";
import makeStyles from "@mui/styles/makeStyles";

interface Props {
  text: string | number;
}

const useStyles = makeStyles((theme) => ({
  root: {
    width: theme.spacing(3),
    height: theme.spacing(3),
    fontSize: 12,
    backgroundColor: theme.palette.primary.main,
  },
}));

const SideMenuItemBadge: React.FC<Props> = ({ text }) => {
  const classes = useStyles();
  return <Avatar className={classes.root}>{text}</Avatar>;
};

export default React.memo(SideMenuItemBadge);
