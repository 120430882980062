import { RootState } from "redux/store";
import selectConfigurationForMeForCurrentSystem from "module/staff/redux/selector/selectConfigurationForMeForCurrentSystem";
import { EntityType, MyEntityInterface } from "module/entity/types";

const selectEntitiesByTypes =
  (types: EntityType[]) =>
  (state: RootState): MyEntityInterface[] => {
    try {
      return Object.values(
        selectConfigurationForMeForCurrentSystem(state).entities
      ).filter((singleEntity) =>
        types.includes(singleEntity.entityIdentifier.type)
      );
    } catch (error) {
      return [];
    }
  };

export default selectEntitiesByTypes;
