import React, { useEffect, useMemo } from "react";
import { useHistory, useParams, useRouteMatch } from "react-router-dom";
import routes from "routes/routes";
import { getPathToTrainingDetailsOverview } from "module/training/helper/getPath";
import MyTrainingDetailsContainer from "pages/myTrainingDetails/MyTrainingDetailsContainer";
import { EntityIdentifier, EntityType } from "module/entity/types";
import MyTrainingDetailsContextProvider from "pages/myTrainingDetails/MyTrainingDetailsContextProvider";

const MyTrainingDetailsPage: React.FC = () => {
  const { trainingId: id } = useParams<{ trainingId: string }>();
  const history = useHistory();
  const match = useRouteMatch({
    path: routes.myTrainingDetails.path,
    exact: true,
  });

  useEffect(() => {
    if (id && match) {
      history.push(getPathToTrainingDetailsOverview(id));
    }
  }, [history, id, match]);

  const entityIdentifier: EntityIdentifier = useMemo(
    () => ({ id, type: EntityType.training }),
    [id]
  );

  return (
    <MyTrainingDetailsContextProvider {...{ entityIdentifier }}>
      <MyTrainingDetailsContainer />
    </MyTrainingDetailsContextProvider>
  );
};

export default React.memo(MyTrainingDetailsPage);
