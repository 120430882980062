import { MaintenanceTaskProcessStepActionPerformed } from "module/maintenanceTaskProcessStep/types";
import t from "module/translations";

export const getMaintenanceTaskProcessStepActionPerformedTranslation = (
  actionPerformed: MaintenanceTaskProcessStepActionPerformed
): string =>
  ({
    [MaintenanceTaskProcessStepActionPerformed.not_set]:
      t.maintenanceTask.processStep.actionPerformed.notSetText,
    [MaintenanceTaskProcessStepActionPerformed.good_yes]:
      t.maintenanceTask.processStep.actionPerformed.goodYesText,
  }[actionPerformed]);
