import { createNestedPropertyIfNotExist } from "toolkit/createNestedPropertyIfNotExist";
import { MaintenanceTaskInterface } from "module/maintenanceTask/types";
import { XValidatorErrorsInterface } from "module/validation/types";
import { XRequiredValidator } from "module/validation/validator/requiredValidator";
import { MaintenanceTaskPartInterface } from "module/maintenanceTaskPart/types";
import {
  MAINTENANCE_TASK_PART_AMOUNT_FIELD_NAME,
  MAINTENANCE_TASK_PART_STOCK_ARTICLE_FIELD_NAME,
} from "module/maintenanceTaskPart/constants";
import { StockArticleCollection } from "module/stockArticle/types";
import t from "module/translations";
import { ArticleMovementType } from "module/articleMovement/types";

export interface Result {
  errors: null | XValidatorErrorsInterface;
  warnings: null | XValidatorErrorsInterface;
}

export const validateMaintenanceTaskPart = (
  maintenanceTask: MaintenanceTaskInterface,
  originalMaintenanceTaskPart: MaintenanceTaskPartInterface,
  maintenanceTaskPart: MaintenanceTaskPartInterface,
  stockArticles: StockArticleCollection
): Result => {
  let errors: XValidatorErrorsInterface = {};
  let warnings: XValidatorErrorsInterface = {};

  if (maintenanceTaskPart.amount == null) {
    errors = createNestedPropertyIfNotExist(
      errors,
      MAINTENANCE_TASK_PART_AMOUNT_FIELD_NAME,
      []
    );
    errors[MAINTENANCE_TASK_PART_AMOUNT_FIELD_NAME].push(
      new XRequiredValidator().message
    );
  }

  if (maintenanceTaskPart.stockArticleId == null) {
    errors = createNestedPropertyIfNotExist(
      errors,
      MAINTENANCE_TASK_PART_STOCK_ARTICLE_FIELD_NAME,
      []
    );
    errors[MAINTENANCE_TASK_PART_STOCK_ARTICLE_FIELD_NAME].push(
      new XRequiredValidator().message
    );
  }

  const amountBeforeChange =
    originalMaintenanceTaskPart.amount != null
      ? originalMaintenanceTaskPart.amount
      : 0;

  let onStock =
    stockArticles[maintenanceTaskPart.stockArticleId as number]?.onStock;

  if (maintenanceTaskPart.type === ArticleMovementType.maintenance) {
    onStock += Math.abs(amountBeforeChange);
  }

  if (
    maintenanceTaskPart.amount != null &&
    maintenanceTaskPart.stockArticleId != null &&
    onStock < Math.abs(maintenanceTaskPart.amount)
  ) {
    warnings = createNestedPropertyIfNotExist(
      warnings,
      MAINTENANCE_TASK_PART_AMOUNT_FIELD_NAME,
      []
    );
    warnings[MAINTENANCE_TASK_PART_AMOUNT_FIELD_NAME].push(
      t.maintenanceTask.parts.insufficientAmountOnStockText(onStock)
    );
  }

  return {
    errors: Object.keys(errors).length ? errors : null,
    warnings: Object.keys(warnings).length ? warnings : null,
  };
};
