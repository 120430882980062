import { useCallback } from "react";
import { useAppSelector } from "redux/hooks";
import { EntityType } from "module/entity/types";
import selectEntityHelper from "module/staff/redux/selector/selectEntity";
import {
  MyTrainingInterface,
  MyTrainingSessionInterface,
  TrainingToSyncInterface,
} from "module/training/types";
import { mapTrainingToSync } from "module/training/sync/mapper";

interface Result {
  geTrainingMappedToSync: (id: string) => TrainingToSyncInterface;
}

const useTrainingSyncHelper = (): Result => {
  const selectEntity = useAppSelector(selectEntityHelper);

  const geTrainingMappedToSync = useCallback(
    (id: string): TrainingToSyncInterface => {
      const myTraining = selectEntity({
        id,
        type: EntityType.training,
      }) as MyTrainingInterface;

      const sessions = myTraining.data.sessions.map(
        (objectiveIdentifier) =>
          selectEntity(objectiveIdentifier) as MyTrainingSessionInterface
      );

      return mapTrainingToSync(myTraining, sessions);
    },
    [selectEntity]
  );

  return {
    geTrainingMappedToSync,
  };
};

export default useTrainingSyncHelper;
