import React from "react";
import makeStyles from "@mui/styles/makeStyles";
import PullToRefreshLib from "react-simple-pull-to-refresh";

interface Props {
  refresh: () => Promise<unknown>;
  children: React.ReactNode;
}

const useStyles = makeStyles({
  pullToRefresh: {
    "& .ptr__children": {
      transform: "none !important",
    },
    minHeight: "calc(100vh - 150px)",
  },
});

const PullToRefresh: React.FC<Props> = ({ refresh, children }) => {
  const classes = useStyles();

  return (
    <PullToRefreshLib onRefresh={refresh} className={classes.pullToRefresh}>
      {children as JSX.Element}
    </PullToRefreshLib>
  );
};

export default React.memo(PullToRefresh);
