import t from "module/translations";
import { XValidatorInterface } from "module/validation/types";

export class ValueMustBeDifferentThanValidator implements XValidatorInterface {
  public message: string;

  constructor(originalValue: string) {
    this.message = t.validation.valueMustBeDifferentThan(originalValue);
  }
}
