import React, { useState, useMemo, useCallback } from "react";
import Grid from "@mui/material/Grid";
import t from "module/translations";
import useMaintenanceTaskTemplateOptions from "module/maintenanceTaskTemplate/options/useMaintenanceTaskTemplateOptions";
import StickyPaper from "component/sticky/StickyPaper";
import AutocompleteField from "component/autocompleteField/AutocompleteField";
import ButtonsContainer from "component/button/ButtonsContainer";
import GenerateButton from "component/button/GenerateButton";
import { selectMaintenanceTaskTemplates } from "module/auth/redux/authSlice";
import { useAppSelector } from "redux/hooks";
import createStyles from "@mui/styles/createStyles";
import makeStyles from "@mui/styles/makeStyles";

const useStyles = makeStyles(() =>
  createStyles({
    wrapper: {
      width: "100%",
      padding: "24px",
    },
  })
);

interface Props {
  onGenerate: (templateId: number) => void;
}
const MaintenanceTaskProcessStepCorrectiveGenerate: React.FC<Props> = ({
  onGenerate,
}) => {
  const classes = useStyles();
  const { options: templateOptions } = useMaintenanceTaskTemplateOptions();
  const templates = useAppSelector(selectMaintenanceTaskTemplates);
  const [templateId, setTemplateId] = useState<number | undefined>(undefined);

  const onTemplateChange = useCallback((id: unknown) => {
    setTemplateId(id as number);
  }, []);

  const selectedTemplate = useMemo(
    () =>
      templates && Object.keys(templates).length && templateId
        ? {
            text: templates[templateId].name,
            value: templates[templateId].id,
          }
        : undefined,
    [templateId, templates]
  );

  const handleGenerate = useCallback(() => {
    if (templateId) {
      onGenerate(templateId);
    }
  }, [onGenerate, templateId]);

  return (
    <div className={classes.wrapper}>
      <StickyPaper>
        <Grid container spacing={3}>
          <Grid item xs={6}>
            <AutocompleteField
              {...{
                label: t.maintenanceTask.templateText,
                options: templateOptions,
                onChange: onTemplateChange,
                selected: selectedTemplate,
              }}
            />
          </Grid>
        </Grid>
      </StickyPaper>
      <ButtonsContainer>
        {templateId && <GenerateButton onClick={handleGenerate} />}
      </ButtonsContainer>
    </div>
  );
};

export default React.memo(MaintenanceTaskProcessStepCorrectiveGenerate);
