import { sortBy } from "sort-by-typescript";
import { SortDirection, SortType } from "module/sort/types";

export const sort = <Item>(
  items: Item[],
  sorting: SortType,
  defaultSortingProperty: string
): Item[] => {
  const sortKey = `${sorting.direction === SortDirection.asc ? "" : "-"}${
    sorting.fieldName
  }`;
  return items.sort(sortBy(sortKey, defaultSortingProperty));
};
