import { useCallback } from "react";
import { useAppDispatch, useAppSelector } from "redux/hooks";
import {
  selectSystem,
  selectSystemConfiguration,
  selectUser,
} from "module/auth/redux/authSlice";
import { EntityIdentifier } from "module/entity/types";
import { validateEntities } from "module/staff/redux/staffDataSlice";

interface UseMyEntityValidationResult {
  validateMyEntities: (entityIdentifiers: EntityIdentifier[]) => void;
  validateAllMyEntities: () => void;
}

const useMyEntityValidation = (): UseMyEntityValidationResult => {
  const dispatch = useAppDispatch();
  const staffId = useAppSelector(selectUser).id;
  const systemShortName = useAppSelector(selectSystem).shortName;
  const systemConfiguration = useAppSelector(selectSystemConfiguration);

  const validateMyEntities = useCallback(
    (entityIdentifiers: EntityIdentifier[]) => {
      dispatch(
        validateEntities({
          staffId,
          systemShortName,
          entityIdentifiers,
          systemConfiguration,
        })
      );
    },
    [dispatch, staffId, systemConfiguration, systemShortName]
  );

  const validateAllMyEntities = useCallback(() => {
    dispatch(
      validateEntities({
        staffId,
        systemShortName,
        systemConfiguration,
      })
    );
  }, [dispatch, staffId, systemConfiguration, systemShortName]);
  return {
    validateMyEntities,
    validateAllMyEntities,
  };
};

export default useMyEntityValidation;
