import React, { useContext } from "react";
import InventoryCheckContext from "module/inventoryCheck/context/InventoryCheckContext";
import InventoryCheckValidationResultContext from "module/inventoryCheck/validation/InventoryCheckValidationResultContext";
import InventoryCheckOpenCloseContext from "module/inventoryCheck/openClose/context/InventoryCheckOpenCloseContext";
import InventoryCheckStateContext from "module/inventoryCheck/state/context/InventoryCheckStateContext";
import { InventoryCheckInterface } from "module/inventoryCheck/types";
import MyInventoryCheckDetails from "./MyInventoryCheckDetails";

const MyInventoryCheckDetailsContainer: React.FC = () => {
  const { entity } = useContext(InventoryCheckContext);
  const { hasError } = useContext(InventoryCheckValidationResultContext);
  const { open, close } = useContext(InventoryCheckOpenCloseContext);
  const { isStateEditable } = useContext(InventoryCheckStateContext);

  const inventoryCheck = entity as InventoryCheckInterface;

  return (
    <MyInventoryCheckDetails
      {...{
        inventoryCheckId: inventoryCheck.id,
        hasError,
        open,
        close,
        isStateEditable,
      }}
    />
  );
};

export default React.memo(MyInventoryCheckDetailsContainer);
