import React, { useContext } from "react";
import AssessmentContext from "module/assessment/context/AssessmentContext";
import { AssessmentInterface } from "module/assessment/types";
import AssessmentTrainerLabelField from "module/assessment/enrichedComponent/trainerField/AssessmentTrainerLabelField";

const AssessmentTrainerFieldContainer: React.FC = () => {
  const { entity } = useContext(AssessmentContext);
  const { internalTrainerId, externalTrainer } = entity as AssessmentInterface;

  return (
    <AssessmentTrainerLabelField {...{ internalTrainerId, externalTrainer }} />
  );
};

export default React.memo(AssessmentTrainerFieldContainer);
