import { useCallback, useContext } from "react";
import { useAppDispatch, useAppSelector } from "redux/hooks";
import { selectSystem, selectUser } from "module/auth/redux/authSlice";
import { MaintenanceTaskInterface } from "module/maintenanceTask/types";
import MaintenanceTaskContext from "module/maintenanceTask/context/MaintenanceTaskContext";
import { EntityType } from "module/entity/types";
import useMyEntityValidation from "module/entity/useMyEntityValidation";
import { API_DATE_TIME_FORMAT } from "api/date";
import {
  addToEntityPropertyList,
  addToMyEntities,
} from "module/staff/redux/staffDataSlice";
import { v4 as uuid } from "uuid";
import moment from "moment";
import {
  MaintenanceTaskPersonnelAssignmentInternalStaffInterface,
  MyMaintenanceTaskPersonnelAssignmentInternalStaffInterface,
} from "module/maintenanceTaskPersonnelAssignment/internalStaff/types";
import { MaintenanceTaskPersonnelAssignmentResponsibility } from "module/maintenanceTaskPersonnelAssignment/types";

interface AddProps {
  responsibility?: MaintenanceTaskPersonnelAssignmentResponsibility;
  internalStaffId?: number;
}

interface Result {
  add: (addProps: AddProps) => void;
}

const useMaintenanceTaskPersonnelAssignmentInternalStaffAdd = (): Result => {
  const { entity } = useContext(MaintenanceTaskContext);
  const maintenanceTask = entity as MaintenanceTaskInterface;

  const dispatch = useAppDispatch();
  const staffId = useAppSelector(selectUser).id;
  const systemShortName = useAppSelector(selectSystem).shortName;

  const { validateMyEntities } = useMyEntityValidation();

  const add = useCallback(
    ({ responsibility = undefined, internalStaffId = undefined }: AddProps) => {
      const maintenanceTaskEntityIdentifier = {
        id: maintenanceTask.id,
        type: EntityType.maintenanceTask,
      };

      const id = uuid();
      const entityIdentifier = {
        type: EntityType.maintenanceTaskPersonnelAssignmentInternalStaff,
        id,
      };

      const today = moment()
        .set({ hour: 0, minute: 0, second: 0 })
        .format(API_DATE_TIME_FORMAT);

      const data: MaintenanceTaskPersonnelAssignmentInternalStaffInterface = {
        id,
        maintenanceTaskId: maintenanceTask.id,
        staffId: internalStaffId,
        responsibility,
        relationships: undefined,
        relationshipIdentifiers: [],
        changedDateTime: today,
      };

      const myEntity: MyMaintenanceTaskPersonnelAssignmentInternalStaffInterface =
        {
          entityIdentifier,
          data,
          dataWithoutChanges: data,
          validationErrors: null,
          validationWarnings: null,
        };

      dispatch(
        addToMyEntities({
          staffId,
          systemShortName,
          entities: [myEntity],
        })
      );

      validateMyEntities([entityIdentifier]);

      dispatch(
        addToEntityPropertyList({
          staffId,
          systemShortName,
          baseEntityIdentifier: maintenanceTaskEntityIdentifier,
          propertyName: "personnelAssignments",
          entityIdentifierToAdd: entityIdentifier,
        })
      );
    },
    [dispatch, maintenanceTask.id, staffId, systemShortName, validateMyEntities]
  );

  return {
    add,
  };
};

export default useMaintenanceTaskPersonnelAssignmentInternalStaffAdd;
