import React from "react";
import { EntityFieldMetaDataContextInterface } from "module/entity/context/fieldMetaData/EntityFieldMetaDataContext";

export type MyAssessmentFieldMetaDataContextInterface =
  EntityFieldMetaDataContextInterface;

const AssessmentFieldMetaDataContext = React.createContext(
  <MyAssessmentFieldMetaDataContextInterface>{}
);

export default AssessmentFieldMetaDataContext;
