import React from "react";
import withOnlineDetector from "module/onlineDetector/withOnlineDetector";
import SortContextProvider from "module/sort/context/SortContextProvider";
import { SortDirection } from "module/sort/types";
import FilterContextProvider from "module/filter/context/FilterContextProvider";
import { InventoryCheckState } from "module/inventoryCheck/types";
import { InventoryCheckSortProperty } from "./types";
import InventoryCheckListContainer from "./InvenotryCheckListContainer";

const InventoryCheckListPage: React.FC = () => {
  return (
    <FilterContextProvider
      {...{
        initialValue: {
          states: [InventoryCheckState.scheduled],
        },
      }}
    >
      <SortContextProvider
        {...{
          initialValue: {
            fieldName: InventoryCheckSortProperty.createdAt,
            direction: SortDirection.asc,
          },
        }}
      >
        <InventoryCheckListContainer />
      </SortContextProvider>
    </FilterContextProvider>
  );
};

export default React.memo(withOnlineDetector(InventoryCheckListPage));
