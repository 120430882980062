import { PayloadAction } from "@reduxjs/toolkit";
import { StaffDataState } from "module/staff/redux/types";
import { EntityIdentifier } from "module/entity/types";
import { getEntityIdentifierKey } from "module/entity/helper/getEntityIdentifierKey";

interface ChangeEntityActionProps<EntityInterface> {
  staffId: number;
  systemShortName: string;
  entityIdentifier: EntityIdentifier;
  changes: Partial<EntityInterface>;
}

const changeEntityAction = <EntityInterface>(
  state: StaffDataState,
  action: PayloadAction<ChangeEntityActionProps<EntityInterface>>
): void => {
  const { staffId, systemShortName, entityIdentifier, changes } =
    action.payload;

  state[staffId][systemShortName].entities[
    getEntityIdentifierKey(entityIdentifier)
  ].data = {
    ...state[staffId][systemShortName].entities[
      getEntityIdentifierKey(entityIdentifier)
    ].data,
    ...changes,
  };
};

export default changeEntityAction;
