import React from "react";
import Typography from "@mui/material/Typography";
import makeStyles from "@mui/styles/makeStyles";
import { ValidatorInterface } from "module/validation/types";
import FormControl from "@mui/material/FormControl";
import ValidationErrorMessage from "component/validationErrorMessage/ValidationErrorMessage";
import ValidationWarningMessage from "component/validationWarningMessage/ValidationWarningMessage";
import Caption from "component/caption/Caption";

const useStyles = makeStyles(() => ({
  wrapper: {
    width: "100%",
    padding: "8px 0",
  },
}));

interface Props {
  caption?: string;
  label?: string;
  errors?: ValidatorInterface[];
  warnings?: ValidatorInterface[];
  children?: React.ReactNode;
}

// TODO: remove it
const LabelField: React.FC<Props> = ({
  caption,
  label,
  errors,
  warnings,
  children,
}) => {
  const classes = useStyles();
  const hasError = errors != null && Boolean(errors.length);

  const labelDefault = children == null ? "-" : "";

  return (
    <FormControl
      error={hasError}
      className={classes.wrapper}
      variant="standard"
    >
      {caption && <Caption {...{ text: caption }} />}
      <Typography variant="body2" gutterBottom>
        {label && label.length ? label : labelDefault}
      </Typography>
      {children}
      {errors && <ValidationErrorMessage {...{ errors }} />}
      {warnings && <ValidationWarningMessage {...{ warnings }} />}
    </FormControl>
  );
};

export default React.memo(LabelField);
