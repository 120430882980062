import React from "react";
import { EntityIdentifier } from "module/entity/types";
import MyEntityContextProvider from "module/entity/context/entity/MyEntityContextProvider";
import MyEntityChangeContextProvider from "module/entity/context/change/MyEntityChangeContextProvider";
import MyTrainingStateContextProvider from "module/training/state/context/MyTrainingStateContextProvider";
import TrainingContext from "module/training/context/TrainingContext";
import MyTrainingFieldMetaDataContextProvider from "module/training/validation/fieldMetaData/MyTrainingFieldMetaDataContextProvider";
import TrainingChangeContext from "module/training/change/TrainingChangeContext";
import MyTrainingOpenCloseContextProvider from "module/training/openClose/context/MyTrainingOpenCloseContextProvider";
import MyEntityValidationResultContextProvider from "module/entity/context/validationResult/MyEntityValidationResultContextProvider";
import TrainingValidationResultContext from "module/training/validation/TrainingValidationResultContext";

interface MyTrainingDetailsContextProviderInterface {
  entityIdentifier: EntityIdentifier;
  children: React.ReactNode;
}

const MyTrainingDetailsContextProvider: React.FC<MyTrainingDetailsContextProviderInterface> =
  ({ children, entityIdentifier }) => {
    return (
      <MyEntityContextProvider
        {...{ entityIdentifier, context: TrainingContext }}
      >
        <MyEntityValidationResultContextProvider
          {...{ entityIdentifier, context: TrainingValidationResultContext }}
        >
          <MyTrainingOpenCloseContextProvider>
            <MyTrainingStateContextProvider>
              <MyTrainingFieldMetaDataContextProvider>
                <MyEntityChangeContextProvider
                  {...{ entityIdentifier, context: TrainingChangeContext }}
                >
                  {children}
                </MyEntityChangeContextProvider>
              </MyTrainingFieldMetaDataContextProvider>
            </MyTrainingStateContextProvider>
          </MyTrainingOpenCloseContextProvider>
        </MyEntityValidationResultContextProvider>
      </MyEntityContextProvider>
    );
  };

export default MyTrainingDetailsContextProvider;
