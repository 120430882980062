import React, { useContext, useCallback } from "react";
import { MAINTENANCE_TASK_PERSONNEL_ASSIGNMENT_INTERNAL_STAFF_DURATION_FIELD_NAME } from "module/maintenanceTaskPersonnelAssignment/externalStaff/constants";
import XLabelField from "component/labelField/XLabelField";
import { getFieldMetaDataWithErrorHandling } from "module/fieldMetaData/getFieldMetaData";
import DurationField from "component/durationField/DurationField";
import MaintenanceTaskPersonnelAssignmentInternalStaffContext from "../../context/MaintenanceTaskPersonnelAssignmentInternalStaffContext";
import MaintenanceTaskPersonnelAssignmentInternalStaffChangeContext from "../../change/MaintenanceTaskPersonnelAssignmentInternalStaffChangeContext";
import MaintenanceTaskPersonnelAssignmentInternalStaffFieldMetaDataContext from "../../validation/fieldMetaData/MaintenanceTaskPersonnelAssignmentInternalStaffFieldMetaDataContext";
import { MaintenanceTaskPersonnelAssignmentInternalStaffInterface } from "../../types";
import { MaintenanceTaskPersonnelAssignmentResponsibility } from "../../../types";

const MaintenanceTaskPersonnelAssignmentInternalStaffDurationFieldContainer: React.FC =
  () => {
    const { entity } = useContext(
      MaintenanceTaskPersonnelAssignmentInternalStaffContext
    );
    const { change: changeHelper } = useContext(
      MaintenanceTaskPersonnelAssignmentInternalStaffChangeContext
    );
    const { getFieldMetaData } = useContext(
      MaintenanceTaskPersonnelAssignmentInternalStaffFieldMetaDataContext
    );

    const maintenanceTaskPersonnelAssignment =
      entity as MaintenanceTaskPersonnelAssignmentInternalStaffInterface;

    const { warnings, errors, isEditable } = getFieldMetaDataWithErrorHandling(
      () =>
        getFieldMetaData(
          MAINTENANCE_TASK_PERSONNEL_ASSIGNMENT_INTERNAL_STAFF_DURATION_FIELD_NAME
        )
    );

    const change = useCallback(
      (duration?: number) => {
        changeHelper({ duration });
      },
      [changeHelper]
    );

    if (
      isEditable &&
      maintenanceTaskPersonnelAssignment.responsibility !==
        MaintenanceTaskPersonnelAssignmentResponsibility.LEAD
    ) {
      return (
        <DurationField
          {...{
            value: maintenanceTaskPersonnelAssignment.duration,
            errors,
            warnings,
            change,
            unit: "min",
          }}
        />
      );
    }

    const hours =
      maintenanceTaskPersonnelAssignment.duration != null
        ? Math.floor(maintenanceTaskPersonnelAssignment.duration / (60 * 60))
        : 0;
    const minutes =
      maintenanceTaskPersonnelAssignment.duration != null
        ? Math.floor(
            (maintenanceTaskPersonnelAssignment.duration - hours * 60 * 60) / 60
          )
        : 0;

    const label =
      maintenanceTaskPersonnelAssignment.duration == null
        ? "-"
        : `${hours?.toString().padStart(2, "0")}:${minutes
            .toString()
            .padStart(2, "0")}`;

    return <XLabelField {...{ label, warnings, errors }} />;
  };

export default React.memo(
  MaintenanceTaskPersonnelAssignmentInternalStaffDurationFieldContainer
);
