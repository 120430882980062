import React, { useContext } from "react";
import MaintenanceTaskContext from "module/maintenanceTask/context/MaintenanceTaskContext";
import {
  MaintenanceTaskAssistantType,
  MaintenanceTaskInterface,
} from "module/maintenanceTask/types";
import useMaintenanceTaskAssistantRemove from "module/maintenanceTaskAssisstant/remove/useMaintenanceTaskAssistantRemove";
import MaintenanceTaskAssistantTableRow from "module/maintenanceTaskAssisstant/enrichedComponent/table/MaintenanceTaskAssistantTableRow";

const MyMaintenanceTaskDetailsAssistantTableRowContainer: React.FC = () => {
  const { entity } = useContext(MaintenanceTaskContext);
  const maintenanceTask = entity as MaintenanceTaskInterface;

  const { canRemove, remove } = useMaintenanceTaskAssistantRemove();

  return (
    <MaintenanceTaskAssistantTableRow
      {...{
        isActionAvailable: true,
        assistantType:
          maintenanceTask.assistantType as MaintenanceTaskAssistantType,
        canRemove,
        remove,
      }}
    />
  );
};

export default React.memo(MyMaintenanceTaskDetailsAssistantTableRowContainer);
