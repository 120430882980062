import React from "react";
import LabelField from "component/labelField/LabelField";
import t from "module/translations";
import StaffNameText from "module/staff/StaffNameText";
import Typography from "@mui/material/Typography";

interface Props {
  internalTrainerId?: number;
  externalTrainer?: string;
}

const AssessmentTrainerLabelField: React.FC<Props> = ({
  internalTrainerId,
  externalTrainer,
}) => {
  return (
    <LabelField
      {...{
        caption: t.competencyManagement.assessment.trainerText,
      }}
    >
      <Typography variant="body2" gutterBottom>
        {internalTrainerId && (
          <StaffNameText {...{ staffId: internalTrainerId }} />
        )}
        {externalTrainer && externalTrainer}
        {!internalTrainerId && !externalTrainer && "-"}
      </Typography>
    </LabelField>
  );
};

export default React.memo(AssessmentTrainerLabelField);
