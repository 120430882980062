import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import type { RootState } from "redux/store";
import { getStateSliceFromLocalStorage } from "module/storage/storage";
import {
  Staff,
  SystemConfigurationInterface,
  SystemInterface,
  UserInterface,
} from "module/auth/types";
import {
  StockArticle,
  StockArticleCollection,
} from "module/stockArticle/types";
import { MaintenanceTaskProcessStepQuickFixTypeCollectionInterface } from "module/maintenanceTaskProcessStepQuickFix/types";
import { sliceKey, defaultState } from "module/auth/constants";
import { ArticleCollection } from "module/article/types";
import {
  ApiMaintenanceTaskTemplateInterface,
  MaintenanceTaskTemplateCollection,
} from "api/types";

interface AuthState {
  apiToken: string | null;
  user: UserInterface | null;
  system: string | null;
}

const initialState: AuthState =
  getStateSliceFromLocalStorage(sliceKey) ?? defaultState;

const slice = createSlice({
  name: sliceKey,
  initialState,
  reducers: {
    setApiToken: (state, action: PayloadAction<string>) => {
      return { ...state, apiToken: action.payload };
    },
    setUser: (state, action: PayloadAction<UserInterface | null>) => {
      return { ...state, user: action.payload };
    },
    setSystem: (state, action: PayloadAction<string | null>) => {
      return { ...state, system: action.payload };
    },
    setSystemConfiguration: (state, action: PayloadAction<SystemInterface>) => {
      if (state.user != null) {
        return {
          ...state,
          user: {
            ...state.user,
            availableSystems: state.user?.availableSystems.reduce(
              (result: SystemInterface[], current) => {
                if (current.shortName === action.payload.shortName) {
                  return [...result, action.payload];
                }
                return [...result, { ...current, configuration: null }];
              },
              []
            ),
          },
        };
      }
      return state;
    },
    setDefaultState: () => {
      return defaultState;
    },
  },
});

export const { reducer, actions } = slice;
export const {
  setApiToken,
  setDefaultState,
  setUser,
  setSystem,
  setSystemConfiguration,
} = actions;

export const selectIsAppInitialized = (state: RootState): boolean =>
  state.auth.user !== null && state.auth.system !== null;

export const selectUser = (state: RootState): UserInterface => {
  if (state.auth.user === null) {
    throw Error("User is not initialized");
  }

  return state.auth.user;
};

export const selectSystem = (state: RootState): SystemInterface => {
  if (state.auth.system === null || state.auth.user === null) {
    throw Error("System is not initialized.");
  }

  return (
    state.auth.user.availableSystems.find(
      (availableSystem) => availableSystem.shortName === state.auth.system
    ) || state.auth.user.availableSystems[0]
  );
};

export const selectSystemConfiguration = (
  state: RootState
): SystemConfigurationInterface => {
  const system = selectSystem(state);

  if (system.configuration == null) {
    throw Error("System is not initialized.");
  }

  return system.configuration;
};

export const selectStaffs = (state: RootState): Staff[] => {
  return selectSystemConfiguration(state).staffs;
};

export const selectStaffPermissions = (state: RootState): string[] => {
  return selectSystemConfiguration(state).staffPermissions;
};

export const selectStockArticles = (
  state: RootState
): StockArticleCollection => {
  return selectSystemConfiguration(state).stockArticles;
};

export const selectArticles = (state: RootState): ArticleCollection => {
  return selectSystemConfiguration(state).articles;
};

export const selectMaintenanceTaskTemplates = (
  state: RootState
): MaintenanceTaskTemplateCollection | undefined => {
  return selectSystemConfiguration(state).maintenanceTaskTemplates;
};

export const selectMaintenanceTaskTemplateById =
  (state: RootState) =>
  (
    maintenanceTaskTemplateId: number
  ): ApiMaintenanceTaskTemplateInterface | undefined => {
    return selectSystemConfiguration(state).maintenanceTaskTemplates?.[
      maintenanceTaskTemplateId
    ];
  };

export const selectStockArticleById =
  (state: RootState) =>
  (stockArticleId: number): StockArticle => {
    return selectStockArticles(state)[stockArticleId];
  };

export const selectStaffById =
  (state: RootState) =>
  (staffId: number): Staff => {
    const system = selectSystem(state);
    const staff = system.configuration?.staffs.find(
      (systemStaff) => systemStaff.id === staffId
    );
    if (staff === undefined) {
      throw Error(`Staff (id: ${staffId})does not exists.`);
    }

    return staff;
  };

export const selectMaintenanceTaskProcessStepQuickFixTypes = (
  state: RootState
): MaintenanceTaskProcessStepQuickFixTypeCollectionInterface => {
  return selectSystemConfiguration(state)
    .maintenanceTaskProcessStepQuickFixAvailableTypes;
};
