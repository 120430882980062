import React from "react";
import useOnlineDetector from "module/onlineDetector/useOnlineDetector";
import OnlineDetectorContext from "module/onlineDetector/OnlineDetectorContext";

interface Prop {
  children: React.ReactNode;
}
const OnlineDetectorContextProvider: React.FC<Prop> = ({ children }) => {
  const { isOnline } = useOnlineDetector();
  return (
    <OnlineDetectorContext.Provider value={{ isOnline }}>
      {children}
    </OnlineDetectorContext.Provider>
  );
};

export default OnlineDetectorContextProvider;
