import React, { useCallback } from "react";
import makeStyles from "@mui/styles/makeStyles";
import t from "module/translations";
import SelectField from "component/selectField/SelectField";
import { EntityType } from "module/entity/types";
import { SelectOption, SelectOptionValue } from "component/selectField/type";

const useToolbarStyles = makeStyles(() => ({
  type: {
    margin: "8px 15px 0 0",
    minWidth: "120px",
  },
}));

interface Props {
  options: SelectOption[];
  change: (newType: EntityType) => void;
  value: SelectOptionValue;
}

const CompetencyManagementListToolbarFilterType: React.FC<Props> = ({
  change,
  options,
  value,
}) => {
  const classes = useToolbarStyles();

  const handleChange = useCallback(
    (type: unknown) => {
      change(type as EntityType);
    },
    [change]
  );

  return (
    <div className={classes.type}>
      <SelectField
        {...{
          options,
          change: handleChange,
          value,
          label: t.competencyManagement.typeText,
        }}
      />
    </div>
  );
};

export default React.memo(CompetencyManagementListToolbarFilterType);
