import React, { useCallback } from "react";
import Button from "@mui/material/Button";
import ButtonGroup from "@mui/material/ButtonGroup";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import Grow from "@mui/material/Grow";
import Paper from "@mui/material/Paper";
import Popper from "@mui/material/Popper";
import MenuItem from "@mui/material/MenuItem";
import MenuList from "@mui/material/MenuList";
import makeStyles from "@mui/styles/makeStyles";
import { DropdownButtonOption } from "./type";
import { filterOutHiddenOptions } from "./helper";

const useStyles = makeStyles(() => ({
  popper: {
    zIndex: 2,
  },
  button: {
    padding: "8px 0",
  },
}));

interface Props {
  options: DropdownButtonOption[];
  onChange: (newValue: unknown) => void;
  value: unknown;
  color?: "primary" | "warning";
  size?: "small" | "medium" | "large";
}

const ID = "dropdown-button";

const DropdownButtonField: React.FC<Props> = ({
  options,
  onChange,
  value,
  color = "primary",
  size = "large",
}) => {
  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef<HTMLDivElement>(null);

  const handleChange = useCallback(
    (newValue: unknown) => () => {
      setOpen(false);
      onChange(newValue);
    },
    [onChange]
  );

  const handleToggle = useCallback(() => {
    setOpen((prevOpen) => !prevOpen);
  }, []);

  const handleClose = useCallback((event: MouseEvent | TouchEvent): void => {
    if (
      anchorRef.current &&
      anchorRef.current.contains(event.target as HTMLElement)
    ) {
      return;
    }

    setOpen(false);
  }, []);
  const classes = useStyles();

  const text =
    value != null ? options.find((option) => option.value === value)?.text : "";

  return (
    <>
      <ButtonGroup
        variant="contained"
        color={color}
        ref={anchorRef}
        aria-label="split button"
        size={size}
      >
        <Button>{text}</Button>
        <Button
          className={classes.button}
          color={color}
          aria-controls={open ? ID : undefined}
          aria-expanded={open ? "true" : undefined}
          aria-haspopup="menu"
          onClick={handleToggle}
        >
          <ArrowDropDownIcon />
        </Button>
      </ButtonGroup>
      <Popper
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
        className={classes.popper}
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin:
                placement === "bottom" ? "center top" : "center bottom",
            }}
          >
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList id={ID}>
                  {options.filter(filterOutHiddenOptions).map((option) => (
                    <MenuItem
                      key={option.value as string}
                      selected={option.value === value}
                      onClick={handleChange(option.value)}
                    >
                      {option.text}
                    </MenuItem>
                  ))}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </>
  );
};

export default React.memo(DropdownButtonField);
