import React, { useContext, useMemo } from "react";
import TableCell from "@mui/material/TableCell";
import Checkbox from "@mui/material/Checkbox";
import ToggleContext from "module/toggle/context/ToggleContext";
import InfoIconWithReason from "module/block/InfoIconWithReason";
import { EntityType } from "module/entity/types";
import TrainingCustomContext from "module/trainingCustom/context/TrainingCustomContext";
import { TrainingCustomInterface } from "module/trainingCustom/types";
import useGetTrainingCustomBlockedReasonText from "module/trainingCustom/helper/useGetTrainingCustomBlockedReasonText";

const CompetencyManagementListTableRowTrainingCustomSelectTableCellContainer: React.FC =
  () => {
    const { entity } = useContext(TrainingCustomContext);
    const { isSelected: isSelectedHelper } = useContext(ToggleContext);

    const trainingCustom = entity as TrainingCustomInterface;

    const isBlockedReasonText =
      useGetTrainingCustomBlockedReasonText(trainingCustom);

    const isSelected = useMemo(
      () =>
        isSelectedHelper({
          id: trainingCustom.id,
          type: EntityType.trainingCustom,
        }),
      [isSelectedHelper, trainingCustom.id]
    );

    return (
      <TableCell padding="checkbox">
        {isBlockedReasonText ? (
          <InfoIconWithReason {...{ reason: isBlockedReasonText as string }} />
        ) : (
          <Checkbox checked={isSelected} />
        )}
      </TableCell>
    );
  };

export default React.memo(
  CompetencyManagementListTableRowTrainingCustomSelectTableCellContainer
);
