const MICROSOFT_OAUTH_PATH =
  "https://login.microsoftonline.com/common/oauth2/v2.0";
const MICROSOFT_OAUTH_SCOPE =
  "openid%20user.read%20user.read.all%20offline_access%20profile";
// TODO: it should be generated per request?
const MICROSOFT_OAUTH_NONCE = "example";
export const LOGOUT_VALUE = "logout";

export const MICROSOFT_LOGIN_OAUTH_STEP1_PATH = `${MICROSOFT_OAUTH_PATH}/authorize?client_id=${
  process.env.REACT_APP_MICROSOFT_GRAPH_CLIENT_ID
}&response_type=id_token&scope=${MICROSOFT_OAUTH_SCOPE}&nonce=${MICROSOFT_OAUTH_NONCE}&redirect_uri=${encodeURIComponent(
  `${global.location.origin}`
)}`;
export const MICROSOFT_LOGIN_OAUTH_STEP2_PATH = `${MICROSOFT_OAUTH_PATH}/authorize?client_id=${
  process.env.REACT_APP_MICROSOFT_GRAPH_CLIENT_ID
}&response_type=id_token%20token&scope=${MICROSOFT_OAUTH_SCOPE}&nonce=${MICROSOFT_OAUTH_NONCE}&redirect_uri=${encodeURIComponent(
  `${global.location.origin}`
)}`;
export const MICROSOFT_LOGOUT_OAUTH_PATH = `${MICROSOFT_OAUTH_PATH}/logout?post_logout_redirect_uri=${encodeURIComponent(
  `${global.location.origin}/#logout=${LOGOUT_VALUE}`
)}`;

export const sliceKey = "auth";

export const defaultState = {
  apiToken: null,
  user: null,
  system: null,
};
