import { useContext, useCallback } from "react";
import usePermissions from "module/permission/usePermissions";
import { useAppSelector } from "redux/hooks";
import _ from "lodash";
import OnlineDetectorContext from "module/onlineDetector/OnlineDetectorContext";
import isAssessmentSyncAvailable from "module/assessment/sync/isAssessmentSyncAvailable";
import selectEntity from "module/staff/redux/selector/selectEntity";
import { EntityIdentifier, EntityType } from "module/entity/types";
import selectOpenItemByEntityType from "module/staff/redux/selector/selectOpenItemByEntityType";
import { IsEntitySyncAvailableResult } from "module/entity/useEntityAbstractFactory";
import selectHasEntityOrRelatedEntityError from "module/staff/redux/selector/selectHasEntityOrRelatedEntityError";

interface UseAssessmentIsSyncAvailableResult {
  isAssessmentSyncAvailable: (id: string) => IsEntitySyncAvailableResult;
}

const useAssessmentIsSyncAvailable = (): UseAssessmentIsSyncAvailableResult => {
  const { canEditAssessment: hasPermissionsToSync } = usePermissions();
  const { isOnline } = useContext(OnlineDetectorContext);
  const openAssessmentId = useAppSelector(
    selectOpenItemByEntityType(EntityType.assessment)
  )?.id;
  const selectAssessment = useAppSelector(selectEntity);
  const selectHasError = useAppSelector(selectHasEntityOrRelatedEntityError);

  const isAssessmentSyncAvailableHelper = useCallback(
    (id: string): IsEntitySyncAvailableResult => {
      const entityIdentifier: EntityIdentifier = {
        id,
        type: EntityType.assessment,
      };
      const myAssessment = selectAssessment(entityIdentifier);
      const { data, dataWithoutChanges } = myAssessment;

      const isValid = !selectHasError(entityIdentifier);
      const hasChanged = !_.isEqual(data, dataWithoutChanges);

      return isAssessmentSyncAvailable({
        hasPermissions: hasPermissionsToSync,
        isOnline,
        isOpen: myAssessment && data.id === openAssessmentId,
        isValid,
        hasChanged,
      });
    },
    [
      hasPermissionsToSync,
      isOnline,
      openAssessmentId,
      selectAssessment,
      selectHasError,
    ]
  );

  return {
    isAssessmentSyncAvailable: isAssessmentSyncAvailableHelper,
  };
};

export default useAssessmentIsSyncAvailable;
