export const MAINTENANCE_TASK_NAME_FIELD_NAME = "name";
export const MAINTENANCE_TASK_REMARKS_FIELD_NAME = "remarks";
export const MAINTENANCE_TASK_OBSERVATIONAL_INDICATORS_FIELD_NAME =
  "observationalIndicators";
export const MAINTENANCE_TASK_EXPECTED_START_DATE_FIELD_NAME =
  "expectedStartDate";
export const MAINTENANCE_TASK_EXPECTED_END_DATE_FIELD_NAME = "expectedEndDate";
export const MAINTENANCE_TASK_SCHEDULED_START_DATE_FIELD_NAME =
  "scheduledStartDate";
export const MAINTENANCE_TASK_SCHEDULED_END_DATE_FIELD_NAME =
  "scheduledEndDate";
export const MAINTENANCE_TASK_PRESUMED_CAUSES_FIELD_NAME = "presumedCauses";
export const MAINTENANCE_TASK_MAINTAINED_AT_VALUE_FIELD_NAME =
  "maintainedAtValue";
export const MAINTENANCE_TASK_ASSISTANT_TYPE_FIELD_NAME = "assistantType";
export const MAINTENANCE_TASK_ASSISTANT_INTERNAL_STAFF_FIELD_NAME =
  "assistantInternalStaffId";
export const MAINTENANCE_TASK_ASSISTANT_EXTERNAL_STAFF_NAME_FIELD_NAME =
  "assistantExternalStaffName";
export const MAINTENANCE_TASK_ASSISTANT_EXTERNAL_STAFF_COMPANY_FIELD_NAME =
  "assistantExternalStaffCompany";
