import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import useMaintenanceTaskCorrectiveCreate from "module/maintenanceTask/createCorrective/useMaintenanceTaskCorrectiveCreate";
import ErrorPage from "error/ErrorPage";
import { getPathToMaintenanceTaskDetailsOverview } from "module/maintenanceTask/helper/getPath";

const MaintenanceTaskServiceRequestPage: React.FC = () => {
  const { canCreate, create } = useMaintenanceTaskCorrectiveCreate();
  const history = useHistory();

  useEffect(() => {
    if (canCreate) {
      const id = create();
      history.push(getPathToMaintenanceTaskDetailsOverview(id));
    }
  }, [canCreate, create, history]);

  if (!canCreate) {
    return <ErrorPage />;
  }

  return null;
};

export default React.memo(MaintenanceTaskServiceRequestPage);
