import { mergeEntityMaps } from "module/entity/helper/mergeEntityMaps";
import { EntityType } from "module/entity/types";
import {
  ApiInventoryCheckArticleInterface,
  ApiInventoryCheckInterface,
} from "api/inventoryCheck/types";
import {
  InventoryCheckArticleInterface,
  MyInventoryCheckArticleInterface,
} from "module/inventoryCheckArticle/types";

export const mapApiInventoryCheckArticleToApp = (
  rawInventoryCheck: ApiInventoryCheckInterface,
  raw: ApiInventoryCheckArticleInterface
): MyInventoryCheckArticleInterface => {
  const relationships = mergeEntityMaps([]);

  const data: InventoryCheckArticleInterface = {
    ...raw,
    id: `${raw.id}`,
    backendId: raw.id,
    inventoryCheckId: `${rawInventoryCheck.id}`,

    relationships,
    relationshipIdentifiers: Array.from(relationships.keys()),
  };

  return {
    entityIdentifier: {
      type: EntityType.inventoryCheckArticle,
      id: `${raw.id}`,
      backendId: raw.id,
    },
    data,
    dataWithoutChanges: data,
    raw,
    validationErrors: null,
    validationWarnings: null,
  };
};
