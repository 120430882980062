import React, { useContext } from "react";
import useDateFormat from "toolkit/useDateFormat";
import MaintenanceTaskContext from "module/maintenanceTask/context/MaintenanceTaskContext";
import { MaintenanceTaskInterface } from "module/maintenanceTask/types";
import XLabelField from "component/labelField/XLabelField";
import t from "module/translations";

const MaintenanceTaskCompletionStartDateFieldContainer: React.FC = () => {
  const { entity } = useContext(MaintenanceTaskContext);
  const { completionStartDate } = entity as MaintenanceTaskInterface;

  const { formatDateTime } = useDateFormat();

  return (
    <XLabelField
      {...{
        caption: t.maintenanceTask.completionStartText,
        label: completionStartDate
          ? formatDateTime(completionStartDate)
          : undefined,
      }}
    />
  );
};

export default React.memo(MaintenanceTaskCompletionStartDateFieldContainer);
