import React from "react";
import { Link as RouterLink } from "react-router-dom";
import MUILink from "@mui/material/Link";
import IconButton from "@mui/material/IconButton";
import makeStyles from "@mui/styles/makeStyles";
import { LinkProps } from "./type";

const useStyles = makeStyles(() => ({
  name: {
    color: "#000",
  },
}));

const Link: React.FC<LinkProps> = ({ path, label, icon, iconBefore }) => {
  const classes = useStyles();
  return (
    <MUILink to={path} component={RouterLink} underline="none">
      {iconBefore && (
        <IconButton size="small" color="primary">
          {iconBefore}
        </IconButton>
      )}
      <span className={classes.name}>{label || "-"}</span>
      {icon && (
        <IconButton size="small" color="primary">
          {icon}
        </IconButton>
      )}
    </MUILink>
  );
};

export default React.memo(Link);
