import React from "react";
import AppBar from "component/appBar/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Hamburger from "component/hamburger/Hamburger";
import FlexGrow from "component/flexGrow/FlexGrow";
import HeaderProfile from "component/headerProfile/HeaderProfile";
import HeaderConnectionContainer from "layout/header/HeaderConnectionContainer";
import HeaderSubtitle from "./HeaderSubTitle";
import HeaderTitle from "./HeaderTitle";

interface Props {
  isMenuOpen: boolean;
  openMenu: () => void;
  openUserMenu: () => void;
}

const Header: React.FC<Props> = ({ isMenuOpen, openMenu, openUserMenu }) => {
  return (
    <AppBar {...{ isMenuOpen }}>
      <Toolbar>
        <Hamburger {...{ isMenuOpen, openMenu }} />
        <HeaderTitle />
        <HeaderSubtitle />
        <FlexGrow />
        <HeaderConnectionContainer />
        <HeaderProfile open={openUserMenu} />
      </Toolbar>
    </AppBar>
  );
};

export default React.memo(Header);
