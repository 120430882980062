import React, { useContext } from "react";
import { getFieldMetaData as getFieldMetaDataHelper } from "module/fieldMetaData/getFieldMetaData";
import MaintenanceTaskOpenCloseContext from "module/maintenanceTask/openClose/context/MaintenanceTaskOpenCloseContext";
import MaintenanceTaskStateContext from "module/maintenanceTask/state/context/MaintenanceTaskStateContext";
import MyMaintenanceTaskPartFieldMetaDataContext, {
  MaintenanceTaskPartFieldMetaDataContextInterface,
} from "module/maintenanceTaskPart/validation/fieldMetaData/MaintenanceTaskPartFieldMetaDataContext";
import MaintenanceTaskPartValidationResultContext from "module/maintenanceTaskPart/validation/MaintenanceTaskPartValidationResultContext";

interface Prop {
  children: React.ReactNode;
}

const MyMaintenanceTaskPartFieldMetaDataContextProvider: React.FC<Prop> = ({
  children,
}) => {
  const { validationWarnings, validationErrors } = useContext(
    MaintenanceTaskPartValidationResultContext
  );
  const { isOpen } = useContext(MaintenanceTaskOpenCloseContext);
  const { isStateScheduledOrLower } = useContext(MaintenanceTaskStateContext);

  const getFieldMetaData = getFieldMetaDataHelper({
    validationWarnings,
    validationErrors,
    isOpen,
    isStateScheduledOrLower,
  });

  const contextValue: MaintenanceTaskPartFieldMetaDataContextInterface = {
    getFieldMetaData,
  };

  return (
    <MyMaintenanceTaskPartFieldMetaDataContext.Provider value={contextValue}>
      {children}
    </MyMaintenanceTaskPartFieldMetaDataContext.Provider>
  );
};

export default MyMaintenanceTaskPartFieldMetaDataContextProvider;
