import { MaintenanceTaskType } from "module/maintenanceTask/types";

export enum MaintenanceTaskDefinitionType {
  equipment = "equipment",
  system = "system",
  sms = "sms",
  audit = "audit",
}

export interface MaintenanceTaskFilters {
  scheduledDate: Date;
  types: MaintenanceTaskType[];
  definitionTypes: MaintenanceTaskDefinitionType[];
}

export enum MaintenanceTaskSortProperty {
  name = "name",
  type = "type",
  state = "state",
  scheduled = "scheduledStartDate",
  reportingNumber = "reportingNumber",
}
