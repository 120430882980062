import React, { useCallback } from "react";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import useMediaQuery from "@mui/material/useMediaQuery";
import ListSubheader from "@mui/material/ListSubheader";
import { useTheme } from "@mui/material/styles";
import { ListChildComponentProps, VariableSizeList } from "react-window";
import XValidationMessage from "module/validation/XValidationMessage";
import { AutocompleteOption } from "./type";

const LIST_BOX_PADDING = 8; // px

function renderRow(props: ListChildComponentProps) {
  const { data, index, style } = props;
  return React.cloneElement(data[index], {
    style: {
      ...style,
      top: (style.top as number) + LIST_BOX_PADDING,
    },
  });
}

const OuterElementContext = React.createContext({});

const OuterElementType = React.forwardRef<HTMLDivElement>((props, ref) => {
  const outerProps = React.useContext(OuterElementContext);
  return <div ref={ref} {...props} {...outerProps} />;
});

function useResetCache(data: unknown) {
  const ref = React.useRef<VariableSizeList>(null);
  React.useEffect(() => {
    if (ref.current != null) {
      ref.current.resetAfterIndex(0, true);
    }
  }, [data]);
  return ref;
}

// Adapter for react-window
const ListBoxComponent = React.forwardRef<HTMLDivElement>(
  function ListBoxComponent(props, ref) {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const { children, ...other } = props;
    const itemData = React.Children.toArray(children);
    const theme = useTheme();
    const smUp = useMediaQuery(theme.breakpoints.up("sm"), { noSsr: true });
    const itemCount = itemData.length;
    const itemSize = smUp ? 36 : 48;

    const getChildSize = useCallback(
      (child: React.ReactNode) => {
        if (React.isValidElement(child) && child.type === ListSubheader) {
          return 48;
        }

        return itemSize;
      },
      [itemSize]
    );

    const getHeight = () => {
      if (itemCount > 8) {
        return 8 * itemSize;
      }
      return itemData.map(getChildSize).reduce((a, b) => a + b, 0);
    };

    const gridRef = useResetCache(itemCount);

    return (
      <div ref={ref}>
        <OuterElementContext.Provider value={other}>
          <VariableSizeList
            itemData={itemData}
            height={getHeight() + 2 * LIST_BOX_PADDING}
            width="100%"
            ref={gridRef}
            outerElementType={OuterElementType}
            innerElementType="ul"
            itemSize={(index) => getChildSize(itemData[index])}
            overscanCount={5}
            itemCount={itemCount}
          >
            {renderRow}
          </VariableSizeList>
        </OuterElementContext.Provider>
      </div>
    );
  }
);

interface Props {
  placeholder?: string;
  options: AutocompleteOption[];
  selected?: AutocompleteOption;
  onChange: (newValue: unknown) => void;
  errors?: string[];
  warnings?: string[];
  label?: string;
}

const AutocompleteField: React.FC<Props> = ({
  placeholder,
  options,
  selected,
  onChange,
  errors,
  warnings,
  label,
}) => {
  const handleChange = useCallback(
    (event: React.ChangeEvent<unknown>, newOption: AutocompleteOption) => {
      onChange(newOption.value);
    },
    [onChange]
  );
  const shouldUseVirtualization = options.length > 25;

  return (
    <Autocomplete
      {...(shouldUseVirtualization
        ? {
            disableListWrap: true,
            ListboxComponent: ListBoxComponent as React.ComponentType<
              React.HTMLAttributes<HTMLElement>
            >,
          }
        : {})}
      value={selected}
      options={options}
      getOptionLabel={(option) => option.text}
      onChange={handleChange}
      disableClearable
      renderInput={(params) => {
        return (
          <TextField
            {...params}
            placeholder={placeholder}
            label={label}
            variant="standard"
            {...(errors != null && errors.length
              ? {
                  helperText: <XValidationMessage messages={errors} />,
                  error: true,
                }
              : {})}
            {...(warnings != null && warnings.length
              ? {
                  helperText: <XValidationMessage messages={warnings} />,
                }
              : {})}
          />
        );
      }}
    />
  );
};

export default React.memo(AutocompleteField);
