import React, { useContext, useCallback } from "react";
import t from "module/translations";
import { ASSESSMENT_OBJECTIVE_ACHIEVED_FIELD_NAME } from "module/assessmentObjective/constants";
import CheckboxField from "component/checkboxField/CheckboxField";
import XLabelField from "component/labelField/XLabelField";
import AssessmentObjectiveContext from "module/assessmentObjective/context/AssessmentObjectiveContext";
import AssessmentObjectiveChangeContext from "module/assessmentObjective/change/AssessmentObjectiveChangeContext";
import AssessmentObjectiveFieldMetaDataContext from "module/assessmentObjective/valiadation/fieldMetaData/AssessmentObjectiveFieldMetaDataContext";
import { AssessmentObjectiveInterface } from "module/assessment/types";
import { getFieldMetaDataWithErrorHandling } from "module/fieldMetaData/getFieldMetaData";

const AssessmentObjectiveAchievedFieldContainer: React.FC = () => {
  const { entity } = useContext(AssessmentObjectiveContext);
  const { change: changeHelper } = useContext(AssessmentObjectiveChangeContext);
  const { getFieldMetaData } = useContext(
    AssessmentObjectiveFieldMetaDataContext
  );

  const assessmentObjective = entity as AssessmentObjectiveInterface;

  const change = useCallback(
    (achieved: boolean) => {
      changeHelper({ achieved });
    },
    [changeHelper]
  );

  const { warnings, errors, isEditable } = getFieldMetaDataWithErrorHandling(
    () => getFieldMetaData(ASSESSMENT_OBJECTIVE_ACHIEVED_FIELD_NAME)
  );

  return isEditable ? (
    <CheckboxField
      {...{
        isChecked: assessmentObjective.achieved,
        change,
        errors,
        warnings,
      }}
    />
  ) : (
    <XLabelField
      {...{
        ...(assessmentObjective.achieved
          ? {
              label: t.yesText,
            }
          : {}),
        warnings,
        errors,
      }}
    />
  );
};

export default React.memo(AssessmentObjectiveAchievedFieldContainer);
