import React, { useContext } from "react";
import t from "module/translations";
import XLabelField from "component/labelField/XLabelField";
import MaintenanceTaskProcessStepAttachmentList from "module/maintenanceTaskProcessStepAttachment/enrichedComponent/list/MaintenanceTaskProcessStepAttachmentList";
import useMaintenanceTaskProcessStepAttachmentAdd from "module/maintenanceTaskProcessStepAttachment/add/useMaintenanceTaskProcessStepAttachmentAdd";
import MaintenanceTaskProcessStepContext from "module/maintenanceTaskProcessStep/context/MaintenanceTaskProcessStepContext";
import { MaintenanceTaskProcessStepInterface } from "module/maintenanceTaskProcessStep/types";
import { EntityIdentifier } from "module/entity/types";

const MaintenanceTaskProcessStepAttachmentListContainer: React.FC = () => {
  const { entity } = useContext(MaintenanceTaskProcessStepContext);
  const maintenanceTaskProcessStep =
    entity as MaintenanceTaskProcessStepInterface;

  const { attachments } = maintenanceTaskProcessStep;

  const { canAdd, add } = useMaintenanceTaskProcessStepAttachmentAdd();

  if ((attachments == null || attachments.length === 0) && !canAdd) {
    return (
      <XLabelField
        {...{
          caption: t.maintenanceTask.processStep.attachmentsText,
          label: t.maintenanceTask.processStep.noAttachmentsText,
        }}
      />
    );
  }

  return (
    <MaintenanceTaskProcessStepAttachmentList
      {...{
        entityIdentifiers:
          (maintenanceTaskProcessStep.attachments as EntityIdentifier[]) ?? [],
        canAdd,
        add,
      }}
    />
  );
};

export default React.memo(MaintenanceTaskProcessStepAttachmentListContainer);
