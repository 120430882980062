import React, { useContext } from "react";
import XLabelField from "component/labelField/XLabelField";
import t from "module/translations";
import AssessmentContext from "module/assessment/context/AssessmentContext";
import { AssessmentInterface } from "module/assessment/types";

const AssessmentReportingNumberFieldContainer: React.FC = () => {
  const { entity } = useContext(AssessmentContext);
  const { reportingNumber } = entity as AssessmentInterface;

  return (
    <XLabelField
      {...{
        caption: t.competencyManagement.assessment.reportingNumberText,
        label: reportingNumber,
      }}
    />
  );
};

export default React.memo(AssessmentReportingNumberFieldContainer);
