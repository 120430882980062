import { AssessmentSyncUnavailableReason } from "module/assessment/types";
import { IsEntitySyncAvailableResult } from "module/entity/useEntityAbstractFactory";

interface IsAssessmentSyncAvailableProps {
  isOnline: boolean;
  hasPermissions: boolean;
  isOpen: boolean;
  isValid: boolean;
  hasChanged: boolean;
}

const isAssessmentSyncAvailable = ({
  hasPermissions,
  isOnline,
  isOpen,
  isValid,
  hasChanged,
}: IsAssessmentSyncAvailableProps): IsEntitySyncAvailableResult => {
  if (!isOnline) {
    return {
      isAvailable: false,
      unavailableReason: AssessmentSyncUnavailableReason.offline,
    };
  }

  if (!hasPermissions) {
    return {
      isAvailable: false,
      unavailableReason: AssessmentSyncUnavailableReason.noPermissions,
    };
  }

  if (isOpen) {
    return {
      isAvailable: false,
      unavailableReason: AssessmentSyncUnavailableReason.isOpen,
    };
  }

  if (!isValid) {
    return {
      isAvailable: false,
      unavailableReason: AssessmentSyncUnavailableReason.notValid,
    };
  }

  if (!hasChanged) {
    return {
      isAvailable: false,
      unavailableReason: AssessmentSyncUnavailableReason.hasNotChanged,
    };
  }

  return {
    isAvailable: true,
    unavailableReason: null,
  };
};

export default isAssessmentSyncAvailable;
