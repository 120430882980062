import React from "react";
import SideMenuSection from "component/sidemenu/SideMenuSection";
import SideMenuItem from "component/sidemenu/SideMenuItem";
import t from "module/translations";
import routes from "routes/routes";
import { useAppSelector } from "redux/hooks";
import selectOpenItemByEntityType from "module/staff/redux/selector/selectOpenItemByEntityType";
import { EntityType } from "module/entity/types";
import { ReactComponent as CompetencyManagementIcon } from "assets/training.svg";
import MenuIcon from "layout/menu/MenuIcon";

const CompetencyMenuSection: React.FC = () => {
  const openTrainingId = useAppSelector(
    selectOpenItemByEntityType(EntityType.training)
  )?.id;
  const openAssessmentId = useAppSelector(
    selectOpenItemByEntityType(EntityType.assessment)
  );
  const openTrainingCustomId = useAppSelector(
    selectOpenItemByEntityType(EntityType.trainingCustom)
  );

  return (
    <SideMenuSection
      {...{
        name: t.sideMenu.competencyManagementSection,
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        icon: <MenuIcon {...{ Icon: CompetencyManagementIcon }} />,
      }}
    >
      <SideMenuItem
        {...{
          title: routes.competencyManagementOverview.title || "",
          path: routes.competencyManagementOverview.path,
          key: routes.competencyManagementOverview.path,
        }}
      />
      <SideMenuItem
        {...{
          title: routes.myCompetencyManagementResources.title || "",
          path: routes.myCompetencyManagementResources.path,
          key: routes.myCompetencyManagementResources.path,
        }}
      />
      {(openTrainingId || openAssessmentId || openTrainingCustomId) && (
        <SideMenuItem
          {...{
            title: routes.myOpenCompetencyManagementResource.title || "",
            path: routes.myOpenCompetencyManagementResource.path,
            key: routes.myOpenCompetencyManagementResource.path,
          }}
        />
      )}
    </SideMenuSection>
  );
};

export default React.memo(CompetencyMenuSection);
