import React from "react";
import { EntityValidationResultContextInterface } from "module/entity/context/validationResult/EntityValidationResultContext";

export type AssessmentObjectiveValidationResultContextInterface =
  EntityValidationResultContextInterface;

const AssessmentObjectiveValidationResultContext = React.createContext(
  <AssessmentObjectiveValidationResultContextInterface>{}
);

export default AssessmentObjectiveValidationResultContext;
