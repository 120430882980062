import axios from "axios";
import { store } from "redux/store";
import GitInfo from "react-git-info/macro";

axios.interceptors.request.use((config) => ({
  ...config,
  baseURL: process.env.REACT_APP_API_PATH,
  headers: {
    ...config.headers,
    "Content-Type": "application/json",
    Authorization: store.getState().auth.apiToken
      ? `Bearer ${store.getState().auth.apiToken}`
      : null,
    "X-System": store.getState().auth.system,
    "X-DeviceIdentifier": store.getState().device.identifier,
    "X-MobileAppVersion": GitInfo().commit.hash,
  },
}));
