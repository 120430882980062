import React from "react";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import makeStyles from "@mui/styles/makeStyles";
import t from "module/translations";

const useStyles = makeStyles({
  hasChanged: {
    minWidth: "250px",
  },
});

const InventoryCheckArticleListTableHead: React.FC = () => {
  const classes = useStyles();
  return (
    <TableHead>
      <TableRow>
        <TableCell align="left">{t.inventoryCheck.articlesText}</TableCell>
        <TableCell align="left">{t.inventoryCheck.amountText}</TableCell>
        <TableCell align="left" className={classes.hasChanged}>
          {t.inventoryCheck.amountCorrectText}
        </TableCell>
      </TableRow>
    </TableHead>
  );
};

export default React.memo(InventoryCheckArticleListTableHead);
