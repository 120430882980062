import { ValidationError } from "api/block/types";

export const mapValidationErrorGeneralMessagesToString = (
  validationError: ValidationError
): string[] => {
  let result: string[] = [];

  // eslint-disable-next-line guard-for-in,no-restricted-syntax
  for (const type in validationError) {
    // eslint-disable-next-line guard-for-in,no-restricted-syntax
    for (const id in validationError[type]) {
      // eslint-disable-next-line no-underscore-dangle
      result = [...result, ...validationError[type][id].__general__];
    }
  }

  return result;
};
