import React from "react";
import TableCell from "@mui/material/TableCell";
import t from "module/translations";
import TableHeadRow from "component/table/tableHeadRow/TableHeadRow";
import TableToggleCell from "component/table/toggleCell/TableToggleCell";
import TableEmptyToggleCell from "component/table/toggleCell/TableEmptyToggleCell";
import TableHead from "@mui/material/TableHead";

interface Props {
  isAnySelected: boolean;
  areAllSelected: boolean;
  toggleAll: (checked: boolean) => void;
  canAnyBeSelected: boolean;
}

const MyCompetencyManagementListTableHead: React.FC<Props> = ({
  isAnySelected,
  areAllSelected,
  toggleAll,
  canAnyBeSelected,
}) => {
  return (
    <TableHead>
      <TableHeadRow>
        {canAnyBeSelected && (
          <TableToggleCell
            {...{
              isSelected: areAllSelected,
              indeterminate: isAnySelected,
              toggle: toggleAll,
            }}
          />
        )}
        {!canAnyBeSelected && <TableEmptyToggleCell />}
        <TableCell align="left">
          {t.competencyManagement.reportingNumber}
        </TableCell>
        <TableCell align="left">{t.competencyManagement.nameText}</TableCell>
        <TableCell>{t.competencyManagement.assigneeText}</TableCell>
        <TableCell align="left">{t.competencyManagement.trainerText}</TableCell>
        <TableCell align="left">{t.competencyManagement.typeText}</TableCell>
        <TableCell>{t.competencyManagement.scheduledDateText}</TableCell>
        <TableCell>{t.competencyManagement.stateText}</TableCell>
        <TableCell />
      </TableHeadRow>
    </TableHead>
  );
};

export default React.memo(MyCompetencyManagementListTableHead);
