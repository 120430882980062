import React from "react";
import Typography from "@mui/material/Typography";
import makeStyles from "@mui/styles/makeStyles";
import FormControl from "@mui/material/FormControl";
import Caption from "component/caption/Caption";
import XValidationErrorMessage from "component/validationErrorMessage/XValidationErrorMessage";
import XValidationWarningMessage from "component/validationWarningMessage/XValidationWarningMessage";
import nl2br from "toolkit/nl2br";

const useStyles = makeStyles(() => ({
  wrapper: {
    width: "100%",
    padding: "8px 0",
  },
}));

interface Props {
  caption?: string;
  label?: string;
  errors?: string[];
  warnings?: string[];
  children?: React.ReactNode;
}

const XLabelField: React.FC<Props> = ({
  caption,
  label,
  errors,
  warnings,
  children,
}) => {
  const classes = useStyles();
  const hasError = errors != null && Boolean(errors.length);

  const labelDefault = children == null ? "-" : "";

  return (
    <FormControl
      error={hasError}
      className={classes.wrapper}
      variant="standard"
    >
      {caption && <Caption {...{ text: caption }} />}
      <Typography variant="body2" gutterBottom>
        {label && label.length ? (
          // eslint-disable-next-line react/no-danger
          <div dangerouslySetInnerHTML={{ __html: nl2br(label) }} />
        ) : (
          labelDefault
        )}
      </Typography>
      {children}
      {errors && <XValidationErrorMessage {...{ errors }} />}
      {warnings && <XValidationWarningMessage {...{ warnings }} />}
    </FormControl>
  );
};

export default React.memo(XLabelField);
