import React from "react";
import { ValidatorInterface } from "module/validation/types";
import FormHelperText from "@mui/material/FormHelperText";
import ValidationMessage from "module/validation/ValidationMessage";

interface Props {
  warnings: ValidatorInterface[];
}

const ValidationWarningMessage: React.FC<Props> = ({ warnings }) => {
  const hasWarning = Boolean(warnings.length);

  if (hasWarning) {
    return (
      <FormHelperText>
        <ValidationMessage validators={warnings} />
      </FormHelperText>
    );
  }
  return null;
};

export default React.memo(ValidationWarningMessage);
