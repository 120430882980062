import React from "react";
import { EntityFieldMetaDataContextInterface } from "module/entity/context/fieldMetaData/EntityFieldMetaDataContext";

export type TrainingSessionFieldMetaDataContextInterface =
  EntityFieldMetaDataContextInterface;

const TrainingSessionFieldMetaDataContext = React.createContext(
  <TrainingSessionFieldMetaDataContextInterface>{}
);

export default TrainingSessionFieldMetaDataContext;
