import { IsBlockedInterface } from "module/block/types";
import {
  ApiTrainingInterface,
  ApiTrainingSessionInterface,
} from "api/competencyManagement/types";
import {
  AbstractMyEntityInterface,
  EntityChangedDate,
  EntityIdentifier,
  EntityType,
  EntityWithId,
  EntityWithRelationships,
} from "module/entity/types";

export interface TrainingIsBlockedInterface extends IsBlockedInterface {
  isBlockedBecauseItIsCompleted: boolean;
  isBlockedBecauseUserHasNoPermissions: boolean;
}

export interface TrainingSessionInterface
  extends EntityWithId,
    EntityWithRelationships,
    EntityChangedDate {
  trainingId: string;
  name: string;
  remarks: string;
  completeDate?: string;
  achieved: boolean;
  isCategory: boolean;
  level: number;
  sorting: number;
}

export type MyTrainingSessionInterface = AbstractMyEntityInterface<
  ApiTrainingSessionInterface,
  TrainingSessionInterface
>;

export enum TrainingType {
  initial = "initial",
  refresher = "refresher",
}

export enum TrainingState {
  initScheduled = "init_sched",
  scheduled = "scheduled",
  completed = "completed",
}

export interface TrainingInterface
  extends EntityWithId,
    EntityWithRelationships,
    EntityChangedDate {
  name: string;
  reportingNumber?: string;
  remarks: string;
  assigneeId?: number;
  internalTrainerId?: number;
  externalTrainer?: string;
  type: TrainingType;
  scheduledDate: string;
  scheduledStartDate: string;
  scheduledEndDate: string;
  state: TrainingState;
  moduleText: string;
  expectedDate: string;
  completionStartDate?: string;
  completionEndDate?: string;
  isBlockedDetails: TrainingIsBlockedInterface;
  sessions: EntityIdentifier[];
}

export type MyTrainingInterface = AbstractMyEntityInterface<
  ApiTrainingInterface,
  TrainingInterface
>;

export enum TrainingSyncUnavailableReason {
  noPermissions = "noPermissions",
  offline = "offline",
  notValid = "notValid",
  isOpen = "isOpen",
  hasNotChanged = "hasNotChanged",
}

export interface TrainingSessionToSyncInterface {
  identifier: {
    type: EntityType.trainingSession;
    id?: number;
    frontendId?: string;
  };
  data: {
    id?: number;
    achieved: boolean;
    remarks?: string;
    changedDateTime?: string;
  };
  raw?: ApiTrainingSessionInterface;
}

export interface TrainingToSyncInterface {
  identifier: {
    type: EntityType.training;
    id: number | null;
    frontendId?: string;
  };
  data: {
    id?: number;
    remarks?: string;
    state: TrainingState;
    completionStartDate?: string;
    completionEndDate?: string;
    changedDateTime?: string;
    sessions: TrainingSessionToSyncInterface[];
    internalTrainerId?: number;
    externalTrainer?: string;
  };
  raw?: ApiTrainingInterface;
}
