import React, { useContext } from "react";
import MyTrainingCustomDetails from "pages/myTrainingCustomDetails/MyTrainingCustomDetails";
import TrainingCustomContext from "module/trainingCustom/context/TrainingCustomContext";
import TrainingCustomValidationResultContext from "module/trainingCustom/validation/TrainingCustomValidationResultContext";
import TrainingCustomOpenCloseContext from "module/trainingCustom/openClose/context/TrainingCustomOpenCloseContext";
import TrainingCustomStateContext from "module/trainingCustom/state/context/TrainingCustomStateContext";

const MyTrainingCustomDetailsContainer: React.FC = () => {
  const { entity } = useContext(TrainingCustomContext);
  const { hasError } = useContext(TrainingCustomValidationResultContext);
  const { open, close } = useContext(TrainingCustomOpenCloseContext);
  const { isStateEditable } = useContext(TrainingCustomStateContext);

  return (
    <MyTrainingCustomDetails
      {...{
        trainingCustomId: entity.id,
        hasError,
        open,
        close,
        isStateEditable,
      }}
    />
  );
};

export default React.memo(MyTrainingCustomDetailsContainer);
