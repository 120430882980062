import React from "react";
import LabelField from "component/labelField/LabelField";
import t from "module/translations";

interface Props {
  completionStartDate?: string;
  formatDateTime: (date: string) => string;
}

const AssessmentCompletionStartDateTimeLabelField: React.FC<Props> = ({
  completionStartDate,
  formatDateTime,
}) => {
  return (
    <LabelField
      {...{
        caption: t.competencyManagement.assessment.completionStartDateTimeText,
        label: completionStartDate ? formatDateTime(completionStartDate) : "",
      }}
    />
  );
};

export default React.memo(AssessmentCompletionStartDateTimeLabelField);
