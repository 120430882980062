import t from "module/translations";
import { useAppSelector } from "redux/hooks";
import { selectStaffById as selectStaffByIdHelper } from "module/auth/redux/authSlice";
import { TrainingCustomInterface } from "module/trainingCustom/types";

const useGetTrainingCustomBlockedReasonText = (
  trainingCustom: TrainingCustomInterface
): string | null => {
  const {
    isBlockedDetails: {
      blockedByStaffId,
      isBlockedByOtherStaff,
      isBlockedByMeOnOtherDevice,
      isBlockedByMeOnThisDevice,
      isBlockedBecauseItIsCompleted,
    },
  } = trainingCustom;

  const selectStaffById = useAppSelector(selectStaffByIdHelper);

  if (isBlockedByMeOnThisDevice) {
    return t.competencyManagement.training.blocked.byMeOnThisDeviceText;
  }
  if (isBlockedByMeOnOtherDevice) {
    return t.competencyManagement.training.blocked.byMeOnOtherDeviceText;
  }
  if (isBlockedByOtherStaff && blockedByStaffId) {
    const { name: staffName } = selectStaffById(blockedByStaffId);
    return t.competencyManagement.training.blocked.byOtherStaffText(staffName);
  }
  if (isBlockedBecauseItIsCompleted) {
    return t.competencyManagement.training.blocked
      .isBlockedBecauseItIsCompletedText;
  }

  return null;
};

export default useGetTrainingCustomBlockedReasonText;
