import React, { useContext, useMemo } from "react";
import TableCell from "@mui/material/TableCell";
import Checkbox from "@mui/material/Checkbox";
import ToggleContext from "module/toggle/context/ToggleContext";
import InfoIconWithReason from "module/block/InfoIconWithReason";
import { MaintenanceTaskInterface } from "module/maintenanceTask/types";
import { EntityType } from "module/entity/types";
import useGetMaintenanceTaskBlockedReasonText from "module/maintenanceTask/block/useGetMaintenanceTaskBlockedReasonText";
import MaintenanceTaskContext from "module/maintenanceTask/context/MaintenanceTaskContext";

const MaintenanceTaskListTableRowSelectTableCellContainer: React.FC = () => {
  const { entity } = useContext(MaintenanceTaskContext);
  const { isSelected: isSelectedHelper } = useContext(ToggleContext);

  const maintenanceTask = entity as MaintenanceTaskInterface;

  const isBlockedReasonText =
    useGetMaintenanceTaskBlockedReasonText(maintenanceTask);

  const isSelected = useMemo(
    () =>
      isSelectedHelper({
        id: maintenanceTask.id,
        type: EntityType.maintenanceTask,
      }),
    [isSelectedHelper, maintenanceTask.id]
  );

  return (
    <TableCell padding="checkbox">
      {isBlockedReasonText ? (
        <InfoIconWithReason {...{ reason: isBlockedReasonText as string }} />
      ) : (
        <Checkbox checked={isSelected} />
      )}
    </TableCell>
  );
};

export default MaintenanceTaskListTableRowSelectTableCellContainer;
