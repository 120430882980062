import { MyCompetencyManagementInterface } from "module/competencyManagement/types";

const sortMyCompetencyManagementItemsByScheduledDate = (
  el1: MyCompetencyManagementInterface,
  el2: MyCompetencyManagementInterface
) => {
  const el1scheduledStartDate = el1.data.scheduledStartDate;
  const el2scheduledStartDate = el2.data.scheduledStartDate;

  if (el1scheduledStartDate < el2scheduledStartDate) {
    return -1;
  }
  if (el1scheduledStartDate > el2scheduledStartDate) {
    return 1;
  }

  return 0;
};

export const sortCompetencyManagementItems = (
  myEntities: MyCompetencyManagementInterface[]
): MyCompetencyManagementInterface[] => {
  return [...myEntities].sort(sortMyCompetencyManagementItemsByScheduledDate);
};
