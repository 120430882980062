import React from "react";
import { EntityChangeContextInterface } from "module/entity/context/change/EntityChangeContext";

export type AssessmentObjectiveChangeContextInterface =
  EntityChangeContextInterface;

const AssessmentObjectiveChangeContext = React.createContext(
  <AssessmentObjectiveChangeContextInterface>{}
);

export default AssessmentObjectiveChangeContext;
