import React from "react";
import { EntityIdentifier } from "module/entity/types";
import MyEntityContextProvider from "module/entity/context/entity/MyEntityContextProvider";
import MyEntityValidationResultContextProvider from "module/entity/context/validationResult/MyEntityValidationResultContextProvider";
import MyEntityChangeContextProvider from "module/entity/context/change/MyEntityChangeContextProvider";
import MaintenanceTaskContext from "module/maintenanceTask/context/MaintenanceTaskContext";
import MaintenanceTaskValidationResultContext from "module/maintenanceTask/validation/MaintenanceTaskValidationResultContext";
import MyMaintenanceTaskOpenCloseContextProvider from "module/maintenanceTask/openClose/context/MyMaintenanceTaskOpenCloseContextProvider";
import MyMaintenanceTaskStateContextProvider from "module/maintenanceTask/state/context/MyMaintenanceTaskStateContextProvider";
import MyMaintenanceTaskFieldMetaDataContextProvider from "module/maintenanceTask/validation/fieldMetaData/MyMaintenanceTaskFieldMetaDataContextProvider";
import MaintenanceTaskChangeContext from "module/maintenanceTask/change/MaintenanceTaskChangeContext";

interface Props {
  entityIdentifier: EntityIdentifier;
  children: React.ReactNode;
}

const MyMaintenanceTaskDetailsContextProvider: React.FC<Props> = ({
  entityIdentifier,
  children,
}) => {
  return (
    <MyEntityContextProvider
      {...{ entityIdentifier, context: MaintenanceTaskContext }}
    >
      <MyEntityValidationResultContextProvider
        {...{
          entityIdentifier,
          context: MaintenanceTaskValidationResultContext,
        }}
      >
        <MyMaintenanceTaskOpenCloseContextProvider>
          <MyMaintenanceTaskStateContextProvider>
            <MyMaintenanceTaskFieldMetaDataContextProvider>
              <MyEntityChangeContextProvider
                {...{ entityIdentifier, context: MaintenanceTaskChangeContext }}
              >
                {children}
              </MyEntityChangeContextProvider>
            </MyMaintenanceTaskFieldMetaDataContextProvider>
          </MyMaintenanceTaskStateContextProvider>
        </MyMaintenanceTaskOpenCloseContextProvider>
      </MyEntityValidationResultContextProvider>
    </MyEntityContextProvider>
  );
};

export default React.memo(MyMaintenanceTaskDetailsContextProvider);
