import { EntityType } from "module/entity/types";
import { InventoryCheckArticleInterface } from "module/inventoryCheckArticle/types";
import { mapInventoryCheckArticleToSync } from "module/inventoryCheckArticle/helper/mapInventoryCheckArticleToSync";
import {
  InventoryCheckToSyncInterface,
  MyInventoryCheckInterface,
} from "module/inventoryCheck/types";

export const mapInventoryCheckToSync = (
  myInventoryCheck: MyInventoryCheckInterface,
  inventoryCheckArticles: InventoryCheckArticleInterface[]
): InventoryCheckToSyncInterface => {
  const { data: inventoryCheck, raw } = myInventoryCheck;

  return {
    identifier: {
      type: myInventoryCheck.entityIdentifier.type as EntityType.inventoryCheck,
      id: myInventoryCheck.entityIdentifier.backendId as number,
      frontendId: myInventoryCheck.entityIdentifier.id,
    },
    data: {
      id: inventoryCheck.backendId as number,
      remarks: inventoryCheck.remarks,
      state: inventoryCheck.state,
      changedDateTime: inventoryCheck.changedDateTime,
      inventoryCheckArticles: inventoryCheckArticles.map(
        (inventoryCheckArticle) =>
          mapInventoryCheckArticleToSync(inventoryCheckArticle)
      ),
      assigneeId: inventoryCheck.assigneeId,
    },
    raw,
  };
};
