import React, { useContext } from "react";
import { TrainingSessionInterface } from "module/training/types";
import TrainingSessionContext from "module/trainingSession/context/TrainingSessionContext";
import TrainingSessionTableRowCategory from "module/trainingSession/enrichedComponent/tableRow/category/TrainingSessionTableRowCategory";

const TrainingSessionTableRowCategoryContainer: React.FC = () => {
  const { entity } = useContext(TrainingSessionContext);

  const trainingSession = entity as TrainingSessionInterface;
  const { name, level } = trainingSession;

  return <TrainingSessionTableRowCategory {...{ name, level }} />;
};

export default React.memo(TrainingSessionTableRowCategoryContainer);
