import { IsBlockedInterface } from "module/block/types";
import { ApiInventoryCheckInterface } from "api/inventoryCheck/types";
import { InventoryCheckArticleToSyncInterface } from "module/inventoryCheckArticle/types";
import {
  AbstractMyEntityInterface,
  EntityChangedDate,
  EntityIdentifier,
  EntityType,
  EntityWithId,
  EntityWithRelationships,
} from "module/entity/types";

export enum InventoryCheckState {
  scheduled = "scheduled",
  completed = "completed",
  validated = "validated",
}

export interface InventoryCheckIsBlockedInterface extends IsBlockedInterface {
  isBlockedBecauseItIsCompleted: boolean;
  isBlockedBecauseItIsValidated: boolean;
}

export interface InventoryCheckInterface
  extends EntityWithId,
    EntityWithRelationships,
    EntityChangedDate {
  reportingNumber: string;
  subject: string;
  remarks?: string;
  state: InventoryCheckState;
  isBlockedDetails: InventoryCheckIsBlockedInterface;
  assigneeId?: number;
  inventoryCheckArticles: EntityIdentifier[];
  createdAt: string;
}

export type MyInventoryCheckInterface = AbstractMyEntityInterface<
  ApiInventoryCheckInterface,
  InventoryCheckInterface
>;

export enum InventoryCheckSyncUnavailableReason {
  noPermissions = "noPermissions",
  offline = "offline",
  notValid = "notValid",
  isOpen = "isOpen",
  hasNotChanged = "hasNotChanged",
  stateIsNotSupported = "stateIsNotSupported",
}

export interface InventoryCheckToSyncInterface {
  identifier: {
    type: EntityType.inventoryCheck;
    id: number | null;
    frontendId: string;
  };
  data: {
    id: number;
    remarks?: string;
    state: InventoryCheckState;
    inventoryCheckArticles: InventoryCheckArticleToSyncInterface[];
    changedDateTime?: string;
    assigneeId?: number;
  };
  raw?: ApiInventoryCheckInterface;
}
