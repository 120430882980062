import React, { useContext } from "react";
import MyMaintenanceTaskDetailsAssistant from "pages/myMaintenanceTaskDetails/personnelAssignment/assistant/MyMaintenanceTaskDetailsAssistant";
import useMaintenanceTaskAssistantAdd from "module/maintenanceTaskAssisstant/add/useMaintenanceTaskAssistantAdd";
import MaintenanceTaskContext from "module/maintenanceTask/context/MaintenanceTaskContext";
import { MaintenanceTaskInterface } from "module/maintenanceTask/types";

const MyMaintenanceTaskDetailsAssistantContainer: React.FC = () => {
  const { entity } = useContext(MaintenanceTaskContext);
  const maintenanceTask = entity as MaintenanceTaskInterface;

  const isSet = maintenanceTask.assistantType != null;

  const { add, canAdd } = useMaintenanceTaskAssistantAdd();

  return (
    <MyMaintenanceTaskDetailsAssistant
      {...{
        add,
        canAdd,
        isSet,
      }}
    />
  );
};

export default React.memo(MyMaintenanceTaskDetailsAssistantContainer);
