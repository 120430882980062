import React, { useContext } from "react";
import AssessmentContext from "module/assessment/context/AssessmentContext";
import { AssessmentInterface } from "module/assessment/types";
import AssessmentStateLabelField from "module/assessment/enrichedComponent/stateField/AssessmentStateLabelField";

const AssessmentStateLabelFieldContainer: React.FC = () => {
  const { entity } = useContext(AssessmentContext);
  const { state } = entity as AssessmentInterface;

  return <AssessmentStateLabelField {...{ state }} />;
};

export default React.memo(AssessmentStateLabelFieldContainer);
