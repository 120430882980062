import React from "react";
import { EntityValidationResultContextInterface } from "module/entity/context/validationResult/EntityValidationResultContext";

export type AssessmentValidationResultContextInterface =
  EntityValidationResultContextInterface;

const AssessmentValidationResultContext = React.createContext(
  <AssessmentValidationResultContextInterface>{}
);

export default AssessmentValidationResultContext;
