import { useContext, useCallback } from "react";
import usePermissions from "module/permission/usePermissions";
import { useAppSelector } from "redux/hooks";
import _ from "lodash";
import OnlineDetectorContext from "module/onlineDetector/OnlineDetectorContext";
import selectOpenItemByEntityType from "module/staff/redux/selector/selectOpenItemByEntityType";
import { EntityIdentifier, EntityType } from "module/entity/types";
import selectHasEntityOrRelatedEntityError from "module/staff/redux/selector/selectHasEntityOrRelatedEntityError";
import { IsEntitySyncAvailableResult } from "module/entity/useEntityAbstractFactory";
import selectEntity from "module/staff/redux/selector/selectEntity";
import { InventoryCheckInterface } from "module/inventoryCheck/types";
import isInventoryCheckSyncAvailable from "./isInventoryCheckSyncAvailable";

interface Result {
  isInventoryCheckSyncAvailable: (id: string) => IsEntitySyncAvailableResult;
}

const useInventoryCheckIsSyncAvailable = (): Result => {
  const { canEditInventoryCheck } = usePermissions();
  const { isOnline } = useContext(OnlineDetectorContext);
  const openedInventoryCheckId = useAppSelector(
    selectOpenItemByEntityType(EntityType.inventoryCheck)
  )?.id;
  const selectInventoryCheck = useAppSelector(selectEntity);
  const selectHasError = useAppSelector(selectHasEntityOrRelatedEntityError);

  const hasPermissions = canEditInventoryCheck;

  const isInventoryCheckSyncAvailableHelper = useCallback(
    (id: string): IsEntitySyncAvailableResult => {
      const entityIdentifier: EntityIdentifier = {
        id,
        type: EntityType.inventoryCheck,
      };
      const myEntity = selectInventoryCheck(entityIdentifier);
      if (myEntity == null) {
        return {
          isAvailable: false,
          unavailableReason: "",
        };
      }
      const { data, dataWithoutChanges } = myEntity;

      const isValid = !selectHasError(entityIdentifier);
      const hasChanged = !_.isEqual(data, dataWithoutChanges);

      return isInventoryCheckSyncAvailable({
        inventoryCheck: myEntity.data as InventoryCheckInterface,
        isOnline,
        hasPermissions,
        isOpen: myEntity && entityIdentifier.id === openedInventoryCheckId,
        isValid,
        hasChanged,
      });
    },
    [
      hasPermissions,
      isOnline,
      openedInventoryCheckId,
      selectHasError,
      selectInventoryCheck,
    ]
  );

  return {
    isInventoryCheckSyncAvailable: isInventoryCheckSyncAvailableHelper,
  };
};

export default useInventoryCheckIsSyncAvailable;
