import React, { useContext } from "react";
import AssessmentContext from "module/assessment/context/AssessmentContext";
import { AssessmentInterface } from "module/assessment/types";
import AssessmentExpectedDateLabelField from "module/assessment/enrichedComponent/expectedDateField/AssessmentExpectedDateLabelField";
import useDateFormat from "toolkit/useDateFormat";

const AssessmentExpectedDateFieldContainer: React.FC = () => {
  const { entity } = useContext(AssessmentContext);
  const { formatDate } = useDateFormat();

  const { expectedDate } = entity as AssessmentInterface;

  return <AssessmentExpectedDateLabelField {...{ expectedDate, formatDate }} />;
};

export default React.memo(AssessmentExpectedDateFieldContainer);
