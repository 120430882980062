import React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import { EntityIdentifier } from "module/entity/types";
import { getEntityIdentifierKey } from "module/entity/helper/getEntityIdentifierKey";
import { useDownloadedMaintenanceTaskDetailsPersonnelAssignmentsExternalStyles } from "module/maintenanceTaskPersonnelAssignment/externalStaff/enrichedComponent/table/styles";
import MaintenanceTaskDetailsPersonnelAssignmentExternalStaffListTableHead from "module/maintenanceTaskPersonnelAssignment/externalStaff/enrichedComponent/table/MaintenanceTaskDetailsPersonnelAssignmentExternalStaffListTableHead";
import MaintenanceTaskPersonnelAssignmentExternalStaffContextProvider from "module/maintenanceTaskPersonnelAssignment/externalStaff/context/MaintenanceTaskPersonnelAssignmentExternalStaffContextProvider";
import MaintenanceTaskDetailsPersonnelAssignmentExternalStaffListTableRow from "module/maintenanceTaskPersonnelAssignment/externalStaff/enrichedComponent/table/MaintenanceTaskDetailsPersonnelAssignmentExternalStaffListTableRow";

interface Props {
  entityIdentifiers: EntityIdentifier[];
}

const MaintenanceTaskToBeSignedDetailsPersonnelAssignmentsExternalStaff: React.FC<Props> =
  ({ entityIdentifiers }) => {
    const classes =
      useDownloadedMaintenanceTaskDetailsPersonnelAssignmentsExternalStyles();
    const isActionAvailable = false;
    return (
      <Table className={classes.table}>
        <MaintenanceTaskDetailsPersonnelAssignmentExternalStaffListTableHead
          {...{ isActionAvailable }}
        />
        <TableBody>
          {entityIdentifiers.map((entityIdentifier) => (
            <MaintenanceTaskPersonnelAssignmentExternalStaffContextProvider
              {...{
                key: getEntityIdentifierKey(entityIdentifier),
                entityIdentifier,
              }}
            >
              <MaintenanceTaskDetailsPersonnelAssignmentExternalStaffListTableRow
                {...{ isActionAvailable }}
              />
            </MaintenanceTaskPersonnelAssignmentExternalStaffContextProvider>
          ))}
        </TableBody>
      </Table>
    );
  };

export default React.memo(
  MaintenanceTaskToBeSignedDetailsPersonnelAssignmentsExternalStaff
);
