import React, { useCallback, useEffect, useState, useMemo } from "react";
import { useHistory, useParams, useRouteMatch } from "react-router-dom";
import routes from "routes/routes";
import { useAppSelector } from "redux/hooks";
import { selectUser } from "module/auth/redux/authSlice";
import { toastWarning } from "layout/toast/helper";
import t from "module/translations";
import EntitySignContextProvider from "module/entity/context/sign/EntitySignContextProvider";
import { EntityIdentifier, EntityType } from "module/entity/types";
import {
  TrainingCustomAssigneeInterface,
  TrainingCustomInterface,
} from "module/trainingCustom/types";
import getTrainingCustomDetails from "api/competencyManagement/getTrainingCustomDetails";
import { canSignTrainingCustom } from "module/trainingCustom/helper/canSignTrainingCustom";
import { getPathToTrainingCustomToBeSignedDetailsOverview } from "module/trainingCustom/helper/getPath";
import TrainingCustomSignContext from "module/trainingCustom/sign/TrainingCustomSignContext";
import TrainingCustomContext from "module/trainingCustom/context/TrainingCustomContext";
import TrainingCustomToBeSignedDetailsContainer from "pages/trainingCustomToBeSignedDetails/TrainingCustomToBeSignedDetailsContainer";

const TrainingCustomToBeSignedDetailsPage: React.FC = () => {
  const { trainingCustomId: id } = useParams<{ trainingCustomId: string }>();
  const [trainingCustom, setTrainingCustom] =
    useState<TrainingCustomInterface | null>(null);
  const history = useHistory();
  const match = useRouteMatch({
    path: routes.trainingCustomToBeSignedDetails.path,
    exact: true,
  });
  const loggedInStaffId = useAppSelector(selectUser).id;

  useEffect(() => {
    if (id && match) {
      history.push(getPathToTrainingCustomToBeSignedDetailsOverview(id));
    }
  }, [history, id, match]);

  const load = useCallback(async () => {
    const myTraining = await getTrainingCustomDetails(Number(id));
    setTrainingCustom(myTraining.data);
  }, [id]);

  useEffect(() => {
    const assignees: TrainingCustomAssigneeInterface[] =
      trainingCustom?.relationships != null
        ? Array.from(trainingCustom.relationships.values())
            .filter(
              (relationship) =>
                relationship.entityIdentifier.type ===
                EntityType.trainingCustomAssignee
            )
            .map(
              (myAssignee) => myAssignee.data as TrainingCustomAssigneeInterface
            )
        : [];
    if (
      trainingCustom != null &&
      !canSignTrainingCustom({ trainingCustom, assignees, loggedInStaffId })
    ) {
      toastWarning(t.toBeSigned.itemNoAbleToSignText);
      history.push(routes.toBeSigned.path);
    }
  }, [trainingCustom, history, loggedInStaffId]);

  useEffect(() => {
    load();
  }, [load]);

  const entityIdentifier: EntityIdentifier = useMemo(
    () => ({
      id,
      type: EntityType.trainingCustom,
      backendId: Number(id),
    }),
    [id]
  );

  if (trainingCustom == null) {
    return null;
  }

  return (
    <TrainingCustomContext.Provider value={{ entity: trainingCustom }}>
      <EntitySignContextProvider
        {...{ entityIdentifier, context: TrainingCustomSignContext }}
      >
        <TrainingCustomToBeSignedDetailsContainer />
      </EntitySignContextProvider>
    </TrainingCustomContext.Provider>
  );
};

export default React.memo(TrainingCustomToBeSignedDetailsPage);
