import React from "react";
import LabelField from "component/labelField/LabelField";
import t from "module/translations";
import StaffNameText from "module/staff/StaffNameText";
import Typography from "@mui/material/Typography";

interface Props {
  assigneeId?: number;
}

const TrainingAssigneeLabelField: React.FC<Props> = ({ assigneeId }) => {
  return (
    <LabelField
      {...{
        caption: t.competencyManagement.training.assigneeText,
      }}
    >
      <Typography variant="body2" gutterBottom>
        {assigneeId && <StaffNameText {...{ staffId: assigneeId }} />}
        {!assigneeId && "-"}
      </Typography>
    </LabelField>
  );
};

export default React.memo(TrainingAssigneeLabelField);
