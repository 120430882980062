import React from "react";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import t from "module/translations";
import useDateFormat from "toolkit/useDateFormat";
import { TrainingCustomInterface } from "module/trainingCustom/types";
import { getPathToTrainingCustomToBeSignedDetails } from "module/trainingCustom/helper/getPath";
import LinkWithIcon from "component/link/LinkWithIcon";
import TrainingCustomAssigneesLabelContainer from "module/trainingCustom/enrichedComponent/assigneesField/TrainingCustomAssigneesLabelContainer";

interface Props {
  trainingCustom: TrainingCustomInterface;
}

const ToBeSignedListTableRowTrainingCustom: React.FC<Props> = ({
  trainingCustom,
}) => {
  const { formatDate } = useDateFormat();

  return (
    <TableRow
      {...{
        hover: true,
        tabIndex: -1,
        key: trainingCustom.id,
      }}
    >
      <TableCell>{t.competencyManagement.trainingCustomText}</TableCell>
      <TableCell>
        <LinkWithIcon
          {...{
            label: trainingCustom.name,
            path: getPathToTrainingCustomToBeSignedDetails(trainingCustom.id),
          }}
        />
        <TrainingCustomAssigneesLabelContainer />
      </TableCell>

      <TableCell>{t.competencyManagement.trainingCustom.typeText}</TableCell>
      <TableCell>{formatDate(trainingCustom.scheduledDate)}</TableCell>
    </TableRow>
  );
};

export default React.memo(ToBeSignedListTableRowTrainingCustom);
