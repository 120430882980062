import React from "react";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import t from "module/translations";

interface Props {
  isActionAvailable: boolean;
}

const MaintenanceTaskDetailsPersonnelAssignmentExternalStaffListTableHead: React.FC<Props> =
  ({ isActionAvailable }) => {
    return (
      <TableHead>
        <TableRow>
          <TableCell>
            {t.maintenanceTask.personnelAssignments.nameText}
          </TableCell>
          <TableCell>
            {t.maintenanceTask.personnelAssignments.companyText}
          </TableCell>
          <TableCell>
            {t.maintenanceTask.personnelAssignments.responsibilityText}
          </TableCell>
          {isActionAvailable && <TableCell />}
        </TableRow>
      </TableHead>
    );
  };

export default React.memo(
  MaintenanceTaskDetailsPersonnelAssignmentExternalStaffListTableHead
);
