import React, { useContext, useCallback } from "react";
import t from "module/translations";
import { INVENTORY_CHECK_REMARKS_FIELD_NAME } from "module/inventoryCheck/constants";
import { getFieldMetaDataWithErrorHandling } from "module/fieldMetaData/getFieldMetaData";
import TextareaField from "component/textareaField/TextareaField";
import XLabelField from "component/labelField/XLabelField";
import InventoryCheckContext from "module/inventoryCheck/context/InventoryCheckContext";
import InventoryCheckChangeContext from "module/inventoryCheck/change/InventoryCheckChangeContext";
import InventoryCheckFieldMetaDataContext from "module/inventoryCheck/validation/fieldMetaData/InventoryCheckFieldMetaDataContext";
import { InventoryCheckInterface } from "module/inventoryCheck/types";

const InventoryCheckRemarksFieldContainer: React.FC = () => {
  const { entity } = useContext(InventoryCheckContext);
  const { change: changeHelper } = useContext(InventoryCheckChangeContext);
  const { getFieldMetaData } = useContext(InventoryCheckFieldMetaDataContext);

  const inventoryCheck = entity as InventoryCheckInterface;

  const change = useCallback(
    (remarks?: string) => {
      changeHelper({ remarks });
    },
    [changeHelper]
  );

  const { warnings, errors, isEditable } = getFieldMetaDataWithErrorHandling(
    () => getFieldMetaData(INVENTORY_CHECK_REMARKS_FIELD_NAME)
  );

  const label = t.inventoryCheck.remarksText;

  return isEditable ? (
    <TextareaField
      {...{
        value: inventoryCheck.remarks,
        change,
        label,
        errors,
      }}
    />
  ) : (
    <XLabelField
      {...{ caption: label, label: inventoryCheck.remarks, errors, warnings }}
    />
  );
};

export default React.memo(InventoryCheckRemarksFieldContainer);
