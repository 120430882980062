import { PayloadAction } from "@reduxjs/toolkit";
import { StaffDataState } from "module/staff/redux/types";
import * as Sentry from "@sentry/react";

const initializeAction = (
  state: StaffDataState,
  action: PayloadAction<StaffDataState>
): void => {
  Sentry.withScope((scope) => {
    scope.setLevel("info");
    scope.setExtra("state", JSON.stringify(state));
    let shouldCapture = false;
    // eslint-disable-next-line array-callback-return
    Object.keys(action.payload).map((staffIdString) => {
      const staffId = Number(staffIdString);
      const data = Object.keys(action.payload[staffId]).reduce(
        (result, systemShortName) => {
          if (systemShortName === "OAC") {
            shouldCapture = true;
          }
          return {
            ...result,
            [systemShortName]: Object.keys(
              action.payload[staffId][systemShortName].entities
            ),
          };
        },
        {}
      );
      scope.setExtra(`staffIdData - ${staffId}`, data);
      if (shouldCapture) {
        Sentry.captureMessage(`initializeStore`);
      }
    });
  });

  // eslint-disable-next-line array-callback-return
  Object.keys(action.payload).map((staffIdString) => {
    const staffId = Number(staffIdString);
    state[staffId] = action.payload[staffId];
  });
};

export default initializeAction;
