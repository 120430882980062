import React from "react";
import { EntityContextInterface } from "module/entity/context/entity/EntityContext";

export type MaintenanceTaskContextInterface = EntityContextInterface;

const MaintenanceTaskContext = React.createContext(
  <MaintenanceTaskContextInterface>{}
);

export default MaintenanceTaskContext;
