import React, { useContext, useMemo } from "react";
import MyCompetencyManagementListTableRowAssessment from "pages/myCompetencyManagementList/table/row/assessment/MyCompetencyManagementListTableRowAssessment";
import ToggleContext from "module/toggle/context/ToggleContext";
import AssessmentContext from "module/assessment/context/AssessmentContext";
import { AssessmentInterface } from "module/assessment/types";
import { EntityType } from "module/entity/types";

const MyCompetencyManagementListTableRowAssessmentContainer: React.FC = () => {
  const { entity } = useContext(AssessmentContext);
  const { isSelected: isSelectedHelper } = useContext(ToggleContext);

  const assessment = entity as AssessmentInterface;

  const entityIdentifier = useMemo(
    () => ({ id: assessment.id, type: EntityType.assessment }),
    [assessment.id]
  );
  const isSelected = useMemo(
    () => isSelectedHelper(entityIdentifier),
    [entityIdentifier, isSelectedHelper]
  );

  return (
    <MyCompetencyManagementListTableRowAssessment
      {...{ assessment, isSelected, entityIdentifier }}
    />
  );
};

export default React.memo(
  MyCompetencyManagementListTableRowAssessmentContainer
);
