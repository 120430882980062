import React, { useContext } from "react";
import t from "module/translations";
import XLabelField from "component/labelField/XLabelField";
import TrainingCustomContext from "module/trainingCustom/context/TrainingCustomContext";
import { TrainingCustomInterface } from "module/trainingCustom/types";

const TrainingCustomDescriptionFieldContainer: React.FC = () => {
  const { entity } = useContext(TrainingCustomContext);

  const trainingCustom = entity as TrainingCustomInterface;

  const label = t.competencyManagement.trainingCustom.descriptionText;

  return (
    <XLabelField {...{ caption: label, label: trainingCustom.description }} />
  );
};

export default React.memo(TrainingCustomDescriptionFieldContainer);
