import { useCallback } from "react";
import { useAppSelector } from "redux/hooks";
import { EntityType } from "module/entity/types";
import selectEntityHelper from "module/staff/redux/selector/selectEntity";
import {
  MyTrainingCustomAssigneeInterface,
  MyTrainingCustomInterface,
  TrainingCustomToSyncInterface,
} from "module/trainingCustom/types";
import { mapTrainingCustomToSync } from "module/trainingCustom/sync/mapper";

interface Result {
  geTrainingCustomMappedToSync: (id: string) => TrainingCustomToSyncInterface;
}

const useTrainingCustomSyncHelper = (): Result => {
  const selectEntity = useAppSelector(selectEntityHelper);

  const geTrainingCustomMappedToSync = useCallback(
    (id: string): TrainingCustomToSyncInterface => {
      const myTrainingCustom = selectEntity({
        id,
        type: EntityType.trainingCustom,
      }) as MyTrainingCustomInterface;

      const assignees = myTrainingCustom.data.assignees.map(
        (entityIdentifier) =>
          selectEntity(entityIdentifier) as MyTrainingCustomAssigneeInterface
      );

      return mapTrainingCustomToSync(myTrainingCustom, assignees);
    },
    [selectEntity]
  );

  return {
    geTrainingCustomMappedToSync,
  };
};

export default useTrainingCustomSyncHelper;
