import React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import StickyPaperTableContainer from "component/sticky/StickyPaperTableContainer";
import { EntityIdentifier } from "module/entity/types";
import MyAssessmentDetailsObjectiveContextProvider from "pages/myAssessmentDetails/objectives/MyAssessmentDetailsObjectiveContextProvider";
import AssessmentObjectiveTableHead from "module/assessmentObjective/enrichedComponent/tableHead/AssessmentObjectiveTableHead";
import AssessmentObjectiveTableRowContainer from "module/assessmentObjective/enrichedComponent/tableRow/AssessmentObjectiveTableRowContainer";
import { getEntityIdentifierKey } from "module/entity/helper/getEntityIdentifierKey";

interface MyAssessmentDetailsObjectivesInterface {
  objectivesIdentifiers: EntityIdentifier[];
}

const MyAssessmentDetailsObjectives: React.FC<MyAssessmentDetailsObjectivesInterface> =
  ({ objectivesIdentifiers }) => {
    return (
      <StickyPaperTableContainer>
        <Table stickyHeader>
          <AssessmentObjectiveTableHead />
          <TableBody>
            {objectivesIdentifiers.map((objective) => (
              <MyAssessmentDetailsObjectiveContextProvider
                {...{
                  entityIdentifier: objective,
                  key: getEntityIdentifierKey(objective),
                }}
              >
                <AssessmentObjectiveTableRowContainer />
              </MyAssessmentDetailsObjectiveContextProvider>
            ))}
          </TableBody>
        </Table>
      </StickyPaperTableContainer>
    );
  };

export default React.memo(MyAssessmentDetailsObjectives);
