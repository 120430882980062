import React, { useContext, useCallback } from "react";
import { useHistory } from "react-router-dom";
import TrainingToBeSignedDetailsSessions from "pages/trainingToBeSignedDetails/sessions/TrainingToBeSignedDetailsSessions";
import TrainingContext from "module/training/context/TrainingContext";
import { TrainingInterface } from "module/training/types";
import routes from "routes/routes";
import TrainingSignContext from "module/training/sign/TrainingSignContext";

const TrainingToBeSignedDetailsSessionsContainer: React.FC = () => {
  const { entity } = useContext(TrainingContext);
  const { sign: signHelper } = useContext(TrainingSignContext);
  const history = useHistory();

  const training = entity as TrainingInterface;

  const sign = useCallback(async (): Promise<void> => {
    await signHelper();
    history.push(routes.toBeSigned.path);
  }, [history, signHelper]);

  return (
    <TrainingToBeSignedDetailsSessions
      {...{ sessionsIdentifiers: training.sessions, sign }}
    />
  );
};

export default React.memo(TrainingToBeSignedDetailsSessionsContainer);
