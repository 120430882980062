import React, { useCallback, useEffect, useState, useMemo } from "react";
import { useHistory, useParams, useRouteMatch } from "react-router-dom";
import TrainingContext from "module/training/context/TrainingContext";
import routes from "routes/routes";
import { getPathToTrainingToBeSignedDetailsOverview } from "module/training/helper/getPath";
import TrainingToBeSignedDetailsContainer from "pages/trainingToBeSignedDetails/TrainingToBeSignedDetailsContainer";
import { TrainingInterface } from "module/training/types";
import getTrainingDetails from "api/competencyManagement/getTrainingDetails";
import { useAppSelector } from "redux/hooks";
import { selectUser } from "module/auth/redux/authSlice";
import { toastWarning } from "layout/toast/helper";
import t from "module/translations";
import TrainingSignContext from "module/training/sign/TrainingSignContext";
import EntitySignContextProvider from "module/entity/context/sign/EntitySignContextProvider";
import { EntityIdentifier, EntityType } from "module/entity/types";
import { canSignTraining } from "module/training/helper/canSignTraining";

const TrainingToBeSignedDetailsPage: React.FC = () => {
  const { trainingId: id } = useParams<{ trainingId: string }>();
  const [training, setTraining] = useState<TrainingInterface | null>(null);
  const history = useHistory();
  const match = useRouteMatch({
    path: routes.trainingToBeSignedDetails.path,
    exact: true,
  });
  const loggedInStaffId = useAppSelector(selectUser).id;

  useEffect(() => {
    if (id && match) {
      history.push(getPathToTrainingToBeSignedDetailsOverview(id));
    }
  }, [history, id, match]);

  const load = useCallback(async () => {
    const myTraining = await getTrainingDetails(Number(id));
    setTraining(myTraining.data);
  }, [id]);

  useEffect(() => {
    if (training != null && !canSignTraining({ training, loggedInStaffId })) {
      toastWarning(t.toBeSigned.itemNoAbleToSignText);
      history.push(routes.toBeSigned.path);
    }
  }, [training, history, loggedInStaffId]);

  useEffect(() => {
    load();
  }, [load]);

  const entityIdentifier: EntityIdentifier = useMemo(
    () => ({
      id,
      type: EntityType.training,
      backendId: Number(id),
    }),
    [id]
  );

  if (training == null) {
    return null;
  }

  return (
    <TrainingContext.Provider value={{ entity: training }}>
      <EntitySignContextProvider
        {...{ entityIdentifier, context: TrainingSignContext }}
      >
        <TrainingToBeSignedDetailsContainer />
      </EntitySignContextProvider>
    </TrainingContext.Provider>
  );
};

export default React.memo(TrainingToBeSignedDetailsPage);
