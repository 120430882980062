import React, { useContext } from "react";
import CompetencyManagementListTableHead from "pages/competencyManagementList/table/head/CompetencyManagementListTableHead";
import ToggleContext from "module/toggle/context/ToggleContext";
import usePermissions from "module/permission/usePermissions";

const CompetencyManagementListTableHeadContainer: React.FC = () => {
  const { canEditTraining, canEditAssessment } = usePermissions();
  const { toggleAll, areAllSelected, isAnySelected } =
    useContext(ToggleContext);

  const hasPermissionToDownload = canEditTraining || canEditAssessment;

  return (
    <CompetencyManagementListTableHead
      {...{
        areAllSelected,
        isAnySelected,
        toggleAll,
        hasPermissionToDownload,
      }}
    />
  );
};

export default React.memo(CompetencyManagementListTableHeadContainer);
