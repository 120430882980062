import React from "react";
import { EntityValidationResultContextInterface } from "module/entity/context/validationResult/EntityValidationResultContext";

export type MaintenanceTaskValidationResultContextInterface =
  EntityValidationResultContextInterface;

const MaintenanceTaskValidationResultContext = React.createContext(
  <MaintenanceTaskValidationResultContextInterface>{}
);

export default MaintenanceTaskValidationResultContext;
