import React from "react";
import t from "module/translations";
import Button from "@mui/material/Button";
import StopIcon from "@mui/icons-material/Stop";

interface Props {
  onClick: () => void;
}

const CloseButton: React.FC<Props> = ({ onClick }) => {
  return (
    <Button
      variant="contained"
      color="primary"
      size="large"
      onClick={onClick}
      startIcon={<StopIcon />}
    >
      {t.stopText}
    </Button>
  );
};

export default React.memo(CloseButton);
