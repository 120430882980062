import React, { useContext } from "react";
import MyCompetencyManagementListTableHead from "pages/myCompetencyManagementList/table/head/MyCompetencyManagementListTableHead";
import ToggleContext from "module/toggle/context/ToggleContext";

const MyCompetencyManagementListTableHeadContainer: React.FC = () => {
  const { areAllSelected, isAnySelected, toggleAll, canAnyBeSelected } =
    useContext(ToggleContext);

  return (
    <MyCompetencyManagementListTableHead
      {...{
        areAllSelected,
        isAnySelected,
        toggleAll,
        canAnyBeSelected,
      }}
    />
  );
};

export default React.memo(MyCompetencyManagementListTableHeadContainer);
