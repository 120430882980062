import React, { useCallback } from "react";
import { useHistory } from "react-router-dom";
import routes from "routes/routes";

const ErrorPage: React.FC = () => {
  const history = useHistory();
  const handleGoBack = useCallback(() => {
    try {
      history.back();
    } catch (error) {
      history.push(routes.dashboard.path);
    }
  }, [history]);

  return (
    <div>
      Error page
      <button type="button" onClick={handleGoBack}>
        Go back
      </button>
    </div>
  );
};

export default ErrorPage;
