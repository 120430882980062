import { IsEntitySyncAvailableResult } from "module/entity/useEntityAbstractFactory";
import {
  InventoryCheckInterface,
  InventoryCheckState,
  InventoryCheckSyncUnavailableReason,
} from "module/inventoryCheck/types";

interface IsInventoryCheckSyncAvailableProps {
  inventoryCheck: InventoryCheckInterface;
  isOnline: boolean;
  hasPermissions: boolean;
  isOpen: boolean;
  isValid: boolean;
  hasChanged: boolean;
}

const isInventoryCheckSyncAvailable = ({
  inventoryCheck,
  isOnline,
  hasPermissions,
  isOpen,
  isValid,
  hasChanged,
}: IsInventoryCheckSyncAvailableProps): IsEntitySyncAvailableResult => {
  if (!isOnline) {
    return {
      isAvailable: false,
      unavailableReason: InventoryCheckSyncUnavailableReason.offline,
    };
  }

  if (!hasPermissions) {
    return {
      isAvailable: false,
      unavailableReason: InventoryCheckSyncUnavailableReason.noPermissions,
    };
  }

  if (isOpen) {
    return {
      isAvailable: false,
      unavailableReason: InventoryCheckSyncUnavailableReason.isOpen,
    };
  }

  if (!isValid) {
    return {
      isAvailable: false,
      unavailableReason: InventoryCheckSyncUnavailableReason.notValid,
    };
  }

  if (!hasChanged) {
    return {
      isAvailable: false,
      unavailableReason: InventoryCheckSyncUnavailableReason.hasNotChanged,
    };
  }

  if (
    ![InventoryCheckState.scheduled, InventoryCheckState.completed].includes(
      inventoryCheck.state
    )
  ) {
    return {
      isAvailable: false,
      unavailableReason:
        InventoryCheckSyncUnavailableReason.stateIsNotSupported,
    };
  }

  return {
    isAvailable: true,
    unavailableReason: null,
  };
};

export default isInventoryCheckSyncAvailable;
