import React from "react";
import { EntityContextInterface } from "module/entity/context/entity/EntityContext";

export type InventoryCheckArticleContextInterface = EntityContextInterface;

const InventoryCheckArticleContext = React.createContext(
  <InventoryCheckArticleContextInterface>{}
);

export default InventoryCheckArticleContext;
