import React, { useContext } from "react";
import useMyAssessmentOpenClose from "module/assessment/openClose/useMyAssessmentOpenClose";
import { AssessmentInterface } from "module/assessment/types";
import AssessmentContext from "module/assessment/context/AssessmentContext";
import AssessmentOpenCloseContext, {
  AssessmentOpenCloseContextInterface,
} from "module/assessment/openClose/context/AssessmentOpenCloseContext";

interface Prop {
  children: React.ReactNode;
}

const MyAssessmentOpenCloseContextProvider: React.FC<Prop> = ({ children }) => {
  const { entity } = useContext(AssessmentContext);

  const { open, close } = useMyAssessmentOpenClose(
    entity as AssessmentInterface
  );

  const contextValue: AssessmentOpenCloseContextInterface = {
    isOpen: close !== undefined,
    open,
    close,
  };

  return (
    <AssessmentOpenCloseContext.Provider value={contextValue}>
      {children}
    </AssessmentOpenCloseContext.Provider>
  );
};

export default MyAssessmentOpenCloseContextProvider;
