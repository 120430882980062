import React from "react";
import TableCell from "@mui/material/TableCell";
import t from "module/translations";
import TableHeadRow from "component/table/tableHeadRow/TableHeadRow";
import TableToggleCell from "component/table/toggleCell/TableToggleCell";
import TableEmptyToggleCell from "component/table/toggleCell/TableEmptyToggleCell";
import { MaintenanceTaskSortProperty } from "pages/maintenanceTaskList/types";
import TableHead from "@mui/material/TableHead";
import TableSortCellContainer from "module/sort/enrichedComponent/TableSortCellContainer";

interface Props {
  isAnySelected: boolean;
  areAllSelected: boolean;
  toggleAll: (checked: boolean) => void;
  canAnyBeSelected: boolean;
}

const MyMaintenanceTaskListTableHead: React.FC<Props> = ({
  isAnySelected,
  areAllSelected,
  toggleAll,
  canAnyBeSelected,
}) => {
  return (
    <TableHead>
      <TableHeadRow>
        {canAnyBeSelected && (
          <TableToggleCell
            {...{
              isSelected: areAllSelected,
              indeterminate: isAnySelected,
              toggle: toggleAll,
            }}
          />
        )}
        {!canAnyBeSelected && <TableEmptyToggleCell />}
        <TableCell align="left">
          {t.maintenanceTask.reportingNumberText}
        </TableCell>
        <TableSortCellContainer
          {...{
            fieldName: MaintenanceTaskSortProperty.name,
          }}
        >
          {t.maintenanceTask.nameText}
        </TableSortCellContainer>
        <TableCell align="left">{t.maintenanceTask.typeText}</TableCell>
        <TableSortCellContainer
          {...{
            fieldName: MaintenanceTaskSortProperty.scheduled,
          }}
        >
          {t.maintenanceTask.scheduledDateText}
        </TableSortCellContainer>
        <TableCell align="left">{t.maintenanceTask.windowText}</TableCell>
        <TableCell align="left">
          {t.maintenanceTask.timeDeviationText}
        </TableCell>
        <TableSortCellContainer
          {...{
            fieldName: MaintenanceTaskSortProperty.state,
          }}
        >
          {t.maintenanceTask.stateText}
        </TableSortCellContainer>
        <TableCell />
      </TableHeadRow>
    </TableHead>
  );
};

export default React.memo(MyMaintenanceTaskListTableHead);
