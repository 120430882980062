import React, { useContext, useMemo } from "react";
import MaintenanceTaskContext from "module/maintenanceTask/context/MaintenanceTaskContext";
import { MaintenanceTaskInterface } from "module/maintenanceTask/types";
import { EntityType } from "module/entity/types";
import useMaintenanceTaskPersonnelAssignmentExternalStaffAdd from "module/maintenanceTaskPersonnelAssignment/externalStaff/add/useMaintenanceTaskPersonnelAssignmentExternalStaffAdd";
import MyMaintenanceTaskDetailsPersonnelAssignmentsExternalStaff from "pages/myMaintenanceTaskDetails/personnelAssignment/externalList/MyMaintenanceTaskDetailsPersonnelAssignmentsExternalStaff";

const MyMaintenanceTaskDetailsPersonnelAssignmentsExternalStaffContainer: React.FC =
  () => {
    const { entity } = useContext(MaintenanceTaskContext);
    const maintenanceTask = entity as MaintenanceTaskInterface;

    const { canAdd, add } =
      useMaintenanceTaskPersonnelAssignmentExternalStaffAdd();

    const entityIdentifiers = useMemo(
      () =>
        maintenanceTask.personnelAssignments.filter(
          (personnelAssignmentEntityIdentifier) =>
            personnelAssignmentEntityIdentifier.type ===
            EntityType.maintenanceTaskPersonnelAssignmentExternalStaff
        ),
      [maintenanceTask.personnelAssignments]
    );

    return (
      <MyMaintenanceTaskDetailsPersonnelAssignmentsExternalStaff
        {...{
          entityIdentifiers,
          canAdd,
          add,
        }}
      />
    );
  };

export default React.memo(
  MyMaintenanceTaskDetailsPersonnelAssignmentsExternalStaffContainer
);
