import { PayloadAction } from "@reduxjs/toolkit";
import { StaffDataState } from "module/staff/redux/types";
import {
  EntityIdentifier,
  EntityType,
  MyEntityInterface,
} from "module/entity/types";
import { validateAssessment } from "module/assessment/validation/validateAssessment";
import { validateAssessmentObjective } from "module/assessmentObjective/valiadation/validateAssessmentObjective";
import {
  AssessmentInterface,
  AssessmentObjectiveInterface,
  MyAssessmentInterface,
  MyAssessmentObjectiveInterface,
} from "module/assessment/types";
import { validateTraining } from "module/training/validation/validateTraining";
import {
  MyTrainingInterface,
  MyTrainingSessionInterface,
  TrainingInterface,
  TrainingSessionInterface,
} from "module/training/types";
import { validateTrainingSession } from "module/trainingSession/validation/validateTrainingSession";
import { getEntityIdentifierKey } from "module/entity/helper/getEntityIdentifierKey";
import {
  MaintenanceTaskInterface,
  MyMaintenanceTaskInterface,
} from "module/maintenanceTask/types";
import { validateMaintenanceTask } from "module/maintenanceTask/validation/validateMaintenanceTask";
import {
  MaintenanceTaskProcessStepInterface,
  MyMaintenanceTaskProcessStepInterface,
} from "module/maintenanceTaskProcessStep/types";
import { validateMaintenanceTaskProcessStep } from "module/maintenanceTaskProcessStep/validation/validateMaintenanceTaskProcessStep";
import {
  MaintenanceTaskProcessStepQuickFixInterface,
  MyMaintenanceTaskProcessStepQuickFixInterface,
} from "module/maintenanceTaskProcessStepQuickFix/types";
import { validateMaintenanceTaskProcessStepQuickFix } from "module/maintenanceTaskProcessStepQuickFix/validation/validateMaintenanceTaskProcessStepQuickFix";
import {
  MaintenanceTaskPartInterface,
  MyMaintenanceTaskPartInterface,
} from "module/maintenanceTaskPart/types";
import { validateMaintenanceTaskPart } from "module/maintenanceTaskPart/validation/validateMaintenanceTaskPart";
import { SystemConfigurationInterface } from "module/auth/types";
import {
  MaintenanceTaskPersonnelAssignmentInternalStaffInterface,
  MyMaintenanceTaskPersonnelAssignmentInternalStaffInterface,
} from "module/maintenanceTaskPersonnelAssignment/internalStaff/types";
import { validateMaintenanceTaskPersonnelAssignmentInternalStaff } from "module/maintenanceTaskPersonnelAssignment/internalStaff/validation/validateMaintenanceTaskPersonnelAssignmentInternalStaff";
import {
  MaintenanceTaskPersonnelAssignmentExternalStaffInterface,
  MyMaintenanceTaskPersonnelAssignmentExternalStaffInterface,
} from "module/maintenanceTaskPersonnelAssignment/externalStaff/types";
import { validateMaintenanceTaskPersonnelAssignmentExternalStaff } from "module/maintenanceTaskPersonnelAssignment/externalStaff/validation/validateMaintenanceTaskPersonnelAssignmentExternalStaff";
import { validateTrainingCustom } from "module/trainingCustom/validation/validateTrainingCustom";
import {
  MyTrainingCustomAssigneeInterface,
  MyTrainingCustomInterface,
  TrainingCustomAssigneeInterface,
  TrainingCustomInterface,
} from "module/trainingCustom/types";
import { validateTrainingCustomAssignee } from "module/trainingCustomAssignee/validation/validateTrainingCustomAssignee";
import { validateInventoryCheck } from "module/inventoryCheck/validation/validateInventoryCheck";
import {
  InventoryCheckInterface,
  MyInventoryCheckInterface,
} from "module/inventoryCheck/types";
import {
  InventoryCheckArticleInterface,
  MyInventoryCheckArticleInterface,
} from "module/inventoryCheckArticle/types";
import { validateInventoryCheckArticle } from "module/inventoryCheckArticle/validation/validateInventoryCheckArticle";

interface Props {
  staffId: number;
  systemShortName: string;
  entityIdentifiers?: EntityIdentifier[];
  systemConfiguration: SystemConfigurationInterface;
}

const validateEntitiesAction = (
  state: StaffDataState,
  action: PayloadAction<Props>
): void => {
  const { staffId, systemShortName, entityIdentifiers, systemConfiguration } =
    action.payload;

  const getEntitiesToValidate = (
    entityIdentifier: EntityIdentifier
  ): MyEntityInterface[] => {
    const myEntity =
      state[staffId][systemShortName].entities[
        getEntityIdentifierKey(entityIdentifier)
      ];
    let result: MyEntityInterface[] = [myEntity];
    myEntity.data.relationshipIdentifiers.map(
      // eslint-disable-next-line array-callback-return
      (relationshipEntityIdentifier) => {
        result = [
          ...result,
          ...getEntitiesToValidate(relationshipEntityIdentifier),
        ];
      }
    );

    return result;
  };

  let entitiesToValidate: MyEntityInterface[] = [];
  if (entityIdentifiers == null) {
    entitiesToValidate = Object.values(
      state[staffId][systemShortName].entities
    );
  } else {
    entitiesToValidate = entityIdentifiers.reduce(
      (result, entityIdentifier) => [
        ...result,
        ...getEntitiesToValidate(entityIdentifier),
      ],
      [] as MyEntityInterface[]
    );
  }

  // eslint-disable-next-line array-callback-return
  entitiesToValidate.map((myEntity) => {
    let validationResult;

    if (myEntity.entityIdentifier.type === EntityType.assessment) {
      validationResult = validateAssessment(
        myEntity.data as AssessmentInterface
      );
    } else if (
      myEntity.entityIdentifier.type === EntityType.assessmentObjective
    ) {
      const myAssessment = state[staffId][systemShortName].entities[
        getEntityIdentifierKey({
          id: (myEntity as MyAssessmentObjectiveInterface).data.assessmentId,
          type: EntityType.assessment,
        })
      ] as MyAssessmentInterface;
      validationResult = validateAssessmentObjective(
        myAssessment.data,
        myEntity.data as AssessmentObjectiveInterface
      );
    } else if (myEntity.entityIdentifier.type === EntityType.training) {
      validationResult = validateTraining(myEntity.data as TrainingInterface);
    } else if (myEntity.entityIdentifier.type === EntityType.trainingSession) {
      const myTraining = state[staffId][systemShortName].entities[
        getEntityIdentifierKey({
          id: (myEntity as MyTrainingSessionInterface).data.trainingId,
          type: EntityType.training,
        })
      ] as MyTrainingInterface;
      validationResult = validateTrainingSession(
        myTraining.data,
        myEntity.data as TrainingSessionInterface
      );
    } else if (myEntity.entityIdentifier.type === EntityType.trainingCustom) {
      validationResult = validateTrainingCustom(
        myEntity.data as TrainingCustomInterface
      );
    } else if (
      myEntity.entityIdentifier.type === EntityType.trainingCustomAssignee
    ) {
      const myTrainingCustom = state[staffId][systemShortName].entities[
        getEntityIdentifierKey({
          id: (myEntity as MyTrainingCustomAssigneeInterface).data
            .trainingCustomId,
          type: EntityType.trainingCustom,
        })
      ] as MyTrainingCustomInterface;
      validationResult = validateTrainingCustomAssignee(
        myTrainingCustom.data,
        myEntity.data as TrainingCustomAssigneeInterface
      );
    } else if (myEntity.entityIdentifier.type === EntityType.maintenanceTask) {
      validationResult = validateMaintenanceTask(
        myEntity.data as MaintenanceTaskInterface
      );
    } else if (
      myEntity.entityIdentifier.type === EntityType.maintenanceTaskProcessStep
    ) {
      const myMaintenanceTask = state[staffId][systemShortName].entities[
        getEntityIdentifierKey({
          id: (myEntity as MyMaintenanceTaskProcessStepInterface).data
            .maintenanceTaskId,
          type: EntityType.maintenanceTask,
        })
      ] as MyMaintenanceTaskInterface;
      validationResult = validateMaintenanceTaskProcessStep(
        myMaintenanceTask.data,
        myEntity.data as MaintenanceTaskProcessStepInterface
      );
    } else if (
      myEntity.entityIdentifier.type ===
      EntityType.maintenanceTaskProcessStepQuickFix
    ) {
      const myMaintenanceTaskProcessStep = state[staffId][systemShortName]
        .entities[
        getEntityIdentifierKey({
          id: (myEntity as MyMaintenanceTaskProcessStepQuickFixInterface).data
            .maintenanceTaskProcessStepId,
          type: EntityType.maintenanceTaskProcessStep,
        })
      ] as MyMaintenanceTaskProcessStepInterface;
      const myMaintenanceTask = state[staffId][systemShortName].entities[
        getEntityIdentifierKey({
          id: myMaintenanceTaskProcessStep.data.maintenanceTaskId,
          type: EntityType.maintenanceTask,
        })
      ] as MyMaintenanceTaskInterface;
      validationResult = validateMaintenanceTaskProcessStepQuickFix(
        myMaintenanceTask.data,
        myMaintenanceTaskProcessStep.data,
        myEntity.data as MaintenanceTaskProcessStepQuickFixInterface
      );
    } else if (
      myEntity.entityIdentifier.type === EntityType.maintenanceTaskPart
    ) {
      const myMaintenanceTask = state[staffId][systemShortName].entities[
        getEntityIdentifierKey({
          id: (myEntity as MyMaintenanceTaskPartInterface).data
            .maintenanceTaskId,
          type: EntityType.maintenanceTask,
        })
      ] as MyMaintenanceTaskInterface;

      validationResult = validateMaintenanceTaskPart(
        myMaintenanceTask.data,
        myEntity.dataWithoutChanges as MaintenanceTaskPartInterface,
        myEntity.data as MaintenanceTaskPartInterface,
        systemConfiguration.stockArticles
      );
    } else if (
      myEntity.entityIdentifier.type ===
      EntityType.maintenanceTaskPersonnelAssignmentInternalStaff
    ) {
      const myMaintenanceTask = state[staffId][systemShortName].entities[
        getEntityIdentifierKey({
          id: (
            myEntity as MyMaintenanceTaskPersonnelAssignmentInternalStaffInterface
          ).data.maintenanceTaskId,
          type: EntityType.maintenanceTask,
        })
      ] as MyMaintenanceTaskInterface;

      validationResult =
        validateMaintenanceTaskPersonnelAssignmentInternalStaff(
          myMaintenanceTask.data,
          myEntity.data as MaintenanceTaskPersonnelAssignmentInternalStaffInterface
        );
    } else if (
      myEntity.entityIdentifier.type ===
      EntityType.maintenanceTaskPersonnelAssignmentExternalStaff
    ) {
      const myMaintenanceTask = state[staffId][systemShortName].entities[
        getEntityIdentifierKey({
          id: (
            myEntity as MyMaintenanceTaskPersonnelAssignmentExternalStaffInterface
          ).data.maintenanceTaskId,
          type: EntityType.maintenanceTask,
        })
      ] as MyMaintenanceTaskInterface;

      validationResult =
        validateMaintenanceTaskPersonnelAssignmentExternalStaff(
          myMaintenanceTask.data,
          myEntity.data as MaintenanceTaskPersonnelAssignmentExternalStaffInterface
        );
    } else if (myEntity.entityIdentifier.type === EntityType.inventoryCheck) {
      validationResult = validateInventoryCheck(
        myEntity.data as InventoryCheckInterface
      );
    } else if (
      myEntity.entityIdentifier.type === EntityType.inventoryCheckArticle
    ) {
      const myInventoryCheck = state[staffId][systemShortName].entities[
        getEntityIdentifierKey({
          id: (myEntity as MyInventoryCheckArticleInterface).data
            .inventoryCheckId,
          type: EntityType.inventoryCheck,
        })
      ] as MyInventoryCheckInterface;
      validationResult = validateInventoryCheckArticle(
        myInventoryCheck.data,
        myEntity.data as InventoryCheckArticleInterface
      );
    }

    if (validationResult) {
      state[staffId][systemShortName].entities[
        getEntityIdentifierKey(myEntity.entityIdentifier)
      ].validationErrors = validationResult.errors;

      state[staffId][systemShortName].entities[
        getEntityIdentifierKey(myEntity.entityIdentifier)
      ].validationWarnings = validationResult.warnings;
    }
  });
};

export default validateEntitiesAction;
