import { useCallback, useContext } from "react";
import { useAppDispatch } from "redux/hooks";
import {
  setApiToken,
  setDefaultState,
  setSystem,
  setUser,
} from "module/auth/redux/authSlice";
import loginByAccessToken from "api/auth/loginByAccessToken";
import getMe from "api/auth/getMe";
import {
  LOGOUT_VALUE,
  MICROSOFT_LOGIN_OAUTH_STEP1_PATH,
  MICROSOFT_LOGIN_OAUTH_STEP2_PATH,
  MICROSOFT_LOGOUT_OAUTH_PATH,
} from "module/auth/constants";
import { parse as parseQueryString } from "query-string";
import { removeAuthData } from "module/storage/storage";
import * as Sentry from "@sentry/react";
import FullScreenLoaderContext from "module/fullScreenLoader/context/FullScreenLoaderContext";
import loginByImsAccessToken from "api/auth/loginByImsAccessToken";

interface Result {
  redirectToLogin: () => void;
  handleLogin: () => void;
  redirectToLogout: () => void;
  handleLogout: () => void;
}

const useAuth = (): Result => {
  const dispatch = useAppDispatch();
  const { turnOn: turnLoaderOn, turnOff: turnLoaderOff } = useContext(
    FullScreenLoaderContext
  );

  const login = useCallback(
    async (accessToken: string) => {
      turnLoaderOn();
      const imsToken = await loginByAccessToken(accessToken);
      dispatch(setApiToken(imsToken));
      const user = await getMe();
      Sentry.setUser({
        id: `${user.id}`,
        username: `${user.firstName} ${user.lastName}`,
      });
      Sentry.setTag("system", user.defaultSystemIdentifier);
      dispatch(setUser(user));
      dispatch(setSystem(user.defaultSystemIdentifier));
      turnLoaderOff();
      Sentry.captureMessage("User logged in");
    },
    [dispatch, turnLoaderOff, turnLoaderOn]
  );

  const loginByImsAccessTokenHelper = useCallback(
    async (accessToken: string) => {
      turnLoaderOn();
      const imsToken = await loginByImsAccessToken(accessToken);
      dispatch(setApiToken(imsToken));
      const user = await getMe();
      Sentry.setUser({
        id: `${user.id}`,
        username: `${user.firstName} ${user.lastName}`,
      });
      Sentry.setTag("system", user.defaultSystemIdentifier);
      dispatch(setUser(user));
      dispatch(setSystem(user.defaultSystemIdentifier));
      turnLoaderOff();
      if (user.defaultSystemIdentifier === "OAC") {
        Sentry.captureMessage("User logged in");
      }
    },
    [dispatch, turnLoaderOff, turnLoaderOn]
  );

  const redirectToLogin = useCallback(() => {
    global.location.href = MICROSOFT_LOGIN_OAUTH_STEP1_PATH;
  }, []);

  const handleLogin = useCallback(async () => {
    const {
      id_token: idToken,
      access_token: accessToken,
      ims_access_token: imsAccessToken,
    } = parseQueryString(global.location.hash);

    if (accessToken) {
      global.location.hash = "";
      await login(accessToken as string);
    } else if (idToken) {
      global.location.href = MICROSOFT_LOGIN_OAUTH_STEP2_PATH;
    } else if (imsAccessToken) {
      global.location.hash = "";
      await loginByImsAccessTokenHelper(imsAccessToken as string);
    }
  }, [login, loginByImsAccessTokenHelper]);

  // `removeAuthData` is being called in `redirectToLogout` and `handleLogout` for purpose
  // microsoft logout should always redirect back to application, but sometimes it does not work
  // calling `removeAuthData` before redirect to microsoft removes all the auth data from application, so even if microsoft redirect back won't work app auth data will be empty
  const redirectToLogout = useCallback(async () => {
    Sentry.captureMessage("User logged out (redirect to Microsoft)");
    await removeAuthData();
    global.location.href = MICROSOFT_LOGOUT_OAUTH_PATH;
  }, []);

  const handleLogout = useCallback(async () => {
    const { logout } = parseQueryString(global.location.hash);
    if (logout === LOGOUT_VALUE) {
      dispatch(setDefaultState());
      await removeAuthData();
      global.location.hash = "";
      Sentry.captureMessage("User logged out");
    }
  }, [dispatch]);

  return {
    redirectToLogin,
    handleLogin,
    redirectToLogout,
    handleLogout,
  };
};

export default useAuth;
