import React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import StickyPaperTableContainer from "component/sticky/StickyPaperTableContainer";
import t from "module/translations";
import { EntityIdentifier } from "module/entity/types";
import ElementWithConfirmation from "component/elementWithConfirmation/ElementWithConfirmation";
import SignButton from "component/button/SignButton";
import ButtonsContainer from "component/button/ButtonsContainer";
import AssessmentObjectiveContextProvider from "module/assessmentObjective/context/AssessmentObjectiveContextProvider";
import AssessmentObjectiveTableHead from "module/assessmentObjective/enrichedComponent/tableHead/AssessmentObjectiveTableHead";
import AssessmentObjectiveTableRowContainer from "module/assessmentObjective/enrichedComponent/tableRow/AssessmentObjectiveTableRowContainer";
import { getEntityIdentifierKey } from "module/entity/helper/getEntityIdentifierKey";

interface AssessmentToBeSignedDetailsObjectivesInterface {
  objectivesIdentifiers: EntityIdentifier[];
  sign: () => Promise<void>;
}

const AssessmentToBeSignedDetailsObjectives: React.FC<AssessmentToBeSignedDetailsObjectivesInterface> =
  ({ objectivesIdentifiers, sign }) => {
    return (
      <>
        <StickyPaperTableContainer>
          <Table stickyHeader>
            <AssessmentObjectiveTableHead />
            <TableBody>
              {objectivesIdentifiers.map((objective) => (
                <AssessmentObjectiveContextProvider
                  {...{
                    entityIdentifier: objective,
                    key: getEntityIdentifierKey(objective),
                  }}
                >
                  <AssessmentObjectiveTableRowContainer />
                </AssessmentObjectiveContextProvider>
              ))}
            </TableBody>
          </Table>
        </StickyPaperTableContainer>
        <ButtonsContainer>
          <ElementWithConfirmation
            {...{
              confirmationDialogTitle:
                t.competencyManagement.signConfirmationText,
              asyncAction: sign,
            }}
          >
            {(props: { onClick: () => void }) => (
              <SignButton {...{ onClick: props.onClick }} />
            )}
          </ElementWithConfirmation>
        </ButtonsContainer>
      </>
    );
  };

export default React.memo(AssessmentToBeSignedDetailsObjectives);
