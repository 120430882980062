import {
  MyTrainingInterface,
  MyTrainingSessionInterface,
  TrainingInterface,
  TrainingSessionInterface,
} from "module/training/types";
import {
  AssessmentInterface,
  AssessmentObjectiveInterface,
  MyAssessmentInterface,
  MyAssessmentObjectiveInterface,
} from "module/assessment/types";
import { XValidatorErrorsInterface } from "module/validation/types";
import {
  MaintenanceTaskInterface,
  MyMaintenanceTaskInterface,
} from "module/maintenanceTask/types";
import {
  AttachmentInterface,
  MyAttachmentInterface,
} from "module/attachment/types";
import {
  MaintenanceTaskProcessStepInterface,
  MyMaintenanceTaskProcessStepInterface,
} from "module/maintenanceTaskProcessStep/types";

import {
  MaintenanceTaskPartInterface,
  MyMaintenanceTaskPartInterface,
} from "module/maintenanceTaskPart/types";
import {
  MaintenanceTaskProcessStepQuickFixInterface,
  MyMaintenanceTaskProcessStepQuickFixInterface,
} from "module/maintenanceTaskProcessStepQuickFix/types";
import {
  MaintenanceTaskPersonnelAssignmentInternalStaffInterface,
  MyMaintenanceTaskPersonnelAssignmentInternalStaffInterface,
} from "module/maintenanceTaskPersonnelAssignment/internalStaff/types";
import {
  MaintenanceTaskPersonnelAssignmentExternalStaffInterface,
  MyMaintenanceTaskPersonnelAssignmentExternalStaffInterface,
} from "module/maintenanceTaskPersonnelAssignment/externalStaff/types";
import {
  MyTrainingCustomAssigneeInterface,
  MyTrainingCustomInterface,
  TrainingCustomAssigneeInterface,
  TrainingCustomInterface,
} from "module/trainingCustom/types";
import {
  InventoryCheckInterface,
  MyInventoryCheckInterface,
} from "../inventoryCheck/types";
import {
  InventoryCheckArticleInterface,
  MyInventoryCheckArticleInterface,
} from "../inventoryCheckArticle/types";

export enum EntityType {
  training = "training",
  trainingSession = "trainingSession",
  assessment = "assessment",
  assessmentObjective = "assessmentObjective",
  maintenanceTask = "maintenanceTask",
  maintenanceTaskDefinition = "maintenanceTaskDefinition",
  maintenanceTaskProcessStep = "maintenanceTaskProcessStep",
  maintenanceTaskProcessStepQuickFix = "maintenanceTaskProcessStepQuickFix",
  maintenanceTaskPersonnelAssignmentInternalStaff = "maintenanceTaskPersonnelAssignmentInternalStaff",
  maintenanceTaskPersonnelAssignmentExternalStaff = "maintenanceTaskPersonnelAssignmentExternalStaff",
  maintenanceTaskAssistantInternalStaff = "maintenanceTaskAssistantInternalStaff",
  maintenanceTaskAssistantExternalStaff = "maintenanceTaskAssistantExternalStaff",
  maintenanceTaskPart = "maintenanceTaskPart",
  attachment = "attachment",
  trainingCustom = "trainingCustom",
  trainingCustomAssignee = "trainingCustomAssignee",
  inventoryCheck = "inventoryCheck",
  inventoryCheckArticle = "inventoryCheckArticle",
}

export interface EntityIdentifier {
  id: string;
  type: EntityType;
  backendId?: number;
}

export interface ApiEntityIdentifier {
  id?: number;
  type: EntityType;
  frontendId: string;
}

export type EntityInterface =
  | TrainingInterface
  | TrainingSessionInterface
  | AssessmentInterface
  | AssessmentObjectiveInterface
  | MaintenanceTaskInterface
  | MaintenanceTaskProcessStepInterface
  | MaintenanceTaskProcessStepQuickFixInterface
  | MaintenanceTaskPersonnelAssignmentInternalStaffInterface
  | MaintenanceTaskPersonnelAssignmentExternalStaffInterface
  | MaintenanceTaskPartInterface
  | AttachmentInterface
  | TrainingCustomInterface
  | TrainingCustomAssigneeInterface
  | InventoryCheckInterface
  | InventoryCheckArticleInterface;

export type MyEntityInterface =
  | MyTrainingSessionInterface
  | MyTrainingInterface
  | MyAssessmentInterface
  | MyAssessmentObjectiveInterface
  | MyMaintenanceTaskInterface
  | MyMaintenanceTaskProcessStepInterface
  | MyMaintenanceTaskProcessStepQuickFixInterface
  | MyMaintenanceTaskPersonnelAssignmentInternalStaffInterface
  | MyMaintenanceTaskPersonnelAssignmentExternalStaffInterface
  | MyMaintenanceTaskPartInterface
  | MyAttachmentInterface
  | MyTrainingCustomInterface
  | MyTrainingCustomAssigneeInterface
  | MyInventoryCheckInterface
  | MyInventoryCheckArticleInterface;

export interface EntityWithRelationships {
  relationships?: Map<EntityIdentifier, MyEntityInterface>;
  relationshipIdentifiers: EntityIdentifier[];
}

export interface EntityWithId {
  id: string;
  backendId?: number;
}

export interface EntityChangedDate {
  changedDateTime?: string;
}

export interface AbstractMyEntityInterface<ApiInterface, AppInterface> {
  entityIdentifier: EntityIdentifier;
  data: AppInterface;
  raw?: ApiInterface;
  dataWithoutChanges: AppInterface;
  validationErrors: XValidatorErrorsInterface | null;
  validationWarnings: XValidatorErrorsInterface | null;
}
