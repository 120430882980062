import {
  MaintenanceTaskInterface,
  MaintenanceTaskState,
} from "module/maintenanceTask/types";
import { isStaffLeadOfMaintenanceTask } from "module/maintenanceTask/helper/isStaffLeadOfMaintenanceTask";
import { isStaffResponsibleForFinalVerificationOfMaintenanceTask } from "module/maintenanceTask/helper/isStaffResponsibleForFinalVerificationOfMaintenanceTask";

export const canSignMaintenanceTask = ({
  maintenanceTask,
  loggedInStaffId,
}: {
  maintenanceTask: MaintenanceTaskInterface;
  loggedInStaffId: number;
}): boolean => {
  const staffIsResponsibleForFinalVerification =
    isStaffResponsibleForFinalVerificationOfMaintenanceTask({
      maintenanceTask,
      loggedInStaffId,
    });

  const staffIsLead = isStaffLeadOfMaintenanceTask({
    maintenanceTask,
    loggedInStaffId,
  });
  return (
    maintenanceTask.state === MaintenanceTaskState.completed &&
    (staffIsResponsibleForFinalVerification || staffIsLead)
  );
};
