import React, { useState, useCallback } from "react";
import { Theme } from "@mui/material/styles";
import createStyles from "@mui/styles/createStyles";
import makeStyles from "@mui/styles/makeStyles";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import PhotoIcon from "@mui/icons-material/Photo";
import DeleteIcon from "@mui/icons-material/Delete";
import IconButton from "@mui/material/IconButton";
import FullPageDialog from "component/dialog/FullPageDialog";
import t from "module/translations";
import { PHOTO_HEIGHT_PX } from "component/camera/Camera";

export const BUTTON_SIZE = 192;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: `${BUTTON_SIZE}px`,
      height: `${BUTTON_SIZE}px`,
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      cursor: "pointer",
      position: "relative",
    },
    image: {
      maxHeight: `${BUTTON_SIZE}px`,
      maxWidth: `${BUTTON_SIZE}px`,
      marginTop: "6px",
      objectFit: "cover",
    },
    imagePreviewWrapper: {
      width: "100%",
      display: "flex",
      flexGrow: 1,
      justifyContent: "center",
    },
    actionsWrapper: {
      width: `${BUTTON_SIZE}px`,
      height: `${BUTTON_SIZE}px`,
      position: "absolute",
      display: "flex",
      justifyContent: "space-evenly",
    },
    icon: {
      color: theme.palette.primary.main,
      backgroundColor: "#fff",
      opacity: 0.85,
      borderRadius: "5px",
    },
    iconButton: {
      "&:hover": {
        backgroundColor: "transparent",
        color: "transparent",
      },
    },
  })
);

interface Props {
  src: string;
  canRemove: boolean;
  remove: () => void;
}

const Image: React.FC<Props> = ({ src, canRemove, remove }) => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);

  const handleOpen = useCallback(() => {
    setOpen(true);
  }, []);

  const handleClose = useCallback(() => {
    setOpen(false);
  }, []);

  return (
    <>
      <Card className={classes.root} elevation={3}>
        <CardContent>
          {/* eslint-disable-next-line jsx-a11y/alt-text */}
          <img src={src} height={BUTTON_SIZE} className={classes.image} />
        </CardContent>
        <div className={classes.actionsWrapper}>
          <IconButton
            onClick={handleOpen}
            className={classes.iconButton}
            size="large"
          >
            <PhotoIcon fontSize="large" className={classes.icon} />
          </IconButton>
          {canRemove && (
            <IconButton
              onClick={remove}
              className={classes.iconButton}
              size="large"
            >
              <DeleteIcon fontSize="large" className={classes.icon} />
            </IconButton>
          )}
        </div>
      </Card>
      {open && (
        <FullPageDialog
          {...{
            title: t.imagePreviewText,
            onClose: handleClose,
          }}
        >
          <div className={classes.imagePreviewWrapper}>
            {/* eslint-disable-next-line jsx-a11y/alt-text */}
            <img src={src} height={PHOTO_HEIGHT_PX} />
          </div>
        </FullPageDialog>
      )}
    </>
  );
};

export default React.memo(Image);
