import React, { useContext } from "react";
import MaintenanceTaskContext from "module/maintenanceTask/context/MaintenanceTaskContext";
import { MaintenanceTaskInterface } from "module/maintenanceTask/types";
import XLabelField from "component/labelField/XLabelField";
import t from "module/translations";

const MaintenanceTaskDescriptionFieldContainer: React.FC = () => {
  const { entity } = useContext(MaintenanceTaskContext);
  const { description } = entity as MaintenanceTaskInterface;

  return (
    <XLabelField
      {...{ caption: t.maintenanceTask.descriptionText, label: description }}
    />
  );
};

export default React.memo(MaintenanceTaskDescriptionFieldContainer);
