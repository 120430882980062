import React, { useContext } from "react";
import MaintenanceTaskAttachmentList from "module/maintenanceTaskAttachment/enrichedComponent/list/MaintenanceTaskAttachmentList";
import t from "module/translations";
import MaintenanceTaskContext from "module/maintenanceTask/context/MaintenanceTaskContext";
import { MaintenanceTaskInterface } from "module/maintenanceTask/types";
import XLabelField from "component/labelField/XLabelField";
import useMaintenanceTaskAttachmentAdd from "module/maintenanceTaskAttachment/add/useMaintenanceTaskAttachmentAdd";

const MaintenanceTaskAttachmentListContainer: React.FC = () => {
  const { entity } = useContext(MaintenanceTaskContext);
  const maintenanceTask = entity as MaintenanceTaskInterface;

  const { attachments } = maintenanceTask;

  const { canAdd, add } = useMaintenanceTaskAttachmentAdd();

  if (attachments.length === 0 && !canAdd) {
    return (
      <XLabelField
        {...{
          caption: t.attachment.attachmentsText,
          label: t.attachment.noAttachmentsText,
        }}
      />
    );
  }

  return (
    <MaintenanceTaskAttachmentList
      {...{
        entityIdentifiers: maintenanceTask.attachments,
        canAdd,
        add,
      }}
    />
  );
};

export default React.memo(MaintenanceTaskAttachmentListContainer);
