import React, { useContext } from "react";
import MaintenanceTaskContext from "module/maintenanceTask/context/MaintenanceTaskContext";
import { MaintenanceTaskInterface } from "module/maintenanceTask/types";
import MyMaintenanceTaskToBeSignedDetails from "pages/maintenanceTaskToBeSignedDetails/MaintenanceTaskToBeSignedDetails";

const MyMaintenanceTaskDetailsContainer: React.FC = () => {
  const { entity } = useContext(MaintenanceTaskContext);

  const maintenanceTask = entity as MaintenanceTaskInterface;

  return (
    <MyMaintenanceTaskToBeSignedDetails
      {...{
        maintenanceTaskId: maintenanceTask.id,
      }}
    />
  );
};

export default React.memo(MyMaintenanceTaskDetailsContainer);
