import {
  TrainingInterface,
  TrainingSessionInterface,
  TrainingState,
} from "module/training/types";
import { XValidatorErrorsInterface } from "module/validation/types";
import { createNestedPropertyIfNotExist } from "toolkit/createNestedPropertyIfNotExist";
import { XRequiredValidator } from "module/validation/validator/requiredValidator";
import { TRAINING_SESSION_REMARKS_FIELD_NAME } from "module/trainingSession/constants";

export interface TrainingValidationResult {
  errors: null | XValidatorErrorsInterface;
  warnings: null | XValidatorErrorsInterface;
}

export const validateTrainingSession = (
  training: TrainingInterface,
  session: TrainingSessionInterface
): TrainingValidationResult => {
  let errors: XValidatorErrorsInterface = {};
  const warnings: XValidatorErrorsInterface = {};

  if (
    training.state === TrainingState.completed &&
    !session.isCategory &&
    !session.achieved &&
    !session.remarks
  ) {
    errors = createNestedPropertyIfNotExist(
      errors,
      TRAINING_SESSION_REMARKS_FIELD_NAME,
      []
    );
    errors[TRAINING_SESSION_REMARKS_FIELD_NAME].push(
      new XRequiredValidator().message
    );
  }

  return {
    errors: Object.keys(errors).length ? errors : null,
    warnings: Object.keys(warnings).length ? warnings : null,
  };
};
