import { EntityIdentifier, EntityType } from "module/entity/types";
import { MyInventoryCheckInterface } from "module/inventoryCheck/types";

export const sortInventoryChecks = (
  rawInventoryChecks: MyInventoryCheckInterface[],
  inventoryCheckIdsAssignedToMe: string[]
): Map<EntityIdentifier, MyInventoryCheckInterface> => {
  const items: Map<EntityIdentifier, MyInventoryCheckInterface> = new Map();
  const myItems: Map<EntityIdentifier, MyInventoryCheckInterface> = new Map();
  const notMyItems: Map<EntityIdentifier, MyInventoryCheckInterface> =
    new Map();

  rawInventoryChecks.map(
    // eslint-disable-next-line array-callback-return
    (rawInventoryCheck) => {
      const key: EntityIdentifier = {
        id: rawInventoryCheck.data.id,
        type: EntityType.inventoryCheck,
      };

      // assigned to me first
      if (inventoryCheckIdsAssignedToMe.includes(rawInventoryCheck.data.id)) {
        myItems.set(key, rawInventoryCheck);
      } else {
        notMyItems.set(key, rawInventoryCheck);
      }
    }
  );

  Array.from(myItems.keys()).map((key) =>
    items.set(key, myItems.get(key) as MyInventoryCheckInterface)
  );

  Array.from(notMyItems.keys()).map((key) =>
    items.set(key, notMyItems.get(key) as MyInventoryCheckInterface)
  );

  return items;
};
