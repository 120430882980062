import React from "react";
import { EntityChangeContextInterface } from "module/entity/context/change/EntityChangeContext";

export type AssessmentChangeContextInterface = EntityChangeContextInterface;

const AssessmentChangeContext = React.createContext(
  <AssessmentChangeContextInterface>{}
);

export default AssessmentChangeContext;
