import React from "react";
import Table from "component/table/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "component/table/TableContainer";
import MyMaintenanceTaskListTableRowContainer from "pages/myMaintenanceTaskList/table/row/MyMaintenanceTaskListTableRowContainer";
import MyMaintenanceTaskListTableHeadContainer from "pages/myMaintenanceTaskList/table/head/MyMaintenanceTaskListTableHeadContainer";
import { EntityIdentifier } from "module/entity/types";
import t from "module/translations";
import ListToolbarContainer from "module/listToolbar/ListToolbarContainer";
import { getEntityIdentifierKey } from "module/entity/helper/getEntityIdentifierKey";
import MaintenanceTaskContext from "module/maintenanceTask/context/MaintenanceTaskContext";
import { MaintenanceTaskInterface } from "module/maintenanceTask/types";

interface Props {
  entities: Map<EntityIdentifier, MaintenanceTaskInterface>;
}

const MyMaintenanceTaskList: React.FC<Props> = ({ entities }) => {
  return (
    <>
      <TableContainer>
        <ListToolbarContainer
          {...{
            title: t.routes.maintenanceTasksOffline,
            isSyncAvailable: true,
          }}
        />
        <Table>
          <MyMaintenanceTaskListTableHeadContainer />
          <TableBody>
            {Array.from(entities.keys()).map((entityIdentifier) => (
              <MaintenanceTaskContext.Provider
                {...{
                  key: getEntityIdentifierKey(entityIdentifier),
                  value: {
                    entity: entities.get(
                      entityIdentifier
                    ) as MaintenanceTaskInterface,
                  },
                }}
              >
                <MyMaintenanceTaskListTableRowContainer />
              </MaintenanceTaskContext.Provider>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

export default React.memo(MyMaintenanceTaskList);
