import React from "react";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";
import AssessmentObjectiveAchievedFieldContainer from "module/assessmentObjective/enrichedComponent/achievedField/AssessmentObjectiveAchievedFieldContainer";
import AssessmentObjectiveRemarksFieldContainer from "module/assessmentObjective/enrichedComponent/remarksField/AssessmentObjectiveRemarksFieldContainer";
import NestedElement from "component/nestedElement/NestedElement";

interface Props {
  name: string;
  level: number;
}

const AssessmentObjectiveTableRowItem: React.FC<Props> = ({ name, level }) => {
  return (
    <TableRow hover>
      <TableCell align="left">
        <Typography variant="body2" gutterBottom>
          <NestedElement {...{ level }}>{name}</NestedElement>
        </Typography>
      </TableCell>
      <TableCell align="left">
        <AssessmentObjectiveAchievedFieldContainer />
      </TableCell>
      <TableCell>
        <AssessmentObjectiveRemarksFieldContainer />
      </TableCell>
    </TableRow>
  );
};

export default React.memo(AssessmentObjectiveTableRowItem);
