import { useCallback } from "react";
import { useAppSelector } from "redux/hooks";
import { API_DATE_TIME_FORMAT } from "api/date";
import moment from "moment";
import { AssessmentInterface, AssessmentState } from "module/assessment/types";
import selectOpenItemByEntityType from "module/staff/redux/selector/selectOpenItemByEntityType";
import { EntityIdentifier, EntityType } from "module/entity/types";
import useEntityOpenClose from "module/entity/useEntityOpenClose";
import useEntityChange from "module/entity/useEntityChange";
import { selectUser } from "module/auth/redux/authSlice";

interface Result {
  open?: () => void;
  close?: () => void;
}

const useMyAssessmentOpenClose = (assessment: AssessmentInterface): Result => {
  const entityIdentifier: EntityIdentifier = {
    id: assessment.id,
    type: EntityType.assessment,
  };
  const staffId = useAppSelector(selectUser).id;
  const { change } = useEntityChange(entityIdentifier);
  const {
    canOpen: canOpenEntity,
    open: openEntity,
    canClose: canCloseEntity,
    close: closeEntity,
  } = useEntityOpenClose(entityIdentifier);
  const isAnyAssessmentOpen =
    useAppSelector(selectOpenItemByEntityType(EntityType.assessment)) != null;
  const isAnyTrainingOpen =
    useAppSelector(selectOpenItemByEntityType(EntityType.training)) != null;
  const isAnyTrainingCustomOpen =
    useAppSelector(selectOpenItemByEntityType(EntityType.trainingCustom)) !=
    null;

  const handleOpen = useCallback(() => {
    openEntity();

    let changes: Partial<AssessmentInterface> = {
      completionEndDate: undefined,
      internalTrainerId: staffId,
      externalTrainer: undefined,
    };

    if (assessment.completionStartDate == null) {
      changes = {
        ...changes,
        completionStartDate: moment().format(API_DATE_TIME_FORMAT),
      };
    }

    if (assessment.state === AssessmentState.initScheduled) {
      changes = {
        ...changes,
        state: AssessmentState.scheduled,
      };
    }

    change(changes);
  }, [
    assessment.completionStartDate,
    assessment.state,
    change,
    openEntity,
    staffId,
  ]);
  const canOpen =
    canOpenEntity &&
    !isAnyAssessmentOpen &&
    !isAnyTrainingOpen &&
    !isAnyTrainingCustomOpen;
  const open = canOpen ? handleOpen : undefined;

  const handleClose = useCallback(() => {
    closeEntity();
    if (assessment.state === AssessmentState.completed) {
      change({
        completionEndDate: moment().format(API_DATE_TIME_FORMAT),
      });
    }
  }, [assessment.state, change, closeEntity]);
  const close = canCloseEntity ? handleClose : undefined;

  return {
    open,
    close,
  };
};

export default useMyAssessmentOpenClose;
