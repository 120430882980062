import { useCallback } from "react";
import { useAppDispatch, useAppSelector } from "redux/hooks";
import { selectSystem, selectUser } from "module/auth/redux/authSlice";
import {
  closeEntityCollection,
  openEntityCollection,
} from "module/staff/redux/staffDataSlice";
import { EntityIdentifier } from "module/entity/types";
import selectIsEntityOpen from "module/staff/redux/selector/selectIsEntityOpen";

interface Result {
  canOpen: boolean;
  open: () => void;
  canClose: boolean;
  close: () => void;
}

interface UseEntityCollectionOpenCloseResult {
  calculate: (entityIdentifiers: EntityIdentifier[]) => Result;
}

const useEntityCollectionOpenClose = (): UseEntityCollectionOpenCloseResult => {
  const dispatch = useAppDispatch();
  const staffId = useAppSelector(selectUser).id;
  const systemShortName = useAppSelector(selectSystem).shortName;
  const selectIsEntityOpenHelper = useAppSelector(selectIsEntityOpen);

  const calculate = useCallback(
    (entityIdentifiers: EntityIdentifier[]): Result => {
      const canOpen = entityIdentifiers.reduce((result, entityIdentifier) => {
        const isEntityOpen = selectIsEntityOpenHelper(entityIdentifier);
        if (isEntityOpen) {
          return false;
        }
        return result;
      }, true);

      const open = () => {
        dispatch(
          openEntityCollection({
            systemShortName,
            staffId,
            entityIdentifiers,
          })
        );
      };

      const canClose = entityIdentifiers.reduce((result, entityIdentifier) => {
        const isEntityOpen = selectIsEntityOpenHelper(entityIdentifier);
        if (!isEntityOpen) {
          return false;
        }
        return result;
      }, true);

      const close = () => {
        dispatch(
          closeEntityCollection({
            systemShortName,
            staffId,
            entityIdentifiers,
          })
        );
      };

      return {
        canOpen,
        open,
        canClose,
        close,
      };
    },
    [dispatch, selectIsEntityOpenHelper, staffId, systemShortName]
  );

  return { calculate };
};

export default useEntityCollectionOpenClose;
