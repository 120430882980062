import React, { useContext } from "react";
import { TrainingSessionInterface } from "module/training/types";
import TrainingSessionContext from "module/trainingSession/context/TrainingSessionContext";
import TrainingSessionTableRowCategoryContainer from "module/trainingSession/enrichedComponent/tableRow/category/TrainingSessionTableRowCategoryContainer";
import TrainingSessionTableRowItemContainer from "module/trainingSession/enrichedComponent/tableRow/item/TrainingSessionTableRowItemContainer";

const TrainingSessionTableRowContainer: React.FC = () => {
  const { entity } = useContext(TrainingSessionContext);

  const trainingSession = entity as TrainingSessionInterface;

  return trainingSession.isCategory ? (
    <TrainingSessionTableRowCategoryContainer />
  ) : (
    <TrainingSessionTableRowItemContainer />
  );
};

export default React.memo(TrainingSessionTableRowContainer);
