import React from "react";
import { EntityValidationResultContextInterface } from "module/entity/context/validationResult/EntityValidationResultContext";

export type TrainingCustomAssigneeValidationResultContextInterface =
  EntityValidationResultContextInterface;

const TrainingCustomAssigneeValidationResultContext = React.createContext(
  <TrainingCustomAssigneeValidationResultContextInterface>{}
);

export default TrainingCustomAssigneeValidationResultContext;
