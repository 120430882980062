import { MaintenanceTaskType } from "module/maintenanceTask/types";
import t from "module/translations";

export const getTypeTranslation = (type: MaintenanceTaskType): string =>
  ({
    [MaintenanceTaskType.preventive]: t.maintenanceTask.type.preventive,
    [MaintenanceTaskType.corrective]: t.maintenanceTask.type.corrective,
    [MaintenanceTaskType.failure]: t.maintenanceTask.type.failure,
    [MaintenanceTaskType.carp]: t.maintenanceTask.type.carp,
    [MaintenanceTaskType.modification]: t.maintenanceTask.type.modification,
    [MaintenanceTaskType.ppeInspection]: t.maintenanceTask.type.ppeInspection,
    [MaintenanceTaskType.eqInspection]: t.maintenanceTask.type.eqInspection,
  }[type]);
