import React from "react";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import { Theme } from "@mui/material/styles";
import createStyles from "@mui/styles/createStyles";
import makeStyles from "@mui/styles/makeStyles";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    icon: {
      minWidth: 0,
      marginRight: "10px",
    },
    text: {
      color: theme.palette.grey.A400,
    },
  })
);

interface Props {
  name?: string;
  icon?: JSX.Element;
  children?: React.ReactNode;
}

const SideMenuSection: React.FC<Props> = ({ name, icon, children }) => {
  const identifier = `${name}-section`;
  const classes = useStyles();
  return (
    <List aria-labelledby={identifier} component="nav">
      {name && (
        <ListItem>
          {icon && <ListItemIcon className={classes.icon}>{icon}</ListItemIcon>}
          <ListItemText primary={name} className={classes.text} />
        </ListItem>
      )}
      {children}
    </List>
  );
};

export default React.memo(SideMenuSection);
