import React from "react";
import makeStyles from "@mui/styles/makeStyles";

const useStyles = makeStyles(() => ({
  grow: {
    flexGrow: 1,
  },
}));

const FlexGrow: React.FC = () => {
  const classes = useStyles();
  return <div className={classes.grow} />;
};

export default React.memo(FlexGrow);
