import React from "react";
import { EntityContextInterface } from "module/entity/context/entity/EntityContext";

export type TrainingCustomContextInterface = EntityContextInterface;

const TrainingCustomContext = React.createContext(
  <TrainingCustomContextInterface>{}
);

export default TrainingCustomContext;
