import routes from "routes/routes";

export const getPathToAssessmentDetails = (id: string): string =>
  routes.myAssessmentDetails.path.replace(":assessmentId", id);

export const getPathToAssessmentDetailsOverview = (id: string): string =>
  routes.myAssessmentDetailsOverview.path.replace(":assessmentId", id);

export const getPathToAssessmentDetailsObjectives = (id: string): string =>
  routes.myAssessmentDetailsObjectives.path.replace(":assessmentId", id);

export const getPathToAssessmentToBeSignedDetails = (id: string): string =>
  routes.assessmentToBeSignedDetails.path.replace(":assessmentId", id);

export const getPathToAssessmentToBeSignedDetailsOverview = (
  id: string
): string =>
  routes.assessmentToBeSignedDetailsOverview.path.replace(":assessmentId", id);

export const getPathToAssessmentToBeSignedDetailsObjectives = (
  id: string
): string =>
  routes.assessmentToBeSignedDetailsObjectives.path.replace(
    ":assessmentId",
    id
  );
