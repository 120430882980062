import React from "react";
import { useStickyStyles } from "component/sticky/styles";

interface Props {
  children: React.ReactNode;
}

const StickyDiv: React.FC<Props> = ({ children }) => {
  const stickyClasses = useStickyStyles();
  return <div className={stickyClasses.sticky}>{children}</div>;
};

export default StickyDiv;
