import React from "react";
import LabelField from "component/labelField/LabelField";
import t from "module/translations";
import { AssessmentState } from "module/assessment/types";
import { getAssessmentStateTranslation } from "module/assessment/helper/getAssessmentStateTranslation";

interface Props {
  state: AssessmentState;
}

const AssessmentNameLabelField: React.FC<Props> = ({ state }) => {
  return (
    <LabelField
      {...{
        caption: t.competencyManagement.assessment.stateText,
        label: getAssessmentStateTranslation(state),
      }}
    />
  );
};

export default React.memo(AssessmentNameLabelField);
