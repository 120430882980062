import React from "react";
import { useAppSelector } from "redux/hooks";
import selectEntity from "module/staff/redux/selector/selectEntity";
import { EntityIdentifier, MyEntityInterface } from "module/entity/types";
import { EntityContextInterface } from "module/entity/context/entity/EntityContext";
import EntityContextProvider from "module/entity/context/entity/EntityContextProvider";

interface Props {
  entityIdentifier: EntityIdentifier;
  context: React.Context<EntityContextInterface>;
  children: React.ReactNode;
}

const MyEntityContextProvider: React.FC<Props> = ({
  entityIdentifier,
  context,
  children,
}) => {
  const selectEntityHelper = useAppSelector(selectEntity);
  const myEntity = selectEntityHelper(entityIdentifier) as MyEntityInterface;
  if (myEntity == null) {
    return null;
  }
  const { data: entity } = myEntity;

  return (
    <EntityContextProvider {...{ entity, context }}>
      {children}
    </EntityContextProvider>
  );
};

export default MyEntityContextProvider;
