import React, { useContext, useMemo } from "react";
import TableCell from "@mui/material/TableCell";
import Checkbox from "@mui/material/Checkbox";
import ToggleContext from "module/toggle/context/ToggleContext";
import InfoIconWithReason from "module/block/InfoIconWithReason";
import { EntityType } from "module/entity/types";
import InventoryCheckContext from "module/inventoryCheck/context/InventoryCheckContext";
import { InventoryCheckInterface } from "module/inventoryCheck/types";
import useGetInventoryCheckBlockedReasonText from "module/inventoryCheck/block/useGetInventoryCheckBlockedReasonText";

const InventoryCheckListTableRowSelectTableCellContainer: React.FC = () => {
  const { entity } = useContext(InventoryCheckContext);
  const { isSelected: isSelectedHelper } = useContext(ToggleContext);

  const inventoryCheck = entity as InventoryCheckInterface;

  const isBlockedReasonText =
    useGetInventoryCheckBlockedReasonText(inventoryCheck);

  const isSelected = useMemo(
    () =>
      isSelectedHelper({
        id: inventoryCheck.id,
        type: EntityType.inventoryCheck,
      }),
    [isSelectedHelper, inventoryCheck.id]
  );

  return (
    <TableCell padding="checkbox">
      {isBlockedReasonText ? (
        <InfoIconWithReason {...{ reason: isBlockedReasonText as string }} />
      ) : (
        <Checkbox checked={isSelected} />
      )}
    </TableCell>
  );
};

export default InventoryCheckListTableRowSelectTableCellContainer;
