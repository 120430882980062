import { createNestedPropertyIfNotExist } from "toolkit/createNestedPropertyIfNotExist";
import {
  MaintenanceTaskInterface,
  MaintenanceTaskType,
} from "module/maintenanceTask/types";
import { XValidatorErrorsInterface } from "module/validation/types";
import { XRequiredValidator } from "module/validation/validator/requiredValidator";
import {
  MaintenanceTaskProcessStepCheckResult,
  MaintenanceTaskProcessStepInterface,
} from "module/maintenanceTaskProcessStep/types";
import { isStateCompletedOrHigher as isStateCompletedOrHigherHelper } from "module/maintenanceTask/helper/isStateCompletedOrHigher";
import { XRangeValidator } from "module/validation/validator/rangeValidator";
import {
  MAINTENANCE_TASK_PROCESS_STEP_CHECK_RESULT_FIELD_NAME,
  MAINTENANCE_TASK_PROCESS_STEP_MEASURE_VALUE_FIELD_NAME,
  MAINTENANCE_TASK_PROCESS_STEP_REMARKS_FIELD_NAME,
} from "module/maintenanceTaskProcessStep/constants";
import { isValueInRange } from "toolkit/isValueInRange";

export interface MaintenanceTaskValidationResult {
  errors: null | XValidatorErrorsInterface;
  warnings: null | XValidatorErrorsInterface;
}

export const validateMaintenanceTaskProcessStep = (
  maintenanceTask: MaintenanceTaskInterface,
  maintenanceTaskProcessStep: MaintenanceTaskProcessStepInterface
): MaintenanceTaskValidationResult => {
  let errors: XValidatorErrorsInterface = {};
  let warnings: XValidatorErrorsInterface = {};

  const isStateCompletedOrHigher =
    isStateCompletedOrHigherHelper(maintenanceTask);

  if (maintenanceTask.type === MaintenanceTaskType.preventive) {
    if (
      isStateCompletedOrHigher &&
      maintenanceTaskProcessStep.measureName != null &&
      maintenanceTaskProcessStep.measureValue == null &&
      maintenanceTaskProcessStep.checkResult !==
        MaintenanceTaskProcessStepCheckResult.not_set
    ) {
      errors = createNestedPropertyIfNotExist(
        errors,
        MAINTENANCE_TASK_PROCESS_STEP_MEASURE_VALUE_FIELD_NAME,
        []
      );
      errors[MAINTENANCE_TASK_PROCESS_STEP_MEASURE_VALUE_FIELD_NAME].push(
        new XRequiredValidator().message
      );
    }

    if (
      maintenanceTaskProcessStep.measureValue != null &&
      !isValueInRange(
        maintenanceTaskProcessStep.measureValue,
        maintenanceTaskProcessStep.measureValueMin,
        maintenanceTaskProcessStep.measureValueMax
      )
    ) {
      if (
        isStateCompletedOrHigher &&
        maintenanceTaskProcessStep.checkResult ===
          MaintenanceTaskProcessStepCheckResult.good_yes
      ) {
        errors = createNestedPropertyIfNotExist(
          errors,
          MAINTENANCE_TASK_PROCESS_STEP_MEASURE_VALUE_FIELD_NAME,
          []
        );
        errors[MAINTENANCE_TASK_PROCESS_STEP_MEASURE_VALUE_FIELD_NAME].push(
          new XRangeValidator({
            min: maintenanceTaskProcessStep.measureValueMin,
            max: maintenanceTaskProcessStep.measureValueMax,
          }).message
        );
      } else {
        warnings = createNestedPropertyIfNotExist(
          warnings,
          MAINTENANCE_TASK_PROCESS_STEP_MEASURE_VALUE_FIELD_NAME,
          []
        );
        warnings[MAINTENANCE_TASK_PROCESS_STEP_MEASURE_VALUE_FIELD_NAME].push(
          new XRangeValidator({
            min: maintenanceTaskProcessStep.measureValueMin,
            max: maintenanceTaskProcessStep.measureValueMax,
          }).message
        );
      }
    }

    if (
      isStateCompletedOrHigher &&
      [
        MaintenanceTaskProcessStepCheckResult.not_set,
        MaintenanceTaskProcessStepCheckResult.good_no,
      ].includes(maintenanceTaskProcessStep.checkResult) &&
      (maintenanceTaskProcessStep.remarks == null ||
        maintenanceTaskProcessStep.remarks.length === 0)
    ) {
      errors = createNestedPropertyIfNotExist(
        errors,
        MAINTENANCE_TASK_PROCESS_STEP_REMARKS_FIELD_NAME,
        []
      );
      errors[MAINTENANCE_TASK_PROCESS_STEP_REMARKS_FIELD_NAME].push(
        new XRequiredValidator().message
      );
    }
  }

  if (
    [
      MaintenanceTaskType.corrective,
      MaintenanceTaskType.carp,
      MaintenanceTaskType.modification,
      MaintenanceTaskType.failure,
    ].includes(maintenanceTask.type)
  ) {
    if (
      isStateCompletedOrHigher &&
      maintenanceTaskProcessStep.measureName != null &&
      maintenanceTaskProcessStep.measureValue == null
    ) {
      errors = createNestedPropertyIfNotExist(
        errors,
        MAINTENANCE_TASK_PROCESS_STEP_MEASURE_VALUE_FIELD_NAME,
        []
      );
      errors[MAINTENANCE_TASK_PROCESS_STEP_MEASURE_VALUE_FIELD_NAME].push(
        new XRequiredValidator().message
      );
    }

    if (
      maintenanceTaskProcessStep.measureValue != null &&
      !isValueInRange(
        maintenanceTaskProcessStep.measureValue,
        maintenanceTaskProcessStep.measureValueMin,
        maintenanceTaskProcessStep.measureValueMax
      )
    ) {
      if (isStateCompletedOrHigher) {
        errors = createNestedPropertyIfNotExist(
          errors,
          MAINTENANCE_TASK_PROCESS_STEP_MEASURE_VALUE_FIELD_NAME,
          []
        );
        errors[MAINTENANCE_TASK_PROCESS_STEP_MEASURE_VALUE_FIELD_NAME].push(
          new XRangeValidator({
            min: maintenanceTaskProcessStep.measureValueMin,
            max: maintenanceTaskProcessStep.measureValueMax,
          }).message
        );
      } else {
        warnings = createNestedPropertyIfNotExist(
          warnings,
          MAINTENANCE_TASK_PROCESS_STEP_MEASURE_VALUE_FIELD_NAME,
          []
        );
        warnings[MAINTENANCE_TASK_PROCESS_STEP_MEASURE_VALUE_FIELD_NAME].push(
          new XRangeValidator({
            min: maintenanceTaskProcessStep.measureValueMin,
            max: maintenanceTaskProcessStep.measureValueMax,
          }).message
        );
      }
    }

    if (
      isStateCompletedOrHigher &&
      MaintenanceTaskProcessStepCheckResult.not_set ===
        maintenanceTaskProcessStep.checkResult
    ) {
      errors = createNestedPropertyIfNotExist(
        errors,
        MAINTENANCE_TASK_PROCESS_STEP_CHECK_RESULT_FIELD_NAME,
        []
      );
      errors[MAINTENANCE_TASK_PROCESS_STEP_CHECK_RESULT_FIELD_NAME].push(
        new XRequiredValidator().message
      );
    }
  }

  return {
    errors: Object.keys(errors).length ? errors : null,
    warnings: Object.keys(warnings).length ? warnings : null,
  };
};
