import {
  MaintenanceTaskInterface,
  MaintenanceTaskState,
} from "module/maintenanceTask/types";

export const isStateScheduledOrLower = (
  maintenanceTask: MaintenanceTaskInterface
): boolean =>
  [
    MaintenanceTaskState.initScheduled,
    MaintenanceTaskState.requested,
    MaintenanceTaskState.scheduled,
  ].includes(maintenanceTask.state);
