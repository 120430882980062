import { PayloadAction } from "@reduxjs/toolkit";
import { StaffDataState } from "module/staff/redux/types";
import { EntityIdentifier } from "module/entity/types";
import _ from "lodash";

interface CloseEntityCollectionActionTypeProps {
  staffId: number;
  systemShortName: string;
  entityIdentifiers: EntityIdentifier[];
}

const closeEntityAction = (
  state: StaffDataState,
  action: PayloadAction<CloseEntityCollectionActionTypeProps>
): void => {
  const { staffId, systemShortName, entityIdentifiers } = action.payload;

  // eslint-disable-next-line array-callback-return
  entityIdentifiers.map((entityIdentifier) => {
    state[staffId][systemShortName].openEntityIdentifiers = state[staffId][
      systemShortName
    ].openEntityIdentifiers.filter(
      (item) => !_.isEqual(item, entityIdentifier)
    );
  });
};

export default closeEntityAction;
