import React from "react";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import t from "module/translations";
import { useDownloadedMaintenanceTaskDetailsPersonnelAssignmentAssistantStyles } from "pages/myMaintenanceTaskDetails/personnelAssignment/assistant/styles";

interface Props {
  isActionAvailable: boolean;
}

const MaintenanceTaskAssistantTableHead: React.FC<Props> = ({
  isActionAvailable,
}) => {
  const classes =
    useDownloadedMaintenanceTaskDetailsPersonnelAssignmentAssistantStyles();
  return (
    <TableHead>
      <TableRow>
        <TableCell className={classes.type} />
        <TableCell>{t.maintenanceTask.personnelAssignments.nameText}</TableCell>
        <TableCell>
          {t.maintenanceTask.personnelAssignments.positionOrCompanyText}
        </TableCell>
        {isActionAvailable && <TableCell />}
      </TableRow>
    </TableHead>
  );
};

export default React.memo(MaintenanceTaskAssistantTableHead);
