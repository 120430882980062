import React, { useContext } from "react";
import TrainingCustomContext from "module/trainingCustom/context/TrainingCustomContext";
import useMyTrainingCustomState from "module/trainingCustom/state/useMyTrainingCustomState";
import { TrainingCustomInterface } from "module/trainingCustom/types";
import TrainingCustomStateContext, {
  TrainingCustomStateContextInterface,
} from "module/trainingCustom/state/context/TrainingCustomStateContext";

interface Prop {
  children: React.ReactNode;
}

const MyTrainingCustomStateContextProvider: React.FC<Prop> = ({ children }) => {
  const { entity } = useContext(TrainingCustomContext);

  const {
    isStateScheduledOrLower,
    isEditable: isStateEditable,
    options: stateOptions,
  } = useMyTrainingCustomState(entity as TrainingCustomInterface);

  const contextValue: TrainingCustomStateContextInterface = {
    isStateScheduledOrLower,
    isStateEditable,
    stateOptions,
  };

  return (
    <TrainingCustomStateContext.Provider value={contextValue}>
      {children}
    </TrainingCustomStateContext.Provider>
  );
};

export default MyTrainingCustomStateContextProvider;
