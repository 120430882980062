import React, { useContext, useCallback } from "react";
import t from "module/translations";
import FilterContext from "module/filter/context/FilterContext";
import TextField from "../../../../component/textField/TextField";

const StockArticleListToolbarFilterExternalIdContainer: React.FC = () => {
  const { filters, changeFilters } = useContext(FilterContext);
  const change = useCallback(
    (externalId: string | undefined) => {
      changeFilters({
        ...filters,
        externalId,
      });
    },
    [changeFilters, filters]
  );

  return (
    <TextField
      {...{
        label: t.stockArticle.externalIdText,
        change,
        value: filters.externalId as string,
        errors: [],
      }}
    />
  );
};

export default React.memo(StockArticleListToolbarFilterExternalIdContainer);
