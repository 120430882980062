import React from "react";
import { EntityFieldMetaDataContextInterface } from "module/entity/context/fieldMetaData/EntityFieldMetaDataContext";

export type MaintenanceTaskPartFieldMetaDataContextInterface =
  EntityFieldMetaDataContextInterface;

const MaintenanceTaskPartFieldMetaDataContext = React.createContext(
  <MaintenanceTaskPartFieldMetaDataContextInterface>{}
);

export default MaintenanceTaskPartFieldMetaDataContext;
