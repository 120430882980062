import React from "react";
import { EntityIdentifier } from "module/entity/types";
import MyEntityContextProvider from "module/entity/context/entity/MyEntityContextProvider";
import MyEntityValidationResultContextProvider from "module/entity/context/validationResult/MyEntityValidationResultContextProvider";
import MyEntityChangeContextProvider from "module/entity/context/change/MyEntityChangeContextProvider";
import InventoryCheckContext from "module/inventoryCheck/context/InventoryCheckContext";
import InventoryCheckValidationResultContext from "module/inventoryCheck/validation/InventoryCheckValidationResultContext";
import MyInventoryCheckOpenCloseContextProvider from "module/inventoryCheck/openClose/context/MyInventoryCheckOpenCloseContextProvider";
import MyInventoryCheckStateContextProvider from "module/inventoryCheck/state/context/MyInventoryCheckStateContextProvider";
import MyInventoryCheckFieldMetaDataContextProvider from "module/inventoryCheck/validation/fieldMetaData/MyInventoryCheckFieldMetaDataContextProvider";
import InventoryCheckChangeContext from "module/inventoryCheck/change/InventoryCheckChangeContext";

interface Props {
  entityIdentifier: EntityIdentifier;
  children: React.ReactNode;
}

const MyInventoryCheckDetailsContextProvider: React.FC<Props> = ({
  entityIdentifier,
  children,
}) => {
  return (
    <MyEntityContextProvider
      {...{ entityIdentifier, context: InventoryCheckContext }}
    >
      <MyEntityValidationResultContextProvider
        {...{
          entityIdentifier,
          context: InventoryCheckValidationResultContext,
        }}
      >
        <MyInventoryCheckOpenCloseContextProvider>
          <MyInventoryCheckStateContextProvider>
            <MyInventoryCheckFieldMetaDataContextProvider>
              <MyEntityChangeContextProvider
                {...{ entityIdentifier, context: InventoryCheckChangeContext }}
              >
                {children}
              </MyEntityChangeContextProvider>
            </MyInventoryCheckFieldMetaDataContextProvider>
          </MyInventoryCheckStateContextProvider>
        </MyInventoryCheckOpenCloseContextProvider>
      </MyEntityValidationResultContextProvider>
    </MyEntityContextProvider>
  );
};

export default React.memo(MyInventoryCheckDetailsContextProvider);
