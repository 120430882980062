import React, { useContext, useCallback } from "react";
import t from "module/translations";
import { ASSESSMENT_REMARKS_FIELD_NAME } from "module/assessment/constants";
import TextareaField from "component/textareaField/TextareaField";
import XLabelField from "component/labelField/XLabelField";
import { AssessmentInterface } from "module/assessment/types";
import AssessmentContext from "module/assessment/context/AssessmentContext";
import AssessmentChangeContext from "module/assessment/change/AssessmentChangeContext";
import AssessmentFieldMetaDataContext from "module/assessment/validation/fieldMetaData/AssessmentFieldMetaDataContext";
import { getFieldMetaDataWithErrorHandling } from "module/fieldMetaData/getFieldMetaData";

const MyAssessmentRemarksFieldContainer: React.FC = () => {
  const { entity } = useContext(AssessmentContext);
  const { change: changeHelper } = useContext(AssessmentChangeContext);
  const { getFieldMetaData } = useContext(AssessmentFieldMetaDataContext);

  const assessment = entity as AssessmentInterface;

  const change = useCallback(
    (remarks?: string) => {
      changeHelper({ remarks });
    },
    [changeHelper]
  );

  const { warnings, errors, isEditable } = getFieldMetaDataWithErrorHandling(
    () => getFieldMetaData(ASSESSMENT_REMARKS_FIELD_NAME)
  );

  const label = t.competencyManagement.assessment.remarksText;

  return isEditable ? (
    <TextareaField
      {...{
        value: assessment.remarks,
        change,
        label,
        errors,
      }}
    />
  ) : (
    <XLabelField
      {...{ caption: label, label: assessment.remarks, errors, warnings }}
    />
  );
};

export default React.memo(MyAssessmentRemarksFieldContainer);
