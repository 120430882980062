import React, { useEffect } from "react";
import AuthLoginPage from "module/auth/login/AuthLoginPage";
import useAuth from "module/auth/useAuth";

const AuthLoginPageContainer: React.FC = () => {
  const { handleLogin, redirectToLogin } = useAuth();

  useEffect(() => {
    handleLogin();
  }, [handleLogin]);

  return <AuthLoginPage {...{ login: redirectToLogin }} />;
};

export default React.memo(AuthLoginPageContainer);
