import React, { useMemo } from "react";
import makeStyles from "@mui/styles/makeStyles";
import TableCell from "@mui/material/TableCell";
import IconButton from "@mui/material/IconButton";
import TableRow from "@mui/material/TableRow";
import useDateFormat from "toolkit/useDateFormat";
import SubdirectoryArrowRightIcon from "@mui/icons-material/SubdirectoryArrowRight";
import t from "module/translations";
import LinkWithIcon from "component/link/LinkWithIcon";
import { getPathToMaintenanceTaskDetails } from "module/maintenanceTask/helper/getPath";
import { MaintenanceTaskInterface } from "module/maintenanceTask/types";
import { getMaintenanceTaskStateTranslation } from "module/maintenanceTask/helper/getMaintenanceTaskStateTranslation";
import { getTypeTranslation } from "module/maintenanceTask/helper/getTypeTranslation";

const useStyles = makeStyles({
  tr: {
    background: "#f7f5f5",
  },
});

interface Props {
  maintenanceTask: MaintenanceTaskInterface;
  isSelected: boolean;
  originalMaintenanceTask: MaintenanceTaskInterface;
}

const MyMaintenanceTaskCreatedForOtherMaintenanceTaskProcessStepListTableRow: React.FC<Props> =
  ({ maintenanceTask, isSelected }) => {
    const classes = useStyles();
    const { formatDateTime } = useDateFormat();
    const reportingNumber = useMemo(() => {
      let result = maintenanceTask.reportingNumber;

      if (
        !maintenanceTask.reportingNumber &&
        maintenanceTask.source === "template"
      ) {
        result = t.maintenanceTask.fromTemplateText;
      }

      return `${result} (${t.maintenanceTask.createdForMaintenanceTaskProcessStepText})`;
    }, [maintenanceTask.source, maintenanceTask.reportingNumber]);

    return (
      <TableRow
        {...{
          hover: true,
          tabIndex: -1,
          role: "checkbox",
          "aria-checked": isSelected,
          selected: isSelected,
          key: maintenanceTask.id,
          className: classes.tr,
        }}
      >
        <TableCell>
          <IconButton aria-label="expand row" size="small">
            <SubdirectoryArrowRightIcon />
          </IconButton>
        </TableCell>
        <TableCell align="left">{reportingNumber}</TableCell>
        <TableCell align="left">
          <LinkWithIcon
            {...{
              label: maintenanceTask.name,
              path: getPathToMaintenanceTaskDetails(maintenanceTask.id),
            }}
          />
        </TableCell>
        <TableCell align="left">
          {getTypeTranslation(maintenanceTask.type)}
        </TableCell>
        <TableCell align="left">
          {maintenanceTask.scheduledStartDate
            ? formatDateTime(maintenanceTask.scheduledStartDate)
            : "-"}
        </TableCell>
        <TableCell align="left">{maintenanceTask.window}</TableCell>
        <TableCell align="left">
          {maintenanceTask.timeDeviation || "-"}
        </TableCell>
        <TableCell align="left">
          {getMaintenanceTaskStateTranslation(maintenanceTask.state)}
        </TableCell>
        <TableCell align="right" />
      </TableRow>
    );
  };
export default React.memo(
  MyMaintenanceTaskCreatedForOtherMaintenanceTaskProcessStepListTableRow
);
