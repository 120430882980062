import React from "react";

export interface FiltersInterface {
  [key: string]: unknown;
}

export interface FilterContextInterface {
  filters: FiltersInterface;
  changeFilters: (changes: FiltersInterface) => void;
}

const FilterContext = React.createContext(<FilterContextInterface>{});

export default FilterContext;
