import React, { useContext, useMemo, useCallback } from "react";
import AutocompleteField from "component/autocompleteField/AutocompleteField";
import t from "module/translations";
import { useAppSelector } from "redux/hooks";
import { selectMaintenanceTaskProcessStepQuickFixTypes } from "module/auth/redux/authSlice";
import MaintenanceTaskProcessStepQuickFixContext from "module/maintenanceTaskProcessStepQuickFix/context/MaintenanceTaskProcessStepQuickFixContext";
import MaintenanceTaskProcessStepQuickFixChangeContext from "module/maintenanceTaskProcessStepQuickFix/change/MaintenanceTaskProcessStepQuickFixChangeContext";
import MaintenanceTaskProcessStepQuickFixFieldMetaDataContext from "module/maintenanceTaskProcessStepQuickFix/validation/fieldMetaData/MaintenanceTaskProcessStepQuickFixFieldMetaDataContext";
import { getFieldMetaDataWithErrorHandling } from "module/fieldMetaData/getFieldMetaData";
import XLabelField from "component/labelField/XLabelField";
import { MaintenanceTaskProcessStepQuickFixInterface } from "module/maintenanceTaskProcessStepQuickFix/types";
import { MAINTENANCE_TASK_PROCESS_STEP_QUICK_FIX_TYPE_FIELD_NAME } from "module/maintenanceTaskProcessStepQuickFix/constants";
import { AutocompleteOption } from "component/autocompleteField/type";

const MaintenanceTaskProcessStepQuickFixTypeFieldContainer: React.FC = () => {
  const { entity } = useContext(MaintenanceTaskProcessStepQuickFixContext);
  const quickFix = entity as MaintenanceTaskProcessStepQuickFixInterface;
  const { change: changeHelper } = useContext(
    MaintenanceTaskProcessStepQuickFixChangeContext
  );
  const { getFieldMetaData } = useContext(
    MaintenanceTaskProcessStepQuickFixFieldMetaDataContext
  );

  const types = useAppSelector(selectMaintenanceTaskProcessStepQuickFixTypes);

  const value = quickFix?.type;

  const { warnings, errors, isEditable } = getFieldMetaDataWithErrorHandling(
    () =>
      getFieldMetaData(MAINTENANCE_TASK_PROCESS_STEP_QUICK_FIX_TYPE_FIELD_NAME)
  );

  const options: AutocompleteOption[] = useMemo(
    () => Object.values(types),
    [types]
  );
  const selected = useMemo(
    () => (value ? types[value] : undefined),
    [types, value]
  );

  const change = useCallback(
    (newValue: unknown) => {
      changeHelper({ type: newValue as string });
    },
    [changeHelper]
  );

  const title = t.maintenanceTask.processStep.quickFixText;

  if (isEditable) {
    return (
      <AutocompleteField
        {...{
          errors,
          options,
          selected,
          onChange: change,
          placeholder: t.pleaseSelectText,
          label: title,
        }}
      />
    );
  }

  return (
    <XLabelField
      {...{
        caption: title,
        label: value,
        errors,
        warnings,
      }}
    />
  );
};

export default React.memo(MaintenanceTaskProcessStepQuickFixTypeFieldContainer);
