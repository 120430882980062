import React from "react";
import { EntityStateContextInterface } from "module/entity/context/state/EntityStateContext";

export type TrainingStateContextInterface = EntityStateContextInterface;

const TrainingStateContext = React.createContext(
  <TrainingStateContextInterface>{}
);

export default TrainingStateContext;
