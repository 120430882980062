import React, { useState, useMemo, useCallback } from "react";
import { useHistory } from "react-router-dom";
import { MaintenanceTaskType } from "module/maintenanceTask/types";
import StickyPaper from "component/sticky/StickyPaper";
import Grid from "@mui/material/Grid";
import AutocompleteField from "component/autocompleteField/AutocompleteField";
import t from "module/translations";
import { useAppSelector } from "redux/hooks";
import { selectMaintenanceTaskTemplates } from "module/auth/redux/authSlice";
import SelectField from "component/selectField/SelectField";
import { getTypeTranslation } from "module/maintenanceTask/helper/getTypeTranslation";
import { getPathToMaintenanceTaskDetails } from "module/maintenanceTask/helper/getPath";
import ButtonsContainer from "component/button/ButtonsContainer";
import GenerateButton from "component/button/GenerateButton";
import useMaintenanceTaskCreateFromTemplate from "module/maintenanceTask/template/useMaintenanceTaskCreateFromTemplate";
import useMaintenanceTaskTemplateOptions from "../../module/maintenanceTaskTemplate/options/useMaintenanceTaskTemplateOptions";

const MaintenanceTaskFromTemplatePage: React.FC = () => {
  const { options: templateOptions } = useMaintenanceTaskTemplateOptions();
  const templates = useAppSelector(selectMaintenanceTaskTemplates);
  const [templateId, setTemplateId] = useState<number | undefined>(undefined);
  const [type, setType] = useState<MaintenanceTaskType>(
    MaintenanceTaskType.preventive
  );
  const { create: createFromTemplate } = useMaintenanceTaskCreateFromTemplate();
  const history = useHistory();

  const onTemplateChange = useCallback((id: unknown) => {
    setTemplateId(id as number);
  }, []);

  const selectedTemplate = useMemo(
    () =>
      templates && Object.keys(templates).length && templateId
        ? {
            text: templates[templateId].name,
            value: templates[templateId].id,
          }
        : undefined,
    [templateId, templates]
  );

  const typeOptions = [
    {
      value: MaintenanceTaskType.preventive,
      text: getTypeTranslation(MaintenanceTaskType.preventive),
    },
    {
      value: MaintenanceTaskType.corrective,
      text: getTypeTranslation(MaintenanceTaskType.corrective),
    },
  ];

  const onTypeChange = useCallback((newType: unknown) => {
    setType(newType as MaintenanceTaskType);
  }, []);

  const onGenerate = useCallback(() => {
    if (templateId) {
      const id = createFromTemplate(templateId, type);
      history.push(getPathToMaintenanceTaskDetails(id));
    }
  }, [createFromTemplate, history, templateId, type]);

  return (
    <>
      <StickyPaper>
        <Grid container spacing={3}>
          <Grid item xs={6}>
            <AutocompleteField
              {...{
                label: t.maintenanceTask.templateText,
                options: templateOptions,
                onChange: onTemplateChange,
                selected: selectedTemplate,
              }}
            />
          </Grid>
          <Grid item xs={6}>
            <SelectField
              {...{
                label: t.maintenanceTask.typeText,
                options: typeOptions,
                change: onTypeChange,
                value: type,
              }}
            />
          </Grid>
        </Grid>
      </StickyPaper>
      <ButtonsContainer>
        {templateId && type && <GenerateButton onClick={onGenerate} />}
      </ButtonsContainer>
    </>
  );
};

export default React.memo(MaintenanceTaskFromTemplatePage);
