import { useCallback } from "react";
import { useAppDispatch, useAppSelector } from "redux/hooks";
import { v4 as uuid } from "uuid";
import { selectSystem, selectUser } from "module/auth/redux/authSlice";
import usePermissions from "module/permission/usePermissions";
import { createEntity } from "module/staff/redux/staffDataSlice";
import { EntityType } from "module/entity/types";
import { API_DATE_TIME_FORMAT } from "api/date";
import moment from "moment";
import {
  MaintenanceTaskInterface,
  MaintenanceTaskState,
  MaintenanceTaskType,
  MyMaintenanceTaskInterface,
} from "module/maintenanceTask/types";
import useMyEntityValidation from "module/entity/useMyEntityValidation";

interface Result {
  create: () => string;
  canCreate: boolean;
}

const useMaintenanceTaskCorrectiveCreate = (): Result => {
  const dispatch = useAppDispatch();
  const staffId = useAppSelector(selectUser).id;
  const systemShortName = useAppSelector(selectSystem).shortName;
  const { canScheduleMaintenanceTask } = usePermissions();
  const canCreate = canScheduleMaintenanceTask;
  const { validateMyEntities } = useMyEntityValidation();

  const create = useCallback((): string => {
    const id = uuid();
    const today = moment()
      .set({ hour: 0, minute: 0, second: 0 })
      .format(API_DATE_TIME_FORMAT);

    const data: MaintenanceTaskInterface = {
      source: "serviceRequest",
      attachments: [],
      expectedEndDate: today,
      expectedStartDate: today,
      isBlockedDetails: {
        isBlocked: false,
        isBlockedBecauseItIsValidated: false,
        blockedByStaffId: null,
        isBlockedBecauseIsOutOfPMWindow: false,
        isBlockedByMeOnThisDevice: false,
        isBlockedByMeOnOtherDevice: false,
        isBlockedByOtherStaff: false,
        isBlockedBecauseStaffIsNotTrained: false,
        isBlockedBecauseStaffIsNotTrainedMessage: null,
      },
      parts: [],
      personnelAssignments: [],
      processSteps: [],
      relationshipIdentifiers: [],
      relationships: undefined,
      scheduledEndDate: today,
      scheduledStartDate: today,
      state: MaintenanceTaskState.requested,
      type: MaintenanceTaskType.corrective,
      id,
      openCloseHistory: [],
    };

    const entityIdentifier = {
      type: EntityType.maintenanceTask,
      id,
    };

    const myEntity: MyMaintenanceTaskInterface = {
      entityIdentifier,
      data,
      dataWithoutChanges: data,
      validationErrors: null,
      validationWarnings: null,
    };

    dispatch(
      createEntity({
        staffId,
        systemShortName,
        myEntity,
      })
    );

    validateMyEntities([entityIdentifier]);

    return id;
  }, [dispatch, staffId, systemShortName, validateMyEntities]);

  return {
    canCreate,
    create,
  };
};

export default useMaintenanceTaskCorrectiveCreate;
