import { isStateScheduledOrLower } from "module/maintenanceTask/helper/isStateScheduledOrLower";
import { MaintenanceTaskInterface } from "module/maintenanceTask/types";
import { MaintenanceTaskPersonnelAssignmentInternalStaffInterface } from "module/maintenanceTaskPersonnelAssignment/internalStaff/types";

interface Props {
  isMaintenanceTaskOpen: boolean;
  canEditMaintenanceTask: boolean;
  maintenanceTask: MaintenanceTaskInterface;
  maintenanceTaskPersonnelAssignmentInternalStaff: MaintenanceTaskPersonnelAssignmentInternalStaffInterface;
  currentLoggedInStaffId: number;
}

export const canRemoveMaintenanceTaskPersonnelAssignmentInternalStaff = ({
  isMaintenanceTaskOpen,
  canEditMaintenanceTask,
  maintenanceTask,
  maintenanceTaskPersonnelAssignmentInternalStaff,
  currentLoggedInStaffId,
}: Props): boolean =>
  isMaintenanceTaskOpen &&
  canEditMaintenanceTask &&
  isStateScheduledOrLower(maintenanceTask) &&
  currentLoggedInStaffId !==
    maintenanceTaskPersonnelAssignmentInternalStaff.staffId;
