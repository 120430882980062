import React, { useContext } from "react";
import { getFieldMetaData as getFieldMetaDataHelper } from "module/fieldMetaData/getFieldMetaData";
import MaintenanceTaskValidationResultContext from "module/maintenanceTask/validation/MaintenanceTaskValidationResultContext";
import MaintenanceTaskOpenCloseContext from "module/maintenanceTask/openClose/context/MaintenanceTaskOpenCloseContext";
import MaintenanceTaskStateContext from "module/maintenanceTask/state/context/MaintenanceTaskStateContext";
import MyMaintenanceTaskFieldMetaDataContext, {
  MaintenanceTaskFieldMetaDataContextInterface,
} from "module/maintenanceTask/validation/fieldMetaData/MaintenanceTaskFieldMetaDataContext";
import MaintenanceTaskContext from "module/maintenanceTask/context/MaintenanceTaskContext";
import { MaintenanceTaskInterface } from "module/maintenanceTask/types";

interface Prop {
  children: React.ReactNode;
}

const MyMaintenanceTaskFieldMetaDataContextProvider: React.FC<Prop> = ({
  children,
}) => {
  const { validationWarnings, validationErrors } = useContext(
    MaintenanceTaskValidationResultContext
  );
  const { isOpen } = useContext(MaintenanceTaskOpenCloseContext);
  const { isStateScheduledOrLower } = useContext(MaintenanceTaskStateContext);

  const { entity } = useContext(MaintenanceTaskContext);
  const maintenanceTask = entity as MaintenanceTaskInterface;
  const isNew = maintenanceTask.source === "serviceRequest";

  const getFieldMetaData = getFieldMetaDataHelper({
    validationWarnings,
    validationErrors,
    isOpen,
    isStateScheduledOrLower,
    isNew,
  });

  const contextValue: MaintenanceTaskFieldMetaDataContextInterface = {
    getFieldMetaData,
  };

  return (
    <MyMaintenanceTaskFieldMetaDataContext.Provider value={contextValue}>
      {children}
    </MyMaintenanceTaskFieldMetaDataContext.Provider>
  );
};

export default MyMaintenanceTaskFieldMetaDataContextProvider;
