import { PayloadAction } from "@reduxjs/toolkit";
import { StaffDataState } from "module/staff/redux/types";
import { EntityIdentifier } from "module/entity/types";
import _ from "lodash";
import { getEntityIdentifierKey } from "module/entity/helper/getEntityIdentifierKey";
import * as Sentry from "@sentry/react";

interface RemoveFromMyEntitiesActionTypeProps {
  entityIdentifiers: EntityIdentifier[];
  staffId: number;
  systemShortName: string;
}

const removeFromMyEntitiesAction = (
  state: StaffDataState,
  action: PayloadAction<RemoveFromMyEntitiesActionTypeProps>
): void => {
  const { staffId, systemShortName, entityIdentifiers } = action.payload;

  if (systemShortName === "OAC") {
    Sentry.withScope((scope) => {
      scope.setLevel("info");

      scope.setExtra("entityIdentifiers", entityIdentifiers);
      Sentry.captureMessage("removeFromMyEntitiesAction");
    });
  }

  const removeMyEntity = (entityIdentifier: EntityIdentifier): void => {
    state[staffId][systemShortName].entities[
      getEntityIdentifierKey(entityIdentifier)
      // eslint-disable-next-line array-callback-return
    ].data.relationshipIdentifiers.map((relatedEntityIdentifier) => {
      removeMyEntity(relatedEntityIdentifier);
    });

    delete state[staffId][systemShortName].entities[
      getEntityIdentifierKey(entityIdentifier)
    ];
  };
  entityIdentifiers.map(removeMyEntity);

  state[staffId][systemShortName].openEntityIdentifiers = _.differenceWith(
    state[staffId][systemShortName].openEntityIdentifiers,
    entityIdentifiers,
    _.isEqual
  );
};

export default removeFromMyEntitiesAction;
