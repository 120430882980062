import { useContext, useMemo } from "react";
import usePermissions from "module/permission/usePermissions";
import { MaintenanceTaskInterface } from "module/maintenanceTask/types";
import MaintenanceTaskContext from "module/maintenanceTask/context/MaintenanceTaskContext";
import MaintenanceTaskOpenCloseContext from "module/maintenanceTask/openClose/context/MaintenanceTaskOpenCloseContext";
import { canAddMaintenanceTaskInternalPersonnelAssignment } from "module/maintenanceTaskPersonnelAssignment/internalStaff/helper/canAddMaintenanceTaskPersonnelAssignmentInternalStaff";

interface Result {
  canAdd: boolean;
}

const useMaintenanceTaskPersonnelAssignmentInternalStaffCanAdd = (): Result => {
  const { entity } = useContext(MaintenanceTaskContext);
  const maintenanceTask = entity as MaintenanceTaskInterface;

  const { isOpen } = useContext(MaintenanceTaskOpenCloseContext);

  const { canEditMaintenanceTask } = usePermissions();

  const canAdd = useMemo(
    () =>
      canAddMaintenanceTaskInternalPersonnelAssignment({
        isMaintenanceTaskOpen: isOpen,
        maintenanceTask,
        canEditMaintenanceTask,
      }),
    [canEditMaintenanceTask, isOpen, maintenanceTask]
  );

  return {
    canAdd,
  };
};

export default useMaintenanceTaskPersonnelAssignmentInternalStaffCanAdd;
