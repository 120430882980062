import { useCallback } from "react";
import { useAppSelector } from "redux/hooks";
import { EntityType } from "module/entity/types";
import selectEntityHelper from "module/staff/redux/selector/selectEntity";
import {
  AssessmentToSyncInterface,
  MyAssessmentInterface,
  MyAssessmentObjectiveInterface,
} from "module/assessment/types";
import { mapAssessmentToSync } from "module/assessment/sync/mapper";

interface Result {
  geAssessmentMappedToSync: (id: string) => AssessmentToSyncInterface;
}

const useAssessmentSyncHelper = (): Result => {
  const selectEntity = useAppSelector(selectEntityHelper);

  const geAssessmentMappedToSync = useCallback(
    (id: string): AssessmentToSyncInterface => {
      const myAssessment = selectEntity({
        id,
        type: EntityType.assessment,
      }) as MyAssessmentInterface;
      const objectives = myAssessment.data.objectives.map(
        (objectiveIdentifier) =>
          selectEntity(objectiveIdentifier) as MyAssessmentObjectiveInterface
      );

      return mapAssessmentToSync(myAssessment, objectives);
    },
    [selectEntity]
  );

  return {
    geAssessmentMappedToSync,
  };
};

export default useAssessmentSyncHelper;
