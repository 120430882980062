import React from "react";
import StickyPaper from "component/sticky/StickyPaper";
import Grid from "@mui/material/Grid";
import TrainingCustomNameFieldContainer from "module/trainingCustom/enrichedComponent/nameField/TrainingCustomNameFieldContainer";
import TrainingCustomTrainerFieldContainer from "module/trainingCustom/enrichedComponent/trainerField/TrainingCustomTrainerFieldContainer";
import TrainingCustomDescriptionFieldContainer from "module/trainingCustom/enrichedComponent/descriptionField/TrainingDescriptionFieldContainer";
import TrainingCustomRemarksFieldContainer from "module/trainingCustom/enrichedComponent/remarksField/TrainingRemarksFieldContainer";
import TrainingCustomCompletionDateFieldContainer from "module/trainingCustom/enrichedComponent/completionDateField/TrainingCustomCompletionDateFieldContainer";
import TrainingCustomStateLabelFieldContainer from "module/trainingCustom/enrichedComponent/stateField/TrainingCustomStateLabelFieldContainer";
import TrainingCustomScheduledDateFieldContainer from "module/trainingCustom/enrichedComponent/scheduledDateField/TrainingCustomScheduledDateFieldContainer";

const TrainingCustomToBeSignedDetailsOverview: React.FC = () => {
  return (
    <StickyPaper>
      <Grid container spacing={3}>
        <Grid item xs={6}>
          <TrainingCustomNameFieldContainer />
        </Grid>
        <Grid item xs={6}>
          <TrainingCustomTrainerFieldContainer />
        </Grid>
      </Grid>
      <TrainingCustomDescriptionFieldContainer />
      <TrainingCustomRemarksFieldContainer />
      <Grid container spacing={3}>
        <Grid item xs={6}>
          <TrainingCustomScheduledDateFieldContainer />
        </Grid>
        <Grid item xs={6}>
          <TrainingCustomCompletionDateFieldContainer />
        </Grid>
      </Grid>
      <TrainingCustomStateLabelFieldContainer />
    </StickyPaper>
  );
};

export default React.memo(TrainingCustomToBeSignedDetailsOverview);
