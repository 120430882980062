import React from "react";
import Avatar from "@mui/material/Avatar";
import { useAppSelector } from "redux/hooks";
import { selectUser } from "module/auth/redux/authSlice";
import { UserInterface } from "module/auth/types";
import getInitials from "layout/userAvatar/getInitials";

const UserAvatar: React.FC = () => {
  const user: UserInterface = useAppSelector(selectUser);

  return <Avatar>{getInitials(user.firstName, user.lastName)}</Avatar>;
};

export default React.memo(UserAvatar);
