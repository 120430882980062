import { MaintenanceTaskInterface } from "module/maintenanceTask/types";

export const isStaffResponsibleForFinalVerificationOfMaintenanceTask = ({
  maintenanceTask,
  loggedInStaffId,
}: {
  maintenanceTask: MaintenanceTaskInterface;
  loggedInStaffId: number;
}): boolean => {
  return maintenanceTask.assistantInternalStaffId === loggedInStaffId;
};
