import React from "react";
import { EntityFieldMetaDataContextInterface } from "module/entity/context/fieldMetaData/EntityFieldMetaDataContext";

export type MyTrainingFieldMetaDataContextInterface =
  EntityFieldMetaDataContextInterface;

const TrainingFieldMetaDataContext = React.createContext(
  <MyTrainingFieldMetaDataContextInterface>{}
);

export default TrainingFieldMetaDataContext;
