import React, { useContext, useMemo } from "react";
import MaintenanceTaskContext from "module/maintenanceTask/context/MaintenanceTaskContext";
import { MaintenanceTaskInterface } from "module/maintenanceTask/types";
import MyNewCorrectiveMaintenanceTaskDetailsOverview from "pages/myMaintenanceTaskDetails/overview/MyNewCorrectiveMaintenanceTaskDetailsOverview";
import MyMaintenanceTaskDetailsOverview from "pages/myMaintenanceTaskDetails/overview/MyMaintenanceTaskDetailsOverview";
import MaintenanceTaskStateContext from "module/maintenanceTask/state/context/MaintenanceTaskStateContext";
import { isObservationalIndicatorsAvailable } from "module/maintenanceTask/helper/isObservationalIndicatorsAvailable";
import { isPresumedCausesAvailable } from "module/maintenanceTask/helper/isPresumedCausesAvailable";
import { isMaintenanceTaskMaintainedAtValueAvailable } from "module/maintenanceTask/helper/isMaintenanceTaskMaintainedAtValueAvailable";
import { isSerializedArticleAvailable as isSerializedArticleAvailableHelper } from "module/maintenanceTask/helper/isSerializedArticleAvailable";

const MyMaintenanceTaskDetailsOverviewContainer: React.FC = () => {
  const { entity } = useContext(MaintenanceTaskContext);
  const { isStateEditable } = useContext(MaintenanceTaskStateContext);

  const maintenanceTask = entity as MaintenanceTaskInterface;

  const areObservationalIndicatorsAvailable = useMemo(
    () => isObservationalIndicatorsAvailable(maintenanceTask),
    [maintenanceTask]
  );
  const arePresumedCausesAvailable = useMemo(
    () => isPresumedCausesAvailable(maintenanceTask),
    [maintenanceTask]
  );
  const isMaintainedAtValueAvailable = useMemo(
    () => isMaintenanceTaskMaintainedAtValueAvailable(maintenanceTask),
    [maintenanceTask]
  );
  const isSerializedArticleAvailable = useMemo(
    () => isSerializedArticleAvailableHelper(maintenanceTask),
    [maintenanceTask]
  );

  if (maintenanceTask.source === "serviceRequest") {
    return (
      <MyNewCorrectiveMaintenanceTaskDetailsOverview {...{ maintenanceTask }} />
    );
  }

  return (
    <MyMaintenanceTaskDetailsOverview
      {...{
        isStateEditable,
        areObservationalIndicatorsAvailable,
        arePresumedCausesAvailable,
        isMaintainedAtValueAvailable,
        isSerializedArticleAvailable,
      }}
    />
  );
};

export default React.memo(MyMaintenanceTaskDetailsOverviewContainer);
