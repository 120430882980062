import React from "react";
import { EntityValidationResultContextInterface } from "module/entity/context/validationResult/EntityValidationResultContext";

export type InventoryCheckValidationResultContextInterface =
  EntityValidationResultContextInterface;

const InventoryCheckValidationResultContext = React.createContext(
  <InventoryCheckValidationResultContextInterface>{}
);

export default InventoryCheckValidationResultContext;
