import React from "react";
import withOnlineDetector from "module/onlineDetector/withOnlineDetector";
import FilterContextProvider from "module/filter/context/FilterContextProvider";
import InventoryListContainer from "./InvenotryListContainer";

const InventoryListPage: React.FC = () => {
  return (
    <FilterContextProvider
      {...{
        initialValue: {},
      }}
    >
      <InventoryListContainer />
    </FilterContextProvider>
  );
};

export default React.memo(withOnlineDetector(InventoryListPage));
