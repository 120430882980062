import React, { useContext, useCallback } from "react";
import t from "module/translations";
import MaintenanceTaskContext from "module/maintenanceTask/context/MaintenanceTaskContext";
import { MaintenanceTaskInterface } from "module/maintenanceTask/types";
import XLabelField from "component/labelField/XLabelField";
import MaintenanceTaskChangeContext from "module/maintenanceTask/change/MaintenanceTaskChangeContext";
import MaintenanceTaskFieldMetaDataContext from "module/maintenanceTask/validation/fieldMetaData/MaintenanceTaskFieldMetaDataContext";
import { getFieldMetaDataWithErrorHandling } from "module/fieldMetaData/getFieldMetaData";
import { MAINTENANCE_TASK_PRESUMED_CAUSES_FIELD_NAME } from "module/maintenanceTask/constants";
import XTextareaField from "component/textareaField/XTextareaField";

const MaintenanceTaskPresumedCausesFieldContainer: React.FC = () => {
  const { entity } = useContext(MaintenanceTaskContext);
  const { change: changeHelper } = useContext(MaintenanceTaskChangeContext);
  const { getFieldMetaData } = useContext(MaintenanceTaskFieldMetaDataContext);

  const maintenanceTask = entity as MaintenanceTaskInterface;

  const change = useCallback(
    (presumedCauses?: string) => {
      changeHelper({ presumedCauses });
    },
    [changeHelper]
  );
  const isNew = maintenanceTask.backendId == null;
  const { warnings, errors, isEditable } = getFieldMetaDataWithErrorHandling(
    () => getFieldMetaData(MAINTENANCE_TASK_PRESUMED_CAUSES_FIELD_NAME)
  );

  const label = t.maintenanceTask.presumedCausesText;

  return isNew && isEditable ? (
    <XTextareaField
      {...{
        value: maintenanceTask.presumedCauses,
        change,
        label,
        errors,
      }}
    />
  ) : (
    <XLabelField
      {...{
        caption: label,
        label: maintenanceTask.presumedCauses,
        errors,
        warnings,
      }}
    />
  );
};

export default React.memo(MaintenanceTaskPresumedCausesFieldContainer);
