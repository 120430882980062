import { RootState } from "redux/store";
import selectConfigurationForMeForCurrentSystem from "module/staff/redux/selector/selectConfigurationForMeForCurrentSystem";
import { EntityIdentifier } from "module/entity/types";
import _ from "lodash";

const selectIsEntityOpen =
  (state: RootState) =>
  (entityIdentifier: EntityIdentifier): boolean => {
    return (
      Boolean(
        selectConfigurationForMeForCurrentSystem(
          state
        )?.openEntityIdentifiers?.find((item) =>
          _.isEqual(item, entityIdentifier)
        )
      ) ?? false
    );
  };

export default selectIsEntityOpen;
