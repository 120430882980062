import React, {
  useCallback,
  useContext,
  useEffect,
  useState,
  useMemo,
} from "react";
import PullToRefresh from "component/pullToRefresh/PullToRefresh";
import ToBeSignedList from "pages/toBeSignedList/ToBeSignedList";
import FullScreenLoaderContext from "module/fullScreenLoader/context/FullScreenLoaderContext";
import { EntityIdentifier, MyEntityInterface } from "module/entity/types";
import getToBeSigned from "api/toBeSigned/getToBeSigned";
import MyEntityCollectionContextProvider from "module/entity/context/entityCollection/MyEntityCollectionContextProvider";

const ToBeSignedListContainer: React.FC = () => {
  const { turnOn: turnLoaderOn, turnOff: turnLoaderOff } = useContext(
    FullScreenLoaderContext
  );
  const [myEntityCollection, setMyEntityCollection] = useState<
    Map<EntityIdentifier, MyEntityInterface>
  >(new Map());

  const load = useCallback(async () => {
    turnLoaderOn();
    const { data: toBeSignedFromApi } = await getToBeSigned();
    const toBeSigned = toBeSignedFromApi.reduce((result, singleRawResource) => {
      result.set(
        {
          id: singleRawResource.entityIdentifier.id,
          type: singleRawResource.entityIdentifier.type,
        },
        singleRawResource
      );
      return result;
    }, new Map() as Map<EntityIdentifier, MyEntityInterface>);
    setMyEntityCollection(toBeSigned);
    turnLoaderOff();
  }, [turnLoaderOff, turnLoaderOn]);

  useEffect(() => {
    load();
  }, [load]);

  const entityIdentifiers = useMemo(
    () => Array.from(myEntityCollection.keys()),
    [myEntityCollection]
  );

  return (
    <PullToRefresh refresh={load}>
      <MyEntityCollectionContextProvider {...{ myEntityCollection }}>
        <ToBeSignedList {...{ entityIdentifiers }} />
      </MyEntityCollectionContextProvider>
    </PullToRefresh>
  );
};

export default React.memo(ToBeSignedListContainer);
