import React from "react";
import { SortType } from "module/sort/types";

export interface SortContextInterface {
  sort: SortType;
  changeSort: (newSort: SortType) => void;
}

const SortContext = React.createContext(<SortContextInterface>{});

export default SortContext;
