import React from "react";
import useMaintenanceTaskPersonnelAssignmentExternalStaffRemove from "module/maintenanceTaskPersonnelAssignment/externalStaff/remove/useMaintenanceTaskPersonnelAssignmentExternalStaffRemove";
import MaintenanceTaskDetailsPersonnelAssignmentExternalStaffListTableRow from "module/maintenanceTaskPersonnelAssignment/externalStaff/enrichedComponent/table/MaintenanceTaskDetailsPersonnelAssignmentExternalStaffListTableRow";

const MyMaintenanceTaskPersonnelAssignmentListTableRowContainer: React.FC =
  () => {
    const { canRemove, remove } =
      useMaintenanceTaskPersonnelAssignmentExternalStaffRemove();

    return (
      <MaintenanceTaskDetailsPersonnelAssignmentExternalStaffListTableRow
        {...{ canRemove, remove, isActionAvailable: true }}
      />
    );
  };

export default React.memo(
  MyMaintenanceTaskPersonnelAssignmentListTableRowContainer
);
