import routes from "routes/routes";

export const getPathToTrainingDetails = (id: string): string =>
  routes.myTrainingDetails.path.replace(":trainingId", id);

export const getPathToTrainingDetailsOverview = (id: string): string =>
  routes.myTrainingDetailsOverview.path.replace(":trainingId", id);

export const getPathToTrainingDetailsSessions = (id: string): string =>
  routes.myTrainingDetailsSessions.path.replace(":trainingId", id);

export const getPathToTrainingGroupDetails = (ids: string[]): string =>
  routes.myTrainingGroupDetails.path.replace(":trainingsIds", ids.join(","));

export const getPathToTrainingGroupDetailsOverview = (ids: string[]): string =>
  routes.myTrainingGroupDetailsOverview.path.replace(
    ":trainingsIds",
    ids.join(",")
  );

export const getPathToTrainingGroupDetailsSessions = (ids: string[]): string =>
  routes.myTrainingGroupDetailsSessions.path.replace(
    ":trainingsIds",
    ids.join(",")
  );

export const getPathToTrainingToBeSignedDetails = (id: string): string =>
  routes.trainingToBeSignedDetails.path.replace(":trainingId", id);

export const getPathToTrainingToBeSignedDetailsOverview = (
  id: string
): string =>
  routes.trainingToBeSignedDetailsOverview.path.replace(":trainingId", id);

export const getPathToTrainingToBeSignedDetailsSessions = (
  id: string
): string =>
  routes.trainingToBeSignedDetailsSessions.path.replace(":trainingId", id);
