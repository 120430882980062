import { MaintenanceTaskInterface } from "module/maintenanceTask/types";
import { MaintenanceTaskAssistantInternalStaffToSyncInterface } from "module/maintenanceTaskAssisstant/types";

export const mapMaintenanceTaskAssistantInternalStaffToSync = (
  maintenanceTask: MaintenanceTaskInterface
): MaintenanceTaskAssistantInternalStaffToSyncInterface => {
  return {
    staffId: maintenanceTask.assistantInternalStaffId as number,
  };
};
