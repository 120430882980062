import React from "react";
import { useAppSelector } from "redux/hooks";
import { useHistory } from "react-router-dom";
import ErrorPage from "error/ErrorPage";
import { EntityIdentifier, EntityType } from "module/entity/types";
import selectOpenItemByEntityType from "../../module/staff/redux/selector/selectOpenItemByEntityType";
import { getPathToInventoryCheckDetails } from "../../module/inventoryCheck/helper/getPath";

const MyOpenInventoryCheckDetailsPage: React.FC = () => {
  const openInventoryCheckEntityIdentifier = useAppSelector(
    selectOpenItemByEntityType(EntityType.inventoryCheck)
  ) as EntityIdentifier;

  const history = useHistory();
  if (openInventoryCheckEntityIdentifier) {
    history.push(
      getPathToInventoryCheckDetails(openInventoryCheckEntityIdentifier.id)
    );
  }

  return <ErrorPage />;
};

export default React.memo(MyOpenInventoryCheckDetailsPage);
