import React, { useContext, useCallback } from "react";
import t from "module/translations";
import FilterContext from "module/filter/context/FilterContext";
import TextField from "../../../../component/textField/TextField";

const StockArticleListToolbarFilterNameContainer: React.FC = () => {
  const { filters, changeFilters } = useContext(FilterContext);
  const change = useCallback(
    (name: string | undefined) => {
      changeFilters({
        ...filters,
        name,
      });
    },
    [changeFilters, filters]
  );

  return (
    <TextField
      {...{
        label: t.stockArticle.nameText,
        change,
        value: filters.name as string,
        errors: [],
      }}
    />
  );
};

export default React.memo(StockArticleListToolbarFilterNameContainer);
