import { MaintenanceTaskInterface } from "../../types";
import { filterOutMaintenanceTaskCreatedForOtherMaintenanceTaskProcessStep } from "../filterOutMaintenanceTaskCreatedForOtherMaintenanceTaskProcessStep";
import { filterMaintenanceTaskCreatedForOtherMaintenanceTaskProcessStep } from "../filterMaintenanceTaskCreatedForOtherMaintenanceTaskProcessStep";

interface Result {
  [maintenanceTaskId: string]: MaintenanceTaskInterface[];
}
export const getMaintenanceTasksCreatedForOtherMaintenanceTaskProcessStepsMap =
  (allMaintenanceTasks: MaintenanceTaskInterface[]): Result => {
    const originalMaintenanceTasks = allMaintenanceTasks.filter(
      filterOutMaintenanceTaskCreatedForOtherMaintenanceTaskProcessStep
    );

    const maintenanceTasksCreatedForOtherMaintenanceTaskProcessStep =
      allMaintenanceTasks.filter(
        filterMaintenanceTaskCreatedForOtherMaintenanceTaskProcessStep
      );

    const originalMaintenanceTaskMaintenanceTaskProcessStepMap: {
      [maintenanceTaskId: string]: string[];
    } = originalMaintenanceTasks.reduce((result, current) => {
      return {
        ...result,
        [current.id]: current.processSteps.map(
          (processStepEntityIdentifier) => processStepEntityIdentifier.id
        ),
      };
    }, {});

    return originalMaintenanceTasks.reduce(
      (result, current) => ({
        ...result,
        [current.id]:
          maintenanceTasksCreatedForOtherMaintenanceTaskProcessStep.filter(
            (mt) =>
              originalMaintenanceTaskMaintenanceTaskProcessStepMap[
                current.id
              ].includes(mt.originalMaintenanceTaskProcessStepId as string)
          ),
      }),
      {}
    );
  };
