import { mergeEntityMaps } from "module/entity/helper/mergeEntityMaps";
import { EntityIdentifier, MyEntityInterface } from "module/entity/types";
import { ApiInventoryCheckInterface } from "api/inventoryCheck/types";
import { mapApiInventoryCheckArticleToApp } from "module/inventoryCheckArticle/helper/mapApiInventoryCheckArticleToApp";
import {
  InventoryCheckInterface,
  MyInventoryCheckInterface,
} from "module/inventoryCheck/types";

export const mapApiInventoryCheckToApp = (
  raw: ApiInventoryCheckInterface
): MyInventoryCheckInterface => {
  const inventoryCheckArticles = raw.inventoryCheckArticles.reduce(
    (result, rawInventoryCheckArticle) => {
      const myEntity = mapApiInventoryCheckArticleToApp(
        raw,
        rawInventoryCheckArticle
      );
      result.set(myEntity.entityIdentifier, myEntity);
      return result;
    },
    new Map() as Map<EntityIdentifier, MyEntityInterface>
  );

  const relationships = mergeEntityMaps([inventoryCheckArticles]);

  const data: InventoryCheckInterface = {
    ...raw,
    id: `${raw.id}`,
    backendId: raw.id,

    inventoryCheckArticles: Array.from(inventoryCheckArticles.keys()),

    relationships,
    relationshipIdentifiers: Array.from(relationships.keys()),
  };

  return {
    entityIdentifier: {
      type: raw.entityType,
      id: `${raw.id}`,
      backendId: raw.id,
    },
    data,
    dataWithoutChanges: data,
    raw,
    validationErrors: null,
    validationWarnings: null,
  };
};
