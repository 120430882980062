import React, { useContext } from "react";
import AssessmentContext from "module/assessment/context/AssessmentContext";
import { AssessmentInterface } from "module/assessment/types";
import AssessmentNameLabelField from "module/assessment/enrichedComponent/nameField/AssessmentNameLabelField";

const AssessmentNameFieldContainer: React.FC = () => {
  const { entity } = useContext(AssessmentContext);
  const { name } = entity as AssessmentInterface;

  return <AssessmentNameLabelField {...{ name }} />;
};

export default React.memo(AssessmentNameFieldContainer);
