import React from "react";
import { EntityChangeContextInterface } from "module/entity/context/change/EntityChangeContext";

export type InventoryCheckChangeContextInterface = EntityChangeContextInterface;

const InventoryCheckChangeContext = React.createContext(
  <InventoryCheckChangeContextInterface>{}
);

export default InventoryCheckChangeContext;
