import { PayloadAction } from "@reduxjs/toolkit";
import { StaffDataState } from "module/staff/redux/types";
import { EntityIdentifier } from "module/entity/types";
import { getEntityIdentifierKey } from "module/entity/helper/getEntityIdentifierKey";

interface Props {
  staffId: number;
  systemShortName: string;
  baseEntityIdentifier: EntityIdentifier;
  propertyName: string;
  entityIdentifierToRemove: EntityIdentifier;
}

const removeFromEntityPropertyListAction = (
  state: StaffDataState,
  action: PayloadAction<Props>
): void => {
  const {
    staffId,
    systemShortName,
    baseEntityIdentifier,
    propertyName,
    entityIdentifierToRemove,
  } = action.payload;

  const { data } =
    state[staffId][systemShortName].entities[
      getEntityIdentifierKey(baseEntityIdentifier)
    ];

  const newData = {
    ...data,
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    [propertyName]: data[propertyName]?.filter(
      (entityIdentifier: EntityIdentifier) =>
        getEntityIdentifierKey(entityIdentifier) !==
        getEntityIdentifierKey(entityIdentifierToRemove)
    ),
    relationshipIdentifiers: data.relationshipIdentifiers.filter(
      (entityIdentifier) =>
        getEntityIdentifierKey(entityIdentifier) !==
        getEntityIdentifierKey(entityIdentifierToRemove)
    ),
  };

  state[staffId][systemShortName].entities[
    getEntityIdentifierKey(baseEntityIdentifier)
  ].data = newData;
};

export default removeFromEntityPropertyListAction;
