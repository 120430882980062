import React, { useContext } from "react";
import TrainingContext from "module/training/context/TrainingContext";
import { TrainingInterface } from "module/training/types";
import TrainingExpectedDateLabelField from "module/training/enrichedComponent/expectedDateField/TrainingExpectedDateLabelField";
import useDateFormat from "toolkit/useDateFormat";

const TrainingExpectedDateFieldContainer: React.FC = () => {
  const { entity } = useContext(TrainingContext);
  const { formatDate } = useDateFormat();

  const { expectedDate } = entity as TrainingInterface;

  return <TrainingExpectedDateLabelField {...{ expectedDate, formatDate }} />;
};

export default React.memo(TrainingExpectedDateFieldContainer);
