import React from "react";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";

interface Props {
  title: string;
  onClick: () => void;
  icon: React.ReactNode;
}

const ListToolbarButton: React.FC<Props> = ({ title, onClick, icon }) => {
  return (
    <Tooltip title={title}>
      <IconButton aria-label={title} onClick={onClick} size="large">
        {icon}
      </IconButton>
    </Tooltip>
  );
};

export default React.memo(ListToolbarButton);
