import { useContext, useCallback } from "react";
import { toastError, toastSuccess, toastWarning } from "layout/toast/helper";
import t from "module/translations";
import { EntityIdentifier } from "module/entity/types";
import apiSync from "api/sync/sync";
import { EntityToSyncInterface } from "module/sync/types";
import FullScreenLoaderContext from "module/fullScreenLoader/context/FullScreenLoaderContext";
import { removeFromMyEntities } from "module/staff/redux/staffDataSlice";
import { useAppDispatch, useAppSelector } from "redux/hooks";
import {
  selectSystem,
  selectUser,
  setSystemConfiguration,
} from "module/auth/redux/authSlice";
import {
  displayValidationErrors,
  getValidEntityIdentifiers,
} from "module/validation/helper";
import useEntityAbstractFactory from "module/entity/useEntityAbstractFactory";
import getSystem from "api/auth/getSystem";
import useMyEntityValidation from "module/entity/useMyEntityValidation";
import * as Sentry from "@sentry/react";

interface Result {
  sync: (keys: EntityIdentifier[]) => Promise<void>;
}

const useEntitySync = (): Result => {
  const { turnOn: turnLoaderOn, turnOff: turnLoaderOff } = useContext(
    FullScreenLoaderContext
  );
  const dispatch = useAppDispatch();
  const staffId = useAppSelector(selectUser).id;
  const systemShortName = useAppSelector(selectSystem).shortName;

  const { getEntityFactory } = useEntityAbstractFactory();
  const { validateAllMyEntities } = useMyEntityValidation();

  const getItemsToSync = useCallback(
    (keys: EntityIdentifier[]): EntityToSyncInterface[] =>
      keys.reduce((result: EntityToSyncInterface[], entityIdentifier) => {
        const entityFactory = getEntityFactory(entityIdentifier.type);
        return [...result, entityFactory.getMappedToSync(entityIdentifier.id)];
      }, []),
    [getEntityFactory]
  );

  const sync = useCallback(
    async (entityIdentifiers: EntityIdentifier[]) => {
      turnLoaderOn();
      try {
        const entityIdentifiersUnableToSyncWithReason = new Map();

        // frontend validation
        const entityIdentifiersAllowedToSync: EntityIdentifier[] =
          entityIdentifiers.reduce((result, entityIdentifier) => {
            const entityFactory = getEntityFactory(entityIdentifier.type);
            const { isAvailable, unavailableReason } =
              entityFactory.isSyncAvailable(entityIdentifier.id);
            if (isAvailable) {
              return [...result, entityIdentifier];
            }
            entityIdentifiersUnableToSyncWithReason.set(
              entityIdentifier,
              unavailableReason
            );
            return result;
          }, [] as EntityIdentifier[]);

        if (entityIdentifiersUnableToSyncWithReason.size) {
          entityIdentifiersUnableToSyncWithReason.forEach(
            (reason, entityIdentifier) => {
              const entityFactory = getEntityFactory(entityIdentifier.type);
              toastWarning(
                t.sync.syncIsNotPossibleText({
                  type: entityFactory.readableType,
                  identifier: entityFactory.getReadableIdentifier(
                    entityIdentifier.id
                  ),
                  reason: entityFactory.getSyncUnavailableReasonText(reason),
                })
              );
            }
          );
        }

        if (entityIdentifiersAllowedToSync.length) {
          const itemsToSync = getItemsToSync(entityIdentifiersAllowedToSync);

          const { validationErrors } = await apiSync(itemsToSync);
          const validEntityIdentifiers = getValidEntityIdentifiers(
            entityIdentifiersAllowedToSync,
            validationErrors
          );

          if (validationErrors) {
            displayValidationErrors(validationErrors);
          }

          if (validEntityIdentifiers.length) {
            toastSuccess(t.sync.syncSuccessText(validEntityIdentifiers.length));
            Sentry.withScope((scope) => {
              scope.setExtra("entityIdentifiers", validEntityIdentifiers);
              Sentry.captureMessage("useEntitySync");
            });
            dispatch(
              removeFromMyEntities({
                entityIdentifiers: validEntityIdentifiers,
                staffId,
                systemShortName,
              })
            );
          }
        }
      } catch (error) {
        toastError(t.api.generalError);
      }
      const system = await getSystem(systemShortName);
      dispatch(setSystemConfiguration(system));
      validateAllMyEntities();

      turnLoaderOff();
    },
    [
      dispatch,
      getEntityFactory,
      getItemsToSync,
      staffId,
      systemShortName,
      turnLoaderOff,
      turnLoaderOn,
      validateAllMyEntities,
    ]
  );

  return {
    sync,
  };
};

export default useEntitySync;
