import {
  MaintenanceTaskPersonnelAssignmentExternalStaffInterface,
  MaintenanceTaskPersonnelAssignmentExternalStaffToSyncInterface,
} from "module/maintenanceTaskPersonnelAssignment/externalStaff/types";

export const mapMaintenanceTaskPersonnelAssignmentExternalStaffToSync = (
  maintenanceTaskPersonnelAssignmentExternalStaff: MaintenanceTaskPersonnelAssignmentExternalStaffInterface
): MaintenanceTaskPersonnelAssignmentExternalStaffToSyncInterface => {
  return {
    id: maintenanceTaskPersonnelAssignmentExternalStaff.backendId,
    externalStaffCompany:
      maintenanceTaskPersonnelAssignmentExternalStaff.company as string,
    externalStaffName:
      maintenanceTaskPersonnelAssignmentExternalStaff.name as string,
    responsibility:
      maintenanceTaskPersonnelAssignmentExternalStaff.responsibility,
  };
};
