import React, { useContext, useMemo } from "react";
import ToBeSignedListTableRowAssessmentContainer from "pages/toBeSignedList/table/row/assessment/ToBeSignedListTableRowAssessmentContainer";
import ToBeSignedListTableRowTrainingContainer from "pages/toBeSignedList/table/row/training/ToBeSignedListTableRowTrainingContainer";
import TrainingContext from "module/training/context/TrainingContext";
import AssessmentContext from "module/assessment/context/AssessmentContext";
import {
  EntityIdentifier,
  EntityType,
  MyEntityInterface,
} from "module/entity/types";
import MyEntityCollectionContext from "module/entity/context/entityCollection/MyEntityCollectionContext";
import TrainingCustomContext from "module/trainingCustom/context/TrainingCustomContext";
import ToBeSignedListTableRowTrainingCustomContainer from "pages/toBeSignedList/table/row/trainingCustom/ToBeSignedListTableRowTrainingCustomContainer";
import MaintenanceTaskContext from "module/maintenanceTask/context/MaintenanceTaskContext";
import ToBeSignedListTableRowMaintenanceTaskContainer from "pages/toBeSignedList/table/row/maintenanceTask/ToBeSignedListTableRowMaintenanceTaskContainer";

interface Pros {
  entityIdentifier: EntityIdentifier;
}

const ToBeSignedListTableRowContainer: React.FC<Pros> = ({
  entityIdentifier,
}) => {
  const { myEntityCollection } = useContext(MyEntityCollectionContext);
  const { data: entity } = useMemo(
    () => myEntityCollection.get(entityIdentifier) as MyEntityInterface,
    [entityIdentifier, myEntityCollection]
  );

  if (entityIdentifier.type === EntityType.training) {
    return (
      <TrainingContext.Provider value={{ entity }}>
        <ToBeSignedListTableRowTrainingContainer />
      </TrainingContext.Provider>
    );
  }

  if (entityIdentifier.type === EntityType.assessment) {
    return (
      <AssessmentContext.Provider value={{ entity }}>
        <ToBeSignedListTableRowAssessmentContainer />
      </AssessmentContext.Provider>
    );
  }

  if (entityIdentifier.type === EntityType.trainingCustom) {
    return (
      <TrainingCustomContext.Provider value={{ entity }}>
        <ToBeSignedListTableRowTrainingCustomContainer />
      </TrainingCustomContext.Provider>
    );
  }

  if (entityIdentifier.type === EntityType.maintenanceTask) {
    return (
      <MaintenanceTaskContext.Provider value={{ entity }}>
        <ToBeSignedListTableRowMaintenanceTaskContainer />
      </MaintenanceTaskContext.Provider>
    );
  }

  return null;
};

export default React.memo(ToBeSignedListTableRowContainer);
