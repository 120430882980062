import React, { useContext } from "react";
import InventoryCheckStateContext from "module/inventoryCheck/state/context/InventoryCheckStateContext";
import MyInventoryCheckDetailsOverview from "./MyInventoryCheckDetailsOverview";

const MyInventoryCheckDetailsOverviewContainer: React.FC = () => {
  const { isStateEditable } = useContext(InventoryCheckStateContext);

  return (
    <MyInventoryCheckDetailsOverview
      {...{
        isStateEditable,
      }}
    />
  );
};

export default React.memo(MyInventoryCheckDetailsOverviewContainer);
