import { useSyncExternalStore } from "react";

interface Result {
  isOnline: boolean;
  isOffline: boolean;
}

function subscribe(callback: () => void) {
  window.addEventListener("online", callback);
  window.addEventListener("offline", callback);
  return () => {
    window.removeEventListener("online", callback);
    window.removeEventListener("offline", callback);
  };
}

const useOnlineDetector = (): Result => {
  const isOnline = useSyncExternalStore(
    subscribe,
    () => global.navigator.onLine, // How to get the value on the client
    () => true // How to get the value on the server
  );

  return { isOnline, isOffline: !isOnline };
};

export default useOnlineDetector;
