import React, { useCallback } from "react";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Typography from "@mui/material/Typography";
import FormControl from "@mui/material/FormControl";
import { RadioGroupOption } from "./type";
import XValidationErrorMessage from "../validationErrorMessage/XValidationErrorMessage";
import XValidationWarningMessage from "../validationWarningMessage/XValidationWarningMessage";

interface Props {
  options: RadioGroupOption[];
  selectedOption?: RadioGroupOption;
  change: (newValue: unknown) => void;
  errors?: string[];
  warnings?: string[];
}

const RadioGroupField: React.FC<Props> = ({
  options,
  selectedOption,
  change,
  errors,
  warnings,
}) => {
  const handleChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>): void => {
      change(event.target.value);
    },
    [change]
  );
  const hasError = errors != null && Boolean(errors.length);

  return (
    <FormControl error={hasError} variant="standard">
      <RadioGroup
        row
        name="checkResult"
        onChange={handleChange}
        value={selectedOption?.value}
      >
        {options.map((option) => (
          <FormControlLabel
            value={option.value}
            control={<Radio color="primary" size="medium" />}
            label={
              <Typography variant="caption" gutterBottom>
                {option.label}
              </Typography>
            }
            labelPlacement="top"
          />
        ))}
      </RadioGroup>
      {errors && <XValidationErrorMessage {...{ errors }} />}
      {warnings && <XValidationWarningMessage {...{ warnings }} />}
    </FormControl>
  );
};

export default React.memo(RadioGroupField);
