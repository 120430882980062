import React, { useContext, useState, useCallback } from "react";
import { toastError } from "layout/toast/helper";
import t from "module/translations";
import ConfirmDialog from "component/confirmDialog/ConfirmDialog";
import FullScreenLoaderContext from "module/fullScreenLoader/context/FullScreenLoaderContext";

interface Props {
  confirmationDialogTitle: string;
  asyncAction?: () => Promise<void>;
  action?: () => void;
  children:
    | ((props: { onClick: () => void }) => React.ReactNode)
    | React.ReactNode;
}

const ElementWithConfirmation: React.FC<Props> = ({
  confirmationDialogTitle,
  asyncAction,
  action,
  children,
}) => {
  const { turnOn: turnLoaderOn, turnOff: turnLoaderOff } = useContext(
    FullScreenLoaderContext
  );
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const onConfirmAsync = useCallback(async () => {
    if (asyncAction != null) {
      setIsOpen(false);
      turnLoaderOn();
      try {
        await asyncAction();
      } catch (error) {
        toastError(t.api.generalError);
      }
      turnLoaderOff();
    }
  }, [asyncAction, turnLoaderOff, turnLoaderOn]);

  const onConfirmSync = useCallback(() => {
    if (action != null) {
      setIsOpen(false);
      try {
        action();
      } catch (error) {
        toastError(t.generalError);
      }
    }
  }, [action]);

  const onConfirm = action != null ? onConfirmSync : onConfirmAsync;

  const handleClick = useCallback(() => setIsOpen(true), []);
  const onCancel = useCallback(() => setIsOpen(false), []);

  const renderChildren = useCallback(
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    () => children({ onClick: handleClick }),
    [children, handleClick]
  );

  return (
    <>
      {renderChildren()}
      <ConfirmDialog
        {...{
          onConfirm,
          onCancel,
          isOpen,
          title: confirmationDialogTitle,
        }}
      />
    </>
  );
};

export default React.memo(ElementWithConfirmation);
