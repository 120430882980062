import makeStyles from "@mui/styles/makeStyles";

export const useDownloadedMaintenanceTaskDetailsPersonnelAssignmentAssistantStyles =
  makeStyles(() => ({
    table: {
      minWidth: "100%",
    },
    type: {
      minWidth: "120px",
    },
    staffName: {
      width: "50%",
    },
    staffPositionOrCompany: {
      width: "50%",
    },
    actions: {
      width: "50px",
    },
  }));
