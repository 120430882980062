import { PayloadAction } from "@reduxjs/toolkit";
import { StaffDataState } from "module/staff/redux/types";
import { EntityIdentifier } from "module/entity/types";
import { getEntityIdentifierKey } from "module/entity/helper/getEntityIdentifierKey";

interface ChangeEntityCollectionActionProps<EntityInterface> {
  staffId: number;
  systemShortName: string;
  entityIdentifiers: EntityIdentifier[];
  changes: Partial<EntityInterface>;
}

const changeEntityCollectionAction = <EntityInterface>(
  state: StaffDataState,
  action: PayloadAction<ChangeEntityCollectionActionProps<EntityInterface>>
): void => {
  const { staffId, systemShortName, entityIdentifiers, changes } =
    action.payload;

  // eslint-disable-next-line array-callback-return
  entityIdentifiers.map((entityIdentifier) => {
    state[staffId][systemShortName].entities[
      getEntityIdentifierKey(entityIdentifier)
    ].data = {
      ...state[staffId][systemShortName].entities[
        getEntityIdentifierKey(entityIdentifier)
      ].data,
      ...changes,
    };
  });
};

export default changeEntityCollectionAction;
