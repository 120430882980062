import React, { useState, useCallback } from "react";
import createStyles from "@mui/styles/createStyles";
import makeStyles from "@mui/styles/makeStyles";
import Webcam from "react-webcam";
import IconButton from "@mui/material/IconButton";
import CameraIcon from "@mui/icons-material/Camera";
import DoneIcon from "@mui/icons-material/Done";
import ClearIcon from "@mui/icons-material/Clear";
import ReplayIcon from "@mui/icons-material/Replay";
import Fade from "@mui/material/Fade";

export const PHOTO_WIDTH_PX = 1024;
export const PHOTO_HEIGHT_PX = 576;

const videoConstraints = {
  width: PHOTO_WIDTH_PX,
  height: PHOTO_HEIGHT_PX,
  facingMode: "environment",
};

const useStyles = makeStyles(() =>
  createStyles({
    wrapper: {
      width: "100%",
      margin: "auto",
    },
    cameraViewWrapper: {
      display: "flex",
      flexGrow: 1,
      justifyContent: "center",
    },
    actionWrapper: {
      width: "100%",
      flexGrow: 1,
      display: "flex",
      justifyContent: "center",
      marginTop: "64px",
    },
    iconButton: {
      width: "100px",
      margin: "0 75px",
    },
    icon: {
      fontSize: "64px",
    },
    imagePreviewWrapper: {
      width: "100%",
      display: "flex",
      flexGrow: 1,
      justifyContent: "center",
    },
  })
);

interface Props {
  onClose: () => void;
  onSave: (imageData: string) => void;
}

const Camera: React.FC<Props> = ({ onClose, onSave }) => {
  const classes = useStyles();
  const [imageData, setImageData] = useState<string | null>(null);
  const webcamRef = React.useRef(null);

  const capture = React.useCallback(() => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const imageSrc = webcamRef?.current?.getScreenshot();
    setImageData(imageSrc);
  }, [webcamRef]);

  const repeat = useCallback(() => setImageData(null), []);
  const save = useCallback(() => {
    onSave(imageData as string);
  }, [imageData, onSave]);
  const close = useCallback(() => {
    onClose();
  }, [onClose]);

  return (
    <div className={classes.wrapper}>
      <div className={classes.cameraViewWrapper}>
        {imageData == null && (
          <Webcam
            audio={false}
            height={PHOTO_HEIGHT_PX}
            ref={webcamRef}
            screenshotFormat="image/jpeg"
            width={PHOTO_WIDTH_PX}
            videoConstraints={videoConstraints}
          />
        )}
        {imageData != null && (
          <Fade in={Boolean(imageData)}>
            <div className={classes.imagePreviewWrapper}>
              {/* eslint-disable-next-line jsx-a11y/alt-text */}
              <img src={imageData} height={PHOTO_HEIGHT_PX} />
            </div>
          </Fade>
        )}
      </div>
      <div className={classes.actionWrapper}>
        {imageData == null && (
          <IconButton
            color="primary"
            className={classes.iconButton}
            onClick={capture}
            size="large"
          >
            <CameraIcon className={classes.icon} />
          </IconButton>
        )}
        {imageData != null && (
          <>
            <IconButton
              color="primary"
              className={classes.iconButton}
              onClick={repeat}
              size="large"
            >
              <ReplayIcon className={classes.icon} />
            </IconButton>
            <IconButton
              color="primary"
              className={classes.iconButton}
              onClick={save}
              size="large"
            >
              <DoneIcon className={classes.icon} />
            </IconButton>
            <IconButton
              color="primary"
              className={classes.iconButton}
              onClick={close}
              size="large"
            >
              <ClearIcon className={classes.icon} />
            </IconButton>
          </>
        )}
      </div>
    </div>
  );
};

export default React.memo(Camera);
