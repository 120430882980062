import React from "react";
import { EntityIdentifier, MyEntityInterface } from "module/entity/types";

export interface MyEntityCollectionContextInterface {
  myEntityCollection: Map<EntityIdentifier, MyEntityInterface>;
}

const MyEntityCollectionContext = React.createContext(
  <MyEntityCollectionContextInterface>{}
);

export default MyEntityCollectionContext;
