import React, { useCallback } from "react";
import Popover from "@mui/material/Popover";
import IconButton from "@mui/material/IconButton";
import InfoIcon from "@mui/icons-material/Info";
import createStyles from "@mui/styles/createStyles";
import makeStyles from "@mui/styles/makeStyles";

const useStyles = makeStyles(() =>
  createStyles({
    icon: {
      padding: "10px",
    },
  })
);

interface Props {
  label: string;
  children: React.ReactNode;
}

const InfoIconWIthPopover: React.FC<Props> = ({ label, children }) => {
  const classes = useStyles();

  const [anchorEl, setAnchorEl] =
    React.useState<HTMLButtonElement | null>(null);

  const handleClick = useCallback(
    (event: React.MouseEvent<HTMLButtonElement>) => {
      setAnchorEl(event.currentTarget);
    },
    []
  );

  const handleClose = useCallback(() => {
    setAnchorEl(null);
  }, []);

  const open = Boolean(anchorEl);
  const id = open ? label : undefined;

  return (
    <>
      <IconButton
        aria-label={label}
        onClick={handleClick}
        className={classes.icon}
        size="large"
      >
        <InfoIcon color="primary" />
      </IconButton>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        {children}
      </Popover>
    </>
  );
};

export default React.memo(InfoIconWIthPopover);
