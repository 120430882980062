import React from "react";
import VisibilityIcon from "@mui/icons-material/Visibility";
import Link from "component/link/Link";
import { LinkProps } from "./type";

const LinkWithIcon: React.FC<LinkProps> = ({ path, label }) => {
  return <Link {...{ path, label, icon: <VisibilityIcon /> }} />;
};

export default React.memo(LinkWithIcon);
