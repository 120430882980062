import React from "react";
import { EntityIdentifier } from "module/entity/types";
import MyEntityContextProvider from "module/entity/context/entity/MyEntityContextProvider";
import TrainingSessionContext from "module/trainingSession/context/TrainingSessionContext";
import MyTrainingSessionFieldMetaDataContextProvider from "module/trainingSession/validation/fieldMetaData/MyTrainingSessionFieldMetaDataContextProvider";
import MyEntityValidationResultContextProvider from "module/entity/context/validationResult/MyEntityValidationResultContextProvider";
import TrainingSessionValidationResultContext from "module/trainingSession/validation/TrainingSessionValidationResultContext";
import MyTrainingSessionChangeContext from "module/trainingSession/change/TrainingSessionChangeContext";
import MyEntityCollectionChangeContextProvider from "module/entity/context/change/MyEntityCollectionChangeContextProvider";

interface Props {
  entityIdentifiers: EntityIdentifier[];
  children: React.ReactNode;
}

const MyTrainingGroupDetailsSessionContextProvider: React.FC<Props> = ({
  children,
  entityIdentifiers,
}) => {
  return (
    <MyEntityContextProvider
      {...{
        entityIdentifier: entityIdentifiers[0],
        context: TrainingSessionContext,
      }}
    >
      <MyEntityValidationResultContextProvider
        {...{
          entityIdentifier: entityIdentifiers[0],
          context: TrainingSessionValidationResultContext,
        }}
      >
        <MyTrainingSessionFieldMetaDataContextProvider>
          <MyEntityCollectionChangeContextProvider
            {...{
              entityIdentifiers,
              context: MyTrainingSessionChangeContext,
            }}
          >
            {children}
          </MyEntityCollectionChangeContextProvider>
        </MyTrainingSessionFieldMetaDataContextProvider>
      </MyEntityValidationResultContextProvider>
    </MyEntityContextProvider>
  );
};

export default MyTrainingGroupDetailsSessionContextProvider;
