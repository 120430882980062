import React, { useContext, useMemo } from "react";
import { EntityIdentifier } from "module/entity/types";
import EntityContextProvider from "module/entity/context/entity/EntityContextProvider";
import TrainingCustomContext from "module/trainingCustom/context/TrainingCustomContext";
import {
  TrainingCustomAssigneeInterface,
  TrainingCustomInterface,
} from "module/trainingCustom/types";
import TrainingCustomAssigneeContext from "module/trainingCustomAssignee/context/TrainingCustomAssigneeContext";

interface Props {
  entityIdentifier: EntityIdentifier;
  children: React.ReactNode;
}

const TrainingCustomAssigneeContextProvider: React.FC<Props> = ({
  entityIdentifier,
  children,
}) => {
  const { entity } = useContext(TrainingCustomContext);

  const trainingCustom = entity as TrainingCustomInterface;

  const assignment = useMemo(
    () =>
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      trainingCustom.relationships.get(entityIdentifier)
        .data as TrainingCustomAssigneeInterface,
    [entityIdentifier, trainingCustom.relationships]
  );

  return (
    <EntityContextProvider
      {...{ entity: assignment, context: TrainingCustomAssigneeContext }}
    >
      {children}
    </EntityContextProvider>
  );
};

export default TrainingCustomAssigneeContextProvider;
