import { useCallback } from "react";
import { EntityIdentifier, EntityType } from "module/entity/types";
import useAssessmentFactory from "module/assessment/useAssessmentFactory";
import useTrainingFactory from "module/training/useTrainingFactory";
import { EntityToSyncInterface } from "module/sync/types";
import useMaintenanceTaskFactory from "module/maintenanceTask/useMaintenanceTaskFactory";
import useTrainingCustomFactory from "module/trainingCustom/useTrainingCustomFactory";
import useInventoryCheckFactory from "../inventoryCheck/useInventoryCheckFactory";

export interface IsEntitySyncAvailableResult {
  isAvailable: boolean;
  unavailableReason: string | null;
}

export interface EntityFactory {
  getPathToMyDetails: (id: string) => string;
  getPathToMyGroupedDetails: (ids: string[]) => string;
  areSimilar: (
    entityIdentifier1: EntityIdentifier,
    entityIdentifier2: EntityIdentifier
  ) => boolean;
  isSyncAvailable: (id: string) => IsEntitySyncAvailableResult;
  getMappedToSync: (id: string) => EntityToSyncInterface;
  getReadableIdentifier: (id: string) => string;
  getSyncUnavailableReasonText: (reason: string) => string;
  readableType: string;
}

interface Result {
  getEntityFactory: (entityType: EntityType) => EntityFactory;
}

const useEntityAbstractFactory = (): Result => {
  const assessmentFactory = useAssessmentFactory();
  const trainingFactory = useTrainingFactory();
  const maintenanceTaskFactory = useMaintenanceTaskFactory();
  const trainingCustomFactory = useTrainingCustomFactory();
  const inventoryCheckFactory = useInventoryCheckFactory();

  const getEntityFactory = useCallback(
    (entityType: EntityType): EntityFactory => {
      switch (entityType) {
        case EntityType.assessment:
          return assessmentFactory;
        case EntityType.training:
          return trainingFactory;
        case EntityType.maintenanceTask:
          return maintenanceTaskFactory;
        case EntityType.trainingCustom:
          return trainingCustomFactory;
        case EntityType.inventoryCheck:
          return inventoryCheckFactory;
        default:
          throw new Error(`Type ${entityType} is not supported.`);
      }
    },
    [
      assessmentFactory,
      inventoryCheckFactory,
      maintenanceTaskFactory,
      trainingCustomFactory,
      trainingFactory,
    ]
  );
  return { getEntityFactory };
};

export default useEntityAbstractFactory;
