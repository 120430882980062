import React from "react";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import { StockArticle } from "../types";

interface Props {
  stockArticle: StockArticle;
}

const StockArticleListTableRow: React.FC<Props> = ({ stockArticle }) => {
  return (
    <TableRow
      {...{
        hover: true,
        tabIndex: -1,
        key: stockArticle.id,
      }}
    >
      <TableCell>{stockArticle.externalId}</TableCell>
      <TableCell>{stockArticle.name}</TableCell>
      <TableCell>{stockArticle.stock}</TableCell>
      <TableCell>{`${stockArticle.onStock} ${stockArticle.unit}`}</TableCell>
    </TableRow>
  );
};

export default React.memo(StockArticleListTableRow);
