import React from "react";
import { EntityIdentifier } from "module/entity/types";
import MyEntityContextProvider from "module/entity/context/entity/MyEntityContextProvider";
import MaintenanceTaskProcessStepContext from "module/maintenanceTaskProcessStep/context/MaintenanceTaskProcessStepContext";
import { getEntityIdentifierKey } from "module/entity/helper/getEntityIdentifierKey";
import MyEntityValidationResultContextProvider from "module/entity/context/validationResult/MyEntityValidationResultContextProvider";
import MaintenanceTaskProcessStepProcessStepValidationResultContext from "module/maintenanceTaskProcessStep/validation/MaintenanceTaskProcessStepValidationResultContext";
import MyMaintenanceTaskProcessStepFieldMetaDataContextProvider from "module/maintenanceTaskProcessStep/validation/fieldMetaData/MyMaintenanceTaskProcessStepFieldMetaDataContextProvider";
import MyEntityChangeContextProvider from "module/entity/context/change/MyEntityChangeContextProvider";
import MaintenanceTaskProcessStepChangeContext from "module/maintenanceTaskProcessStep/change/MaintenanceTaskProcessStepChangeContext";

interface Props {
  entityIdentifier: EntityIdentifier;
  children: React.ReactNode;
}

const MyMaintenanceTaskProcessStepContextProvider: React.FC<Props> = ({
  entityIdentifier,
  children,
}) => {
  return (
    <MyEntityContextProvider
      {...{
        entityIdentifier,
        context: MaintenanceTaskProcessStepContext,
        key: getEntityIdentifierKey(entityIdentifier),
      }}
    >
      <MyEntityValidationResultContextProvider
        {...{
          entityIdentifier,
          context: MaintenanceTaskProcessStepProcessStepValidationResultContext,
        }}
      >
        <MyMaintenanceTaskProcessStepFieldMetaDataContextProvider>
          <MyEntityChangeContextProvider
            {...{
              entityIdentifier,
              context: MaintenanceTaskProcessStepChangeContext,
            }}
          >
            {children}
          </MyEntityChangeContextProvider>
        </MyMaintenanceTaskProcessStepFieldMetaDataContextProvider>
      </MyEntityValidationResultContextProvider>
    </MyEntityContextProvider>
  );
};

export default MyMaintenanceTaskProcessStepContextProvider;
