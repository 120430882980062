import React from "react";
import { EntityContextInterface } from "module/entity/context/entity/EntityContext";

export type MaintenanceTaskProcessStepQuickFixContextInterface =
  EntityContextInterface;

const MaintenanceTaskProcessStepQuickFixContext = React.createContext(
  <MaintenanceTaskProcessStepQuickFixContextInterface>{}
);

export default MaintenanceTaskProcessStepQuickFixContext;
