import { ValidationError } from "api/block/types";
import { EntityIdentifier, EntityType } from "module/entity/types";

export const mapBlockInterfaceToKey = (
  validationError: ValidationError
): EntityIdentifier[] => {
  const result: EntityIdentifier[] = [];

  // eslint-disable-next-line guard-for-in,no-restricted-syntax
  for (const type in validationError) {
    // eslint-disable-next-line guard-for-in,no-restricted-syntax
    for (const id in validationError[type]) {
      result.push({
        id,
        type: type as EntityType,
      });
    }
  }

  return result;
};
