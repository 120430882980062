import React, { useState, useCallback } from "react";
import FilterContext, {
  FiltersInterface,
} from "module/filter/context/FilterContext";

interface Props {
  initialValue: FiltersInterface;
  children: React.ReactNode;
}

const FilterContextProvider: React.FC<Props> = ({ initialValue, children }) => {
  const [filters, setFilters] = useState<FiltersInterface>(initialValue);

  const changeFilters = useCallback(
    (changes: FiltersInterface) => {
      setFilters({ ...filters, ...changes });
    },
    [filters]
  );

  const contextValue = { filters, changeFilters };

  return (
    <FilterContext.Provider value={contextValue}>
      {children}
    </FilterContext.Provider>
  );
};

export default FilterContextProvider;
