import React from "react";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import makeStyles from "@mui/styles/makeStyles";
import t from "module/translations";
import IconButton from "@mui/material/IconButton";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";

const useStyles = makeStyles({
  checkedResult: {
    minWidth: "250px",
  },
  toggleRow: {
    width: "30px",
  },
});

interface Props {
  isExpanded: boolean;
  toggle: () => void;
  isCheckedResultAvailable: boolean;
  isActionPerformedAvailable: boolean;
}

const MaintenanceTaskProcessStepListTableHead: React.FC<Props> = ({
  isExpanded,
  toggle,
  isCheckedResultAvailable,
  isActionPerformedAvailable,
}) => {
  const classes = useStyles();
  return (
    <TableHead>
      <TableRow>
        <TableCell className={classes.toggleRow}>
          <IconButton aria-label="expand row" size="small" onClick={toggle}>
            {isExpanded ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell align="left">
          {t.maintenanceTask.processStepActionText}
        </TableCell>
        <TableCell align="left" />
        <TableCell align="left">
          {t.maintenanceTask.processStepComponentText}
        </TableCell>
        <TableCell align="left">
          {t.maintenanceTask.processStepMeasureText}
        </TableCell>
        {isCheckedResultAvailable && (
          <TableCell align="left" className={classes.checkedResult}>
            {t.maintenanceTask.processStepCheckResultText}
          </TableCell>
        )}
        {isActionPerformedAvailable && (
          <TableCell align="left" className={classes.checkedResult}>
            {t.maintenanceTask.processStepActionPerformedText}
          </TableCell>
        )}
      </TableRow>
    </TableHead>
  );
};

export default React.memo(MaintenanceTaskProcessStepListTableHead);
