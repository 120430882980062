import { useCallback } from "react";
import { EntityFactory } from "module/entity/useEntityAbstractFactory";
import t from "module/translations";
import { getPathToMaintenanceTaskDetails } from "module/maintenanceTask/helper/getPath";
import useMyMaintenanceTaskReadableIdentifier from "module/maintenanceTask/readableIdentifier/useMyMaintenanceTaskReadableIdentifier";
import useMaintenanceTaskIsSyncAvailable from "module/maintenanceTask/sync/useMaintenanceTaskIsSyncAvailable";
import useMaintenanceTaskSyncHelper from "module/maintenanceTask/sync/useMaintenanceTaskSyncHelper";
import { getMaintenanceTaskSyncUnavailableTranslation } from "module/maintenanceTask/helper/getMaintenanceTaskSyncUnavailableTranslation";
import { MaintenanceTaskSyncUnavailableReason } from "module/maintenanceTask/types";

const useMaintenanceTaskFactory = (): EntityFactory => {
  const { isMaintenanceTaskSyncAvailable } =
    useMaintenanceTaskIsSyncAvailable();
  const { geMaintenanceTaskMappedToSync } = useMaintenanceTaskSyncHelper();
  const { getReadableIdentifier } = useMyMaintenanceTaskReadableIdentifier();

  const getPathToMyGroupedDetails = useCallback((): string => {
    throw new Error("Not supported.");
  }, []);

  const areSimilar = useCallback((): boolean => {
    throw new Error("Not supported.");
  }, []);

  const getSyncUnavailableReasonText = useCallback(
    (reason: string): string =>
      getMaintenanceTaskSyncUnavailableTranslation(
        reason as MaintenanceTaskSyncUnavailableReason
      ),
    []
  );

  return {
    getPathToMyDetails: getPathToMaintenanceTaskDetails,
    getPathToMyGroupedDetails,
    areSimilar,
    isSyncAvailable: isMaintenanceTaskSyncAvailable,
    getMappedToSync: geMaintenanceTaskMappedToSync,
    getReadableIdentifier,
    readableType: t.maintenanceTaskText,
    getSyncUnavailableReasonText,
  };
};

export default useMaintenanceTaskFactory;
