import React from "react";
import { Route } from "react-router-dom";
import FormContainsErrors from "component/form/FormContainsErrors";
import ButtonsContainer from "component/button/ButtonsContainer";
import OpenButton from "component/button/OpenButton";
import CloseButton from "component/button/CloseButton";
import TrainingCustomStateEditableFieldContainer from "module/trainingCustom/enrichedComponent/stateField/TrainingCustomStateEditableFieldContainer";
import routes from "routes/routes";
import {
  getPathToTrainingCustomDetailsAssignee,
  getPathToTrainingCustomDetailsOverview,
} from "module/trainingCustom/helper/getPath";
import TrainingCustomDetailsNavigation from "module/trainingCustom/enrichedComponent/detailsNavigation/TrainingCustomDetailsNavigation";

interface Props {
  trainingCustomId: string;
  hasError: boolean;
  open?: () => void;
  close?: () => void;
  isStateEditable: boolean;
}

const MyTrainingCustomDetails: React.FC<Props> = ({
  trainingCustomId,
  hasError,
  open,
  close,
  isStateEditable,
}) => {
  return (
    <>
      {hasError && <FormContainsErrors />}
      <TrainingCustomDetailsNavigation
        {...{
          listPath: routes.myCompetencyManagementResources.path,
          overviewPath:
            getPathToTrainingCustomDetailsOverview(trainingCustomId),
          assigneesPath:
            getPathToTrainingCustomDetailsAssignee(trainingCustomId),
        }}
      />
      <Route
        {...{
          key: routes.myTrainingCustomDetailsOverview.path,
          path: routes.myTrainingCustomDetailsOverview.path,
          render: () => (
            <routes.myTrainingCustomDetailsOverview.mainComponent />
          ),
        }}
      />
      <Route
        {...{
          key: routes.myTrainingCustomDetailsAssignees.path,
          path: routes.myTrainingCustomDetailsAssignees.path,
          render: () => (
            <routes.myTrainingCustomDetailsAssignees.mainComponent />
          ),
        }}
      />
      {(open || close) && (
        <ButtonsContainer>
          {open && <OpenButton {...{ onClick: open }} />}
          {close && <CloseButton {...{ onClick: close }} />}
          {isStateEditable && <TrainingCustomStateEditableFieldContainer />}
        </ButtonsContainer>
      )}
    </>
  );
};

export default React.memo(MyTrainingCustomDetails);
