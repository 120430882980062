import React, { useContext } from "react";
import { getFieldMetaData as getFieldMetaDataHelper } from "module/fieldMetaData/getFieldMetaData";
import TrainingOpenCloseContext from "module/training/openClose/context/TrainingOpenCloseContext";
import TrainingSessionFieldMetaDataContext, {
  TrainingSessionFieldMetaDataContextInterface,
} from "module/trainingSession/validation/fieldMetaData/TrainingSessionFieldMetaDataContext";
import TrainingStateContext from "module/training/state/context/TrainingStateContext";
import TrainingSessionValidationResultContext from "module/trainingSession/validation/TrainingSessionValidationResultContext";

interface Prop {
  children: React.ReactNode;
}
const MyTrainingSessionFieldMetaDataContextProvider: React.FC<Prop> = ({
  children,
}) => {
  const { validationWarnings, validationErrors } = useContext(
    TrainingSessionValidationResultContext
  );
  const { isOpen } = useContext(TrainingOpenCloseContext);
  const { isStateScheduledOrLower } = useContext(TrainingStateContext);

  const getFieldMetaData = getFieldMetaDataHelper({
    validationWarnings,
    validationErrors,
    isOpen,
    isStateScheduledOrLower,
  });

  const contextValue: TrainingSessionFieldMetaDataContextInterface = {
    getFieldMetaData,
  };

  return (
    <TrainingSessionFieldMetaDataContext.Provider value={contextValue}>
      {children}
    </TrainingSessionFieldMetaDataContext.Provider>
  );
};

export default MyTrainingSessionFieldMetaDataContextProvider;
