import { EntityType } from "module/entity/types";

export interface CompetencyManagementListFilters {
  scheduledDate: Date;
  types?: EntityType[];
}

export enum CompetencyManagementListSortingProperty {
  scheduledDate = "scheduledStartDate",
}
