import { XValidatorErrorsInterface } from "module/validation/types";
import { createNestedPropertyIfNotExist } from "toolkit/createNestedPropertyIfNotExist";
import { XRequiredValidator } from "module/validation/validator/requiredValidator";
import {
  AssessmentInterface,
  AssessmentObjectiveInterface,
  AssessmentState,
} from "module/assessment/types";
import { ASSESSMENT_OBJECTIVE_REMARKS_FIELD_NAME } from "module/assessmentObjective/constants";

export interface AssessmentValidationResult {
  errors: null | XValidatorErrorsInterface;
  warnings: null | XValidatorErrorsInterface;
}

export const validateAssessmentObjective = (
  assessment: AssessmentInterface,
  objective: AssessmentObjectiveInterface
): AssessmentValidationResult => {
  let errors: XValidatorErrorsInterface = {};
  const warnings: XValidatorErrorsInterface = {};

  if (
    assessment.state === AssessmentState.completed &&
    !objective.isCategory &&
    !objective.achieved &&
    !objective.remarks
  ) {
    errors = createNestedPropertyIfNotExist(
      errors,
      ASSESSMENT_OBJECTIVE_REMARKS_FIELD_NAME,
      []
    );
    errors[ASSESSMENT_OBJECTIVE_REMARKS_FIELD_NAME].push(
      new XRequiredValidator().message
    );
  }

  return {
    errors: Object.keys(errors).length ? errors : null,
    warnings: Object.keys(warnings).length ? warnings : null,
  };
};
