import t from "module/translations";
import { InventoryCheckSyncUnavailableReason } from "module/inventoryCheck/types";

export const getInventoryCheckSyncUnavailableTranslation = (
  unavailableReason: InventoryCheckSyncUnavailableReason
): string => {
  return {
    [InventoryCheckSyncUnavailableReason.notValid]:
      t.maintenanceTask.sync.unavailableReason.notValidText,
    [InventoryCheckSyncUnavailableReason.isOpen]:
      t.maintenanceTask.sync.unavailableReason.isOpenText,
    [InventoryCheckSyncUnavailableReason.offline]:
      t.maintenanceTask.sync.unavailableReason.offlineText,
    [InventoryCheckSyncUnavailableReason.noPermissions]:
      t.maintenanceTask.sync.unavailableReason.noPermissionsText,
    [InventoryCheckSyncUnavailableReason.hasNotChanged]:
      t.maintenanceTask.sync.unavailableReason.hasNotChangedText,
    [InventoryCheckSyncUnavailableReason.stateIsNotSupported]:
      t.maintenanceTask.sync.unavailableReason.stateIsNotSupportedText,
  }[unavailableReason];
};
