import React, { useContext, useCallback } from "react";
import { useHistory } from "react-router-dom";
import AssessmentToBeSignedDetailsObjectives from "pages/assessmentToBeSignedDetails/objectives/AssessmentToBeSignedDetailsObjectives";
import AssessmentContext from "module/assessment/context/AssessmentContext";
import AssessmentSignContext from "module/assessment/sign/AssessmentSignContext";
import { AssessmentInterface } from "module/assessment/types";
import routes from "routes/routes";

const AssessmentToBeSignedDetailsObjectivesContainer: React.FC = () => {
  const { entity } = useContext(AssessmentContext);
  const { sign: signHelper } = useContext(AssessmentSignContext);
  const history = useHistory();

  const assessment = entity as AssessmentInterface;

  const sign = useCallback(async (): Promise<void> => {
    await signHelper();
    history.push(routes.toBeSigned.path);
  }, [history, signHelper]);

  return (
    <AssessmentToBeSignedDetailsObjectives
      {...{ objectivesIdentifiers: assessment.objectives, sign }}
    />
  );
};

export default React.memo(AssessmentToBeSignedDetailsObjectivesContainer);
