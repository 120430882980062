import React from "react";
import Table from "component/table/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "component/table/TableContainer";
import { EntityIdentifier } from "module/entity/types";
import t from "module/translations";
import ListToolbarContainer from "module/listToolbar/ListToolbarContainer";
import { getEntityIdentifierKey } from "module/entity/helper/getEntityIdentifierKey";
import { InventoryCheckInterface } from "module/inventoryCheck/types";
import InventoryCheckContext from "module/inventoryCheck/context/InventoryCheckContext";
import MyInventoryCheckListTableHeadContainer from "./table/head/MyInventoryCheckListTableHeadContainer";
import MyInventoryCheckListTableRowContainer from "./table/row/MyInventoryCheckListTableRowContainer";

interface Props {
  entities: Map<EntityIdentifier, InventoryCheckInterface>;
}

const MyInventoryCheckList: React.FC<Props> = ({ entities }) => {
  return (
    <>
      <TableContainer>
        <ListToolbarContainer
          {...{
            title: t.routes.inventoryChecksOffline,
            isSyncAvailable: true,
          }}
        />
        <Table>
          <MyInventoryCheckListTableHeadContainer />
          <TableBody>
            {Array.from(entities.keys()).map((entityIdentifier) => (
              <InventoryCheckContext.Provider
                {...{
                  key: getEntityIdentifierKey(entityIdentifier),
                  value: {
                    entity: entities.get(
                      entityIdentifier
                    ) as InventoryCheckInterface,
                  },
                }}
              >
                <MyInventoryCheckListTableRowContainer />
              </InventoryCheckContext.Provider>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

export default React.memo(MyInventoryCheckList);
