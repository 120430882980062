import React, { useContext, useMemo } from "react";
import { TrainingInterface } from "module/training/types";
import EntityCollectionContext from "module/entity/context/entityCollection/EntityCollectionContext";
import TrainingCollectionAssigneeLabel from "module/training/enrichedComponent/assigneesField/TrainingCollectionAssigneeLabel";

const TrainingCollectionAssigneeLabelContainer: React.FC = () => {
  const { entityCollection } = useContext(EntityCollectionContext);

  const trainings = useMemo(
    () => Array.from(entityCollection.values()) as TrainingInterface[],
    [entityCollection]
  );

  const assigneeIds = useMemo(
    () =>
      trainings.reduce((result, singleTraining) => {
        if (singleTraining.assigneeId) {
          return [...result, singleTraining.assigneeId];
        }
        return result;
      }, [] as number[]),
    [trainings]
  );

  return <TrainingCollectionAssigneeLabel {...{ assigneeIds }} />;
};

export default React.memo(TrainingCollectionAssigneeLabelContainer);
