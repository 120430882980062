import {
  MaintenanceTaskProcessStepQuickFixInterface,
  MaintenanceTaskProcessStepQuickFixToSyncInterface,
} from "module/maintenanceTaskProcessStepQuickFix/types";

export const mapMaintenanceTaskProcessStepQuickFixToSync = (
  maintenanceTaskProcessStepQuickFix: MaintenanceTaskProcessStepQuickFixInterface
): MaintenanceTaskProcessStepQuickFixToSyncInterface => {
  return {
    type: maintenanceTaskProcessStepQuickFix.type as string,
  };
};
