import React, { useMemo } from "react";
import InventoryCheckOpenCloseContext, {
  InventoryCheckOpenCloseContextInterface,
} from "module/inventoryCheck/openClose/context/InventoryCheckOpenCloseContext";
import useInventoryCheckOpenClose from "module/inventoryCheck/openClose/useInventoryCheckOpenClose";

interface Prop {
  children: React.ReactNode;
}

const MyInventoryCheckOpenCloseContextProvider: React.FC<Prop> = ({
  children,
}) => {
  const { open, close } = useInventoryCheckOpenClose();

  const contextValue: InventoryCheckOpenCloseContextInterface = useMemo(
    () => ({
      isOpen: close !== undefined,
      open,
      close,
    }),
    [close, open]
  );

  return (
    <InventoryCheckOpenCloseContext.Provider value={contextValue}>
      {children}
    </InventoryCheckOpenCloseContext.Provider>
  );
};

export default MyInventoryCheckOpenCloseContextProvider;
