import React, { useContext } from "react";
import TableSortCell from "component/table/tableSortCell/TableSortCell";
import SortContext from "module/sort/context/SortContext";

interface Props {
  fieldName: string;
  children: React.ReactNode;
}

const TableSortCellContainer: React.FC<Props> = ({ fieldName, children }) => {
  const { sort, changeSort } = useContext(SortContext);
  return (
    <TableSortCell
      {...{
        fieldName,
        currentSort: sort,
        onClick: changeSort,
      }}
    >
      {children}
    </TableSortCell>
  );
};

export default React.memo(TableSortCellContainer);
