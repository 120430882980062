import React from "react";
import IconButton from "@mui/material/IconButton";
import AddCircleIcon from "@mui/icons-material/AddCircle";

interface Props {
  onClick: () => void;
}

const AddIconButton: React.FC<Props> = ({ onClick }) => {
  return (
    <IconButton size="small" color="primary" onClick={onClick}>
      <AddCircleIcon />
    </IconButton>
  );
};

export default React.memo(AddIconButton);
