import React from "react";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import t from "module/translations";
import useDateFormat from "toolkit/useDateFormat";
import StaffNameText from "module/staff/StaffNameText";
import LinkWithIcon from "component/link/LinkWithIcon";
import { EntityIdentifier } from "module/entity/types";
import TableToggleCellContainer from "module/toggle/TableToggleCellContainer";
import { TrainingCustomInterface } from "module/trainingCustom/types";
import TrainingCustomAssigneesLabelContainer from "module/trainingCustom/enrichedComponent/assigneesField/TrainingCustomAssigneesLabelContainer";
import { getTrainingCustomStateTranslation } from "module/trainingCustom/helper/getTrainingCustomStateTranslation";
import TrainingCustomDeleteButtonContainer from "module/trainingCustom/enrichedComponent/deleteButton/TrainingCustomDeleteButtonContainer";
import { getPathToTrainingCustomDetails } from "module/trainingCustom/helper/getPath";

interface Props {
  trainingCustom: TrainingCustomInterface;
  isSelected: boolean;
  entityIdentifier: EntityIdentifier;
}

const MyCompetencyManagementListTableRowTraining: React.FC<Props> = ({
  isSelected,
  trainingCustom,
  entityIdentifier,
}) => {
  const { formatDateTime } = useDateFormat();
  return (
    <TableRow
      {...{
        hover: true,
        tabIndex: -1,
        role: "checkbox",
        "aria-checked": isSelected,
        selected: isSelected,
        key: trainingCustom.id,
      }}
    >
      <TableToggleCellContainer {...{ entityIdentifier }} />
      <TableCell align="left">{trainingCustom.reportingNumber}</TableCell>
      <TableCell align="left">
        <LinkWithIcon
          {...{
            label: trainingCustom.name,
            path: getPathToTrainingCustomDetails(trainingCustom.id),
          }}
        />
      </TableCell>
      <TableCell align="left">
        <TrainingCustomAssigneesLabelContainer />
      </TableCell>
      <TableCell align="left">
        {trainingCustom.internalTrainerId && (
          <StaffNameText {...{ staffId: trainingCustom.internalTrainerId }} />
        )}
        {trainingCustom.externalTrainer ? trainingCustom.externalTrainer : ""}
        {trainingCustom.internalTrainerId == null &&
          trainingCustom.externalTrainer == null &&
          "-"}
      </TableCell>
      <TableCell align="left">
        {t.competencyManagement.trainingCustom.typeText}
      </TableCell>
      <TableCell align="left">
        {formatDateTime(trainingCustom.scheduledStartDate)}
      </TableCell>
      <TableCell align="left">
        {getTrainingCustomStateTranslation(trainingCustom.state)}
      </TableCell>
      <TableCell align="right">
        <TrainingCustomDeleteButtonContainer />
      </TableCell>
    </TableRow>
  );
};

export default React.memo(MyCompetencyManagementListTableRowTraining);
