import React from "react";
import Table from "@mui/material/Table";
import StickyPaperTableContainer from "component/sticky/StickyPaperTableContainer";
import { EntityIdentifier } from "module/entity/types";
import { getEntityIdentifierKey } from "module/entity/helper/getEntityIdentifierKey";
import TableBody from "component/table/tableBody/TableBody";
import InventoryCheckArticleListTableHead from "module/inventoryCheckArticle/enrichedComponent/tableHead/InventoryCheckArticleListTableHead";
import MyInventoryCheckArticleContextProvider from "module/inventoryCheckArticle/context/MyInventoryCheckArticleContextProvider";
import InventoryCheckArticleListRowContainer from "module/inventoryCheckArticle/enrichedComponent/tableRow/InventoryCheckArticleListRowContainer";

interface Props {
  entityIdentifiers: EntityIdentifier[];
}

const MyInventoryCheckArticleList: React.FC<Props> = ({
  entityIdentifiers,
}) => {
  return (
    <StickyPaperTableContainer>
      <Table stickyHeader>
        <InventoryCheckArticleListTableHead />
        <TableBody>
          {entityIdentifiers.map((entityIdentifier) => (
            <MyInventoryCheckArticleContextProvider
              {...{
                entityIdentifier,
                key: getEntityIdentifierKey(entityIdentifier),
              }}
            >
              <InventoryCheckArticleListRowContainer
                {...{ key: getEntityIdentifierKey(entityIdentifier) }}
              />
            </MyInventoryCheckArticleContextProvider>
          ))}
        </TableBody>
      </Table>
    </StickyPaperTableContainer>
  );
};

export default React.memo(MyInventoryCheckArticleList);
