import React, { useEffect, useMemo } from "react";
import { useHistory, useParams, useRouteMatch } from "react-router-dom";
import routes from "routes/routes";
import { getPathToAssessmentDetailsOverview } from "module/assessment/helper/getPath";
import MyAssessmentDetailsContainer from "pages/myAssessmentDetails/MyAssessmentDetailsContainer";
import { EntityIdentifier, EntityType } from "module/entity/types";
import MyAssessmentDetailsContextProvider from "pages/myAssessmentDetails/MyAssessmentDetailsContextProvider";

const MyAssessmentDetailsPage: React.FC = () => {
  const { assessmentId: id } = useParams<{ assessmentId: string }>();
  const history = useHistory();
  const match = useRouteMatch({
    path: routes.myAssessmentDetails.path,
    exact: true,
  });

  useEffect(() => {
    if (id && match) {
      history.push(getPathToAssessmentDetailsOverview(id));
    }
  }, [history, id, match]);

  const entityIdentifier: EntityIdentifier = useMemo(
    () => ({
      id,
      type: EntityType.assessment,
    }),
    [id]
  );

  return (
    <MyAssessmentDetailsContextProvider {...{ entityIdentifier }}>
      <MyAssessmentDetailsContainer />
    </MyAssessmentDetailsContextProvider>
  );
};

export default React.memo(MyAssessmentDetailsPage);
