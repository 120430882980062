import React, { useCallback, useEffect, useState, useMemo } from "react";
import { useHistory, useParams, useRouteMatch } from "react-router-dom";
import routes from "routes/routes";
import { getPathToAssessmentToBeSignedDetailsOverview } from "module/assessment/helper/getPath";
import AssessmentToBeSignedDetailsContainer from "pages/assessmentToBeSignedDetails/AssessmentToBeSignedDetailsContainer";
import { EntityIdentifier, EntityType } from "module/entity/types";
import { AssessmentInterface } from "module/assessment/types";
import getAssessmentDetails from "api/competencyManagement/getAssessmentDetails";
import { useAppSelector } from "redux/hooks";
import { selectUser } from "module/auth/redux/authSlice";
import { toastWarning } from "layout/toast/helper";
import t from "module/translations";
import AssessmentSignContext from "module/assessment/sign/AssessmentSignContext";
import EntitySignContextProvider from "module/entity/context/sign/EntitySignContextProvider";
import AssessmentContext from "module/assessment/context/AssessmentContext";
import { canSignAssessment } from "module/assessment/helper/canSignAssessment";

const AssessmentToBeSignedDetailsPage: React.FC = () => {
  const { assessmentId: id } = useParams<{ assessmentId: string }>();
  const [assessment, setAssessment] =
    useState<AssessmentInterface | null>(null);
  const history = useHistory();
  const match = useRouteMatch({
    path: routes.assessmentToBeSignedDetails.path,
    exact: true,
  });
  const loggedInStaffId = useAppSelector(selectUser).id;

  useEffect(() => {
    if (id && match) {
      history.push(getPathToAssessmentToBeSignedDetailsOverview(id));
    }
  }, [history, id, match]);

  const load = useCallback(async () => {
    const myAssessment = await getAssessmentDetails(Number(id));
    setAssessment(myAssessment.data);
  }, [id]);

  useEffect(() => {
    if (
      assessment != null &&
      !canSignAssessment({ assessment, loggedInStaffId })
    ) {
      toastWarning(t.toBeSigned.itemNoAbleToSignText);
      history.push(routes.toBeSigned.path);
    }
  }, [assessment, history, loggedInStaffId]);

  useEffect(() => {
    load();
  }, [load]);

  const entityIdentifier: EntityIdentifier = useMemo(
    () => ({
      id,
      type: EntityType.assessment,
      backendId: Number(id),
    }),
    [id]
  );

  if (assessment == null) {
    return null;
  }

  return (
    <AssessmentContext.Provider value={{ entity: assessment }}>
      <EntitySignContextProvider
        {...{ entityIdentifier, context: AssessmentSignContext }}
      >
        <AssessmentToBeSignedDetailsContainer />
      </EntitySignContextProvider>
    </AssessmentContext.Provider>
  );
};

export default React.memo(AssessmentToBeSignedDetailsPage);
