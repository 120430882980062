import React, { useContext } from "react";
import MaintenanceTaskContext from "module/maintenanceTask/context/MaintenanceTaskContext";
import { MaintenanceTaskInterface } from "module/maintenanceTask/types";
import MaintenanceTaskToBeSignedPartList from "pages/maintenanceTaskToBeSignedDetails/partList/MaintenanceTaskToBeSignedPartList";

const MaintenanceTaskToBeSignedPartListContainer: React.FC = () => {
  const { entity } = useContext(MaintenanceTaskContext);
  const maintenanceTask = entity as MaintenanceTaskInterface;

  return (
    <MaintenanceTaskToBeSignedPartList
      {...{ entityIdentifiers: maintenanceTask.parts }}
    />
  );
};

export default React.memo(MaintenanceTaskToBeSignedPartListContainer);
