// eslint-disable-next-line max-classes-per-file
import t from "module/translations";
import {
  ValidatorInterface,
  ValidatorSerializedDataInterface,
  XValidatorInterface,
} from "module/validation/types";

export interface RequiredValidatorSerializedInterface
  extends ValidatorSerializedDataInterface {
  fieldName: string;
}

class RequiredValidator implements ValidatorInterface {
  private readonly fieldName: string;

  static validatorIdentifier = "required";

  public message: string;

  constructor(fieldName: string) {
    this.fieldName = fieldName;
    this.message = t.validation.fieldIsRequiredText;
  }

  serialize(): RequiredValidatorSerializedInterface {
    return {
      validatorIdentifier: RequiredValidator.validatorIdentifier,
      fieldName: this.fieldName,
    };
  }
}

export class XRequiredValidator implements XValidatorInterface {
  public message: string;

  constructor() {
    this.message = t.validation.fieldIsRequiredText;
  }
}

export default RequiredValidator;
