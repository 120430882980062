import { useCallback, useContext, useMemo } from "react";
import { useAppDispatch, useAppSelector } from "redux/hooks";
import { selectSystem, selectUser } from "module/auth/redux/authSlice";
import usePermissions from "module/permission/usePermissions";
import { MaintenanceTaskInterface } from "module/maintenanceTask/types";
import MaintenanceTaskContext from "module/maintenanceTask/context/MaintenanceTaskContext";
import { EntityType } from "module/entity/types";
import MaintenanceTaskOpenCloseContext from "module/maintenanceTask/openClose/context/MaintenanceTaskOpenCloseContext";
import useMyEntityValidation from "module/entity/useMyEntityValidation";
import { API_DATE_TIME_FORMAT } from "api/date";
import {
  addToEntityPropertyList,
  addToMyEntities,
} from "module/staff/redux/staffDataSlice";
import { v4 as uuid } from "uuid";
import moment from "moment";
import { canAddMaintenanceTaskPersonnelAssignmentExternalStaff } from "module/maintenanceTaskPersonnelAssignment/externalStaff/helper/canAddMaintenanceTaskPersonnelAssignmentExternalStaff";
import {
  MaintenanceTaskPersonnelAssignmentExternalStaffInterface,
  MyMaintenanceTaskPersonnelAssignmentExternalStaffInterface,
} from "module/maintenanceTaskPersonnelAssignment/externalStaff/types";

interface Result {
  canAdd: boolean;
  add: () => void;
}

const useMaintenanceTaskPersonnelAssignmentExternalStaffAdd = (): Result => {
  const { entity } = useContext(MaintenanceTaskContext);
  const maintenanceTask = entity as MaintenanceTaskInterface;

  const { isOpen } = useContext(MaintenanceTaskOpenCloseContext);

  const { canEditMaintenanceTask } = usePermissions();

  const dispatch = useAppDispatch();
  const staffId = useAppSelector(selectUser).id;
  const systemShortName = useAppSelector(selectSystem).shortName;

  const { validateMyEntities } = useMyEntityValidation();

  const canAdd = useMemo(
    () =>
      canAddMaintenanceTaskPersonnelAssignmentExternalStaff({
        isMaintenanceTaskOpen: isOpen,
        maintenanceTask,
        canEditMaintenanceTask,
      }),
    [canEditMaintenanceTask, isOpen, maintenanceTask]
  );

  const add = useCallback(() => {
    const maintenanceTaskEntityIdentifier = {
      id: maintenanceTask.id,
      type: EntityType.maintenanceTask,
    };

    const id = uuid();
    const entityIdentifier = {
      type: EntityType.maintenanceTaskPersonnelAssignmentExternalStaff,
      id,
    };

    const today = moment()
      .set({ hour: 0, minute: 0, second: 0 })
      .format(API_DATE_TIME_FORMAT);

    const data: MaintenanceTaskPersonnelAssignmentExternalStaffInterface = {
      id,
      maintenanceTaskId: maintenanceTask.id,
      relationships: undefined,
      relationshipIdentifiers: [],
      changedDateTime: today,
    };

    const myEntity: MyMaintenanceTaskPersonnelAssignmentExternalStaffInterface =
      {
        entityIdentifier,
        data,
        dataWithoutChanges: data,
        validationErrors: null,
        validationWarnings: null,
      };

    dispatch(
      addToMyEntities({
        staffId,
        systemShortName,
        entities: [myEntity],
      })
    );

    validateMyEntities([entityIdentifier]);

    dispatch(
      addToEntityPropertyList({
        staffId,
        systemShortName,
        baseEntityIdentifier: maintenanceTaskEntityIdentifier,
        propertyName: "personnelAssignments",
        entityIdentifierToAdd: entityIdentifier,
      })
    );
  }, [
    dispatch,
    maintenanceTask.id,
    staffId,
    systemShortName,
    validateMyEntities,
  ]);

  return {
    canAdd,
    add,
  };
};

export default useMaintenanceTaskPersonnelAssignmentExternalStaffAdd;
