import {
  TrainingCustomAssigneeInterface,
  TrainingCustomInterface,
  TrainingCustomState,
} from "module/trainingCustom/types";

interface Props {
  trainingCustom: TrainingCustomInterface;
  assignees: TrainingCustomAssigneeInterface[];
  loggedInStaffId: number;
}

export const canSignTrainingCustom = ({
  trainingCustom,
  assignees,
  loggedInStaffId,
}: Props): boolean =>
  trainingCustom.state === TrainingCustomState.completed &&
  (Boolean(
    assignees.filter((assignee) => assignee.staffId === loggedInStaffId).length
  ) ||
    trainingCustom.internalTrainerId === loggedInStaffId);
