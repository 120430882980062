import React from "react";
import MyCompetencyManagementListTableRowAssessmentContainer from "pages/myCompetencyManagementList/table/row/assessment/MyCompetencyManagementListTableRowAssessmentContainer";
import MyCompetencyManagementListTableRowTrainingContainer from "pages/myCompetencyManagementList/table/row/training/MyCompetencyManagementListTableRowTrainingContainer";
import { EntityIdentifier, EntityType } from "module/entity/types";
import MyEntityContextProvider from "module/entity/context/entity/MyEntityContextProvider";
import TrainingContext from "module/training/context/TrainingContext";
import AssessmentContext from "module/assessment/context/AssessmentContext";
import TrainingCustomContext from "module/trainingCustom/context/TrainingCustomContext";
import MyCompetencyManagementListTableRowTrainingCustomContainer from "pages/myCompetencyManagementList/table/row/trainingCustom/MyCompetencyManagementListTableRowTrainingCustomContainer";

interface Props {
  entityIdentifier: EntityIdentifier;
}

const MyCompetencyManagementListTableRowContainer: React.FC<Props> = ({
  entityIdentifier,
}) => {
  if (entityIdentifier.type === EntityType.assessment) {
    return (
      <MyEntityContextProvider
        {...{ entityIdentifier, context: AssessmentContext }}
      >
        <MyCompetencyManagementListTableRowAssessmentContainer />
      </MyEntityContextProvider>
    );
  }

  if (entityIdentifier.type === EntityType.training) {
    return (
      <MyEntityContextProvider
        {...{ entityIdentifier, context: TrainingContext }}
      >
        <MyCompetencyManagementListTableRowTrainingContainer />
      </MyEntityContextProvider>
    );
  }

  if (entityIdentifier.type === EntityType.trainingCustom) {
    return (
      <MyEntityContextProvider
        {...{ entityIdentifier, context: TrainingCustomContext }}
      >
        <MyCompetencyManagementListTableRowTrainingCustomContainer />
      </MyEntityContextProvider>
    );
  }

  return null;
};

export default React.memo(MyCompetencyManagementListTableRowContainer);
