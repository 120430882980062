import React, { useContext } from "react";
import ToggleContext from "module/toggle/context/ToggleContext";
import MyMaintenanceTaskListTableHead from "pages/myMaintenanceTaskList/table/head/MyMaintenanceTaskListTableHead";

const MyMaintenanceTaskListTableHeadContainer: React.FC = () => {
  const { areAllSelected, isAnySelected, toggleAll, canAnyBeSelected } =
    useContext(ToggleContext);

  return (
    <MyMaintenanceTaskListTableHead
      {...{
        toggleAll,
        isAnySelected,
        areAllSelected,
        canAnyBeSelected,
      }}
    />
  );
};

export default React.memo(MyMaintenanceTaskListTableHeadContainer);
