import React, { useEffect, useState, useCallback, useMemo } from "react";
import { useHistory, useParams, useRouteMatch } from "react-router-dom";
import routes from "routes/routes";
import { getPathToMaintenanceTaskToBeSignedDetailsOverview } from "module/maintenanceTask/helper/getPath";
import { EntityIdentifier, EntityType } from "module/entity/types";
import { useAppSelector } from "redux/hooks";
import { selectUser } from "module/auth/redux/authSlice";
import { toastWarning } from "layout/toast/helper";
import t from "module/translations";
import EntitySignContextProvider from "module/entity/context/sign/EntitySignContextProvider";
import { MaintenanceTaskInterface } from "module/maintenanceTask/types";
import MaintenanceTaskSignContext from "module/maintenanceTask/sign/MaintenanceTaskSignContext";
import MaintenanceTaskContext from "module/maintenanceTask/context/MaintenanceTaskContext";
import getMaintenanceTaskDetails from "api/maintenanceTask/getMaintenanceTaskDetails";
import { canSignMaintenanceTask } from "module/maintenanceTask/helper/canSignMaintenanceTask";
import MyMaintenanceTaskDetailsContainer from "pages/maintenanceTaskToBeSignedDetails/MaintenanceTaskToBeSignedDetailsContainer";

const MyMaintenanceTaskDetailsPage: React.FC = () => {
  const { maintenanceTaskId: id } = useParams<{ maintenanceTaskId: string }>();
  const [maintenanceTask, setMaintenanceTask] =
    useState<MaintenanceTaskInterface | null>(null);
  const history = useHistory();
  const match = useRouteMatch({
    path: routes.maintenanceTaskToBeSignedDetails.path,
    exact: true,
  });
  const loggedInStaffId = useAppSelector(selectUser).id;

  useEffect(() => {
    if (id && match) {
      history.push(getPathToMaintenanceTaskToBeSignedDetailsOverview(id));
    }
  }, [history, id, match]);

  const load = useCallback(async () => {
    const myMaintenanceTask = await getMaintenanceTaskDetails(Number(id));
    setMaintenanceTask(myMaintenanceTask.data);
  }, [id]);

  useEffect(() => {
    if (
      maintenanceTask != null &&
      !canSignMaintenanceTask({ maintenanceTask, loggedInStaffId })
    ) {
      toastWarning(t.toBeSigned.itemNoAbleToSignText);
      history.push(routes.toBeSigned.path);
    }
  }, [history, loggedInStaffId, maintenanceTask]);

  useEffect(() => {
    load();
  }, [load]);

  const entityIdentifier: EntityIdentifier = useMemo(
    () => ({
      id,
      type: EntityType.maintenanceTask,
      backendId: Number(id),
    }),
    [id]
  );

  if (maintenanceTask == null) {
    return null;
  }

  return (
    <MaintenanceTaskContext.Provider value={{ entity: maintenanceTask }}>
      <EntitySignContextProvider
        {...{ entityIdentifier, context: MaintenanceTaskSignContext }}
      >
        <MyMaintenanceTaskDetailsContainer />
      </EntitySignContextProvider>
    </MaintenanceTaskContext.Provider>
  );
};

export default React.memo(MyMaintenanceTaskDetailsPage);
