import React, { useContext, useCallback } from "react";
import MaintenanceTaskContext from "module/maintenanceTask/context/MaintenanceTaskContext";
import { MaintenanceTaskInterface } from "module/maintenanceTask/types";
import t from "module/translations";
import XLabelField from "component/labelField/XLabelField";
import MaintenanceTaskChangeContext from "module/maintenanceTask/change/MaintenanceTaskChangeContext";
import MaintenanceTaskFieldMetaDataContext from "module/maintenanceTask/validation/fieldMetaData/MaintenanceTaskFieldMetaDataContext";
import { getFieldMetaDataWithErrorHandling } from "module/fieldMetaData/getFieldMetaData";
import { MAINTENANCE_TASK_MAINTAINED_AT_VALUE_FIELD_NAME } from "module/maintenanceTask/constants";
import NumberField from "component/numberField/NumberField";

const MaintenanceTaskMaintainedAtValueFieldContainer: React.FC = () => {
  const { entity } = useContext(MaintenanceTaskContext);
  const { change: changeHelper } = useContext(MaintenanceTaskChangeContext);
  const { getFieldMetaData } = useContext(MaintenanceTaskFieldMetaDataContext);

  const maintenanceTask = entity as MaintenanceTaskInterface;

  const change = useCallback(
    (maintainedAtValue?: number) => {
      changeHelper({ maintainedAtValue });
    },
    [changeHelper]
  );

  const { warnings, errors, isEditable } = getFieldMetaDataWithErrorHandling(
    () => getFieldMetaData(MAINTENANCE_TASK_MAINTAINED_AT_VALUE_FIELD_NAME)
  );

  const label = t.maintenanceTask.maintainedAtValueText;

  return isEditable ? (
    <div>
      <NumberField
        {...{
          value: maintenanceTask.maintainedAtValue,
          errors,
          warnings,
          change,
        }}
      />
    </div>
  ) : (
    <XLabelField
      {...{
        caption: label,
        label: `${maintenanceTask.maintainedAtValue || "-"}`,
        errors,
        warnings,
      }}
    />
  );
};

export default React.memo(MaintenanceTaskMaintainedAtValueFieldContainer);
