import { useRef } from "react";
import _ from "lodash";

const DEBOUNCE_TIME = 600;

type UseDebounceResponse = () => void;

const useDebounce = (
  functionToDebounce: unknown,
  time: number = DEBOUNCE_TIME
): UseDebounceResponse => {
  return useRef(
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    _.debounce(functionToDebounce, time)
  ).current;
};

export default useDebounce;
