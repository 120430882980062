import React, { useContext } from "react";
import MaintenanceTaskContext from "module/maintenanceTask/context/MaintenanceTaskContext";
import { MaintenanceTaskInterface } from "module/maintenanceTask/types";
import CollapsedListContextProvider from "module/collapsedList/context/CollapsedListContextProvider";
import MaintenanceTaskToBeSignedProcessStepList from "pages/maintenanceTaskToBeSignedDetails/processStepList/MaintenanceTaskToBeSignedProcessStepList";

const MaintenanceTaskToBeSignedProcessStepListContainer: React.FC = () => {
  const { entity } = useContext(MaintenanceTaskContext);
  const maintenanceTask = entity as MaintenanceTaskInterface;

  return (
    <CollapsedListContextProvider {...{ initialValue: true }}>
      <MaintenanceTaskToBeSignedProcessStepList
        {...{ entityIdentifiers: maintenanceTask.processSteps }}
      />
    </CollapsedListContextProvider>
  );
};

export default React.memo(MaintenanceTaskToBeSignedProcessStepListContainer);
