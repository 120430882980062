import React from "react";
import { EntityIdentifier } from "module/entity/types";

export interface EntityDownloadContextInterface {
  download: (keys: EntityIdentifier[]) => Promise<void>;
}

const EntityDownloadContext = React.createContext(
  <EntityDownloadContextInterface>{}
);

export default EntityDownloadContext;
