import { EntityIdentifier, EntityType } from "module/entity/types";
import { MyMaintenanceTaskInterface } from "api/maintenanceTask/types";

export const sortMaintenanceTasks = (
  rawMaintenanceTasks: MyMaintenanceTaskInterface[],
  maintenanceTasksIdsAssignedToMe: string[]
): Map<EntityIdentifier, MyMaintenanceTaskInterface> => {
  const items: Map<EntityIdentifier, MyMaintenanceTaskInterface> = new Map();
  const myItems: Map<EntityIdentifier, MyMaintenanceTaskInterface> = new Map();
  const notMyItems: Map<EntityIdentifier, MyMaintenanceTaskInterface> =
    new Map();

  rawMaintenanceTasks.map(
    // eslint-disable-next-line array-callback-return
    (rawMaintenanceTask) => {
      const key: EntityIdentifier = {
        id: rawMaintenanceTask.data.id,
        type: EntityType.maintenanceTask,
      };

      // assigned to me first
      if (
        maintenanceTasksIdsAssignedToMe.includes(rawMaintenanceTask.data.id)
      ) {
        myItems.set(key, rawMaintenanceTask);
      } else {
        notMyItems.set(key, rawMaintenanceTask);
      }
    }
  );

  Array.from(myItems.keys()).map((key) =>
    items.set(key, myItems.get(key) as MyMaintenanceTaskInterface)
  );

  Array.from(notMyItems.keys()).map((key) =>
    items.set(key, notMyItems.get(key) as MyMaintenanceTaskInterface)
  );

  return items;
};
