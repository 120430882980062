import React, { useContext, useMemo } from "react";
import TableInfoIconCell from "component/table/infoIconCell/TableInfoIconCell";
import { EntityIdentifier, EntityType } from "module/entity/types";
import TableToggleCellContainer from "module/toggle/TableToggleCellContainer";
import InventoryCheckContext from "module/inventoryCheck/context/InventoryCheckContext";
import {
  InventoryCheckInterface,
  InventoryCheckSyncUnavailableReason,
} from "module/inventoryCheck/types";
import useInventoryCheckIsSyncAvailable from "module/inventoryCheck/sync/useInventoryCheckIsSyncAvailable";
import { getInventoryCheckSyncUnavailableTranslation } from "module/inventoryCheck/helper/getInventoryCheckSyncUnavailableTranslation";

const MyInventoryCheckListTableCellSyncContainer: React.FC = () => {
  const { entity } = useContext(InventoryCheckContext);
  const inventoryCheck = entity as InventoryCheckInterface;

  const { isInventoryCheckSyncAvailable } = useInventoryCheckIsSyncAvailable();

  const canSync = useMemo(
    () => isInventoryCheckSyncAvailable(inventoryCheck.id),
    [inventoryCheck.id, isInventoryCheckSyncAvailable]
  );

  const entityIdentifier: EntityIdentifier = useMemo(
    () => ({
      id: inventoryCheck.id,
      type: EntityType.inventoryCheck,
    }),
    [inventoryCheck.id]
  );

  if (canSync.isAvailable) {
    return <TableToggleCellContainer {...{ entityIdentifier }} />;
  }

  if (canSync.unavailableReason != null) {
    return (
      <TableInfoIconCell
        {...{
          label: getInventoryCheckSyncUnavailableTranslation(
            canSync.unavailableReason as InventoryCheckSyncUnavailableReason
          ),
        }}
      />
    );
  }

  return null;
};

export default React.memo(MyInventoryCheckListTableCellSyncContainer);
