import React from "react";
import makeStyles from "@mui/styles/makeStyles";
import StockArticleListToolbarFilterExternalIdContainer from "../../module/stockArticle/enrichedComponent/externalIdFilterField/StockArticleListToolbarFilterExternalIdContainer";
import StockArticleListToolbarFilterNameContainer from "../../module/stockArticle/enrichedComponent/nameFilterField/StockArticleListToolbarFilterNameContainer";

const useStyles = makeStyles({
  field: {
    marginLeft: "1em",
    width: "400px",
  },
});

const InventoryListFilters: React.FC = () => {
  const classes = useStyles();
  return (
    <>
      <span className={classes.field}>
        <StockArticleListToolbarFilterExternalIdContainer />
      </span>
      <span className={classes.field}>
        <StockArticleListToolbarFilterNameContainer />
      </span>
    </>
  );
};

export default React.memo(InventoryListFilters);
