import React, { useContext } from "react";
import { AssessmentObjectiveInterface } from "module/assessment/types";
import AssessmentObjectiveContext from "module/assessmentObjective/context/AssessmentObjectiveContext";
import AssessmentObjectiveTableRowCategory from "module/assessmentObjective/enrichedComponent/tableRow/category/AssessmentObjectiveTableRowCategory";

const AssessmentObjectiveTableRowCategoryContainer: React.FC = () => {
  const { entity } = useContext(AssessmentObjectiveContext);

  const assessmentObjective = entity as AssessmentObjectiveInterface;
  const { name, level } = assessmentObjective;

  return <AssessmentObjectiveTableRowCategory {...{ name, level }} />;
};

export default React.memo(AssessmentObjectiveTableRowCategoryContainer);
