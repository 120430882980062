import axios from "axios";
import {
  SingleEntityValidationError,
  PerformActionOnEntitiesResponse,
} from "api/types";
import { EntityToSyncInterface } from "module/sync/types";
import { EntityIdentifier, EntityType } from "module/entity/types";

const sync = async (
  entities: EntityToSyncInterface[]
): Promise<PerformActionOnEntitiesResponse> => {
  try {
    await axios.post(`/api/sync-entities`, {
      entities,
    });
    return {};
  } catch (error) {
    if (error.response.status === 422) {
      const response = error.response.data;
      const validationErrors: Map<
        EntityIdentifier,
        SingleEntityValidationError
      > = new Map();
      // eslint-disable-next-line array-callback-return
      Object.keys(response).map((type) => {
        // eslint-disable-next-line array-callback-return
        Object.keys(response[type]).map((id) => {
          validationErrors.set(
            { id, type: type as EntityType },
            response[type][id]
          );
        });
      });

      // eslint-disable-next-line consistent-return
      return {
        validationErrors,
      };
    }
    throw new Error(error);
  }
};

export default sync;
