import t from "module/translations";
import { TrainingCustomState } from "module/trainingCustom/types";

export const getTrainingCustomStateTranslation = (
  state: TrainingCustomState
): string =>
  ({
    [TrainingCustomState.scheduled]:
      t.competencyManagement.training.state.scheduledText,
    [TrainingCustomState.completed]:
      t.competencyManagement.training.state.completedText,
  }[state]);
