import { useMemo } from "react";
import { useAppSelector } from "redux/hooks";
import usePermissions from "module/permission/usePermissions";
import { TrainingInterface, TrainingState } from "module/training/types";
import selectOpenItemByEntityType from "module/staff/redux/selector/selectOpenItemByEntityType";
import { EntityType } from "module/entity/types";
import { isTrainingStateScheduledOrLower as isStateScheduledOrLowerHelper } from "module/training/helper/isTrainingStateScheduledOrLower";

interface TrainingStateResult {
  options: TrainingState[];
  isEditable: boolean;
  isStateScheduledOrLower: boolean;
}

const useTrainingState = (training: TrainingInterface): TrainingStateResult => {
  const { canCompleteTraining } = usePermissions();
  const openId = useAppSelector(
    selectOpenItemByEntityType(EntityType.training)
  )?.id;

  const isStateScheduledOrLower = isStateScheduledOrLowerHelper(training);

  const options = useMemo(() => {
    const result = [];
    if (
      isStateScheduledOrLower ||
      (training.state === TrainingState.completed && canCompleteTraining)
    ) {
      result.push(TrainingState.scheduled);
    }
    if (
      (training.state === TrainingState.completed || isStateScheduledOrLower) &&
      canCompleteTraining
    ) {
      result.push(TrainingState.completed);
    }
    return result;
  }, [canCompleteTraining, isStateScheduledOrLower, training.state]);

  const isEditable = training.id === openId && options.length > 1;

  return {
    options,
    isEditable,
    isStateScheduledOrLower,
  };
};

export default useTrainingState;
