import React, { useContext } from "react";
import TrainingContext from "module/training/context/TrainingContext";
import { TrainingInterface } from "module/training/types";
import TrainingNameLabelField from "module/training/enrichedComponent/nameField/TrainingNameLabelField";

const TrainingNameFieldContainer: React.FC = () => {
  const { entity } = useContext(TrainingContext);
  const { name } = entity as TrainingInterface;

  return <TrainingNameLabelField {...{ name }} />;
};

export default React.memo(TrainingNameFieldContainer);
