import { InventoryCheckState } from "module/inventoryCheck/types";

export enum InventoryCheckSortProperty {
  createdAt = "createdAt",
  reportingNumber = "reportingNumber",
}

export interface InventoryCheckFilters {
  states: InventoryCheckState[];
}
