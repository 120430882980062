import React, { useEffect, useMemo } from "react";
import { useHistory, useParams, useRouteMatch } from "react-router-dom";
import routes from "routes/routes";
import MyTrainingCustomDetailsContainer from "pages/myTrainingCustomDetails/MyTrainingCustomDetailsContainer";
import { EntityIdentifier, EntityType } from "module/entity/types";
import MyTrainingCustomDetailsContextProvider from "pages/myTrainingCustomDetails/MyTrainingCustomDetailsContextProvider";
import { getPathToTrainingCustomDetailsOverview } from "module/trainingCustom/helper/getPath";

const MyTrainingCustomDetailsPage: React.FC = () => {
  const { trainingCustomId: id } = useParams<{ trainingCustomId: string }>();
  const history = useHistory();
  const match = useRouteMatch({
    path: routes.myTrainingCustomDetails.path,
    exact: true,
  });

  useEffect(() => {
    if (id && match) {
      history.push(getPathToTrainingCustomDetailsOverview(id));
    }
  }, [history, id, match]);

  const entityIdentifier: EntityIdentifier = useMemo(
    () => ({
      id,
      type: EntityType.trainingCustom,
    }),
    [id]
  );

  return (
    <MyTrainingCustomDetailsContextProvider {...{ entityIdentifier }}>
      <MyTrainingCustomDetailsContainer />
    </MyTrainingCustomDetailsContextProvider>
  );
};

export default React.memo(MyTrainingCustomDetailsPage);
