import React, { useContext, useMemo } from "react";
import InventoryCheckContext from "module/inventoryCheck/context/InventoryCheckContext";
import useMyInventoryCheckState from "module/inventoryCheck/state/useMyInventoryCheckState";
import { InventoryCheckInterface } from "module/inventoryCheck/types";
import InventoryCheckStateContext, {
  InventoryCheckStateContextInterface,
} from "./InventoryCheckStateContext";

interface Prop {
  children: React.ReactNode;
}
const MyInventoryCheckStateContextProvider: React.FC<Prop> = ({ children }) => {
  const { entity } = useContext(InventoryCheckContext);

  const {
    isStateScheduledOrLower,
    isEditable: isStateEditable,
    options: stateOptions,
  } = useMyInventoryCheckState(entity as InventoryCheckInterface);

  const contextValue: InventoryCheckStateContextInterface = useMemo(
    () => ({
      isStateScheduledOrLower,
      isStateEditable,
      stateOptions,
    }),
    [isStateEditable, isStateScheduledOrLower, stateOptions]
  );

  return (
    <InventoryCheckStateContext.Provider value={contextValue}>
      {children}
    </InventoryCheckStateContext.Provider>
  );
};

export default MyInventoryCheckStateContextProvider;
