import makeStyles from "@mui/styles/makeStyles";

export const useDownloadedMaintenanceTaskDetailsPersonnelAssignmentsExternalStyles =
  makeStyles(() => ({
    table: {
      minWidth: "100%",
    },
    externalStaffName: {
      width: "33%",
    },
    externalStaffCompany: {
      width: "33%",
    },
    responsibility: {
      width: "33%",
    },
    actions: {
      width: "50px",
    },
  }));
