import React from "react";
import t from "module/translations";
import Button from "@mui/material/Button";
import DoneIcon from "@mui/icons-material/Done";

interface SignButtonProps {
  onClick: () => void;
}

const SignButton: React.FC<SignButtonProps> = ({ onClick }) => {
  return (
    <Button
      variant="contained"
      color="primary"
      size="large"
      onClick={onClick}
      startIcon={<DoneIcon />}
    >
      {t.signText}
    </Button>
  );
};

export default SignButton;
