import React, { useContext } from "react";
import AssessmentContext from "module/assessment/context/AssessmentContext";
import { AssessmentInterface } from "module/assessment/types";
import useDateFormat from "toolkit/useDateFormat";
import AssessmentScheduledDateLabelField from "module/assessment/enrichedComponent/scheduledDateField/AssessmentScheduledDateLabelField";

const AssessmentScheduledDateFieldContainer: React.FC = () => {
  const { entity } = useContext(AssessmentContext);
  const { formatDate } = useDateFormat();

  const { scheduledDate } = entity as AssessmentInterface;

  return (
    <AssessmentScheduledDateLabelField {...{ scheduledDate, formatDate }} />
  );
};

export default React.memo(AssessmentScheduledDateFieldContainer);
