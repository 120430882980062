import React from "react";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import useDateFormat from "toolkit/useDateFormat";
import StaffNameText from "module/staff/StaffNameText";
import makeStyles from "@mui/styles/makeStyles";
import { TrainingCustomInterface } from "module/trainingCustom/types";
import { getTrainingCustomStateTranslation } from "module/trainingCustom/helper/getTrainingCustomStateTranslation";
import CompetencyManagementListTableRowTrainingCustomSelectTableCellContainer from "pages/competencyManagementList/table/row/trainingCustom/selectTableCell/CompetencyManagementListTableRowTrainingCustomSelectTableCellContainer";
import TrainingCustomAssigneesLabelContainer from "module/trainingCustom/enrichedComponent/assigneesField/TrainingCustomAssigneesLabelContainer";
import t from "module/translations";

const useStyles = makeStyles({
  cell: {
    fontWeight: (props: { isHighlighted: boolean }) =>
      props.isHighlighted ? "bold" : "normal",
  },
});

interface Props {
  trainingCustom: TrainingCustomInterface;
  isSelected: boolean;
  rowClick: () => void;
  isHighlighted: boolean;
  hasPermissionToDownloadTraining: boolean;
}

const CompetencyManagementListTableRowTrainingCustom: React.FC<Props> = ({
  trainingCustom,
  isSelected,
  rowClick,
  isHighlighted,
  hasPermissionToDownloadTraining,
}) => {
  const classes = useStyles({ isHighlighted });
  const { formatDateTime } = useDateFormat();

  return (
    <TableRow
      {...{
        hover: true,
        tabIndex: -1,
        role: "checkbox",
        "aria-checked": isSelected,
        selected: isSelected,
        key: trainingCustom.id,
        onClick: rowClick,
      }}
    >
      {hasPermissionToDownloadTraining && (
        <CompetencyManagementListTableRowTrainingCustomSelectTableCellContainer />
      )}
      <TableCell className={classes.cell}>
        {trainingCustom.reportingNumber}
      </TableCell>
      <TableCell className={classes.cell}>{trainingCustom.name}</TableCell>
      <TableCell className={classes.cell}>
        <TrainingCustomAssigneesLabelContainer />
      </TableCell>
      <TableCell className={classes.cell}>
        {trainingCustom.internalTrainerId && (
          <StaffNameText {...{ staffId: trainingCustom.internalTrainerId }} />
        )}
        {trainingCustom.externalTrainer ? trainingCustom.externalTrainer : ""}
        {trainingCustom.internalTrainerId == null &&
          trainingCustom.externalTrainer == null &&
          "-"}
      </TableCell>
      <TableCell className={classes.cell}>
        {t.competencyManagement.trainingCustom.typeText}
      </TableCell>
      <TableCell className={classes.cell}>
        {formatDateTime(trainingCustom.scheduledStartDate)}
      </TableCell>
      <TableCell className={classes.cell}>
        {getTrainingCustomStateTranslation(trainingCustom.state)}
      </TableCell>
    </TableRow>
  );
};

export default React.memo(CompetencyManagementListTableRowTrainingCustom);
