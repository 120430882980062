import React, { useContext, useMemo } from "react";
import CollapsedListContext from "module/collapsedList/context/CollapsedListContext";
import MaintenanceTaskProcessStepListTableHead from "module/maintenanceTaskProcessStep/enrichedComponent/tableHead/MaintenanceTaskProcessStepListTableHead";
import MaintenanceTaskContext from "module/maintenanceTask/context/MaintenanceTaskContext";
import { MaintenanceTaskInterface } from "module/maintenanceTask/types";
import { isMaintenanceTaskProcessStepActionPerformedAvailable as isActionPerformedAvailableHelper } from "module/maintenanceTaskProcessStep/helper/isMaintenanceTaskProcessStepActionPerformedAvailable";
import { isMaintenanceTaskProcessStepCheckedResultAvailable as isCheckedResultAvailableHelper } from "module/maintenanceTaskProcessStep/helper/isMaintenanceTaskProcessStepCheckedResultAvailable";

const MaintenanceTaskProcessStepListTableHeadContainer: React.FC = () => {
  const { areAllExpanded, toggleAll } = useContext(CollapsedListContext);
  const { entity } = useContext(MaintenanceTaskContext);
  const maintenanceTask = entity as MaintenanceTaskInterface;

  const isActionPerformedAvailable = useMemo(
    () => isActionPerformedAvailableHelper(maintenanceTask),
    [maintenanceTask]
  );

  const isCheckedResultAvailable = useMemo(
    () => isCheckedResultAvailableHelper(maintenanceTask),
    [maintenanceTask]
  );

  return (
    <MaintenanceTaskProcessStepListTableHead
      {...{
        isExpanded: areAllExpanded,
        toggle: toggleAll,
        isActionPerformedAvailable,
        isCheckedResultAvailable,
      }}
    />
  );
};

export default React.memo(MaintenanceTaskProcessStepListTableHeadContainer);
