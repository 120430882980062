import React from "react";
import { EntityIdentifier } from "module/entity/types";
import { EntityChangeContextInterface } from "module/entity/context/change/EntityChangeContext";
import useEntityCollectionChange from "module/entity/useEntityCollectionChange";

interface Props {
  entityIdentifiers: EntityIdentifier[];
  context: React.Context<EntityChangeContextInterface>;
  children: React.ReactNode;
}

const MyEntityCollectionChangeContextProvider: React.FC<Props> = ({
  entityIdentifiers,
  context,
  children,
}) => {
  const { change } = useEntityCollectionChange(entityIdentifiers);

  const contextValue: EntityChangeContextInterface = {
    change,
  };

  return <context.Provider value={contextValue}>{children}</context.Provider>;
};

export default MyEntityCollectionChangeContextProvider;
