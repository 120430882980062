import { useMemo } from "react";
import { useAppSelector } from "redux/hooks";
import usePermissions from "module/permission/usePermissions";
import { EntityType } from "module/entity/types";
import {
  InventoryCheckInterface,
  InventoryCheckState,
} from "module/inventoryCheck/types";
import selectOpenItemByEntityType from "module/staff/redux/selector/selectOpenItemByEntityType";

interface Result {
  options: InventoryCheckState[];
  isEditable: boolean;
  isStateScheduledOrLower: boolean;
}

const useMyInventoryCheckState = (
  inventoryCheck: InventoryCheckInterface
): Result => {
  const { canCompleteInventoryCheck } = usePermissions();
  const openMaintenanceTasksEntityIdentifier = useAppSelector(
    selectOpenItemByEntityType(EntityType.inventoryCheck)
  );

  const isOpen = useMemo(
    () => openMaintenanceTasksEntityIdentifier?.id === inventoryCheck.id,
    [inventoryCheck.id, openMaintenanceTasksEntityIdentifier]
  );

  const isStateScheduledOrLower =
    inventoryCheck.state === InventoryCheckState.scheduled;

  const options = useMemo(() => {
    const result = [];
    if (
      isStateScheduledOrLower ||
      (inventoryCheck.state === InventoryCheckState.completed &&
        canCompleteInventoryCheck)
    ) {
      result.push(InventoryCheckState.scheduled);
    }
    if (
      inventoryCheck.state === InventoryCheckState.completed ||
      (isStateScheduledOrLower && canCompleteInventoryCheck)
    ) {
      result.push(InventoryCheckState.completed);
    }
    return result;
  }, [
    canCompleteInventoryCheck,
    isStateScheduledOrLower,
    inventoryCheck.state,
  ]);

  const isEditable = isOpen && options.length > 1;

  return {
    options,
    isEditable,
    isStateScheduledOrLower,
  };
};

export default useMyInventoryCheckState;
