import React from "react";
import { EntityValidationResultContextInterface } from "module/entity/context/validationResult/EntityValidationResultContext";

export type TrainingSessionValidationResultContextInterface =
  EntityValidationResultContextInterface;

const TrainingSessionValidationResultContext = React.createContext(
  <TrainingSessionValidationResultContextInterface>{}
);

export default TrainingSessionValidationResultContext;
