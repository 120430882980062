import {
  ApiMaintenanceTaskInterface,
  ApiMaintenanceTaskPersonnelAssignmentStaffInterface,
} from "api/maintenanceTask/types";

import { EntityType } from "module/entity/types";
import {
  MaintenanceTaskPersonnelAssignmentInternalStaffInterface,
  MyMaintenanceTaskPersonnelAssignmentInternalStaffInterface,
} from "module/maintenanceTaskPersonnelAssignment/internalStaff/types";

export const mapApiMaintenanceTaskPersonnelAssignmentInternalStaffToApp = (
  rawMaintenanceTask: ApiMaintenanceTaskInterface,
  raw: ApiMaintenanceTaskPersonnelAssignmentStaffInterface
): MyMaintenanceTaskPersonnelAssignmentInternalStaffInterface => {
  const data: MaintenanceTaskPersonnelAssignmentInternalStaffInterface = {
    id: `${raw.id}`,
    backendId: raw.id,
    maintenanceTaskId: `${rawMaintenanceTask.id}`,
    relationshipIdentifiers: [],
    relationships: undefined,
    staffId: raw.staff?.id,
    responsibility: raw.responsibility,
    duration: raw.duration,
  };

  return {
    entityIdentifier: {
      type: EntityType.maintenanceTaskPersonnelAssignmentInternalStaff,
      id: `${raw.id}`,
      backendId: raw.id,
    },
    data,
    dataWithoutChanges: data,
    raw,
    validationErrors: null,
    validationWarnings: null,
  };
};
