import React, { useContext } from "react";
import { getFieldMetaData as getFieldMetaDataHelper } from "module/fieldMetaData/getFieldMetaData";
import MaintenanceTaskOpenCloseContext from "module/maintenanceTask/openClose/context/MaintenanceTaskOpenCloseContext";
import MaintenanceTaskStateContext from "module/maintenanceTask/state/context/MaintenanceTaskStateContext";
import MyMaintenanceTaskProcessStepQuickFixFieldMetaDataContext, {
  MaintenanceTaskProcessStepQuickFixFieldMetaDataContextInterface,
} from "module/maintenanceTaskProcessStepQuickFix/validation/fieldMetaData/MaintenanceTaskProcessStepQuickFixFieldMetaDataContext";
import MaintenanceTaskProcessStepQuickFixValidationResultContext from "module/maintenanceTaskProcessStepQuickFix/validation/MaintenanceTaskProcessStepQuickFixValidationResultContext";

interface Prop {
  children: React.ReactNode;
}

const MyMaintenanceTaskProcessStepQuickFixFieldMetaDataContextProvider: React.FC<Prop> =
  ({ children }) => {
    const { validationWarnings, validationErrors } = useContext(
      MaintenanceTaskProcessStepQuickFixValidationResultContext
    );
    const { isOpen } = useContext(MaintenanceTaskOpenCloseContext);
    const { isStateScheduledOrLower } = useContext(MaintenanceTaskStateContext);

    const getFieldMetaData = getFieldMetaDataHelper({
      validationWarnings,
      validationErrors,
      isOpen,
      isStateScheduledOrLower,
    });

    const contextValue: MaintenanceTaskProcessStepQuickFixFieldMetaDataContextInterface =
      {
        getFieldMetaData,
      };

    return (
      <MyMaintenanceTaskProcessStepQuickFixFieldMetaDataContext.Provider
        value={contextValue}
      >
        {children}
      </MyMaintenanceTaskProcessStepQuickFixFieldMetaDataContext.Provider>
    );
  };

export default MyMaintenanceTaskProcessStepQuickFixFieldMetaDataContextProvider;
