import React from "react";
import { EntityValidationResultContextInterface } from "module/entity/context/validationResult/EntityValidationResultContext";

export type MaintenanceTaskPersonnelAssignmentInternalStaffValidationResultContextInterface =
  EntityValidationResultContextInterface;

const MaintenanceTaskPersonnelAssignmentInternalStaffValidationResultContext =
  React.createContext(
    <
      MaintenanceTaskPersonnelAssignmentInternalStaffValidationResultContextInterface
    >{}
  );

export default MaintenanceTaskPersonnelAssignmentInternalStaffValidationResultContext;
