import React from "react";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import t from "module/translations";
import { useMaintenanceTaskPartListStyles } from "module/maintenanceTaskPart/enrichedComponent/table/styles";

interface Props {
  isActionAvailable: boolean;
}

const MaintenanceTaskProcessStepListTableHead: React.FC<Props> = ({
  isActionAvailable,
}) => {
  const classes = useMaintenanceTaskPartListStyles();
  return (
    <TableHead>
      <TableRow>
        <TableCell align="left" className={classes.stockArticleTableCell}>
          {t.maintenanceTask.parts.stockArticleText}
        </TableCell>
        <TableCell align="left" className={classes.amountTableCell}>
          {t.maintenanceTask.parts.amountText}
        </TableCell>
        {isActionAvailable && (
          <TableCell align="right" className={classes.actionsTableCell} />
        )}
      </TableRow>
    </TableHead>
  );
};

export default React.memo(MaintenanceTaskProcessStepListTableHead);
