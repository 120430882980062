import { RootState } from "redux/store";
import selectConfigurationForMeForCurrentSystem from "module/staff/redux/selector/selectConfigurationForMeForCurrentSystem";
import { EntityIdentifier, EntityType } from "module/entity/types";

const selectOpenItemByEntityType =
  (entityType: EntityType) =>
  (state: RootState): EntityIdentifier | null =>
    selectConfigurationForMeForCurrentSystem(
      state
    )?.openEntityIdentifiers?.find((item) => item.type === entityType) ?? null;

export default selectOpenItemByEntityType;
