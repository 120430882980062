import React, { useContext, useCallback } from "react";
import t from "module/translations";
import MaintenanceTaskProcessStepContext from "module/maintenanceTaskProcessStep/context/MaintenanceTaskProcessStepContext";
import MaintenanceTaskProcessStepChangeContext from "module/maintenanceTaskProcessStep/change/MaintenanceTaskProcessStepChangeContext";
import MaintenanceTaskProcessStepFieldMetaDataContext from "module/maintenanceTaskProcessStep/validation/fieldMetaData/MaintenanceTaskProcessStepFieldMetaDataContext";
import { MaintenanceTaskProcessStepInterface } from "module/maintenanceTaskProcessStep/types";
import { getFieldMetaDataWithErrorHandling } from "module/fieldMetaData/getFieldMetaData";
import { MAINTENANCE_TASK_PROCESS_STEP_REMARKS_FIELD_NAME } from "module/maintenanceTaskProcessStep/constants";
import TextareaField from "component/textareaField/TextareaField";
import XLabelField from "component/labelField/XLabelField";

const MaintenanceTaskProcessStepRemarksFieldContainer: React.FC = () => {
  const { entity } = useContext(MaintenanceTaskProcessStepContext);
  const { change: changeHelper } = useContext(
    MaintenanceTaskProcessStepChangeContext
  );
  const { getFieldMetaData } = useContext(
    MaintenanceTaskProcessStepFieldMetaDataContext
  );

  const processStep = entity as MaintenanceTaskProcessStepInterface;

  const change = useCallback(
    (remarks?: string): void => {
      changeHelper({ remarks });
    },
    [changeHelper]
  );

  const { warnings, errors, isEditable } = getFieldMetaDataWithErrorHandling(
    () => getFieldMetaData(MAINTENANCE_TASK_PROCESS_STEP_REMARKS_FIELD_NAME)
  );

  const label = t.maintenanceTask.processStep.remarksText;
  const value = processStep.remarks;

  if (isEditable) {
    return (
      <TextareaField
        {...{
          label,
          value,
          change,
          errors,
        }}
      />
    );
  }

  if (!isEditable && value != null) {
    return (
      <XLabelField {...{ caption: label, label: value, errors, warnings }} />
    );
  }

  return null;
};
export default React.memo(MaintenanceTaskProcessStepRemarksFieldContainer);
