import React, { useContext, useCallback } from "react";
import OnlineDetectorContext from "module/onlineDetector/OnlineDetectorContext";
import { toastSuccess } from "layout/toast/helper";
import t from "module/translations";
import { useAppDispatch, useAppSelector } from "redux/hooks";
import { selectSystem, selectUser } from "module/auth/redux/authSlice";
import ToggleContext from "module/toggle/context/ToggleContext";
import unblock from "api/block/unblock";
import { EntityIdentifier, EntityType } from "module/entity/types";
import { removeFromMyEntities } from "module/staff/redux/staffDataSlice";
import DeleteButton from "component/button/DeleteButton";
import ElementWithConfirmation from "component/elementWithConfirmation/ElementWithConfirmation";
import TrainingContext from "module/training/context/TrainingContext";
import { TrainingInterface } from "module/training/types";

const TrainingDeleteButtonContainer: React.FC = () => {
  const { isOnline } = useContext(OnlineDetectorContext);
  const { entity } = useContext(TrainingContext);

  const dispatch = useAppDispatch();
  const staffId = useAppSelector(selectUser).id;
  const systemShortName = useAppSelector(selectSystem).shortName;
  const { unselectOne } = useContext(ToggleContext);

  const training = entity as TrainingInterface;
  const canDelete = isOnline;

  const remove = useCallback(async () => {
    const entityIdentifier: EntityIdentifier = {
      id: training.id,
      type: EntityType.training,
    };
    // TODO: error handling
    await unblock([entityIdentifier]);
    unselectOne(entityIdentifier);
    dispatch(
      removeFromMyEntities({
        entityIdentifiers: [entityIdentifier],
        staffId,
        systemShortName,
      })
    );
    toastSuccess(t.competencyManagement.training.deleteSuccessText);
  }, [dispatch, staffId, systemShortName, training.id, unselectOne]);

  const confirmationDialogTitle = t.deleteTitle;

  if (!canDelete) {
    return null;
  }

  return (
    <ElementWithConfirmation
      {...{ confirmationDialogTitle, asyncAction: remove }}
    >
      {(props: { onClick: () => void }) => (
        <DeleteButton {...{ onClick: props.onClick }} />
      )}
    </ElementWithConfirmation>
  );
};

export default React.memo(TrainingDeleteButtonContainer);
