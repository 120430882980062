import axios from "axios";
import moment from "moment";
import { SortType } from "module/sort/types";
import {
  ApiMaintenanceTaskInterface,
  MyMaintenanceTaskInterface,
} from "api/maintenanceTask/types";
import { MaintenanceTaskFilters } from "pages/maintenanceTaskList/types";
import { mapApiMaintenanceTaskToApp } from "module/maintenanceTask/helper/mapApiMaintenanceTaskToApp";

interface ApiGetMaintenanceTasksResponse {
  items: ApiMaintenanceTaskInterface[];
}

export const mapApiResponse = (
  rawData: ApiGetMaintenanceTasksResponse
): MyMaintenanceTaskInterface[] =>
  rawData.items.map(mapApiMaintenanceTaskToApp);

const getMaintenanceTasks = async (
  filters: MaintenanceTaskFilters,
  sort: SortType
): Promise<MyMaintenanceTaskInterface[]> => {
  const response = await axios.get(
    `/api/maintenance-tasks?` +
      `exactDate=${moment(filters.scheduledDate).format("YYYY-MM-DD")}&` +
      `types=${filters.types?.join()}&` +
      `definitionTypes=${filters.definitionTypes?.join()}&` +
      `sort=${sort.fieldName}&` +
      `sort_direction=${sort.direction}`
  );
  return mapApiResponse(response.data);
};

export default getMaintenanceTasks;
