import React from "react";
import { Provider } from "react-redux";
import { store } from "redux/store";

interface Prop {
  children: React.ReactNode;
}

const ReduxProvider: React.FC<Prop> = ({ children }) => (
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  <Provider store={store}>{children}</Provider>
);

export default ReduxProvider;
