import React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import { useDownloadedMaintenanceTaskDetailsPersonnelAssignmentAssistantStyles } from "pages/myMaintenanceTaskDetails/personnelAssignment/assistant/styles";
import MaintenanceTaskAssistantTableRow from "module/maintenanceTaskAssisstant/enrichedComponent/table/MaintenanceTaskAssistantTableRow";
import { MaintenanceTaskAssistantType } from "module/maintenanceTask/types";
import MaintenanceTaskAssistantTableHead from "module/maintenanceTaskAssisstant/enrichedComponent/table/MaintenanceTaskAssistantTableHead";

interface Props {
  isSet: boolean;
  assistantType?: MaintenanceTaskAssistantType | null;
}

const MaintenanceTaskToBeSignedDetailsAssistant: React.FC<Props> = ({
  isSet,
  assistantType,
}) => {
  const classes =
    useDownloadedMaintenanceTaskDetailsPersonnelAssignmentAssistantStyles();
  const isActionAvailable = false;
  return (
    <Table className={classes.table}>
      <MaintenanceTaskAssistantTableHead {...{ isActionAvailable }} />
      <TableBody>
        {isSet && (
          <MaintenanceTaskAssistantTableRow
            {...{
              isActionAvailable,
              assistantType: assistantType as MaintenanceTaskAssistantType,
            }}
          />
        )}
      </TableBody>
    </Table>
  );
};

export default React.memo(MaintenanceTaskToBeSignedDetailsAssistant);
