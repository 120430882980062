import React, { useContext } from "react";
import InternetConnectionIcon from "component/internetConnectionIcon/InternetConnectionIcon";
import OnlineDetectorContext from "module/onlineDetector/OnlineDetectorContext";

const HeaderConnectionContainer: React.FC = () => {
  const { isOnline } = useContext(OnlineDetectorContext);
  return <InternetConnectionIcon {...{ isOnline }} />;
};

export default React.memo(HeaderConnectionContainer);
