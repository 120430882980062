import { useCallback } from "react";
import { useAppSelector } from "redux/hooks";
import { TrainingInterface, TrainingState } from "module/training/types";
import { API_DATE_TIME_FORMAT } from "api/date";
import moment from "moment";
import useEntityOpenClose from "module/entity/useEntityOpenClose";
import { EntityIdentifier, EntityType } from "module/entity/types";
import selectOpenItemByEntityType from "module/staff/redux/selector/selectOpenItemByEntityType";
import useEntityChange from "module/entity/useEntityChange";
import { selectUser } from "module/auth/redux/authSlice";

interface Result {
  open?: () => void;
  close?: () => void;
}

const useMyTrainingOpenClose = (training: TrainingInterface): Result => {
  const entityIdentifier: EntityIdentifier = {
    id: training.id,
    type: EntityType.training,
  };
  const staffId = useAppSelector(selectUser).id;
  const { change } = useEntityChange(entityIdentifier);
  const {
    canOpen: canOpenEntity,
    open: openEntity,
    canClose: canCloseEntity,
    close: closeEntity,
  } = useEntityOpenClose(entityIdentifier);

  const isAnyAssessmentOpen =
    useAppSelector(selectOpenItemByEntityType(EntityType.assessment)) != null;
  const isAnyTrainingOpen =
    useAppSelector(selectOpenItemByEntityType(EntityType.training)) != null;
  const isAnyTrainingCustomOpen =
    useAppSelector(selectOpenItemByEntityType(EntityType.trainingCustom)) !=
    null;

  const handleOpen = useCallback(() => {
    let changes: Partial<TrainingInterface> = {
      completionEndDate: undefined,
      internalTrainerId: staffId,
      externalTrainer: undefined,
    };

    openEntity();
    if (training.completionStartDate == null) {
      changes = {
        ...changes,
        completionStartDate: moment().format(API_DATE_TIME_FORMAT),
      };
    }

    if (training.state === TrainingState.initScheduled) {
      changes = {
        ...changes,
        state: TrainingState.scheduled,
      };
    }

    change(changes);
  }, [
    change,
    openEntity,
    staffId,
    training.completionStartDate,
    training.state,
  ]);
  const canOpen =
    canOpenEntity &&
    !isAnyAssessmentOpen &&
    !isAnyTrainingOpen &&
    !isAnyTrainingCustomOpen;
  const open = canOpen ? handleOpen : undefined;

  const handleClose = useCallback(() => {
    closeEntity();
    if (training.state === TrainingState.completed) {
      change({
        completionEndDate: moment().format(API_DATE_TIME_FORMAT),
      });
    }
  }, [change, closeEntity, training.state]);
  const close = canCloseEntity ? handleClose : undefined;

  return {
    open,
    close,
  };
};

export default useMyTrainingOpenClose;
