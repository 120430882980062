import React, { useContext, useMemo } from "react";
import TableCell from "@mui/material/TableCell";
import Checkbox from "@mui/material/Checkbox";
import ToggleContext from "module/toggle/context/ToggleContext";
import InfoIconWithReason from "module/block/InfoIconWithReason";
import { EntityType } from "module/entity/types";
import TrainingContext from "module/training/context/TrainingContext";
import { TrainingInterface } from "module/training/types";
import useGetTrainingBlockedReasonText from "module/training/helper/useGetTrainingBlockedReasonText";

const CompetencyManagementListTableRowTrainingSelectTableCellContainer: React.FC =
  () => {
    const { entity } = useContext(TrainingContext);
    const { isSelected: isSelectedHelper } = useContext(ToggleContext);

    const training = entity as TrainingInterface;

    const isBlockedReasonText = useGetTrainingBlockedReasonText(training);

    const isSelected = useMemo(
      () =>
        isSelectedHelper({
          id: training.id,
          type: EntityType.training,
        }),
      [isSelectedHelper, training.id]
    );

    return (
      <TableCell padding="checkbox">
        {isBlockedReasonText ? (
          <InfoIconWithReason {...{ reason: isBlockedReasonText as string }} />
        ) : (
          <Checkbox checked={isSelected} />
        )}
      </TableCell>
    );
  };

export default React.memo(
  CompetencyManagementListTableRowTrainingSelectTableCellContainer
);
