import {
  TrainingInterface,
  TrainingSessionInterface,
} from "module/training/types";

const areTrainingEntitiesSimilar = (
  training1: TrainingInterface,
  training2: TrainingInterface
): boolean => {
  return (
    training1.name === training2.name &&
    training1.moduleText === training2.moduleText &&
    training1.remarks === training2.remarks &&
    training1.state === training2.state
  );
};

const areTrainingSessionsEntitiesSimilar = (
  trainingSession1: TrainingSessionInterface,
  trainingSession2: TrainingSessionInterface
): boolean => {
  return (
    trainingSession1.name === trainingSession2.name &&
    trainingSession1.remarks === trainingSession2.remarks &&
    trainingSession1.achieved === trainingSession2.achieved &&
    trainingSession1.completeDate === trainingSession2.completeDate &&
    trainingSession1.isCategory === trainingSession2.isCategory
  );
};

const areTrainingSessionsSimilar = (
  training1SessionsEntities: TrainingSessionInterface[],
  training2SessionsEntities: TrainingSessionInterface[]
): boolean => {
  if (training1SessionsEntities.length !== training2SessionsEntities.length) {
    return false;
  }

  return training1SessionsEntities.reduce(
    (result, training1SessionEntity, index) => {
      if (result) {
        return areTrainingSessionsEntitiesSimilar(
          training1SessionEntity,
          training2SessionsEntities[index]
        );
      }
      return result;
    },
    true as boolean
  );
};

export const areTrainingsSimilar = (
  training1: {
    training: TrainingInterface;
    sessions: TrainingSessionInterface[];
  },
  training2: {
    training: TrainingInterface;
    sessions: TrainingSessionInterface[];
  }
): boolean => {
  return (
    areTrainingEntitiesSimilar(training1.training, training2.training) &&
    areTrainingSessionsSimilar(training1.sessions, training2.sessions)
  );
};
