import React, { useCallback } from "react";
import CameraButton from "component/camera/CameraButton";

interface Props {
  add: (mime: string, data: string) => void;
}

const AddAttachmentButton: React.FC<Props> = ({ add }) => {
  const handleSave = useCallback(
    (imageData: string): void => {
      add("image/jpeg", imageData);
    },
    [add]
  );
  return <CameraButton {...{ onSave: handleSave }} />;
};

export default React.memo(AddAttachmentButton);
