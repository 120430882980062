import { useCallback } from "react";
import { useAppSelector } from "redux/hooks";
import { EntityType } from "module/entity/types";
import selectEntityHelper from "module/staff/redux/selector/selectEntity";
import { InventoryCheckArticleInterface } from "module/inventoryCheckArticle/types";
import {
  InventoryCheckToSyncInterface,
  MyInventoryCheckInterface,
} from "module/inventoryCheck/types";
import { mapInventoryCheckToSync } from "./mapper";

interface Result {
  geInventoryCheckMappedToSync: (id: string) => InventoryCheckToSyncInterface;
}

const useInventoryCheckSyncHelper = (): Result => {
  const selectEntity = useAppSelector(selectEntityHelper);

  const geInventoryCheckMappedToSync = useCallback(
    (id: string): InventoryCheckToSyncInterface => {
      const myInventoryCheck = selectEntity({
        id,
        type: EntityType.inventoryCheck,
      }) as MyInventoryCheckInterface;
      const inventoryCheckArticles =
        myInventoryCheck.data.inventoryCheckArticles.map(
          (inventoryCheckArticleEntityIdentifier) =>
            selectEntity(inventoryCheckArticleEntityIdentifier)
              .data as InventoryCheckArticleInterface
        );

      return mapInventoryCheckToSync(myInventoryCheck, inventoryCheckArticles);
    },
    [selectEntity]
  );

  return {
    geInventoryCheckMappedToSync,
  };
};

export default useInventoryCheckSyncHelper;
