import React, { useContext } from "react";
import ToggleContext from "module/toggle/context/ToggleContext";
import MyInventoryCheckListTableHead from "./MyInventoryCheckListTableHead";

const MyInventoryCheckListTableHeadContainer: React.FC = () => {
  const { areAllSelected, isAnySelected, toggleAll, canAnyBeSelected } =
    useContext(ToggleContext);

  return (
    <MyInventoryCheckListTableHead
      {...{
        toggleAll,
        isAnySelected,
        areAllSelected,
        canAnyBeSelected,
      }}
    />
  );
};

export default React.memo(MyInventoryCheckListTableHeadContainer);
