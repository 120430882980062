import React, { useContext } from "react";
import MyTrainingDetails from "pages/myTrainingDetails/MyTrainingDetails";
import TrainingContext from "module/training/context/TrainingContext";
import TrainingOpenCloseContext from "module/training/openClose/context/TrainingOpenCloseContext";
import TrainingStateContext from "module/training/state/context/TrainingStateContext";
import TrainingValidationResultContext from "module/training/validation/TrainingValidationResultContext";

const MyTrainingDetailsContainer: React.FC = () => {
  const { entity } = useContext(TrainingContext);
  const { hasError } = useContext(TrainingValidationResultContext);
  const { open, close } = useContext(TrainingOpenCloseContext);
  const { isStateEditable } = useContext(TrainingStateContext);

  return (
    <MyTrainingDetails
      {...{
        trainingId: entity.id,
        hasError,
        open,
        close,
        isStateEditable,
      }}
    />
  );
};

export default React.memo(MyTrainingDetailsContainer);
