import { isStateScheduledOrLower } from "module/maintenanceTask/helper/isStateScheduledOrLower";
import { MaintenanceTaskInterface } from "module/maintenanceTask/types";

interface Props {
  isMaintenanceTaskOpen: boolean;
  maintenanceTask: MaintenanceTaskInterface;
  canEditMaintenanceTask: boolean;
}

export const canAddMaintenanceTaskAttachment = ({
  isMaintenanceTaskOpen,
  maintenanceTask,
  canEditMaintenanceTask,
}: Props): boolean =>
  (isMaintenanceTaskOpen || maintenanceTask.backendId == null) &&
  isStateScheduledOrLower(maintenanceTask) &&
  canEditMaintenanceTask;
