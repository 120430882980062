import {
  MaintenanceTaskInterface,
  MaintenanceTaskScheduleType,
} from "module/maintenanceTask/types";

export const isMaintenanceTaskMaintainedAtValueAvailable = (
  maintenanceTask: MaintenanceTaskInterface
): boolean =>
  maintenanceTask.definition != null &&
  (maintenanceTask.definition.scheduleType ===
    MaintenanceTaskScheduleType.allXValues ||
    maintenanceTask.definition.scheduleType ===
      MaintenanceTaskScheduleType.allXValuesSerialized);
