import React from "react";
import { EntityValidationResultContextInterface } from "module/entity/context/validationResult/EntityValidationResultContext";

export type TrainingCustomValidationResultContextInterface =
  EntityValidationResultContextInterface;

const TrainingCustomValidationResultContext = React.createContext(
  <TrainingCustomValidationResultContextInterface>{}
);

export default TrainingCustomValidationResultContext;
