import React from "react";
import { EntityStateContextInterface } from "module/entity/context/state/EntityStateContext";

export type AssessmentStateContextInterface = EntityStateContextInterface;

const AssessmentStateContext = React.createContext(
  <AssessmentStateContextInterface>{}
);

export default AssessmentStateContext;
