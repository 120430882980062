import t from "module/translations";
import { TrainingCustomSyncUnavailableReason } from "module/trainingCustom/types";

export const getTrainingCustomSyncUnavailableReasonTranslation = (
  unavailableReason: TrainingCustomSyncUnavailableReason
): string => {
  return {
    [TrainingCustomSyncUnavailableReason.notValid]:
      t.competencyManagement.trainingCustom.sync.unavailableReason.notValidText,
    [TrainingCustomSyncUnavailableReason.isOpen]:
      t.competencyManagement.trainingCustom.sync.unavailableReason.isOpenText,
    [TrainingCustomSyncUnavailableReason.offline]:
      t.competencyManagement.trainingCustom.sync.unavailableReason.offlineText,
    [TrainingCustomSyncUnavailableReason.noPermissions]:
      t.competencyManagement.trainingCustom.sync.unavailableReason
        .noPermissionsText,
    [TrainingCustomSyncUnavailableReason.hasNotChanged]:
      t.competencyManagement.trainingCustom.sync.unavailableReason
        .hasNotChangedText,
  }[unavailableReason];
};
