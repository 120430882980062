import React from "react";
import { EntityStateContextInterface } from "module/entity/context/state/EntityStateContext";

export type InventoryCheckStateContextInterface = EntityStateContextInterface;

const InventoryCheckStateContext = React.createContext(
  <InventoryCheckStateContextInterface>{}
);

export default InventoryCheckStateContext;
