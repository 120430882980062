import { MaintenanceTaskInterface } from "module/maintenanceTask/types";
import {
  MaintenanceTaskProcessStepCheckResult,
  MaintenanceTaskProcessStepInterface,
} from "module/maintenanceTaskProcessStep/types";
import { isMaintenanceTaskProcessStepCheckedResultAvailable } from "module/maintenanceTaskProcessStep/helper/isMaintenanceTaskProcessStepCheckedResultAvailable";

export const isMaintenanceTaskProcessStepCorrectiveFixAvailable = (
  maintenanceTask: MaintenanceTaskInterface,
  processStep: MaintenanceTaskProcessStepInterface
): boolean =>
  isMaintenanceTaskProcessStepCheckedResultAvailable(maintenanceTask) &&
  processStep.checkResult === MaintenanceTaskProcessStepCheckResult.good_no;
