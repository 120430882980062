import React from "react";
import makeStyles from "@mui/styles/makeStyles";
import LinkWithIcon from "component/link/LinkWithIcon";
import t from "module/translations";
import DeleteButton from "component/button/DeleteButton";
import XValidationErrorMessage from "component/validationErrorMessage/XValidationErrorMessage";

const useStyles = makeStyles({
  row: {
    display: "flex",
    flexGrow: 1,
    alignItems: "flex-start",
  },
  link: {
    flexGrow: 1,
  },
});

interface Props {
  path: string;
  remove?: () => void;
  hasError: boolean;
}

const MyMaintenanceTaskProcessStepCorrectiveLink: React.FC<Props> = ({
  path,
  remove,
  hasError,
}) => {
  const classes = useStyles();
  return (
    <div className={classes.row}>
      <div className={classes.link}>
        <LinkWithIcon
          {...{
            path,
            label: t.maintenanceTask.processStep.correctiveText,
          }}
        />
        {hasError && (
          <XValidationErrorMessage
            {...{
              errors: [
                t.maintenanceTask.processStep.correctiveContainsErrorsText,
              ],
            }}
          />
        )}
      </div>
      {remove != null && (
        <DeleteButton {...{ onClick: remove as () => void }} />
      )}
    </div>
  );
};

export default React.memo(MyMaintenanceTaskProcessStepCorrectiveLink);
