import React from "react";
import { EntityIdentifier } from "module/entity/types";
import MyEntityContextProvider from "module/entity/context/entity/MyEntityContextProvider";
import InventoryCheckArticleContext from "module/inventoryCheckArticle/context/InventoryCheckArticleContext";
import { getEntityIdentifierKey } from "module/entity/helper/getEntityIdentifierKey";
import MyEntityValidationResultContextProvider from "module/entity/context/validationResult/MyEntityValidationResultContextProvider";
import InventoryCheckArticleProcessStepValidationResultContext from "module/inventoryCheckArticle/validation/InventoryCheckArticleValidationResultContext";
import MyInventoryCheckArticleFieldMetaDataContextProvider from "module/inventoryCheckArticle/validation/fieldMetaData/MyInventoryCheckArticleFieldMetaDataContextProvider";
import MyEntityChangeContextProvider from "module/entity/context/change/MyEntityChangeContextProvider";
import InventoryCheckArticleChangeContext from "module/inventoryCheckArticle/change/InventoryCheckArticleChangeContext";

interface Props {
  entityIdentifier: EntityIdentifier;
  children: React.ReactNode;
}

const MyInventoryCheckArticleContextProvider: React.FC<Props> = ({
  entityIdentifier,
  children,
}) => {
  return (
    <MyEntityContextProvider
      {...{
        entityIdentifier,
        context: InventoryCheckArticleContext,
        key: getEntityIdentifierKey(entityIdentifier),
      }}
    >
      <MyEntityValidationResultContextProvider
        {...{
          entityIdentifier,
          context: InventoryCheckArticleProcessStepValidationResultContext,
        }}
      >
        <MyInventoryCheckArticleFieldMetaDataContextProvider>
          <MyEntityChangeContextProvider
            {...{
              entityIdentifier,
              context: InventoryCheckArticleChangeContext,
            }}
          >
            {children}
          </MyEntityChangeContextProvider>
        </MyInventoryCheckArticleFieldMetaDataContextProvider>
      </MyEntityValidationResultContextProvider>
    </MyEntityContextProvider>
  );
};

export default MyInventoryCheckArticleContextProvider;
