import React, { useMemo } from "react";
import SortContextProvider from "module/sort/context/SortContextProvider";
import { SortDirection } from "module/sort/types";
import { useAppSelector } from "redux/hooks";
import selectEntitiesByTypes from "module/staff/redux/selector/selectEntitiesByTypes";
import { EntityType } from "module/entity/types";
import { MyInventoryCheckInterface } from "module/inventoryCheck/types";
import { InventoryCheckSortProperty } from "pages/inventoryCheckList/types";
import MyInventoryCheckListContainer from "./MyInventoryCheckListContainer";

const MyInventoryCheckListPage: React.FC = () => {
  const myInventoryChecks = useAppSelector(
    selectEntitiesByTypes([EntityType.inventoryCheck])
  ) as MyInventoryCheckInterface[];
  const inventoryChecks = useMemo(
    () => myInventoryChecks.map(({ data }) => data),
    [myInventoryChecks]
  );
  return (
    <SortContextProvider
      {...{
        initialValue: {
          fieldName: InventoryCheckSortProperty.createdAt,
          direction: SortDirection.asc,
        },
      }}
    >
      <MyInventoryCheckListContainer {...{ inventoryChecks }} />
    </SortContextProvider>
  );
};

export default React.memo(MyInventoryCheckListPage);
