import React, { useCallback, useContext, useMemo } from "react";
import { useAppSelector } from "redux/hooks";
import StaffSelectContainer from "module/staff/StaffSelectContainer";
import XLabelField from "component/labelField/XLabelField";
import MaintenanceTaskPersonnelAssignmentInternalStaffContext from "module/maintenanceTaskPersonnelAssignment/internalStaff/context/MaintenanceTaskPersonnelAssignmentInternalStaffContext";
import {
  MaintenanceTaskPersonnelAssignmentInternalStaffInterface,
  MyMaintenanceTaskPersonnelAssignmentInternalStaffInterface,
} from "module/maintenanceTaskPersonnelAssignment/internalStaff/types";
import MaintenanceTaskPersonnelAssignmentInternalStaffFieldMetaDataContext from "module/maintenanceTaskPersonnelAssignment/internalStaff/validation/fieldMetaData/MaintenanceTaskPersonnelAssignmentInternalStaffFieldMetaDataContext";
import { getFieldMetaDataWithErrorHandling } from "module/fieldMetaData/getFieldMetaData";
import { MAINTENANCE_TASK_PERSONNEL_ASSIGNMENT_INTERNAL_STAFF_FIELD_NAME } from "module/maintenanceTaskPersonnelAssignment/internalStaff/constants";
import MaintenanceTaskPersonnelAssignmentInternalStaffChangeContext from "module/maintenanceTaskPersonnelAssignment/internalStaff/change/MaintenanceTaskPersonnelAssignmentInternalStaffChangeContext";
import { selectStaffById } from "module/auth/redux/authSlice";
import MaintenanceTaskContext from "module/maintenanceTask/context/MaintenanceTaskContext";
import { MaintenanceTaskInterface } from "module/maintenanceTask/types";
import { EntityIdentifier, EntityType } from "module/entity/types";
import selectEntity from "module/staff/redux/selector/selectEntity";
import { MaintenanceTaskPersonnelAssignmentResponsibility } from "module/maintenanceTaskPersonnelAssignment/types";
import { getEntityIdentifierKey } from "../../../../entity/helper/getEntityIdentifierKey";

const MaintenanceTaskPersonnelAssignmentInternalStaffContainer: React.FC =
  () => {
    const { entity: maintenanceTaskEntity } = useContext(
      MaintenanceTaskContext
    );
    const maintenanceTask = maintenanceTaskEntity as MaintenanceTaskInterface;

    const { entity } = useContext(
      MaintenanceTaskPersonnelAssignmentInternalStaffContext
    );

    const maintenanceTaskPersonnelAssignmentInternalStaff =
      entity as MaintenanceTaskPersonnelAssignmentInternalStaffInterface;

    const { change: changeHelper } = useContext(
      MaintenanceTaskPersonnelAssignmentInternalStaffChangeContext
    );

    const selectStaffByIdHelper = useAppSelector(selectStaffById);
    const selectEntityHelper = useAppSelector(selectEntity);

    const { getFieldMetaData } = useContext(
      MaintenanceTaskPersonnelAssignmentInternalStaffFieldMetaDataContext
    );
    const { warnings, errors, isEditable } = getFieldMetaDataWithErrorHandling(
      () =>
        getFieldMetaData(
          MAINTENANCE_TASK_PERSONNEL_ASSIGNMENT_INTERNAL_STAFF_FIELD_NAME
        )
    );

    const selectedStaff = useMemo(() => {
      let staff;
      if (maintenanceTaskPersonnelAssignmentInternalStaff.staffId != null) {
        try {
          staff = selectStaffByIdHelper(
            maintenanceTaskPersonnelAssignmentInternalStaff.staffId
          );
        } catch (e) {
          staff = undefined;
        }
      }
      return staff;
    }, [
      maintenanceTaskPersonnelAssignmentInternalStaff.staffId,
      selectStaffByIdHelper,
    ]);

    const change = useCallback(
      (staffId: number): void => {
        changeHelper({ staffId });
      },
      [changeHelper]
    );

    const maintenanceTaskPersonnelAssignmentsInternalStaff = useMemo(
      () =>
        maintenanceTask.personnelAssignments
          .filter(
            (personnelAssignmentEntityIdentifier) =>
              personnelAssignmentEntityIdentifier.type ===
              EntityType.maintenanceTaskPersonnelAssignmentInternalStaff
          )
          .map((personnelAssignmentInternalStaffEntityIdentifier) => {
            const myMaintenanceTaskPersonnelAssignmentInternalStaff =
              selectEntityHelper(
                personnelAssignmentInternalStaffEntityIdentifier
              ) as MyMaintenanceTaskPersonnelAssignmentInternalStaffInterface | null;

            if (myMaintenanceTaskPersonnelAssignmentInternalStaff != null) {
              return myMaintenanceTaskPersonnelAssignmentInternalStaff.data;
            }

            const entityIdentifier =
              maintenanceTask.relationshipIdentifiers.find(
                (identifier) =>
                  getEntityIdentifierKey(identifier) ===
                  getEntityIdentifierKey({
                    id: maintenanceTaskPersonnelAssignmentInternalStaff.id,
                    backendId:
                      maintenanceTaskPersonnelAssignmentInternalStaff.backendId,
                    type: EntityType.maintenanceTaskPersonnelAssignmentInternalStaff,
                  })
              ) as EntityIdentifier;

            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            return maintenanceTask.relationships.get(
              entityIdentifier
            ) as MaintenanceTaskPersonnelAssignmentInternalStaffInterface;
          }),
      [
        maintenanceTask.personnelAssignments,
        maintenanceTask.relationshipIdentifiers,
        maintenanceTask.relationships,
        maintenanceTaskPersonnelAssignmentInternalStaff.backendId,
        maintenanceTaskPersonnelAssignmentInternalStaff.id,
        selectEntityHelper,
      ]
    );

    const excludedStaffIds = useMemo(
      () =>
        maintenanceTaskPersonnelAssignmentsInternalStaff
          .filter(
            (personnelAssignment) =>
              // personnelAssignment != null &&
              personnelAssignment.staffId != null &&
              personnelAssignment.staffId !==
                maintenanceTaskPersonnelAssignmentInternalStaff.staffId
          )
          .map(
            (personnelAssignment) => personnelAssignment.staffId
          ) as number[],
      [
        maintenanceTaskPersonnelAssignmentInternalStaff.staffId,
        maintenanceTaskPersonnelAssignmentsInternalStaff,
      ]
    );

    if (
      isEditable &&
      maintenanceTaskPersonnelAssignmentInternalStaff.responsibility !==
        MaintenanceTaskPersonnelAssignmentResponsibility.LEAD
    ) {
      return (
        <StaffSelectContainer
          {...{
            selectedStaffId:
              maintenanceTaskPersonnelAssignmentInternalStaff.staffId,
            onChange: change,
            excludedStaffIds,
            errors,
          }}
        />
      );
    }

    const label = selectedStaff == null ? undefined : selectedStaff.name;
    return <XLabelField {...{ label, errors, warnings }} />;
  };

export default React.memo(
  MaintenanceTaskPersonnelAssignmentInternalStaffContainer
);
