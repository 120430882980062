import React from "react";
import t from "module/translations";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { EntityIdentifier } from "module/entity/types";
import { getEntityIdentifierKey } from "module/entity/helper/getEntityIdentifierKey";
import AddIconButton from "component/addButton/AddIconButton";
import { useDownloadedMaintenanceTaskDetailsPersonnelAssignmentsInternalStyles } from "pages/myMaintenanceTaskDetails/personnelAssignment/internalList/styles";
import MyMaintenanceTaskPersonnelAssignmentInternalStaffListRowContextProvider from "pages/myMaintenanceTaskDetails/personnelAssignment/internalList/table/row/MyMaintenanceTaskDetailsPersonnelAssignmentInternalStaffListTableRowContextProvider";
import MyMaintenanceTaskDetailsPersonnelAssignmentInternalStaffListTableRowContainer from "pages/myMaintenanceTaskDetails/personnelAssignment/internalList/table/row/MyMaintenanceTaskDetailsPersonnelAssignmentInternalStaffListTableRowContainer";

interface Props {
  entityIdentifiers: EntityIdentifier[];
  canAdd: boolean;
  add: () => void;
}

const MyMaintenanceTaskDetailsPersonnelAssignmentsInternalStaff: React.FC<Props> =
  ({ entityIdentifiers, canAdd, add }) => {
    const classes =
      useDownloadedMaintenanceTaskDetailsPersonnelAssignmentsInternalStyles();

    return (
      <Table className={classes.table}>
        <TableHead>
          <TableRow>
            <TableCell>
              {t.maintenanceTask.personnelAssignments.nameText}
            </TableCell>
            <TableCell>
              {t.maintenanceTask.personnelAssignments.positionText}
            </TableCell>
            <TableCell>
              {t.maintenanceTask.personnelAssignments.responsibilityText}
            </TableCell>
            <TableCell>
              {t.maintenanceTask.personnelAssignments.durationText}
            </TableCell>
            <TableCell />
          </TableRow>
        </TableHead>
        <TableBody>
          {entityIdentifiers.map((entityIdentifier) => (
            <MyMaintenanceTaskPersonnelAssignmentInternalStaffListRowContextProvider
              {...{
                key: getEntityIdentifierKey(entityIdentifier),
                entityIdentifier,
              }}
            >
              <MyMaintenanceTaskDetailsPersonnelAssignmentInternalStaffListTableRowContainer />
            </MyMaintenanceTaskPersonnelAssignmentInternalStaffListRowContextProvider>
          ))}
          {canAdd && (
            <TableRow>
              <TableCell className={classes.staffName}>
                <AddIconButton {...{ onClick: add }} />
              </TableCell>
              <TableCell className={classes.staffPosition} />
              <TableCell className={classes.responsibility} />
              <TableCell className={classes.actions} />
            </TableRow>
          )}
        </TableBody>
      </Table>
    );
  };

export default React.memo(
  MyMaintenanceTaskDetailsPersonnelAssignmentsInternalStaff
);
