import React from "react";
import { EntityChangeContextInterface } from "module/entity/context/change/EntityChangeContext";

export type MaintenanceTaskProcessStepChangeContextInterface =
  EntityChangeContextInterface;

const MaintenanceTaskProcessStepChangeContext = React.createContext(
  <MaintenanceTaskProcessStepChangeContextInterface>{}
);

export default MaintenanceTaskProcessStepChangeContext;
