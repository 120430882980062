import React from "react";
import makeStyles from "@mui/styles/makeStyles";
import t from "module/translations";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import TableContainer from "@mui/material/TableContainer";
import StickyDiv from "component/sticky/StickyDiv";
import MaintenanceTaskToBeSignedDetailsPersonnelAssignmentsInternalStaffContainer from "pages/maintenanceTaskToBeSignedDetails/personnelAssignment/internalList/MaintenanceTaskToBeSignedDetailsPersonnelAssignmentsInternalStaffContainer";
import MaintenanceTaskToBeSignedDetailsPersonnelAssignmentsExternalStaffContainer from "pages/maintenanceTaskToBeSignedDetails/personnelAssignment/externalList/MaintenanceTaskToBeSignedDetailsPersonnelAssignmentsExternalStaffContainer";
import MaintenanceTaskToBeSignedDetailsAssistantContainer from "pages/maintenanceTaskToBeSignedDetails/personnelAssignment/assistant/MaintenanceTaskToBeSignedDetailsAssistantContainer";
import MaintenanceTaskToBeSignedDetailsSignContainer from "pages/maintenanceTaskToBeSignedDetails/MaintenanceTaskToBeSignedDetailsSignContainer";

const useStyles = makeStyles(() => ({
  paperContainer: {
    padding: "24px",
    marginBottom: "24px",
  },
  tableContainer: {
    marginBottom: "24px",
  },
}));

const MaintenanceTaskToBeSignedDetailsPersonnelAssignments: React.FC = () => {
  const classes = useStyles();

  return (
    <StickyDiv>
      <Typography variant="h5" component="div">
        {t.maintenanceTask.personnelAssignmentsText}
      </Typography>
      <Typography variant="h6" component="div">
        {t.maintenanceTask.personnelAssignments.type.internalText}
      </Typography>
      <TableContainer component={Paper} className={classes.tableContainer}>
        <MaintenanceTaskToBeSignedDetailsPersonnelAssignmentsInternalStaffContainer />
      </TableContainer>
      <Typography variant="h6" component="div">
        {t.maintenanceTask.personnelAssignments.type.externalText}
      </Typography>
      <TableContainer component={Paper} className={classes.tableContainer}>
        <MaintenanceTaskToBeSignedDetailsPersonnelAssignmentsExternalStaffContainer />
      </TableContainer>
      <Typography variant="h5" component="div">
        {t.maintenanceTask.personnelAssignmentsSecondSetOfEyesText}
      </Typography>
      <Typography variant="caption" component="div">
        {t.maintenanceTask.personnelAssignmentsSecondSetOfEyesSubtitleText}
      </Typography>
      <TableContainer component={Paper} className={classes.tableContainer}>
        <MaintenanceTaskToBeSignedDetailsAssistantContainer />
      </TableContainer>
      <MaintenanceTaskToBeSignedDetailsSignContainer />
    </StickyDiv>
  );
};

export default React.memo(MaintenanceTaskToBeSignedDetailsPersonnelAssignments);
