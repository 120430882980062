import React, { useContext } from "react";
import MaintenanceTaskContext from "module/maintenanceTask/context/MaintenanceTaskContext";
import { MaintenanceTaskInterface } from "module/maintenanceTask/types";
import t from "module/translations";

const MaintenanceTaskWindowMessageFieldContainer: React.FC = () => {
  const { entity } = useContext(MaintenanceTaskContext);
  const { window } = entity as MaintenanceTaskInterface;

  if (window != null) {
    return <>{t.maintenanceTask.windowMessageText(window)}</>;
  }

  return null;
};

export default React.memo(MaintenanceTaskWindowMessageFieldContainer);
