import React from "react";
import { EntityOpenCloseContextInterface } from "module/entity/context/openClose/EntityOpenCloseContext";

export type InventoryCheckOpenCloseContextInterface =
  EntityOpenCloseContextInterface;

const InventoryCheckOpenCloseContext = React.createContext(
  <InventoryCheckOpenCloseContextInterface>{}
);

export default InventoryCheckOpenCloseContext;
