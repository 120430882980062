import React from "react";
import makeStyles from "@mui/styles/makeStyles";
import clsx from "clsx";
import MenuIcon from "@mui/icons-material/Menu";
import IconButton from "@mui/material/IconButton";

const useStyles = makeStyles((theme) => ({
  menuButton: {
    marginRight: theme.spacing(2),
  },
  hide: {
    display: "none",
  },
}));

interface Props {
  isMenuOpen: boolean;
  openMenu: () => void;
}

const Hamburger: React.FC<Props> = ({ openMenu, isMenuOpen }) => {
  const classes = useStyles();
  return (
    <IconButton
      color="inherit"
      aria-label="open drawer"
      onClick={openMenu}
      edge="start"
      className={clsx(classes.menuButton, isMenuOpen && classes.hide)}
      size="large"
    >
      <MenuIcon />
    </IconButton>
  );
};

export default React.memo(Hamburger);
