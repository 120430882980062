import React, { useContext } from "react";
import MaintenanceTaskProcessStepContext from "module/maintenanceTaskProcessStep/context/MaintenanceTaskProcessStepContext";
import { MaintenanceTaskProcessStepInterface } from "module/maintenanceTaskProcessStep/types";
import { EntityIdentifier } from "module/entity/types";
import { getPathToMaintenanceTaskDetails } from "module/maintenanceTask/helper/getPath";
import { useAppSelector } from "redux/hooks";
import selectEntity from "module/staff/redux/selector/selectEntity";
import t from "module/translations";
import selectHasEntityOrRelatedEntityError from "module/staff/redux/selector/selectHasEntityOrRelatedEntityError";
import useMaintenanceTaskProcessStepCorrectiveRemove from "module/maintenanceTaskProcessStepCorrective/remove/useMaintenanceTaskProcessStepCorrectiveRemove";
import MyMaintenanceTaskProcessStepCorrectiveLink from "./MyMaintenanceTaskProcessStepCorrectiveLink";

const MyMaintenanceTaskProcessStepCorrectiveLinkContainer: React.FC = () => {
  const { entity: processStepEntity } = useContext(
    MaintenanceTaskProcessStepContext
  );
  const processStep = processStepEntity as MaintenanceTaskProcessStepInterface;
  const correctiveEntityIdentifier =
    processStep.correctiveMaintenanceTask as EntityIdentifier;

  const { canRemove: canRemoveCorrective, remove: removeCorrective } =
    useMaintenanceTaskProcessStepCorrectiveRemove();

  const selectHasError = useAppSelector(selectHasEntityOrRelatedEntityError);
  const selectEntityHelper = useAppSelector(selectEntity);

  const correctiveWasCreatedLocally =
    selectEntityHelper(correctiveEntityIdentifier) != null;

  if (!correctiveWasCreatedLocally) {
    return (
      <div>
        {t.maintenanceTask.processStep.correctiveExistText} (#
        {correctiveEntityIdentifier.id})
      </div>
    );
  }

  const hasError = selectHasError(correctiveEntityIdentifier);

  const path = getPathToMaintenanceTaskDetails(correctiveEntityIdentifier.id);

  const remove = canRemoveCorrective ? removeCorrective : undefined;

  return (
    <MyMaintenanceTaskProcessStepCorrectiveLink
      {...{ path, remove, hasError }}
    />
  );
};

export default React.memo(MyMaintenanceTaskProcessStepCorrectiveLinkContainer);
