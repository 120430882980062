import { PayloadAction } from "@reduxjs/toolkit";
import { StaffDataState } from "module/staff/redux/types";
import { createNestedPropertyIfNotExist } from "toolkit/createNestedPropertyIfNotExist";
import { MyEntityInterface } from "module/entity/types";
import { getEntityIdentifierKey } from "module/entity/helper/getEntityIdentifierKey";

interface AddToMyEntitiesActionTypeProps {
  entities: MyEntityInterface[];
  staffId: number;
  systemShortName: string;
}

const addToMyEntitiesAction = (
  state: StaffDataState,
  action: PayloadAction<AddToMyEntitiesActionTypeProps>
): void => {
  const { staffId, systemShortName, entities } = action.payload;

  const defaultValue = {
    entities: {},
    openEntityIdentifiers: [],
  };

  // eslint-disable-next-line no-param-reassign
  state = createNestedPropertyIfNotExist(
    state,
    `${staffId}.${systemShortName}`,
    defaultValue
  );

  const newData = entities.reduce((result, myEntity) => {
    const key = getEntityIdentifierKey(myEntity.entityIdentifier);
    return {
      ...result,
      [key]: myEntity,
    };
  }, {});

  state[staffId][systemShortName].entities = {
    ...state[staffId][systemShortName].entities,
    ...newData,
  };
};

export default addToMyEntitiesAction;
