import React from "react";
import { EntityValidationResultContextInterface } from "module/entity/context/validationResult/EntityValidationResultContext";

export type MaintenanceTaskProcessStepValidationResultContextInterface =
  EntityValidationResultContextInterface;

const MaintenanceTaskProcessStepProcessStepValidationResultContext =
  React.createContext(
    <MaintenanceTaskProcessStepValidationResultContextInterface>{}
  );

export default MaintenanceTaskProcessStepProcessStepValidationResultContext;
