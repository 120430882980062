import { useCallback } from "react";
import { EntityIdentifier } from "module/entity/types";
import t from "module/translations";
import useEntityAbstractFactory from "module/entity/useEntityAbstractFactory";
import useEntityCollectionOpenClose from "module/entity/useEntityCollectionOpenClose";

interface IsGroupEditPossibleYesResult {
  areEntitiesSimilar: true;
  path: string;
}

interface IsGroupEditPossibleNoResult {
  areEntitiesSimilar: false;
  entitiesAreNotSimilarReasonText: string;
}

type IsGroupEditPossibleResult =
  | IsGroupEditPossibleYesResult
  | IsGroupEditPossibleNoResult;

interface UseIsGroupEditPossibleResult {
  isGroupEditPossible: (
    entityIdentifiers: EntityIdentifier[]
  ) => IsGroupEditPossibleResult;
}

const useIsGroupEditPossible = (): UseIsGroupEditPossibleResult => {
  const { getEntityFactory } = useEntityAbstractFactory();
  const { calculate: calculateEntityCollectionOpenClose } =
    useEntityCollectionOpenClose();

  const isGroupEditPossible = useCallback(
    (entityIdentifiers: EntityIdentifier[]): IsGroupEditPossibleResult => {
      if (entityIdentifiers.length === 0) {
        return {
          areEntitiesSimilar: false,
          entitiesAreNotSimilarReasonText:
            t.groupEdit.notPossibleReason.noEntityText,
        };
      }

      const firstEntityIdentifier = entityIdentifiers[0];

      const typeIsNotTheSameForAllEntities = !!entityIdentifiers.filter(
        (entityIdentifier) =>
          entityIdentifier.type !== firstEntityIdentifier.type
      ).length;

      if (typeIsNotTheSameForAllEntities) {
        return {
          areEntitiesSimilar: false,
          entitiesAreNotSimilarReasonText:
            t.groupEdit.notPossibleReason.differentTypesText,
        };
      }

      const entitiesIds = entityIdentifiers.map(
        (entityIdentifier) => entityIdentifier.id
      );

      const { canOpen, canClose } =
        calculateEntityCollectionOpenClose(entityIdentifiers);
      const areAllOpenedOrAllClosed = canOpen || canClose;

      if (!areAllOpenedOrAllClosed) {
        return {
          areEntitiesSimilar: false,
          entitiesAreNotSimilarReasonText:
            t.groupEdit.notPossibleReason.openCloseStateIsDifferentText,
        };
      }

      try {
        const entityFactory = getEntityFactory(firstEntityIdentifier.type);

        if (entityIdentifiers.length === 1) {
          return {
            areEntitiesSimilar: true,
            path: entityFactory.getPathToMyDetails(firstEntityIdentifier.id),
          };
        }

        const similarEntities = entityIdentifiers.filter((entityIdentifier) => {
          return entityFactory.areSimilar(
            firstEntityIdentifier,
            entityIdentifier
          );
        });

        if (similarEntities.length === entityIdentifiers.length) {
          return {
            areEntitiesSimilar: true,
            path: entityFactory.getPathToMyGroupedDetails(entitiesIds),
          };
        }
        return {
          areEntitiesSimilar: false,
          entitiesAreNotSimilarReasonText:
            t.groupEdit.notPossibleReason.entitiesAreNotSimilarText,
        };
      } catch (e) {
        return {
          areEntitiesSimilar: false,
          entitiesAreNotSimilarReasonText:
            t.groupEdit.notPossibleReason.typeIsNotSupportedText(
              firstEntityIdentifier.type
            ),
        };
      }
    },
    [calculateEntityCollectionOpenClose, getEntityFactory]
  );

  return {
    isGroupEditPossible,
  };
};

export default useIsGroupEditPossible;
