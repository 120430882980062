import React, { useContext, useState, useCallback, useMemo } from "react";
import DropdownButtonField from "component/dropdownButtonField/DropdownButtonField";
import { getTrainingStateTranslation } from "module/training/helper/getTrainingStateTranslation";
import t from "module/translations";
import ElementWithConfirmation from "component/elementWithConfirmation/ElementWithConfirmation";
import { TrainingInterface, TrainingState } from "module/training/types";
import TrainingContext from "module/training/context/TrainingContext";
import TrainingChangeContext from "module/training/change/TrainingChangeContext";
import TrainingStateContext from "module/training/state/context/TrainingStateContext";

const TrainingStateEditableFieldContainer: React.FC = () => {
  const { entity } = useContext(TrainingContext);
  const { change } = useContext(TrainingChangeContext);
  const { stateOptions: stateOptionsHelper } = useContext(TrainingStateContext);

  const training = entity as TrainingInterface;
  const stateOptions = stateOptionsHelper as TrainingState[];

  const [temporaryState, setTemporaryState] = useState<TrainingState>(
    training.state
  );

  const onChange = useCallback(async () => {
    await new Promise(() => {
      change({
        state: temporaryState,
      });
    });
  }, [change, temporaryState]);

  const onChangeHelper = useCallback(
    (newState: unknown, onClick: () => void) => {
      setTemporaryState(newState as TrainingState);
      if (newState === TrainingState.completed) {
        onClick();
      } else {
        change({
          state: newState as TrainingState,
        });
      }
    },
    [change]
  );

  const options = useMemo(
    () =>
      stateOptions.map((state: TrainingState) => ({
        value: state,
        text: getTrainingStateTranslation(state),
      })),
    [stateOptions]
  );

  const value = training.state;

  const confirmationDialogTitle =
    t.competencyManagement.completedConfirmationText;

  return (
    <ElementWithConfirmation {...{ confirmationDialogTitle, action: onChange }}>
      {(props: { onClick: () => void }) => (
        <DropdownButtonField
          {...{
            options,
            onChange: (newState) => {
              onChangeHelper(newState, props.onClick);
            },
            value,
          }}
        />
      )}
    </ElementWithConfirmation>
  );
};

export default React.memo(TrainingStateEditableFieldContainer);
