import React from "react";
import Typography from "@mui/material/Typography";
import { Theme } from "@mui/material/styles";
import createStyles from "@mui/styles/createStyles";
import makeStyles from "@mui/styles/makeStyles";
import InfoIconWIthPopover from "component/infoIconWithPopover/InfoIconWithPopover";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    typography: {
      padding: theme.spacing(2),
    },
  })
);

interface InfoIconWithReasonProps {
  reason: string;
}

const InfoIconWithReason: React.FC<InfoIconWithReasonProps> = ({ reason }) => {
  const classes = useStyles();
  return (
    <InfoIconWIthPopover {...{ label: reason }}>
      <Typography className={classes.typography}>{reason}</Typography>
    </InfoIconWIthPopover>
  );
};

export default InfoIconWithReason;
