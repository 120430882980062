import React from "react";
import { lighten } from "@mui/material/styles";
import makeStyles from "@mui/styles/makeStyles";
import Typography from "@mui/material/Typography";
import Toolbar from "@mui/material/Toolbar";
import { useToolbarStyles } from "component/listToolbar/styles";
import clsx from "clsx";
import t from "module/translations";

const useStyles = makeStyles((theme) => ({
  root: {
    color: theme.palette.secondary.main,
    backgroundColor: lighten(theme.palette.secondary.light, 0.85),
  },
}));

interface Props {
  selectedItemsCount: number;
  children: React.ReactNode;
}

const ListToolbarSelectedItems: React.FC<Props> = ({
  selectedItemsCount,
  children,
}) => {
  const classes = useStyles();
  const commonClasses = useToolbarStyles();

  return (
    <Toolbar className={clsx(commonClasses.root, classes.root)}>
      <Typography
        className={commonClasses.title}
        color="inherit"
        variant="subtitle1"
        component="div"
      >
        {t.listing.selectedItemsText(selectedItemsCount)}
      </Typography>
      {children}
    </Toolbar>
  );
};

export default React.memo(ListToolbarSelectedItems);
