import React from "react";
import MUITableBody from "@mui/material/TableBody";

interface Props {
  children: React.ReactNode;
}

const TableBody: React.FC<Props> = ({ children }) => {
  return <MUITableBody>{children}</MUITableBody>;
};

export default React.memo(TableBody);
