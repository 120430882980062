import {
  MaintenanceTaskPersonnelAssignmentInternalStaffInterface,
  MyMaintenanceTaskPersonnelAssignmentInternalStaffInterface,
} from "module/maintenanceTaskPersonnelAssignment/internalStaff/types";
import {
  MaintenanceTaskPersonnelAssignmentExternalStaffInterface,
  MyMaintenanceTaskPersonnelAssignmentExternalStaffInterface,
} from "module/maintenanceTaskPersonnelAssignment/externalStaff/types";

export enum MaintenanceTaskPersonnelAssignmentResponsibility {
  LEAD = "lead",
  SUPPORT = "support",
  HELPER = "helper",
  TRAINEE = "trainee",
}

export type MyMaintenanceTaskPersonnelAssignmentInterface =
  | MyMaintenanceTaskPersonnelAssignmentInternalStaffInterface
  | MyMaintenanceTaskPersonnelAssignmentExternalStaffInterface;

export type MaintenanceTaskPersonnelAssignmentInterface =
  | MaintenanceTaskPersonnelAssignmentInternalStaffInterface
  | MaintenanceTaskPersonnelAssignmentExternalStaffInterface;
