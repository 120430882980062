import React from "react";
import DeleteIcon from "@mui/icons-material/Delete";
import IconButton from "@mui/material/IconButton";

interface Props {
  onClick: () => void;
}

const DeleteButton: React.FC<Props> = ({ onClick }) => {
  return (
    <IconButton size="small" onClick={onClick}>
      <DeleteIcon />
    </IconButton>
  );
};

export default React.memo(DeleteButton);
