import React, { useContext } from "react";
import InventoryCheckArticleContext from "module/inventoryCheckArticle/context/InventoryCheckArticleContext";
import { InventoryCheckArticleInterface } from "module/inventoryCheckArticle/types";
import InventoryCheckArticleListRow from "./InventoryCheckArticleListRow";

const InventoryCheckArticleListRowContainer: React.FC = () => {
  const { entity } = useContext(InventoryCheckArticleContext);
  const inventoryCheckArticle = entity as InventoryCheckArticleInterface;

  return (
    <InventoryCheckArticleListRow
      {...{
        key: inventoryCheckArticle.id,
        inventoryCheckArticle,
      }}
    />
  );
};
export default React.memo(InventoryCheckArticleListRowContainer);
