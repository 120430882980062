import makeStyles from "@mui/styles/makeStyles";

export const useToolbarStyles = makeStyles((theme) => ({
  root: {
    paddingLeft: theme.spacing(2),
    position: "sticky",
    top: 0,
    zIndex: 2,
    backgroundColor: "#fff",
    minHeight: "72px",
  },
  title: {
    flex: "1 1 100%",
  },
}));
