import React, { useContext } from "react";
import XLabelField from "component/labelField/XLabelField";
import t from "module/translations";
import MaintenanceTaskProcessStepContext from "module/maintenanceTaskProcessStep/context/MaintenanceTaskProcessStepContext";
import { MaintenanceTaskProcessStepInterface } from "module/maintenanceTaskProcessStep/types";

const MaintenanceTaskProcessStepNameFieldContainer: React.FC = () => {
  const { entity } = useContext(MaintenanceTaskProcessStepContext);
  const { name } = entity as MaintenanceTaskProcessStepInterface;

  return (
    <XLabelField
      {...{
        caption: t.maintenanceTask.processStep.nameText,
        label: name,
      }}
    />
  );
};

export default React.memo(MaintenanceTaskProcessStepNameFieldContainer);
