import React from "react";
import TableCell from "@mui/material/TableCell";
import t from "module/translations";
import TableHeadRow from "component/table/tableHeadRow/TableHeadRow";
import TableToggleCell from "component/table/toggleCell/TableToggleCell";
import TableEmptyToggleCell from "component/table/toggleCell/TableEmptyToggleCell";
import TableHead from "@mui/material/TableHead";
import TableSortCellContainer from "module/sort/enrichedComponent/TableSortCellContainer";
import { InventoryCheckSortProperty } from "pages/inventoryCheckList/types";

interface Props {
  isAnySelected: boolean;
  areAllSelected: boolean;
  toggleAll: (checked: boolean) => void;
  canAnyBeSelected: boolean;
}

const MyInventoryCheckListTableHead: React.FC<Props> = ({
  isAnySelected,
  areAllSelected,
  toggleAll,
  canAnyBeSelected,
}) => {
  return (
    <TableHead>
      <TableHeadRow>
        {canAnyBeSelected && (
          <TableToggleCell
            {...{
              isSelected: areAllSelected,
              indeterminate: isAnySelected,
              toggle: toggleAll,
            }}
          />
        )}
        {!canAnyBeSelected && <TableEmptyToggleCell />}
        <TableCell align="left">
          {t.inventoryCheck.reportingNumberText}
        </TableCell>
        <TableCell align="left">{t.inventoryCheck.subjectText}</TableCell>
        <TableCell align="left">{t.inventoryCheck.assigneeText}</TableCell>
        <TableSortCellContainer
          {...{
            fieldName: InventoryCheckSortProperty.createdAt,
          }}
        >
          {t.inventoryCheck.createdAtText}
        </TableSortCellContainer>
        <TableCell align="left">{t.inventoryCheck.stateText}</TableCell>
        <TableCell />
      </TableHeadRow>
    </TableHead>
  );
};

export default React.memo(MyInventoryCheckListTableHead);
