import React from "react";
import { EntityValidationResultContextInterface } from "module/entity/context/validationResult/EntityValidationResultContext";

export type InventoryCheckArticleValidationResultContextInterface =
  EntityValidationResultContextInterface;

const InventoryCheckArticlepValidationResultContext = React.createContext(
  <InventoryCheckArticleValidationResultContextInterface>{}
);

export default InventoryCheckArticlepValidationResultContext;
