import { useCallback } from "react";
import { useAppDispatch } from "redux/hooks";
import { setSystem } from "module/auth/redux/authSlice";

interface Result {
  changeSystem: (systemIdentifier: string) => void;
}

const useSystemChange = (): Result => {
  const dispatch = useAppDispatch();

  const changeSystem = useCallback(
    async (systemIdentifier: string) => {
      await dispatch(setSystem(systemIdentifier));
      global.location.href = "/";
    },
    [dispatch]
  );

  return { changeSystem };
};

export default useSystemChange;
