import React, { useMemo } from "react";
import routes from "routes/routes";
import { matchPath, useLocation } from "react-router-dom";
import HeaderSubtitleComponent from "component/headerSubTitle/HeaderSubTitle";

const HeaderSubtitle: React.FC = () => {
  const { pathname: currentPath } = useLocation();
  const currentRoute = useMemo(
    () =>
      Object.values(routes).find(({ path }) =>
        matchPath(currentPath, {
          path,
          exact: true,
          strict: false,
        })
      ),
    [currentPath]
  );

  return (
    <>
      {!!currentRoute?.customHeaderTitle && <currentRoute.customHeaderTitle />}
      {!!currentRoute?.headerTitle && (
        <HeaderSubtitleComponent>
          {currentRoute.headerTitle}
        </HeaderSubtitleComponent>
      )}
    </>
  );
};

export default React.memo(HeaderSubtitle);
