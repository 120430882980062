import React from "react";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import t from "module/translations";
import useDateFormat from "toolkit/useDateFormat";
import CompetencyManagementListTableRowTrainingSelectTableCellContainer from "pages/competencyManagementList/table/row/training/selectTableCell/CompetencyManagementListTableRowTrainingSelectTableCellContainer";
import { getTrainingStateTranslation } from "module/training/helper/getTrainingStateTranslation";
import { getTrainingTypeTranslation } from "module/training/helper/getTrainingTypeTranslation";
import StaffNameText from "module/staff/StaffNameText";
import makeStyles from "@mui/styles/makeStyles";
import { TrainingInterface } from "module/training/types";

const useStyles = makeStyles({
  cell: {
    fontWeight: (props: { isHighlighted: boolean }) =>
      props.isHighlighted ? "bold" : "normal",
  },
});

interface Props {
  training: TrainingInterface;
  isSelected: boolean;
  rowClick: () => void;
  isHighlighted: boolean;
  hasPermissionToDownloadTraining: boolean;
}

const CompetencyManagementListTableRowTraining: React.FC<Props> = ({
  training,
  isSelected,
  rowClick,
  isHighlighted,
  hasPermissionToDownloadTraining,
}) => {
  const classes = useStyles({ isHighlighted });
  const { formatDateTime } = useDateFormat();

  return (
    <TableRow
      {...{
        hover: true,
        tabIndex: -1,
        role: "checkbox",
        "aria-checked": isSelected,
        selected: isSelected,
        key: training.id,
        onClick: rowClick,
      }}
    >
      {hasPermissionToDownloadTraining && (
        <CompetencyManagementListTableRowTrainingSelectTableCellContainer />
      )}
      <TableCell className={classes.cell}>{training.reportingNumber}</TableCell>
      <TableCell className={classes.cell}>{training.name}</TableCell>
      <TableCell className={classes.cell}>
        {training.assigneeId ? (
          <StaffNameText {...{ staffId: training.assigneeId }} />
        ) : (
          "-"
        )}
      </TableCell>
      <TableCell className={classes.cell}>
        {training.internalTrainerId && (
          <StaffNameText {...{ staffId: training.internalTrainerId }} />
        )}
        {training.externalTrainer ? training.externalTrainer : ""}
        {training.internalTrainerId == null &&
          training.externalTrainer == null &&
          "-"}
      </TableCell>
      <TableCell className={classes.cell}>
        {`${getTrainingTypeTranslation(training.type)} ${
          t.competencyManagement.trainingText
        }`}
      </TableCell>
      <TableCell className={classes.cell}>
        {formatDateTime(training.scheduledStartDate)}
      </TableCell>
      <TableCell className={classes.cell}>
        {getTrainingStateTranslation(training.state)}
      </TableCell>
    </TableRow>
  );
};

export default React.memo(CompetencyManagementListTableRowTraining);
