import React from "react";
import withOnlineDetector from "module/onlineDetector/withOnlineDetector";
import SortContextProvider from "module/sort/context/SortContextProvider";
import { SortDirection } from "module/sort/types";
import FilterContextProvider from "module/filter/context/FilterContextProvider";
import { supportedTypes } from "module/maintenanceTask/helper/supportedTypes";
import {
  MaintenanceTaskDefinitionType,
  MaintenanceTaskSortProperty,
} from "pages/maintenanceTaskList/types";
import MaintenanceTaskListContainer from "pages/maintenanceTaskList/MaintenanceTaskListContainer";

const SafetyMaintenanceTaskListPage: React.FC = () => {
  return (
    <SortContextProvider
      {...{
        initialValue: {
          fieldName: MaintenanceTaskSortProperty.scheduled,
          direction: SortDirection.asc,
        },
      }}
    >
      <FilterContextProvider
        {...{
          initialValue: {
            scheduledDate: new Date(),
            types: supportedTypes,
            definitionTypes: [
              MaintenanceTaskDefinitionType.equipment,
              MaintenanceTaskDefinitionType.sms,
              MaintenanceTaskDefinitionType.audit,
            ],
          },
        }}
      >
        <MaintenanceTaskListContainer />
      </FilterContextProvider>
    </SortContextProvider>
  );
};

export default React.memo(withOnlineDetector(SafetyMaintenanceTaskListPage));
