import React from "react";

export interface CollapsedListContextInterface {
  areAllExpanded: boolean;
  toggleAll: () => void;
}

const CollapsedListContext = React.createContext(
  <CollapsedListContextInterface>{}
);

export default CollapsedListContext;
