import React from "react";
import { EntityStateContextInterface } from "module/entity/context/state/EntityStateContext";

export type TrainingCustomStateContextInterface = EntityStateContextInterface;

const TrainingCustomStateContext = React.createContext(
  <TrainingCustomStateContextInterface>{}
);

export default TrainingCustomStateContext;
