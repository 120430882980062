import React, { useMemo } from "react";
import { useAppSelector } from "redux/hooks";
import { selectStaffById } from "module/auth/redux/authSlice";
import t from "module/translations";

interface Props {
  staffIds: number[];
}

const StaffNamesText: React.FC<Props> = ({ staffIds }) => {
  const selectStaffByIdHelper = useAppSelector(selectStaffById);
  const staffNames = useMemo(
    () =>
      staffIds.map((staffId) => {
        try {
          const staff = selectStaffByIdHelper(staffId);
          return staff.name;
        } catch (e) {
          return t.staffDeletedText;
        }
      }),
    [selectStaffByIdHelper, staffIds]
  );

  return <>{staffNames.join(", ")}</>;
};

export default React.memo(StaffNamesText);
