import { useContext, useCallback, useMemo } from "react";
import { v4 as uuid } from "uuid";
import { API_DATE_TIME_FORMAT } from "api/date";
import moment from "moment";
import { useAppDispatch, useAppSelector } from "redux/hooks";
import { selectSystem, selectUser } from "module/auth/redux/authSlice";
import usePermissions from "module/permission/usePermissions";
import { MaintenanceTaskInterface } from "module/maintenanceTask/types";
import MaintenanceTaskContext from "module/maintenanceTask/context/MaintenanceTaskContext";
import MaintenanceTaskOpenCloseContext from "module/maintenanceTask/openClose/context/MaintenanceTaskOpenCloseContext";
import { canAddMaintenanceTaskAttachment } from "module/maintenanceTaskAttachment/helper/canAddMaintenanceTaskAttachment";
import {
  addToEntityPropertyList,
  addToMyEntities,
} from "module/staff/redux/staffDataSlice";
import {
  AttachmentInterface,
  MyAttachmentInterface,
} from "module/attachment/types";
import { EntityType } from "module/entity/types";

interface Result {
  canAdd: boolean;
  add: (mimeType: string, base64: string) => void;
}

const useMaintenanceTaskAttachmentAdd = (): Result => {
  const { entity } = useContext(MaintenanceTaskContext);
  const maintenanceTask = entity as MaintenanceTaskInterface;
  const maintenanceTaskEntityIdentifier = useMemo(
    () => ({
      id: maintenanceTask.id,
      type: EntityType.maintenanceTask,
    }),
    [maintenanceTask.id]
  );

  const { isOpen } = useContext(MaintenanceTaskOpenCloseContext);

  const dispatch = useAppDispatch();
  const staffId = useAppSelector(selectUser).id;
  const systemShortName = useAppSelector(selectSystem).shortName;

  const { canEditMaintenanceTask } = usePermissions();

  const canAdd = useMemo(
    () =>
      canAddMaintenanceTaskAttachment({
        isMaintenanceTaskOpen: isOpen,
        maintenanceTask,
        canEditMaintenanceTask,
      }),
    [canEditMaintenanceTask, isOpen, maintenanceTask]
  );

  const add = useCallback(
    (mimeType: string, base64: string) => {
      const id = uuid();
      const entityIdentifier = {
        type: EntityType.attachment,
        id,
      };

      const today = moment()
        .set({ hour: 0, minute: 0, second: 0 })
        .format(API_DATE_TIME_FORMAT);

      const data: AttachmentInterface = {
        id,
        base64,
        mimeType,
        relationships: undefined,
        relationshipIdentifiers: [],
        changedDateTime: today,
      };

      const myAttachment: MyAttachmentInterface = {
        entityIdentifier,
        data,
        dataWithoutChanges: data,
        validationErrors: null,
        validationWarnings: null,
      };

      dispatch(
        addToMyEntities({
          staffId,
          systemShortName,
          entities: [myAttachment],
        })
      );

      dispatch(
        addToEntityPropertyList({
          staffId,
          systemShortName,
          baseEntityIdentifier: maintenanceTaskEntityIdentifier,
          propertyName: "attachments",
          entityIdentifierToAdd: entityIdentifier,
        })
      );
    },
    [dispatch, maintenanceTaskEntityIdentifier, staffId, systemShortName]
  );

  return {
    canAdd,
    add,
  };
};

export default useMaintenanceTaskAttachmentAdd;
