import {
  MaintenanceTaskInterface,
  MaintenanceTaskType,
} from "module/maintenanceTask/types";

export const isObservationalIndicatorsAvailable = (
  maintenanceTask: MaintenanceTaskInterface
): boolean =>
  [
    MaintenanceTaskType.corrective,
    MaintenanceTaskType.carp,
    MaintenanceTaskType.failure,
    MaintenanceTaskType.modification,
  ].includes(maintenanceTask.type);
