import makeStyles from "@mui/styles/makeStyles";

export const useDownloadedMaintenanceTaskDetailsPersonnelAssignmentsInternalStyles =
  makeStyles(() => ({
    table: {
      minWidth: "100%",
    },
    staffName: {
      width: "30%",
    },
    staffPosition: {
      width: "30%",
    },
    responsibility: {
      width: "30%",
    },
    duration: {
      width: "15%",
    },
    actions: {
      width: "50px",
    },
  }));
