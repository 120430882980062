import React, { useContext } from "react";
import { AssessmentObjectiveInterface } from "module/assessment/types";
import AssessmentObjectiveContext from "module/assessmentObjective/context/AssessmentObjectiveContext";
import AssessmentObjectiveTableRowCategoryContainer from "module/assessmentObjective/enrichedComponent/tableRow/category/AssessmentObjectiveTableRowCategoryContainer";
import AssessmentObjectiveTableRowItemContainer from "module/assessmentObjective/enrichedComponent/tableRow/item/AssessmentObjectiveTableRowItemContainer";

const AssessmentObjectiveTableRowContainer: React.FC = () => {
  const { entity } = useContext(AssessmentObjectiveContext);

  const assessmentObjective = entity as AssessmentObjectiveInterface;

  return assessmentObjective.isCategory ? (
    <AssessmentObjectiveTableRowCategoryContainer />
  ) : (
    <AssessmentObjectiveTableRowItemContainer />
  );
};

export default React.memo(AssessmentObjectiveTableRowContainer);
