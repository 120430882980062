import React from "react";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import useDateFormat from "toolkit/useDateFormat";
import LinkWithIcon from "component/link/LinkWithIcon";
import { MaintenanceTaskInterface } from "module/maintenanceTask/types";
import { getTypeTranslation } from "module/maintenanceTask/helper/getTypeTranslation";
import { getPathToMaintenanceTaskToBeSignedDetails } from "module/maintenanceTask/helper/getPath";
import t from "module/translations";

interface Props {
  maintenanceTask: MaintenanceTaskInterface;
}

const ToBeSignedListTableRowMaintenanceTask: React.FC<Props> = ({
  maintenanceTask,
}) => {
  const { formatDateTime } = useDateFormat();
  return (
    <TableRow hover tabIndex={-1} key={maintenanceTask.id}>
      <TableCell>{t.maintenanceTaskText}</TableCell>
      <TableCell>
        <LinkWithIcon
          {...{
            label: `${maintenanceTask.reportingNumber} ${maintenanceTask.name}`,
            path: getPathToMaintenanceTaskToBeSignedDetails(maintenanceTask.id),
          }}
        />
      </TableCell>
      <TableCell>{getTypeTranslation(maintenanceTask.type)}</TableCell>
      <TableCell>
        {maintenanceTask.scheduledStartDate
          ? formatDateTime(maintenanceTask.scheduledStartDate)
          : "-"}
      </TableCell>
    </TableRow>
  );
};

export default React.memo(ToBeSignedListTableRowMaintenanceTask);
