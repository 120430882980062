import React, { useMemo } from "react";
import { useAppSelector } from "redux/hooks";
import { selectStaffById } from "module/auth/redux/authSlice";
import t from "module/translations";

interface Props {
  staffId: number;
}

const StaffNameText: React.FC<Props> = ({ staffId }) => {
  const selectStaffByIdHelper = useAppSelector(selectStaffById);
  const staff = useMemo(() => {
    try {
      return selectStaffByIdHelper(staffId);
    } catch (e) {
      return null;
    }
  }, [selectStaffByIdHelper, staffId]);

  if (staff == null) {
    return <>t.staffDeletedText</>;
  }

  return (
    <>
      {staff.name} {staff.isActive ? "" : ` (${t.staffDeletedText})`}
    </>
  );
};

export default React.memo(StaffNameText);
