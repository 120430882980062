import React, { useState, useCallback } from "react";
import Image from "component/image/Image";
import ConfirmDialog from "component/confirmDialog/ConfirmDialog";
import t from "module/translations";
import { AttachmentInterface } from "module/attachment/types";

interface Props {
  attachment: AttachmentInterface;
  canRemove: boolean;
  remove: () => void;
}

const Attachment: React.FC<Props> = ({ attachment, canRemove, remove }) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const onConfirm = useCallback(async () => {
    setIsOpen(false);
    remove();
  }, [remove]);
  const onClick = useCallback(() => setIsOpen(true), []);
  const onCancel = useCallback(() => setIsOpen(false), []);

  if (attachment.mimeType != null && attachment.mimeType.startsWith("image")) {
    return (
      <>
        <Image
          {...{ src: attachment.base64 as string, canRemove, remove: onClick }}
        />
        <ConfirmDialog
          {...{
            onConfirm,
            onCancel,
            isOpen,
            title: t.attachment.deleteTitle,
          }}
        />
      </>
    );
  }

  return null;
};

export default React.memo(Attachment);
