import React from "react";
import { EntityContextInterface } from "module/entity/context/entity/EntityContext";

export type MaintenanceTaskProcessStepContextInterface = EntityContextInterface;

const MaintenanceTaskProcessStepContext = React.createContext(
  <MaintenanceTaskProcessStepContextInterface>{}
);

export default MaintenanceTaskProcessStepContext;
