import React, { useContext } from "react";
import ToggleContext from "module/toggle/context/ToggleContext";
import ListToolbar from "component/listToolbar/ListToolbar";
import ListToolbarSelectedItems from "component/listToolbar/ListToolbarSelectedItems";
import ListToolbarSyncButtonContainer from "module/sync/ListToolbarSyncButtonContainer";
import ListToolbarDownloadButtonContainer from "module/download/ListToolbarDownloadButtonContainer";
import ListToolbarGroupEditButtonContainer from "module/groupEdit/ListToolbarGroupEditButtonContainer";

interface Props {
  title: string;
  filters?: React.ReactNode;
  isDownloadAvailable?: boolean;
  isSyncAvailable?: boolean;
  isGroupEditAvailable?: boolean;
}

const ListToolbarContainer: React.FC<Props> = ({
  title,
  filters,
  isDownloadAvailable,
  isSyncAvailable,
  isGroupEditAvailable,
}) => {
  const { selectedItemsCount } = useContext(ToggleContext);

  if (!selectedItemsCount) {
    return <ListToolbar {...{ title }}>{filters}</ListToolbar>;
  }

  return (
    <ListToolbarSelectedItems {...{ selectedItemsCount }}>
      <>
        {isGroupEditAvailable && <ListToolbarGroupEditButtonContainer />}
        {isDownloadAvailable && <ListToolbarDownloadButtonContainer />}
        {isSyncAvailable && <ListToolbarSyncButtonContainer />}
      </>
    </ListToolbarSelectedItems>
  );
};

export default React.memo(ListToolbarContainer);
