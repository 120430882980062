import React from "react";
import t from "module/translations";
import Typography from "@mui/material/Typography";
import MaintenanceTaskMaintainedAtValueFieldContainer from "module/maintenanceTask/enrichedComponent/maintainedAtValueField/MaintenanceTaskMaintainedAtValueFieldContainer";
import makeStyles from "@mui/styles/makeStyles";

const useStyles = makeStyles({
  container: {
    marginBottom: "2em",
  },
});

interface Props {
  parameterName?: string;
  scheduledValue: number;
}

const MyMaintenanceTaskDetailsOverviewMaintainedAtValue: React.FC<Props> = ({
  parameterName,
  scheduledValue,
}) => {
  const classes = useStyles();
  return (
    <div className={classes.container}>
      <Typography variant="h6" gutterBottom>
        {t.maintenanceTask.maintainedAtValueText}
      </Typography>
      {parameterName && (
        <Typography variant="caption" gutterBottom>
          {t.maintenanceTask.maintainedAtValueDescription(parameterName)}
        </Typography>
      )}
      <MaintenanceTaskMaintainedAtValueFieldContainer />
      <Typography variant="caption" gutterBottom>
        {t.maintenanceTask.maintainedAtValuePostDescription(
          Number(scheduledValue.toFixed(2))
        )}
      </Typography>
    </div>
  );
};

export default React.memo(MyMaintenanceTaskDetailsOverviewMaintainedAtValue);
