import React, { useContext, useMemo } from "react";
import TrainingContext from "module/training/context/TrainingContext";
import MyTrainingGroupDetailsSessions from "pages/myTrainingGroupDetails/sessions/MyTrainingGroupDetailsSessions";
import { TrainingInterface } from "module/training/types";
import EntityCollectionContext from "module/entity/context/entityCollection/EntityCollectionContext";
import { EntityIdentifier } from "module/entity/types";

const MyTrainingGroupDetailsSessionsContainer: React.FC = () => {
  const { entity } = useContext(TrainingContext);
  const { entityCollection } = useContext(EntityCollectionContext);

  const training = entity as TrainingInterface;
  const trainingCollection = entityCollection as Map<
    EntityIdentifier,
    TrainingInterface
  >;

  const trainingGroupSessionsIdentifiers: EntityIdentifier[][] = useMemo(() => {
    const result: EntityIdentifier[][] = [];

    // eslint-disable-next-line array-callback-return
    training.sessions.map((session, index) => {
      result.push(
        Array.from(trainingCollection.values()).map(
          (singleTraining) => singleTraining.sessions[index]
        )
      );
    });
    return result;
  }, [training.sessions, trainingCollection]);

  return (
    <MyTrainingGroupDetailsSessions {...{ trainingGroupSessionsIdentifiers }} />
  );
};

export default React.memo(MyTrainingGroupDetailsSessionsContainer);
