import React from "react";
import TableBody from "@mui/material/TableBody";
import TableContainer from "component/table/TableContainer";
import MyCompetencyManagementListTableHeadContainer from "pages/myCompetencyManagementList/table/head/MyCompetencyManagementListTableHeadContainer";
import MyCompetencyManagementListTableRowContainer from "pages/myCompetencyManagementList/table/row/MyCompetencyManagementListTableRowContainer";
import Table from "component/table/Table";
import { EntityIdentifier } from "module/entity/types";
import t from "module/translations";
import ListToolbarContainer from "module/listToolbar/ListToolbarContainer";
import { getEntityIdentifierKey } from "module/entity/helper/getEntityIdentifierKey";

interface MyCompetencyManagementListProps {
  entityIdentifiers: EntityIdentifier[];
}

const MyCompetencyManagementList: React.FC<MyCompetencyManagementListProps> = ({
  entityIdentifiers,
}) => {
  return (
    <TableContainer>
      <ListToolbarContainer
        {...{
          title: t.competencyManagement.downloadedHeaderText,
          isSyncAvailable: true,
          isGroupEditAvailable: true,
        }}
      />
      <Table>
        <MyCompetencyManagementListTableHeadContainer />
        <TableBody>
          {entityIdentifiers.map((entityIdentifier) => (
            <MyCompetencyManagementListTableRowContainer
              {...{
                key: getEntityIdentifierKey(entityIdentifier),
                entityIdentifier,
              }}
            />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default MyCompetencyManagementList;
