import React, { useContext, useState, useEffect } from "react";
import { EntityIdentifier } from "module/entity/types";
import { useAppSelector } from "redux/hooks";
import selectEntity from "module/staff/redux/selector/selectEntity";
import {
  AttachmentInterface,
  MyAttachmentInterface,
} from "module/attachment/types";
import Attachment from "module/attachment/enrichedComponent/Attachment";
import useMaintenanceTaskProcessStepAttachmentRemove from "module/maintenanceTaskProcessStepAttachment/remove/useMaintenanceTaskProcessStepAttachmentRemove";
import { convertFileIntoBase64 } from "toolkit/convertFileIntoBase64";
import MaintenanceTaskProcessStepContext from "module/maintenanceTaskProcessStep/context/MaintenanceTaskProcessStepContext";
import { MaintenanceTaskProcessStepInterface } from "module/maintenanceTaskProcessStep/types";

interface Props {
  entityIdentifier: EntityIdentifier;
}

const MaintenanceTaskProcessStepAttachmentListItemContainer: React.FC<Props> =
  ({ entityIdentifier }) => {
    const [attachment, setAttachment] =
      useState<AttachmentInterface | null>(null);

    const { entity } = useContext(MaintenanceTaskProcessStepContext);
    const processStep = entity as MaintenanceTaskProcessStepInterface;

    const myAttachment = useAppSelector(selectEntity)(
      entityIdentifier
    ) as MyAttachmentInterface;

    useEffect(() => {
      async function getBase64(attachmentFromApi: AttachmentInterface) {
        const base64 = await convertFileIntoBase64(
          attachmentFromApi.path as string,
          attachmentFromApi.mimeType
        );

        setAttachment({ ...attachmentFromApi, base64 });
      }

      if (myAttachment != null) {
        setAttachment(myAttachment.data);
      } else {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        const rawAttachment = processStep.relationships.get(entityIdentifier)
          .data as AttachmentInterface;
        getBase64(rawAttachment);
      }
    }, [attachment, entityIdentifier, processStep.relationships, myAttachment]);

    const { canRemove, remove } =
      useMaintenanceTaskProcessStepAttachmentRemove(entityIdentifier);

    if (attachment == null) {
      return null;
    }

    return <Attachment {...{ attachment, canRemove, remove }} />;
  };

export default React.memo(
  MaintenanceTaskProcessStepAttachmentListItemContainer
);
