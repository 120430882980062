import React from "react";

const NewVersionAvailable: React.FC = () => {
  document.addEventListener("visibilitychange", () => {
    if (document.visibilityState === "visible") {
      window.location.reload(true);
    }
  });

  navigator.serviceWorker.addEventListener("controllerchange", () => {
    window.location.reload();
  });

  return null;
};

export default React.memo(NewVersionAvailable);
