import {
  ApiMaintenanceTaskInterface,
  ApiMaintenanceTaskProcessStep,
} from "api/maintenanceTask/types";
import {
  MaintenanceTaskProcessStepInterface,
  MyMaintenanceTaskProcessStepInterface,
} from "module/maintenanceTaskProcessStep/types";
import {
  EntityIdentifier,
  EntityType,
  MyEntityInterface,
} from "module/entity/types";
import { mapApiMaintenanceTaskProcessStepQuickFixToApp } from "module/maintenanceTaskProcessStepQuickFix/helper/mapApiMaintenanceTaskProcessStepQuickFixToApp";
import { mapApiAttachmentToApp } from "module/attachment/helper/mapApiAttachmentToApp";
import { mergeEntityMaps } from "module/entity/helper/mergeEntityMaps";

export const mapApiMaintenanceTaskProcessStepToApp = (
  rawMaintenanceTask: ApiMaintenanceTaskInterface,
  raw: ApiMaintenanceTaskProcessStep
): MyMaintenanceTaskProcessStepInterface => {
  const quickFixRelationships = new Map<EntityIdentifier, MyEntityInterface>();
  const myQuickFix =
    raw.quickFix == null
      ? null
      : mapApiMaintenanceTaskProcessStepQuickFixToApp(raw, raw.quickFix);
  const quickFix = myQuickFix == null ? null : myQuickFix.entityIdentifier;

  if (myQuickFix != null) {
    quickFixRelationships.set(myQuickFix.entityIdentifier, myQuickFix);
  }

  // support only images
  const attachments =
    raw.attachments == null
      ? (new Map() as Map<EntityIdentifier, MyEntityInterface>)
      : raw.attachments
          .filter((rawAttachment) =>
            rawAttachment.mimeType.startsWith("image/")
          )
          .reduce((result, rawAttachment) => {
            const myEntity = mapApiAttachmentToApp(rawAttachment);
            result.set(myEntity.entityIdentifier, myEntity);
            return result;
          }, new Map() as Map<EntityIdentifier, MyEntityInterface>);

  const relationships = mergeEntityMaps([quickFixRelationships, attachments]);

  const correctiveMaintenanceTaskEntityIdentifier: EntityIdentifier | null =
    raw.followUp
      ? {
          id: `${raw.followUp.id}`,
          backendId: raw.followUp.id,
          type: EntityType.maintenanceTask,
        }
      : null;

  const data: MaintenanceTaskProcessStepInterface = {
    ...raw,
    id: `${raw.id}`,
    backendId: raw.id,
    maintenanceTaskId: `${rawMaintenanceTask.id}`,
    quickFix,
    correctiveMaintenanceTask: correctiveMaintenanceTaskEntityIdentifier,
    attachments: Array.from(attachments.keys()),
    articleId: raw.articleId == null ? undefined : raw.articleId,
    componentId: raw.componentId == null ? undefined : raw.componentId,
    relationships,
    relationshipIdentifiers: Array.from(relationships.keys()),
    visualBreakAfter: raw.visualBreakAfter ?? false,
  };

  return {
    entityIdentifier: {
      type: EntityType.maintenanceTaskProcessStep,
      id: `${raw.id}`,
      backendId: raw.id,
    },
    data,
    dataWithoutChanges: data,
    raw,
    validationErrors: null,
    validationWarnings: null,
  };
};
