import React from "react";
import { MaintenanceTaskDefinitionType } from "pages/maintenanceTaskList/types";
import t from "module/translations";
import HeaderSubtitle from "component/headerSubTitle/HeaderSubTitle";

interface Props {
  definitionType?: MaintenanceTaskDefinitionType;
}

const MaintenanceTaskDetailsHeaderTitle: React.FC<Props> = ({
  definitionType,
}) => {
  return (
    <HeaderSubtitle>
      {(definitionType == null ||
        definitionType === MaintenanceTaskDefinitionType.system) &&
        t.maintenanceTask.downloadedHeaderText}
      {[
        MaintenanceTaskDefinitionType.equipment,
        MaintenanceTaskDefinitionType.sms,
        MaintenanceTaskDefinitionType.audit,
      ].includes(definitionType as MaintenanceTaskDefinitionType) &&
        t.safetyMaintenanceTask.downloadedHeaderText}
    </HeaderSubtitle>
  );
};

export default React.memo(MaintenanceTaskDetailsHeaderTitle);
