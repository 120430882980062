import React, { useMemo } from "react";
import { useAppSelector } from "redux/hooks";
import { useHistory } from "react-router-dom";
import ErrorPage from "error/ErrorPage";
import { EntityIdentifier, EntityType } from "module/entity/types";
import { getPathToMaintenanceTaskDetails } from "module/maintenanceTask/helper/getPath";
import { MyMaintenanceTaskInterface } from "module/maintenanceTask/types";
import { MaintenanceTaskDefinitionType } from "pages/maintenanceTaskList/types";
import selectOpenItemsByEntityType from "module/staff/redux/selector/selectOpenItemsByEntityType";
import selectEntity from "module/staff/redux/selector/selectEntity";

const MyOpenSafetyMaintenanceTaskDetailsPage: React.FC = () => {
  const selectEntityHelper = useAppSelector(selectEntity);
  const openMaintenanceTasksEntityIdentifier = useAppSelector(
    selectOpenItemsByEntityType(EntityType.maintenanceTask)
  ) as EntityIdentifier[];

  const openSafetyMaintenanceTaskEntityIdentifier = useMemo(
    () =>
      openMaintenanceTasksEntityIdentifier.reduce(
        (result, openMaintenanceTaskEntityIdentifier) => {
          if (result == null) {
            const myMaintenanceTask = selectEntityHelper(
              openMaintenanceTaskEntityIdentifier
            ) as MyMaintenanceTaskInterface;
            if (
              [
                MaintenanceTaskDefinitionType.equipment,
                MaintenanceTaskDefinitionType.sms,
                MaintenanceTaskDefinitionType.audit,
              ].includes(
                myMaintenanceTask.data
                  .definitionType as MaintenanceTaskDefinitionType
              )
            ) {
              return openMaintenanceTaskEntityIdentifier;
            }
          }
          return result;
        },
        null as EntityIdentifier | null
      ),
    [openMaintenanceTasksEntityIdentifier, selectEntityHelper]
  );

  const history = useHistory();
  if (openSafetyMaintenanceTaskEntityIdentifier) {
    history.push(
      getPathToMaintenanceTaskDetails(
        openSafetyMaintenanceTaskEntityIdentifier.id
      )
    );
  }

  return <ErrorPage />;
};

export default React.memo(MyOpenSafetyMaintenanceTaskDetailsPage);
