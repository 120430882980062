import React, { useContext } from "react";
import TrainingContext from "module/training/context/TrainingContext";
import { TrainingInterface } from "module/training/types";
import XLabelField from "component/labelField/XLabelField";
import t from "module/translations";

const TrainingReportingNumberFieldContainer: React.FC = () => {
  const { entity } = useContext(TrainingContext);
  const { reportingNumber } = entity as TrainingInterface;

  return (
    <XLabelField
      {...{
        caption: t.competencyManagement.training.reportingNumberText,
        label: reportingNumber,
      }}
    />
  );
};

export default React.memo(TrainingReportingNumberFieldContainer);
