import React from "react";
import Typography from "@mui/material/Typography";
import makeStyles from "@mui/styles/makeStyles";
import FullScreenContainer from "component/fullscreenContainer/FullscreenContainer";
import t from "module/translations";
import Button from "@mui/material/Button";
import PermIdentityIcon from "@mui/icons-material/PermIdentity";

const useStyles = makeStyles(() => ({
  text: {
    marginTop: "12px",
    color: "#fff",
  },
  loginButtonContainer: {
    marginTop: "72px",
  },
  loginButton: {
    backgroundColor: "#fff",
    color: "rgba(0, 0, 0, 0.87)",
    "&:hover": {
      backgroundColor: "gray",
    },
  },
}));

interface Props {
  login: () => void;
}

const AuthLoginPage: React.FC<Props> = ({ login }) => {
  const classes = useStyles();
  return (
    <FullScreenContainer>
      <Typography variant="h2" gutterBottom className={classes.text}>
        {t.auth.login.title}
      </Typography>
      <Typography variant="subtitle1" gutterBottom className={classes.text}>
        {t.auth.login.subtitle}
      </Typography>
      <div className={classes.loginButtonContainer}>
        <Button
          size="large"
          variant="contained"
          onClick={login}
          className={classes.loginButton}
          startIcon={<PermIdentityIcon />}
        >
          {t.auth.login.buttonText}
        </Button>
      </div>
    </FullScreenContainer>
  );
};

export default React.memo(AuthLoginPage);
