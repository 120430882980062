import React from "react";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import useDateFormat from "toolkit/useDateFormat";
import makeStyles from "@mui/styles/makeStyles";
import { InventoryCheckInterface } from "module/inventoryCheck/types";
import StaffNameText from "module/staff/StaffNameText";
import InventoryCheckListTableRowSelectTableCellContainer from "./selectTableCell/InventoryCheckListTableRowSelectTableCellContainer";

const useStyles = makeStyles({
  cell: {
    fontWeight: (props: { isHighlighted: boolean }) =>
      props.isHighlighted ? "bold" : "normal",
  },
});

interface Props {
  inventoryCheck: InventoryCheckInterface;
  rowClick: () => void;
  isSelected: boolean;
  hasPermissionToDownload: boolean;
  isHighlighted: boolean;
}

const InventoryCheckListTableRow: React.FC<Props> = ({
  inventoryCheck,
  rowClick,
  isSelected,
  hasPermissionToDownload,
  isHighlighted,
}) => {
  const classes = useStyles({ isHighlighted });
  const { formatDateTime } = useDateFormat();

  return (
    <TableRow
      {...{
        hover: true,
        tabIndex: -1,
        role: "checkbox",
        "aria-checked": isSelected,
        selected: isSelected,
        key: inventoryCheck.id,
        onClick: rowClick,
      }}
    >
      {hasPermissionToDownload && (
        <InventoryCheckListTableRowSelectTableCellContainer />
      )}
      <TableCell className={classes.cell}>
        {inventoryCheck.reportingNumber}
      </TableCell>
      <TableCell align="left" className={classes.cell}>
        {inventoryCheck.subject}
      </TableCell>
      <TableCell align="left" className={classes.cell}>
        {inventoryCheck.assigneeId ? (
          <StaffNameText {...{ staffId: inventoryCheck.assigneeId }} />
        ) : (
          "-"
        )}
      </TableCell>
      <TableCell align="left" className={classes.cell}>
        {formatDateTime(inventoryCheck.createdAt)}
      </TableCell>
    </TableRow>
  );
};
export default InventoryCheckListTableRow;
