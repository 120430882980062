import React, { useMemo } from "react";
import { useAppSelector } from "redux/hooks";
import { selectStockArticles } from "module/auth/redux/authSlice";
import XLabelField from "component/labelField/XLabelField";

interface Props {
  value?: number;
  errors: string[];
  warnings: string[];
}

const StockArticleTextContainer: React.FC<Props> = ({
  value,
  errors,
  warnings,
}) => {
  const stockArticles = useAppSelector(selectStockArticles);
  const stockArticle = useMemo(
    () => (value != null ? stockArticles[value] : undefined),
    [stockArticles, value]
  );
  const label = useMemo(
    () => (stockArticle ? stockArticle.text : undefined),
    [stockArticle]
  );

  return <XLabelField {...{ label, errors, warnings }} />;
};

export default React.memo(StockArticleTextContainer);
