import React, { useContext, useState, useCallback, useMemo } from "react";
import t from "module/translations";
import CompetencyManagementListToolbarFilterType from "module/competencyManagement/enrichedComponent/typeFilterField/CompetencyManagementListToolbarFilterType";
import { EntityType } from "module/entity/types";
import FilterContext from "module/filter/context/FilterContext";
import { SelectOption } from "component/selectField/type";

const CompetencyManagementListToolbarFilterTypeContainer: React.FC = () => {
  const [value, setValue] = useState<EntityType | null>(null);
  const { filters, changeFilters } = useContext(FilterContext);

  const change = useCallback(
    (newType: EntityType) => {
      setValue(newType);
      changeFilters({
        ...filters,
        types: [newType],
      });
    },
    [changeFilters, filters]
  );

  const options: SelectOption[] = useMemo(
    () => [
      {
        value: null,
        text: t.allText,
      },
      {
        value: EntityType.assessment,
        text: t.competencyManagement.assessmentText,
      },
      {
        value: EntityType.training,
        text: t.competencyManagement.trainingText,
      },
    ],
    []
  );

  return (
    <CompetencyManagementListToolbarFilterType
      {...{ change, options, value }}
    />
  );
};

export default React.memo(CompetencyManagementListToolbarFilterTypeContainer);
