import React from "react";
import { EntityStateContextInterface } from "module/entity/context/state/EntityStateContext";

export type MaintenanceTaskStateContextInterface = EntityStateContextInterface;

const MaintenanceTaskStateContext = React.createContext(
  <MaintenanceTaskStateContextInterface>{}
);

export default MaintenanceTaskStateContext;
