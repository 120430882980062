import React, { useContext } from "react";
import { getFieldMetaData as getFieldMetaDataHelper } from "module/fieldMetaData/getFieldMetaData";
import MaintenanceTaskOpenCloseContext from "module/maintenanceTask/openClose/context/MaintenanceTaskOpenCloseContext";
import MaintenanceTaskStateContext from "module/maintenanceTask/state/context/MaintenanceTaskStateContext";
import MaintenanceTaskPersonnelAssignmentInternalStaffValidationResultContext from "module/maintenanceTaskPersonnelAssignment/internalStaff/validation/MaintenanceTaskPersonnelAssignmentInternalStaffValidationResultContext";
import MaintenanceTaskPersonnelAssignmentInternalStaffFieldMetaDataContext, {
  MaintenanceTaskPersonnelAssignmentInternalStaffFieldMetaDataContextInterface,
} from "module/maintenanceTaskPersonnelAssignment/internalStaff/validation/fieldMetaData/MaintenanceTaskPersonnelAssignmentInternalStaffFieldMetaDataContext";

interface Prop {
  children: React.ReactNode;
}
const MyMaintenanceTaskPersonnelAssignmentInternalStaffFieldMetaDataContextProvider: React.FC<Prop> =
  ({ children }) => {
    const { validationWarnings, validationErrors } = useContext(
      MaintenanceTaskPersonnelAssignmentInternalStaffValidationResultContext
    );
    const { isOpen } = useContext(MaintenanceTaskOpenCloseContext);
    const { isStateScheduledOrLower } = useContext(MaintenanceTaskStateContext);

    const getFieldMetaData = getFieldMetaDataHelper({
      validationWarnings,
      validationErrors,
      isOpen,
      isStateScheduledOrLower,
    });

    const contextValue: MaintenanceTaskPersonnelAssignmentInternalStaffFieldMetaDataContextInterface =
      {
        getFieldMetaData,
      };

    return (
      <MaintenanceTaskPersonnelAssignmentInternalStaffFieldMetaDataContext.Provider
        value={contextValue}
      >
        {children}
      </MaintenanceTaskPersonnelAssignmentInternalStaffFieldMetaDataContext.Provider>
    );
  };

export default MyMaintenanceTaskPersonnelAssignmentInternalStaffFieldMetaDataContextProvider;
