import React, { useMemo } from "react";
import { useParams } from "react-router-dom";
import { MaintenanceTaskInterface } from "module/maintenanceTask/types";
import { EntityType } from "module/entity/types";
import { useAppSelector } from "redux/hooks";
import selectEntity from "module/staff/redux/selector/selectEntity";
import MaintenanceTaskHeaderTitle from "module/maintenanceTask/enrichedComponent/headerTitle/MaintenanceTaskHeaderTitle";

const MyMaintenanceTaskDetailsHeaderTitle: React.FC = () => {
  const { maintenanceTaskId: id } = useParams<{ maintenanceTaskId: string }>();
  const entityIdentifier = useMemo(
    () => ({
      id,
      type: EntityType.maintenanceTask,
    }),
    [id]
  );
  const myMaintenanceTask = useAppSelector(selectEntity)(entityIdentifier);
  if (myMaintenanceTask == null) {
    return null;
  }
  const maintenanceTask = myMaintenanceTask.data as MaintenanceTaskInterface;

  return (
    <MaintenanceTaskHeaderTitle
      {...{ definitionType: maintenanceTask.definitionType }}
    />
  );
};

export default React.memo(MyMaintenanceTaskDetailsHeaderTitle);
