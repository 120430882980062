import React from "react";
import { Route } from "react-router-dom";
import TrainingDetailsNavigation from "module/training/enrichedComponent/detailsNavigation/TrainingDetailsNavigation";
import routes from "routes/routes";
import {
  getPathToTrainingToBeSignedDetailsOverview,
  getPathToTrainingToBeSignedDetailsSessions,
} from "module/training/helper/getPath";

interface Props {
  trainingId: string;
}

const TrainingToBeSignedDetails: React.FC<Props> = ({ trainingId }) => {
  return (
    <>
      <TrainingDetailsNavigation
        {...{
          listPath: routes.toBeSigned.path,
          overviewPath: getPathToTrainingToBeSignedDetailsOverview(trainingId),
          sessionsPath: getPathToTrainingToBeSignedDetailsSessions(trainingId),
        }}
      />
      <Route
        {...{
          key: routes.trainingToBeSignedDetailsOverview.path,
          path: routes.trainingToBeSignedDetailsOverview.path,
          render: () => (
            <routes.trainingToBeSignedDetailsOverview.mainComponent />
          ),
        }}
      />
      <Route
        {...{
          key: routes.trainingToBeSignedDetailsSessions.path,
          path: routes.trainingToBeSignedDetailsSessions.path,
          render: () => (
            <routes.trainingToBeSignedDetailsSessions.mainComponent />
          ),
        }}
      />
    </>
  );
};

export default React.memo(TrainingToBeSignedDetails);
