import React, { useEffect, useState } from "react";
import {
  getStateSlice,
  rewriteStaffDataToLocalStorage,
} from "module/storage/storage";
import { useAppDispatch } from "redux/hooks";
import {
  initialize as staffDataInitialize,
  sliceKey as staffDataSliceKey,
} from "module/staff/redux/staffDataSlice";
import { StaffDataState } from "module/staff/redux/types";
import { sliceKey as authSliceKey } from "module/auth/constants";
import { setSystemConfiguration } from "module/auth/redux/authSlice";

interface Prop {
  children: React.ReactNode;
}
const ReduxPersistGate: React.FC<Prop> = ({ children }) => {
  const dispatch = useAppDispatch();
  const [initialized, setInitialized] = useState<boolean>(false);

  useEffect(() => {
    async function init() {
      await rewriteStaffDataToLocalStorage();

      const staffDataState = await getStateSlice(staffDataSliceKey);

      if (
        staffDataState != null &&
        Object.keys(staffDataState as StaffDataState).length
      ) {
        dispatch(staffDataInitialize(staffDataState as StaffDataState));
      }

      const authData = await getStateSlice(authSliceKey);
      const system = authData?.user?.availableSystems.find(
        (availableSystem: { shortName: any }) =>
          availableSystem.shortName === authData.system
      );

      if (system?.configuration != null) {
        dispatch(setSystemConfiguration(system));
      }

      setInitialized(true);
    }

    init();
  }, [dispatch]);

  if (!initialized) {
    return null;
  }

  return <>{children}</>;
};

export default ReduxPersistGate;
