import React from "react";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import Button from "@mui/material/Button";

interface Props {
  onClick: () => void;
  label: string;
}

const AddButton: React.FC<Props> = ({ onClick, label }) => {
  return (
    <Button
      variant="contained"
      size="small"
      color="primary"
      startIcon={<AddCircleIcon />}
      onClick={onClick}
    >
      {label}
    </Button>
  );
};

export default React.memo(AddButton);
