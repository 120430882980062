import React, { useContext, useMemo } from "react";
import TableInfoIconCell from "component/table/infoIconCell/TableInfoIconCell";
import { getMaintenanceTaskSyncUnavailableTranslation } from "module/maintenanceTask/helper/getMaintenanceTaskSyncUnavailableTranslation";
import {
  MaintenanceTaskInterface,
  MaintenanceTaskSyncUnavailableReason,
} from "module/maintenanceTask/types";
import MaintenanceTaskContext from "module/maintenanceTask/context/MaintenanceTaskContext";
import { EntityIdentifier, EntityType } from "module/entity/types";
import useMaintenanceTaskIsSyncAvailable from "module/maintenanceTask/sync/useMaintenanceTaskIsSyncAvailable";
import TableToggleCellContainer from "module/toggle/TableToggleCellContainer";

const MyMaintenanceTaskListTableCellSyncContainer: React.FC = () => {
  const { entity } = useContext(MaintenanceTaskContext);
  const maintenanceTask = entity as MaintenanceTaskInterface;

  const { isMaintenanceTaskSyncAvailable } =
    useMaintenanceTaskIsSyncAvailable();

  const canSync = useMemo(
    () => isMaintenanceTaskSyncAvailable(maintenanceTask.id),
    [isMaintenanceTaskSyncAvailable, maintenanceTask.id]
  );

  const entityIdentifier: EntityIdentifier = useMemo(
    () => ({
      id: maintenanceTask.id,
      type: EntityType.maintenanceTask,
    }),
    [maintenanceTask.id]
  );

  if (canSync.isAvailable) {
    return <TableToggleCellContainer {...{ entityIdentifier }} />;
  }

  if (canSync.unavailableReason != null) {
    return (
      <TableInfoIconCell
        {...{
          label: getMaintenanceTaskSyncUnavailableTranslation(
            canSync.unavailableReason as MaintenanceTaskSyncUnavailableReason
          ),
        }}
      />
    );
  }

  return null;
};

export default React.memo(MyMaintenanceTaskListTableCellSyncContainer);
