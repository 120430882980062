import { IsEntitySyncAvailableResult } from "module/entity/useEntityAbstractFactory";
import { TrainingCustomSyncUnavailableReason } from "module/trainingCustom/types";

interface Props {
  isOnline: boolean;
  hasPermissions: boolean;
  isOpen: boolean;
  isValid: boolean;
  hasChanged: boolean;
}

const isTrainingCustomSyncAvailable = ({
  hasPermissions,
  isOnline,
  isOpen,
  isValid,
  hasChanged,
}: Props): IsEntitySyncAvailableResult => {
  if (!isOnline) {
    return {
      isAvailable: false,
      unavailableReason: TrainingCustomSyncUnavailableReason.offline,
    };
  }

  if (!hasPermissions) {
    return {
      isAvailable: false,
      unavailableReason: TrainingCustomSyncUnavailableReason.noPermissions,
    };
  }

  if (isOpen) {
    return {
      isAvailable: false,
      unavailableReason: TrainingCustomSyncUnavailableReason.isOpen,
    };
  }

  if (!isValid) {
    return {
      isAvailable: false,
      unavailableReason: TrainingCustomSyncUnavailableReason.notValid,
    };
  }

  if (!hasChanged) {
    return {
      isAvailable: false,
      unavailableReason: TrainingCustomSyncUnavailableReason.hasNotChanged,
    };
  }

  return {
    isAvailable: true,
    unavailableReason: null,
  };
};

export default isTrainingCustomSyncAvailable;
