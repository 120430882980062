import React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import t from "module/translations";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import StickyPaperTableContainer from "component/sticky/StickyPaperTableContainer";
import { EntityIdentifier } from "module/entity/types";
import { useMaintenanceTaskPartListStyles } from "module/maintenanceTaskPart/enrichedComponent/table/styles";
import { getEntityIdentifierKey } from "module/entity/helper/getEntityIdentifierKey";
import MyMaintenanceTaskPartListRowContextProvider from "pages/myMaintenanceTaskDetails/partList/table/row/MyMaintenanceTaskPartListRowContextProvider";
import AddIconButton from "component/addButton/AddIconButton";
import MyMaintenanceTaskPartListTableRowContainer from "pages/myMaintenanceTaskDetails/partList/table/row/MyMaintenanceTaskPartListTableRowContainer";

interface Props {
  entityIdentifiers: EntityIdentifier[];
  canAdd: boolean;
  add: () => void;
}

const MyMaintenanceTaskPartList: React.FC<Props> = ({
  entityIdentifiers,
  canAdd,
  add,
}) => {
  const classes = useMaintenanceTaskPartListStyles();

  return (
    <StickyPaperTableContainer>
      <Table stickyHeader className={classes.table}>
        <TableHead>
          <TableRow>
            <TableCell align="left" className={classes.stockArticleTableCell}>
              {t.maintenanceTask.parts.stockArticleText}
            </TableCell>
            <TableCell align="left" className={classes.amountTableCell}>
              {t.maintenanceTask.parts.amountText}
            </TableCell>
            <TableCell align="right" className={classes.actionsTableCell} />
          </TableRow>
        </TableHead>
        <TableBody>
          {entityIdentifiers.map((entityIdentifier) => (
            <MyMaintenanceTaskPartListRowContextProvider
              {...{
                key: getEntityIdentifierKey(entityIdentifier),
                entityIdentifier,
              }}
            >
              <MyMaintenanceTaskPartListTableRowContainer />
            </MyMaintenanceTaskPartListRowContextProvider>
          ))}
          {canAdd && (
            <TableRow>
              <TableCell className={classes.stockArticleTableCell}>
                <AddIconButton {...{ onClick: add }} />
              </TableCell>
              <TableCell className={classes.amountTableCell} />
              <TableCell className={classes.actionsTableCell} />
            </TableRow>
          )}
        </TableBody>
      </Table>
    </StickyPaperTableContainer>
  );
};

export default React.memo(MyMaintenanceTaskPartList);
