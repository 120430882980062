import React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import StickyPaperTableContainer from "component/sticky/StickyPaperTableContainer";
import { EntityIdentifier } from "module/entity/types";
import MyTrainingDetailsSessionContextProvider from "pages/myTrainingDetails/sessions/MyTrainingDetailsSessionContextProvider";
import TrainingSessionTableHead from "module/trainingSession/enrichedComponent/tableHead/TrainingSessionTableHead";
import TrainingSessionTableRowContainer from "module/trainingSession/enrichedComponent/tableRow/TrainingSessionTableRowContainer";
import { getEntityIdentifierKey } from "module/entity/helper/getEntityIdentifierKey";

interface Props {
  sessionsIdentifiers: EntityIdentifier[];
}

const MyTrainingDetailsSessions: React.FC<Props> = ({
  sessionsIdentifiers,
}) => {
  return (
    <StickyPaperTableContainer>
      <Table stickyHeader>
        <TrainingSessionTableHead />
        <TableBody>
          {sessionsIdentifiers.map((sessionEntityIdentifier) => (
            <MyTrainingDetailsSessionContextProvider
              {...{
                entityIdentifier: sessionEntityIdentifier,
                key: getEntityIdentifierKey(sessionEntityIdentifier),
              }}
            >
              <TrainingSessionTableRowContainer />
            </MyTrainingDetailsSessionContextProvider>
          ))}
        </TableBody>
      </Table>
    </StickyPaperTableContainer>
  );
};

export default React.memo(MyTrainingDetailsSessions);
