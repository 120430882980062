import { XValidatorErrorsInterface } from "module/validation/types";
import { AssessmentInterface } from "module/assessment/types";

export interface AssessmentValidationResult {
  errors: null | XValidatorErrorsInterface;
  warnings: null | XValidatorErrorsInterface;
}

export const validateAssessment = (
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  assessment: AssessmentInterface
): AssessmentValidationResult => {
  const errors: XValidatorErrorsInterface = {};
  const warnings: XValidatorErrorsInterface = {};

  return {
    errors: Object.keys(errors).length ? errors : null,
    warnings: Object.keys(warnings).length ? warnings : null,
  };
};
