import React, { useContext } from "react";
import MyTrainingCustomDetailsOverview from "pages/myTrainingCustomDetails/overview/MyTrainingCustomDetailsOverview";
import TrainingCustomStateContext from "module/trainingCustom/state/context/TrainingCustomStateContext";

const MyTrainingCustomDetailsOverviewContainer: React.FC = () => {
  const { isStateEditable } = useContext(TrainingCustomStateContext);

  return (
    <MyTrainingCustomDetailsOverview
      {...{
        isStateEditable,
      }}
    />
  );
};

export default React.memo(MyTrainingCustomDetailsOverviewContainer);
