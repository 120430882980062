import * as Sentry from "@sentry/react";
import { isDevelopment, isProduction } from "toolkit/environment";

export const reportErrorHelper = (
  title: string,
  details: unknown = {}
): void => {
  if (details) {
    Sentry.setExtra("details", details);
  }
  Sentry.captureException(new Error(title));
};
export const reportError = (title: string, details: unknown = {}): void => {
  if (isDevelopment || isProduction) {
    reportErrorHelper(title, details);
  }
};
