import React from "react";
import { EntityIdentifier } from "module/entity/types";
import MyEntityContextProvider from "module/entity/context/entity/MyEntityContextProvider";
import { getEntityIdentifierKey } from "module/entity/helper/getEntityIdentifierKey";
import MyEntityValidationResultContextProvider from "module/entity/context/validationResult/MyEntityValidationResultContextProvider";
import MyEntityChangeContextProvider from "module/entity/context/change/MyEntityChangeContextProvider";
import MaintenanceTaskPartContext from "module/maintenanceTaskPart/context/MaintenanceTaskPartContext";
import MaintenanceTaskPartValidationResultContext from "module/maintenanceTaskPart/validation/MaintenanceTaskPartValidationResultContext";
import MyMaintenanceTaskPartFieldMetaDataContextProvider from "module/maintenanceTaskPart/validation/fieldMetaData/MyMaintenanceTaskPartFieldMetaDataContextProvider";
import MaintenanceTaskPartChangeContext from "module/maintenanceTaskPart/change/MaintenanceTaskPartChangeContext";

interface Props {
  entityIdentifier: EntityIdentifier;
  children: React.ReactNode;
}

const MyMaintenanceTaskPartListRowContextProvider: React.FC<Props> = ({
  entityIdentifier,
  children,
}) => {
  return (
    <MyEntityContextProvider
      {...{
        entityIdentifier,
        context: MaintenanceTaskPartContext,
        key: getEntityIdentifierKey(entityIdentifier),
      }}
    >
      <MyEntityValidationResultContextProvider
        {...{
          entityIdentifier,
          context: MaintenanceTaskPartValidationResultContext,
        }}
      >
        <MyMaintenanceTaskPartFieldMetaDataContextProvider>
          <MyEntityChangeContextProvider
            {...{
              entityIdentifier,
              context: MaintenanceTaskPartChangeContext,
            }}
          >
            {children}
          </MyEntityChangeContextProvider>
        </MyMaintenanceTaskPartFieldMetaDataContextProvider>
      </MyEntityValidationResultContextProvider>
    </MyEntityContextProvider>
  );
};

export default MyMaintenanceTaskPartListRowContextProvider;
