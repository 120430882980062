import React from "react";
import Alert from "@mui/material/Alert";
import makeStyles from "@mui/styles/makeStyles";

const useStyles = makeStyles({
  root: {
    marginBottom: "12px",
  },
});

interface Props {
  children: React.ReactNode;
}

const InfoAlert: React.FC<Props> = ({ children }) => {
  const classes = useStyles();
  return (
    <Alert severity="info" className={classes.root}>
      {children}
    </Alert>
  );
};

export default React.memo(InfoAlert);
