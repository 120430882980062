import {
  InventoryCheckArticleInterface,
  InventoryCheckArticleToSyncInterface,
} from "module/inventoryCheckArticle/types";

export const mapInventoryCheckArticleToSync = (
  inventoryCheckArticle: InventoryCheckArticleInterface
): InventoryCheckArticleToSyncInterface => {
  return {
    ...inventoryCheckArticle,
    id: inventoryCheckArticle.backendId as number,
  };
};
