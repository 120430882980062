import React, { useContext } from "react";
import useMyTrainingOpenClose from "module/training/openClose/useMyTrainingOpenClose";
import { TrainingInterface } from "module/training/types";
import TrainingContext from "module/training/context/TrainingContext";
import TrainingOpenCloseContext, {
  TrainingOpenCloseContextInterface,
} from "module/training/openClose/context/TrainingOpenCloseContext";

interface Prop {
  children: React.ReactNode;
}
const MyTrainingOpenCloseContextProvider: React.FC<Prop> = ({ children }) => {
  const { entity } = useContext(TrainingContext);

  const { open, close } = useMyTrainingOpenClose(entity as TrainingInterface);

  const contextValue: TrainingOpenCloseContextInterface = {
    isOpen: close !== undefined,
    open,
    close,
  };

  return (
    <TrainingOpenCloseContext.Provider value={contextValue}>
      {children}
    </TrainingOpenCloseContext.Provider>
  );
};

export default MyTrainingOpenCloseContextProvider;
