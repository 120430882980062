import t from "module/translations";
import { AssessmentType } from "module/assessment/types";

export const getAssessmentTypeTranslation = (state: AssessmentType): string =>
  ({
    [AssessmentType.initial]:
      t.competencyManagement.assessment.type.initialText,
    [AssessmentType.refresher]:
      t.competencyManagement.assessment.type.refresherText,
  }[state]);
