import { useMemo } from "react";
import { useAppSelector } from "redux/hooks";
import usePermissions from "module/permission/usePermissions";
import selectOpenItemByEntityType from "module/staff/redux/selector/selectOpenItemByEntityType";
import { EntityType } from "module/entity/types";
import { isTrainingCustomStateScheduledOrLower } from "module/trainingCustom/helper/isTrainingCustomStateScheduledOrLower";
import {
  TrainingCustomInterface,
  TrainingCustomState,
} from "module/trainingCustom/types";

interface Result {
  options: TrainingCustomState[];
  isEditable: boolean;
  isStateScheduledOrLower: boolean;
}

const useTrainingCustomState = (
  trainingCustom: TrainingCustomInterface
): Result => {
  const { canCompleteTraining } = usePermissions();
  const openId = useAppSelector(
    selectOpenItemByEntityType(EntityType.trainingCustom)
  )?.id;

  const isStateScheduledOrLower =
    isTrainingCustomStateScheduledOrLower(trainingCustom);

  const options = useMemo(() => {
    const result = [];
    if (
      isStateScheduledOrLower ||
      (trainingCustom.state === TrainingCustomState.completed &&
        canCompleteTraining)
    ) {
      result.push(TrainingCustomState.scheduled);
    }
    if (
      (trainingCustom.state === TrainingCustomState.completed ||
        isStateScheduledOrLower) &&
      canCompleteTraining
    ) {
      result.push(TrainingCustomState.completed);
    }
    return result;
  }, [canCompleteTraining, isStateScheduledOrLower, trainingCustom.state]);

  const isEditable = trainingCustom.id === openId && options.length > 1;

  return {
    options,
    isEditable,
    isStateScheduledOrLower,
  };
};

export default useTrainingCustomState;
