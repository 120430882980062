import React, { useContext } from "react";
import t from "module/translations";
import MaintenanceTaskProcessStepContext from "module/maintenanceTaskProcessStep/context/MaintenanceTaskProcessStepContext";
import { MaintenanceTaskProcessStepInterface } from "module/maintenanceTaskProcessStep/types";
import XLabelField from "component/labelField/XLabelField";
import {
  getPathToMaintenanceTaskProcessStepDetails,
  getPathToMaintenanceTaskToBeSignedProcessStepDetails,
} from "module/maintenanceTask/helper/getPath";
import MaintenanceTaskContext from "module/maintenanceTask/context/MaintenanceTaskContext";
import { MaintenanceTaskInterface } from "module/maintenanceTask/types";
import Link from "component/link/Link";
import CameraAltIcon from "@mui/icons-material/CameraAlt";

const MaintenanceTaskProcessStepAttachmentsSummaryFieldContainer: React.FC =
  () => {
    const { entity: maintenanceTaskEntity } = useContext(
      MaintenanceTaskContext
    );
    const maintenanceTask = maintenanceTaskEntity as MaintenanceTaskInterface;

    const { entity: processStepEntity } = useContext(
      MaintenanceTaskProcessStepContext
    );
    const processStep =
      processStepEntity as MaintenanceTaskProcessStepInterface;

    const caption = t.maintenanceTask.processStep.attachmentsText;

    const label = processStep.attachments?.length
      ? `(${processStep.attachments.length})`
      : " ";

    const isSignMode = maintenanceTask.relationships != null;

    const path = isSignMode
      ? getPathToMaintenanceTaskToBeSignedProcessStepDetails({
          maintenanceTaskId: maintenanceTask.id,
          processStepId: processStep.id,
        })
      : getPathToMaintenanceTaskProcessStepDetails({
          maintenanceTaskId: maintenanceTask.id,
          processStepId: processStep.id,
        });

    return (
      <XLabelField {...{ caption }}>
        <Link
          {...{
            label,
            path,
            iconBefore: <CameraAltIcon />,
          }}
        />
      </XLabelField>
    );
  };

export default React.memo(
  MaintenanceTaskProcessStepAttachmentsSummaryFieldContainer
);
