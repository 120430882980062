import React from "react";
import TableCell from "@mui/material/TableCell";
import t from "module/translations";
import TableHeadRow from "component/table/tableHeadRow/TableHeadRow";
import TableHead from "@mui/material/TableHead";

const StockArticleListTableHead: React.FC = () => {
  return (
    <TableHead>
      <TableHeadRow>
        <TableCell align="left">{t.stockArticle.externalIdText}</TableCell>
        <TableCell align="left">{t.stockArticle.nameText}</TableCell>
        <TableCell align="left">{t.stockArticle.stockText}</TableCell>
        <TableCell align="left">{t.stockArticle.amountText}</TableCell>
      </TableHeadRow>
    </TableHead>
  );
};

export default StockArticleListTableHead;
