import React from "react";
import { EntityContextInterface } from "module/entity/context/entity/EntityContext";

export type InventoryCheckContextInterface = EntityContextInterface;

const InventoryCheckContext = React.createContext(
  <InventoryCheckContextInterface>{}
);

export default InventoryCheckContext;
