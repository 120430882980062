import React from "react";
import { Route } from "react-router-dom";
import FormContainsErrors from "component/form/FormContainsErrors";
import ButtonsContainer from "component/button/ButtonsContainer";
import OpenButton from "component/button/OpenButton";
import CloseButton from "component/button/CloseButton";
import TrainingStateEditableFieldContainer from "module/training/enrichedComponent/stateField/TrainingStateEditableFieldContainer";
import routes from "routes/routes";
import {
  getPathToTrainingDetailsOverview,
  getPathToTrainingDetailsSessions,
} from "module/training/helper/getPath";
import TrainingDetailsNavigation from "module/training/enrichedComponent/detailsNavigation/TrainingDetailsNavigation";

interface Props {
  trainingId: string;
  hasError: boolean;
  open?: () => void;
  close?: () => void;
  isStateEditable: boolean;
}

const MyTrainingDetails: React.FC<Props> = ({
  trainingId,
  hasError,
  open,
  close,
  isStateEditable,
}) => {
  return (
    <>
      {hasError && <FormContainsErrors />}
      <TrainingDetailsNavigation
        {...{
          listPath: routes.myCompetencyManagementResources.path,
          overviewPath: getPathToTrainingDetailsOverview(trainingId),
          sessionsPath: getPathToTrainingDetailsSessions(trainingId),
        }}
      />
      <Route
        {...{
          key: routes.myTrainingDetailsOverview.path,
          path: routes.myTrainingDetailsOverview.path,
          render: () => <routes.myTrainingDetailsOverview.mainComponent />,
        }}
      />
      <Route
        {...{
          key: routes.myTrainingDetailsSessions.path,
          path: routes.myTrainingDetailsSessions.path,
          render: () => <routes.myTrainingDetailsSessions.mainComponent />,
        }}
      />
      {(open || close) && (
        <ButtonsContainer>
          {open && <OpenButton {...{ onClick: open }} />}
          {close && <CloseButton {...{ onClick: close }} />}
          {isStateEditable && <TrainingStateEditableFieldContainer />}
        </ButtonsContainer>
      )}
    </>
  );
};

export default React.memo(MyTrainingDetails);
