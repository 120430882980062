import { createNestedPropertyIfNotExist } from "toolkit/createNestedPropertyIfNotExist";
import { MaintenanceTaskInterface } from "module/maintenanceTask/types";
import { XValidatorErrorsInterface } from "module/validation/types";
import { XRequiredValidator } from "module/validation/validator/requiredValidator";
import { isStateCompletedOrHigher } from "module/maintenanceTask/helper/isStateCompletedOrHigher";
import { MaintenanceTaskPersonnelAssignmentInternalStaffInterface } from "module/maintenanceTaskPersonnelAssignment/internalStaff/types";
import {
  MAINTENANCE_TASK_PERSONNEL_ASSIGNMENT_INTERNAL_STAFF_FIELD_NAME,
  MAINTENANCE_TASK_PERSONNEL_ASSIGNMENT_INTERNAL_STAFF_RESPONSIBILITY_FIELD_NAME,
} from "module/maintenanceTaskPersonnelAssignment/internalStaff/constants";

export interface Result {
  errors: null | XValidatorErrorsInterface;
  warnings: null | XValidatorErrorsInterface;
}

export const validateMaintenanceTaskPersonnelAssignmentInternalStaff = (
  maintenanceTask: MaintenanceTaskInterface,
  maintenanceTaskPersonnelAssignmentInternalStaff: MaintenanceTaskPersonnelAssignmentInternalStaffInterface
): Result => {
  let errors: XValidatorErrorsInterface = {};
  const warnings: XValidatorErrorsInterface = {};

  const isMaintenanceTaskStateCompletedOrHigher =
    isStateCompletedOrHigher(maintenanceTask);
  const isResponsibilityEmpty =
    maintenanceTaskPersonnelAssignmentInternalStaff.responsibility == null ||
    !maintenanceTaskPersonnelAssignmentInternalStaff.responsibility.length;

  if (isMaintenanceTaskStateCompletedOrHigher && isResponsibilityEmpty) {
    errors = createNestedPropertyIfNotExist(
      errors,
      MAINTENANCE_TASK_PERSONNEL_ASSIGNMENT_INTERNAL_STAFF_RESPONSIBILITY_FIELD_NAME,
      []
    );
    errors[
      MAINTENANCE_TASK_PERSONNEL_ASSIGNMENT_INTERNAL_STAFF_RESPONSIBILITY_FIELD_NAME
    ].push(new XRequiredValidator().message);
  }

  if (
    isMaintenanceTaskStateCompletedOrHigher &&
    maintenanceTaskPersonnelAssignmentInternalStaff.staffId == null
  ) {
    errors = createNestedPropertyIfNotExist(
      errors,
      MAINTENANCE_TASK_PERSONNEL_ASSIGNMENT_INTERNAL_STAFF_FIELD_NAME,
      []
    );
    errors[
      MAINTENANCE_TASK_PERSONNEL_ASSIGNMENT_INTERNAL_STAFF_FIELD_NAME
    ].push(new XRequiredValidator().message);
  }

  return {
    errors: Object.keys(errors).length ? errors : null,
    warnings: Object.keys(warnings).length ? warnings : null,
  };
};
