import { AssessmentState } from "module/assessment/types";
import t from "module/translations";

export const getAssessmentStateTranslation = (state: AssessmentState): string =>
  ({
    [AssessmentState.initScheduled]:
      t.competencyManagement.assessment.state.scheduledText,
    [AssessmentState.scheduled]:
      t.competencyManagement.assessment.state.scheduledText,
    [AssessmentState.completed]:
      t.competencyManagement.assessment.state.completedText,
  }[state]);
