import React, { useContext, useCallback } from "react";
import t from "module/translations";
import { MAINTENANCE_TASK_REMARKS_FIELD_NAME } from "module/maintenanceTask/constants";
import { getFieldMetaDataWithErrorHandling } from "module/fieldMetaData/getFieldMetaData";
import XLabelField from "component/labelField/XLabelField";
import MaintenanceTaskContext from "module/maintenanceTask/context/MaintenanceTaskContext";
import MaintenanceTaskChangeContext from "module/maintenanceTask/change/MaintenanceTaskChangeContext";
import MaintenanceTaskFieldMetaDataContext from "module/maintenanceTask/validation/fieldMetaData/MaintenanceTaskFieldMetaDataContext";
import { MaintenanceTaskInterface } from "module/maintenanceTask/types";
import XTextareaField from "component/textareaField/XTextareaField";

const MaintenanceTaskRemarksFieldContainer: React.FC = () => {
  const { entity } = useContext(MaintenanceTaskContext);
  const { change: changeHelper } = useContext(MaintenanceTaskChangeContext);
  const { getFieldMetaData } = useContext(MaintenanceTaskFieldMetaDataContext);

  const maintenanceTask = entity as MaintenanceTaskInterface;

  const change = useCallback(
    (remarks?: string) => {
      changeHelper({ remarks });
    },
    [changeHelper]
  );

  const { warnings, errors, isEditable } = getFieldMetaDataWithErrorHandling(
    () => getFieldMetaData(MAINTENANCE_TASK_REMARKS_FIELD_NAME)
  );

  const label = t.maintenanceTask.remarksText;

  return isEditable ? (
    <XTextareaField
      {...{
        value: maintenanceTask.remarks,
        change,
        label,
        errors,
      }}
    />
  ) : (
    <XLabelField
      {...{ caption: label, label: maintenanceTask.remarks, errors, warnings }}
    />
  );
};

export default React.memo(MaintenanceTaskRemarksFieldContainer);
