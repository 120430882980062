import React, { useContext, useState, useCallback, useMemo } from "react";
import DropdownButtonField from "component/dropdownButtonField/DropdownButtonField";
import t from "module/translations";
import ElementWithConfirmation from "component/elementWithConfirmation/ElementWithConfirmation";
import {
  TrainingCustomInterface,
  TrainingCustomState,
} from "module/trainingCustom/types";
import { getTrainingCustomStateTranslation } from "module/trainingCustom/helper/getTrainingCustomStateTranslation";
import TrainingCustomContext from "module/trainingCustom/context/TrainingCustomContext";
import TrainingCustomChangeContext from "module/trainingCustom/change/TrainingCustomChangeContext";
import TrainingCustomStateContext from "module/trainingCustom/state/context/TrainingCustomStateContext";

const TrainingCustomStateEditableFieldContainer: React.FC = () => {
  const { entity } = useContext(TrainingCustomContext);
  const { change } = useContext(TrainingCustomChangeContext);
  const { stateOptions: stateOptionsHelper } = useContext(
    TrainingCustomStateContext
  );

  const trainingCustom = entity as TrainingCustomInterface;
  const stateOptions = stateOptionsHelper as TrainingCustomState[];

  const [temporaryState, setTemporaryState] = useState<TrainingCustomState>(
    trainingCustom.state
  );

  const onChange = useCallback(async () => {
    await new Promise(() => {
      change({
        state: temporaryState,
      });
    });
  }, [change, temporaryState]);

  const onChangeHelper = useCallback(
    (newState: unknown, onClick: () => void) => {
      setTemporaryState(newState as TrainingCustomState);
      if (newState === TrainingCustomState.completed) {
        onClick();
      } else {
        change({
          state: newState as TrainingCustomState,
        });
      }
    },
    [change]
  );

  const options = useMemo(
    () =>
      stateOptions.map((state: TrainingCustomState) => ({
        value: state,
        text: getTrainingCustomStateTranslation(state),
      })),
    [stateOptions]
  );

  const value = trainingCustom.state;

  const confirmationDialogTitle =
    t.competencyManagement.completedConfirmationText;

  return (
    <ElementWithConfirmation {...{ confirmationDialogTitle, action: onChange }}>
      {(props: { onClick: () => void }) => (
        <DropdownButtonField
          {...{
            options,
            onChange: (newState) => {
              onChangeHelper(newState, props.onClick);
            },
            value,
          }}
        />
      )}
    </ElementWithConfirmation>
  );
};

export default React.memo(TrainingCustomStateEditableFieldContainer);
