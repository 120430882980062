import React, { useContext } from "react";
import LabelField from "component/labelField/LabelField";
import t from "module/translations";
import StaffNameText from "module/staff/StaffNameText";
import { TrainingCustomInterface } from "module/trainingCustom/types";
import TrainingCustomContext from "module/trainingCustom/context/TrainingCustomContext";
import Typography from "@mui/material/Typography";

const TrainingCustomTrainerFieldContainer: React.FC = () => {
  const { entity } = useContext(TrainingCustomContext);
  const { internalTrainerId, externalTrainer } =
    entity as TrainingCustomInterface;

  return (
    <LabelField
      {...{
        caption: t.competencyManagement.training.trainerText,
      }}
    >
      <Typography variant="body2" gutterBottom>
        {internalTrainerId && (
          <StaffNameText {...{ staffId: internalTrainerId }} />
        )}
        {externalTrainer && externalTrainer}
        {!internalTrainerId && !externalTrainer && "-"}
      </Typography>
    </LabelField>
  );
};

export default React.memo(TrainingCustomTrainerFieldContainer);
