import React, { useContext } from "react";
import MyAssessmentDetailsObjectives from "pages/myAssessmentDetails/objectives/MyAssessmentDetailsObjectives";
import AssessmentContext from "module/assessment/context/AssessmentContext";
import { AssessmentInterface } from "module/assessment/types";

const MyAssessmentDetailsObjectivesContainer: React.FC = () => {
  const { entity } = useContext(AssessmentContext);

  const assessment = entity as AssessmentInterface;

  return (
    <MyAssessmentDetailsObjectives
      {...{ objectivesIdentifiers: assessment.objectives }}
    />
  );
};

export default React.memo(MyAssessmentDetailsObjectivesContainer);
