import React from "react";
import { useAppSelector } from "redux/hooks";
import selectEntity from "module/staff/redux/selector/selectEntity";
import { EntityIdentifier, MyEntityInterface } from "module/entity/types";
import selectHasEntityOrRelatedEntityError from "module/staff/redux/selector/selectHasEntityOrRelatedEntityError";
import selectHasEntityOrRelatedEntityWarning from "module/staff/redux/selector/selectHasEntityOrRelatedEntityWarning";
import { EntityValidationResultContextInterface } from "module/entity/context/validationResult/EntityValidationResultContext";

interface Props {
  entityIdentifier: EntityIdentifier;
  context: React.Context<EntityValidationResultContextInterface>;
  children: React.ReactNode;
}

const MyEntityValidationResultContextProvider: React.FC<Props> = ({
  entityIdentifier,
  context,
  children,
}) => {
  const selectEntityHelper = useAppSelector(selectEntity);
  const myEntity = selectEntityHelper(entityIdentifier) as MyEntityInterface;
  const { validationErrors, validationWarnings } = myEntity;

  const hasError = useAppSelector(selectHasEntityOrRelatedEntityError)(
    entityIdentifier
  );

  const hasWarning = useAppSelector(selectHasEntityOrRelatedEntityWarning)(
    entityIdentifier
  );

  const contextValue: EntityValidationResultContextInterface = {
    validationErrors,
    hasError,
    validationWarnings,
    hasWarning,
  };

  return <context.Provider value={contextValue}>{children}</context.Provider>;
};

export default MyEntityValidationResultContextProvider;
