import React, { useCallback } from "react";
import { Theme } from "@mui/material/styles";
import createStyles from "@mui/styles/createStyles";
import makeStyles from "@mui/styles/makeStyles";
import Camera from "component/camera/Camera";
import FullPageDialog from "component/dialog/FullPageDialog";
import t from "module/translations";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CameraAltIcon from "@mui/icons-material/CameraAlt";
import Typography from "@mui/material/Typography";

export const BUTTON_SIZE = 192;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: `${BUTTON_SIZE}px`,
      height: `${BUTTON_SIZE}px`,
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      cursor: "pointer",
    },
    icon: {
      fontSize: BUTTON_SIZE / 2,
      color: theme.palette.primary.main,
      width: "100%",
    },
    text: {
      textAlign: "center",
    },
  })
);

interface Props {
  onSave: (imageData: string) => void;
}

const CameraButton: React.FC<Props> = ({ onSave }) => {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = useCallback(() => {
    setOpen(true);
  }, []);
  const handleClose = useCallback(() => {
    setOpen(false);
  }, []);

  const handleSave = useCallback(
    (imageData: string) => {
      setOpen(false);
      onSave(imageData);
    },
    [onSave]
  );

  return (
    <>
      <Card className={classes.root} onClick={handleClickOpen} elevation={3}>
        <CardContent>
          <CameraAltIcon className={classes.icon} />
          <div className={classes.text}>
            <Typography variant="caption" gutterBottom>
              {t.camera.buttonText}
            </Typography>
          </div>
        </CardContent>
      </Card>
      {open && (
        <FullPageDialog
          {...{
            title: t.camera.titleText,
            onClose: handleClose,
          }}
        >
          <Camera {...{ onSave: handleSave, onClose: handleClose }} />
        </FullPageDialog>
      )}
    </>
  );
};

export default React.memo(CameraButton);
