import React, { useContext } from "react";
import MyTrainingDetailsSessions from "pages/myTrainingDetails/sessions/MyTrainingDetailsSessions";
import TrainingContext from "module/training/context/TrainingContext";
import { TrainingInterface } from "module/training/types";

const MyTrainingDetailsSessionsContainer: React.FC = () => {
  const { entity } = useContext(TrainingContext);

  const training = entity as TrainingInterface;

  return (
    <MyTrainingDetailsSessions
      {...{ sessionsIdentifiers: training.sessions }}
    />
  );
};

export default React.memo(MyTrainingDetailsSessionsContainer);
