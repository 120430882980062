import React from "react";
import Grid from "@mui/material/Grid";
import StickyPaper from "component/sticky/StickyPaper";
import { MaintenanceTaskInterface } from "module/maintenanceTask/types";
import MaintenanceTaskRemarksFieldContainer from "module/maintenanceTask/enrichedComponent/remarksField/MaintenanceTaskRemarksFieldContainer";
import MaintenanceTaskNameFieldContainer from "module/maintenanceTask/enrichedComponent/nameField/MaintenanceTaskNameFieldContainer";
import MaintenanceTaskStateLabelFieldContainer from "module/maintenanceTask/enrichedComponent/stateField/MaintenanceTaskStateLabelFieldContainer";
import MaintenanceTaskAttachmentListContainer from "module/maintenanceTaskAttachment/enrichedComponent/list/MaintenanceTaskAttachmentListContainer";

interface Props {
  maintenanceTask: MaintenanceTaskInterface;
}

const MyNewCorrectiveMaintenanceTaskDetailsOverview: React.FC<Props> = () => {
  return (
    <StickyPaper>
      <Grid container spacing={3}>
        <Grid item xs={6}>
          <MaintenanceTaskNameFieldContainer />
        </Grid>
      </Grid>
      <MaintenanceTaskRemarksFieldContainer />
      <MaintenanceTaskStateLabelFieldContainer />
      <MaintenanceTaskAttachmentListContainer />
    </StickyPaper>
  );
};

export default React.memo(MyNewCorrectiveMaintenanceTaskDetailsOverview);
