import { XValidatorErrorsInterface } from "module/validation/types";
import { TrainingCustomInterface } from "module/trainingCustom/types";

export interface TrainingCustomValidationResult {
  errors: null | XValidatorErrorsInterface;
  warnings: null | XValidatorErrorsInterface;
}

export const validateTrainingCustom = (
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  trainingCustom: TrainingCustomInterface
): TrainingCustomValidationResult => {
  const errors: XValidatorErrorsInterface = {};
  const warnings: XValidatorErrorsInterface = {};

  return {
    errors: Object.keys(errors).length ? errors : null,
    warnings: Object.keys(warnings).length ? warnings : null,
  };
};
