import React, { useContext, useCallback, useMemo } from "react";
import { useHistory } from "react-router-dom";
import routes from "routes/routes";
import MaintenanceTaskSignContext from "module/maintenanceTask/sign/MaintenanceTaskSignContext";
import ElementWithConfirmation from "component/elementWithConfirmation/ElementWithConfirmation";
import t from "module/translations";
import SignButton from "component/button/SignButton";
import ButtonsContainer from "component/button/ButtonsContainer";
import { isStaffLeadOfMaintenanceTask } from "module/maintenanceTask/helper/isStaffLeadOfMaintenanceTask";
import { useAppSelector } from "redux/hooks";
import { selectUser } from "module/auth/redux/authSlice";
import MaintenanceTaskContext from "module/maintenanceTask/context/MaintenanceTaskContext";
import { MaintenanceTaskInterface } from "module/maintenanceTask/types";

const MaintenanceTaskToBeSignedDetailsSignContainer: React.FC = () => {
  const { sign: signHelper } = useContext(MaintenanceTaskSignContext);
  const { entity } = useContext(MaintenanceTaskContext);
  const maintenanceTask = entity as MaintenanceTaskInterface;
  const history = useHistory();
  const loggedInStaffId = useAppSelector(selectUser).id;

  const sign = useCallback(async (): Promise<void> => {
    await signHelper();
    history.push(routes.toBeSigned.path);
  }, [history, signHelper]);

  const confirmationDialogTitle = useMemo(
    () =>
      isStaffLeadOfMaintenanceTask({
        maintenanceTask,
        loggedInStaffId,
      })
        ? t.maintenanceTask.sign.signAsLeadConfirmationText
        : t.maintenanceTask.sign.signAsFinalVerificationConfirmationText,
    [loggedInStaffId, maintenanceTask]
  );

  return (
    <ButtonsContainer>
      <ElementWithConfirmation
        {...{
          confirmationDialogTitle,
          asyncAction: sign,
        }}
      >
        {(props: { onClick: () => void }) => (
          <SignButton {...{ onClick: props.onClick }} />
        )}
      </ElementWithConfirmation>
    </ButtonsContainer>
  );
};

export default React.memo(MaintenanceTaskToBeSignedDetailsSignContainer);
