import React, { useContext } from "react";
import { getFieldMetaData as getFieldMetaDataHelper } from "module/fieldMetaData/getFieldMetaData";
import TrainingOpenCloseContext from "module/training/openClose/context/TrainingOpenCloseContext";
import TrainingFieldMetaDataContext, {
  MyTrainingFieldMetaDataContextInterface,
} from "module/training/validation/fieldMetaData/TrainingFieldMetaDataContext";
import TrainingStateContext from "module/training/state/context/TrainingStateContext";
import TrainingValidationResultContext from "module/training/validation/TrainingValidationResultContext";

interface Prop {
  children: React.ReactNode;
}

const MyTrainingFieldMetaDataContextProvider: React.FC<Prop> = ({
  children,
}) => {
  const { validationWarnings, validationErrors } = useContext(
    TrainingValidationResultContext
  );
  const { isOpen } = useContext(TrainingOpenCloseContext);
  const { isStateScheduledOrLower } = useContext(TrainingStateContext);

  const getFieldMetaData = getFieldMetaDataHelper({
    validationWarnings,
    validationErrors,
    isOpen,
    isStateScheduledOrLower,
  });

  const contextValue: MyTrainingFieldMetaDataContextInterface = {
    getFieldMetaData,
  };

  return (
    <TrainingFieldMetaDataContext.Provider value={contextValue}>
      {children}
    </TrainingFieldMetaDataContext.Provider>
  );
};

export default MyTrainingFieldMetaDataContextProvider;
