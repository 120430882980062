import { useCallback } from "react";
import { EntityIdentifier } from "module/entity/types";
import { useAppSelector } from "redux/hooks";
import selectEntity from "module/staff/redux/selector/selectEntity";
import {
  MyTrainingInterface,
  MyTrainingSessionInterface,
  TrainingInterface,
  TrainingSessionInterface,
} from "module/training/types";
import { areTrainingsSimilar as areTrainingsSimilarHelper } from "module/training/similar/areTrainingsSimilar";

interface Result {
  areTrainingsSimilar: (
    entityIdentifier1: EntityIdentifier,
    entityIdentifier2: EntityIdentifier
  ) => boolean;
}

const useAreTrainingsSimilar = (): Result => {
  const selectEntityHelper = useAppSelector(selectEntity);

  const getTraining = useCallback(
    (entityIdentifier: EntityIdentifier): TrainingInterface => {
      return (selectEntityHelper(entityIdentifier) as MyTrainingInterface).data;
    },
    [selectEntityHelper]
  );

  const getTrainingSessions = useCallback(
    (
      sessionsEntityIdentifiers: EntityIdentifier[]
    ): TrainingSessionInterface[] => {
      return sessionsEntityIdentifiers.map((sessionsEntityIdentifier) => {
        return (
          selectEntityHelper(
            sessionsEntityIdentifier
          ) as MyTrainingSessionInterface
        ).data;
      });
    },
    [selectEntityHelper]
  );

  const areTrainingsSimilar = useCallback(
    (
      entityIdentifier1: EntityIdentifier,
      entityIdentifier2: EntityIdentifier
    ): boolean => {
      const training1Entity = getTraining(entityIdentifier1);
      const training1SessionsEntities = getTrainingSessions(
        training1Entity.sessions
      );
      const training2Entity = getTraining(entityIdentifier2);
      const training2SessionsEntities = getTrainingSessions(
        training2Entity.sessions
      );

      return areTrainingsSimilarHelper(
        { training: training1Entity, sessions: training1SessionsEntities },
        { training: training2Entity, sessions: training2SessionsEntities }
      );
    },
    [getTraining, getTrainingSessions]
  );

  return {
    areTrainingsSimilar,
  };
};

export default useAreTrainingsSimilar;
