import React, { useContext } from "react";
import TrainingContext from "module/training/context/TrainingContext";
import { TrainingInterface } from "module/training/types";
import TrainingAssigneeLabelField from "module/training/enrichedComponent/assigneeField/TrainingAssigneeLabelField";

const TrainingAssigneeContainer: React.FC = () => {
  const { entity } = useContext(TrainingContext);
  const { assigneeId } = entity as TrainingInterface;

  return <TrainingAssigneeLabelField {...{ assigneeId }} />;
};

export default React.memo(TrainingAssigneeContainer);
