import { MyEntityInterface } from "module/entity/types";

const getMyEntityWithoutRelationships = (
  myEntity: MyEntityInterface
): MyEntityInterface => {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  return {
    ...myEntity,
    data: {
      ...myEntity.data,
      relationships: undefined,
    },
    dataWithoutChanges: {
      ...myEntity.dataWithoutChanges,
      relationships: undefined,
    },
  };
};

export const transformEntityWithRelationshipsToEntityArray = (
  myEntity: MyEntityInterface
): MyEntityInterface[] => {
  let result: MyEntityInterface[] = [];

  result.push(getMyEntityWithoutRelationships(myEntity));

  if (myEntity.data.relationships != null) {
    // eslint-disable-next-line array-callback-return
    Array.from(myEntity.data.relationships.values()).map((nestedEntity) => {
      result = [
        ...result,
        ...transformEntityWithRelationshipsToEntityArray(nestedEntity),
      ];
    });
  }

  return result;
};
