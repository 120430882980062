import { useContext, useCallback, useMemo } from "react";
import { useAppDispatch, useAppSelector } from "redux/hooks";
import { selectSystem, selectUser } from "module/auth/redux/authSlice";
import { canRemoveMaintenanceTaskPart } from "module/maintenanceTaskPart/helper/canRemoveMaintenanceTaskPart";
import MaintenanceTaskContext from "module/maintenanceTask/context/MaintenanceTaskContext";
import { MaintenanceTaskInterface } from "module/maintenanceTask/types";
import MaintenanceTaskOpenCloseContext from "module/maintenanceTask/openClose/context/MaintenanceTaskOpenCloseContext";
import { EntityIdentifier, EntityType } from "module/entity/types";
import {
  removeFromEntityPropertyList,
  removeFromMyEntities,
} from "module/staff/redux/staffDataSlice";

interface Result {
  canRemove: boolean;
  remove: () => void;
}

const useMaintenanceTaskPartRemove = (
  entityIdentifier: EntityIdentifier
): Result => {
  const { entity } = useContext(MaintenanceTaskContext);
  const maintenanceTask = entity as MaintenanceTaskInterface;

  const { isOpen } = useContext(MaintenanceTaskOpenCloseContext);

  const dispatch = useAppDispatch();
  const staffId = useAppSelector(selectUser).id;
  const systemShortName = useAppSelector(selectSystem).shortName;

  const canRemove = useMemo(
    () =>
      canRemoveMaintenanceTaskPart({
        isMaintenanceTaskOpen: isOpen,
        maintenanceTask,
      }),
    [isOpen, maintenanceTask]
  );

  const remove = useCallback(() => {
    const maintenanceTaskEntityIdentifier = {
      id: maintenanceTask.id,
      type: EntityType.maintenanceTask,
    };

    dispatch(
      removeFromEntityPropertyList({
        staffId,
        systemShortName,
        baseEntityIdentifier: maintenanceTaskEntityIdentifier,
        propertyName: "parts",
        entityIdentifierToRemove: entityIdentifier,
      })
    );

    dispatch(
      removeFromMyEntities({
        staffId,
        systemShortName,
        entityIdentifiers: [entityIdentifier],
      })
    );
  }, [
    dispatch,
    entityIdentifier,
    maintenanceTask.id,
    staffId,
    systemShortName,
  ]);

  return {
    canRemove,
    remove,
  };
};

export default useMaintenanceTaskPartRemove;
