import t from "module/translations";
import { InventoryCheckArticleHasChanged } from "module/inventoryCheckArticle/types";

export const getInventoryCheckArticleHasChangedTranslation = (
  hasChanged: InventoryCheckArticleHasChanged
): string =>
  ({
    [InventoryCheckArticleHasChanged.notSet]:
      t.inventoryCheck.article.hasChanged.notSetText,
    [InventoryCheckArticleHasChanged.yes]:
      t.inventoryCheck.article.hasChanged.yesText,
    [InventoryCheckArticleHasChanged.no]:
      t.inventoryCheck.article.hasChanged.noText,
  }[hasChanged]);

export const getInventoryCheckArticleAmountCorrectTranslation = (
  hasChanged: InventoryCheckArticleHasChanged
): string =>
  ({
    [InventoryCheckArticleHasChanged.notSet]:
      t.inventoryCheck.article.amountCorrect.notSetText,
    [InventoryCheckArticleHasChanged.yes]:
      t.inventoryCheck.article.amountCorrect.noText,
    [InventoryCheckArticleHasChanged.no]:
      t.inventoryCheck.article.amountCorrect.yesText,
  }[hasChanged]);
