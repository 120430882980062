import React from "react";
import Grid from "@mui/material/Grid";
import StickyPaper from "component/sticky/StickyPaper";
import InventoryCheckReportingNumberFieldContainer from "module/inventoryCheck/enrichedComponent/reportingNumberField/InventoryCheckReportingNumberFieldContainer";
import InventoryCheckRemarksFieldContainer from "module/inventoryCheck/enrichedComponent/remarksField/InventoryCheckRemarksFieldContainer";
import InventoryCheckStateLabelFieldContainer from "module/inventoryCheck/enrichedComponent/stateField/InventoryCheckStateLabelFieldContainer";
import InventoryCheckAssigneeFieldContainer from "module/inventoryCheck/enrichedComponent/assigneeField/InventoryCheckAssigneeFieldContainer";
import InventoryCheckSubjectFieldContainer from "module/inventoryCheck/enrichedComponent/subjectField/InventoryCheckSubjectFieldContainer";

interface Props {
  isStateEditable: boolean;
}

const MyInventoryCheckDetailsOverview: React.FC<Props> = ({
  isStateEditable,
}) => {
  return (
    <StickyPaper>
      <Grid container spacing={3}>
        <Grid item xs={6}>
          <InventoryCheckReportingNumberFieldContainer />
        </Grid>
      </Grid>
      <Grid container spacing={3}>
        <Grid item xs={6}>
          <InventoryCheckSubjectFieldContainer />
        </Grid>
        <Grid item xs={6}>
          <InventoryCheckAssigneeFieldContainer />
        </Grid>
      </Grid>
      <InventoryCheckRemarksFieldContainer />
      {!isStateEditable && <InventoryCheckStateLabelFieldContainer />}
    </StickyPaper>
  );
};

export default React.memo(MyInventoryCheckDetailsOverview);
