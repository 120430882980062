import React, { useCallback } from "react";
import { EntityIdentifier } from "module/entity/types";
import useSign from "module/sign/useSign";
import { EntitySignContextInterface } from "module/entity/context/sign/EntitySignContext";

interface Props {
  entityIdentifier: EntityIdentifier;
  context: React.Context<EntitySignContextInterface>;
  children: React.ReactNode;
}

const EntitySignContextProvider: React.FC<Props> = ({
  entityIdentifier,
  context,
  children,
}) => {
  const { signOne } = useSign();

  const sign = useCallback(async (): Promise<void> => {
    await signOne(entityIdentifier);
  }, [entityIdentifier, signOne]);

  const contextValue: EntitySignContextInterface = {
    sign,
  };

  return <context.Provider value={contextValue}>{children}</context.Provider>;
};

export default EntitySignContextProvider;
