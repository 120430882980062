import React, { useContext } from "react";
import useDateFormat from "toolkit/useDateFormat";
import MaintenanceTaskContext from "module/maintenanceTask/context/MaintenanceTaskContext";
import { MaintenanceTaskInterface } from "module/maintenanceTask/types";
import XLabelField from "component/labelField/XLabelField";
import t from "module/translations";

const MaintenanceTaskCompletionEndDateFieldContainer: React.FC = () => {
  const { entity } = useContext(MaintenanceTaskContext);
  const { completionEndDate } = entity as MaintenanceTaskInterface;

  const { formatDateTime } = useDateFormat();

  return (
    <XLabelField
      {...{
        caption: t.maintenanceTask.completionEndText,
        label: completionEndDate
          ? formatDateTime(completionEndDate)
          : undefined,
      }}
    />
  );
};

export default React.memo(MaintenanceTaskCompletionEndDateFieldContainer);
