import React from "react";
import Button from "@mui/material/Button";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Dialog from "@mui/material/Dialog";
import t from "module/translations";

interface Props {
  onConfirm: () => void;
  onCancel: () => void;
  isOpen: boolean;
  title: string;
  children?: React.ReactNode;
}

const ConfirmDialog: React.FC<Props> = ({
  onConfirm,
  onCancel,
  children,
  isOpen,
  title,
}) => {
  return (
    <Dialog disableEscapeKeyDown maxWidth="md" open={isOpen} keepMounted>
      <DialogTitle id="confirmation-dialog-title">{title}</DialogTitle>
      {children && <DialogContent dividers>{children}</DialogContent>}
      <DialogActions>
        <Button autoFocus onClick={onCancel} color="primary">
          {t.dialog.confirm.noText}
        </Button>
        <Button onClick={onConfirm} color="primary">
          {t.dialog.confirm.yesText}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default React.memo(ConfirmDialog);
