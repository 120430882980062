import t from "module/translations";
import { MaintenanceTaskState } from "module/maintenanceTask/types";

export const getMaintenanceTaskStateTranslation = (
  state: MaintenanceTaskState
): string =>
  ({
    [MaintenanceTaskState.initScheduled]: t.maintenanceTask.state.scheduled,
    [MaintenanceTaskState.requested]: t.maintenanceTask.state.requested,
    [MaintenanceTaskState.scheduled]: t.maintenanceTask.state.scheduled,
    [MaintenanceTaskState.completed]: t.maintenanceTask.state.completed,
    [MaintenanceTaskState.validated]: t.maintenanceTask.state.validated,
  }[state]);
