import {
  ApiAssessmentInterface,
  ApiAssessmentObjectiveInterface,
  ApiCompetencyManagementInterface,
  ApiTrainingCustomAssigneeInterface,
  ApiTrainingCustomInterface,
  ApiTrainingInterface,
  ApiTrainingSessionInterface,
} from "api/competencyManagement/types";
import {
  MyTrainingInterface,
  MyTrainingSessionInterface,
  TrainingInterface,
  TrainingSessionInterface,
} from "module/training/types";
import { EntityIdentifier, EntityType } from "module/entity/types";
import {
  AssessmentInterface,
  AssessmentObjectiveInterface,
  MyAssessmentInterface,
  MyAssessmentObjectiveInterface,
} from "module/assessment/types";
import { MyCompetencyManagementInterface } from "module/competencyManagement/types";
import {
  MyTrainingCustomAssigneeInterface,
  MyTrainingCustomInterface,
  TrainingCustomAssigneeInterface,
  TrainingCustomInterface,
} from "module/trainingCustom/types";
import moment from "moment";

const mapTrainingSession = (
  rawTraining: ApiTrainingInterface,
  rawSession: ApiTrainingSessionInterface
): MyTrainingSessionInterface => {
  const data: TrainingSessionInterface = {
    ...rawSession,
    id: `${rawSession.id}`,
    backendId: rawSession.id,
    remarks: rawSession.remarks == null ? "" : rawSession.remarks,
    relationships: new Map(),
    relationshipIdentifiers: [],
    trainingId: `${rawTraining.id}`,
    // backendTrainingId: rawTraining.id,
  };

  return {
    entityIdentifier: {
      id: `${rawSession.id}`,
      backendId: rawSession.id,
      type: EntityType.trainingSession,
    },
    data,
    dataWithoutChanges: data,
    raw: rawSession,
    validationErrors: null,
    validationWarnings: null,
  };
};

const mapTrainingSessions = (
  rawTraining: ApiTrainingInterface
): Map<EntityIdentifier, MyTrainingSessionInterface> => {
  const result = new Map<EntityIdentifier, MyTrainingSessionInterface>();

  // eslint-disable-next-line array-callback-return
  rawTraining.sessions.map((rawSession) => {
    result.set(
      { id: `${rawSession.id}`, type: EntityType.trainingSession },
      mapTrainingSession(rawTraining, rawSession)
    );
  });

  return result;
};

export const mapTraining = (raw: ApiTrainingInterface): MyTrainingInterface => {
  const sessions = mapTrainingSessions(raw);

  const data: TrainingInterface = {
    ...raw,
    id: `${raw.id}`,
    backendId: raw.id,
    remarks: raw.remarks == null ? "" : raw.remarks,
    sessions: Array.from(sessions.keys()),
    relationships: sessions,
    relationshipIdentifiers: Array.from(sessions.keys()),
  };

  return {
    entityIdentifier: {
      type: raw.entityType,
      id: `${raw.id}`,
      backendId: raw.id,
    },
    data,
    dataWithoutChanges: data,
    raw,
    validationErrors: null,
    validationWarnings: null,
  };
};
const mapAssessmentObjective = (
  rawAssessment: ApiAssessmentInterface,
  rawObjective: ApiAssessmentObjectiveInterface
): MyAssessmentObjectiveInterface => {
  const data: AssessmentObjectiveInterface = {
    ...rawObjective,
    id: `${rawObjective.id}`,
    backendId: rawObjective.id,
    assessmentId: `${rawAssessment.id}`,
    remarks: rawObjective.remarks == null ? "" : rawObjective.remarks,
    // backendAssessmentId: rawAssessment.id,
    relationships: new Map(),
    relationshipIdentifiers: [],
  };

  return {
    entityIdentifier: {
      id: `${rawObjective.id}`,
      backendId: rawObjective.id,
      type: EntityType.assessmentObjective,
    },
    data,
    dataWithoutChanges: data,
    raw: rawObjective,
    validationErrors: null,
    validationWarnings: null,
  };
};

const mapAssessmentObjectives = (
  raw: ApiAssessmentInterface
): Map<EntityIdentifier, MyAssessmentObjectiveInterface> => {
  const result = new Map<EntityIdentifier, MyAssessmentObjectiveInterface>();

  // eslint-disable-next-line array-callback-return
  raw.objectives.map((rawObjective) => {
    result.set(
      { id: `${rawObjective.id}`, type: EntityType.assessmentObjective },
      mapAssessmentObjective(raw, rawObjective)
    );
  });

  return result;
};

export const mapAssessment = (
  raw: ApiAssessmentInterface
): MyAssessmentInterface => {
  const objectives = mapAssessmentObjectives(raw);

  const data: AssessmentInterface = {
    ...raw,
    id: `${raw.id}`,
    backendId: raw.id,
    remarks: raw.remarks === null ? "" : raw.remarks,
    objectives: Array.from(objectives.keys()),
    relationships: objectives,
    relationshipIdentifiers: Array.from(objectives.keys()),
  };

  return {
    entityIdentifier: {
      type: raw.entityType,
      id: `${raw.id}`,
      backendId: raw.id,
    },
    data,
    dataWithoutChanges: data,
    raw,
    validationErrors: null,
    validationWarnings: null,
  };
};

const mapTrainingCustomAssignee = (
  rawTrainingCustom: ApiTrainingCustomInterface,
  rawAssignee: ApiTrainingCustomAssigneeInterface
): MyTrainingCustomAssigneeInterface => {
  const data: TrainingCustomAssigneeInterface = {
    ...rawAssignee,
    id: `${rawAssignee.id}`,
    backendId: rawAssignee.id,
    trainingCustomId: `${rawTrainingCustom.id}`,
    achieved: rawAssignee.completionDate != null,
    remarks: rawAssignee.remarks ?? "",
    relationships: new Map(),
    relationshipIdentifiers: [],
  };

  return {
    entityIdentifier: {
      id: `${rawAssignee.id}`,
      backendId: rawAssignee.id,
      type: EntityType.trainingCustomAssignee,
    },
    data,
    dataWithoutChanges: data,
    raw: rawAssignee,
    validationErrors: null,
    validationWarnings: null,
  };
};

const mapTrainingCustomAssignees = (
  raw: ApiTrainingCustomInterface
): Map<EntityIdentifier, MyTrainingCustomAssigneeInterface> => {
  const result = new Map<EntityIdentifier, MyTrainingCustomAssigneeInterface>();

  // eslint-disable-next-line array-callback-return
  raw.assignees.map((rawAssignee) => {
    result.set(
      { id: `${rawAssignee.id}`, type: EntityType.trainingCustomAssignee },
      mapTrainingCustomAssignee(raw, rawAssignee)
    );
  });

  return result;
};

export const mapTrainingCustom = (
  raw: ApiTrainingCustomInterface
): MyTrainingCustomInterface => {
  const assignees = mapTrainingCustomAssignees(raw);

  const data: TrainingCustomInterface = {
    ...raw,
    id: `${raw.id}`,
    backendId: raw.id,
    remarks: raw.remarks === null ? "" : raw.remarks,
    scheduledStartDate: moment(raw.scheduledDate).format("YYYY-MM-DD H:m:s"),
    assignees: Array.from(assignees.keys()),
    relationships: assignees,
    relationshipIdentifiers: Array.from(assignees.keys()),
  };

  return {
    entityIdentifier: {
      type: raw.entityType,
      id: `${raw.id}`,
      backendId: raw.id,
    },
    data,
    dataWithoutChanges: data,
    raw,
    validationErrors: null,
    validationWarnings: null,
  };
};

const mapCompetencyManagement = (
  raw: ApiCompetencyManagementInterface
): MyCompetencyManagementInterface => {
  const { entityType } = raw;
  if (entityType === EntityType.training) {
    return mapTraining(raw as ApiTrainingInterface);
  }
  if (entityType === EntityType.assessment) {
    return mapAssessment(raw as ApiAssessmentInterface);
  }
  if (entityType === EntityType.trainingCustom) {
    return mapTrainingCustom(raw as ApiTrainingCustomInterface);
  }
  throw Error("Competency Management type is not supported");
};

interface ApiGetCompetencyManagementsResponse {
  items: ApiCompetencyManagementInterface[];
  count: number;
}

export interface GetCompetencyManagementsResponse {
  data: MyCompetencyManagementInterface[];
  count: number;
}

export const mapApiResponse = (
  rawData: ApiGetCompetencyManagementsResponse
): GetCompetencyManagementsResponse => {
  return {
    data: rawData.items.map(mapCompetencyManagement),
    count: rawData.count,
  };
};
