import { XFieldMetaDataInterface } from "module/fieldMetaData/types";
import { XValidatorErrorsInterface } from "module/validation/types";

export interface FieldMetaDataProps {
  validationErrors: XValidatorErrorsInterface | null;
  validationWarnings: XValidatorErrorsInterface | null;
  isOpen: boolean;
  isStateScheduledOrLower: boolean;
  isNew?: boolean;
}

const getValidationForField =
  (validationData: XValidatorErrorsInterface | null) =>
  (fieldName: string): string[] => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    return validationData?.[fieldName] || [];
  };

const isFieldEditable =
  ({
    validationErrors,
    validationWarnings,
    isOpen,
    isStateScheduledOrLower,
    isNew,
  }: FieldMetaDataProps) =>
  (fieldName: string): boolean => {
    const hasErrors = Boolean(
      getValidationForField(validationErrors)(fieldName).length
    );
    const hasWarnings = Boolean(
      getValidationForField(validationWarnings)(fieldName).length
    );
    return (
      isNew || (isOpen && (isStateScheduledOrLower || hasErrors || hasWarnings))
    );
  };

export const getFieldMetaData =
  ({
    validationErrors,
    validationWarnings,
    isOpen,
    isStateScheduledOrLower,
    isNew = false,
  }: FieldMetaDataProps) =>
  (fieldName: string): XFieldMetaDataInterface => {
    return {
      warnings: getValidationForField(validationWarnings)(fieldName),
      errors: getValidationForField(validationErrors)(fieldName),
      isEditable: isFieldEditable({
        validationErrors,
        validationWarnings,
        isOpen,
        isStateScheduledOrLower,
        isNew,
      })(fieldName),
    };
  };

export const getFieldMetaDataWithErrorHandling = (
  func: () => XFieldMetaDataInterface
): XFieldMetaDataInterface => {
  try {
    return func();
  } catch (e) {
    return { errors: [], warnings: [], isEditable: false };
  }
};
