import React from "react";
import makeStyles from "@mui/styles/makeStyles";
import MaintenanceTaskProcessStepQuickFixTypeFieldContainer from "module/maintenanceTaskProcessStepQuickFix/enrichedComponent/typeField/MaintenanceTaskProcessStepQuickFixTypeFieldContainer";
import MaintenanceTaskProcessStepQuickFixRemoveButtonContainer from "module/maintenanceTaskProcessStepQuickFix/enrichedComponent/removeButton/MaintenanceTaskProcessStepQuickFixRemoveButtonContainer";

const useStyles = makeStyles({
  row: {
    display: "flex",
    flexGrow: 1,
  },
  typeField: {
    flexGrow: 1,
  },
});

const MyMaintenanceTaskProcessStepQuickFix: React.FC = () => {
  const classes = useStyles();
  return (
    <div className={classes.row}>
      <div className={classes.typeField}>
        <MaintenanceTaskProcessStepQuickFixTypeFieldContainer />
      </div>
      <MaintenanceTaskProcessStepQuickFixRemoveButtonContainer />
    </div>
  );
};

export default React.memo(MyMaintenanceTaskProcessStepQuickFix);
