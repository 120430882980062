import React, { useContext, useMemo } from "react";
import MaintenanceTaskContext from "module/maintenanceTask/context/MaintenanceTaskContext";
import { MaintenanceTaskInterface } from "module/maintenanceTask/types";
import XLabelField from "component/labelField/XLabelField";
import t from "module/translations";
import { useAppSelector } from "redux/hooks";
import { selectArticles } from "module/auth/redux/authSlice";

const MaintenanceTaskSerializedArticleFieldContainer: React.FC = () => {
  const { entity } = useContext(MaintenanceTaskContext);
  const { definition } = entity as MaintenanceTaskInterface;

  const articles = useAppSelector(selectArticles);
  const article = useMemo(
    () =>
      articles == null || definition?.serializedArticleId == null
        ? undefined
        : articles[definition.serializedArticleId],
    [articles, definition]
  );

  const label = article ? article.text : undefined;
  return (
    <XLabelField
      {...{
        caption: t.maintenanceTask.serializedArticleText,
        label,
      }}
    />
  );
};

export default React.memo(MaintenanceTaskSerializedArticleFieldContainer);
