import React from "react";
import { Route } from "react-router-dom";
import routes from "routes/routes";
import TrainingCustomDetailsNavigation from "module/trainingCustom/enrichedComponent/detailsNavigation/TrainingCustomDetailsNavigation";
import {
  getPathToTrainingCustomToBeSignedDetailsAssignees,
  getPathToTrainingCustomToBeSignedDetailsOverview,
} from "module/trainingCustom/helper/getPath";

interface Props {
  trainingCustomId: string;
}

const TrainingCustomToBeSignedDetails: React.FC<Props> = ({
  trainingCustomId,
}) => {
  return (
    <>
      <TrainingCustomDetailsNavigation
        {...{
          listPath: routes.toBeSigned.path,
          overviewPath:
            getPathToTrainingCustomToBeSignedDetailsOverview(trainingCustomId),
          assigneesPath:
            getPathToTrainingCustomToBeSignedDetailsAssignees(trainingCustomId),
        }}
      />
      <Route
        {...{
          key: routes.trainingCustomToBeSignedDetailsOverview.path,
          path: routes.trainingCustomToBeSignedDetailsOverview.path,
          render: () => (
            <routes.trainingCustomToBeSignedDetailsOverview.mainComponent />
          ),
        }}
      />
      <Route
        {...{
          key: routes.trainingCustomToBeSignedDetailsAssignees.path,
          path: routes.trainingCustomToBeSignedDetailsAssignees.path,
          render: () => (
            <routes.trainingCustomToBeSignedDetailsAssignees.mainComponent />
          ),
        }}
      />
    </>
  );
};

export default React.memo(TrainingCustomToBeSignedDetails);
