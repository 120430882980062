import React, { useContext, useCallback } from "react";
import DatePickerField from "component/datePickerField/DatePickerField";
import t from "module/translations";
import FilterContext from "module/filter/context/FilterContext";

const CompetencyManagementListToolbarFilterScheduledDateContainer: React.FC =
  () => {
    const { filters, changeFilters } = useContext(FilterContext);
    const change = useCallback(
      (newScheduledDate: Date | null) => {
        changeFilters({
          ...filters,
          scheduledDate: newScheduledDate ? new Date(newScheduledDate) : null,
        });
      },
      [changeFilters, filters]
    );

    return (
      <DatePickerField
        {...{
          label: t.competencyManagement.scheduledDateText,
          change,
          value: filters.scheduledDate as Date,
        }}
      />
    );
  };

export default React.memo(
  CompetencyManagementListToolbarFilterScheduledDateContainer
);
