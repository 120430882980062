import { createNestedPropertyIfNotExist } from "toolkit/createNestedPropertyIfNotExist";
import { MaintenanceTaskInterface } from "module/maintenanceTask/types";
import { XValidatorErrorsInterface } from "module/validation/types";
import { XRequiredValidator } from "module/validation/validator/requiredValidator";
import { isStateCompletedOrHigher } from "module/maintenanceTask/helper/isStateCompletedOrHigher";
import { MaintenanceTaskPersonnelAssignmentExternalStaffInterface } from "module/maintenanceTaskPersonnelAssignment/externalStaff/types";
import {
  MAINTENANCE_TASK_PERSONNEL_ASSIGNMENT_EXTERNAL_STAFF_COMPANY_FIELD_NAME,
  MAINTENANCE_TASK_PERSONNEL_ASSIGNMENT_EXTERNAL_STAFF_NAME_FIELD_NAME,
  MAINTENANCE_TASK_PERSONNEL_ASSIGNMENT_EXTERNAL_STAFF_RESPONSIBILITY_FIELD_NAME,
} from "module/maintenanceTaskPersonnelAssignment/externalStaff/constants";

export interface Result {
  errors: null | XValidatorErrorsInterface;
  warnings: null | XValidatorErrorsInterface;
}

export const validateMaintenanceTaskPersonnelAssignmentExternalStaff = (
  maintenanceTask: MaintenanceTaskInterface,
  maintenanceTaskPersonnelAssignmentExternalStaff: MaintenanceTaskPersonnelAssignmentExternalStaffInterface
): Result => {
  let errors: XValidatorErrorsInterface = {};
  const warnings: XValidatorErrorsInterface = {};

  const isMaintenanceTaskStateCompletedOrHigher =
    isStateCompletedOrHigher(maintenanceTask);
  const isResponsibilityEmpty =
    maintenanceTaskPersonnelAssignmentExternalStaff.responsibility == null ||
    !maintenanceTaskPersonnelAssignmentExternalStaff.responsibility.length;

  if (isMaintenanceTaskStateCompletedOrHigher && isResponsibilityEmpty) {
    errors = createNestedPropertyIfNotExist(
      errors,
      MAINTENANCE_TASK_PERSONNEL_ASSIGNMENT_EXTERNAL_STAFF_RESPONSIBILITY_FIELD_NAME,
      []
    );
    errors[
      MAINTENANCE_TASK_PERSONNEL_ASSIGNMENT_EXTERNAL_STAFF_RESPONSIBILITY_FIELD_NAME
    ].push(new XRequiredValidator().message);
  }

  const isNameEmpty =
    maintenanceTaskPersonnelAssignmentExternalStaff.name == null ||
    !maintenanceTaskPersonnelAssignmentExternalStaff.name.length;
  const isCompanyEmpty =
    maintenanceTaskPersonnelAssignmentExternalStaff.company == null ||
    !maintenanceTaskPersonnelAssignmentExternalStaff.company.length;

  if (isMaintenanceTaskStateCompletedOrHigher && isNameEmpty) {
    errors = createNestedPropertyIfNotExist(
      errors,
      MAINTENANCE_TASK_PERSONNEL_ASSIGNMENT_EXTERNAL_STAFF_NAME_FIELD_NAME,
      []
    );
    errors[
      MAINTENANCE_TASK_PERSONNEL_ASSIGNMENT_EXTERNAL_STAFF_NAME_FIELD_NAME
    ].push(new XRequiredValidator().message);
  }

  if (isMaintenanceTaskStateCompletedOrHigher && isCompanyEmpty) {
    errors = createNestedPropertyIfNotExist(
      errors,
      MAINTENANCE_TASK_PERSONNEL_ASSIGNMENT_EXTERNAL_STAFF_COMPANY_FIELD_NAME,
      []
    );
    errors[
      MAINTENANCE_TASK_PERSONNEL_ASSIGNMENT_EXTERNAL_STAFF_COMPANY_FIELD_NAME
    ].push(new XRequiredValidator().message);
  }

  return {
    errors: Object.keys(errors).length ? errors : null,
    warnings: Object.keys(warnings).length ? warnings : null,
  };
};
