import React, { useContext, useCallback, useMemo } from "react";
import DropdownButtonField from "component/dropdownButtonField/DropdownButtonField";
import MaintenanceTaskContext from "module/maintenanceTask/context/MaintenanceTaskContext";
import MaintenanceTaskChangeContext from "module/maintenanceTask/change/MaintenanceTaskChangeContext";
import MaintenanceTaskStateContext from "module/maintenanceTask/state/context/MaintenanceTaskStateContext";
import {
  MaintenanceTaskInterface,
  MaintenanceTaskState,
} from "module/maintenanceTask/types";
import { getMaintenanceTaskStateTranslation } from "module/maintenanceTask/helper/getMaintenanceTaskStateTranslation";

const MaintenanceTaskStateEditableFieldContainer: React.FC = () => {
  const { entity } = useContext(MaintenanceTaskContext);
  const { change } = useContext(MaintenanceTaskChangeContext);
  const { stateOptions: stateOptionsHelper } = useContext(
    MaintenanceTaskStateContext
  );

  const maintenanceTask = entity as MaintenanceTaskInterface;
  const stateOptions = stateOptionsHelper as MaintenanceTaskState[];

  const onChange = useCallback(
    (newState: unknown) => {
      change({
        state: newState as MaintenanceTaskState,
      });
    },
    [change]
  );

  const options = useMemo(
    () =>
      stateOptions.map((state: MaintenanceTaskState) => ({
        value: state,
        text: getMaintenanceTaskStateTranslation(state),
      })),
    [stateOptions]
  );

  const value = maintenanceTask.state;

  return (
    <DropdownButtonField
      {...{
        options,
        onChange,
        value,
      }}
    />
  );
};

export default React.memo(MaintenanceTaskStateEditableFieldContainer);
