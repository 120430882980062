import React, { useContext, useCallback } from "react";
import { TRAINING_SESSION_REMARKS_FIELD_NAME } from "module/trainingSession/constants";
import TextareaField from "component/textareaField/TextareaField";
import XLabelField from "component/labelField/XLabelField";
import { TrainingSessionInterface } from "module/training/types";
import TrainingSessionContext from "module/trainingSession/context/TrainingSessionContext";
import TrainingSessionChangeContext from "module/trainingSession/change/TrainingSessionChangeContext";
import TrainingSessionFieldMetaDataContext from "module/trainingSession/validation/fieldMetaData/TrainingSessionFieldMetaDataContext";
import { getFieldMetaDataWithErrorHandling } from "module/fieldMetaData/getFieldMetaData";

const TrainingSessionRemarksFieldContainer: React.FC = () => {
  const { entity } = useContext(TrainingSessionContext);
  const { change: changeHelper } = useContext(TrainingSessionChangeContext);
  const { getFieldMetaData } = useContext(TrainingSessionFieldMetaDataContext);

  const trainingSessions = entity as TrainingSessionInterface;

  const change = useCallback(
    (remarks?: string) => {
      changeHelper({ remarks });
    },
    [changeHelper]
  );

  const { warnings, errors, isEditable } = getFieldMetaDataWithErrorHandling(
    () => getFieldMetaData(TRAINING_SESSION_REMARKS_FIELD_NAME)
  );

  return isEditable ? (
    <TextareaField
      {...{
        value: trainingSessions.remarks,
        change,
        errors,
      }}
    />
  ) : (
    <XLabelField {...{ label: trainingSessions.remarks, errors, warnings }} />
  );
};

export default React.memo(TrainingSessionRemarksFieldContainer);
