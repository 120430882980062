import React, { useContext } from "react";
import TrainingContext from "module/training/context/TrainingContext";
import { TrainingInterface } from "module/training/types";
import useDateFormat from "toolkit/useDateFormat";
import TrainingCompletionEndDateTimeLabelField from "module/training/enrichedComponent/completionEndDateTimeField/TrainingCompletionEndDateTimeLabelField";

const TrainingCompletionEndDateTimeFieldContainer: React.FC = () => {
  const { entity } = useContext(TrainingContext);
  const { formatDateTime } = useDateFormat();

  const { completionEndDate } = entity as TrainingInterface;

  return (
    <TrainingCompletionEndDateTimeLabelField
      {...{ completionEndDate, formatDateTime }}
    />
  );
};

export default React.memo(TrainingCompletionEndDateTimeFieldContainer);
