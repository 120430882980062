import React from "react";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import DeleteButton from "component/button/DeleteButton";
import { useDownloadedMaintenanceTaskDetailsPersonnelAssignmentAssistantStyles } from "pages/myMaintenanceTaskDetails/personnelAssignment/assistant/styles";
import { MaintenanceTaskAssistantType } from "module/maintenanceTask/types";
import MaintenanceTaskAssistantTypeFieldContainer from "module/maintenanceTask/enrichedComponent/assistantTypeField/MaintenanceTaskAssistantTypeFieldContainer";
import MaintenanceTaskAssistantInternalStaffNameContainer from "module/maintenanceTask/enrichedComponent/assistantInternalStaffField/MaintenanceTaskAssistantInternalStaffNameContainer";
import MaintenanceTaskAssistantExternalStaffNameContainer from "module/maintenanceTask/enrichedComponent/assistantExternalStaffNameField/MaintenanceTaskAssistantExternalStaffNameContainer";
import MaintenanceTaskAssistantInternalStaffPositionContainer from "module/maintenanceTask/enrichedComponent/assistantInternalStaffPositionField/MaintenanceTaskAssistantInternalStaffPositionContainer";
import MaintenanceTaskAssistantExternalStaffCompanyContainer from "module/maintenanceTask/enrichedComponent/assistantExternalStaffCompanyField/MaintenanceTaskAssistantExternalStaffCompanyContainer";

interface Props {
  isActionAvailable: boolean;
  assistantType: MaintenanceTaskAssistantType;
  canRemove?: boolean;
  remove?: () => void;
}

const MaintenanceTaskAssistantTableRow: React.FC<Props> = ({
  canRemove,
  remove,
  assistantType,
  isActionAvailable,
}) => {
  const classes =
    useDownloadedMaintenanceTaskDetailsPersonnelAssignmentAssistantStyles();

  const isInternal = assistantType === MaintenanceTaskAssistantType.internal;
  const isExternal = assistantType === MaintenanceTaskAssistantType.external;

  return (
    <TableRow>
      <TableCell className={classes.type}>
        <MaintenanceTaskAssistantTypeFieldContainer />
      </TableCell>
      <TableCell className={classes.staffName}>
        {isInternal && <MaintenanceTaskAssistantInternalStaffNameContainer />}
        {isExternal && <MaintenanceTaskAssistantExternalStaffNameContainer />}
      </TableCell>
      <TableCell className={classes.staffPositionOrCompany}>
        {isInternal && (
          <MaintenanceTaskAssistantInternalStaffPositionContainer />
        )}
        {isExternal && (
          <MaintenanceTaskAssistantExternalStaffCompanyContainer />
        )}
      </TableCell>
      {isActionAvailable && (
        <TableCell className={classes.actions}>
          {canRemove && remove != null && (
            <DeleteButton {...{ onClick: remove }} />
          )}
        </TableCell>
      )}
    </TableRow>
  );
};

export default React.memo(MaintenanceTaskAssistantTableRow);
