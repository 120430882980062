import {
  AbstractMyEntityInterface,
  EntityChangedDate,
  EntityIdentifier,
  EntityWithId,
  EntityWithRelationships,
} from "module/entity/types";
import { ApiMaintenanceTaskProcessStep } from "api/maintenanceTask/types";
import { MaintenanceTaskProcessStepQuickFixToSyncInterface } from "module/maintenanceTaskProcessStepQuickFix/types";
import { AttachmentToSyncInterface } from "module/attachment/types";
import { MaintenanceTaskToSyncInterface } from "../maintenanceTask/types";

export type MyMaintenanceTaskProcessStepInterface = AbstractMyEntityInterface<
  ApiMaintenanceTaskProcessStep,
  MaintenanceTaskProcessStepInterface
>;

export enum MaintenanceTaskProcessStepCheckResult {
  not_set = "not_set",
  good_yes = "good_yes",
  good_no = "good_no",
}

export interface MaintenanceTaskProcessStepInterface
  extends EntityWithId,
    EntityWithRelationships,
    EntityChangedDate {
  maintenanceTaskId: string;
  name: string;
  description?: string;
  type: string;
  component: string[];
  remarks?: string;
  checkResult: MaintenanceTaskProcessStepCheckResult;
  measureName?: string;
  measureValue?: number;
  measureUnit?: string;
  measureValueMin?: number;
  measureValueMax?: number;
  changedDateTime?: string;
  quickFix: EntityIdentifier | null;
  correctiveMaintenanceTask?: EntityIdentifier | null;
  attachments?: EntityIdentifier[];
  articleId?: number;
  componentId?: number;
  visualBreakAfter?: boolean;
}

export enum MaintenanceTaskProcessStepActionPerformed {
  not_set = "not_set",
  good_yes = "good_yes",
}

export interface MaintenanceTaskProcessStepToSyncInterface {
  id: number;
  measureValue: number | null;
  checkResult: MaintenanceTaskProcessStepCheckResult;
  remarks: string | null;
  changedDateTime?: string;
  quickFix: MaintenanceTaskProcessStepQuickFixToSyncInterface | null;
  correctiveMaintenanceTask: MaintenanceTaskToSyncInterface | null;
  attachments: AttachmentToSyncInterface[];
  name: string;
  description?: string;
  type: string;
  componentId?: number;
  measureName?: string;
  measureUnit?: string;
  measureValueMin?: number;
  measureValueMax?: number;
  articleId?: number;
}
