import React, { useContext, useCallback } from "react";
import t from "module/translations";
import { TRAINING_REMARKS_FIELD_NAME } from "module/training/constants";
import TextareaField from "component/textareaField/TextareaField";
import XLabelField from "component/labelField/XLabelField";
import { TrainingInterface } from "module/training/types";
import TrainingContext from "module/training/context/TrainingContext";
import TrainingChangeContext from "module/training/change/TrainingChangeContext";
import TrainingFieldMetaDataContext from "module/training/validation/fieldMetaData/TrainingFieldMetaDataContext";
import { getFieldMetaDataWithErrorHandling } from "module/fieldMetaData/getFieldMetaData";

const MyTrainingRemarksFieldContainer: React.FC = () => {
  const { entity } = useContext(TrainingContext);
  const { change: changeHelper } = useContext(TrainingChangeContext);
  const { getFieldMetaData } = useContext(TrainingFieldMetaDataContext);

  const training = entity as TrainingInterface;

  const change = useCallback(
    (remarks?: string) => {
      changeHelper({ remarks });
    },
    [changeHelper]
  );

  const { warnings, errors, isEditable } = getFieldMetaDataWithErrorHandling(
    () => getFieldMetaData(TRAINING_REMARKS_FIELD_NAME)
  );

  const label = t.competencyManagement.training.remarksText;

  return isEditable ? (
    <TextareaField
      {...{
        value: training.remarks,
        change,
        label,
        errors,
      }}
    />
  ) : (
    <XLabelField
      {...{ caption: label, label: training.remarks, errors, warnings }}
    />
  );
};

export default React.memo(MyTrainingRemarksFieldContainer);
