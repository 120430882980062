import React from "react";
import { EntityIdentifier, EntityInterface } from "module/entity/types";
import EntityCollectionContext, {
  EntityCollectionContextInterface,
} from "module/entity/context/entityCollection/EntityCollectionContext";

interface EntityCollectionContextProviderProps {
  entityCollection: Map<EntityIdentifier, EntityInterface>;
  children: React.ReactNode;
}

const EntityCollectionContextProvider: React.FC<EntityCollectionContextProviderProps> =
  ({ entityCollection, children }) => {
    const contextValue: EntityCollectionContextInterface = {
      entityCollection,
    };

    return (
      <EntityCollectionContext.Provider value={contextValue}>
        {children}
      </EntityCollectionContext.Provider>
    );
  };

export default EntityCollectionContextProvider;
