import React from "react";
import { EntityChangeContextInterface } from "module/entity/context/change/EntityChangeContext";

export type TrainingSessionChangeContextInterface =
  EntityChangeContextInterface;

const TrainingSessionChangeContext = React.createContext(
  <TrainingSessionChangeContextInterface>{}
);

export default TrainingSessionChangeContext;
