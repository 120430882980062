import React, { useContext } from "react";
import TableCell from "@mui/material/TableCell";
import Checkbox from "@mui/material/Checkbox";
import ToggleContext from "module/toggle/context/ToggleContext";
import InfoIconWithReason from "module/block/InfoIconWithReason";
import { EntityType } from "module/entity/types";
import AssessmentContext from "module/assessment/context/AssessmentContext";
import { AssessmentInterface } from "module/assessment/types";
import useGetAssessmentBlockedReasonText from "module/assessment/block/useGetAssessmentBlockedReasonText";

const CompetencyManagementListTableRowAssessmentSelectTableCellContainer: React.FC =
  () => {
    const { entity } = useContext(AssessmentContext);
    const { isSelected: isSelectedHelper } = useContext(ToggleContext);

    const assessment = entity as AssessmentInterface;

    const isBlockedReasonText = useGetAssessmentBlockedReasonText(assessment);

    const isSelected = isSelectedHelper({
      id: assessment.id,
      type: EntityType.assessment,
    });

    return (
      <TableCell padding="checkbox">
        {isBlockedReasonText ? (
          <InfoIconWithReason {...{ reason: isBlockedReasonText as string }} />
        ) : (
          <Checkbox checked={isSelected} />
        )}
      </TableCell>
    );
  };

export default React.memo(
  CompetencyManagementListTableRowAssessmentSelectTableCellContainer
);
