import React, { useContext, useMemo } from "react";
import MaintenanceTaskContext from "module/maintenanceTask/context/MaintenanceTaskContext";
import { MaintenanceTaskInterface } from "module/maintenanceTask/types";
import { EntityType } from "module/entity/types";
import MaintenanceTaskToBeSignedDetailsPersonnelAssignmentsInternalStaff from "pages/maintenanceTaskToBeSignedDetails/personnelAssignment/internalList/MaintenanceTaskToBeSignedDetailsPersonnelAssignmentsInternalStaff";

const MaintenanceTaskToBeSignedDetailsPersonnelAssignmentsInternalStaffContainer: React.FC =
  () => {
    const { entity } = useContext(MaintenanceTaskContext);
    const maintenanceTask = entity as MaintenanceTaskInterface;

    const entityIdentifiers = useMemo(
      () =>
        maintenanceTask.personnelAssignments.filter(
          (personnelAssignmentEntityIdentifier) =>
            personnelAssignmentEntityIdentifier.type ===
            EntityType.maintenanceTaskPersonnelAssignmentInternalStaff
        ),
      [maintenanceTask.personnelAssignments]
    );

    return (
      <MaintenanceTaskToBeSignedDetailsPersonnelAssignmentsInternalStaff
        {...{
          entityIdentifiers,
        }}
      />
    );
  };

export default React.memo(
  MaintenanceTaskToBeSignedDetailsPersonnelAssignmentsInternalStaffContainer
);
