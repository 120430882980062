import React, { useContext } from "react";
import MaintenanceTaskProcessStepContext from "module/maintenanceTaskProcessStep/context/MaintenanceTaskProcessStepContext";
import { MaintenanceTaskProcessStepInterface } from "module/maintenanceTaskProcessStep/types";
import MyMaintenanceTaskProcessStepListRowQuickFix from "module/maintenanceTaskProcessStepQuickFix/enrichedComponent/quickFix/MyMaintenanceTaskProcessStepQuickFix";
import MyMaintenanceTaskProcessStepQuickFixContextProvider from "module/maintenanceTaskProcessStepQuickFix/enrichedComponent/quickFix/MyMaintenanceTaskProcessStepQuickFixContextProvider";
import { EntityIdentifier } from "module/entity/types";
import MaintenanceTaskProcessStepQuickFixAddButtonContainer from "module/maintenanceTaskProcessStepQuickFix/enrichedComponent/addButton/MaintenanceTaskProcessStepQuickFixAddButtonContainer";

const MyMaintenanceTaskProcessStepQuickFixContainer: React.FC = () => {
  const { entity: processStepEntity } = useContext(
    MaintenanceTaskProcessStepContext
  );
  const { quickFix, correctiveMaintenanceTask } =
    processStepEntity as MaintenanceTaskProcessStepInterface;

  // TODO: not strict because for old data correctiveMaintenanceTask can be undefined
  // TODO: introduced ~ 30/11/2023
  if (correctiveMaintenanceTask != null) {
    return null;
  }

  if (quickFix === null) {
    return <MaintenanceTaskProcessStepQuickFixAddButtonContainer />;
  }
  return (
    <MyMaintenanceTaskProcessStepQuickFixContextProvider
      {...{ entityIdentifier: quickFix as EntityIdentifier }}
    >
      <MyMaintenanceTaskProcessStepListRowQuickFix />
    </MyMaintenanceTaskProcessStepQuickFixContextProvider>
  );
};

export default React.memo(MyMaintenanceTaskProcessStepQuickFixContainer);
