import React, { useContext } from "react";
import AssessmentContext from "module/assessment/context/AssessmentContext";
import { AssessmentInterface } from "module/assessment/types";
import AssessmentAssigneeLabelField from "module/assessment/enrichedComponent/assigneeField/AssessmentAssigneeLabelField";

const AssessmentAssigneeContainer: React.FC = () => {
  const { entity } = useContext(AssessmentContext);
  const { assigneeId } = entity as AssessmentInterface;

  return <AssessmentAssigneeLabelField {...{ assigneeId }} />;
};

export default React.memo(AssessmentAssigneeContainer);
