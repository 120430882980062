import { useContext, useCallback } from "react";
import { EntityIdentifier, EntityType } from "module/entity/types";
import useEntityOpenClose from "module/entity/useEntityOpenClose";
import useEntityChange from "module/entity/useEntityChange";
import { useAppSelector } from "redux/hooks";
import { selectStaffById, selectUser } from "module/auth/redux/authSlice";
import selectOpenItemByEntityType from "module/staff/redux/selector/selectOpenItemByEntityType";
import InventoryCheckContext from "module/inventoryCheck/context/InventoryCheckContext";
import {
  InventoryCheckInterface,
  InventoryCheckState,
} from "module/inventoryCheck/types";

interface Result {
  open?: () => void;
  close?: () => void;
}

const useInventoryCheckOpenClose = (): Result => {
  const { entity } = useContext(InventoryCheckContext);
  const inventoryCheck = entity as InventoryCheckInterface;
  const entityIdentifier: EntityIdentifier = {
    id: inventoryCheck.id,
    type: EntityType.inventoryCheck,
  };

  const staffId = useAppSelector(selectUser).id;
  const selectStaffByIdHelper = useAppSelector(selectStaffById);
  const { visibleOnList: isStaffVisibleOnList } =
    selectStaffByIdHelper(staffId);

  const { change } = useEntityChange(entityIdentifier);
  const {
    canOpen: canOpenEntity,
    open: openEntity,
    canClose,
    close: closeEntity,
  } = useEntityOpenClose(entityIdentifier);

  const handleOpen = useCallback(() => {
    openEntity();

    let changes: Partial<InventoryCheckInterface> = {};

    if (isStaffVisibleOnList) {
      changes = { ...changes, assigneeId: staffId };
    }

    change(changes);
  }, [change, isStaffVisibleOnList, openEntity, staffId]);

  const isAnyOpen = Boolean(
    useAppSelector(selectOpenItemByEntityType(EntityType.inventoryCheck))
  );

  const canOpen =
    canOpenEntity &&
    inventoryCheck.state !== InventoryCheckState.validated &&
    !isAnyOpen;
  const open = canOpen ? handleOpen : undefined;

  const handleClose = useCallback(() => {
    closeEntity();
  }, [closeEntity]);

  const close =
    canClose && inventoryCheck.state !== InventoryCheckState.validated
      ? handleClose
      : undefined;

  return {
    open,
    close,
  };
};

export default useInventoryCheckOpenClose;
