import React, { useMemo } from "react";
import t from "module/translations";
import FormContainsErrors from "component/form/FormContainsErrors";
import MyMaintenanceTaskDetailsNavigation, {
  MyMaintenanceTaskDetailsNavigationProps,
} from "pages/myMaintenanceTaskDetails/navigation/MyMaintenanceTaskDetailsNavigation";
import routes from "routes/routes";
import {
  getPathToMaintenanceTaskDetailsOverview,
  getPathToMaintenanceTaskDetailsParts,
  getPathToMaintenanceTaskDetailsPersonnelAssignments,
  getPathToMaintenanceTaskDetailsProcessSteps,
} from "module/maintenanceTask/helper/getPath";
import { Route } from "react-router-dom";
import ButtonsContainer from "component/button/ButtonsContainer";
import OpenButton from "component/button/OpenButton";
import CloseButton from "component/button/CloseButton";
import MaintenanceTaskStateEditableFieldContainer from "module/maintenanceTask/enrichedComponent/stateField/MaintenanceTaskStateEditableFieldContainer";
import { MaintenanceTaskDefinitionType } from "pages/maintenanceTaskList/types";
import InfoAlert from "component/alert/InfoAlert";
import LinkWithIcon from "component/link/LinkWithIcon";
import { MaintenanceTaskProcessStepInterface } from "../../module/maintenanceTaskProcessStep/types";

interface Props {
  maintenanceTaskId: string;
  hasError: boolean;
  open?: () => void;
  close?: () => void;
  isStateEditable: boolean;
  maintenanceTaskDefinitionType: MaintenanceTaskDefinitionType;
  originalMaintenanceTaskProcessStep: MaintenanceTaskProcessStepInterface | null;
}

const MyMaintenanceTaskDetails: React.FC<Props> = ({
  maintenanceTaskId,
  hasError,
  open,
  close,
  isStateEditable,
  maintenanceTaskDefinitionType,
  originalMaintenanceTaskProcessStep,
}) => {
  const navigationProps: MyMaintenanceTaskDetailsNavigationProps =
    useMemo(() => {
      const result: MyMaintenanceTaskDetailsNavigationProps = {
        listPath:
          maintenanceTaskDefinitionType === MaintenanceTaskDefinitionType.system
            ? routes.myMaintenanceTasks.path
            : routes.mySafetyMaintenanceTasks.path,
        overviewPath:
          getPathToMaintenanceTaskDetailsOverview(maintenanceTaskId),
        processStepPath:
          getPathToMaintenanceTaskDetailsProcessSteps(maintenanceTaskId),
        partsPath: getPathToMaintenanceTaskDetailsParts(maintenanceTaskId),
        personnelAssignmentPath:
          getPathToMaintenanceTaskDetailsPersonnelAssignments(
            maintenanceTaskId
          ),
      };

      return result;
    }, [maintenanceTaskDefinitionType, maintenanceTaskId]);

  return (
    <>
      {hasError && <FormContainsErrors />}
      {originalMaintenanceTaskProcessStep !== null && (
        <InfoAlert>
          <>
            {t.maintenanceTask.theEntityRelatesToOriginalMaintenanceTaskProcessStep(
              originalMaintenanceTaskProcessStep.name
            )}{" "}
            <LinkWithIcon
              {...{
                path: getPathToMaintenanceTaskDetailsProcessSteps(
                  originalMaintenanceTaskProcessStep.maintenanceTaskId
                ),
              }}
            />
          </>
        </InfoAlert>
      )}
      <MyMaintenanceTaskDetailsNavigation {...navigationProps} />
      <Route
        {...{
          key: routes.myMaintenanceTaskDetailsOverview.path,
          path: routes.myMaintenanceTaskDetailsOverview.path,
          component: routes.myMaintenanceTaskDetailsOverview.mainComponent,
        }}
      />
      <Route
        {...{
          key: routes.myMaintenanceTaskDetailsProcessSteps.path,
          path: routes.myMaintenanceTaskDetailsProcessSteps.path,
          component: routes.myMaintenanceTaskDetailsProcessSteps.mainComponent,
          exact: routes.myMaintenanceTaskDetailsProcessSteps.exact,
        }}
      />
      <Route
        {...{
          key: routes.myMaintenanceTaskProcessStepsDetails.path,
          path: routes.myMaintenanceTaskProcessStepsDetails.path,
          component: routes.myMaintenanceTaskProcessStepsDetails.mainComponent,
          exact: routes.myMaintenanceTaskProcessStepsDetails.exact,
        }}
      />
      <Route
        {...{
          key: routes.myMaintenanceTaskDetailsParts.path,
          path: routes.myMaintenanceTaskDetailsParts.path,
          component: routes.myMaintenanceTaskDetailsParts.mainComponent,
        }}
      />
      <Route
        {...{
          key: routes.myMaintenanceTaskDetailsPersonnelAssignments.path,
          path: routes.myMaintenanceTaskDetailsPersonnelAssignments.path,
          component:
            routes.myMaintenanceTaskDetailsPersonnelAssignments.mainComponent,
        }}
      />
      {(open || close) && (
        <ButtonsContainer>
          {open && <OpenButton onClick={open} />}
          {close && <CloseButton onClick={close} />}
          {isStateEditable && <MaintenanceTaskStateEditableFieldContainer />}
        </ButtonsContainer>
      )}
    </>
  );
};

export default React.memo(MyMaintenanceTaskDetails);
