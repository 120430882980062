import React from "react";
import makeStyles from "@mui/styles/makeStyles";
import MUITableContainer from "@mui/material/TableContainer";
import Paper from "@mui/material/Paper";

const useStyles = makeStyles({
  root: {
    maxHeight: "calc(100vh - 120px)",
  },
});

interface Props {
  children: React.ReactNode;
}

const TableContainer: React.FC<Props> = ({ children }) => {
  const classes = useStyles();
  return (
    <MUITableContainer component={Paper} className={classes.root}>
      {children}
    </MUITableContainer>
  );
};

export default React.memo(TableContainer);
