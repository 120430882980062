import React from "react";
import makeStyles from "@mui/styles/makeStyles";
import MUITable from "@mui/material/Table";

const useStyles = makeStyles({
  root: {
    minWidth: 650,
  },
});

interface Props {
  children: React.ReactNode;
}

const Table: React.FC<Props> = ({ children }) => {
  const classes = useStyles();
  return <MUITable className={classes.root}>{children}</MUITable>;
};

export default React.memo(Table);
