import { IsBlockedInterface } from "module/block/types";
import {
  ApiAssessmentInterface,
  ApiAssessmentObjectiveInterface,
} from "api/competencyManagement/types";
import {
  AbstractMyEntityInterface,
  EntityChangedDate,
  EntityIdentifier,
  EntityType,
  EntityWithId,
  EntityWithRelationships,
} from "module/entity/types";

export interface AssessmentIsBlockedInterface extends IsBlockedInterface {
  isBlockedBecauseItIsCompleted: boolean;
  isBlockedBecauseUserHasNoPermissions: boolean;
}

export interface AssessmentObjectiveInterface
  extends EntityWithId,
    EntityWithRelationships,
    EntityChangedDate {
  assessmentId: string;
  // backendAssessmentId?: number;
  name: string;
  remarks: string;
  completeDate?: string;
  achieved: boolean;
  isCategory: boolean;
  level: number;
  sorting: number;
}

export type MyAssessmentObjectiveInterface = AbstractMyEntityInterface<
  ApiAssessmentObjectiveInterface,
  AssessmentObjectiveInterface
>;

export enum AssessmentState {
  initScheduled = "init_sched",
  scheduled = "scheduled",
  completed = "completed",
}

export enum AssessmentType {
  initial = "initial",
  refresher = "refresher",
}

export interface AssessmentInterface
  extends EntityWithId,
    EntityWithRelationships,
    EntityChangedDate {
  name: string;
  reportingNumber?: string;
  remarks: string;
  assigneeId?: number;
  internalTrainerId?: number;
  externalTrainer?: string;
  type?: AssessmentType;
  scheduledDate: string;
  scheduledStartDate: string;
  scheduledEndDate: string;
  state: AssessmentState;
  moduleText: string;
  expectedDate: string;
  completionStartDate?: string;
  completionEndDate?: string;
  isBlockedDetails: AssessmentIsBlockedInterface;
  objectives: EntityIdentifier[];
}

export type MyAssessmentInterface = AbstractMyEntityInterface<
  ApiAssessmentInterface,
  AssessmentInterface
>;

export enum AssessmentSyncUnavailableReason {
  noPermissions = "noPermissions",
  offline = "offline",
  notValid = "notValid",
  isOpen = "isOpen",
  hasNotChanged = "hasNotChanged",
}

export interface AssessmentObjectiveToSyncInterface {
  identifier: {
    type: EntityType.assessmentObjective;
    id?: number;
    frontendId?: string;
  };
  data: {
    id?: number;
    achieved: boolean;
    remarks?: string;
    changedDateTime?: string;
  };
  raw?: ApiAssessmentObjectiveInterface;
}

export interface AssessmentToSyncInterface {
  identifier: {
    type: EntityType.assessment;
    id: number | null;
    frontendId?: string;
  };
  data: {
    id?: number;
    remarks?: string;
    state: AssessmentState;
    completionStartDate?: string;
    completionEndDate?: string;
    changedDateTime?: string;
    objectives: AssessmentObjectiveToSyncInterface[];
    internalTrainerId?: number;
    externalTrainer?: string;
  };
  raw?: ApiAssessmentInterface;
}
