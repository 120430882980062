import React from "react";
import { EntityFieldMetaDataContextInterface } from "module/entity/context/fieldMetaData/EntityFieldMetaDataContext";

export type MaintenanceTaskPersonnelAssignmentExternalStaffFieldMetaDataContextInterface =
  EntityFieldMetaDataContextInterface;

const MaintenanceTaskPersonnelAssignmentExternalStaffFieldMetaDataContext =
  React.createContext(
    <
      MaintenanceTaskPersonnelAssignmentExternalStaffFieldMetaDataContextInterface
    >{}
  );

export default MaintenanceTaskPersonnelAssignmentExternalStaffFieldMetaDataContext;
