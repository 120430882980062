import { useCallback } from "react";
import { useAppSelector } from "redux/hooks";
import { TrainingInterface, TrainingState } from "module/training/types";
import { API_DATE_TIME_FORMAT } from "api/date";
import moment from "moment";
import { EntityIdentifier, EntityType } from "module/entity/types";
import selectOpenItemByEntityType from "module/staff/redux/selector/selectOpenItemByEntityType";
import { selectUser } from "module/auth/redux/authSlice";
import useEntityCollectionChange from "module/entity/useEntityCollectionChange";
import useEntityCollectionOpenClose from "module/entity/useEntityCollectionOpenClose";

interface Result {
  open?: () => void;
  close?: () => void;
}

const useMyTrainingCollectionOpenClose = (
  trainings: TrainingInterface[]
): Result => {
  const entityIdentifiers: EntityIdentifier[] = trainings.map((training) => ({
    id: training.id,
    type: EntityType.training,
  }));
  const staffId = useAppSelector(selectUser).id;
  const { change } = useEntityCollectionChange(entityIdentifiers);
  const { calculate: calculateEntityCollectionOpenCLose } =
    useEntityCollectionOpenClose();

  const {
    open: openEntityCollection,
    canOpen: canOpenEntityCollection,
    close: closeEntityCollection,
    canClose: canCloseEntityCollection,
  } = calculateEntityCollectionOpenCLose(entityIdentifiers);

  const isAnyAssessmentOpen =
    useAppSelector(selectOpenItemByEntityType(EntityType.assessment)) != null;
  const isAnyTrainingOpen =
    useAppSelector(selectOpenItemByEntityType(EntityType.training)) != null;
  const isAnyTrainingCustomOpen =
    useAppSelector(selectOpenItemByEntityType(EntityType.trainingCustom)) !=
    null;

  const handleOpen = useCallback(() => {
    let changes: Partial<TrainingInterface> = {
      completionEndDate: undefined,
      internalTrainerId: staffId,
      externalTrainer: undefined,
    };

    openEntityCollection();
    if (trainings[0].completionStartDate == null) {
      changes = {
        ...changes,
        completionStartDate: moment().format(API_DATE_TIME_FORMAT),
      };
    }

    if (trainings[0].state === TrainingState.initScheduled) {
      changes = {
        ...changes,
        state: TrainingState.scheduled,
      };
    }

    change(changes);
  }, [change, openEntityCollection, staffId, trainings]);

  const canOpen =
    canOpenEntityCollection &&
    !isAnyAssessmentOpen &&
    !isAnyTrainingOpen &&
    !isAnyTrainingCustomOpen;
  const open = canOpen ? handleOpen : undefined;

  const handleClose = useCallback(() => {
    closeEntityCollection();
    if (trainings[0].state === TrainingState.completed) {
      change({
        completionEndDate: moment().format(API_DATE_TIME_FORMAT),
      });
    }
  }, [change, closeEntityCollection, trainings]);
  const close = canCloseEntityCollection ? handleClose : undefined;

  return {
    open,
    close,
  };
};

export default useMyTrainingCollectionOpenClose;
