import React, { useContext, useState, useEffect } from "react";
import { EntityIdentifier } from "module/entity/types";
import { useAppSelector } from "redux/hooks";
import selectEntity from "module/staff/redux/selector/selectEntity";
import {
  AttachmentInterface,
  MyAttachmentInterface,
} from "module/attachment/types";
import Attachment from "module/attachment/enrichedComponent/Attachment";
import useMaintenanceTaskAttachmentRemove from "module/maintenanceTaskAttachment/remove/useMaintenanceTaskAttachmentRemove";
import MaintenanceTaskContext from "module/maintenanceTask/context/MaintenanceTaskContext";
import { MaintenanceTaskInterface } from "module/maintenanceTask/types";
import { convertFileIntoBase64 } from "toolkit/convertFileIntoBase64";

interface Props {
  entityIdentifier: EntityIdentifier;
}

const MaintenanceTaskAttachmentListItemContainer: React.FC<Props> = ({
  entityIdentifier,
}) => {
  const [attachment, setAttachment] =
    useState<AttachmentInterface | null>(null);

  const { entity } = useContext(MaintenanceTaskContext);
  const maintenanceTask = entity as MaintenanceTaskInterface;

  const myAttachment = useAppSelector(selectEntity)(
    entityIdentifier
  ) as MyAttachmentInterface;

  useEffect(() => {
    async function getBase64(attachmentFromApi: AttachmentInterface) {
      const base64 = await convertFileIntoBase64(
        attachmentFromApi.path as string,
        attachmentFromApi.mimeType
      );

      setAttachment({ ...attachmentFromApi, base64 });
    }

    if (myAttachment != null) {
      setAttachment(myAttachment.data);
    } else {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      const rawAttachment = maintenanceTask.relationships.get(entityIdentifier)
        .data as AttachmentInterface;
      getBase64(rawAttachment);
    }
  }, [
    attachment,
    entityIdentifier,
    maintenanceTask.relationships,
    myAttachment,
  ]);

  const { canRemove, remove } =
    useMaintenanceTaskAttachmentRemove(entityIdentifier);

  if (attachment == null) {
    return null;
  }

  return <Attachment {...{ attachment, canRemove, remove }} />;
};

export default React.memo(MaintenanceTaskAttachmentListItemContainer);
