import React from "react";
import TableRow from "@mui/material/TableRow";
import makeStyles from "@mui/styles/makeStyles";

const useStyles = makeStyles({
  row: {
    position: "sticky",
    top: "72px",
    backgroundColor: "#fafafa",
    zIndex: 2,
  },
});

interface Props {
  children: React.ReactNode;
}

const TableHeadRow: React.FC<Props> = ({ children }) => {
  const classes = useStyles();
  return <TableRow className={classes.row}>{children}</TableRow>;
};

export default TableHeadRow;
