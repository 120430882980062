import React from "react";
import makeStyles from "@mui/styles/makeStyles";

const PADDING_PX = 25;

const useStyles = makeStyles({
  root: {
    paddingLeft: (props: { level: number }) => `${props.level * PADDING_PX}px`,
  },
});

interface Props {
  level: number;
  children: React.ReactNode;
}

const NestedElement: React.FC<Props> = ({ level, children }) => {
  const classes = useStyles({ level });
  return <span className={classes.root}>{children}</span>;
};

export default React.memo(NestedElement);
