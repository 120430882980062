import React, { useState } from "react";
import SortContext, {
  SortContextInterface,
} from "module/sort/context/SortContext";

import { SortType } from "module/sort/types";

interface Props {
  initialValue: SortType;
  children: React.ReactNode;
}

const SortContextProvider: React.FC<Props> = ({ initialValue, children }) => {
  const [sort, changeSort] = useState<SortType>(initialValue);

  const contextValue: SortContextInterface = {
    sort,
    changeSort,
  };

  return (
    <SortContext.Provider value={contextValue}>{children}</SortContext.Provider>
  );
};

export default SortContextProvider;
