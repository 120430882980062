import React, { useContext } from "react";
import TrainingCustomContext from "module/trainingCustom/context/TrainingCustomContext";
import TrainingCustomToBeSignedDetails from "pages/trainingCustomToBeSignedDetails/TrainingCustomToBeSignedDetails";

const TrainingCustomToBeSignedDetailsContainer: React.FC = () => {
  const { entity } = useContext(TrainingCustomContext);

  return (
    <TrainingCustomToBeSignedDetails
      {...{
        trainingCustomId: entity.id,
      }}
    />
  );
};

export default React.memo(TrainingCustomToBeSignedDetailsContainer);
