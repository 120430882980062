import React from "react";
import Alert from "@mui/material/Alert";
import t from "module/translations";
import makeStyles from "@mui/styles/makeStyles";

const useStyles = makeStyles({
  root: {
    marginBottom: "12px",
  },
});

const FormContainsErrors: React.FC = () => {
  const classes = useStyles();
  return (
    <Alert severity="error" className={classes.root}>
      {t.formContainsErrorsText}
    </Alert>
  );
};

export default React.memo(FormContainsErrors);
