import React from "react";
import { EntityIdentifier } from "module/entity/types";
import useEntityChange from "module/entity/useEntityChange";
import { EntityChangeContextInterface } from "module/entity/context/change/EntityChangeContext";

interface Props {
  entityIdentifier: EntityIdentifier;
  context: React.Context<EntityChangeContextInterface>;
  children: React.ReactNode;
}

const MyEntityChangeContextProvider: React.FC<Props> = ({
  entityIdentifier,
  context,
  children,
}) => {
  const { change } = useEntityChange(entityIdentifier);

  const contextValue: EntityChangeContextInterface = {
    change,
  };

  return <context.Provider value={contextValue}>{children}</context.Provider>;
};

export default MyEntityChangeContextProvider;
