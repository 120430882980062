import React, { useMemo } from "react";
import Tabs from "@mui/material/Tabs";

interface Props {
  tabMap: boolean[];
  children: React.ReactNode;
}

const NavigationBarTabs: React.FC<Props> = ({ tabMap, children }) => {
  const selectedTab: number = useMemo(
    () =>
      tabMap.reduce((result, current, index) => {
        if (current) {
          return index;
        }
        return result;
      }, -1),
    [tabMap]
  );

  return (
    <Tabs value={selectedTab} indicatorColor="primary" textColor="primary">
      {children}
    </Tabs>
  );
};

export default React.memo(NavigationBarTabs);
