import React, { useState, useEffect, useCallback } from "react";
import { ValidatorInterface } from "module/validation/types";
import { Theme } from "@mui/material/styles";
import createStyles from "@mui/styles/createStyles";
import makeStyles from "@mui/styles/makeStyles";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormHelperText from "@mui/material/FormHelperText";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import ValidationMessage from "module/validation/ValidationMessage";
import { SelectOption, SelectOptionValue } from "./type";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    formControl: {
      minWidth: 120,
    },
    selectEmpty: {
      marginTop: theme.spacing(1),
    },
  })
);

interface Props {
  options: SelectOption[];
  value?: SelectOptionValue;
  change: (newValue: SelectOptionValue) => void;
  errors?: ValidatorInterface[];
  label?: string;
}

const SelectField: React.FC<Props> = ({
  options,
  value,
  change,
  errors,
  label,
}) => {
  const classes = useStyles();

  const [tempValue, setTempValue] = useState<SelectOptionValue>(value ?? null);

  useEffect(() => {
    setTempValue(value ?? null);
  }, [value]);

  const hasError = errors != null && Boolean(errors.length);

  const handleChange = useCallback(
    (
      event:
        | (Event & { target: { value: SelectOptionValue; name: string } })
        | React.ChangeEvent<HTMLInputElement>
    ) => {
      const newValue = event.target.value as SelectOptionValue;
      setTempValue(newValue);
      change(newValue);
    },
    [change]
  );

  return (
    <FormControl
      className={classes.formControl}
      error={hasError}
      variant="standard"
    >
      <InputLabel>{label}</InputLabel>
      <Select
        {...{
          value: tempValue,
          onChange: handleChange,
          variant: "standard",
        }}
      >
        {options.map((option) => (
          <MenuItem
            {...{
              value: option.value == null ? "" : option.value,
              key: option.value,
            }}
          >
            {option.text}
          </MenuItem>
        ))}
      </Select>
      {hasError && (
        <FormHelperText>
          <ValidationMessage validators={errors as ValidatorInterface[]} />
        </FormHelperText>
      )}
    </FormControl>
  );
};

export default React.memo(SelectField);
