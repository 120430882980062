import React, { useContext } from "react";
import { getFieldMetaData as getFieldMetaDataHelper } from "module/fieldMetaData/getFieldMetaData";
import TrainingCustomValidationResultContext from "module/trainingCustom/validation/TrainingCustomValidationResultContext";
import TrainingCustomOpenCloseContext from "module/trainingCustom/openClose/context/TrainingCustomOpenCloseContext";
import TrainingCustomStateContext from "module/trainingCustom/state/context/TrainingCustomStateContext";
import TrainingCustomFieldMetaDataContext, {
  MyTrainingCustomFieldMetaDataContextInterface,
} from "module/trainingCustom/validation/fieldMetaData/TrainingCustomFieldMetaDataContext";

interface Prop {
  children: React.ReactNode;
}
const MyTrainingCustomFieldMetaDataContextProvider: React.FC<Prop> = ({
  children,
}) => {
  const { validationWarnings, validationErrors } = useContext(
    TrainingCustomValidationResultContext
  );
  const { isOpen } = useContext(TrainingCustomOpenCloseContext);
  const { isStateScheduledOrLower } = useContext(TrainingCustomStateContext);

  const getFieldMetaData = getFieldMetaDataHelper({
    validationWarnings,
    validationErrors,
    isOpen,
    isStateScheduledOrLower,
  });

  const contextValue: MyTrainingCustomFieldMetaDataContextInterface = {
    getFieldMetaData,
  };

  return (
    <TrainingCustomFieldMetaDataContext.Provider value={contextValue}>
      {children}
    </TrainingCustomFieldMetaDataContext.Provider>
  );
};

export default MyTrainingCustomFieldMetaDataContextProvider;
