import React, { useEffect, useState, useCallback } from "react";
import TextField from "@mui/material/TextField";
import useDebounce from "toolkit/useDebounce";
import XValidationMessage from "module/validation/XValidationMessage";

interface Props {
  label?: string;
  value?: string;
  change: (newValue?: string) => void;
  errors: string[];
}

const TextareaField: React.FC<Props> = ({ label, value, change, errors }) => {
  const [tempValue, setTempValue] = useState<string | undefined>(value);

  useEffect(() => {
    setTempValue(value);
  }, [value]);

  const debounceHandleChange = useDebounce(change);

  const handleChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const newValue = event.target.value;
      setTempValue(newValue);
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      debounceHandleChange(newValue);
    },
    [debounceHandleChange]
  );

  return (
    <TextField
      {...{
        label,
        multiline: true,
        value: tempValue,
        fullWidth: true,
        onChange: handleChange,
        variant: "standard",
        ...(errors.length
          ? {
              helperText: <XValidationMessage messages={errors} />,
              error: true,
            }
          : {}),
      }}
    />
  );
};

export default React.memo(TextareaField);
