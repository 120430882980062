import { RouteType } from "routes/types";

export const filterRoutesByParentIdentifier = (
  routes: RouteType[],
  parentIdentifier: string
): RouteType[] =>
  routes.filter((route) => route.parentIdentifier === parentIdentifier);

export const filterMainLevelRoutes = (routes: RouteType[]): RouteType[] =>
  routes.filter((route) => route.parentIdentifier === undefined);
