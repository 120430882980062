import React from "react";
import makeStyles from "@mui/styles/makeStyles";
import IconButton from "@mui/material/IconButton";
import UserAvatar from "layout/userAvatar/UserAvatar";

const useStyles = makeStyles(() => ({
  rightSection: {
    display: "flex",
  },
}));

interface Props {
  open: () => void;
}

const HeaderProfile: React.FC<Props> = ({ open }) => {
  const classes = useStyles();
  return (
    <div className={classes.rightSection}>
      <IconButton
        edge="end"
        aria-label="account of current user"
        aria-haspopup="true"
        onClick={open}
        color="inherit"
        size="large"
      >
        <UserAvatar />
      </IconButton>
    </div>
  );
};

export default React.memo(HeaderProfile);
