import React from "react";
import { EntityOpenCloseContextInterface } from "module/entity/context/openClose/EntityOpenCloseContext";

export type TrainingOpenCloseContextInterface = EntityOpenCloseContextInterface;

const TrainingOpenCloseContext = React.createContext(
  <TrainingOpenCloseContextInterface>{}
);

export default TrainingOpenCloseContext;
