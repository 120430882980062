import React, { useEffect } from "react";
import { selectIsAppInitialized } from "module/auth/redux/authSlice";
import { useAppDispatch, useAppSelector } from "redux/hooks";
import AuthLoginPageContainer from "module/auth/login/AuthLoginPageContainer";
import AppForLoggedInUser from "layout/appForLoggedInUser/AppForLoggedInUser";
import useAuth from "module/auth/useAuth";
import { initDevice } from "module/device/redux/deviceSlice";

const App: React.FC = () => {
  const dispatch = useAppDispatch();
  const isAppInitialized = useAppSelector(selectIsAppInitialized);
  const { handleLogout } = useAuth();

  useEffect(() => {
    dispatch(initDevice());
  }, [dispatch]);

  useEffect(() => {
    handleLogout();
  }, [handleLogout]);

  return isAppInitialized ? <AppForLoggedInUser /> : <AuthLoginPageContainer />;
};
export default App;
