import React, {
  useCallback,
  useContext,
  useEffect,
  useState,
  useMemo,
} from "react";
import FullScreenLoaderContext from "module/fullScreenLoader/context/FullScreenLoaderContext";
import SortContext from "module/sort/context/SortContext";
import { EntityIdentifier, MyEntityInterface } from "module/entity/types";
import MyEntityCollectionContextProvider from "module/entity/context/entityCollection/MyEntityCollectionContextProvider";
import EntityDownloadContextProvider from "module/entity/context/download/EntityDownloadContextProvider";
import PullToRefresh from "component/pullToRefresh/PullToRefresh";
import ToggleContextProvider from "module/toggle/context/ToggleContextProvider";
import { useAppSelector } from "redux/hooks";
import { selectUser } from "module/auth/redux/authSlice";
import getInventoryChecks from "api/inventoryCheck/getInventoryChecks";
import { MyInventoryCheckInterface } from "module/inventoryCheck/types";
import FilterContext from "module/filter/context/FilterContext";
import { sortInventoryChecks } from "./sortInventoryChecks";
import InventoryCheckList from "./InventoryCheckList";
import { InventoryCheckFilters } from "./types";

const InventoryCheckListContainer: React.FC = () => {
  const { turnOn: turnLoaderOn, turnOff: turnLoaderOff } = useContext(
    FullScreenLoaderContext
  );
  const { sort } = useContext(SortContext);
  const { filters } = useContext(FilterContext);

  const { id: myStaffId } = useAppSelector(selectUser);

  const [myEntityCollection, setMyEntityCollection] = useState<
    Map<EntityIdentifier, MyEntityInterface>
  >(new Map());

  const load = useCallback(async () => {
    turnLoaderOn();
    const inventoryChecksFromApi = await getInventoryChecks(
      filters as unknown as InventoryCheckFilters,
      sort
    );

    const inventoryChecksIdsAssignedToMe: string[] = inventoryChecksFromApi
      .filter(
        (inventoryCheckFromApi) =>
          inventoryCheckFromApi.data.assigneeId === myStaffId
      )
      .map((inventoryCheckFromApi) => inventoryCheckFromApi.data.id);

    const sortedInventoryChecksFromApi = sortInventoryChecks(
      inventoryChecksFromApi,
      inventoryChecksIdsAssignedToMe
    );
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    setMyEntityCollection(sortedInventoryChecksFromApi);
    turnLoaderOff();
  }, [filters, myStaffId, sort, turnLoaderOff, turnLoaderOn]);

  useEffect(() => {
    load();
  }, [load]);

  const itemsAllowedToToggle: EntityIdentifier[] = useMemo(
    () =>
      Array.from(myEntityCollection.keys()).reduce(
        (result, entityIdentifier) => {
          const myEntity = myEntityCollection.get(
            entityIdentifier
          ) as MyInventoryCheckInterface;
          if (!myEntity.data.isBlockedDetails?.isBlocked) {
            return [...result, entityIdentifier];
          }

          return result;
        },
        [] as EntityIdentifier[]
      ),
    [myEntityCollection]
  );

  const entityIdentifiers = useMemo(
    () => Array.from(myEntityCollection.keys()),
    [myEntityCollection]
  );

  return (
    <MyEntityCollectionContextProvider {...{ myEntityCollection }}>
      <EntityDownloadContextProvider {...{ load }}>
        <PullToRefresh refresh={load}>
          <ToggleContextProvider {...{ itemsAllowedToToggle }}>
            <InventoryCheckList
              {...{
                entityIdentifiers,
              }}
            />
          </ToggleContextProvider>
        </PullToRefresh>
      </EntityDownloadContextProvider>
    </MyEntityCollectionContextProvider>
  );
};

export default React.memo(InventoryCheckListContainer);
