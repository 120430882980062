import React, { useMemo } from "react";
import { useRouteMatch } from "react-router-dom";
import NavigationBar from "component/navigationBar/NavigationBar";
import NavigationBarItemBack from "component/navigationBar/NavigationBarItemBack";
import NavigationBarItem from "component/navigationBar/NavigationBarItem";
import NavigationBarTabs from "component/navigationBar/NavigationBarTabs";
import t from "module/translations";

export interface MyInventoryCheckDetailsNavigationProps {
  listPath: string;
  overviewPath: string;
  articlesPath: string;
}

const MyInventoryCheckDetailsNavigation: React.FC<MyInventoryCheckDetailsNavigationProps> =
  ({ listPath, overviewPath, articlesPath }) => {
    const matchedOverview = Boolean(
      useRouteMatch({
        path: overviewPath,
        exact: true,
      })
    );
    const matchedArticles = Boolean(
      useRouteMatch({
        path: articlesPath,
        exact: true,
      })
    );

    const matchedList = false;

    const tabMap: boolean[] = useMemo(
      () => [matchedList, matchedOverview, matchedArticles],
      [matchedList, matchedOverview, matchedArticles]
    );

    return (
      <NavigationBar>
        <NavigationBarTabs {...{ tabMap }}>
          <NavigationBarItemBack
            {...{
              key: listPath,
              path: listPath,
            }}
          />
          <NavigationBarItem
            {...{
              key: overviewPath,
              path: overviewPath,
              title: t.routes.inventoryCheckDetailsOverview,
            }}
          />
          <NavigationBarItem
            {...{
              key: articlesPath,
              path: articlesPath,
              title: t.routes.inventoryCheckDetailsArticles,
            }}
          />
        </NavigationBarTabs>
      </NavigationBar>
    );
  };

export default React.memo(MyInventoryCheckDetailsNavigation);
