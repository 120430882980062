import React, { useContext } from "react";
import { AssessmentObjectiveInterface } from "module/assessment/types";
import AssessmentObjectiveContext from "module/assessmentObjective/context/AssessmentObjectiveContext";
import AssessmentObjectiveTableRowItem from "module/assessmentObjective/enrichedComponent/tableRow/item/AssessmentObjectiveTableRowItem";

const AssessmentObjectiveTableRowItemContainer: React.FC = () => {
  const { entity } = useContext(AssessmentObjectiveContext);

  const assessmentObjective = entity as AssessmentObjectiveInterface;
  const { name, level } = assessmentObjective;

  return <AssessmentObjectiveTableRowItem {...{ name, level }} />;
};

export default React.memo(AssessmentObjectiveTableRowItemContainer);
