import React from "react";
import { EntityOpenCloseContextInterface } from "module/entity/context/openClose/EntityOpenCloseContext";

export type MaintenanceTaskOpenCloseContextInterface =
  EntityOpenCloseContextInterface;

const MaintenanceTaskOpenCloseContext = React.createContext(
  <MaintenanceTaskOpenCloseContextInterface>{}
);

export default MaintenanceTaskOpenCloseContext;
