import React from "react";
import { Link as RouterLink } from "react-router-dom";
import Tab from "@mui/material/Tab";
import Link from "@mui/material/Link";
import makeStyles from "@mui/styles/makeStyles";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";

const useStyles = makeStyles({
  tab: {
    marginLeft: "15px",
    minWidth: "auto",
  },
});

interface Props {
  path: string;
}

const NavigationBarItemBack: React.FC<Props> = ({ path }) => {
  const classes = useStyles();

  return (
    <Link
      {...{
        to: path,
        component: RouterLink,
        underline: "none",
      }}
    >
      <Tab className={classes.tab} icon={<ArrowBackIosIcon />} />
    </Link>
  );
};

export default React.memo(NavigationBarItemBack);
