import React from "react";
import TableCell from "@mui/material/TableCell";
import { Theme } from "@mui/material/styles";
import createStyles from "@mui/styles/createStyles";
import makeStyles from "@mui/styles/makeStyles";
import Typography from "@mui/material/Typography";
import InfoIconWIthPopover from "component/infoIconWithPopover/InfoIconWithPopover";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    typography: {
      padding: theme.spacing(2),
    },
  })
);

interface Props {
  label: string;
}

const TableInfoIconCell: React.FC<Props> = ({ label }) => {
  const classes = useStyles();
  return (
    <TableCell padding="checkbox">
      <InfoIconWIthPopover {...{ label }}>
        <Typography className={classes.typography}>{label}</Typography>
      </InfoIconWIthPopover>
    </TableCell>
  );
};

export default TableInfoIconCell;
