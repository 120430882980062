import React, { useCallback, useContext } from "react";
import _ from "lodash";
import EntityDownloadContext, {
  EntityDownloadContextInterface,
} from "module/entity/context/download/EntityDownloadContext";
import { EntityIdentifier, MyEntityInterface } from "module/entity/types";
import useEntityDownload from "module/entity/useEntityDownload";
import { toastError, toastSuccess, toastWarning } from "layout/toast/helper";
import t from "module/translations";
import MyEntityCollectionContext from "module/entity/context/entityCollection/MyEntityCollectionContext";

interface Props {
  load: () => Promise<void>;
  children: React.ReactNode;
}

const EntityDownloadContextProvider: React.FC<Props> = ({ load, children }) => {
  const { myEntityCollection } = useContext(MyEntityCollectionContext);

  const { download: downloadHelper } = useEntityDownload();

  const download = useCallback(
    async (entityIdentifiers: EntityIdentifier[]): Promise<void> => {
      try {
        const myEntityCollectionToDownload = Array.from(
          myEntityCollection.keys()
        ).reduce((result, entityIdentifier) => {
          if (
            entityIdentifiers.find((key) => _.isEqual(key, entityIdentifier))
          ) {
            result.set(
              entityIdentifier,
              myEntityCollection.get(entityIdentifier) as MyEntityInterface
            );
          }
          return result;
        }, new Map<EntityIdentifier, MyEntityInterface>());

        const { invalidMessages, downloadedCount } = await downloadHelper(
          myEntityCollectionToDownload
        );

        await load();

        if (downloadedCount) {
          toastSuccess(t.download.downloadSuccessText(downloadedCount));
        }
        if (invalidMessages.length) {
          toastWarning(invalidMessages.map((message) => <div>{message}</div>));
        }
      } catch (error) {
        toastError(t.api.generalError);
      }
    },
    [downloadHelper, load, myEntityCollection]
  );

  const contextValue: EntityDownloadContextInterface = {
    download,
  };

  return (
    <EntityDownloadContext.Provider value={contextValue}>
      {children}
    </EntityDownloadContext.Provider>
  );
};

export default EntityDownloadContextProvider;
