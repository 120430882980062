import React from "react";
import { EntitySignContextInterface } from "module/entity/context/sign/EntitySignContext";

export type TrainingSignContextInterface = EntitySignContextInterface;

const AssessmentSignContext = React.createContext(
  <TrainingSignContextInterface>{}
);

export default AssessmentSignContext;
