import React from "react";
import { EntityFieldMetaDataContextInterface } from "module/entity/context/fieldMetaData/EntityFieldMetaDataContext";

export type MaintenanceTaskProcessStepQuickFixFieldMetaDataContextInterface =
  EntityFieldMetaDataContextInterface;

const MaintenanceTaskProcessStepQuickFixFieldMetaDataContext =
  React.createContext(
    <MaintenanceTaskProcessStepQuickFixFieldMetaDataContextInterface>{}
  );

export default MaintenanceTaskProcessStepQuickFixFieldMetaDataContext;
