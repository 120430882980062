import React, { useContext } from "react";
import { getFieldMetaData as getFieldMetaDataHelper } from "module/fieldMetaData/getFieldMetaData";
import InventoryCheckOpenCloseContext from "module/inventoryCheck/openClose/context/InventoryCheckOpenCloseContext";
import InventoryCheckStateContext from "module/inventoryCheck/state/context/InventoryCheckStateContext";
import InventoryCheckValidationResultContext from "module/inventoryCheck/validation/InventoryCheckValidationResultContext";
import InventoryCheckFieldMetaDataContext, {
  InventoryCheckFieldMetaDataContextInterface,
} from "./InventoryCheckFieldMetaDataContext";

interface Prop {
  children: React.ReactNode;
}

const MyInventoryCheckFieldMetaDataContextProvider: React.FC<Prop> = ({
  children,
}) => {
  const { validationWarnings, validationErrors } = useContext(
    InventoryCheckValidationResultContext
  );
  const { isOpen } = useContext(InventoryCheckOpenCloseContext);
  const { isStateScheduledOrLower } = useContext(InventoryCheckStateContext);

  const getFieldMetaData = getFieldMetaDataHelper({
    validationWarnings,
    validationErrors,
    isOpen,
    isStateScheduledOrLower,
  });

  const contextValue: InventoryCheckFieldMetaDataContextInterface = {
    getFieldMetaData,
  };

  return (
    <InventoryCheckFieldMetaDataContext.Provider value={contextValue}>
      {children}
    </InventoryCheckFieldMetaDataContext.Provider>
  );
};

export default MyInventoryCheckFieldMetaDataContextProvider;
