import t from "module/translations";
import { TrainingSyncUnavailableReason } from "module/training/types";

export const getTrainingSyncUnavailableReasonTranslation = (
  unavailableReason: TrainingSyncUnavailableReason
): string => {
  return {
    [TrainingSyncUnavailableReason.notValid]:
      t.competencyManagement.training.sync.unavailableReason.notValidText,
    [TrainingSyncUnavailableReason.isOpen]:
      t.competencyManagement.training.sync.unavailableReason.isOpenText,
    [TrainingSyncUnavailableReason.offline]:
      t.competencyManagement.training.sync.unavailableReason.offlineText,
    [TrainingSyncUnavailableReason.noPermissions]:
      t.competencyManagement.training.sync.unavailableReason.noPermissionsText,
    [TrainingSyncUnavailableReason.hasNotChanged]:
      t.competencyManagement.training.sync.unavailableReason.hasNotChangedText,
  }[unavailableReason];
};
