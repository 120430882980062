import React from "react";
import { Route } from "react-router-dom";
import routes from "routes/routes";
import {
  getPathToAssessmentToBeSignedDetailsObjectives,
  getPathToAssessmentToBeSignedDetailsOverview,
} from "module/assessment/helper/getPath";
import AssessmentDetailsNavigation from "module/assessment/enrichedComponent/detailsNavigation/AssessmentDetailsNavigation";

interface Props {
  assessmentId: string;
}

const AssessmentToBeSignedDetails: React.FC<Props> = ({ assessmentId }) => {
  return (
    <>
      <AssessmentDetailsNavigation
        {...{
          listPath: routes.toBeSigned.path,
          overviewPath:
            getPathToAssessmentToBeSignedDetailsOverview(assessmentId),
          objectivesPath:
            getPathToAssessmentToBeSignedDetailsObjectives(assessmentId),
        }}
      />
      <Route
        {...{
          key: routes.assessmentToBeSignedDetailsOverview.path,
          path: routes.assessmentToBeSignedDetailsOverview.path,
          render: () => (
            <routes.assessmentToBeSignedDetailsOverview.mainComponent />
          ),
        }}
      />
      <Route
        {...{
          key: routes.assessmentToBeSignedDetailsObjectives.path,
          path: routes.assessmentToBeSignedDetailsObjectives.path,
          render: () => (
            <routes.assessmentToBeSignedDetailsObjectives.mainComponent />
          ),
        }}
      />
    </>
  );
};

export default React.memo(AssessmentToBeSignedDetails);
