import React from "react";
import { EntitySignContextInterface } from "module/entity/context/sign/EntitySignContext";

export type MaintenanceTaskSignContextInterface = EntitySignContextInterface;

const MaintenanceTaskSignContext = React.createContext(
  <MaintenanceTaskSignContextInterface>{}
);

export default MaintenanceTaskSignContext;
