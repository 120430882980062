import React from "react";
import { toastWarning } from "layout/toast/helper";
import { EntityIdentifier } from "module/entity/types";
import _ from "lodash";
import { ValidationErrorsInterface } from "api/types";

export const displayValidationErrors = (
  validationErrors: ValidationErrorsInterface
): void => {
  Array.from(validationErrors.values()).map(
    // eslint-disable-next-line array-callback-return
    (singleEntityValidationError) => {
      toastWarning(
        Object.values(singleEntityValidationError).map((message) => (
          <div>{message}</div>
        ))
      );
    }
  );
};

const compareEntityIdentifiers = (
  entityIdentifier1: EntityIdentifier,
  entityIdentifier2: EntityIdentifier
): boolean =>
  entityIdentifier1.id === entityIdentifier2.id &&
  entityIdentifier1.type === entityIdentifier2.type;

const getInvalidEntityIdentifiers = (
  validationErrors: ValidationErrorsInterface | undefined
): EntityIdentifier[] =>
  validationErrors == null ? [] : Array.from(validationErrors.keys());

export const getValidEntityIdentifiers = (
  keys: EntityIdentifier[],
  validationErrors: ValidationErrorsInterface | undefined
): EntityIdentifier[] => {
  if (validationErrors == null) {
    return keys;
  }
  return _.differenceWith(
    keys,
    getInvalidEntityIdentifiers(validationErrors),
    compareEntityIdentifiers
  );
};
