import React, { useMemo, useCallback } from "react";
import { useAppSelector } from "redux/hooks";
import { selectStockArticles } from "module/auth/redux/authSlice";
import AutocompleteField from "component/autocompleteField/AutocompleteField";
import t from "module/translations";

interface Props {
  value?: number;
  update: (newValue?: number) => void;
  errors?: string[];
}

const StockArticleSelectContainer: React.FC<Props> = ({
  value,
  update,
  errors,
}) => {
  const stockArticles = useAppSelector(selectStockArticles);

  const placeholder = t.pleaseSelectText;
  const selected = useMemo(
    () => (value ? stockArticles[value] : undefined),
    [stockArticles, value]
  );

  const onChange = useCallback(
    (newValue: unknown) => {
      update(newValue as number);
    },
    [update]
  );

  return (
    <AutocompleteField
      {...{
        placeholder,
        errors,
        selected,
        options: Object.values(stockArticles),
        onChange,
      }}
    />
  );
};

export default React.memo(StockArticleSelectContainer);
