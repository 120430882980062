import {
  AssessmentObjectiveToSyncInterface,
  AssessmentToSyncInterface,
  MyAssessmentInterface,
  MyAssessmentObjectiveInterface,
} from "module/assessment/types";
import { EntityType } from "module/entity/types";

const mapObjectiveToSync = (
  myObjective: MyAssessmentObjectiveInterface
): AssessmentObjectiveToSyncInterface => ({
  identifier: {
    type: myObjective.entityIdentifier.type as EntityType.assessmentObjective,
    id: myObjective.entityIdentifier.backendId,
    frontendId: myObjective.entityIdentifier.id,
  },
  data: {
    ...myObjective.data,
    id: myObjective.data.backendId,
  },
  raw: myObjective.raw,
});

export const mapAssessmentToSync = (
  assessment: MyAssessmentInterface,
  objectives: MyAssessmentObjectiveInterface[]
): AssessmentToSyncInterface => {
  return {
    identifier: {
      type: assessment.entityIdentifier.type as EntityType.assessment,
      id: assessment.entityIdentifier.backendId ?? null,
      frontendId: assessment.entityIdentifier.id,
    },
    data: {
      ...assessment.data,
      id: assessment.data.backendId,
      objectives: objectives.map(mapObjectiveToSync),
    },
    raw: assessment.raw,
  };
};
