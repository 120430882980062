import {
  ApiMaintenanceTaskInterface,
  ApiMaintenanceTaskPersonnelAssignmentExternalStaffInterface,
} from "api/maintenanceTask/types";
import {
  MaintenanceTaskPersonnelAssignmentExternalStaffInterface,
  MyMaintenanceTaskPersonnelAssignmentExternalStaffInterface,
} from "module/maintenanceTaskPersonnelAssignment/externalStaff/types";
import { EntityType } from "module/entity/types";

export const mapApiMaintenanceTaskPersonnelAssignmentExternalStaffToApp = (
  rawMaintenanceTask: ApiMaintenanceTaskInterface,
  raw: ApiMaintenanceTaskPersonnelAssignmentExternalStaffInterface
): MyMaintenanceTaskPersonnelAssignmentExternalStaffInterface => {
  const data: MaintenanceTaskPersonnelAssignmentExternalStaffInterface = {
    ...raw,
    id: `${raw.id}`,
    backendId: raw.id,
    maintenanceTaskId: `${rawMaintenanceTask.id}`,
    name: raw.externalStaff.name,
    company: raw.externalStaff.company,
    relationshipIdentifiers: [],
    relationships: undefined,
  };

  return {
    entityIdentifier: {
      type: EntityType.maintenanceTaskPersonnelAssignmentExternalStaff,
      id: `${raw.id}`,
      backendId: raw.id,
    },
    data,
    dataWithoutChanges: data,
    raw,
    validationErrors: null,
    validationWarnings: null,
  };
};
