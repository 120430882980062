import React, { useContext, useCallback, useMemo } from "react";
import RadioGroupField from "component/radioGroupField/RadioGroupField";
import { RadioGroupOption } from "component/radioGroupField/type";
import { getMaintenanceTaskProcessStepCheckResultTranslation } from "module/maintenanceTaskProcessStep/helper/getMaintenanceTaskProcessStepCheckResultTranslation";
import {
  MaintenanceTaskProcessStepCheckResult,
  MaintenanceTaskProcessStepInterface,
} from "module/maintenanceTaskProcessStep/types";
import MaintenanceTaskProcessStepContext from "module/maintenanceTaskProcessStep/context/MaintenanceTaskProcessStepContext";
import MaintenanceTaskProcessStepChangeContext from "module/maintenanceTaskProcessStep/change/MaintenanceTaskProcessStepChangeContext";
import MaintenanceTaskProcessStepFieldMetaDataContext from "module/maintenanceTaskProcessStep/validation/fieldMetaData/MaintenanceTaskProcessStepFieldMetaDataContext";
import { getFieldMetaDataWithErrorHandling } from "module/fieldMetaData/getFieldMetaData";
import { MAINTENANCE_TASK_PROCESS_STEP_CHECK_RESULT_FIELD_NAME } from "module/maintenanceTaskProcessStep/constants";
import XLabelField from "component/labelField/XLabelField";
import {
  removeFromEntityRelationships,
  removeFromMyEntities,
} from "module/staff/redux/staffDataSlice";
import { useAppDispatch, useAppSelector } from "redux/hooks";
import { selectSystem, selectUser } from "module/auth/redux/authSlice";
import { EntityIdentifier, EntityType } from "module/entity/types";

const MaintenanceTaskProcessStepCheckResultFieldContainer: React.FC = () => {
  const { entity } = useContext(MaintenanceTaskProcessStepContext);
  const { change: changeHelper } = useContext(
    MaintenanceTaskProcessStepChangeContext
  );
  const { getFieldMetaData } = useContext(
    MaintenanceTaskProcessStepFieldMetaDataContext
  );

  const maintenanceTaskProcessStep =
    entity as MaintenanceTaskProcessStepInterface;

  const dispatch = useAppDispatch();
  const staffId = useAppSelector(selectUser).id;
  const systemShortName = useAppSelector(selectSystem).shortName;

  const { warnings, errors, isEditable } = getFieldMetaDataWithErrorHandling(
    () =>
      getFieldMetaData(MAINTENANCE_TASK_PROCESS_STEP_CHECK_RESULT_FIELD_NAME)
  );

  const change = useCallback(
    (rawCheckResult: unknown) => {
      const checkResult =
        rawCheckResult as MaintenanceTaskProcessStepCheckResult;
      let changes: Partial<MaintenanceTaskProcessStepInterface> = {
        checkResult,
      };

      if (checkResult === MaintenanceTaskProcessStepCheckResult.good_yes) {
        changes = { ...changes, remarks: "" };
      }

      if (
        checkResult !== MaintenanceTaskProcessStepCheckResult.good_no &&
        maintenanceTaskProcessStep.quickFix != null
      ) {
        const maintenanceTaskProcessStepEntityIdentifier: EntityIdentifier = {
          id: maintenanceTaskProcessStep.id,
          type: EntityType.maintenanceTaskProcessStep,
        };

        changes = { ...changes, quickFix: null };
        dispatch(
          removeFromEntityRelationships({
            staffId,
            systemShortName,
            baseEntityIdentifier: maintenanceTaskProcessStepEntityIdentifier,
            entityIdentifierToRemove: maintenanceTaskProcessStep.quickFix,
          })
        );
        dispatch(
          removeFromMyEntities({
            staffId,
            systemShortName,
            entityIdentifiers: [maintenanceTaskProcessStep.quickFix],
          })
        );
      }
      changeHelper(changes);
    },
    [
      changeHelper,
      dispatch,
      maintenanceTaskProcessStep.id,
      maintenanceTaskProcessStep.quickFix,
      staffId,
      systemShortName,
    ]
  );

  const options: RadioGroupOption[] = useMemo(
    () => [
      {
        value: MaintenanceTaskProcessStepCheckResult.good_yes,
        label: getMaintenanceTaskProcessStepCheckResultTranslation(
          MaintenanceTaskProcessStepCheckResult.good_yes
        ),
      },
      {
        value: MaintenanceTaskProcessStepCheckResult.good_no,
        label: getMaintenanceTaskProcessStepCheckResultTranslation(
          MaintenanceTaskProcessStepCheckResult.good_no
        ),
      },
      {
        value: MaintenanceTaskProcessStepCheckResult.not_set,
        label: getMaintenanceTaskProcessStepCheckResultTranslation(
          MaintenanceTaskProcessStepCheckResult.not_set
        ),
      },
    ],
    []
  );

  const selectedOption = useMemo(
    () =>
      maintenanceTaskProcessStep.checkResult == null
        ? undefined
        : (options.find(
            (option) => option.value === maintenanceTaskProcessStep.checkResult
          ) as RadioGroupOption),
    [maintenanceTaskProcessStep.checkResult, options]
  );

  if (
    isEditable &&
    maintenanceTaskProcessStep.measureValueMin == null &&
    maintenanceTaskProcessStep.measureValueMax == null
  ) {
    return <RadioGroupField {...{ options, selectedOption, change }} />;
  }
  return (
    <XLabelField
      {...{
        errors,
        warnings,
        label: getMaintenanceTaskProcessStepCheckResultTranslation(
          maintenanceTaskProcessStep.checkResult
        ),
      }}
    />
  );
};
export default React.memo(MaintenanceTaskProcessStepCheckResultFieldContainer);
