import React, { useContext, useMemo } from "react";
import { EntityIdentifier } from "module/entity/types";
import {
  AssessmentInterface,
  AssessmentObjectiveInterface,
} from "module/assessment/types";
import AssessmentContext from "module/assessment/context/AssessmentContext";
import AssessmentObjectiveContext from "module/assessmentObjective/context/AssessmentObjectiveContext";
import EntityContextProvider from "module/entity/context/entity/EntityContextProvider";

interface Props {
  entityIdentifier: EntityIdentifier;
  children: React.ReactNode;
}

const AssessmentObjectiveContextProvider: React.FC<Props> = ({
  entityIdentifier,
  children,
}) => {
  const { entity } = useContext(AssessmentContext);

  const assessment = entity as AssessmentInterface;

  const objective = useMemo(
    () =>
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      assessment.relationships.get(entityIdentifier)
        .data as AssessmentObjectiveInterface,
    [assessment.relationships, entityIdentifier]
  );

  return (
    <EntityContextProvider
      {...{ entity: objective, context: AssessmentObjectiveContext }}
    >
      {children}
    </EntityContextProvider>
  );
};

export default AssessmentObjectiveContextProvider;
