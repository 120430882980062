import React, { useContext } from "react";
import TrainingContext from "module/training/context/TrainingContext";
import { TrainingInterface } from "module/training/types";
import TrainingStateLabelField from "module/training/enrichedComponent/stateField/TrainingStateLabelField";

const TrainingStateLabelFieldContainer: React.FC = () => {
  const { entity } = useContext(TrainingContext);
  const { state } = entity as TrainingInterface;

  return <TrainingStateLabelField {...{ state }} />;
};

export default React.memo(TrainingStateLabelFieldContainer);
