import React from "react";
import { ValidatorInterface } from "module/validation/types";

interface Props {
  validators: ValidatorInterface[];
}

const ValidationMessage: React.FC<Props> = ({ validators }) => {
  return (
    <>{validators.map((validator: { message: string }) => validator.message)}</>
  );
};

export default React.memo(ValidationMessage);
