import { useCallback } from "react";
import { EntityFactory } from "module/entity/useEntityAbstractFactory";
import {
  getPathToTrainingDetails,
  getPathToTrainingGroupDetails,
} from "module/training/helper/getPath";
import useAreTrainingsSimilar from "module/training/similar/useAreTrainingsSimilar";
import useTrainingIsSyncAvailable from "module/training/sync/useTrainingIsSyncAvailable";
import useTrainingSyncHelper from "module/training/sync/useTrainingSyncHelper";
import useMyTrainingReadableIdentifier from "module/training/readableIdentifier/useMyTrainingReadableIdentifier";
import t from "module/translations";
import { getTrainingSyncUnavailableReasonTranslation } from "module/training/helper/getTrainingSyncUnavailableReasonTranslation";
import { TrainingSyncUnavailableReason } from "module/training/types";

const useTrainingFactory = (): EntityFactory => {
  const { areTrainingsSimilar } = useAreTrainingsSimilar();
  const { isTrainingSyncAvailable } = useTrainingIsSyncAvailable();
  const { geTrainingMappedToSync } = useTrainingSyncHelper();
  const { getReadableIdentifier } = useMyTrainingReadableIdentifier();

  const getSyncUnavailableReasonText = useCallback(
    (reason: string): string =>
      getTrainingSyncUnavailableReasonTranslation(
        reason as TrainingSyncUnavailableReason
      ),
    []
  );

  return {
    getPathToMyDetails: getPathToTrainingDetails,
    getPathToMyGroupedDetails: getPathToTrainingGroupDetails,
    areSimilar: areTrainingsSimilar,
    isSyncAvailable: isTrainingSyncAvailable,
    getMappedToSync: geTrainingMappedToSync,
    getReadableIdentifier,
    readableType: t.competencyManagement.trainingText,
    getSyncUnavailableReasonText,
  };
};

export default useTrainingFactory;
