import { configureStore } from "@reduxjs/toolkit";
import {
  reducer as deviceReducer,
  sliceKey as deviceSliceKey,
} from "module/device/redux/deviceSlice";
import { reducer as authReducer } from "module/auth/redux/authSlice";
import { sliceKey as authSliceKey } from "module/auth/constants";

import {
  reducer as staffDataReducer,
  sliceKey as staffDataSliceKey,
} from "module/staff/redux/staffDataSlice";
import { persistState } from "module/storage/storage";

const store = configureStore({
  reducer: {
    [deviceSliceKey]: deviceReducer,
    [authSliceKey]: authReducer,
    [staffDataSliceKey]: staffDataReducer,
  },
});

store.subscribe(() => {
  persistState(store.getState());
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export { store };
