import { isMaintenanceTaskProcessStepCheckedResultAvailable } from "module/maintenanceTaskProcessStep/helper/isMaintenanceTaskProcessStepCheckedResultAvailable";
import {
  MaintenanceTaskProcessStepCheckResult,
  MaintenanceTaskProcessStepInterface,
} from "module/maintenanceTaskProcessStep/types";
import { MaintenanceTaskInterface } from "module/maintenanceTask/types";

interface Props {
  isMaintenanceTaskOpen: boolean;
  isMaintenanceTaskStateScheduledOrLower: boolean;
  maintenanceTask: MaintenanceTaskInterface;
  maintenanceTaskProcessStep: MaintenanceTaskProcessStepInterface;
}

export const canRemoveCorrectiveToMaintenanceTaskProcessStep = ({
  isMaintenanceTaskOpen,
  isMaintenanceTaskStateScheduledOrLower,
  maintenanceTask,
  maintenanceTaskProcessStep,
}: Props): boolean =>
  isMaintenanceTaskOpen &&
  isMaintenanceTaskStateScheduledOrLower &&
  isMaintenanceTaskProcessStepCheckedResultAvailable(maintenanceTask) &&
  maintenanceTaskProcessStep.checkResult ===
    MaintenanceTaskProcessStepCheckResult.good_no &&
  maintenanceTaskProcessStep.correctiveMaintenanceTask != null;
