import React, { useContext, useMemo } from "react";
import { EntityIdentifier } from "module/entity/types";
import MaintenanceTaskProcessStepContext from "module/maintenanceTaskProcessStep/context/MaintenanceTaskProcessStepContext";
import { MaintenanceTaskInterface } from "module/maintenanceTask/types";
import { MaintenanceTaskProcessStepInterface } from "module/maintenanceTaskProcessStep/types";
import EntityContextProvider from "module/entity/context/entity/EntityContextProvider";
import MaintenanceTaskContext from "module/maintenanceTask/context/MaintenanceTaskContext";

interface Props {
  entityIdentifier: EntityIdentifier;
  children: React.ReactNode;
}

const MaintenanceTaskProcessStepContextProvider: React.FC<Props> = ({
  entityIdentifier,
  children,
}) => {
  const { entity } = useContext(MaintenanceTaskContext);

  const maintenanceTask = entity as MaintenanceTaskInterface;

  const processStep = useMemo(
    () =>
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      maintenanceTask.relationships.get(entityIdentifier)
        .data as MaintenanceTaskProcessStepInterface,
    [entityIdentifier, maintenanceTask.relationships]
  );

  return (
    <EntityContextProvider
      {...{
        entity: processStep,
        context: MaintenanceTaskProcessStepContext,
        key: processStep.id,
      }}
    >
      {children}
    </EntityContextProvider>
  );
};

export default MaintenanceTaskProcessStepContextProvider;
