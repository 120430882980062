import React, { useContext } from "react";
import MyMaintenanceTaskDetails from "pages/myMaintenanceTaskDetails/MyMaintenanceTaskDetails";
import MaintenanceTaskContext from "module/maintenanceTask/context/MaintenanceTaskContext";
import { MaintenanceTaskInterface } from "module/maintenanceTask/types";
import MaintenanceTaskValidationResultContext from "module/maintenanceTask/validation/MaintenanceTaskValidationResultContext";
import MaintenanceTaskOpenCloseContext from "module/maintenanceTask/openClose/context/MaintenanceTaskOpenCloseContext";
import MaintenanceTaskStateContext from "module/maintenanceTask/state/context/MaintenanceTaskStateContext";
import { MaintenanceTaskDefinitionType } from "pages/maintenanceTaskList/types";
import { MyMaintenanceTaskProcessStepInterface } from "module/maintenanceTaskProcessStep/types";
import MyMaintenanceTaskDetailsForServiceRequest from "./MyMaintenanceTaskDetailsForServiceRequest";
import { useAppSelector } from "../../redux/hooks";
import selectEntity from "../../module/staff/redux/selector/selectEntity";
import { EntityType } from "../../module/entity/types";

const MyMaintenanceTaskDetailsContainer: React.FC = () => {
  const { entity } = useContext(MaintenanceTaskContext);
  const { hasError } = useContext(MaintenanceTaskValidationResultContext);
  const { open, close } = useContext(MaintenanceTaskOpenCloseContext);
  const { isStateEditable } = useContext(MaintenanceTaskStateContext);

  const maintenanceTask = entity as MaintenanceTaskInterface;
  const { originalMaintenanceTaskProcessStepId } = maintenanceTask;

  const selectEntityHelper = useAppSelector(selectEntity);
  const originalMaintenanceTaskProcessStep =
    originalMaintenanceTaskProcessStepId
      ? (
          selectEntityHelper({
            id: originalMaintenanceTaskProcessStepId,
            type: EntityType.maintenanceTaskProcessStep,
          }) as MyMaintenanceTaskProcessStepInterface
        )?.data
      : null;

  if (maintenanceTask.source === "serviceRequest") {
    return (
      <MyMaintenanceTaskDetailsForServiceRequest
        {...{
          maintenanceTaskId: maintenanceTask.id,
          hasError,
          isStateEditable,
        }}
      />
    );
  }

  return (
    <MyMaintenanceTaskDetails
      {...{
        maintenanceTaskId: maintenanceTask.id,
        maintenanceTaskDefinitionType:
          maintenanceTask.definitionType ??
          MaintenanceTaskDefinitionType.system,
        hasError,
        open,
        close,
        isStateEditable,
        originalMaintenanceTaskProcessStep,
      }}
    />
  );
};

export default React.memo(MyMaintenanceTaskDetailsContainer);
