import { MaintenanceTaskInterface } from "module/maintenanceTask/types";
import { MaintenanceTaskAssistantExternalStaffToSyncInterface } from "module/maintenanceTaskAssisstant/types";

export const mapMaintenanceTaskAssistantExternalStaffToSync = (
  maintenanceTask: MaintenanceTaskInterface
): MaintenanceTaskAssistantExternalStaffToSyncInterface => {
  return {
    externalStaffCompany:
      maintenanceTask.assistantExternalStaffCompany as string,
    externalStaffName: maintenanceTask.assistantExternalStaffName as string,
  };
};
