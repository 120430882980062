import { EntityType } from "module/entity/types";
import {
  ApiAttachmentInterface,
  AttachmentInterface,
  MyAttachmentInterface,
} from "module/attachment/types";

export const mapApiAttachmentToApp = (
  raw: ApiAttachmentInterface
): MyAttachmentInterface => {
  // const base64 = await convertFileIntoBase64(raw.path as string, raw.mimeType);
  const data: AttachmentInterface = {
    ...raw,
    id: `${raw.id}`,
    backendId: raw.id,
    relationshipIdentifiers: [],
    relationships: undefined,
    path: raw.path,
  };

  return {
    entityIdentifier: {
      type: EntityType.attachment,
      id: `${raw.id}`,
      backendId: raw.id,
    },
    data,
    dataWithoutChanges: data,
    raw,
    validationErrors: null,
    validationWarnings: null,
  };
};
