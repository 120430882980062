import React from "react";
import { EntityChangeContextInterface } from "module/entity/context/change/EntityChangeContext";

export type MaintenanceTaskPartChangeContextInterface =
  EntityChangeContextInterface;

const MaintenanceTaskPartChangeContext = React.createContext(
  <MaintenanceTaskPartChangeContextInterface>{}
);

export default MaintenanceTaskPartChangeContext;
