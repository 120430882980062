import { RoutesType } from "routes/types";
import DashboardPage from "pages/dashboard/DashboardPage";
import t from "module/translations";
import AppInfoContainer from "pages/appInfo/AppInfoContainer";
import CompetencyManagementListPage from "pages/competencyManagementList/CompetencyManagementListPage";
import MyCompetencyManagementListContainer from "pages/myCompetencyManagementList/MyCompetencyManagementListContainer";
import MyTrainingCustomDetailsPage from "pages/myTrainingCustomDetails/MyTrainingCustomDetailsPage";
import MyTrainingCustomDetailsOverviewContainer from "pages/myTrainingCustomDetails/overview/MyTrainingCustomDetailsOverviewContainer";
import MyTrainingCustomDetailsAssigneesContainer from "pages/myTrainingCustomDetails/assignees/MyTrainingCustomDetailsAssigneesContainer";
import MyAssessmentDetailsPage from "pages/myAssessmentDetails/MyAssessmentDetailsPage";
import MyAssessmentDetailsOverviewContainer from "pages/myAssessmentDetails/overview/MyAssessmentDetailsOverviewContainer";
import MyAssessmentDetailsObjectivesContainer from "pages/myAssessmentDetails/objectives/MyAssessmentDetailsObjectivesContainer";
import MyOpenCompetencyManagementDetailsPage from "pages/myOpenCompetencyManagementDetails/MyOpenCompetencyManagementDetailsPage";
import AssessmentToBeSignedDetailsObjectivesContainer from "pages/assessmentToBeSignedDetails/objectives/AssessmentToBeSignedDetailsObjectivesContainer";
import AssessmentToBeSignedDetailsOverview from "pages/assessmentToBeSignedDetails/overview/AssessmentToBeSignedDetailsOverview";
import AssessmentToBeSignedDetailsPage from "pages/assessmentToBeSignedDetails/AssessmentToBeSignedDetailsPage";
import TrainingToBeSignedDetailsOverview from "pages/trainingToBeSignedDetails/overview/TrainingToBeSignedDetailsOverview";
import TrainingToBeSignedDetailsSessionsContainer from "pages/trainingToBeSignedDetails/sessions/TrainingToBeSignedDetailsSessionsContainer";
import TrainingToBeSignedDetailsPage from "pages/trainingToBeSignedDetails/TrainingToBeSignedDetailsPage";
import MyTrainingGroupDetailsOverviewContainer from "pages/myTrainingGroupDetails/overview/MyTrainingGroupDetailsOverviewContainer";
import MyTrainingGroupDetailsPage from "pages/myTrainingGroupDetails/MyTrainingGroupDetailsPage";
import MyTrainingGroupDetailsSessionsContainer from "pages/myTrainingGroupDetails/sessions/MyTrainingGroupDetailsSessionsContainer";
import ToBeSignedListContainer from "pages/toBeSignedList/ToBeSignedListContainer";
import MaintenanceTaskListPage from "pages/maintenanceTaskList/MaintenanceTaskListPage";
import MySafetyMaintenanceTaskListPage from "pages/myMaintenanceTaskList/MySafetyMaintenanceTaskListPage";
import MyOpenMaintenanceTaskDetailsPage from "pages/myOpenMaintenanceTaskDetails/MyOpenMaintenanceTaskDetailsPage";
import MyMaintenanceTaskDetailsPage from "pages/myMaintenanceTaskDetails/MyMaintenanceTaskDetailsPage";
import MyMaintenanceTaskDetailsOverviewContainer from "pages/myMaintenanceTaskDetails/overview/MyMaintenanceTaskDetailsOverviewContainer";
import MyMaintenanceTaskProcessStepListContainer from "pages/myMaintenanceTaskDetails/processStepList/MyMaintenanceTaskProcessStepListContainer";
import MaintenanceTaskServiceRequestPage from "pages/maintenanceTaskServiceRequest/MaintenanceTaskServiceRequestPage";
import MyMaintenanceTaskPartListContainer from "pages/myMaintenanceTaskDetails/partList/MyMaintenanceTaskPartListContainer";
import MyMaintenanceTaskDetailsPersonnelAssignments from "pages/myMaintenanceTaskDetails/personnelAssignment/MyMaintenanceTaskDetailsPersonnelAssignments";
import MyTrainingDetailsSessionsContainer from "pages/myTrainingDetails/sessions/MyTrainingDetailsSessionsContainer";
import MyTrainingDetailsOverviewContainer from "pages/myTrainingDetails/overview/MyTrainingDetailsOverviewContainer";
import MyTrainingDetailsPage from "pages/myTrainingDetails/MyTrainingDetailsPage";
import TrainingCustomToBeSignedDetailsPage from "pages/trainingCustomToBeSignedDetails/TrainingCustomToBeSignedDetailsPage";
import TrainingCustomToBeSignedDetailsAssigneesContainer from "pages/trainingCustomToBeSignedDetails/assignees/TrainingCustomToBeSignedDetailsAssigneesContainer";
import TrainingCustomToBeSignedDetailsOverview from "pages/trainingCustomToBeSignedDetails/overview/TrainingCustomToBeSignedDetailsOverview";
import MyMaintenanceTaskProcessStepDetailsContainer from "pages/myMaintenanceTaskDetails/processStepDetails/MyMaintenanceTaskProcessStepDetailsContainer";
import SafetyMaintenanceTaskListPage from "pages/maintenanceTaskList/SafetyMaintenanceTaskListPage";
import MyOpenSafetyMaintenanceTaskDetailsPage from "pages/myOpenSafetyMaintenanceTaskDetails/MyOpenSafetyMaintenanceTaskDetailsPage";
import MyMaintenanceTaskListPage from "pages/myMaintenanceTaskList/MyMaintenanceTaskListPage";
import MyMaintenanceTaskDetailsHeaderTitle from "pages/myMaintenanceTaskDetails/MyMaintenanceTaskHeaderTitle";
import MaintenanceTaskToBeSignedDetailsPage from "pages/maintenanceTaskToBeSignedDetails/MaintenanceTaskToBeSignedDetailsPage";
import MaintenanceTaskToBeSignedDetailsOverviewContainer from "pages/maintenanceTaskToBeSignedDetails/overview/MaintenanceTaskToBeSignedDetailsOverviewContainer";
import MaintenanceTaskToBeSignedProcessStepListContainer from "pages/maintenanceTaskToBeSignedDetails/processStepList/MaintenanceTaskToBeSignedProcessStepListContainer";
import MaintenanceTaskToBeSignedPartListContainer from "pages/maintenanceTaskToBeSignedDetails/partList/MaintenanceTaskToBeSignedPartListContainer";
import MaintenanceTaskToBeSignedDetailsPersonnelAssignments from "pages/maintenanceTaskToBeSignedDetails/personnelAssignment/MaintenanceTaskToBeSignedDetailsPersonnelAssignments";
import MaintenanceTaskToBeSignedProcessStepDetailsContainer from "pages/maintenanceTaskToBeSignedDetails/processStepDetails/MaintenanceTaskToBeSignedProcessStepDetailsContainer";
import MaintenanceTaskFromTemplatePage from "pages/maintenanceTaskFromTemplate/MaintenanceTaskFromTemplatePage";
import InventoryCheckListPage from "../pages/inventoryCheckList/InventoryCheckListPage";
import MyInventoryCheckListPage from "../pages/myInventoryCheckList/MyInventoryCheckListPage";
import MyInventoryCheckDetailsPage from "../pages/myInventoryCheckDetails/MyInventoryCheckDetailsPage";
import MyInventoryCheckDetailsOverviewContainer from "../pages/myInventoryCheckDetails/overview/MyInventoryCheckDetailsOverviewContainer";
import MyInventoryCheckArticleListContainer from "../pages/myInventoryCheckDetails/articleList/MyInventoryCheckArticleListContainer";
import MyOpenInventoryCheckDetailsPage from "../pages/myOpenInventoryCheckDetails/MyOpenInventoryCheckDetailsPage";
import InventoryListPage from "../pages/inventory/InventoryListPage";

const routes: RoutesType = {
  dashboard: {
    path: "/",
    mainComponent: DashboardPage,
    exact: true,
  },
  maintenanceTasks: {
    path: "/maintenance-tasks",
    mainComponent: MaintenanceTaskListPage,
    title: t.routes.maintenanceTasksOnline,
    headerTitle: t.maintenanceTask.overviewHeaderText,
    exact: true,
  },
  myMaintenanceTasks: {
    path: "/my/maintenance-tasks",
    mainComponent: MyMaintenanceTaskListPage,
    title: t.routes.maintenanceTasksOffline,
    headerTitle: t.maintenanceTask.downloadedHeaderText,
    exact: true,
  },
  createCorrectiveMaintenanceTask: {
    path: "/my/maintenance-tasks/create-corrective",
    mainComponent: MaintenanceTaskServiceRequestPage,
    title: t.routes.createCorrectiveMaintenanceTask,
    exact: true,
  },
  createMaintenanceTaskFromTemplate: {
    path: "/my/maintenance-tasks/create-from-template",
    mainComponent: MaintenanceTaskFromTemplatePage,
    title: t.routes.createMaintenanceTaskFromTemplate,
    exact: true,
  },
  myMaintenanceTaskDetails: {
    path: `/my/maintenance-tasks/:maintenanceTaskId`,
    mainComponent: MyMaintenanceTaskDetailsPage,
    exact: false,
  },
  myMaintenanceTaskDetailsOverview: {
    path: `/my/maintenance-tasks/:maintenanceTaskId/overview`,
    mainComponent: MyMaintenanceTaskDetailsOverviewContainer,
    title: t.routes.maintenanceTaskDetailsOverview,
    customHeaderTitle: MyMaintenanceTaskDetailsHeaderTitle,
    exact: true,
  },
  myMaintenanceTaskDetailsProcessSteps: {
    path: `/my/maintenance-tasks/:maintenanceTaskId/process-steps`,
    mainComponent: MyMaintenanceTaskProcessStepListContainer,
    title: t.routes.maintenanceTaskDetailsProcessSteps,
    customHeaderTitle: MyMaintenanceTaskDetailsHeaderTitle,
    exact: true,
  },
  myMaintenanceTaskProcessStepsDetails: {
    path: `/my/maintenance-tasks/:maintenanceTaskId/process-steps/:processStepId`,
    mainComponent: MyMaintenanceTaskProcessStepDetailsContainer,
    title: t.routes.maintenanceTaskProcessStepsDetails,
    headerTitle: t.maintenanceTaskProcessStep.downloadedHeaderText,
    exact: true,
  },
  myMaintenanceTaskDetailsParts: {
    path: `/my/maintenance-tasks/:maintenanceTaskId/parts`,
    mainComponent: MyMaintenanceTaskPartListContainer,
    title: t.routes.myMaintenanceTaskDetailsParts,
    customHeaderTitle: MyMaintenanceTaskDetailsHeaderTitle,
    exact: true,
  },
  myMaintenanceTaskDetailsPersonnelAssignments: {
    path: `/my/maintenance-tasks/:maintenanceTaskId/personal-assignments`,
    mainComponent: MyMaintenanceTaskDetailsPersonnelAssignments,
    title: t.routes.maintenanceTaskDetailsPersonnelAssignments,
    customHeaderTitle: MyMaintenanceTaskDetailsHeaderTitle,
    exact: true,
  },
  myOpenMaintenanceTaskDetails: {
    path: `/my/opened-maintenance-task`,
    mainComponent: MyOpenMaintenanceTaskDetailsPage,
    title: t.routes.myOpenMaintenanceTask,
    exact: true,
  },
  inventoryChecks: {
    path: "/inventory-checks",
    mainComponent: InventoryCheckListPage,
    title: t.routes.inventoryChecksOnline,
    headerTitle: t.inventoryCheck.overviewHeaderText,
    exact: true,
  },
  myInventoryChecks: {
    path: "/my/inventory-checks",
    mainComponent: MyInventoryCheckListPage,
    title: t.routes.inventoryChecksOffline,
    headerTitle: t.inventoryCheck.downloadedHeaderText,
    exact: true,
  },
  myInventoryCheckDetails: {
    path: `/my/inventory-checks/:inventoryCheckId`,
    mainComponent: MyInventoryCheckDetailsPage,
    exact: false,
  },
  myInventoryCheckDetailsOverview: {
    path: `/my/inventory-checks/:inventoryCheckId/overview`,
    mainComponent: MyInventoryCheckDetailsOverviewContainer,
    title: t.routes.maintenanceTaskDetailsOverview,
    customHeaderTitle: MyMaintenanceTaskDetailsHeaderTitle,
    exact: true,
  },
  myInventoryCheckDetailsArticles: {
    path: `/my/inventory-checks/:inventoryCheckId/articles`,
    mainComponent: MyInventoryCheckArticleListContainer,
    title: t.routes.inventoryCheckDetailsArticles,
    customHeaderTitle: MyMaintenanceTaskDetailsHeaderTitle,
    exact: true,
  },
  myOpenInventoryCheckDetails: {
    path: `/my/opened-inventory-check`,
    mainComponent: MyOpenInventoryCheckDetailsPage,
    title: t.routes.myOpenInventoryCheck,
    exact: true,
  },
  inventory: {
    path: "/inventory",
    mainComponent: InventoryListPage,
    title: t.routes.inventory,
    headerTitle: t.routes.inventory,
    exact: true,
  },
  safetyMaintenanceTasks: {
    path: "/safety",
    mainComponent: SafetyMaintenanceTaskListPage,
    title: t.routes.safetyMaintenanceTasksOnline,
    headerTitle: t.safetyMaintenanceTask.overviewHeaderText,
    exact: true,
  },
  mySafetyMaintenanceTasks: {
    path: "/my/safety",
    mainComponent: MySafetyMaintenanceTaskListPage,
    title: t.routes.safetyMaintenanceTasksOffline,
    headerTitle: t.safetyMaintenanceTask.downloadedHeaderText,
    exact: true,
  },
  myOpenSafetyMaintenanceTaskDetails: {
    path: `/my/opened-safety`,
    mainComponent: MyOpenSafetyMaintenanceTaskDetailsPage,
    title: t.routes.myOpenSafetyMaintenanceTask,
    exact: true,
  },
  appInfo: {
    path: `/app`,
    mainComponent: AppInfoContainer,
    exact: true,
  },
  competencyManagementOverview: {
    path: "/competency-management",
    mainComponent: CompetencyManagementListPage,
    title: t.routes.competencyManagementOverview,
    headerTitle: t.competencyManagement.overviewHeaderText,
    exact: true,
  },
  myCompetencyManagementResources: {
    path: "/my/competency-management",
    mainComponent: MyCompetencyManagementListContainer,
    title: t.routes.myCompetencyManagementTrainings,
    headerTitle: t.competencyManagement.downloadedHeaderText,
    exact: true,
  },
  myTrainingDetails: {
    path: `/my/competency-management/trainings/:trainingId`,
    mainComponent: MyTrainingDetailsPage,
    exact: false,
  },
  myTrainingDetailsOverview: {
    path: `/my/competency-management/trainings/:trainingId/overview`,
    mainComponent: MyTrainingDetailsOverviewContainer,
    title: t.competencyManagement.training.overviewTitleText,
    headerTitle: t.competencyManagement.training.overviewTitleText,
    exact: true,
  },
  myTrainingDetailsSessions: {
    path: `/my/competency-management/trainings/:trainingId/sessions`,
    mainComponent: MyTrainingDetailsSessionsContainer,
    title: t.competencyManagement.training.sessionsTitleText,
    headerTitle: t.competencyManagement.training.sessionsTitleText,
    exact: true,
  },
  myTrainingGroupDetails: {
    path: `/my/competency-management/trainings-group/:trainingsIds`,
    mainComponent: MyTrainingGroupDetailsPage,
    exact: false,
  },
  myTrainingGroupDetailsOverview: {
    path: `/my/competency-management/trainings-group/:trainingsIds/overview`,
    mainComponent: MyTrainingGroupDetailsOverviewContainer,
    title: t.competencyManagement.training.overviewTitleText,
    headerTitle: t.competencyManagement.training.overviewTitleText,
    exact: true,
  },
  myTrainingGroupDetailsSessions: {
    path: `/my/competency-management/trainings-group/:trainingsIds/sessions`,
    mainComponent: MyTrainingGroupDetailsSessionsContainer,
    title: t.competencyManagement.training.sessionsTitleText,
    headerTitle: t.competencyManagement.training.sessionsTitleText,
    exact: true,
  },
  myAssessmentDetails: {
    path: `/my/competency-management/assessments/:assessmentId`,
    mainComponent: MyAssessmentDetailsPage,
    exact: false,
  },
  myAssessmentDetailsOverview: {
    path: `/my/competency-management/assessments/:assessmentId/overview`,
    mainComponent: MyAssessmentDetailsOverviewContainer,
    title: t.competencyManagement.assessment.overviewTitleText,
    headerTitle: t.competencyManagement.assessment.overviewTitleText,
    exact: true,
  },
  myAssessmentDetailsObjectives: {
    path: `/my/competency-management/assessments/:assessmentId/objectives`,
    mainComponent: MyAssessmentDetailsObjectivesContainer,
    title: t.competencyManagement.assessment.objectivesTitleText,
    headerTitle: t.competencyManagement.assessment.objectivesTitleText,
    exact: true,
  },
  myTrainingCustomDetails: {
    path: `/my/competency-management/trainings-custom/:trainingCustomId`,
    mainComponent: MyTrainingCustomDetailsPage,
    exact: false,
  },
  myTrainingCustomDetailsOverview: {
    path: `/my/competency-management/trainings-custom/:trainingCustomId/overview`,
    mainComponent: MyTrainingCustomDetailsOverviewContainer,
    title: t.competencyManagement.trainingCustom.overviewTitleText,
    headerTitle: t.competencyManagement.trainingCustom.overviewTitleText,
    exact: true,
  },
  myTrainingCustomDetailsAssignees: {
    path: `/my/competency-management/trainings-custom/:trainingCustomId/assignees`,
    mainComponent: MyTrainingCustomDetailsAssigneesContainer,
    title: t.competencyManagement.trainingCustom.assigneesTitleText,
    headerTitle: t.competencyManagement.trainingCustom.assigneesTitleText,
    exact: true,
  },
  myOpenCompetencyManagementResource: {
    path: `/my/competency-management/open`,
    mainComponent: MyOpenCompetencyManagementDetailsPage,
    title: t.competencyManagement.openTitleText,
    exact: true,
  },
  toBeSigned: {
    path: `/to-be-signed`,
    mainComponent: ToBeSignedListContainer,
    title: t.toBeSignedText,
    exact: true,
  },
  trainingToBeSignedDetails: {
    path: `/to-be-signed/training/:trainingId`,
    mainComponent: TrainingToBeSignedDetailsPage,
    exact: false,
  },
  trainingToBeSignedDetailsOverview: {
    path: `/to-be-signed/training/:trainingId/overview`,
    mainComponent: TrainingToBeSignedDetailsOverview,
    title: t.competencyManagement.training.overviewTitleText,
    headerTitle: t.competencyManagement.training.toBeSignedText,
    exact: true,
  },
  trainingToBeSignedDetailsSessions: {
    path: `/to-be-signed/training/:trainingId/sessions`,
    mainComponent: TrainingToBeSignedDetailsSessionsContainer,
    title: t.competencyManagement.training.sessionsTitleText,
    headerTitle: t.competencyManagement.training.toBeSignedText,
    exact: true,
  },
  assessmentToBeSignedDetails: {
    path: `/to-be-signed/assessment/:assessmentId`,
    mainComponent: AssessmentToBeSignedDetailsPage,
    exact: false,
  },
  assessmentToBeSignedDetailsOverview: {
    path: `/to-be-signed/assessment/:assessmentId/overview`,
    mainComponent: AssessmentToBeSignedDetailsOverview,
    title: t.competencyManagement.assessment.overviewTitleText,
    headerTitle: t.competencyManagement.assessment.toBeSignedText,
    exact: true,
  },
  assessmentToBeSignedDetailsObjectives: {
    path: `/to-be-signed/assessment/:assessmentId/objectives`,
    mainComponent: AssessmentToBeSignedDetailsObjectivesContainer,
    title: t.competencyManagement.assessment.objectivesTitleText,
    headerTitle: t.competencyManagement.assessment.toBeSignedText,
    exact: true,
  },
  trainingCustomToBeSignedDetails: {
    path: `/to-be-signed/training-custom/:trainingCustomId`,
    mainComponent: TrainingCustomToBeSignedDetailsPage,
    exact: false,
  },
  trainingCustomToBeSignedDetailsOverview: {
    path: `/to-be-signed/training-custom/:trainingCustomId/overview`,
    mainComponent: TrainingCustomToBeSignedDetailsOverview,
    title: t.competencyManagement.trainingCustom.overviewTitleText,
    headerTitle: t.competencyManagement.training.toBeSignedText,
    exact: true,
  },
  trainingCustomToBeSignedDetailsAssignees: {
    path: `/to-be-signed/training-custom/:trainingCustomId/assignees`,
    mainComponent: TrainingCustomToBeSignedDetailsAssigneesContainer,
    title: t.competencyManagement.trainingCustom.assigneesTitleText,
    headerTitle: t.competencyManagement.training.toBeSignedText,
    exact: true,
  },
  maintenanceTaskToBeSignedDetails: {
    path: `/to-be-signed/maintenance-task/:maintenanceTaskId`,
    mainComponent: MaintenanceTaskToBeSignedDetailsPage,
    exact: false,
  },
  maintenanceTaskToBeSignedDetailsOverview: {
    path: `/to-be-signed/maintenance-task/:maintenanceTaskId/overview`,
    mainComponent: MaintenanceTaskToBeSignedDetailsOverviewContainer,
    title: t.routes.maintenanceTaskDetailsOverview,
    exact: true,
  },
  maintenanceTaskToBeSignedDetailsProcessSteps: {
    path: `/to-be-signed/maintenance-task/:maintenanceTaskId/process-steps`,
    mainComponent: MaintenanceTaskToBeSignedProcessStepListContainer,
    title: t.routes.maintenanceTaskDetailsProcessSteps,
    exact: true,
  },
  maintenanceTaskToBeSignedDetailsProcessStepsDetails: {
    path: `/to-be-signed/maintenance-task/:maintenanceTaskId/process-steps/:processStepId`,
    mainComponent: MaintenanceTaskToBeSignedProcessStepDetailsContainer,
    title: t.routes.maintenanceTaskProcessStepsDetails,
    headerTitle: t.maintenanceTaskProcessStep.downloadedHeaderText,
    exact: true,
  },
  maintenanceTaskToBeSignedDetailsParts: {
    path: `/to-be-signed/maintenance-task/:maintenanceTaskId/parts`,
    mainComponent: MaintenanceTaskToBeSignedPartListContainer,
    title: t.routes.myMaintenanceTaskDetailsParts,
    exact: true,
  },
  maintenanceTaskToBeSignedDetailsPersonnelAssignments: {
    path: `/to-be-signed/maintenance-task/:maintenanceTaskId/personal-assignments`,
    mainComponent: MaintenanceTaskToBeSignedDetailsPersonnelAssignments,
    title: t.routes.maintenanceTaskDetailsPersonnelAssignments,
    exact: true,
  },
};

export default routes;
