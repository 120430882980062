import React, { useContext } from "react";
import ToBeSignedListTableRowTraining from "pages/toBeSignedList/table/row/training/ToBeSignedListTableRowTraining";
import TrainingContext from "module/training/context/TrainingContext";
import { TrainingInterface } from "module/training/types";

const ToBeSignedListTableRowTrainingContainer: React.FC = () => {
  const { entity } = useContext(TrainingContext);

  const training = entity as TrainingInterface;

  return (
    <ToBeSignedListTableRowTraining
      {...{
        training,
      }}
    />
  );
};

export default React.memo(ToBeSignedListTableRowTrainingContainer);
