import React from "react";
import FormHelperText from "@mui/material/FormHelperText";
import XValidationMessage from "module/validation/XValidationMessage";

interface Props {
  warnings: string[];
}

const XValidationWarningMessage: React.FC<Props> = ({ warnings }) => {
  const hasWarning = Boolean(warnings.length);

  if (hasWarning) {
    return (
      <FormHelperText>
        <XValidationMessage messages={warnings} />
      </FormHelperText>
    );
  }
  return null;
};

export default React.memo(XValidationWarningMessage);
