import {
  AbstractMyEntityInterface,
  EntityChangedDate,
  EntityWithId,
  EntityWithRelationships,
} from "module/entity/types";
import { ApiInventoryCheckArticleInterface } from "api/inventoryCheck/types";

export enum InventoryCheckArticleHasChanged {
  yes = "yes",
  no = "no",
  notSet = "not_set",
}

export interface InventoryCheckArticleInterface
  extends EntityWithId,
    EntityWithRelationships,
    EntityChangedDate {
  inventoryCheckId: string;
  stockArticleId: number;
  oldAmount: number;
  hasChanged: InventoryCheckArticleHasChanged;
  newAmount?: number;
  description?: string;
}

export type MyInventoryCheckArticleInterface = AbstractMyEntityInterface<
  ApiInventoryCheckArticleInterface,
  InventoryCheckArticleInterface
>;

export interface InventoryCheckArticleToSyncInterface {
  id: number;
  changedDateTime?: string;
  description?: string;
  stockArticleId: number;
  oldAmount: number;
  newAmount?: number;
  hasChanged: InventoryCheckArticleHasChanged;
}
