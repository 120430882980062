import React from "react";
import MaintenanceTaskDetailsPersonnelAssignmentInternalStaffListTableRow from "module/maintenanceTaskPersonnelAssignment/internalStaff/enrichedComponent/table/MaintenanceTaskDetailsPersonnelAssignmentInternalStaffListTableRow";
import useMaintenanceTaskPersonnelAssignmentInternalStaffRemove from "module/maintenanceTaskPersonnelAssignment/internalStaff/remove/useMaintenanceTaskPersonnelAssignmentInternalStaffRemove";

const MyMaintenanceTaskPersonnelAssignmentListTableRowContainer: React.FC =
  () => {
    const { canRemove, remove } =
      useMaintenanceTaskPersonnelAssignmentInternalStaffRemove();

    return (
      <MaintenanceTaskDetailsPersonnelAssignmentInternalStaffListTableRow
        {...{ isActionAvailable: true, canRemove, remove }}
      />
    );
  };

export default React.memo(
  MyMaintenanceTaskPersonnelAssignmentListTableRowContainer
);
