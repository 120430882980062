import { IsBlockedInterface } from "module/block/types";
import {
  ApiTrainingCustomAssigneeInterface,
  ApiTrainingCustomInterface,
} from "api/competencyManagement/types";
import {
  AbstractMyEntityInterface,
  EntityChangedDate,
  EntityIdentifier,
  EntityType,
  EntityWithId,
  EntityWithRelationships,
} from "module/entity/types";

export interface TrainingCustomIsBlockedInterface extends IsBlockedInterface {
  isBlockedBecauseItIsCompleted: boolean;
}

export enum TrainingCustomState {
  scheduled = "scheduled",
  completed = "completed",
}

export enum TrainingCustomAssigneeType {
  internal = "internal",
  external = "external",
}

export interface TrainingCustomInterface
  extends EntityWithId,
    EntityWithRelationships,
    EntityChangedDate {
  name: string;
  reportingNumber?: string;
  description: string;
  scheduledDate: string;
  scheduledStartDate: string;
  completionDate?: string;
  remarks: string;
  state: TrainingCustomState;
  internalTrainerId?: number;
  externalTrainer?: string;
  assignees: EntityIdentifier[];
  isBlockedDetails: TrainingCustomIsBlockedInterface;
}

export type MyTrainingCustomInterface = AbstractMyEntityInterface<
  ApiTrainingCustomInterface,
  TrainingCustomInterface
>;

export interface TrainingCustomAssigneeInterface
  extends EntityWithId,
    EntityWithRelationships,
    EntityChangedDate {
  trainingCustomId: string;
  staffId?: number | null;
  internalExternalAssignee: TrainingCustomAssigneeType;
  externalAssigneeName?: string | null;
  achieved: boolean;
  remarks?: string;
}

export type MyTrainingCustomAssigneeInterface = AbstractMyEntityInterface<
  ApiTrainingCustomAssigneeInterface,
  TrainingCustomAssigneeInterface
>;

export enum TrainingCustomSyncUnavailableReason {
  noPermissions = "noPermissions",
  offline = "offline",
  notValid = "notValid",
  isOpen = "isOpen",
  hasNotChanged = "hasNotChanged",
}

export interface TrainingCustomAssigneeToSyncInterface {
  identifier: {
    type: EntityType.trainingCustomAssignee;
    id: number;
    frontendId: string;
  };
  data: {
    id: number;
    achieved: boolean;
    changedDateTime?: string;
    remarks: string;
    staffId?: number | null;
    internalExternalAssignee: TrainingCustomAssigneeType;
    externalAssigneeName?: string | null;
  };
  raw?: ApiTrainingCustomAssigneeInterface;
}

export interface TrainingCustomToSyncInterface {
  identifier: {
    type: EntityType.trainingCustom;
    id: number;
    frontendId: string;
  };
  data: {
    id: number;
    description?: string;
    completionDate?: string;
    remarks?: string;
    state: TrainingCustomState;
    changedDateTime?: string;
    assignees: TrainingCustomAssigneeToSyncInterface[];
  };
  raw?: ApiTrainingCustomInterface;
}
