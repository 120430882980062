import React from "react";
import LabelField from "component/labelField/LabelField";
import t from "module/translations";
import Typography from "@mui/material/Typography";
import StaffNamesText from "module/staff/StaffNamesText";

interface Props {
  assigneeIds?: number[];
}

const TrainingCollectionAssigneeLabel: React.FC<Props> = ({ assigneeIds }) => {
  return (
    <LabelField
      {...{
        caption: t.competencyManagement.training.assigneeText,
      }}
    >
      <Typography variant="body2" gutterBottom>
        {assigneeIds && <StaffNamesText {...{ staffIds: assigneeIds }} />}
        {!assigneeIds && "-"}
      </Typography>
    </LabelField>
  );
};

export default React.memo(TrainingCollectionAssigneeLabel);
