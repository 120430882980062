import { createNestedPropertyIfNotExist } from "toolkit/createNestedPropertyIfNotExist";
import {
  MaintenanceTaskInterface,
  MaintenanceTaskType,
} from "module/maintenanceTask/types";
import { XValidatorErrorsInterface } from "module/validation/types";
import { XRequiredValidator } from "module/validation/validator/requiredValidator";
import {
  MaintenanceTaskProcessStepCheckResult,
  MaintenanceTaskProcessStepInterface,
} from "module/maintenanceTaskProcessStep/types";
import { isStateCompletedOrHigher as isStateCompletedOrHigherHelper } from "module/maintenanceTask/helper/isStateCompletedOrHigher";
import { MaintenanceTaskProcessStepQuickFixInterface } from "module/maintenanceTaskProcessStepQuickFix/types";
import { MAINTENANCE_TASK_PROCESS_STEP_QUICK_FIX_TYPE_FIELD_NAME } from "module/maintenanceTaskProcessStepQuickFix/constants";

export interface MaintenanceTaskValidationResult {
  errors: null | XValidatorErrorsInterface;
  warnings: null | XValidatorErrorsInterface;
}

export const validateMaintenanceTaskProcessStepQuickFix = (
  maintenanceTask: MaintenanceTaskInterface,
  maintenanceTaskProcessStep: MaintenanceTaskProcessStepInterface,
  maintenanceTaskProcessStepQuickFix: MaintenanceTaskProcessStepQuickFixInterface
): MaintenanceTaskValidationResult => {
  let errors: XValidatorErrorsInterface = {};
  const warnings: XValidatorErrorsInterface = {};

  const isStateCompletedOrHigher =
    isStateCompletedOrHigherHelper(maintenanceTask);

  if (maintenanceTask.type === MaintenanceTaskType.preventive) {
    if (
      isStateCompletedOrHigher &&
      maintenanceTaskProcessStep.checkResult ===
        MaintenanceTaskProcessStepCheckResult.good_no
    ) {
      if (
        maintenanceTaskProcessStep.quickFix != null &&
        (maintenanceTaskProcessStepQuickFix.type == null ||
          maintenanceTaskProcessStepQuickFix.type === "")
      ) {
        errors = createNestedPropertyIfNotExist(
          errors,
          MAINTENANCE_TASK_PROCESS_STEP_QUICK_FIX_TYPE_FIELD_NAME,
          []
        );
        errors[MAINTENANCE_TASK_PROCESS_STEP_QUICK_FIX_TYPE_FIELD_NAME].push(
          new XRequiredValidator().message
        );
      }
    }
  }

  return {
    errors: Object.keys(errors).length ? errors : null,
    warnings: Object.keys(warnings).length ? warnings : null,
  };
};
