import React, { useContext, useCallback } from "react";
import TextField from "component/textField/TextField";
import MaintenanceTaskContext from "module/maintenanceTask/context/MaintenanceTaskContext";
import MaintenanceTaskChangeContext from "module/maintenanceTask/change/MaintenanceTaskChangeContext";
import MaintenanceTaskFieldMetaDataContext from "module/maintenanceTask/validation/fieldMetaData/MaintenanceTaskFieldMetaDataContext";
import { MaintenanceTaskInterface } from "module/maintenanceTask/types";
import { getFieldMetaDataWithErrorHandling } from "module/fieldMetaData/getFieldMetaData";
import XLabelField from "component/labelField/XLabelField";
import { MAINTENANCE_TASK_ASSISTANT_EXTERNAL_STAFF_COMPANY_FIELD_NAME } from "module/maintenanceTask/constants";

const MaintenanceTaskAssistantExternalStaffCompanyContainer: React.FC = () => {
  const { entity } = useContext(MaintenanceTaskContext);
  const { change: changeHelper } = useContext(MaintenanceTaskChangeContext);
  const { getFieldMetaData } = useContext(MaintenanceTaskFieldMetaDataContext);

  const maintenanceTask = entity as MaintenanceTaskInterface;

  const { warnings, errors, isEditable } = getFieldMetaDataWithErrorHandling(
    () =>
      getFieldMetaData(
        MAINTENANCE_TASK_ASSISTANT_EXTERNAL_STAFF_COMPANY_FIELD_NAME
      )
  );

  const change = useCallback(
    (assistantExternalStaffCompany?: string) => {
      changeHelper({ assistantExternalStaffCompany });
    },
    [changeHelper]
  );

  return isEditable ? (
    <TextField
      {...{
        value: maintenanceTask.assistantExternalStaffCompany,
        change,
        errors,
      }}
    />
  ) : (
    <XLabelField
      {...{
        label: maintenanceTask.assistantExternalStaffCompany,
        errors,
        warnings,
      }}
    />
  );
};

export default React.memo(
  MaintenanceTaskAssistantExternalStaffCompanyContainer
);
