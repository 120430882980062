import React from "react";

export interface OnlineDetectorContextInterface {
  isOnline: boolean;
}

const OnlineDetectorContext = React.createContext(<
  OnlineDetectorContextInterface
>{
  isOnline: true,
});

export default OnlineDetectorContext;
