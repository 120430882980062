import React from "react";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import useDateFormat from "toolkit/useDateFormat";
import LinkWithIcon from "component/link/LinkWithIcon";
import { InventoryCheckInterface } from "module/inventoryCheck/types";
import { getPathToInventoryCheckDetails } from "module/inventoryCheck/helper/getPath";
import { getInventoryCheckStateTranslation } from "module/inventoryCheck/helper/getInventoryCheckStateTranslation";
import InventoryCheckDeleteButtonContainer from "module/inventoryCheck/enrichedComponent/deleteButton/InventoryCheckDeleteButtonContainer";
import StaffNameText from "module/staff/StaffNameText";
import MyInventoryCheckListTableCellSyncContainer from "./sync/MyInventoryCheckListTableCellSyncContainer";

interface Props {
  inventoryCheck: InventoryCheckInterface;
  isSelected: boolean;
}

const MyInventoryCheckListTableRow: React.FC<Props> = ({
  inventoryCheck,
  isSelected,
}) => {
  const { formatDateTime } = useDateFormat();

  return (
    <TableRow
      {...{
        hover: true,
        tabIndex: -1,
        role: "checkbox",
        "aria-checked": isSelected,
        selected: isSelected,
        key: inventoryCheck.id,
      }}
    >
      <MyInventoryCheckListTableCellSyncContainer />
      <TableCell align="left">
        <LinkWithIcon
          {...{
            label: inventoryCheck.reportingNumber,
            path: getPathToInventoryCheckDetails(inventoryCheck.id),
          }}
        />
      </TableCell>
      <TableCell align="left">{inventoryCheck.subject}</TableCell>
      <TableCell align="left">
        {inventoryCheck.assigneeId ? (
          <StaffNameText {...{ staffId: inventoryCheck.assigneeId }} />
        ) : (
          "-"
        )}
      </TableCell>
      <TableCell align="left">
        {formatDateTime(inventoryCheck.createdAt)}
      </TableCell>
      <TableCell align="left">
        {getInventoryCheckStateTranslation(inventoryCheck.state)}
      </TableCell>
      <TableCell align="right">
        <InventoryCheckDeleteButtonContainer />
      </TableCell>
    </TableRow>
  );
};
export default React.memo(MyInventoryCheckListTableRow);
