import React, { useContext, useCallback } from "react";
import OnlineDetectorContext from "module/onlineDetector/OnlineDetectorContext";
import { toastSuccess } from "layout/toast/helper";
import t from "module/translations";
import { useAppDispatch, useAppSelector } from "redux/hooks";
import { selectSystem, selectUser } from "module/auth/redux/authSlice";
import ToggleContext from "module/toggle/context/ToggleContext";
import unblock from "api/block/unblock";
import { EntityIdentifier, EntityType } from "module/entity/types";
import { removeFromMyEntities } from "module/staff/redux/staffDataSlice";
import ElementWithConfirmation from "component/elementWithConfirmation/ElementWithConfirmation";
import DeleteButton from "component/button/DeleteButton";
import MaintenanceTaskContext from "module/maintenanceTask/context/MaintenanceTaskContext";
import { MaintenanceTaskInterface } from "module/maintenanceTask/types";

const MaintenanceTaskDeleteButtonContainer: React.FC = () => {
  const { isOnline } = useContext(OnlineDetectorContext);
  const { entity } = useContext(MaintenanceTaskContext);
  const maintenanceTask = entity as MaintenanceTaskInterface;

  const dispatch = useAppDispatch();
  const staffId = useAppSelector(selectUser).id;
  const systemShortName = useAppSelector(selectSystem).shortName;
  const { unselectOne } = useContext(ToggleContext);

  const canDelete = isOnline;
  const hasToUnblock = maintenanceTask.backendId;

  const remove = useCallback(async () => {
    const entityIdentifier: EntityIdentifier = {
      id: maintenanceTask.id,
      type: EntityType.maintenanceTask,
    };
    if (hasToUnblock) {
      await unblock([entityIdentifier]);
    }

    unselectOne(entityIdentifier);
    dispatch(
      removeFromMyEntities({
        entityIdentifiers: [entityIdentifier],
        staffId,
        systemShortName,
      })
    );
    toastSuccess(t.maintenanceTask.deleteSuccessText);
  }, [
    dispatch,
    hasToUnblock,
    maintenanceTask.id,
    staffId,
    systemShortName,
    unselectOne,
  ]);

  if (!canDelete) {
    return null;
  }

  return (
    <ElementWithConfirmation
      {...{
        confirmationDialogTitle: t.deleteTitle,
        asyncAction: remove,
      }}
    >
      {(props: { onClick: () => void }) => (
        <DeleteButton {...{ onClick: props.onClick }} />
      )}
    </ElementWithConfirmation>
  );
};

export default React.memo(MaintenanceTaskDeleteButtonContainer);
