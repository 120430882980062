import React, { useContext, useCallback, useMemo } from "react";
import AutocompleteField from "component/autocompleteField/AutocompleteField";
import MaintenanceTaskPersonnelAssignmentInternalStaffContext from "module/maintenanceTaskPersonnelAssignment/internalStaff/context/MaintenanceTaskPersonnelAssignmentInternalStaffContext";
import MaintenanceTaskPersonnelAssignmentInternalStaffChangeContext from "module/maintenanceTaskPersonnelAssignment/internalStaff/change/MaintenanceTaskPersonnelAssignmentInternalStaffChangeContext";
import MaintenanceTaskPersonnelAssignmentInternalStaffFieldMetaDataContext from "module/maintenanceTaskPersonnelAssignment/internalStaff/validation/fieldMetaData/MaintenanceTaskPersonnelAssignmentInternalStaffFieldMetaDataContext";
import { MaintenanceTaskPersonnelAssignmentInternalStaffInterface } from "module/maintenanceTaskPersonnelAssignment/internalStaff/types";
import { getMaintenanceTaskPersonnelAssignmentResponsibilityTranslation } from "module/maintenanceTaskPersonnelAssignment/helper/getMaintenanceTaskPersonnelAssignmentResponsibilityTranslation";
import { MaintenanceTaskPersonnelAssignmentResponsibility } from "module/maintenanceTaskPersonnelAssignment/types";
import { MAINTENANCE_TASK_PERSONNEL_ASSIGNMENT_INTERNAL_STAFF_RESPONSIBILITY_FIELD_NAME } from "module/maintenanceTaskPersonnelAssignment/internalStaff/constants";
import XLabelField from "component/labelField/XLabelField";
import { getFieldMetaDataWithErrorHandling } from "module/fieldMetaData/getFieldMetaData";
import { AutocompleteOption } from "component/autocompleteField/type";

const MaintenanceTaskPersonnelAssignmentInternalStaffResponsibilityContainer: React.FC =
  () => {
    const { entity } = useContext(
      MaintenanceTaskPersonnelAssignmentInternalStaffContext
    );
    const { change: changeHelper } = useContext(
      MaintenanceTaskPersonnelAssignmentInternalStaffChangeContext
    );
    const { getFieldMetaData } = useContext(
      MaintenanceTaskPersonnelAssignmentInternalStaffFieldMetaDataContext
    );

    const maintenanceTaskPersonnelAssignment =
      entity as MaintenanceTaskPersonnelAssignmentInternalStaffInterface;

    const options: AutocompleteOption[] = useMemo(
      () => [
        {
          text: getMaintenanceTaskPersonnelAssignmentResponsibilityTranslation(
            MaintenanceTaskPersonnelAssignmentResponsibility.SUPPORT
          ),
          value: MaintenanceTaskPersonnelAssignmentResponsibility.SUPPORT,
        },
        {
          text: getMaintenanceTaskPersonnelAssignmentResponsibilityTranslation(
            MaintenanceTaskPersonnelAssignmentResponsibility.HELPER
          ),
          value: MaintenanceTaskPersonnelAssignmentResponsibility.HELPER,
        },
        {
          text: getMaintenanceTaskPersonnelAssignmentResponsibilityTranslation(
            MaintenanceTaskPersonnelAssignmentResponsibility.TRAINEE
          ),
          value: MaintenanceTaskPersonnelAssignmentResponsibility.TRAINEE,
        },
      ],
      []
    );

    const selected = useMemo(
      () =>
        options.find(
          (option) =>
            option.value === maintenanceTaskPersonnelAssignment.responsibility
        ),
      [maintenanceTaskPersonnelAssignment.responsibility, options]
    );

    const change = useCallback(
      (responsibility: unknown): void => {
        changeHelper({
          responsibility:
            responsibility as MaintenanceTaskPersonnelAssignmentResponsibility,
        });
      },
      [changeHelper]
    );

    const { warnings, errors, isEditable } = getFieldMetaDataWithErrorHandling(
      () =>
        getFieldMetaData(
          MAINTENANCE_TASK_PERSONNEL_ASSIGNMENT_INTERNAL_STAFF_RESPONSIBILITY_FIELD_NAME
        )
    );

    if (
      isEditable &&
      maintenanceTaskPersonnelAssignment.responsibility !==
        MaintenanceTaskPersonnelAssignmentResponsibility.LEAD
    ) {
      return (
        <AutocompleteField
          {...{ selected, options, onChange: change, errors }}
        />
      );
    }

    const label =
      maintenanceTaskPersonnelAssignment.responsibility != null
        ? getMaintenanceTaskPersonnelAssignmentResponsibilityTranslation(
            maintenanceTaskPersonnelAssignment.responsibility
          )
        : undefined;

    return (
      <XLabelField
        {...{
          label,
          warnings,
          errors,
        }}
      />
    );
  };

export default React.memo(
  MaintenanceTaskPersonnelAssignmentInternalStaffResponsibilityContainer
);
