import React, { useContext } from "react";
import MaintenanceTaskContext from "module/maintenanceTask/context/MaintenanceTaskContext";
import { MaintenanceTaskInterface } from "module/maintenanceTask/types";
import MyMaintenanceTaskProcessStepList from "pages/myMaintenanceTaskDetails/processStepList/MyMaintenanceTaskProcessStepList";
import CollapsedListContextProvider from "module/collapsedList/context/CollapsedListContextProvider";

const MyMaintenanceTaskProcessStepListContainer: React.FC = () => {
  const { entity } = useContext(MaintenanceTaskContext);
  const maintenanceTask = entity as MaintenanceTaskInterface;

  return (
    <CollapsedListContextProvider {...{ initialValue: true }}>
      <MyMaintenanceTaskProcessStepList
        {...{ entityIdentifiers: maintenanceTask.processSteps }}
      />
    </CollapsedListContextProvider>
  );
};

export default React.memo(MyMaintenanceTaskProcessStepListContainer);
