import {
  ApiArticle,
  ApiMaintenanceTaskProcessStepQuickFixAvailableTypeInterface,
  ApiStockArticle,
  ApiSystemConfigurationInterface,
  ApiSystemInterface,
  ApiUserInterface,
} from "api/auth/getMe/types";
import {
  SystemConfigurationInterface,
  SystemInterface,
  UserInterface,
} from "module/auth/types";
import { StockArticleCollection } from "module/stockArticle/types";
import { MaintenanceTaskProcessStepQuickFixTypeCollectionInterface } from "module/maintenanceTaskProcessStepQuickFix/types";
import { ArticleCollection } from "module/article/types";
import moment from "moment";
import { API_DATE_TIME_FORMAT } from "api/date";
import {
  ApiMaintenanceTaskTemplateInterface,
  MaintenanceTaskTemplateCollection,
} from "api/types";

const mapStockArticles = (
  rawStockArticles: ApiStockArticle[]
): StockArticleCollection => {
  return rawStockArticles.reduce(
    (result, current) => ({
      ...result,
      [current.id]: {
        id: current.id,
        text: current.text,
        unit: current.unit,
        value: current.id,
        onStock: current.onStock,
        externalId: current.externalId,
        name: current.name,
        stock: current.stock,
      },
    }),
    {}
  );
};

const mapArticles = (rawArticles: ApiArticle[]): ArticleCollection => {
  return rawArticles.reduce(
    (result, current) => ({
      ...result,
      [current.id]: current,
    }),
    {}
  );
};

const mapMaintenanceTaskTemplates = (
  rawMaintenanceTaskTemplates: ApiMaintenanceTaskTemplateInterface[]
): MaintenanceTaskTemplateCollection => {
  return rawMaintenanceTaskTemplates.reduce(
    (result, current) => ({
      ...result,
      [current.id]: current,
    }),
    {}
  );
};

const mapMaintenanceTaskProcessStepQuickFixAvailableTypes = (
  rawTypes: ApiMaintenanceTaskProcessStepQuickFixAvailableTypeInterface[]
): MaintenanceTaskProcessStepQuickFixTypeCollectionInterface =>
  rawTypes.reduce((result, current) => {
    return {
      ...result,
      [current.value]: {
        text: current.label,
        value: current.value,
      },
    };
  }, {});

const mapSystemConfiguration = (
  rawSystemConfiguration: ApiSystemConfigurationInterface | null
): SystemConfigurationInterface | null => {
  if (rawSystemConfiguration == null) {
    return null;
  }
  return {
    dateOfRetrievingData: moment().format(API_DATE_TIME_FORMAT),
    staffs: rawSystemConfiguration.staffs,
    dateFormat: rawSystemConfiguration.dateFormat,
    dateTimeFormat: rawSystemConfiguration.dateTimeFormat,
    staffPermissions: rawSystemConfiguration.staffPermissions,
    stockArticles: mapStockArticles(rawSystemConfiguration.stockArticles),
    maintenanceTaskProcessStepQuickFixAvailableTypes:
      mapMaintenanceTaskProcessStepQuickFixAvailableTypes(
        rawSystemConfiguration.maintenanceTaskProcessStepQuickFixAvailableTypes
      ),
    articles: mapArticles(rawSystemConfiguration.articles),
    maintenanceTaskTemplates: mapMaintenanceTaskTemplates(
      rawSystemConfiguration.maintenanceTaskTemplates
    ),
  };
};

export const mapAvailableSystem = (
  rawAvailableSystem: ApiSystemInterface
): SystemInterface => ({
  name: rawAvailableSystem.name,
  shortName: rawAvailableSystem.shortName,
  configuration: mapSystemConfiguration(rawAvailableSystem.configuration),
});

const mapAvailableSystems = (
  rawAvailableSystems: ApiSystemInterface[]
): SystemInterface[] => rawAvailableSystems.map(mapAvailableSystem);

export const mapApiResponse = (rawData: ApiUserInterface): UserInterface => ({
  id: rawData.id,
  firstName: rawData.firstName,
  lastName: rawData.lastName,
  defaultSystemIdentifier: rawData.defaultSystemIdentifier,
  availableSystems: mapAvailableSystems(rawData.availableSystems),
});
