import { useCallback } from "react";
import { EntityFactory } from "module/entity/useEntityAbstractFactory";
import t from "module/translations";
import { getPathToInventoryCheckDetails } from "./helper/getPath";
import useInventoryCheckIsSyncAvailable from "./sync/useInventoryCheckIsSyncAvailable";
import useInventoryCheckSyncHelper from "./sync/useInventoryCheckSyncHelper";
import useMyInventoryCheckReadableIdentifier from "./readableIdentifier/useMyInventoryCheckReadableIdentifier";
import { getInventoryCheckSyncUnavailableTranslation } from "./helper/getInventoryCheckSyncUnavailableTranslation";
import { InventoryCheckSyncUnavailableReason } from "./types";

const useInventoryCheckFactory = (): EntityFactory => {
  const { isInventoryCheckSyncAvailable } = useInventoryCheckIsSyncAvailable();
  const { geInventoryCheckMappedToSync } = useInventoryCheckSyncHelper();
  const { getReadableIdentifier } = useMyInventoryCheckReadableIdentifier();

  const getPathToMyGroupedDetails = useCallback((): string => {
    throw new Error("Not supported.");
  }, []);

  const areSimilar = useCallback((): boolean => {
    throw new Error("Not supported.");
  }, []);

  const getSyncUnavailableReasonText = useCallback(
    (reason: string): string =>
      getInventoryCheckSyncUnavailableTranslation(
        reason as InventoryCheckSyncUnavailableReason
      ),
    []
  );

  return {
    getPathToMyDetails: getPathToInventoryCheckDetails,
    getPathToMyGroupedDetails,
    areSimilar,
    isSyncAvailable: isInventoryCheckSyncAvailable,
    getMappedToSync: geInventoryCheckMappedToSync,
    getReadableIdentifier,
    readableType: t.inventoryCheckText,
    getSyncUnavailableReasonText,
  };
};

export default useInventoryCheckFactory;
