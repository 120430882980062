import { RootState } from "redux/store";
import selectConfigurationForMeForCurrentSystem from "module/staff/redux/selector/selectConfigurationForMeForCurrentSystem";
import { EntityIdentifier, MyEntityInterface } from "module/entity/types";
import { getEntityIdentifierKey } from "module/entity/helper/getEntityIdentifierKey";

const selectEntity =
  (state: RootState) =>
  (entityIdentifier: EntityIdentifier): MyEntityInterface => {
    return selectConfigurationForMeForCurrentSystem(state)?.entities[
      getEntityIdentifierKey(entityIdentifier)
    ];
  };

export default selectEntity;
