import { useCallback, useContext, useMemo } from "react";
import { v4 as uuid } from "uuid";
import { API_DATE_TIME_FORMAT } from "api/date";
import moment from "moment";
import { useAppDispatch, useAppSelector } from "redux/hooks";
import { selectSystem, selectUser } from "module/auth/redux/authSlice";
import { MaintenanceTaskInterface } from "module/maintenanceTask/types";
import { canAddMaintenanceTaskPart } from "module/maintenanceTaskPart/helper/canAddMaintenanceTaskPart";
import MaintenanceTaskContext from "module/maintenanceTask/context/MaintenanceTaskContext";
import { EntityType } from "module/entity/types";
import MaintenanceTaskOpenCloseContext from "module/maintenanceTask/openClose/context/MaintenanceTaskOpenCloseContext";
import {
  addToEntityPropertyList,
  addToMyEntities,
} from "module/staff/redux/staffDataSlice";
import {
  MaintenanceTaskPartInterface,
  MyMaintenanceTaskPartInterface,
} from "module/maintenanceTaskPart/types";
import useMyEntityValidation from "module/entity/useMyEntityValidation";

interface Result {
  canAdd: boolean;
  add: () => void;
}

const useMaintenanceTaskPartAdd = (): Result => {
  const { entity } = useContext(MaintenanceTaskContext);
  const maintenanceTask = entity as MaintenanceTaskInterface;

  const { isOpen } = useContext(MaintenanceTaskOpenCloseContext);

  const dispatch = useAppDispatch();
  const staffId = useAppSelector(selectUser).id;
  const systemShortName = useAppSelector(selectSystem).shortName;

  const canAdd = useMemo(
    () =>
      canAddMaintenanceTaskPart({
        isMaintenanceTaskOpen: isOpen,
        maintenanceTask,
      }),
    [isOpen, maintenanceTask]
  );

  const { validateMyEntities } = useMyEntityValidation();

  const add = useCallback(() => {
    const maintenanceTaskEntityIdentifier = {
      id: maintenanceTask.id,
      type: EntityType.maintenanceTask,
    };

    const id = uuid();
    const entityIdentifier = {
      type: EntityType.maintenanceTaskPart,
      id,
    };

    const today = moment()
      .set({ hour: 0, minute: 0, second: 0 })
      .format(API_DATE_TIME_FORMAT);

    const data: MaintenanceTaskPartInterface = {
      id,
      maintenanceTaskId: maintenanceTask.id,
      relationships: undefined,
      relationshipIdentifiers: [],
      changedDateTime: today,
    };

    const myEntity: MyMaintenanceTaskPartInterface = {
      entityIdentifier,
      data,
      dataWithoutChanges: data,
      validationErrors: null,
      validationWarnings: null,
    };

    dispatch(
      addToMyEntities({
        staffId,
        systemShortName,
        entities: [myEntity],
      })
    );

    validateMyEntities([entityIdentifier]);

    dispatch(
      addToEntityPropertyList({
        staffId,
        systemShortName,
        baseEntityIdentifier: maintenanceTaskEntityIdentifier,
        propertyName: "parts",
        entityIdentifierToAdd: entityIdentifier,
      })
    );
  }, [
    dispatch,
    maintenanceTask.id,
    staffId,
    systemShortName,
    validateMyEntities,
  ]);

  return {
    canAdd,
    add,
  };
};

export default useMaintenanceTaskPartAdd;
