import {
  MaintenanceTaskPartInterface,
  MaintenanceTaskPartToSyncInterface,
} from "module/maintenanceTaskPart/types";

export const mapMaintenanceTaskPartToSync = (
  maintenanceTaskPart: MaintenanceTaskPartInterface
): MaintenanceTaskPartToSyncInterface => {
  return {
    id: maintenanceTaskPart.backendId,
    stockArticleId: maintenanceTaskPart.stockArticleId as number,
    amount: maintenanceTaskPart.amount,
  };
};
