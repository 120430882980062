import React from "react";
import Typography from "@mui/material/Typography";
import makeStyles from "@mui/styles/makeStyles";

const useStyles = makeStyles(() => ({
  text: {
    color: "rgba(0, 0, 0, 0.54)",
  },
}));

interface Props {
  text: string;
}

const LabelField: React.FC<Props> = ({ text }) => {
  const classes = useStyles();

  return (
    <Typography variant="caption" gutterBottom className={classes.text}>
      {text}
    </Typography>
  );
};

export default React.memo(LabelField);
