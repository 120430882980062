import { TrainingSyncUnavailableReason } from "module/training/types";
import { IsEntitySyncAvailableResult } from "module/entity/useEntityAbstractFactory";

interface IsTrainingSyncAvailableProps {
  isOnline: boolean;
  hasPermissions: boolean;
  isOpen: boolean;
  isValid: boolean;
  hasChanged: boolean;
}

const isTrainingSyncAvailable = ({
  hasPermissions,
  isOnline,
  isOpen,
  isValid,
  hasChanged,
}: IsTrainingSyncAvailableProps): IsEntitySyncAvailableResult => {
  if (!isOnline) {
    return {
      isAvailable: false,
      unavailableReason: TrainingSyncUnavailableReason.offline,
    };
  }

  if (!hasPermissions) {
    return {
      isAvailable: false,
      unavailableReason: TrainingSyncUnavailableReason.noPermissions,
    };
  }

  if (isOpen) {
    return {
      isAvailable: false,
      unavailableReason: TrainingSyncUnavailableReason.isOpen,
    };
  }

  if (!isValid) {
    return {
      isAvailable: false,
      unavailableReason: TrainingSyncUnavailableReason.notValid,
    };
  }

  if (!hasChanged) {
    return {
      isAvailable: false,
      unavailableReason: TrainingSyncUnavailableReason.hasNotChanged,
    };
  }

  return {
    isAvailable: true,
    unavailableReason: null,
  };
};

export default isTrainingSyncAvailable;
