import React, { useMemo } from "react";
import { useAppSelector } from "redux/hooks";
import { selectStockArticles } from "module/auth/redux/authSlice";

interface Props {
  stockArticleId: number;
}

const StockArticleText: React.FC<Props> = ({ stockArticleId }) => {
  const stockArticles = useAppSelector(selectStockArticles);
  const stockArticle = useMemo(
    () => stockArticles[stockArticleId],
    [stockArticleId, stockArticles]
  );

  return <>{stockArticle?.text}</>;
};

export default React.memo(StockArticleText);
