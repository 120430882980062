import React, { useMemo } from "react";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import useDateFormat from "toolkit/useDateFormat";
import MyMaintenanceTaskLisTableCellSyncContainer from "pages/myMaintenanceTaskList/table/row/sync/MyMaintenanceTaskListTableCellSyncContainer";
import t from "module/translations";
import LinkWithIcon from "component/link/LinkWithIcon";
import { getPathToMaintenanceTaskDetails } from "module/maintenanceTask/helper/getPath";
import { MaintenanceTaskInterface } from "module/maintenanceTask/types";
import { getMaintenanceTaskStateTranslation } from "module/maintenanceTask/helper/getMaintenanceTaskStateTranslation";
import { getTypeTranslation } from "module/maintenanceTask/helper/getTypeTranslation";
import MaintenanceTaskDeleteButtonContainer from "module/maintenanceTask/enrichedComponent/deleteButton/MaintenanceTaskDeleteButtonContainer";

interface Props {
  maintenanceTask: MaintenanceTaskInterface;
  isSelected: boolean;
}

const MyMaintenanceTaskListTableRow: React.FC<Props> = ({
  maintenanceTask,
  isSelected,
}) => {
  const { formatDateTime } = useDateFormat();
  const reportingNumber = useMemo(() => {
    let result = maintenanceTask.reportingNumber;

    if (!result && maintenanceTask.source === "template") {
      result = t.maintenanceTask.fromTemplateText;
    }

    if (!result && maintenanceTask.source === "serviceRequest") {
      result = t.maintenanceTask.serviceRequestText;
    }

    return result;
  }, [maintenanceTask.source, maintenanceTask.reportingNumber]);

  return (
    <TableRow
      {...{
        hover: true,
        tabIndex: -1,
        role: "checkbox",
        "aria-checked": isSelected,
        selected: isSelected,
        key: maintenanceTask.id,
      }}
    >
      <MyMaintenanceTaskLisTableCellSyncContainer />
      <TableCell align="left">{reportingNumber}</TableCell>
      <TableCell align="left">
        <LinkWithIcon
          {...{
            label: maintenanceTask.name,
            path: getPathToMaintenanceTaskDetails(maintenanceTask.id),
          }}
        />
      </TableCell>
      <TableCell align="left">
        {getTypeTranslation(maintenanceTask.type)}
      </TableCell>
      <TableCell align="left">
        {maintenanceTask.scheduledStartDate
          ? formatDateTime(maintenanceTask.scheduledStartDate)
          : "-"}
      </TableCell>
      <TableCell align="left">{maintenanceTask.window}</TableCell>
      <TableCell align="left">{maintenanceTask.timeDeviation || "-"}</TableCell>
      <TableCell align="left">
        {getMaintenanceTaskStateTranslation(maintenanceTask.state)}
      </TableCell>
      <TableCell align="right">
        <MaintenanceTaskDeleteButtonContainer />
      </TableCell>
    </TableRow>
  );
};
export default React.memo(MyMaintenanceTaskListTableRow);
