import React from "react";

export interface FullScreenContextInterface {
  isLoading: boolean;
  turnOn: () => void;
  turnOff: () => void;
}

const FullScreenLoaderContext = React.createContext(
  <FullScreenContextInterface>{}
);

export default FullScreenLoaderContext;
