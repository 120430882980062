import React from "react";
import t from "module/translations";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import AddIconButton from "component/addButton/AddIconButton";
import { useDownloadedMaintenanceTaskDetailsPersonnelAssignmentAssistantStyles } from "pages/myMaintenanceTaskDetails/personnelAssignment/assistant/styles";
import MyMaintenanceTaskDetailsAssistantTableRowContainer from "pages/myMaintenanceTaskDetails/personnelAssignment/assistant/table/row/MyMaintenanceTaskDetailsAssistantTableRowContainer";

interface Props {
  isSet: boolean;
  canAdd: boolean;
  add: () => void;
}

const MyMaintenanceTaskDetailsAssistant: React.FC<Props> = ({
  isSet,
  canAdd,
  add,
}) => {
  const classes =
    useDownloadedMaintenanceTaskDetailsPersonnelAssignmentAssistantStyles();

  return (
    <Table className={classes.table}>
      <TableHead>
        <TableRow>
          <TableCell className={classes.type} />
          <TableCell>
            {t.maintenanceTask.personnelAssignments.nameText}
          </TableCell>
          <TableCell>
            {t.maintenanceTask.personnelAssignments.positionOrCompanyText}
          </TableCell>
          <TableCell />
        </TableRow>
      </TableHead>
      <TableBody>
        {isSet && <MyMaintenanceTaskDetailsAssistantTableRowContainer />}
        {canAdd && (
          <TableRow>
            <TableCell className={classes.type}>
              <AddIconButton {...{ onClick: add }} />
            </TableCell>
            <TableCell className={classes.staffName} />
            <TableCell className={classes.staffPositionOrCompany} />
            <TableCell className={classes.actions} />
          </TableRow>
        )}
      </TableBody>
    </Table>
  );
};

export default React.memo(MyMaintenanceTaskDetailsAssistant);
