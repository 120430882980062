import axios from "axios";
import { mapTrainingCustom } from "api/competencyManagement/mappers";
import { MyTrainingCustomInterface } from "module/trainingCustom/types";

const getTrainingCustomDetails = async (
  trainingCustomId: number
): Promise<MyTrainingCustomInterface> => {
  const response = await axios.get(
    `/api/competency-managements/trainings-custom/${trainingCustomId}`
  );
  return mapTrainingCustom(response.data);
};

export default getTrainingCustomDetails;
