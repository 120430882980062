import React from "react";
import { EntityIdentifier, EntityInterface } from "module/entity/types";

export interface EntityCollectionContextInterface {
  entityCollection: Map<EntityIdentifier, EntityInterface>;
}

const EntityCollectionContext = React.createContext(
  <EntityCollectionContextInterface>{}
);

export default EntityCollectionContext;
