import React, { useCallback } from "react";
import TableCell from "@mui/material/TableCell";
import Checkbox from "@mui/material/Checkbox";

interface Props {
  isSelected: boolean;
  indeterminate?: boolean;
  toggle: (checked: boolean) => void;
}

const TableToggleCell: React.FC<Props> = ({
  isSelected,
  toggle,
  indeterminate = false,
}) => {
  const onChange = useCallback(
    (event: { target: { checked: boolean } }) => toggle(event.target.checked),
    [toggle]
  );

  return (
    <TableCell padding="checkbox">
      <Checkbox {...{ checked: isSelected, onChange, indeterminate }} />
    </TableCell>
  );
};

export default React.memo(TableToggleCell);
