import { PayloadAction } from "@reduxjs/toolkit";
import { StaffDataState } from "module/staff/redux/types";
import { EntityIdentifier } from "module/entity/types";
import _ from "lodash";
import moment from "moment/moment";
import { getEntityIdentifierKey } from "../../../entity/helper/getEntityIdentifierKey";
import { API_DATE_TIME_FORMAT } from "../../../../api/date";

interface CloseEntityActionTypeProps {
  staffId: number;
  systemShortName: string;
  entityIdentifier: EntityIdentifier;
}

const closeEntityAction = (
  state: StaffDataState,
  action: PayloadAction<CloseEntityActionTypeProps>
): void => {
  const { staffId, systemShortName, entityIdentifier } = action.payload;
  state[staffId][systemShortName].openEntityIdentifiers = state[staffId][
    systemShortName
  ].openEntityIdentifiers.filter((item) => !_.isEqual(item, entityIdentifier));

  if (
    "openCloseHistory" in
    state[staffId][systemShortName].entities[
      getEntityIdentifierKey(entityIdentifier)
    ].data
  ) {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const { openCloseHistory } =
      state[staffId][systemShortName].entities[
        getEntityIdentifierKey(entityIdentifier)
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
      ].data;

    openCloseHistory.push({
      type: "close",
      staffId,
      dateTime: moment().format(API_DATE_TIME_FORMAT),
    });

    state[staffId][systemShortName].entities[
      getEntityIdentifierKey(entityIdentifier)
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
    ].data.openCloseHistory = openCloseHistory;
  }
};

export default closeEntityAction;
