import React from "react";
import MUIButton from "@mui/material/Button";

interface Props {
  onClick: () => void;
  text?: string;
  icon?: React.ReactElement;
  disabled?: boolean;
}

const Button: React.FC<Props> = ({ onClick, icon, text, disabled = false }) => {
  return (
    <MUIButton
      variant="contained"
      color="primary"
      size="large"
      onClick={onClick}
      startIcon={icon}
      disabled={disabled}
    >
      {text ?? ""}
    </MUIButton>
  );
};

export default React.memo(Button);
