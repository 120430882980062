import React, { useContext } from "react";
import { getFieldMetaData as getFieldMetaDataHelper } from "module/fieldMetaData/getFieldMetaData";
import TrainingCustomAssigneeValidationResultContext from "module/trainingCustomAssignee/validation/TrainingCustomAssigneeValidationResultContext";
import TrainingCustomOpenCloseContext from "module/trainingCustom/openClose/context/TrainingCustomOpenCloseContext";
import TrainingCustomStateContext from "module/trainingCustom/state/context/TrainingCustomStateContext";
import TrainingCustomAssigneeFieldMetaDataContext, {
  TrainingCustomAssigneeFieldMetaDataContextInterface,
} from "module/trainingCustomAssignee/validation/fieldMetaData/TrainingCustomAssigneeFieldMetaDataContext";

interface Prop {
  children: React.ReactNode;
}

const MyTrainingCustomAssigneeFieldMetaDataContextProvider: React.FC<Prop> = ({
  children,
}) => {
  const { validationWarnings, validationErrors } = useContext(
    TrainingCustomAssigneeValidationResultContext
  );
  const { isOpen } = useContext(TrainingCustomOpenCloseContext);
  const { isStateScheduledOrLower } = useContext(TrainingCustomStateContext);

  const getFieldMetaData = getFieldMetaDataHelper({
    validationWarnings,
    validationErrors,
    isOpen,
    isStateScheduledOrLower,
  });

  const contextValue: TrainingCustomAssigneeFieldMetaDataContextInterface = {
    getFieldMetaData,
  };

  return (
    <TrainingCustomAssigneeFieldMetaDataContext.Provider value={contextValue}>
      {children}
    </TrainingCustomAssigneeFieldMetaDataContext.Provider>
  );
};

export default MyTrainingCustomAssigneeFieldMetaDataContextProvider;
