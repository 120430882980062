import { ApiMaintenanceTaskInterface } from "api/maintenanceTask/types";
import {
  MaintenanceTaskAssistantType,
  MaintenanceTaskInterface,
  MyMaintenanceTaskInterface,
} from "module/maintenanceTask/types";
import { mergeEntityMaps } from "module/entity/helper/mergeEntityMaps";
import { EntityIdentifier, MyEntityInterface } from "module/entity/types";
import { mapApiMaintenanceTaskProcessStepToApp } from "module/maintenanceTaskProcessStep/helper/mapApiMaintenanceTaskProcessStepToApp";
import { mapApiMaintenanceTaskPartToApp } from "module/maintenanceTaskPart/helper/mapApiMaintenanceTaskPartToApp";
import { mapApiMaintenanceTaskPersonnelAssignmentToApp } from "module/maintenanceTaskPersonnelAssignment/helper/mapApiMaintenanceTaskPersonnelAssignmentToApp";
import { mapApiAttachmentToApp } from "module/attachment/helper/mapApiAttachmentToApp";

export const mapApiMaintenanceTaskToApp = (
  raw: ApiMaintenanceTaskInterface
): MyMaintenanceTaskInterface => {
  const processSteps = raw.processSteps.reduce((result, rawProcessStep) => {
    const myEntity = mapApiMaintenanceTaskProcessStepToApp(raw, rawProcessStep);
    result.set(myEntity.entityIdentifier, myEntity);
    return result;
  }, new Map() as Map<EntityIdentifier, MyEntityInterface>);
  const personnelAssignments = raw.personnelAssignments.reduce(
    (result, rawPersonnelAssignment) => {
      const myEntity = mapApiMaintenanceTaskPersonnelAssignmentToApp(
        raw,
        rawPersonnelAssignment
      );
      result.set(myEntity.entityIdentifier, myEntity);
      return result;
    },
    new Map() as Map<EntityIdentifier, MyEntityInterface>
  );
  const parts = raw.articleMovements.reduce((result, rawPart) => {
    const myEntity = mapApiMaintenanceTaskPartToApp(raw, rawPart);
    result.set(myEntity.entityIdentifier, myEntity);
    return result;
  }, new Map() as Map<EntityIdentifier, MyEntityInterface>);

  // support only images
  const attachments = raw.attachments
    .filter((rawAttachment) => rawAttachment.mimeType.startsWith("image/"))
    .reduce((result, rawAttachment) => {
      const myEntity = mapApiAttachmentToApp(rawAttachment);
      result.set(myEntity.entityIdentifier, myEntity);
      return result;
    }, new Map() as Map<EntityIdentifier, MyEntityInterface>);

  const relationships = mergeEntityMaps([
    processSteps,
    personnelAssignments,
    parts,
    attachments,
  ]);

  let assistantType;
  if (raw.assistant.internalStaffId != null) {
    assistantType = MaintenanceTaskAssistantType.internal;
  } else if (
    raw.assistant.externalStaff?.name ||
    raw.assistant.externalStaff?.company
  ) {
    assistantType = MaintenanceTaskAssistantType.external;
  }

  const data: MaintenanceTaskInterface = {
    ...raw,
    id: `${raw.id}`,
    backendId: raw.id,

    processSteps: Array.from(processSteps.keys()),
    personnelAssignments: Array.from(personnelAssignments.keys()),
    parts: Array.from(parts.keys()),
    attachments: Array.from(attachments.keys()),

    assistantType,
    assistantInternalStaffId: raw.assistant.internalStaffId ?? undefined,
    assistantExternalStaffName: raw.assistant.externalStaff?.name,
    assistantExternalStaffCompany: raw.assistant.externalStaff?.company,
    openCloseHistory: [],

    relationships,
    relationshipIdentifiers: Array.from(relationships.keys()),
  };

  return {
    entityIdentifier: {
      type: raw.entityType,
      id: `${raw.id}`,
      backendId: raw.id,
    },
    data,
    dataWithoutChanges: data,
    raw,
    validationErrors: null,
    validationWarnings: null,
  };
};
