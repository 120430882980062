import { useContext, useCallback } from "react";
import { API_DATE_TIME_FORMAT } from "api/date";
import moment from "moment";
import { EntityIdentifier, EntityType } from "module/entity/types";
import useEntityOpenClose from "module/entity/useEntityOpenClose";
import useEntityChange from "module/entity/useEntityChange";
import {
  MaintenanceTaskInterface,
  MaintenanceTaskState,
  MyMaintenanceTaskInterface,
} from "module/maintenanceTask/types";
import selectEntity from "module/staff/redux/selector/selectEntity";
import { useAppSelector } from "redux/hooks";
import { MyMaintenanceTaskPersonnelAssignmentInternalStaffInterface } from "module/maintenanceTaskPersonnelAssignment/internalStaff/types";
import { selectStaffById, selectUser } from "module/auth/redux/authSlice";
import MaintenanceTaskContext from "module/maintenanceTask/context/MaintenanceTaskContext";
import useMaintenanceTaskPersonnelAssignmentInternalStaffAdd from "module/maintenanceTaskPersonnelAssignment/internalStaff/add/useMaintenanceTaskPersonnelAssignmentInternalStaffAdd";
import { MaintenanceTaskPersonnelAssignmentResponsibility } from "module/maintenanceTaskPersonnelAssignment/types";
import selectOpenItemsByEntityType from "module/staff/redux/selector/selectOpenItemsByEntityType";
import useEntityChangeLateCoupling from "module/entity/useEntityChangeLateCoupling";
import {
  MaintenanceTaskProcessStepCheckResult,
  MyMaintenanceTaskProcessStepInterface,
} from "module/maintenanceTaskProcessStep/types";

interface Result {
  open?: () => void;
  close?: () => void;
}

const useMyMaintenanceTaskOpenClose = (): Result => {
  const { entity } = useContext(MaintenanceTaskContext);
  const maintenanceTask = entity as MaintenanceTaskInterface;
  const entityIdentifier: EntityIdentifier = {
    id: maintenanceTask.id,
    type: EntityType.maintenanceTask,
  };

  const selectEntityHelper = useAppSelector(selectEntity);
  const staffId = useAppSelector(selectUser).id;
  const selectStaffByIdHelper = useAppSelector(selectStaffById);
  let isStaffVisibleOnList = false;

  try {
    const staff = selectStaffByIdHelper(staffId);
    isStaffVisibleOnList = staff.visibleOnList;
  } catch (e) {
    isStaffVisibleOnList = false;
  }

  const { add: addMaintenanceTaskPersonnelAssignmentInternalStaff } =
    useMaintenanceTaskPersonnelAssignmentInternalStaffAdd();

  const { change } = useEntityChange(entityIdentifier);
  const { change: changeEntityByIdentifier } = useEntityChangeLateCoupling();
  const {
    canOpen: canOpenEntity,
    open: openEntity,
    canClose,
    close: closeEntity,
  } = useEntityOpenClose(entityIdentifier);

  const handleOpen = useCallback(() => {
    openEntity();

    let changes: Partial<MaintenanceTaskInterface> = {
      completionEndDate: undefined,
    };

    if (
      maintenanceTask.completionStartDate == null ||
      maintenanceTask.completionStartDate.search("00:00:00") !== -1
    ) {
      changes = {
        ...changes,
        completionStartDate: moment().format(API_DATE_TIME_FORMAT),
      };
    }

    if (maintenanceTask.state === MaintenanceTaskState.initScheduled) {
      changes = {
        ...changes,
        state: MaintenanceTaskState.scheduled,
      };
    }
    change(changes);

    const isCurrentLoggedInStaffInPersonnelAssignment = Boolean(
      maintenanceTask.personnelAssignments
        .filter(
          (personnelAssignmentEntityIdentifier) =>
            personnelAssignmentEntityIdentifier.type ===
            EntityType.maintenanceTaskPersonnelAssignmentInternalStaff
        )
        .find((personnelAssignmentEntityIdentifier) => {
          const myPersonnelAssignmentInternalStaff = selectEntityHelper(
            personnelAssignmentEntityIdentifier
          ) as MyMaintenanceTaskPersonnelAssignmentInternalStaffInterface;

          return myPersonnelAssignmentInternalStaff.data.staffId === staffId;
        })
    );

    const allProcessStepsAreEmpty = maintenanceTask.processSteps
      .map((processStepEntityIdentifier) => {
        return selectEntityHelper(
          processStepEntityIdentifier
        ) as MyMaintenanceTaskProcessStepInterface;
      })
      .every((myMaintenanceTaskProcessStep) => {
        return (
          myMaintenanceTaskProcessStep.data.checkResult ===
          MaintenanceTaskProcessStepCheckResult.not_set
        );
      });

    const leadEntityIdentifier = maintenanceTask.personnelAssignments.find(
      (personnelAssignmentEntityIdentifier) => {
        const myPersonnelAssignmentInternalStaff = selectEntityHelper(
          personnelAssignmentEntityIdentifier
        ) as MyMaintenanceTaskPersonnelAssignmentInternalStaffInterface;

        return (
          myPersonnelAssignmentInternalStaff.data.responsibility ===
          MaintenanceTaskPersonnelAssignmentResponsibility.LEAD
        );
      }
    );

    const canCurrentLoggedInStaffBeAddedToPersonnelAssignment =
      isStaffVisibleOnList &&
      !isCurrentLoggedInStaffInPersonnelAssignment &&
      (!allProcessStepsAreEmpty || leadEntityIdentifier == null);

    const canCurrentLoggedInStaffBeSetAsLead =
      isStaffVisibleOnList &&
      !isCurrentLoggedInStaffInPersonnelAssignment &&
      leadEntityIdentifier != null &&
      allProcessStepsAreEmpty;

    if (canCurrentLoggedInStaffBeAddedToPersonnelAssignment) {
      addMaintenanceTaskPersonnelAssignmentInternalStaff({
        internalStaffId: staffId,
        responsibility: MaintenanceTaskPersonnelAssignmentResponsibility.LEAD,
      });
    } else if (canCurrentLoggedInStaffBeSetAsLead) {
      changeEntityByIdentifier(
        {
          staffId,
        },
        leadEntityIdentifier as EntityIdentifier
      );
    }
  }, [
    addMaintenanceTaskPersonnelAssignmentInternalStaff,
    change,
    changeEntityByIdentifier,
    isStaffVisibleOnList,
    maintenanceTask.completionStartDate,
    maintenanceTask.personnelAssignments,
    maintenanceTask.processSteps,
    maintenanceTask.state,
    openEntity,
    selectEntityHelper,
    staffId,
  ]);

  const openMaintenanceTasks: EntityIdentifier[] = useAppSelector(
    selectOpenItemsByEntityType(EntityType.maintenanceTask)
  );

  const isAnyOpen = openMaintenanceTasks.reduce(
    (result, openMaintenanceTaskEntityIdentifier) => {
      if (!result) {
        const myMaintenanceTask = selectEntityHelper(
          openMaintenanceTaskEntityIdentifier
        ) as MyMaintenanceTaskInterface;
        return (
          myMaintenanceTask.data.definitionType ===
          maintenanceTask.definitionType
        );
      }
      return result;
    },
    false
  );

  const canOpen =
    canOpenEntity &&
    maintenanceTask.state !== MaintenanceTaskState.validated &&
    !isAnyOpen;
  const open = canOpen ? handleOpen : undefined;

  const handleClose = useCallback(() => {
    closeEntity();

    const lead = maintenanceTask.personnelAssignments
      .map((personnelAssignmentEntityIdentifier) => {
        return selectEntityHelper(
          personnelAssignmentEntityIdentifier
        ) as MyMaintenanceTaskPersonnelAssignmentInternalStaffInterface;
      })
      .filter(
        (personnelAssignment) =>
          personnelAssignment.data.responsibility ===
          MaintenanceTaskPersonnelAssignmentResponsibility.LEAD
      )
      .find(
        (personnelAssignment) => personnelAssignment.data.staffId === staffId
      );

    if (maintenanceTask.openCloseHistory != null && lead != null) {
      const lastOpenDateTime =
        maintenanceTask.openCloseHistory[
          maintenanceTask.openCloseHistory.length - 1
        ].dateTime;

      const currentDuration = moment().diff(lastOpenDateTime, "seconds");
      changeEntityByIdentifier(
        {
          duration: (lead.data.duration ?? 0) + currentDuration,
        },
        lead.entityIdentifier as EntityIdentifier
      );
    }

    if (maintenanceTask.state === MaintenanceTaskState.completed) {
      change({
        completionEndDate: moment().format(API_DATE_TIME_FORMAT),
      });
    }
  }, [
    change,
    changeEntityByIdentifier,
    closeEntity,
    maintenanceTask.openCloseHistory,
    maintenanceTask.personnelAssignments,
    maintenanceTask.state,
    selectEntityHelper,
    staffId,
  ]);

  const close =
    canClose && maintenanceTask.state !== MaintenanceTaskState.validated
      ? handleClose
      : undefined;

  return {
    open,
    close,
  };
};

export default useMyMaintenanceTaskOpenClose;
