import React from "react";
import StickyPaper from "component/sticky/StickyPaper";
import Grid from "@mui/material/Grid";
import TrainingNameFieldContainer from "module/training/enrichedComponent/nameField/TrainingNameFieldContainer";
import TrainingCollectionAssigneeLabelContainer from "module/training/enrichedComponent/assigneesField/TrainingCollectionAssigneeLabelContainer";
import TrainingModuleFieldContainer from "module/training/enrichedComponent/moduleField/TrainingModuleFieldContainer";
import TrainingTrainerFieldContainer from "module/training/enrichedComponent/trainerField/TrainingTrainerFieldContainer";
import TrainingRemarksFieldContainer from "module/training/enrichedComponent/remarksField/TrainingRemarksFieldContainer";
import TrainingStateLabelFieldContainer from "module/training/enrichedComponent/stateField/TrainingStateLabelFieldContainer";

interface Props {
  isStateEditable: boolean;
}

const MyTrainingGroupDetailsOverview: React.FC<Props> = ({
  isStateEditable,
}) => {
  return (
    <StickyPaper>
      <Grid container spacing={3}>
        <Grid item xs={6}>
          <TrainingNameFieldContainer />
        </Grid>
        <Grid item xs={6}>
          <TrainingCollectionAssigneeLabelContainer />
        </Grid>
        <Grid item xs={6}>
          <TrainingModuleFieldContainer />
        </Grid>
        <Grid item xs={6}>
          <TrainingTrainerFieldContainer />
        </Grid>
      </Grid>
      <TrainingRemarksFieldContainer />
      {!isStateEditable && <TrainingStateLabelFieldContainer />}
    </StickyPaper>
  );
};

export default React.memo(MyTrainingGroupDetailsOverview);
