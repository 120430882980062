import React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import { EntityIdentifier } from "module/entity/types";
import { getEntityIdentifierKey } from "module/entity/helper/getEntityIdentifierKey";
import AddIconButton from "component/addButton/AddIconButton";
import { useDownloadedMaintenanceTaskDetailsPersonnelAssignmentsExternalStyles } from "module/maintenanceTaskPersonnelAssignment/externalStaff/enrichedComponent/table/styles";
import MyMaintenanceTaskPersonnelAssignmentExternalStaffListRowContextProvider from "pages/myMaintenanceTaskDetails/personnelAssignment/externalList/table/row/MyMaintenanceTaskDetailsPersonnelAssignmentExternalStaffListTableRowContextProvider";
import MyMaintenanceTaskDetailsPersonnelAssignmentExternalStaffListTableRowContainer from "pages/myMaintenanceTaskDetails/personnelAssignment/externalList/table/row/MyMaintenanceTaskDetailsPersonnelAssignmentExternalStaffListTableRowContainer";
import MaintenanceTaskDetailsPersonnelAssignmentExternalStaffListTableHead from "module/maintenanceTaskPersonnelAssignment/externalStaff/enrichedComponent/table/MaintenanceTaskDetailsPersonnelAssignmentExternalStaffListTableHead";

interface Props {
  entityIdentifiers: EntityIdentifier[];
  canAdd: boolean;
  add: () => void;
}

const MyMaintenanceTaskDetailsPersonnelAssignmentsExternalStaff: React.FC<Props> =
  ({ entityIdentifiers, canAdd, add }) => {
    const classes =
      useDownloadedMaintenanceTaskDetailsPersonnelAssignmentsExternalStyles();

    return (
      <Table className={classes.table}>
        <MaintenanceTaskDetailsPersonnelAssignmentExternalStaffListTableHead
          {...{ isActionAvailable: true }}
        />
        <TableBody>
          {entityIdentifiers.map((entityIdentifier) => (
            <MyMaintenanceTaskPersonnelAssignmentExternalStaffListRowContextProvider
              {...{
                key: getEntityIdentifierKey(entityIdentifier),
                entityIdentifier,
              }}
            >
              <MyMaintenanceTaskDetailsPersonnelAssignmentExternalStaffListTableRowContainer />
            </MyMaintenanceTaskPersonnelAssignmentExternalStaffListRowContextProvider>
          ))}
          {canAdd && (
            <TableRow>
              <TableCell className={classes.externalStaffName}>
                <AddIconButton {...{ onClick: add }} />
              </TableCell>
              <TableCell className={classes.externalStaffCompany} />
              <TableCell className={classes.responsibility} />
              <TableCell className={classes.actions} />
            </TableRow>
          )}
        </TableBody>
      </Table>
    );
  };

export default React.memo(
  MyMaintenanceTaskDetailsPersonnelAssignmentsExternalStaff
);
