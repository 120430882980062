import { useCallback, useContext, useMemo } from "react";
import { useAppDispatch, useAppSelector } from "redux/hooks";
import { selectSystem, selectUser } from "module/auth/redux/authSlice";
import MaintenanceTaskContext from "module/maintenanceTask/context/MaintenanceTaskContext";
import { MaintenanceTaskInterface } from "module/maintenanceTask/types";
import { EntityType } from "module/entity/types";
import MaintenanceTaskOpenCloseContext from "module/maintenanceTask/openClose/context/MaintenanceTaskOpenCloseContext";
import { canRemoveMaintenanceTaskPersonnelAssignmentInternalStaff } from "module/maintenanceTaskPersonnelAssignment/internalStaff/helper/canRemoveMaintenanceTaskPersonnelAssignmentInternalStaff";
import MaintenanceTaskPersonnelAssignmentInternalStaffContext from "module/maintenanceTaskPersonnelAssignment/internalStaff/context/MaintenanceTaskPersonnelAssignmentInternalStaffContext";
import { MaintenanceTaskPersonnelAssignmentInternalStaffInterface } from "module/maintenanceTaskPersonnelAssignment/internalStaff/types";
import usePermissions from "module/permission/usePermissions";
import {
  removeFromEntityPropertyList,
  removeFromMyEntities,
} from "module/staff/redux/staffDataSlice";

interface Result {
  canRemove: boolean;
  remove: () => void;
}

const useMaintenanceTaskPersonnelAssignmentInternalStaffRemove = (): Result => {
  const { entity: maintenanceTaskEntity } = useContext(MaintenanceTaskContext);
  const maintenanceTask = maintenanceTaskEntity as MaintenanceTaskInterface;

  const { entity: maintenanceTaskPersonnelAssignmentInternalStaffEntity } =
    useContext(MaintenanceTaskPersonnelAssignmentInternalStaffContext);

  const maintenanceTaskPersonnelAssignmentInternalStaff =
    maintenanceTaskPersonnelAssignmentInternalStaffEntity as MaintenanceTaskPersonnelAssignmentInternalStaffInterface;

  const { isOpen } = useContext(MaintenanceTaskOpenCloseContext);

  const { canEditMaintenanceTask } = usePermissions();

  const dispatch = useAppDispatch();
  const staffId = useAppSelector(selectUser).id;
  const systemShortName = useAppSelector(selectSystem).shortName;
  const currentLoggedInStaffId = useAppSelector(selectUser).id;

  const canRemove = useMemo(
    () =>
      canRemoveMaintenanceTaskPersonnelAssignmentInternalStaff({
        isMaintenanceTaskOpen: isOpen,
        maintenanceTask,
        currentLoggedInStaffId,
        canEditMaintenanceTask,
        maintenanceTaskPersonnelAssignmentInternalStaff,
      }),
    [
      canEditMaintenanceTask,
      currentLoggedInStaffId,
      isOpen,
      maintenanceTask,
      maintenanceTaskPersonnelAssignmentInternalStaff,
    ]
  );

  const remove = useCallback(() => {
    const maintenanceTaskEntityIdentifier = {
      id: maintenanceTask.id,
      type: EntityType.maintenanceTask,
    };

    const entityIdentifier = {
      id: maintenanceTaskPersonnelAssignmentInternalStaff.id,
      type: EntityType.maintenanceTaskPersonnelAssignmentInternalStaff,
    };

    dispatch(
      removeFromEntityPropertyList({
        staffId,
        systemShortName,
        baseEntityIdentifier: maintenanceTaskEntityIdentifier,
        propertyName: "personnelAssignments",
        entityIdentifierToRemove: entityIdentifier,
      })
    );

    dispatch(
      removeFromMyEntities({
        staffId,
        systemShortName,
        entityIdentifiers: [entityIdentifier],
      })
    );
  }, [
    dispatch,
    maintenanceTask.id,
    maintenanceTaskPersonnelAssignmentInternalStaff.id,
    staffId,
    systemShortName,
  ]);

  return {
    canRemove,
    remove,
  };
};

export default useMaintenanceTaskPersonnelAssignmentInternalStaffRemove;
