import t from "module/translations";
import { InventoryCheckState } from "module/inventoryCheck/types";

export const getInventoryCheckStateTranslation = (
  state: InventoryCheckState
): string =>
  ({
    [InventoryCheckState.scheduled]: t.inventoryCheck.state.scheduled,
    [InventoryCheckState.completed]: t.inventoryCheck.state.completed,
    [InventoryCheckState.validated]: t.inventoryCheck.state.validated,
  }[state]);
