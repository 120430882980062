import React from "react";
import AddButton from "component/addButton/AddButton";
import t from "module/translations";
import useMaintenanceTaskProcessStepQuickFixAdd from "module/maintenanceTaskProcessStepQuickFix/add/useMaintenanceTaskProcessStepQuickFixAdd";

const MaintenanceTaskProcessStepQuickFixAddButtonContainer: React.FC = () => {
  const { add, canAdd } = useMaintenanceTaskProcessStepQuickFixAdd();

  if (!canAdd) {
    return null;
  }

  return (
    <AddButton
      {...{
        onClick: add,
        label: t.maintenanceTask.processStep.quickFixText,
      }}
    />
  );
};

export default React.memo(MaintenanceTaskProcessStepQuickFixAddButtonContainer);
