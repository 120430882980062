import makeStyles from "@mui/styles/makeStyles";

export const useMaintenanceTaskPartListStyles = makeStyles({
  table: {
    minWidth: "100%",
  },
  stockArticleTableCell: {},
  amountTableCell: {
    width: "150px",
  },
  actionsTableCell: {
    width: "50px",
  },
});
