import React from "react";
import { EntityFieldMetaDataContextInterface } from "module/entity/context/fieldMetaData/EntityFieldMetaDataContext";

export type MaintenanceTaskPersonnelAssignmentInternalStaffFieldMetaDataContextInterface =
  EntityFieldMetaDataContextInterface;

const MaintenanceTaskPersonnelAssignmentInternalStaffFieldMetaDataContext =
  React.createContext(
    <
      MaintenanceTaskPersonnelAssignmentInternalStaffFieldMetaDataContextInterface
    >{}
  );

export default MaintenanceTaskPersonnelAssignmentInternalStaffFieldMetaDataContext;
