import React, { useMemo } from "react";
import { useRouteMatch } from "react-router-dom";
import NavigationBar from "component/navigationBar/NavigationBar";
import NavigationBarItemBack from "component/navigationBar/NavigationBarItemBack";
import NavigationBarItem from "component/navigationBar/NavigationBarItem";
import NavigationBarTabs from "component/navigationBar/NavigationBarTabs";
import t from "module/translations";

export interface MyMaintenanceTaskDetailsNavigationProps {
  listPath: string;
  overviewPath: string;
  processStepPath?: string;
  partsPath?: string;
  personnelAssignmentPath?: string;
}

const MyMaintenanceTaskDetailsNavigation: React.FC<MyMaintenanceTaskDetailsNavigationProps> =
  ({
    listPath,
    overviewPath,
    processStepPath,
    partsPath,
    personnelAssignmentPath,
  }) => {
    const matchedOverview = Boolean(
      useRouteMatch({
        path: overviewPath,
        exact: true,
      })
    );
    const matchedProcessSteps = Boolean(
      useRouteMatch({
        path: processStepPath,
        exact: true,
      })
    );
    const matchedParts = Boolean(
      useRouteMatch({
        path: partsPath,
        exact: true,
      })
    );
    const matchedPersonnelAssignments = Boolean(
      useRouteMatch({
        path: personnelAssignmentPath,
        exact: true,
      })
    );

    const matchedList = false;

    const tabMap: boolean[] = useMemo(
      () => [
        matchedList,
        matchedOverview,
        matchedProcessSteps,
        matchedParts,
        matchedPersonnelAssignments,
      ],
      [
        matchedList,
        matchedOverview,
        matchedParts,
        matchedPersonnelAssignments,
        matchedProcessSteps,
      ]
    );

    return (
      <NavigationBar>
        <NavigationBarTabs {...{ tabMap }}>
          <NavigationBarItemBack
            {...{
              key: listPath,
              path: listPath,
            }}
          />
          <NavigationBarItem
            {...{
              key: overviewPath,
              path: overviewPath,
              title: t.routes.maintenanceTaskDetailsOverview,
            }}
          />
          {processStepPath && (
            <NavigationBarItem
              {...{
                key: processStepPath,
                path: processStepPath,
                title: t.routes.maintenanceTaskDetailsProcessSteps,
              }}
            />
          )}
          {partsPath && (
            <NavigationBarItem
              {...{
                key: partsPath,
                path: partsPath,
                title: t.routes.myMaintenanceTaskDetailsParts,
              }}
            />
          )}
          {personnelAssignmentPath && (
            <NavigationBarItem
              {...{
                key: personnelAssignmentPath,
                path: personnelAssignmentPath,
                title: t.routes.maintenanceTaskDetailsPersonnelAssignments,
              }}
            />
          )}
        </NavigationBarTabs>
      </NavigationBar>
    );
  };

export default React.memo(MyMaintenanceTaskDetailsNavigation);
