import { XValidatorErrorsInterface } from "module/validation/types";
import { InventoryCheckInterface } from "module/inventoryCheck/types";

export interface InventoryCheckValidationResult {
  errors: null | XValidatorErrorsInterface;
  warnings: null | XValidatorErrorsInterface;
}

export const validateInventoryCheck = (
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  inventoryCheck: InventoryCheckInterface
): InventoryCheckValidationResult => {
  const errors: XValidatorErrorsInterface = {};
  const warnings: XValidatorErrorsInterface = {};

  return {
    errors: Object.keys(errors).length ? errors : null,
    warnings: Object.keys(warnings).length ? warnings : null,
  };
};
