import React, { useMemo } from "react";
import useMyMaintenanceTaskOpenClose from "module/maintenanceTask/openClose/useMyMaintenanceTaskOpenClose";
import MaintenanceTaskOpenCloseContext, {
  MaintenanceTaskOpenCloseContextInterface,
} from "module/maintenanceTask/openClose/context/MaintenanceTaskOpenCloseContext";

interface Prop {
  children: React.ReactNode;
}

const MyMaintenanceTaskOpenCloseContextProvider: React.FC<Prop> = ({
  children,
}) => {
  const { open, close } = useMyMaintenanceTaskOpenClose();

  const contextValue: MaintenanceTaskOpenCloseContextInterface = useMemo(
    () => ({
      isOpen: close !== undefined,
      open,
      close,
    }),
    [close, open]
  );

  return (
    <MaintenanceTaskOpenCloseContext.Provider value={contextValue}>
      {children}
    </MaintenanceTaskOpenCloseContext.Provider>
  );
};

export default MyMaintenanceTaskOpenCloseContextProvider;
