import { RootState } from "redux/store";
import selectConfigurationForMeForCurrentSystem from "module/staff/redux/selector/selectConfigurationForMeForCurrentSystem";
import { EntityIdentifier } from "module/entity/types";
import { getEntityIdentifierKey } from "module/entity/helper/getEntityIdentifierKey";

const selectHasEntityOrRelatedEntityWarning =
  (state: RootState) =>
  (entityIdentifier: EntityIdentifier): boolean => {
    const hasEntityOrRelatedEntityWarning = (ei: EntityIdentifier): boolean => {
      const myEntity =
        selectConfigurationForMeForCurrentSystem(state).entities[
          getEntityIdentifierKey(ei)
        ];

      if (myEntity.validationWarnings != null) {
        return true;
      }
      let result = false;
      // eslint-disable-next-line guard-for-in,no-restricted-syntax
      for (const index in myEntity.data.relationshipIdentifiers) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        if (
          hasEntityOrRelatedEntityWarning(
            myEntity.data.relationshipIdentifiers[index]
          )
        ) {
          result = true;
          break;
        }
      }

      return result;
    };

    return hasEntityOrRelatedEntityWarning(entityIdentifier);
  };

export default selectHasEntityOrRelatedEntityWarning;
