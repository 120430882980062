import React from "react";
import Drawer from "@mui/material/Drawer";
import makeStyles from "@mui/styles/makeStyles";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import { DRAWER_WIDTH } from "component/sidemenu/constants";

const useStyles = makeStyles((theme) => ({
  drawer: {
    width: DRAWER_WIDTH,
    flexShrink: 0,
  },
  drawerPaper: {
    width: DRAWER_WIDTH,
  },
  drawerMenu: {
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: "flex-end",
  },
}));

interface Props {
  isOpen: boolean;
  close: () => void;
  children: React.ReactNode;
}

const SideMenu: React.FC<Props> = ({ isOpen, close, children }) => {
  const classes = useStyles();

  return (
    <Drawer
      className={classes.drawer}
      variant="persistent"
      anchor="left"
      open={isOpen}
      classes={{
        paper: classes.drawerPaper,
      }}
    >
      <div className={classes.drawerMenu}>
        <IconButton onClick={close} size="large">
          <ChevronLeftIcon />
        </IconButton>
      </div>
      <Divider />
      {children}
    </Drawer>
  );
};

export default React.memo(SideMenu);
