import React, { useContext } from "react";
import XLabelField from "component/labelField/XLabelField";
import t from "module/translations";
import InventoryCheckContext from "module/inventoryCheck/context/InventoryCheckContext";
import { InventoryCheckInterface } from "module/inventoryCheck/types";

const InventoryCheckSubjectFieldContainer: React.FC = () => {
  const { entity } = useContext(InventoryCheckContext);
  const { subject } = entity as InventoryCheckInterface;

  return (
    <XLabelField
      {...{
        caption: t.inventoryCheck.subjectText,
        label: subject,
      }}
    />
  );
};

export default React.memo(InventoryCheckSubjectFieldContainer);
