import React from "react";
import { EntityOpenCloseContextInterface } from "module/entity/context/openClose/EntityOpenCloseContext";

export type TrainingCustomOpenCloseContextInterface =
  EntityOpenCloseContextInterface;

const TrainingCustomOpenCloseContext = React.createContext(
  <TrainingCustomOpenCloseContextInterface>{}
);

export default TrainingCustomOpenCloseContext;
