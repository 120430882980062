import React from "react";
import { EntityFieldMetaDataContextInterface } from "module/entity/context/fieldMetaData/EntityFieldMetaDataContext";

export type MaintenanceTaskFieldMetaDataContextInterface =
  EntityFieldMetaDataContextInterface;

const MaintenanceTaskFieldMetaDataContext = React.createContext(
  <MaintenanceTaskFieldMetaDataContextInterface>{}
);

export default MaintenanceTaskFieldMetaDataContext;
