import { useCallback } from "react";
import { useAppSelector } from "redux/hooks";
import selectEntity from "module/staff/redux/selector/selectEntity";
import { EntityType } from "module/entity/types";
import { MyMaintenanceTaskInterface } from "module/maintenanceTask/types";

interface Result {
  getReadableIdentifier: (id: string) => string;
}

const useMyMaintenanceTaskReadableIdentifier = (): Result => {
  const selectEntityHelper = useAppSelector(selectEntity);
  const getReadableIdentifier = useCallback(
    (id: string): string => {
      const myEntity = selectEntityHelper({
        id,
        type: EntityType.maintenanceTask,
      }) as MyMaintenanceTaskInterface;
      return myEntity.data.name as string;
    },
    [selectEntityHelper]
  );

  return {
    getReadableIdentifier,
  };
};

export default useMyMaintenanceTaskReadableIdentifier;
