import React, { useCallback } from "react";
import TableCell from "@mui/material/TableCell";
import Checkbox from "@mui/material/Checkbox";
import t from "module/translations";
import TableHeadRow from "component/table/tableHeadRow/TableHeadRow";
import TableHead from "@mui/material/TableHead";
import { MaintenanceTaskSortProperty } from "pages/maintenanceTaskList/types";
import TableSortCellContainer from "module/sort/enrichedComponent/TableSortCellContainer";

interface Props {
  toggleAll: (checked: boolean) => void;
  areAllSelected: boolean;
  isAnySelected: boolean;
  hasPermissionToDownload: boolean;
}

const MaintenanceTaskListTableHead: React.FC<Props> = ({
  toggleAll,
  areAllSelected,
  isAnySelected,
  hasPermissionToDownload,
}) => {
  const handleToggleAll = useCallback(
    (event: { target: { checked: boolean } }) =>
      toggleAll(event.target.checked),
    [toggleAll]
  );

  return (
    <TableHead>
      <TableHeadRow>
        <TableCell />
        {hasPermissionToDownload && (
          <TableCell padding="checkbox">
            <Checkbox
              indeterminate={isAnySelected}
              checked={areAllSelected}
              onChange={handleToggleAll}
            />
          </TableCell>
        )}
        <TableCell align="left">
          {t.maintenanceTask.reportingNumberText}
        </TableCell>
        <TableSortCellContainer
          {...{
            fieldName: MaintenanceTaskSortProperty.name,
          }}
        >
          {t.maintenanceTask.nameText}
        </TableSortCellContainer>
        <TableCell align="left">{t.maintenanceTask.typeText}</TableCell>
        <TableSortCellContainer
          {...{
            fieldName: MaintenanceTaskSortProperty.scheduled,
          }}
        >
          {t.maintenanceTask.scheduledDateText}
        </TableSortCellContainer>
        <TableCell align="left">{t.maintenanceTask.windowText}</TableCell>
        <TableCell align="left">
          {t.maintenanceTask.timeDeviationText}
        </TableCell>
        <TableSortCellContainer
          {...{
            fieldName: MaintenanceTaskSortProperty.state,
          }}
        >
          {t.maintenanceTask.stateText}
        </TableSortCellContainer>
      </TableHeadRow>
    </TableHead>
  );
};

export default MaintenanceTaskListTableHead;
