import React from "react";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import TrainingCustomAssigneeAchievedFieldContainer from "module/trainingCustomAssignee/enrichedComponent/achievedField/TrainingCustomAssigneeAchievedFieldContainer";
import StaffNameText from "module/staff/StaffNameText";
import { TrainingCustomAssigneeInterface } from "module/trainingCustom/types";
import TrainingCustomAssigneeRemarksFieldContainer from "module/trainingCustomAssignee/enrichedComponent/remarksField/TrainingCustomAssigneeRemarksFieldContainer";

interface Props {
  trainingCustomAssignee: TrainingCustomAssigneeInterface;
}

const TrainingCustomAssigneeTableRow: React.FC<Props> = ({
  trainingCustomAssignee,
}) => {
  return (
    <TableRow hover>
      <TableCell align="left">
        {trainingCustomAssignee.staffId != null && (
          <StaffNameText {...{ staffId: trainingCustomAssignee.staffId }} />
        )}
        {trainingCustomAssignee.externalAssigneeName}
      </TableCell>
      <TableCell align="left">
        <TrainingCustomAssigneeAchievedFieldContainer />
      </TableCell>
      <TableCell align="left">
        <TrainingCustomAssigneeRemarksFieldContainer />
      </TableCell>
    </TableRow>
  );
};

export default React.memo(TrainingCustomAssigneeTableRow);
