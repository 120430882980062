import React from "react";
import { useAppSelector } from "redux/hooks";
import { useHistory } from "react-router-dom";
import ErrorPage from "error/ErrorPage";
import {
  getPathToTrainingDetails,
  getPathToTrainingGroupDetails,
} from "module/training/helper/getPath";
import { getPathToAssessmentDetails } from "module/assessment/helper/getPath";
import selectOpenItemByEntityType from "module/staff/redux/selector/selectOpenItemByEntityType";
import { EntityType } from "module/entity/types";
import selectOpenItemsByEntityType from "module/staff/redux/selector/selectOpenItemsByEntityType";
import { getPathToTrainingCustomDetails } from "module/trainingCustom/helper/getPath";

const MyOpenCompetencyManagementDetailsPage: React.FC = () => {
  const openTrainingIdentifiers = useAppSelector(
    selectOpenItemsByEntityType(EntityType.training)
  );
  const openAssessmentIdentifier = useAppSelector(
    selectOpenItemByEntityType(EntityType.assessment)
  );
  const openTrainingCustomIdentifier = useAppSelector(
    selectOpenItemByEntityType(EntityType.trainingCustom)
  );
  const history = useHistory();
  if (openTrainingIdentifiers.length > 1) {
    history.push(
      getPathToTrainingGroupDetails(
        openTrainingIdentifiers.map(
          (openTrainingIdentifier) => openTrainingIdentifier.id
        )
      )
    );
  } else if (openTrainingIdentifiers.length === 1) {
    history.push(getPathToTrainingDetails(openTrainingIdentifiers[0].id));
  } else if (openAssessmentIdentifier) {
    history.push(getPathToAssessmentDetails(openAssessmentIdentifier.id));
  } else if (openTrainingCustomIdentifier) {
    history.push(
      getPathToTrainingCustomDetails(openTrainingCustomIdentifier.id)
    );
  }

  return <ErrorPage />;
};

export default React.memo(MyOpenCompetencyManagementDetailsPage);
