import React from "react";
import { EntityChangeContextInterface } from "module/entity/context/change/EntityChangeContext";

export type MaintenanceTaskChangeContextInterface =
  EntityChangeContextInterface;

const MaintenanceTaskChangeContext = React.createContext(
  <MaintenanceTaskChangeContextInterface>{}
);

export default MaintenanceTaskChangeContext;
