import React from "react";
import { EntitySignContextInterface } from "module/entity/context/sign/EntitySignContext";

export type TrainingCustomSignContextInterface = EntitySignContextInterface;

const TrainingCustomSignContext = React.createContext(
  <TrainingCustomSignContextInterface>{}
);

export default TrainingCustomSignContext;
