import axios from "axios";
import { SortType } from "module/sort/types";
import { mapApiInventoryCheckToApp } from "module/inventoryCheck/helper/mapApiInventoryCheckToApp";
import { MyInventoryCheckInterface } from "module/inventoryCheck/types";
import { ApiInventoryCheckInterface } from "./types";
import { InventoryCheckFilters } from "../../pages/inventoryCheckList/types";

interface ApiGetInventoryChecksResponse {
  items: ApiInventoryCheckInterface[];
}

export const mapApiResponse = (
  rawData: ApiGetInventoryChecksResponse
): MyInventoryCheckInterface[] => rawData.items.map(mapApiInventoryCheckToApp);

const getInventoryChecks = async (
  filters: InventoryCheckFilters,
  sort: SortType
): Promise<MyInventoryCheckInterface[]> => {
  const response = await axios.get(
    `/api/inventory-checks?` +
      `states=${filters.states?.join()}&` +
      `sort=${sort.fieldName}&` +
      `sort_direction=${sort.direction}`
  );
  return mapApiResponse(response.data);
};

export default getInventoryChecks;
