import React, { useContext } from "react";
import MaintenanceTaskContext from "module/maintenanceTask/context/MaintenanceTaskContext";
import { MaintenanceTaskInterface } from "module/maintenanceTask/types";
import ToBeSignedListTableRowMaintenanceTask from "pages/toBeSignedList/table/row/maintenanceTask/ToBeSignedListTableRowMaintenanceTask";

const ToBeSignedListTableRowMaintenanceTaskContainer: React.FC = () => {
  const { entity } = useContext(MaintenanceTaskContext);

  const maintenanceTask = entity as MaintenanceTaskInterface;

  return (
    <ToBeSignedListTableRowMaintenanceTask
      {...{
        maintenanceTask,
      }}
    />
  );
};

export default React.memo(ToBeSignedListTableRowMaintenanceTaskContainer);
