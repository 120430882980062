import React, { useContext, useCallback } from "react";
import TextField from "component/textField/TextField";
import MaintenanceTaskPersonnelAssignmentExternalStaffContext from "module/maintenanceTaskPersonnelAssignment/externalStaff/context/MaintenanceTaskPersonnelAssignmentExternalStaffContext";
import MaintenanceTaskPersonnelAssignmentExternalStaffChangeContext from "module/maintenanceTaskPersonnelAssignment/externalStaff/change/MaintenanceTaskPersonnelAssignmentExternalStaffChangeContext";
import MaintenanceTaskPersonnelAssignmentExternalStaffFieldMetaDataContext from "module/maintenanceTaskPersonnelAssignment/externalStaff/validation/fieldMetaData/MaintenanceTaskPersonnelAssignmentExternalStaffFieldMetaDataContext";
import { MaintenanceTaskPersonnelAssignmentExternalStaffInterface } from "module/maintenanceTaskPersonnelAssignment/externalStaff/types";
import { MAINTENANCE_TASK_PERSONNEL_ASSIGNMENT_EXTERNAL_STAFF_COMPANY_FIELD_NAME } from "module/maintenanceTaskPersonnelAssignment/externalStaff/constants";
import XLabelField from "component/labelField/XLabelField";
import { getFieldMetaDataWithErrorHandling } from "module/fieldMetaData/getFieldMetaData";

const MaintenanceTaskPersonnelAssignmentExternalStaffCompanyFieldContainer: React.FC =
  () => {
    const { entity } = useContext(
      MaintenanceTaskPersonnelAssignmentExternalStaffContext
    );
    const { change: changeHelper } = useContext(
      MaintenanceTaskPersonnelAssignmentExternalStaffChangeContext
    );
    const { getFieldMetaData } = useContext(
      MaintenanceTaskPersonnelAssignmentExternalStaffFieldMetaDataContext
    );

    const maintenanceTaskPersonnelAssignment =
      entity as MaintenanceTaskPersonnelAssignmentExternalStaffInterface;

    const { warnings, errors, isEditable } = getFieldMetaDataWithErrorHandling(
      () =>
        getFieldMetaData(
          MAINTENANCE_TASK_PERSONNEL_ASSIGNMENT_EXTERNAL_STAFF_COMPANY_FIELD_NAME
        )
    );

    const change = useCallback(
      (company?: string) => {
        changeHelper({ company });
      },
      [changeHelper]
    );

    const value = maintenanceTaskPersonnelAssignment.company;

    if (isEditable) {
      return (
        <TextField
          {...{
            value,
            change,
            errors,
          }}
        />
      );
    }

    return <XLabelField {...{ label: value, warnings, errors }} />;
  };

export default React.memo(
  MaintenanceTaskPersonnelAssignmentExternalStaffCompanyFieldContainer
);
