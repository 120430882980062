import React, { useContext } from "react";
import { getFieldMetaData as getFieldMetaDataHelper } from "module/fieldMetaData/getFieldMetaData";
import AssessmentOpenCloseContext from "module/assessment/openClose/context/AssessmentOpenCloseContext";
import AssessmentFieldMetaDataContext, {
  MyAssessmentFieldMetaDataContextInterface,
} from "module/assessment/validation/fieldMetaData/AssessmentFieldMetaDataContext";
import AssessmentStateContext from "module/assessment/state/context/AssessmentStateContext";
import AssessmentValidationResultContext from "module/assessment/validation/AssessmentValidationResultContext";

interface Prop {
  children: React.ReactNode;
}

const MyAssessmentFieldMetaDataContextProvider: React.FC<Prop> = ({
  children,
}) => {
  const { validationWarnings, validationErrors } = useContext(
    AssessmentValidationResultContext
  );
  const { isOpen } = useContext(AssessmentOpenCloseContext);
  const { isStateScheduledOrLower } = useContext(AssessmentStateContext);

  const getFieldMetaData = getFieldMetaDataHelper({
    validationWarnings,
    validationErrors,
    isOpen,
    isStateScheduledOrLower,
  });

  const contextValue: MyAssessmentFieldMetaDataContextInterface = {
    getFieldMetaData,
  };

  return (
    <AssessmentFieldMetaDataContext.Provider value={contextValue}>
      {children}
    </AssessmentFieldMetaDataContext.Provider>
  );
};

export default MyAssessmentFieldMetaDataContextProvider;
