import React from "react";
import LabelField from "component/labelField/LabelField";
import t from "module/translations";

interface Props {
  name: string;
}

const AssessmentNameLabelField: React.FC<Props> = ({ name }) => {
  return (
    <LabelField
      {...{
        caption: t.competencyManagement.assessment.nameText,
        label: name,
      }}
    />
  );
};

export default React.memo(AssessmentNameLabelField);
