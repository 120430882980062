import React, { useContext } from "react";
import InventoryCheckContext from "module/inventoryCheck/context/InventoryCheckContext";
import { InventoryCheckInterface } from "module/inventoryCheck/types";
import XLabelField from "component/labelField/XLabelField";
import t from "module/translations";
import { getInventoryCheckStateTranslation } from "module/inventoryCheck/helper/getInventoryCheckStateTranslation";

const InventoryCheckStateLabelFieldContainer: React.FC = () => {
  const { entity } = useContext(InventoryCheckContext);
  const { state } = entity as InventoryCheckInterface;

  return (
    <XLabelField
      {...{
        caption: t.inventoryCheck.stateText,
        label: getInventoryCheckStateTranslation(state),
      }}
    />
  );
};

export default React.memo(InventoryCheckStateLabelFieldContainer);
