import React from "react";
import MenuItem from "@mui/material/MenuItem";
import { useAppSelector } from "redux/hooks";
import { selectUser } from "module/auth/redux/authSlice";

const UserNameMenuItem: React.FC = () => {
  const user = useAppSelector(selectUser);

  return (
    <MenuItem>
      {user.firstName} {user.lastName}
    </MenuItem>
  );
};

export default React.memo(UserNameMenuItem);
