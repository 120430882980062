import React from "react";
import t from "module/translations";
import StickyPaper from "component/sticky/StickyPaper";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import AssessmentNameFieldContainer from "module/assessment/enrichedComponent/nameField/AssessmentNameFieldContainer";
import AssessmentAssigneeContainer from "module/assessment/enrichedComponent/assigneeField/AssessmentAssigneeContainer";
import AssessmentModuleFieldContainer from "module/assessment/enrichedComponent/moduleField/AssessmentModuleFieldContainer";
import AssessmentTrainerFieldContainer from "module/assessment/enrichedComponent/trainerField/AssessmentTrainerFieldContainer";
import AssessmentRemarksFieldContainer from "module/assessment/enrichedComponent/remarksField/AssessmentRemarksFieldContainer";
import AssessmentStateLabelFieldContainer from "module/assessment/enrichedComponent/stateField/AssessmentStateLabelFieldContainer";
import AssessmentExpectedDateFieldContainer from "module/assessment/enrichedComponent/expectedDateField/AssessmentExpectedDateFieldContainer";
import AssessmentScheduledDateFieldContainer from "module/assessment/enrichedComponent/scheduledDateField/AssessmentScheduledDateFieldContainer";
import AssessmentCompletionStartDateTimeFieldContainer from "module/assessment/enrichedComponent/completionStartDateTimeField/AssessmentCompletionStartDateTimeFieldContainer";
import AssessmentCompletionEndDateTimeFieldContainer from "module/assessment/enrichedComponent/completionEndDateTimeField/AssessmentCompletionEndDateTimeFieldContainer";
import AssessmentReportingNumberFieldContainer from "module/assessment/enrichedComponent/reportingNumberField/AssessmentReportingNumberFieldContainer";

interface MyTrainingDetailsOverviewProps {
  isStateEditable: boolean;
}

const MyAssessmentDetailsOverview: React.FC<MyTrainingDetailsOverviewProps> = ({
  isStateEditable,
}) => {
  return (
    <StickyPaper>
      <Grid container spacing={3}>
        <Grid item xs={6}>
          <AssessmentReportingNumberFieldContainer />
        </Grid>
        <Grid item xs={6}>
          <AssessmentNameFieldContainer />
        </Grid>
        <Grid item xs={6}>
          <AssessmentAssigneeContainer />
        </Grid>
        <Grid item xs={6}>
          <AssessmentModuleFieldContainer />
        </Grid>
        <Grid item xs={6}>
          <AssessmentTrainerFieldContainer />
        </Grid>
      </Grid>
      <AssessmentRemarksFieldContainer />
      <Typography variant="h6" gutterBottom>
        {t.competencyManagement.assessment.scheduledDateHeader}
      </Typography>
      <Grid container spacing={3}>
        <Grid item xs={6}>
          <AssessmentExpectedDateFieldContainer />
        </Grid>
        <Grid item xs={6}>
          <AssessmentScheduledDateFieldContainer />
        </Grid>
      </Grid>
      <Typography variant="h6" gutterBottom>
        {t.competencyManagement.assessment.completionDateHeader}
      </Typography>
      <Grid container spacing={3}>
        <Grid item xs={6}>
          <AssessmentCompletionStartDateTimeFieldContainer />
        </Grid>
        <Grid item xs={6}>
          <AssessmentCompletionEndDateTimeFieldContainer />
        </Grid>
      </Grid>
      {!isStateEditable && <AssessmentStateLabelFieldContainer />}
    </StickyPaper>
  );
};

export default React.memo(MyAssessmentDetailsOverview);
