import {
  MaintenanceTaskInterface,
  MaintenanceTaskState,
} from "module/maintenanceTask/types";

export const isStateCompletedOrHigher = (
  maintenanceTask: MaintenanceTaskInterface
): boolean =>
  [MaintenanceTaskState.completed, MaintenanceTaskState.validated].includes(
    maintenanceTask.state
  );
