import React from "react";
import { EntityChangeContextInterface } from "module/entity/context/change/EntityChangeContext";

export type MaintenanceTaskPersonnelAssignmentExternalStaffChangeContextInterface =
  EntityChangeContextInterface;

const MaintenanceTaskPersonnelAssignmentExternalStaffChangeContext =
  React.createContext(
    <MaintenanceTaskPersonnelAssignmentExternalStaffChangeContextInterface>{}
  );

export default MaintenanceTaskPersonnelAssignmentExternalStaffChangeContext;
