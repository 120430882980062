import React, { useMemo } from "react";
import MyMaintenanceTaskDetailsNavigation, {
  MyMaintenanceTaskDetailsNavigationProps,
} from "pages/myMaintenanceTaskDetails/navigation/MyMaintenanceTaskDetailsNavigation";
import routes from "routes/routes";
import {
  getPathToMaintenanceTaskToBeSignedDetailsOverview,
  getPathToMaintenanceTaskToBeSignedDetailsParts,
  getPathToMaintenanceTaskToBeSignedDetailsPersonnelAssignments,
  getPathToMaintenanceTaskToBeSignedDetailsProcessSteps,
} from "module/maintenanceTask/helper/getPath";
import { Route } from "react-router-dom";

interface Props {
  maintenanceTaskId: string;
}

const MaintenanceTaskToBeSignedDetails: React.FC<Props> = ({
  maintenanceTaskId,
}) => {
  const navigationProps: MyMaintenanceTaskDetailsNavigationProps = useMemo(
    () => ({
      listPath: routes.toBeSigned.path,
      overviewPath:
        getPathToMaintenanceTaskToBeSignedDetailsOverview(maintenanceTaskId),
      processStepPath:
        getPathToMaintenanceTaskToBeSignedDetailsProcessSteps(
          maintenanceTaskId
        ),
      partsPath:
        getPathToMaintenanceTaskToBeSignedDetailsParts(maintenanceTaskId),
      personnelAssignmentPath:
        getPathToMaintenanceTaskToBeSignedDetailsPersonnelAssignments(
          maintenanceTaskId
        ),
    }),
    [maintenanceTaskId]
  );

  return (
    <>
      <MyMaintenanceTaskDetailsNavigation {...navigationProps} />
      <Route
        {...{
          key: routes.maintenanceTaskToBeSignedDetailsOverview.path,
          path: routes.maintenanceTaskToBeSignedDetailsOverview.path,
          render: () => (
            <routes.maintenanceTaskToBeSignedDetailsOverview.mainComponent />
          ),
        }}
      />
      <Route
        {...{
          key: routes.maintenanceTaskToBeSignedDetailsProcessSteps.path,
          path: routes.maintenanceTaskToBeSignedDetailsProcessSteps.path,
          render: () => (
            <routes.maintenanceTaskToBeSignedDetailsProcessSteps.mainComponent />
          ),
          exact: routes.maintenanceTaskToBeSignedDetailsProcessSteps.exact,
        }}
      />
      <Route
        {...{
          key: routes.maintenanceTaskToBeSignedDetailsProcessStepsDetails.path,
          path: routes.maintenanceTaskToBeSignedDetailsProcessStepsDetails.path,
          render: () => (
            <routes.maintenanceTaskToBeSignedDetailsProcessStepsDetails.mainComponent />
          ),
          exact:
            routes.maintenanceTaskToBeSignedDetailsProcessStepsDetails.exact,
        }}
      />
      <Route
        {...{
          key: routes.maintenanceTaskToBeSignedDetailsParts.path,
          path: routes.maintenanceTaskToBeSignedDetailsParts.path,
          render: () => (
            <routes.maintenanceTaskToBeSignedDetailsParts.mainComponent />
          ),
        }}
      />
      <Route
        {...{
          key: routes.maintenanceTaskToBeSignedDetailsPersonnelAssignments.path,
          path: routes.maintenanceTaskToBeSignedDetailsPersonnelAssignments
            .path,
          render: () => (
            <routes.maintenanceTaskToBeSignedDetailsPersonnelAssignments.mainComponent />
          ),
        }}
      />
    </>
  );
};

export default React.memo(MaintenanceTaskToBeSignedDetails);
