import { PayloadAction } from "@reduxjs/toolkit";
import { StaffDataState } from "module/staff/redux/types";

interface DeleteAllDataActionTypeProps {
  staffId: number;
}

const deleteAllDataAction = (
  state: StaffDataState,
  action: PayloadAction<DeleteAllDataActionTypeProps>
): void => {
  const { staffId } = action.payload;

  delete state[staffId];
};

export default deleteAllDataAction;
