import React, { useEffect, useMemo } from "react";
import { useHistory, useParams, useRouteMatch } from "react-router-dom";
import routes from "routes/routes";
import { EntityType } from "module/entity/types";
import { getPathToInventoryCheckDetailsOverview } from "module/inventoryCheck/helper/getPath";
import MyInventoryCheckDetailsContextProvider from "./MyInventoryCheckDetailsContextProvider";
import MyInventoryCheckDetailsContainer from "./MyInventoryCheckDetailsContainer";

const MyInventoryCheckDetailsPage: React.FC = () => {
  const { inventoryCheckId: id } = useParams<{ inventoryCheckId: string }>();
  const entityIdentifier = useMemo(
    () => ({
      id,
      type: EntityType.inventoryCheck,
    }),
    [id]
  );
  const history = useHistory();
  const match = useRouteMatch({
    path: routes.myInventoryCheckDetails.path,
    exact: true,
  });

  useEffect(() => {
    if (id && match) {
      history.push(getPathToInventoryCheckDetailsOverview(id));
    }
  }, [history, id, match]);

  return (
    <MyInventoryCheckDetailsContextProvider {...{ entityIdentifier }}>
      <MyInventoryCheckDetailsContainer />
    </MyInventoryCheckDetailsContextProvider>
  );
};

export default React.memo(MyInventoryCheckDetailsPage);
