import React, { useContext, useMemo } from "react";
import FilterContext from "module/filter/context/FilterContext";
import InventoryList from "./InventoryList";
import { useAppSelector } from "../../redux/hooks";
import { selectStockArticles } from "../../module/auth/redux/authSlice";
import { StockArticle } from "../../module/stockArticle/types";

const InventoryListContainer: React.FC = () => {
  const allStockArticles = useAppSelector(selectStockArticles);
  const { filters } = useContext(FilterContext);

  const stockArticles = useMemo(
    () =>
      Object.values(allStockArticles).filter((stockArticle: StockArticle) => {
        if (
          filters.externalId &&
          !stockArticle.externalId.includes(filters.externalId as string)
        ) {
          return false;
        }

        if (
          filters.name &&
          !stockArticle.name.includes(filters.name as string)
        ) {
          return false;
        }

        return true;
      }),
    [filters, allStockArticles]
  );

  return (
    <InventoryList
      {...{
        stockArticles,
      }}
    />
  );
};

export default React.memo(InventoryListContainer);
