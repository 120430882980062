import React, { useContext } from "react";
import MyTrainingCustomDetailsAssignees from "pages/myTrainingCustomDetails/assignees/MyTrainingCustomDetailsAssignees";
import TrainingCustomContext from "module/trainingCustom/context/TrainingCustomContext";
import { TrainingCustomInterface } from "module/trainingCustom/types";

const MyTrainingCustomDetailsAssigneesContainer: React.FC = () => {
  const { entity } = useContext(TrainingCustomContext);

  const training = entity as TrainingCustomInterface;

  return (
    <MyTrainingCustomDetailsAssignees
      {...{ assigneesIdentifiers: training.assignees }}
    />
  );
};

export default React.memo(MyTrainingCustomDetailsAssigneesContainer);
