import { useCallback, useContext, useMemo, useState } from "react";
import { MaintenanceTaskInterface } from "module/maintenanceTask/types";
import { MaintenanceTaskProcessStepInterface } from "module/maintenanceTaskProcessStep/types";
import MaintenanceTaskContext from "module/maintenanceTask/context/MaintenanceTaskContext";
import MaintenanceTaskOpenCloseContext from "module/maintenanceTask/openClose/context/MaintenanceTaskOpenCloseContext";
import MaintenanceTaskStateContext from "module/maintenanceTask/state/context/MaintenanceTaskStateContext";
import MaintenanceTaskProcessStepContext from "module/maintenanceTaskProcessStep/context/MaintenanceTaskProcessStepContext";
import usePermissions from "module/permission/usePermissions";
import { canAddCorrectiveToMaintenanceTaskProcessStep } from "../helper/canAddCorrectiveToMaintenanceTaskProcessStep";

interface Result {
  canAdd: boolean;
  openModal: () => void;
  closeModal: () => void;
  isModalOpen: boolean;
}

const useMaintenanceTaskProcessStepCorrectiveAdd = (): Result => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { entity: maintenanceTaskEntity } = useContext(MaintenanceTaskContext);
  const maintenanceTask = maintenanceTaskEntity as MaintenanceTaskInterface;

  const { isOpen: isMaintenanceTaskOpen } = useContext(
    MaintenanceTaskOpenCloseContext
  );

  const { isStateScheduledOrLower: isMaintenanceTaskStateScheduledOrLower } =
    useContext(MaintenanceTaskStateContext);

  const { entity: maintenanceTaskProcessStepEntity } = useContext(
    MaintenanceTaskProcessStepContext
  );
  const maintenanceTaskProcessStep =
    maintenanceTaskProcessStepEntity as MaintenanceTaskProcessStepInterface;

  const { canScheduleMaintenanceTask } = usePermissions();

  const canAdd = useMemo(
    () =>
      canAddCorrectiveToMaintenanceTaskProcessStep({
        isMaintenanceTaskOpen,
        isMaintenanceTaskStateScheduledOrLower,
        maintenanceTask,
        maintenanceTaskProcessStep,
        canScheduleMaintenanceTask,
      }),
    [
      canScheduleMaintenanceTask,
      isMaintenanceTaskOpen,
      isMaintenanceTaskStateScheduledOrLower,
      maintenanceTask,
      maintenanceTaskProcessStep,
    ]
  );

  const openModal = useCallback((): void => {
    setIsModalOpen(true);
  }, []);

  const closeModal = useCallback((): void => {
    setIsModalOpen(false);
  }, []);

  return {
    canAdd,
    openModal,
    closeModal,
    isModalOpen,
  };
};

export default useMaintenanceTaskProcessStepCorrectiveAdd;
