import React, { useContext, useMemo } from "react";
import { useParams } from "react-router-dom";
import { getEntityIdentifierKey } from "module/entity/helper/getEntityIdentifierKey";
import { EntityIdentifier } from "module/entity/types";
import MaintenanceTaskContext from "module/maintenanceTask/context/MaintenanceTaskContext";
import MaintenanceTaskToBeSignedProcessStepDetails from "pages/maintenanceTaskToBeSignedDetails/processStepDetails/MaintenanceTaskToBeSignedProcessStepDetails";
import MaintenanceTaskProcessStepContextProvider from "module/maintenanceTaskProcessStep/context/MaintenanceTaskProcessStepContextProvider";
import { MaintenanceTaskInterface } from "module/maintenanceTask/types";

const MaintenanceTaskToBeSignedProcessStepDetailsContainer: React.FC = () => {
  const { entity } = useContext(MaintenanceTaskContext);
  const maintenanceTask = entity as MaintenanceTaskInterface;
  const { processStepId } = useParams<{ processStepId: string }>();

  const entityIdentifier = useMemo(
    () =>
      maintenanceTask.processSteps.find(
        (ei) => ei.id === processStepId
      ) as EntityIdentifier,
    [maintenanceTask.processSteps, processStepId]
  );

  return (
    <MaintenanceTaskProcessStepContextProvider
      {...{
        entityIdentifier,
        key: getEntityIdentifierKey(entityIdentifier),
      }}
    >
      <MaintenanceTaskToBeSignedProcessStepDetails
        {...{ maintenanceTaskId: entity.id }}
      />
    </MaintenanceTaskProcessStepContextProvider>
  );
};

export default React.memo(MaintenanceTaskToBeSignedProcessStepDetailsContainer);
