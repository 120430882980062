import React, { useContext } from "react";
import MyTrainingDetailsOverview from "pages/myTrainingDetails/overview/MyTrainingDetailsOverview";
import TrainingStateContext from "module/training/state/context/TrainingStateContext";

const MyTrainingDetailsOverviewContainer: React.FC = () => {
  const { isStateEditable } = useContext(TrainingStateContext);

  return (
    <MyTrainingDetailsOverview
      {...{
        isStateEditable,
      }}
    />
  );
};

export default React.memo(MyTrainingDetailsOverviewContainer);
