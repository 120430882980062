import { TrainingInterface } from "module/training/types";
import { XValidatorErrorsInterface } from "module/validation/types";

export interface TrainingValidationResult {
  errors: null | XValidatorErrorsInterface;
  warnings: null | XValidatorErrorsInterface;
}

export const validateTraining = (
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  training: TrainingInterface
): TrainingValidationResult => {
  const errors: XValidatorErrorsInterface = {};
  const warnings: XValidatorErrorsInterface = {};

  return {
    errors: Object.keys(errors).length ? errors : null,
    warnings: Object.keys(warnings).length ? warnings : null,
  };
};
