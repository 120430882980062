import React, { useMemo } from "react";
import FormContainsErrors from "component/form/FormContainsErrors";
import MyMaintenanceTaskDetailsNavigation, {
  MyMaintenanceTaskDetailsNavigationProps,
} from "pages/myMaintenanceTaskDetails/navigation/MyMaintenanceTaskDetailsNavigation";
import routes from "routes/routes";
import { getPathToMaintenanceTaskDetailsOverview } from "module/maintenanceTask/helper/getPath";
import { Route } from "react-router-dom";

interface Props {
  maintenanceTaskId: string;
  hasError: boolean;
}

const MyMaintenanceTaskDetailsForServiceRequest: React.FC<Props> = ({
  maintenanceTaskId,
  hasError,
}) => {
  const navigationProps: MyMaintenanceTaskDetailsNavigationProps =
    useMemo(() => {
      const result: MyMaintenanceTaskDetailsNavigationProps = {
        listPath: routes.myMaintenanceTasks.path,
        overviewPath:
          getPathToMaintenanceTaskDetailsOverview(maintenanceTaskId),
      };

      return result;
    }, [maintenanceTaskId]);

  return (
    <>
      {hasError && <FormContainsErrors />}
      <MyMaintenanceTaskDetailsNavigation {...navigationProps} />
      <Route
        {...{
          key: routes.myMaintenanceTaskDetailsOverview.path,
          path: routes.myMaintenanceTaskDetailsOverview.path,
          component: routes.myMaintenanceTaskDetailsOverview.mainComponent,
        }}
      />
    </>
  );
};

export default React.memo(MyMaintenanceTaskDetailsForServiceRequest);
