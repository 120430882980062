import React, { useCallback, useContext } from "react";
import t from "module/translations";
import { getFieldMetaDataWithErrorHandling } from "module/fieldMetaData/getFieldMetaData";
import TextareaField from "component/textareaField/TextareaField";
import XLabelField from "component/labelField/XLabelField";
import InventoryCheckArticleContext from "module/inventoryCheckArticle/context/InventoryCheckArticleContext";
import InventoryCheckArticleChangeContext from "module/inventoryCheckArticle/change/InventoryCheckArticleChangeContext";
import InventoryCheckArticleFieldMetaDataContext from "module/inventoryCheckArticle/validation/fieldMetaData/InventoryCheckArticleFieldMetaDataContext";
import {
  InventoryCheckArticleHasChanged,
  InventoryCheckArticleInterface,
} from "module/inventoryCheckArticle/types";
import { INVENTORY_CHECK_ARTICLE_DESCRIPTION_FIELD_NAME } from "module/inventoryCheckArticle/constants";

const InventoryCheckArticleDescriptionFieldContainer: React.FC = () => {
  const { entity } = useContext(InventoryCheckArticleContext);
  const { change: changeHelper } = useContext(
    InventoryCheckArticleChangeContext
  );
  const { getFieldMetaData } = useContext(
    InventoryCheckArticleFieldMetaDataContext
  );

  const inventoryCheckArticle = entity as InventoryCheckArticleInterface;

  const change = useCallback(
    (description?: string): void => {
      changeHelper({ description });
    },
    [changeHelper]
  );

  const { warnings, errors, isEditable } = getFieldMetaDataWithErrorHandling(
    () => getFieldMetaData(INVENTORY_CHECK_ARTICLE_DESCRIPTION_FIELD_NAME)
  );

  if (
    inventoryCheckArticle.hasChanged !== InventoryCheckArticleHasChanged.yes
  ) {
    return null;
  }

  const label = t.inventoryCheck.article.descriptionText;
  const value = inventoryCheckArticle.description;

  if (isEditable) {
    return (
      <TextareaField
        {...{
          label,
          value,
          change,
          errors,
        }}
      />
    );
  }

  if (!isEditable && value != null) {
    return (
      <XLabelField {...{ caption: label, label: value, errors, warnings }} />
    );
  }

  return null;
};
export default InventoryCheckArticleDescriptionFieldContainer;
