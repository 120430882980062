import React, { useContext } from "react";
import AssessmentContext from "module/assessment/context/AssessmentContext";
import { AssessmentInterface } from "module/assessment/types";
import AssessmentModuleLabelField from "module/assessment/enrichedComponent/moduleField/AssessmentModuleLabelField";

const AssessmentModuleFieldContainer: React.FC = () => {
  const { entity } = useContext(AssessmentContext);
  const { moduleText } = entity as AssessmentInterface;

  return <AssessmentModuleLabelField {...{ module: moduleText }} />;
};

export default React.memo(AssessmentModuleFieldContainer);
