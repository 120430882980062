import React from "react";
import AppBar from "@mui/material/AppBar";
import makeStyles from "@mui/styles/makeStyles";

const useStyles = makeStyles({
  bar: {
    marginBottom: "2em",
  },
});

interface Props {
  children: React.ReactNode;
}

const NavigationBar: React.FC<Props> = ({ children }) => {
  const classes = useStyles();

  return (
    <AppBar position="static" color="default" className={classes.bar}>
      {children}
    </AppBar>
  );
};

export default React.memo(NavigationBar);
