import React from "react";
import makeStyles from "@mui/styles/makeStyles";
import Typography from "@mui/material/Typography";

const useStyles = makeStyles(() => ({
  subtitle: {
    marginLeft: "12px",
    marginTop: "6px",
  },
}));

interface Props {
  children: React.ReactNode;
}

const HeaderSubtitle: React.FC<Props> = ({ children }) => {
  const classes = useStyles();
  return (
    <Typography variant="caption" noWrap className={classes.subtitle}>
      {children}
    </Typography>
  );
};

export default React.memo(HeaderSubtitle);
