import React, { useCallback } from "react";
import StickyPaper from "component/sticky/StickyPaper";
import MaintenanceTaskProcessStepNameFieldContainer from "module/maintenanceTaskProcessStep/enrichedComponent/nameField/MaintenanceTaskProcessStepNameFieldContainer";
import MaintenanceTaskProcessStepAttachmentListContainer from "module/maintenanceTaskProcessStepAttachment/enrichedComponent/list/MaintenanceTaskProcessStepAttachmentListContainer";
import Typography from "@mui/material/Typography";
import t from "module/translations";
import { Link as RouterLink, useHistory } from "react-router-dom";

import Breadcrumbs from "@mui/material/Breadcrumbs";
import MUILink from "@mui/material/Link";

interface Props {
  maintenanceTaskId: string;
}

const MyMaintenanceTaskProcessStepDetails: React.FC<Props> = () => {
  const history = useHistory();

  const handleClick = useCallback(
    (e: React.MouseEvent<HTMLAnchorElement>) => {
      e.preventDefault();
      history.go(-1);
      return false;
    },
    [history]
  );

  return (
    <>
      <Breadcrumbs aria-label="breadcrumb">
        <MUILink
          {...{
            color: "inherit",
            onClick: handleClick,
            component: RouterLink,
          }}
        >
          {t.maintenanceTask.processStep.listText}
        </MUILink>
        <Typography color="textPrimary">
          {t.maintenanceTask.processStep.detailsText}
        </Typography>
      </Breadcrumbs>
      <StickyPaper>
        <MaintenanceTaskProcessStepNameFieldContainer />
        <MaintenanceTaskProcessStepAttachmentListContainer />
      </StickyPaper>
    </>
  );
};

export default React.memo(MyMaintenanceTaskProcessStepDetails);
