import React, { useContext } from "react";
import { getFieldMetaData as getFieldMetaDataHelper } from "module/fieldMetaData/getFieldMetaData";
import AssessmentOpenCloseContext from "module/assessment/openClose/context/AssessmentOpenCloseContext";
import AssessmentObjectiveFieldMetaDataContext, {
  AssessmentObjectiveFieldMetaDataContextInterface,
} from "module/assessmentObjective/valiadation/fieldMetaData/AssessmentObjectiveFieldMetaDataContext";
import AssessmentStateContext from "module/assessment/state/context/AssessmentStateContext";
import AssessmentObjectiveValidationResultContext from "module/assessmentObjective/valiadation/AssessmentObjectiveValidationResultContext";

interface Prop {
  children: React.ReactNode;
}

const MyAssessmentObjectiveFieldMetaDataContextProvider: React.FC<Prop> = ({
  children,
}) => {
  const { validationWarnings, validationErrors } = useContext(
    AssessmentObjectiveValidationResultContext
  );
  const { isOpen } = useContext(AssessmentOpenCloseContext);
  const { isStateScheduledOrLower } = useContext(AssessmentStateContext);

  const getFieldMetaData = getFieldMetaDataHelper({
    validationWarnings,
    validationErrors,
    isOpen,
    isStateScheduledOrLower,
  });

  const contextValue: AssessmentObjectiveFieldMetaDataContextInterface = {
    getFieldMetaData,
  };

  return (
    <AssessmentObjectiveFieldMetaDataContext.Provider value={contextValue}>
      {children}
    </AssessmentObjectiveFieldMetaDataContext.Provider>
  );
};

export default MyAssessmentObjectiveFieldMetaDataContextProvider;
