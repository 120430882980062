import React, { useContext } from "react";
import { getFieldMetaData as getFieldMetaDataHelper } from "module/fieldMetaData/getFieldMetaData";
import MaintenanceTaskOpenCloseContext from "module/maintenanceTask/openClose/context/MaintenanceTaskOpenCloseContext";
import MaintenanceTaskStateContext from "module/maintenanceTask/state/context/MaintenanceTaskStateContext";
import MyMaintenanceTaskProcessStepFieldMetaDataContext, {
  MaintenanceTaskProcessStepFieldMetaDataContextInterface,
} from "module/maintenanceTaskProcessStep/validation/fieldMetaData/MaintenanceTaskProcessStepFieldMetaDataContext";

import MaintenanceTaskProcessStepValidationResultContext from "module/maintenanceTaskProcessStep/validation/MaintenanceTaskProcessStepValidationResultContext";

interface Prop {
  children: React.ReactNode;
}

const MyMaintenanceTaskProcessStepFieldMetaDataContextProvider: React.FC<Prop> =
  ({ children }) => {
    const { validationWarnings, validationErrors } = useContext(
      MaintenanceTaskProcessStepValidationResultContext
    );
    const { isOpen } = useContext(MaintenanceTaskOpenCloseContext);
    const { isStateScheduledOrLower } = useContext(MaintenanceTaskStateContext);

    const getFieldMetaData = getFieldMetaDataHelper({
      validationWarnings,
      validationErrors,
      isOpen,
      isStateScheduledOrLower,
    });

    const contextValue: MaintenanceTaskProcessStepFieldMetaDataContextInterface =
      {
        getFieldMetaData,
      };

    return (
      <MyMaintenanceTaskProcessStepFieldMetaDataContext.Provider
        value={contextValue}
      >
        {children}
      </MyMaintenanceTaskProcessStepFieldMetaDataContext.Provider>
    );
  };

export default MyMaintenanceTaskProcessStepFieldMetaDataContextProvider;
