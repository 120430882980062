import React, { useContext, useMemo } from "react";
import { useParams } from "react-router-dom";
import { getEntityIdentifierKey } from "module/entity/helper/getEntityIdentifierKey";
import MyMaintenanceTaskProcessStepContextProvider from "module/maintenanceTaskProcessStep/context/MyMaintenanceTaskProcessStepContextProvider";
import { EntityType } from "module/entity/types";
import MyMaintenanceTaskProcessStepDetails from "pages/myMaintenanceTaskDetails/processStepDetails/MyMaintenanceTaskProcessStepDetails";
import MaintenanceTaskContext from "module/maintenanceTask/context/MaintenanceTaskContext";
import { MaintenanceTaskInterface } from "module/maintenanceTask/types";

const MyMaintenanceTaskProcessStepDetailsContainer: React.FC = () => {
  const { entity } = useContext(MaintenanceTaskContext);
  const maintenanceTask = entity as MaintenanceTaskInterface;

  const { processStepId } = useParams<{ processStepId: string }>();
  const entityIdentifier = useMemo(
    () => ({
      id: processStepId,
      type: EntityType.maintenanceTaskProcessStep,
    }),
    [processStepId]
  );
  return (
    <MyMaintenanceTaskProcessStepContextProvider
      {...{
        entityIdentifier,
        key: getEntityIdentifierKey(entityIdentifier),
      }}
    >
      <MyMaintenanceTaskProcessStepDetails
        {...{ maintenanceTaskId: maintenanceTask.id }}
      />
    </MyMaintenanceTaskProcessStepContextProvider>
  );
};

export default React.memo(MyMaintenanceTaskProcessStepDetailsContainer);
