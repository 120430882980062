import React from "react";
import StickyPaper from "component/sticky/StickyPaper";
import Grid from "@mui/material/Grid";
import TrainingCustomCompletionDateFieldContainer from "module/trainingCustom/enrichedComponent/completionDateField/TrainingCustomCompletionDateFieldContainer";
import TrainingCustomNameFieldContainer from "module/trainingCustom/enrichedComponent/nameField/TrainingCustomNameFieldContainer";
import TrainingCustomRemarksFieldContainer from "module/trainingCustom/enrichedComponent/remarksField/TrainingRemarksFieldContainer";
import TrainingCustomScheduledDateFieldContainer from "module/trainingCustom/enrichedComponent/scheduledDateField/TrainingCustomScheduledDateFieldContainer";
import TrainingCustomDescriptionFieldContainer from "module/trainingCustom/enrichedComponent/descriptionField/TrainingDescriptionFieldContainer";
import TrainingCustomTrainerFieldContainer from "module/trainingCustom/enrichedComponent/trainerField/TrainingCustomTrainerFieldContainer";
import TrainingCustomStateLabelFieldContainer from "module/trainingCustom/enrichedComponent/stateField/TrainingCustomStateLabelFieldContainer";
import TrainingCustomReportingNumberFieldContainer from "module/trainingCustom/enrichedComponent/reportingNumberField/TrainingCustomReportingNumberFieldContainer";

interface Props {
  isStateEditable: boolean;
}

const MyTrainingCustomDetailsOverview: React.FC<Props> = ({
  isStateEditable,
}) => {
  return (
    <StickyPaper>
      <Grid container spacing={3}>
        <Grid item xs={6}>
          <TrainingCustomReportingNumberFieldContainer />
        </Grid>
        <Grid item xs={6}>
          <TrainingCustomNameFieldContainer />
        </Grid>
        <Grid item xs={6}>
          <TrainingCustomTrainerFieldContainer />
        </Grid>
      </Grid>
      <TrainingCustomDescriptionFieldContainer />
      <TrainingCustomRemarksFieldContainer />
      <Grid container spacing={3}>
        <Grid item xs={6}>
          <TrainingCustomScheduledDateFieldContainer />
        </Grid>
        <Grid item xs={6}>
          <TrainingCustomCompletionDateFieldContainer />
        </Grid>
      </Grid>
      {!isStateEditable && <TrainingCustomStateLabelFieldContainer />}
    </StickyPaper>
  );
};

export default React.memo(MyTrainingCustomDetailsOverview);
