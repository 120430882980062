import React, { useMemo } from "react";
import { useAppSelector } from "redux/hooks";
import selectEntity from "module/staff/redux/selector/selectEntity";
import { EntityIdentifier } from "module/entity/types";
import EntityCollectionContextProvider from "module/entity/context/entityCollection/EntityCollectionContextProvider";

interface MyEntityCollectionContextProviderProps {
  entityIdentifiers: EntityIdentifier[];
  children: React.ReactNode;
}

const EntityCollectionContextProviderRetrievedFromStore: React.FC<MyEntityCollectionContextProviderProps> =
  ({ entityIdentifiers, children }) => {
    const selectEntityHelper = useAppSelector(selectEntity);

    const entityCollection = useMemo(() => {
      const result = new Map();

      // eslint-disable-next-line array-callback-return
      entityIdentifiers.map((entityIdentifier) => {
        const myEntity = selectEntityHelper(entityIdentifier);
        if (myEntity !== undefined) {
          result.set(entityIdentifier, myEntity.data);
        }
      });
      return result;
    }, [entityIdentifiers, selectEntityHelper]);

    return (
      <EntityCollectionContextProvider {...{ entityCollection }}>
        {children}
      </EntityCollectionContextProvider>
    );
  };

export default EntityCollectionContextProviderRetrievedFromStore;
