import React, { useContext } from "react";
import MyAssessmentDetails from "pages/myAssessmentDetails/MyAssessmentDetails";
import AssessmentContext from "module/assessment/context/AssessmentContext";
import AssessmentValidationResultContext from "module/assessment/validation/AssessmentValidationResultContext";
import AssessmentOpenCloseContext from "module/assessment/openClose/context/AssessmentOpenCloseContext";
import AssessmentStateContext from "module/assessment/state/context/AssessmentStateContext";

const MyAssessmentDetailsContainer: React.FC = () => {
  const { entity } = useContext(AssessmentContext);
  const { hasError } = useContext(AssessmentValidationResultContext);
  const { open, close } = useContext(AssessmentOpenCloseContext);
  const { isStateEditable } = useContext(AssessmentStateContext);

  return (
    <MyAssessmentDetails
      {...{
        assessmentId: entity.id,
        hasError,
        open,
        close,
        isStateEditable,
      }}
    />
  );
};

export default React.memo(MyAssessmentDetailsContainer);
