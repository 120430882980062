import React, { useContext } from "react";
import TrainingContext from "module/training/context/TrainingContext";
import { TrainingInterface } from "module/training/types";
import useDateFormat from "toolkit/useDateFormat";
import TrainingScheduledDateLabelField from "module/training/enrichedComponent/scheduledDateField/TrainingScheduledDateLabelField";

const TrainingScheduledDateFieldContainer: React.FC = () => {
  const { entity } = useContext(TrainingContext);
  const { formatDate } = useDateFormat();

  const { scheduledDate } = entity as TrainingInterface;

  return <TrainingScheduledDateLabelField {...{ scheduledDate, formatDate }} />;
};

export default React.memo(TrainingScheduledDateFieldContainer);
