import axios from "axios";
import { SystemInterface } from "module/auth/types";
import { mapAvailableSystem } from "api/auth/getMe/mappers";

const getSystem = async (
  systemIdentifier: string
): Promise<SystemInterface> => {
  const response = await axios.get(
    `/api/system?systemShortName=${systemIdentifier}`
  );
  return mapAvailableSystem(response.data);
};

export default getSystem;
