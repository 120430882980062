import React from "react";
import { EntityValidationResultContextInterface } from "module/entity/context/validationResult/EntityValidationResultContext";

export type MaintenanceTaskProcessStepQuickFixValidationResultContextInterface =
  EntityValidationResultContextInterface;

const MaintenanceTaskProcessStepQuickFixProcessStepValidationResultContext =
  React.createContext(
    <MaintenanceTaskProcessStepQuickFixValidationResultContextInterface>{}
  );

export default MaintenanceTaskProcessStepQuickFixProcessStepValidationResultContext;
