import React, { useContext, useMemo } from "react";
import { TrainingInterface } from "module/training/types";
import TrainingOpenCloseContext, {
  TrainingOpenCloseContextInterface,
} from "module/training/openClose/context/TrainingOpenCloseContext";
import useMyTrainingCollectionOpenClose from "module/training/openClose/useMyTrainingCollectionOpenClose";
import EntityCollectionContext from "module/entity/context/entityCollection/EntityCollectionContext";
import { EntityIdentifier } from "module/entity/types";

interface Prop {
  children: React.ReactNode;
}

const MyTrainingGroupOpenCloseContextProvider: React.FC<Prop> = ({
  children,
}) => {
  const { entityCollection } = useContext(EntityCollectionContext);

  const trainingCollection = entityCollection as Map<
    EntityIdentifier,
    TrainingInterface
  >;

  const trainingCollectionValues = useMemo(
    () => Array.from(trainingCollection.values()),
    [trainingCollection]
  );
  const { open, close } = useMyTrainingCollectionOpenClose(
    trainingCollectionValues
  );

  const contextValue: TrainingOpenCloseContextInterface = {
    isOpen: close !== undefined,
    open,
    close,
  };

  return (
    <TrainingOpenCloseContext.Provider value={contextValue}>
      {children}
    </TrainingOpenCloseContext.Provider>
  );
};

export default MyTrainingGroupOpenCloseContextProvider;
