import React from "react";
import { EntityInterface } from "module/entity/types";
import { EntityContextInterface } from "module/entity/context/entity/EntityContext";

interface EntityContextProviderProps {
  entity: EntityInterface;
  context: React.Context<EntityContextInterface>;
  children: React.ReactNode;
}

const EntityContextProvider: React.FC<EntityContextProviderProps> = ({
  entity,
  context,
  children,
}) => {
  const contextValue: EntityContextInterface = {
    entity,
  };

  return <context.Provider value={contextValue}>{children}</context.Provider>;
};

export default EntityContextProvider;
