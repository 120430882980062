import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";
import GitInfo from "react-git-info/macro";
import { isDevelopment, isProduction } from "toolkit/environment";

if (isDevelopment || isProduction) {
  Sentry.init({
    dsn: "https://23d60ed7a08b48cc88122e907b4c2780@o479825.ingest.sentry.io/5844600",
    integrations: [new Integrations.BrowserTracing(), new Sentry.Replay()],
    environment: process.env.REACT_APP_ENVIRONMENT,
    // We recommend adjusting this value in production, or using tracesSampler
    // for finer control
    tracesSampleRate: 1.0,
    release: GitInfo().commit.hash,
    // This sets the sample rate to be 10%. You may want this to be 100% while
    // in development and sample at a lower rate in production
    replaysSessionSampleRate: 0.1,
    // If the entire session is not sampled, use the below sample rate to sample
    // sessions when an error occurs.
    replaysOnErrorSampleRate: 1.0,
  });
}
