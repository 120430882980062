import routes from "routes/routes";

export const getPathToTrainingCustomDetails = (id: string): string =>
  routes.myTrainingCustomDetails.path.replace(":trainingCustomId", id);

export const getPathToTrainingCustomDetailsOverview = (id: string): string =>
  routes.myTrainingCustomDetailsOverview.path.replace(":trainingCustomId", id);

export const getPathToTrainingCustomDetailsAssignee = (id: string): string =>
  routes.myTrainingCustomDetailsAssignees.path.replace(":trainingCustomId", id);

export const getPathToTrainingCustomToBeSignedDetails = (id: string): string =>
  routes.trainingCustomToBeSignedDetails.path.replace(":trainingCustomId", id);

export const getPathToTrainingCustomToBeSignedDetailsOverview = (
  id: string
): string =>
  routes.trainingCustomToBeSignedDetailsOverview.path.replace(
    ":trainingCustomId",
    id
  );

export const getPathToTrainingCustomToBeSignedDetailsAssignees = (
  id: string
): string =>
  routes.trainingCustomToBeSignedDetailsAssignees.path.replace(
    ":trainingCustomId",
    id
  );
