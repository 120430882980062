import React, { useContext, useCallback, useMemo } from "react";
import { useHistory } from "react-router-dom";
import routes from "routes/routes";
import TrainingCustomContext from "module/trainingCustom/context/TrainingCustomContext";
import TrainingCustomSignContext from "module/trainingCustom/sign/TrainingCustomSignContext";
import {
  MyTrainingCustomAssigneeInterface,
  TrainingCustomInterface,
} from "module/trainingCustom/types";
import TrainingCustomToBeSignedDetailsAssignees from "pages/trainingCustomToBeSignedDetails/assignees/TrainingCustomToBeSignedDetailsAssignees";
import { EntityIdentifier } from "module/entity/types";
import { useAppSelector } from "redux/hooks";
import { selectUser } from "module/auth/redux/authSlice";

const TrainingCustomToBeSignedDetailsAssigneesContainer: React.FC = () => {
  const { entity } = useContext(TrainingCustomContext);
  const { sign: signHelper } = useContext(TrainingCustomSignContext);
  const history = useHistory();

  const trainingCustom = entity as TrainingCustomInterface;

  const staffId = useAppSelector(selectUser).id;

  const sign = useCallback(async (): Promise<void> => {
    await signHelper();
    history.push(routes.toBeSigned.path);
  }, [history, signHelper]);

  const assigneeIdentifiers: EntityIdentifier[] = useMemo(
    () =>
      trainingCustom.internalTrainerId === staffId
        ? trainingCustom.assignees
        : trainingCustom.assignees.filter((assigneeIdentifier) => {
            if (trainingCustom.relationships == null) {
              return false;
            }

            const assignee = trainingCustom.relationships.get(
              assigneeIdentifier
            ) as MyTrainingCustomAssigneeInterface;
            return assignee.data.staffId === staffId;
          }),
    [
      staffId,
      trainingCustom.assignees,
      trainingCustom.internalTrainerId,
      trainingCustom.relationships,
    ]
  );

  return (
    <TrainingCustomToBeSignedDetailsAssignees
      {...{ assigneeIdentifiers, sign }}
    />
  );
};

export default React.memo(TrainingCustomToBeSignedDetailsAssigneesContainer);
