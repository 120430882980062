import {
  MaintenanceTaskPersonnelAssignmentInternalStaffInterface,
  MaintenanceTaskPersonnelAssignmentInternalStaffToSyncInterface,
} from "module/maintenanceTaskPersonnelAssignment/internalStaff/types";

export const mapMaintenanceTaskPersonnelAssignmentInternalStaffToSync = (
  maintenanceTaskPersonnelAssignmentInternalStaff: MaintenanceTaskPersonnelAssignmentInternalStaffInterface
): MaintenanceTaskPersonnelAssignmentInternalStaffToSyncInterface => {
  return {
    id: maintenanceTaskPersonnelAssignmentInternalStaff.backendId,
    staffId: maintenanceTaskPersonnelAssignmentInternalStaff.staffId as number,
    responsibility:
      maintenanceTaskPersonnelAssignmentInternalStaff.responsibility,
    duration: maintenanceTaskPersonnelAssignmentInternalStaff.duration,
  };
};
