import { useMemo } from "react";
import { useAppSelector } from "../../../redux/hooks";
import { selectMaintenanceTaskTemplates } from "../../auth/redux/authSlice";
import { MaintenanceTaskTemplateType } from "../../maintenanceTask/template/types";
import { ApiMaintenanceTaskTemplateInterface } from "../../../api/types";

interface Option {
  value: number;
  text: string;
}

interface Result {
  options: Option[];
}

const useMaintenanceTaskTemplateOptions = (): Result => {
  const templates = useAppSelector(selectMaintenanceTaskTemplates);

  const options = useMemo(() => {
    if (templates == null) {
      return [];
    }

    return Object.values(templates)
      .filter(
        (template) =>
          template.type == null ||
          template.type === MaintenanceTaskTemplateType.system
      )
      .map((temp: ApiMaintenanceTaskTemplateInterface) => ({
        value: temp.id,
        text: temp.name,
      }));
  }, [templates]);

  return {
    options,
  };
};

export default useMaintenanceTaskTemplateOptions;
