import React from "react";
import t from "module/translations";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import makeStyles from "@mui/styles/makeStyles";
import Button from "@mui/material/Button";
import DeleteIcon from "@mui/icons-material/Delete";
import LabelField from "component/labelField/LabelField";

const useStyles = makeStyles(() => ({
  wrapper: {
    padding: "24px",
  },
}));

interface Props {
  deviceId: string;
  gitCommitHash: string;
  deleteAllUserData: () => void;
  staffId: number;
  localStorageUsage: string;
}

const AppInfo: React.FC<Props> = ({
  deviceId,
  gitCommitHash,
  deleteAllUserData,
  staffId,
  localStorageUsage,
}) => {
  const classes = useStyles();

  return (
    <Paper className={classes.wrapper}>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <LabelField
            {...{ caption: t.deviceIdentifierText, label: deviceId }}
          />
        </Grid>
        <Grid item xs={12}>
          <LabelField
            {...{ caption: t.gitCommitHashText, label: gitCommitHash }}
          />
        </Grid>
        <Grid item xs={12}>
          <LabelField {...{ caption: t.staffId, label: `${staffId}` }} />
        </Grid>
        <Grid item xs={12}>
          <LabelField
            {...{
              caption: t.localStorageUsage,
              label: `${localStorageUsage} MB / 5 MB`,
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <LabelField caption={t.deleteAllUserDataText}>
            <div>
              <Button
                variant="contained"
                color="secondary"
                startIcon={<DeleteIcon />}
                onClick={deleteAllUserData}
              >
                {t.deleteAllUserDataButtonText}
              </Button>
            </div>
          </LabelField>
        </Grid>
      </Grid>
    </Paper>
  );
};

export default React.memo(AppInfo);
