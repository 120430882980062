import React, { useRef, useLayoutEffect, useCallback } from "react";
import TableContainer from "@mui/material/TableContainer";
import Paper from "@mui/material/Paper";
import { useStickyStyles } from "component/sticky/styles";
import useDebounce from "toolkit/useDebounce";
import { useHistory } from "react-router-dom";
import { parse as parseQueryString } from "query-string";

const HASH_SCROLL = "scroll";

interface Props {
  children: React.ReactNode;
}

const StickyPaperTableContainer: React.FC<Props> = ({ children }) => {
  const classes = useStickyStyles();
  const divRef = useRef();
  const history = useHistory();
  const hash = parseQueryString(global.location.hash);

  useLayoutEffect(() => {
    if (divRef && divRef.current && hash[HASH_SCROLL] != null) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      divRef.current.scrollTop = hash[HASH_SCROLL];
    }
  }, [hash, history.location.hash]);

  const remember = useCallback(() => {
    if (divRef && divRef.current != null) {
      global.history.pushState(
        null,
        "",
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        `#${HASH_SCROLL}=${divRef.current.scrollTop as number}`
      );
    }
  }, []);
  const handleOnScroll = useDebounce(remember, 400);

  return (
    <TableContainer
      component={Paper}
      className={classes.sticky}
      onScroll={handleOnScroll}
      /* eslint-disable-next-line @typescript-eslint/ban-ts-comment */
      // @ts-ignore
      ref={divRef}
    >
      {children}
    </TableContainer>
  );
};

export default React.memo(StickyPaperTableContainer);
