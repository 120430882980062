import React, { useContext, useCallback } from "react";
import TextareaField from "component/textareaField/TextareaField";
import XLabelField from "component/labelField/XLabelField";
import { getFieldMetaDataWithErrorHandling } from "module/fieldMetaData/getFieldMetaData";
import { TrainingCustomAssigneeInterface } from "module/trainingCustom/types";
import TrainingCustomAssigneeContext from "module/trainingCustomAssignee/context/TrainingCustomAssigneeContext";
import TrainingCustomAssigneeChangeContext from "module/trainingCustomAssignee/change/TrainingCustomAssigneeChangeContext";
import TrainingCustomAssigneeFieldMetaDataContext from "module/trainingCustomAssignee/validation/fieldMetaData/TrainingCustomAssigneeFieldMetaDataContext";
import { TRAINING_CUSTOM_ASSIGNEE_REMARKS_FIELD_NAME } from "module/trainingCustomAssignee/constants";

const TrainingCustomAssigneeRemarksFieldContainer: React.FC = () => {
  const { entity } = useContext(TrainingCustomAssigneeContext);
  const { change: changeHelper } = useContext(
    TrainingCustomAssigneeChangeContext
  );
  const { getFieldMetaData } = useContext(
    TrainingCustomAssigneeFieldMetaDataContext
  );

  const trainingCustomAssignee = entity as TrainingCustomAssigneeInterface;

  const change = useCallback(
    (remarks?: string) => {
      changeHelper({ remarks });
    },
    [changeHelper]
  );

  const { warnings, errors, isEditable } = getFieldMetaDataWithErrorHandling(
    () => getFieldMetaData(TRAINING_CUSTOM_ASSIGNEE_REMARKS_FIELD_NAME)
  );

  return isEditable ? (
    <TextareaField
      {...{
        value: trainingCustomAssignee.remarks,
        change,
        errors,
      }}
    />
  ) : (
    <XLabelField
      {...{
        label: trainingCustomAssignee.remarks,
        errors,
        warnings,
      }}
    />
  );
};

export default React.memo(TrainingCustomAssigneeRemarksFieldContainer);
