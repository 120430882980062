import routes from "routes/routes";

export const getPathToInventoryCheckDetails = (
  inventoryCheckId: string
): string =>
  routes.myInventoryCheckDetails.path.replace(
    ":inventoryCheckId",
    inventoryCheckId.toString()
  );
export const getPathToInventoryCheckDetailsOverview = (
  inventoryCheckId: string
): string =>
  routes.myInventoryCheckDetailsOverview.path.replace(
    ":inventoryCheckId",
    inventoryCheckId.toString()
  );

export const getPathToInventoryCheckDetailsArticles = (
  inventoryCheckId: string
): string =>
  routes.myInventoryCheckDetailsArticles.path.replace(
    ":inventoryCheckId",
    inventoryCheckId.toString()
  );
