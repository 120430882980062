import React, { useState, useCallback } from "react";
import FullScreenLoaderContext, {
  FullScreenContextInterface,
} from "module/fullScreenLoader/context/FullScreenLoaderContext";
import FullScreenLoader from "component/fullscreenLoader/FullscreenLoader";

interface Prop {
  children: React.ReactNode;
}

const FullScreenLoaderContextProvider: React.FC<Prop> = ({ children }) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const turnOn = useCallback(() => setIsLoading(true), []);
  const turnOff = useCallback(() => setIsLoading(false), []);

  const contextValue: FullScreenContextInterface = {
    isLoading,
    turnOn,
    turnOff,
  };

  return (
    <FullScreenLoaderContext.Provider value={contextValue}>
      {children}
      <FullScreenLoader {...{ isLoading }} />
    </FullScreenLoaderContext.Provider>
  );
};

export default FullScreenLoaderContextProvider;
