import React, { useCallback } from "react";
import makeStyles from "@mui/styles/makeStyles";
import Input from "@mui/material/Input";
import InputAdornment from "@mui/material/InputAdornment";
import FormControl from "@mui/material/FormControl";
import XValidationErrorMessage from "component/validationErrorMessage/XValidationErrorMessage";
import XValidationWarningMessage from "component/validationWarningMessage/XValidationWarningMessage";

const useStyles = makeStyles({
  measureValue: {
    "& input": {
      textAlign: "right",
    },
  },
  unitInSeparateLine: {
    textAlign: "right",
  },
});

interface Props {
  name: string;
  value?: number;
  unit?: string;
  change: (newValue?: number) => void;
  errors: string[];
  warnings: string[];
}

const MaintenanceTaskProcessStepMeasureValueEditableField: React.FC<Props> = ({
  value,
  name,
  unit,
  change,
  errors,
  warnings,
}) => {
  const classes = useStyles();

  const handleChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>): void => {
      const newValue =
        event.target.value === "" ? undefined : Number(event.target.value);
      change(newValue);
    },
    [change]
  );

  const hasError = errors != null && Boolean(errors.length);

  const shouldDisplayUnitAsAPostfix = (unit ?? "").length < 4;

  return (
    <>
      <FormControl error={hasError} variant="standard">
        <div>{name}</div>
        <div>
          <Input
            type="number"
            defaultValue={value}
            onChange={handleChange}
            className={classes.measureValue}
            {...(shouldDisplayUnitAsAPostfix
              ? {
                  endAdornment: (
                    <InputAdornment position="end">{unit}</InputAdornment>
                  ),
                }
              : {})}
            error={hasError}
          />
          {!shouldDisplayUnitAsAPostfix && (
            <div className={classes.unitInSeparateLine}>{unit}</div>
          )}
          {errors && <XValidationErrorMessage {...{ errors }} />}
          {warnings && <XValidationWarningMessage {...{ warnings }} />}
        </div>
      </FormControl>
    </>
  );
};

export default React.memo(MaintenanceTaskProcessStepMeasureValueEditableField);
