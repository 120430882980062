import _ from "lodash";

export const createNestedPropertyIfNotExist = (
  // eslint-disable-next-line @typescript-eslint/no-explicit-any,@typescript-eslint/explicit-module-boundary-types
  object: any,
  path: string,
  defaultValue: unknown
): never => {
  if (_.get(object, path) == null) {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    return _.setWith(object, path, defaultValue, Object);
  }
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  return object;
};
