import { RootState } from "redux/store";
import { sliceKey as staffDataSliceKey } from "module/staff/redux/staffDataSlice";
import { selectSystem, selectUser } from "module/auth/redux/authSlice";
import { SingleStaffSingleSystemData } from "module/staff/redux/types";

const selectConfigurationForMeForCurrentSystem = (
  state: RootState
): SingleStaffSingleSystemData => {
  const staffId = selectUser(state).id;
  const systemShortName = selectSystem(state).shortName;

  return state[staffDataSliceKey]?.[staffId]?.[systemShortName];
};

export default selectConfigurationForMeForCurrentSystem;
