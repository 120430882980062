import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import PullToRefresh from "component/pullToRefresh/PullToRefresh";
import CompetencyManagementList from "pages/competencyManagementList/CompetencyManagementList";
import ToggleContextProvider from "module/toggle/context/ToggleContextProvider";
import { EntityIdentifier, MyEntityInterface } from "module/entity/types";
import {
  areCompetencyManagementsFiltersValid,
  getCompetencyManagements,
} from "api/competencyManagement/getCompetencyManagements";
import { CompetencyManagementListFilters } from "pages/competencyManagementList/types";
import sortCompetencyManagements from "pages/competencyManagementList/sortCompetencyManagements";
import { useAppSelector } from "redux/hooks";
import { selectUser } from "module/auth/redux/authSlice";
import SortContext from "module/sort/context/SortContext";
import FilterContext from "module/filter/context/FilterContext";
import FullScreenLoaderContext from "module/fullScreenLoader/context/FullScreenLoaderContext";
import EntityDownloadContextProvider from "module/entity/context/download/EntityDownloadContextProvider";
import MyEntityCollectionContextProvider from "module/entity/context/entityCollection/MyEntityCollectionContextProvider";
import { MyCompetencyManagementInterface } from "module/competencyManagement/types";

const CompetencyManagementListContainer: React.FC = () => {
  const { turnOn: turnLoaderOn, turnOff: turnLoaderOff } = useContext(
    FullScreenLoaderContext
  );
  const { sort } = useContext(SortContext);
  const { filters } = useContext(FilterContext);

  const { id: myStaffId } = useAppSelector(selectUser);

  const [myEntityCollection, setMyEntityCollection] = useState<
    Map<EntityIdentifier, MyEntityInterface>
  >(new Map());

  const load = useCallback(async () => {
    if (
      areCompetencyManagementsFiltersValid(
        filters as unknown as CompetencyManagementListFilters
      )
    ) {
      turnLoaderOn();
      const { data: competencyManagementItemsFromApi } =
        await getCompetencyManagements(
          filters as unknown as CompetencyManagementListFilters,
          sort
        );
      const newCompetencyManagementItems = sortCompetencyManagements(
        competencyManagementItemsFromApi,
        myStaffId
      );

      setMyEntityCollection(newCompetencyManagementItems);
      turnLoaderOff();
    }
  }, [filters, myStaffId, sort, turnLoaderOff, turnLoaderOn]);

  useEffect(() => {
    load();
  }, [load]);

  const itemsAllowedToToggle: EntityIdentifier[] = useMemo(
    () =>
      Array.from(myEntityCollection.keys()).reduce(
        (result, entityIdentifier) => {
          const myEntity = myEntityCollection.get(
            entityIdentifier
          ) as MyCompetencyManagementInterface;
          if (!myEntity.data.isBlockedDetails?.isBlocked) {
            return [...result, entityIdentifier];
          }

          return result;
        },
        [] as EntityIdentifier[]
      ),
    [myEntityCollection]
  );

  const entityIdentifiers = useMemo(
    () => Array.from(myEntityCollection.keys()),
    [myEntityCollection]
  );

  return (
    <MyEntityCollectionContextProvider {...{ myEntityCollection }}>
      <EntityDownloadContextProvider {...{ load }}>
        <PullToRefresh refresh={load}>
          <ToggleContextProvider {...{ itemsAllowedToToggle }}>
            <CompetencyManagementList
              {...{
                entityIdentifiers,
              }}
            />
          </ToggleContextProvider>
        </PullToRefresh>
      </EntityDownloadContextProvider>
    </MyEntityCollectionContextProvider>
  );
};

export default React.memo(CompetencyManagementListContainer);
