import { MaintenanceTaskInterface } from "module/maintenanceTask/types";
import { isMaintenanceTaskProcessStepCheckedResultAvailable } from "module/maintenanceTaskProcessStep/helper/isMaintenanceTaskProcessStepCheckedResultAvailable";
import {
  MaintenanceTaskProcessStepCheckResult,
  MaintenanceTaskProcessStepInterface,
} from "module/maintenanceTaskProcessStep/types";
import { isMaintenanceTaskProcessStepActionPerformedAvailable } from "module/maintenanceTaskProcessStep/helper/isMaintenanceTaskProcessStepActionPerformedAvailable";

export const calculateChangesAfterMaintenanceTaskProcessStepMeasureValueChange =
  (
    maintenanceTask: MaintenanceTaskInterface,
    rawValues: Partial<MaintenanceTaskProcessStepInterface>
  ): Partial<MaintenanceTaskProcessStepInterface> => {
    const values = { ...rawValues };

    if (isMaintenanceTaskProcessStepCheckedResultAvailable(maintenanceTask)) {
      if (values.measureValue == null) {
        values.checkResult = MaintenanceTaskProcessStepCheckResult.not_set;
        return values;
      }

      if (values.measureValueMin != null && values.measureValueMax != null) {
        if (
          values.measureValue < values.measureValueMin ||
          values.measureValue > values.measureValueMax
        ) {
          values.checkResult = MaintenanceTaskProcessStepCheckResult.good_no;
        } else {
          values.checkResult = MaintenanceTaskProcessStepCheckResult.good_yes;
        }
        return values;
      }

      if (values.measureValueMin != null) {
        if (values.measureValue < values.measureValueMin) {
          values.checkResult = MaintenanceTaskProcessStepCheckResult.good_no;
        } else {
          values.checkResult = MaintenanceTaskProcessStepCheckResult.good_yes;
        }
        return values;
      }

      if (values.measureValueMax != null) {
        if (values.measureValue > values.measureValueMax) {
          values.checkResult = MaintenanceTaskProcessStepCheckResult.good_no;
        } else {
          values.checkResult = MaintenanceTaskProcessStepCheckResult.good_yes;
        }
        return values;
      }
    } else if (
      isMaintenanceTaskProcessStepActionPerformedAvailable(maintenanceTask)
    ) {
      if (values.measureValueMin !== null || values.measureValueMax !== null) {
        if (
          values.measureValue == null ||
          (values.measureValueMin != null &&
            values.measureValue < values.measureValueMin) ||
          (values.measureValueMax != null &&
            values.measureValue > values.measureValueMax)
        ) {
          values.checkResult = MaintenanceTaskProcessStepCheckResult.not_set;
        } else {
          values.checkResult = MaintenanceTaskProcessStepCheckResult.good_yes;
        }
      }
      return values;
    }

    return values;
  };
