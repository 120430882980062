import React, { useContext } from "react";
import TrainingCustomContext from "module/trainingCustom/context/TrainingCustomContext";
import { TrainingCustomInterface } from "module/trainingCustom/types";
import ToBeSignedListTableRowTrainingCustom from "pages/toBeSignedList/table/row/trainingCustom/ToBeSignedListTableRowTrainingCustom";

const ToBeSignedListTableRowTrainingCustomContainer: React.FC = () => {
  const { entity } = useContext(TrainingCustomContext);

  const trainingCustom = entity as TrainingCustomInterface;

  return (
    <ToBeSignedListTableRowTrainingCustom
      {...{
        trainingCustom,
      }}
    />
  );
};

export default React.memo(ToBeSignedListTableRowTrainingCustomContainer);
