import React from "react";
import LabelField from "component/labelField/LabelField";
import t from "module/translations";

interface Props {
  module: string;
}

const AssessmentModuleLabelField: React.FC<Props> = ({ module }) => {
  return (
    <LabelField
      {...{
        caption: t.competencyManagement.assessment.moduleText,
        label: module,
      }}
    />
  );
};

export default React.memo(AssessmentModuleLabelField);
