/* eslint-disable no-restricted-syntax */
/* eslint-disable guard-for-in */
import { RootState } from "redux/store";
import localforage from "localforage";
import { sliceKey as staffDataSliceKey } from "module/staff/redux/staffDataSlice";
import {
  defaultState as authDefaultState,
  sliceKey as authSliceKey,
} from "module/auth/constants";
import { EntityType } from "module/entity/types";
import { reportError } from "error/report";
import * as Sentry from "@sentry/react";

const staffDataAttachments = "staffDataAttachments";

/**
 * auth & device slice is being kept in localStorage
 * staffData is being kept in localStorage, images are kept in localForage
 */

export const REDUX_STATE_STORAGE_KEY = "reduxState";

const persistStateToLocalStorage = (state: RootState): void => {
  const saveToLocalStorage = JSON.parse(JSON.stringify(state));

  const staffDataWithoutAttachmnets = Object.keys(
    saveToLocalStorage[staffDataSliceKey] ?? {}
  ).reduce((r, staffId) => {
    const rawSingleStaffData = saveToLocalStorage[staffDataSliceKey][staffId];

    const singleStaffData = Object.keys(rawSingleStaffData).reduce(
      (r2, systemShortName) => {
        const singleStaffSingleSystemRawData =
          rawSingleStaffData[systemShortName];

        const filteredEntities = Object.keys(
          singleStaffSingleSystemRawData.entities
        ).reduce((r3, entityKey) => {
          const entity = singleStaffSingleSystemRawData.entities[entityKey];
          if (entity.entityIdentifier.type !== EntityType.attachment) {
            return {
              ...r3,
              [entityKey]: entity,
            };
          }
          return r3;
        }, {} as Record<string, unknown>);

        return {
          ...r2,
          [systemShortName]: {
            ...singleStaffSingleSystemRawData,
            entities: filteredEntities,
          },
        };
      },
      {} as Record<string, unknown>
    );

    return {
      ...r,
      [staffId]: singleStaffData,
    };
  }, {} as Record<string, unknown>);

  // staffData without attachments
  saveToLocalStorage[staffDataSliceKey] = staffDataWithoutAttachmnets;

  const authData = saveToLocalStorage[authSliceKey] ?? {};

  const authDataWithoutSystemConfiguration = authData.user
    ? {
        ...authData,
        user: {
          ...saveToLocalStorage[authSliceKey].user,
          availableSystems: saveToLocalStorage[
            authSliceKey
          ].user?.availableSystems.map((availableSystem: any) => ({
            ...availableSystem,
            configuration: null,
          })),
        },
      }
    : saveToLocalStorage[authSliceKey];
  saveToLocalStorage[authSliceKey] = authDataWithoutSystemConfiguration;

  localStorage.setItem(
    REDUX_STATE_STORAGE_KEY,
    JSON.stringify(saveToLocalStorage)
  );
};

const persistStateToLocalForage = async (state: RootState): Promise<void> => {
  const saveToLocalForage = JSON.parse(JSON.stringify(state));

  const staffDataAttachmnets = Object.keys(
    saveToLocalForage[staffDataSliceKey]
  ).reduce((r, staffId) => {
    const rawSingleStaffData = saveToLocalForage[staffDataSliceKey][staffId];

    const singleStaffData = Object.keys(rawSingleStaffData).reduce(
      (r2, systemShortName) => {
        const singleStaffSingleSystemRawData =
          rawSingleStaffData[systemShortName];

        const filteredEntities = Object.keys(
          singleStaffSingleSystemRawData.entities
        ).reduce((r3, entityKey) => {
          const entity = singleStaffSingleSystemRawData.entities[entityKey];
          if (entity.entityIdentifier.type === EntityType.attachment) {
            return {
              ...r3,
              [entityKey]: entity,
            };
          }
          return r3;
        }, {} as Record<string, unknown>);

        return {
          ...r2,
          [systemShortName]: {
            ...singleStaffSingleSystemRawData,
            entities: filteredEntities,
          },
        };
      },
      {} as Record<string, unknown>
    );

    return {
      ...r,
      [staffId]: singleStaffData,
    };
  }, {} as Record<string, unknown>);

  // save only images to localForage
  await localforage.setItem(staffDataAttachments, staffDataAttachmnets);

  if (saveToLocalForage[authSliceKey]?.system) {
    const systemConfiguration = saveToLocalForage[authSliceKey].user
      ? saveToLocalForage[authSliceKey].user.availableSystems.find(
          (availableSystem: any) =>
            availableSystem.shortName === saveToLocalForage[authSliceKey].system
        )?.configuration
      : null;

    await localforage.setItem(
      `systemConfiguration${saveToLocalForage[authSliceKey].system}`,
      systemConfiguration
    );
  }
};

const getMinStaffData = (state: RootState) => {
  const minStaffData = {};
  // eslint-disable-next-line no-restricted-syntax
  for (const staffId of Object.keys(state[staffDataSliceKey] ?? {})) {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    minStaffData[`${staffId}`] = {};
    // eslint-disable-next-line no-restricted-syntax
    for (const systemShortName of Object.keys(
      state.staffData[Number(staffId)]
    )) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      minStaffData[`${staffId}`][`${systemShortName}`] = JSON.stringify(
        Object.keys(state.staffData[Number(staffId)][systemShortName].entities)
      );
    }
  }
  return minStaffData;
};

const getLocalStorageState = () =>
  localStorage.getItem(REDUX_STATE_STORAGE_KEY)
    ? JSON.parse(<string>localStorage.getItem(REDUX_STATE_STORAGE_KEY))
    : {};

export const persistState = async (state: RootState): Promise<void> => {
  if (state.auth?.system === "OAC") {
    Sentry.withScope((scope) => {
      scope.setLevel("info");
      scope.setExtra(
        "localStorageState",
        getMinStaffData(getLocalStorageState())
      );
      Sentry.captureMessage("persistState:after");
    });
  }

  persistStateToLocalStorage(state);
  await persistStateToLocalForage(state);

  if (state.auth?.system === "OAC") {
    Sentry.withScope((scope) => {
      scope.setLevel("info");
      scope.setExtra("minStaffData", getMinStaffData(state));
      Sentry.captureMessage("persistState:after");
    });
  }
};

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const getStateSliceFromLocalStorage = (key: string) =>
  getLocalStorageState()?.[key];

const getState = async (): Promise<RootState> => {
  const state = getLocalStorageState();

  try {
    // merge staffData from localForage (images) and localStorage
    const staffDataAttachmentsFromLocalForage = ((await localforage.getItem(
      staffDataAttachments
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    )) ?? {}) as Record<string, any>;

    for (const staffId in staffDataAttachmentsFromLocalForage) {
      const rawSingleStaffData = staffDataAttachmentsFromLocalForage[staffId];

      for (const systemShortName in rawSingleStaffData) {
        const singleStaffSingleSystemRawData =
          rawSingleStaffData[systemShortName];

        for (const entityKey in singleStaffSingleSystemRawData.entities) {
          const entity = singleStaffSingleSystemRawData.entities[entityKey];
          if (entity.entityIdentifier.type === EntityType.attachment) {
            state[staffDataSliceKey][staffId][systemShortName].entities[
              entityKey
            ] = entity;
          }
        }
      }
    }

    if (state[authSliceKey]?.user) {
      const systemConfiguration = await localforage.getItem(
        `systemConfiguration${state[authSliceKey].system}`
      );
      state[authSliceKey].user.availableSystems = state[
        authSliceKey
      ].user.availableSystems.map((availableSystem: { shortName: any }) => {
        if (availableSystem.shortName === state[authSliceKey].system) {
          return {
            ...availableSystem,
            configuration: systemConfiguration,
          };
        }
        return availableSystem;
      });
    }
    // eslint-disable-next-line no-empty
  } catch (err) {}

  return state;
};

const areStaffDataStoredInLocalForage = async (): Promise<boolean> => {
  const staffData = await localforage.getItem(staffDataSliceKey);
  return staffData !== null;
};

export const rewriteStaffDataToLocalStorage = async (): Promise<void> => {
  const staffDataStoredInLocalForage = await areStaffDataStoredInLocalForage();
  if (staffDataStoredInLocalForage) {
    const localStorageState = getLocalStorageState();
    const staffData = await localforage.getItem(staffDataSliceKey);

    try {
      await persistState({
        ...localStorageState,
        staffData,
      });
    } catch (err) {
      reportError("rewriteStaffDataToLocalStorage", err);
      localStorage.clear();
      await persistState({
        ...localStorageState,
        staffData: {},
      });
    }
    await localforage.removeItem(staffDataSliceKey);
  }
};

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const getStateSlice = async (key: string) => {
  const state = await getState();
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  return state[key];
};

export const removeAuthData = async (): Promise<void> => {
  const { auth, ...rest } = await getState();
  await persistState({ ...rest, [authSliceKey]: authDefaultState });
};

export const removeStaffDataData = async (staffId: string): Promise<void> => {
  const { staffData, ...rest } = await getState();
  // Sentry.captureMessage(`removeStaffData staffId: ${staffId}`);
  await persistState({
    ...rest,
    staffData: {
      ...staffData,
      [staffId]: undefined,
    },
  });
};
