import React from "react";
import { useAppSelector } from "redux/hooks";
import { selectSystem } from "module/auth/redux/authSlice";
import HeaderTitleComponent from "component/headerTitle/HeaderTitle";

const HeaderTitle: React.FC = () => {
  const system = useAppSelector(selectSystem);

  return <HeaderTitleComponent text={system.name} />;
};

export default React.memo(HeaderTitle);
