import React from "react";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import t from "module/translations";
import useDateFormat from "toolkit/useDateFormat";
import StaffNameText from "module/staff/StaffNameText";
import LinkWithIcon from "component/link/LinkWithIcon";
import { getPathToTrainingDetails } from "module/training/helper/getPath";
import { getTrainingTypeTranslation } from "module/training/helper/getTrainingTypeTranslation";
import { getTrainingStateTranslation } from "module/training/helper/getTrainingStateTranslation";
import TrainingDeleteButtonContainer from "module/training/enrichedComponent/deleteButton/TrainingDeleteButtonContainer";
import { TrainingInterface } from "module/training/types";
import { EntityIdentifier } from "module/entity/types";
import TableToggleCellContainer from "module/toggle/TableToggleCellContainer";

interface Props {
  training: TrainingInterface;
  isSelected: boolean;
  entityIdentifier: EntityIdentifier;
}

const MyCompetencyManagementListTableRowTraining: React.FC<Props> = ({
  isSelected,
  training,
  entityIdentifier,
}) => {
  const { formatDateTime } = useDateFormat();
  return (
    <TableRow
      {...{
        hover: true,
        tabIndex: -1,
        role: "checkbox",
        "aria-checked": isSelected,
        selected: isSelected,
        key: training.id,
      }}
    >
      <TableToggleCellContainer {...{ entityIdentifier }} />
      <TableCell align="left">{training.reportingNumber}</TableCell>
      <TableCell align="left">
        <LinkWithIcon
          {...{
            label: training.name,
            path: getPathToTrainingDetails(training.id),
          }}
        />
      </TableCell>
      <TableCell align="left">
        {training.assigneeId ? (
          <StaffNameText {...{ staffId: training.assigneeId }} />
        ) : (
          "-"
        )}
      </TableCell>
      <TableCell align="left">
        {training.internalTrainerId && (
          <StaffNameText {...{ staffId: training.internalTrainerId }} />
        )}
        {training.externalTrainer ? training.externalTrainer : ""}
        {training.internalTrainerId == null &&
          training.externalTrainer == null &&
          "-"}
      </TableCell>
      <TableCell align="left">
        {`${getTrainingTypeTranslation(training.type)} ${
          t.competencyManagement.trainingText
        }`}
      </TableCell>
      <TableCell align="left">
        {formatDateTime(training.scheduledStartDate)}
      </TableCell>
      <TableCell align="left">
        {getTrainingStateTranslation(training.state)}
      </TableCell>
      <TableCell align="right">
        <TrainingDeleteButtonContainer />
      </TableCell>
    </TableRow>
  );
};

export default React.memo(MyCompetencyManagementListTableRowTraining);
