import { PayloadAction } from "@reduxjs/toolkit";
import { StaffDataState } from "module/staff/redux/types";
import { EntityIdentifier } from "module/entity/types";

interface OpenEntityCollectionActionTypeProps {
  staffId: number;
  systemShortName: string;
  entityIdentifiers: EntityIdentifier[];
}

const openEntityAction = (
  state: StaffDataState,
  action: PayloadAction<OpenEntityCollectionActionTypeProps>
): void => {
  const { staffId, systemShortName, entityIdentifiers } = action.payload;

  // eslint-disable-next-line array-callback-return
  entityIdentifiers.map((entityIdentifier) => {
    state[staffId][systemShortName].openEntityIdentifiers = [
      ...(state[staffId][systemShortName].openEntityIdentifiers ?? []),
      entityIdentifier,
    ];
  });
};

export default openEntityAction;
