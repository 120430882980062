import React, { useContext, useMemo } from "react";
import MyMaintenanceTaskListTableRow from "pages/myMaintenanceTaskList/table/row/MyMaintenanceTaskListTableRow";
import MaintenanceTaskContext from "module/maintenanceTask/context/MaintenanceTaskContext";
import ToggleContext from "module/toggle/context/ToggleContext";
import { EntityType } from "module/entity/types";
import {
  MaintenanceTaskInterface,
  MyMaintenanceTaskInterface,
} from "module/maintenanceTask/types";
import { getMaintenanceTasksCreatedForOtherMaintenanceTaskProcessStepsMap } from "module/maintenanceTask/helper/getMaintenanceTasksCreatedForOtherMaintenanceTaskProcessStepsMap/getMaintenanceTasksCreatedForOtherMaintenanceTaskProcessStepsMap";
import { useAppSelector } from "redux/hooks";
import selectEntitiesByTypes from "module/staff/redux/selector/selectEntitiesByTypes";
import MyMaintenanceTaskCreatedForOtherMaintenanceTaskProcessStepListTableRow from "./MyMaintenanceTaskCreatedForOtherMaintenanceTaskProcessStepListTableRow";

const MyMaintenanceTaskListTableRowContainer: React.FC = () => {
  const myMaintenanceTasks = useAppSelector(
    selectEntitiesByTypes([EntityType.maintenanceTask])
  ) as MyMaintenanceTaskInterface[];

  const allMaintenanceTasks = useMemo(
    () => myMaintenanceTasks.map(({ data }) => data),
    [myMaintenanceTasks]
  );

  const { entity } = useContext(MaintenanceTaskContext);
  const { isSelected: isSelectedHelper } = useContext(ToggleContext);

  const maintenanceTask = entity as MaintenanceTaskInterface;

  const isSelected = useMemo(
    () =>
      isSelectedHelper({
        id: maintenanceTask.id,
        type: EntityType.maintenanceTask,
      }),
    [isSelectedHelper, maintenanceTask.id]
  );

  const maintenanceTasksCreatedForMaintenanceTaskProcessSteps = useMemo(
    () =>
      getMaintenanceTasksCreatedForOtherMaintenanceTaskProcessStepsMap(
        allMaintenanceTasks
      )[maintenanceTask.id] || [],
    [allMaintenanceTasks, maintenanceTask.id]
  );

  if (maintenanceTask == null) {
    return null;
  }

  return (
    <>
      <MyMaintenanceTaskListTableRow {...{ maintenanceTask, isSelected }} />
      {maintenanceTasksCreatedForMaintenanceTaskProcessSteps.map(
        (maintenanceTaskCreatedForMaintenanceTaskProcessStep) => (
          <MyMaintenanceTaskCreatedForOtherMaintenanceTaskProcessStepListTableRow
            {...{
              maintenanceTask:
                maintenanceTaskCreatedForMaintenanceTaskProcessStep,
              isSelected,
              originalMaintenanceTask: maintenanceTask,
            }}
          />
        )
      )}
    </>
  );
};

export default React.memo(MyMaintenanceTaskListTableRowContainer);
