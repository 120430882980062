import React from "react";
import { EntityIdentifier } from "module/entity/types";
import MyEntityContextProvider from "module/entity/context/entity/MyEntityContextProvider";
import { getEntityIdentifierKey } from "module/entity/helper/getEntityIdentifierKey";
import MyEntityValidationResultContextProvider from "module/entity/context/validationResult/MyEntityValidationResultContextProvider";
import MyEntityChangeContextProvider from "module/entity/context/change/MyEntityChangeContextProvider";
import MaintenanceTaskPersonnelAssignmentExternalStaffContext from "module/maintenanceTaskPersonnelAssignment/externalStaff/context/MaintenanceTaskPersonnelAssignmentExternalStaffContext";
import MaintenanceTaskPersonnelAssignmentExternalStaffValidationResultContext from "module/maintenanceTaskPersonnelAssignment/externalStaff/validation/MaintenanceTaskPersonnelAssignmentExternalStaffValidationResultContext";
import MyMaintenanceTaskPersonnelAssignmentExternalStaffFieldMetaDataContextProvider from "module/maintenanceTaskPersonnelAssignment/externalStaff/validation/fieldMetaData/MyMaintenanceTaskPersonnelAssignmentExternalStaffFieldMetaDataContextProvider";
import MaintenanceTaskPersonnelAssignmentExternalStaffChangeContext from "module/maintenanceTaskPersonnelAssignment/externalStaff/change/MaintenanceTaskPersonnelAssignmentExternalStaffChangeContext";

interface Props {
  entityIdentifier: EntityIdentifier;
  children: React.ReactNode;
}

const MyMaintenanceTaskPersonnelAssignmentExternalListRowContextProvider: React.FC<Props> =
  ({ entityIdentifier, children }) => {
    return (
      <MyEntityContextProvider
        {...{
          entityIdentifier,
          context: MaintenanceTaskPersonnelAssignmentExternalStaffContext,
          key: getEntityIdentifierKey(entityIdentifier),
        }}
      >
        <MyEntityValidationResultContextProvider
          {...{
            entityIdentifier,
            context:
              MaintenanceTaskPersonnelAssignmentExternalStaffValidationResultContext,
          }}
        >
          <MyMaintenanceTaskPersonnelAssignmentExternalStaffFieldMetaDataContextProvider>
            <MyEntityChangeContextProvider
              {...{
                entityIdentifier,
                context:
                  MaintenanceTaskPersonnelAssignmentExternalStaffChangeContext,
              }}
            >
              {children}
            </MyEntityChangeContextProvider>
          </MyMaintenanceTaskPersonnelAssignmentExternalStaffFieldMetaDataContextProvider>
        </MyEntityValidationResultContextProvider>
      </MyEntityContextProvider>
    );
  };

export default MyMaintenanceTaskPersonnelAssignmentExternalListRowContextProvider;
