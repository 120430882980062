import React from "react";
import t from "module/translations";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";

const AssessmentObjectiveTableHead: React.FC = () => {
  return (
    <TableHead>
      <TableRow>
        <TableCell align="left">
          {t.competencyManagement.assessment.objective.nameHeader}
        </TableCell>
        <TableCell align="left">
          {t.competencyManagement.assessment.objective.achievedHeader}
        </TableCell>
        <TableCell align="left">
          {t.competencyManagement.assessment.objective.remarksHeader}
        </TableCell>
      </TableRow>
    </TableHead>
  );
};

export default React.memo(AssessmentObjectiveTableHead);
