import t from "module/translations";
import { AssessmentSyncUnavailableReason } from "module/assessment/types";

export const getAssessmentSyncUnavailableReasonTranslation = (
  unavailableReason: AssessmentSyncUnavailableReason
): string => {
  return {
    [AssessmentSyncUnavailableReason.notValid]:
      t.competencyManagement.assessment.sync.unavailableReason.notValidText,
    [AssessmentSyncUnavailableReason.isOpen]:
      t.competencyManagement.assessment.sync.unavailableReason.isOpenText,
    [AssessmentSyncUnavailableReason.offline]:
      t.competencyManagement.assessment.sync.unavailableReason.offlineText,
    [AssessmentSyncUnavailableReason.noPermissions]:
      t.competencyManagement.assessment.sync.unavailableReason
        .noPermissionsText,
    [AssessmentSyncUnavailableReason.hasNotChanged]:
      t.competencyManagement.assessment.sync.unavailableReason
        .hasNotChangedText,
  }[unavailableReason];
};
