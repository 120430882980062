import React, { useContext } from "react";
import ToBeSignedListTableRowAssessment from "pages/toBeSignedList/table/row/assessment/ToBeSignedListTableRowAssessment";
import AssessmentContext from "module/assessment/context/AssessmentContext";
import { AssessmentInterface } from "module/assessment/types";

const ToBeSignedListTableRowAssessmentContainer: React.FC = () => {
  const { entity } = useContext(AssessmentContext);

  const assessment = entity as AssessmentInterface;

  return (
    <ToBeSignedListTableRowAssessment
      {...{
        assessment,
      }}
    />
  );
};

export default React.memo(ToBeSignedListTableRowAssessmentContainer);
