import React from "react";
import { EntityFieldMetaDataContextInterface } from "module/entity/context/fieldMetaData/EntityFieldMetaDataContext";

export type MaintenanceTaskProcessStepFieldMetaDataContextInterface =
  EntityFieldMetaDataContextInterface;

const MaintenanceTaskProcessStepFieldMetaDataContext = React.createContext(
  <MaintenanceTaskProcessStepFieldMetaDataContextInterface>{}
);

export default MaintenanceTaskProcessStepFieldMetaDataContext;
