import React from "react";
import { EntityIdentifier } from "module/entity/types";
import MyEntityContextProvider from "module/entity/context/entity/MyEntityContextProvider";
import MyEntityChangeContextProvider from "module/entity/context/change/MyEntityChangeContextProvider";
import MyEntityValidationResultContextProvider from "module/entity/context/validationResult/MyEntityValidationResultContextProvider";
import TrainingCustomContext from "module/trainingCustom/context/TrainingCustomContext";
import TrainingCustomValidationResultContext from "module/trainingCustom/validation/TrainingCustomValidationResultContext";
import MyTrainingCustomOpenCloseContextProvider from "module/trainingCustom/openClose/context/MyTrainingCustomOpenCloseContextProvider";
import MyTrainingCustomStateContextProvider from "module/trainingCustom/state/context/MyTrainingCustomStateContextProvider";
import MyTrainingCustomFieldMetaDataContextProvider from "module/trainingCustom/validation/fieldMetaData/MyTrainingCustomFieldMetaDataContextProvider";
import TrainingCustomChangeContext from "module/trainingCustom/change/TrainingCustomChangeContext";

interface Props {
  entityIdentifier: EntityIdentifier;
  children: React.ReactNode;
}

const MyTrainingCustomDetailsContextProvider: React.FC<Props> = ({
  children,
  entityIdentifier,
}) => {
  return (
    <MyEntityContextProvider
      {...{ entityIdentifier, context: TrainingCustomContext }}
    >
      <MyEntityValidationResultContextProvider
        {...{
          entityIdentifier,
          context: TrainingCustomValidationResultContext,
        }}
      >
        <MyTrainingCustomOpenCloseContextProvider>
          <MyTrainingCustomStateContextProvider>
            <MyTrainingCustomFieldMetaDataContextProvider>
              <MyEntityChangeContextProvider
                {...{ entityIdentifier, context: TrainingCustomChangeContext }}
              >
                {children}
              </MyEntityChangeContextProvider>
            </MyTrainingCustomFieldMetaDataContextProvider>
          </MyTrainingCustomStateContextProvider>
        </MyTrainingCustomOpenCloseContextProvider>
      </MyEntityValidationResultContextProvider>
    </MyEntityContextProvider>
  );
};

export default MyTrainingCustomDetailsContextProvider;
