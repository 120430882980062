import React, { useCallback } from "react";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import XValidationErrorMessage from "component/validationErrorMessage/XValidationErrorMessage";
import XValidationWarningMessage from "component/validationWarningMessage/XValidationWarningMessage";

interface Props {
  isChecked: boolean;
  change: (isChecked: boolean) => void;
  label?: string;
  errors: string[];
  warnings: string[];
}

const CheckboxField: React.FC<Props> = ({
  isChecked,
  change,
  label,
  errors,
  warnings,
}) => {
  const handleChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>): void => {
      change(event.target.checked);
    },
    [change]
  );

  return (
    <>
      <FormControlLabel
        control={<Checkbox checked={isChecked} onChange={handleChange} />}
        label={label ?? ""}
      />
      <XValidationErrorMessage {...{ errors }} />
      <XValidationWarningMessage {...{ warnings }} />
    </>
  );
};

export default React.memo(CheckboxField);
