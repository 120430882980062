import React, { useContext } from "react";
import TrainingContext from "module/training/context/TrainingContext";
import { TrainingInterface } from "module/training/types";
import TrainingTrainerLabelField from "module/training/enrichedComponent/trainerField/TrainingTrainerLabelField";

const TrainingTrainerFieldContainer: React.FC = () => {
  const { entity } = useContext(TrainingContext);
  const { internalTrainerId, externalTrainer } = entity as TrainingInterface;

  return (
    <TrainingTrainerLabelField {...{ internalTrainerId, externalTrainer }} />
  );
};

export default React.memo(TrainingTrainerFieldContainer);
