import React from "react";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import t from "module/translations";
import useDateFormat from "toolkit/useDateFormat";
import CompetencyManagementListTableRowAssessmentSelectTableCellContainer from "pages/competencyManagementList/table/row/assessment/selectTableCell/CompetencyManagementListTableRowAssessmentSelectTableCellContainer";
import { getAssessmentTypeTranslation } from "module/assessment/helper/getAssessmentTypeTranslation";
import StaffNameText from "module/staff/StaffNameText";
import makeStyles from "@mui/styles/makeStyles";
import { AssessmentInterface } from "module/assessment/types";
import { getAssessmentStateTranslation } from "module/assessment/helper/getAssessmentStateTranslation";

const useStyles = makeStyles({
  cell: {
    fontWeight: (props: { isHighlighted: boolean }) =>
      props.isHighlighted ? "bold" : "normal",
  },
});

interface Props {
  assessment: AssessmentInterface;
  isSelected: boolean;
  rowClick: () => void;
  isHighlighted: boolean;
  hasPermissionToDownloadAssessment: boolean;
}

const CompetencyManagementListTableRowAssessment: React.FC<Props> = ({
  assessment,
  isSelected,
  rowClick,
  isHighlighted,
  hasPermissionToDownloadAssessment,
}) => {
  const classes = useStyles({ isHighlighted });
  const { formatDateTime } = useDateFormat();
  return (
    <TableRow
      hover
      onClick={rowClick}
      role="checkbox"
      aria-checked={isSelected}
      tabIndex={-1}
      key={assessment.id}
      selected={isSelected}
    >
      {hasPermissionToDownloadAssessment && (
        <CompetencyManagementListTableRowAssessmentSelectTableCellContainer />
      )}
      <TableCell className={classes.cell}>
        {assessment.reportingNumber}
      </TableCell>
      <TableCell className={classes.cell}>{assessment.name}</TableCell>
      <TableCell className={classes.cell}>
        {assessment.assigneeId ? (
          <StaffNameText {...{ staffId: assessment.assigneeId }} />
        ) : (
          "-"
        )}
      </TableCell>
      <TableCell className={classes.cell}>
        {assessment.internalTrainerId && (
          <StaffNameText {...{ staffId: assessment.internalTrainerId }} />
        )}
        {assessment.externalTrainer ? assessment.externalTrainer : ""}
        {assessment.internalTrainerId == null &&
          assessment.externalTrainer == null &&
          "-"}
      </TableCell>
      <TableCell className={classes.cell}>
        {assessment.type
          ? `${getAssessmentTypeTranslation(assessment.type)} ${
              t.competencyManagement.assessmentText
            }`
          : "-"}
      </TableCell>
      <TableCell className={classes.cell}>
        {formatDateTime(assessment.scheduledStartDate)}
      </TableCell>
      <TableCell className={classes.cell}>
        {getAssessmentStateTranslation(assessment.state)}
      </TableCell>
    </TableRow>
  );
};

export default React.memo(CompetencyManagementListTableRowAssessment);
