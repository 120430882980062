import React from "react";
import MenuItem from "@mui/material/MenuItem";
import t from "module/translations";
import useAuth from "module/auth/useAuth";

const UserLogoutMenuItem: React.FC = () => {
  const { redirectToLogout } = useAuth();

  return (
    <MenuItem onClick={redirectToLogout}>{t.auth.logout.menuItemText}</MenuItem>
  );
};

export default React.memo(UserLogoutMenuItem);
