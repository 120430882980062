import React from "react";
import TableBody from "@mui/material/TableBody";
import TableContainer from "component/table/TableContainer";
import Table from "component/table/Table";
import { EntityIdentifier } from "module/entity/types";
import ListToolbarContainer from "module/listToolbar/ListToolbarContainer";
import t from "module/translations";
import MaintenanceTaskListTableHeadContainer from "pages/maintenanceTaskList/table/head/MaintenanceTaskListTableHeadContainer";
import { getEntityIdentifierKey } from "module/entity/helper/getEntityIdentifierKey";
import MaintenanceTaskListTableRowContainer from "pages/maintenanceTaskList/table/row/MaintenanceTaskListTableRowContainer";
import MaintenanceTaskListToolbarFilterScheduledDateContainer from "module/maintenanceTask/enrichedComponent/scheduledDateFilterField/MaintenanceTaskListToolbarFilterScheduledDateContainer";

interface Props {
  entityIdentifiers: EntityIdentifier[];
}

const MaintenanceTaskList: React.FC<Props> = ({ entityIdentifiers }) => {
  return (
    <TableContainer>
      <ListToolbarContainer
        {...{
          title: t.routes.maintenanceTaskDetailsOverview,
          filters: (
            <>
              <MaintenanceTaskListToolbarFilterScheduledDateContainer />
            </>
          ),
          isDownloadAvailable: true,
        }}
      />
      <Table>
        <MaintenanceTaskListTableHeadContainer />
        <TableBody>
          <>
            {entityIdentifiers.map((entityIdentifier) => (
              <MaintenanceTaskListTableRowContainer
                {...{
                  key: getEntityIdentifierKey(entityIdentifier),
                  entityIdentifier,
                }}
              />
            ))}
          </>
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default React.memo(MaintenanceTaskList);
