import React, { useState, useCallback, useMemo } from "react";
import MenuItem from "@mui/material/MenuItem";
import makeStyles from "@mui/styles/makeStyles";
import RadioButtonUncheckedIcon from "@mui/icons-material/RadioButtonUnchecked";
import { useAppSelector } from "redux/hooks";
import { selectSystem, selectUser } from "module/auth/redux/authSlice";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import useSystemChange from "module/auth/useSystemChange";
import FlexGrow from "component/flexGrow/FlexGrow";

interface Props {
  close: () => void;
}

const useStyles = makeStyles(() => ({
  selectSystem: {
    marginLeft: "12px",
  },
}));
const SystemMenuItem: React.FC<Props> = ({ close }) => {
  const classes = useStyles();
  const user = useAppSelector(selectUser);
  const { changeSystem } = useSystemChange();
  const currentSystem = useAppSelector(selectSystem);
  const [isExpanded, setIsExpanded] = useState(false);
  const toggle = () => setIsExpanded(!isExpanded);

  const handleSystemChange = useCallback(
    (systemIdentifier: string) => async () => {
      await changeSystem(systemIdentifier);
      close();
    },
    [changeSystem, close]
  );
  const otherSystems = useMemo(
    () =>
      user.availableSystems.filter(
        (system) => system.shortName !== currentSystem.shortName
      ),
    [currentSystem.shortName, user.availableSystems]
  );
  return (
    <>
      <MenuItem onClick={toggle}>
        {currentSystem.name}
        {otherSystems.length && (
          <>
            <FlexGrow />
            {isExpanded ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
          </>
        )}
      </MenuItem>
      {isExpanded &&
        otherSystems.length &&
        otherSystems.map((system) => (
          <MenuItem
            key={system.shortName}
            onClick={handleSystemChange(system.shortName)}
          >
            <RadioButtonUncheckedIcon fontSize="small" />
            <div className={classes.selectSystem}>{system.name}</div>
          </MenuItem>
        ))}
    </>
  );
};

export default React.memo(SystemMenuItem);
