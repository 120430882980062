import React, { useContext, useCallback } from "react";
import t from "module/translations";
import {
  MaintenanceTaskProcessStepActionPerformed,
  MaintenanceTaskProcessStepCheckResult,
  MaintenanceTaskProcessStepInterface,
} from "module/maintenanceTaskProcessStep/types";
import MaintenanceTaskProcessStepContext from "module/maintenanceTaskProcessStep/context/MaintenanceTaskProcessStepContext";
import MaintenanceTaskProcessStepChangeContext from "module/maintenanceTaskProcessStep/change/MaintenanceTaskProcessStepChangeContext";
import MaintenanceTaskProcessStepFieldMetaDataContext from "module/maintenanceTaskProcessStep/validation/fieldMetaData/MaintenanceTaskProcessStepFieldMetaDataContext";
import { getFieldMetaDataWithErrorHandling } from "module/fieldMetaData/getFieldMetaData";
import { MAINTENANCE_TASK_PROCESS_STEP_CHECK_RESULT_FIELD_NAME } from "module/maintenanceTaskProcessStep/constants";
import XLabelField from "component/labelField/XLabelField";
import { getMaintenanceTaskProcessStepActionPerformedTranslation } from "module/maintenanceTaskProcessStep/helper/getMaintenanceTaskProcessStepActionPerformedTranslation";
import CheckboxField from "component/checkboxField/CheckboxField";

const MaintenanceTaskProcessStepActionPerformedFieldContainer: React.FC =
  () => {
    const { entity } = useContext(MaintenanceTaskProcessStepContext);
    const { change: changeHelper } = useContext(
      MaintenanceTaskProcessStepChangeContext
    );
    const { getFieldMetaData } = useContext(
      MaintenanceTaskProcessStepFieldMetaDataContext
    );

    const processStep = entity as MaintenanceTaskProcessStepInterface;

    const { warnings, errors, isEditable } = getFieldMetaDataWithErrorHandling(
      () =>
        getFieldMetaData(MAINTENANCE_TASK_PROCESS_STEP_CHECK_RESULT_FIELD_NAME)
    );

    const change = useCallback(
      (isChecked: boolean) => {
        const checkResult = isChecked
          ? MaintenanceTaskProcessStepCheckResult.good_yes
          : MaintenanceTaskProcessStepCheckResult.not_set;

        changeHelper({
          checkResult,
        });
      },
      [changeHelper]
    );

    return isEditable ? (
      <CheckboxField
        {...{
          isChecked:
            processStep.checkResult ===
            MaintenanceTaskProcessStepCheckResult.good_yes,
          change,
          label: t.maintenanceTask.processStep.actionPerformed.goodYesText,
          errors,
          warnings,
        }}
      />
    ) : (
      <XLabelField
        {...{
          ...(processStep.checkResult ===
          MaintenanceTaskProcessStepCheckResult.good_yes
            ? {
                label: getMaintenanceTaskProcessStepActionPerformedTranslation(
                  processStep.checkResult as unknown as MaintenanceTaskProcessStepActionPerformed
                ),
              }
            : {}),
          warnings,
          errors,
        }}
      />
    );
  };
export default React.memo(
  MaintenanceTaskProcessStepActionPerformedFieldContainer
);
