import React, { useContext, useCallback, useMemo } from "react";
import ToggleContext from "module/toggle/context/ToggleContext";
import MyEntityCollectionContext from "module/entity/context/entityCollection/MyEntityCollectionContext";
import { EntityIdentifier, MyEntityInterface } from "module/entity/types";
import usePermissions from "module/permission/usePermissions";
import { useAppSelector } from "redux/hooks";
import { selectUser } from "module/auth/redux/authSlice";
import { InventoryCheckInterface } from "module/inventoryCheck/types";
import InventoryCheckContext from "module/inventoryCheck/context/InventoryCheckContext";
import { ApiInventoryCheckInterface } from "api/inventoryCheck/types";
import InventoryCheckListTableRow from "./InventoryCheckListTableRow";

interface Props {
  entityIdentifier: EntityIdentifier;
}

const InventoryCheckListTableRowContainer: React.FC<Props> = ({
  entityIdentifier,
}) => {
  const { myEntityCollection } = useContext(MyEntityCollectionContext);
  const { isSelected: isSelectedHelper, toggleOne } = useContext(ToggleContext);

  const { id: staffId } = useAppSelector(selectUser);

  const { canEditInventoryCheck } = usePermissions();

  const { data, raw } = useMemo(
    () => myEntityCollection.get(entityIdentifier) as MyEntityInterface,
    [entityIdentifier, myEntityCollection]
  );
  const inventoryCheck = data as InventoryCheckInterface;
  const apiInventoryCheck = raw as ApiInventoryCheckInterface;

  const isAssignedToMe = useMemo(
    () => apiInventoryCheck.assigneeId === staffId,
    [apiInventoryCheck.assigneeId, staffId]
  );

  const rowClick = useCallback(() => {
    if (canEditInventoryCheck && !inventoryCheck.isBlockedDetails.isBlocked) {
      toggleOne(entityIdentifier);
    }
  }, [
    canEditInventoryCheck,
    entityIdentifier,
    inventoryCheck.isBlockedDetails.isBlocked,
    toggleOne,
  ]);
  const isSelected = isSelectedHelper(entityIdentifier);

  return (
    <InventoryCheckContext.Provider value={{ entity: inventoryCheck }}>
      <InventoryCheckListTableRow
        {...{
          inventoryCheck,
          isSelected,
          rowClick,
          hasPermissionToDownload: canEditInventoryCheck,
          isHighlighted: isAssignedToMe,
        }}
      />
    </InventoryCheckContext.Provider>
  );
};

export default InventoryCheckListTableRowContainer;
