import { useContext, useCallback } from "react";
import usePermissions from "module/permission/usePermissions";
import { useAppSelector } from "redux/hooks";
import _ from "lodash";
import OnlineDetectorContext from "module/onlineDetector/OnlineDetectorContext";
import selectEntity from "module/staff/redux/selector/selectEntity";
import { EntityIdentifier, EntityType } from "module/entity/types";
import selectOpenItemByEntityType from "module/staff/redux/selector/selectOpenItemByEntityType";
import { IsEntitySyncAvailableResult } from "module/entity/useEntityAbstractFactory";
import selectHasEntityOrRelatedEntityError from "module/staff/redux/selector/selectHasEntityOrRelatedEntityError";
import isTrainingCustomSyncAvailable from "module/trainingCustom/sync/isTrainingCustomSyncAvailable";

interface Result {
  isTrainingCustomSyncAvailable: (id: string) => IsEntitySyncAvailableResult;
}

const useTrainingCustomIsSyncAvailable = (): Result => {
  const { canEditTraining: hasPermissionsToSync } = usePermissions();
  const { isOnline } = useContext(OnlineDetectorContext);
  const openTrainingCustomId = useAppSelector(
    selectOpenItemByEntityType(EntityType.trainingCustom)
  )?.id;
  const selectTrainingCustom = useAppSelector(selectEntity);
  const selectHasError = useAppSelector(selectHasEntityOrRelatedEntityError);

  const isTrainingCustomSyncAvailableHelper = useCallback(
    (id: string): IsEntitySyncAvailableResult => {
      const entityIdentifier: EntityIdentifier = {
        id,
        type: EntityType.trainingCustom,
      };

      const myTrainingCustom = selectTrainingCustom(entityIdentifier);
      const { data, dataWithoutChanges } = myTrainingCustom;

      const isValid = !selectHasError(entityIdentifier);
      const hasChanged = !_.isEqual(data, dataWithoutChanges);

      return isTrainingCustomSyncAvailable({
        hasPermissions: hasPermissionsToSync,
        isOnline,
        isOpen: myTrainingCustom && data.id === openTrainingCustomId,
        isValid,
        hasChanged,
      });
    },
    [
      hasPermissionsToSync,
      isOnline,
      openTrainingCustomId,
      selectHasError,
      selectTrainingCustom,
    ]
  );

  return {
    isTrainingCustomSyncAvailable: isTrainingCustomSyncAvailableHelper,
  };
};

export default useTrainingCustomIsSyncAvailable;
