import React, { useContext } from "react";
import TrainingStateContext from "module/training/state/context/TrainingStateContext";
import MyTrainingGroupDetailsOverview from "pages/myTrainingGroupDetails/overview/MyTrainingGroupDetailsOverview";

const MyTrainingGroupDetailsOverviewContainer: React.FC = () => {
  const { isStateEditable } = useContext(TrainingStateContext);

  return <MyTrainingGroupDetailsOverview {...{ isStateEditable }} />;
};

export default React.memo(MyTrainingGroupDetailsOverviewContainer);
