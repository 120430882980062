import React from "react";
import { EntityFieldMetaDataContextInterface } from "module/entity/context/fieldMetaData/EntityFieldMetaDataContext";

export type TrainingCustomAssigneeFieldMetaDataContextInterface =
  EntityFieldMetaDataContextInterface;

const TrainingCustomAssigneeFieldMetaDataContext = React.createContext(
  <TrainingCustomAssigneeFieldMetaDataContextInterface>{}
);

export default TrainingCustomAssigneeFieldMetaDataContext;
