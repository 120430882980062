import { useCallback } from "react";
import { useAppDispatch, useAppSelector } from "redux/hooks";
import { v4 as uuid } from "uuid";
import {
  selectMaintenanceTaskTemplateById,
  selectSystem,
  selectUser,
} from "module/auth/redux/authSlice";
import usePermissions from "module/permission/usePermissions";
import { addToMyEntities } from "module/staff/redux/staffDataSlice";
import { EntityIdentifier, EntityType } from "module/entity/types";
import { API_DATE_TIME_FORMAT } from "api/date";
import moment from "moment";
import {
  MaintenanceTaskInterface,
  MaintenanceTaskState,
  MaintenanceTaskType,
  MyMaintenanceTaskInterface,
} from "module/maintenanceTask/types";
import useMyEntityValidation from "module/entity/useMyEntityValidation";
import { transformEntityWithRelationshipsToEntityArray } from "module/entity/helper/transformEntityWithRelationshipsToEntityArray";
import {
  MaintenanceTaskPartInterface,
  MyMaintenanceTaskPartInterface,
} from "module/maintenanceTaskPart/types";
import {
  MaintenanceTaskProcessStepInterface,
  MyMaintenanceTaskProcessStepInterface,
} from "module/maintenanceTaskProcessStep/types";
import { mergeEntityMaps } from "module/entity/helper/mergeEntityMaps";

interface Result {
  create: (
    templateId: number,
    type: MaintenanceTaskType,
    originalMaintenanceTaskProcessStep?: MaintenanceTaskProcessStepInterface
  ) => string;
  canCreate: boolean;
}

const useMaintenanceTaskCreateFromTemplate = (): Result => {
  const dispatch = useAppDispatch();
  const staffId = useAppSelector(selectUser).id;
  const getMaintenanceTaskTemplateById = useAppSelector(
    selectMaintenanceTaskTemplateById
  );
  const systemShortName = useAppSelector(selectSystem).shortName;
  const { canScheduleMaintenanceTask } = usePermissions();
  const canCreate = canScheduleMaintenanceTask;
  const { validateMyEntities } = useMyEntityValidation();

  const create = useCallback(
    (
      templateId: number,
      type: MaintenanceTaskType,
      originalMaintenanceTaskProcessStep?: MaintenanceTaskProcessStepInterface
    ): string => {
      const maintenanceTaskTemplate =
        getMaintenanceTaskTemplateById(templateId);

      if (maintenanceTaskTemplate == null) {
        throw Error("Template not found");
      }

      const maintenanceTaskId = uuid();
      const today = moment()
        .set({ hour: 0, minute: 0, second: 0 })
        .format(API_DATE_TIME_FORMAT);

      const parts = new Map<EntityIdentifier, MyMaintenanceTaskPartInterface>();

      // eslint-disable-next-line array-callback-return
      maintenanceTaskTemplate.articleMovements.map((rawPart) => {
        const data: MaintenanceTaskPartInterface = {
          amount: rawPart.amount,
          stockArticleId: rawPart.stockArticleId,
          id: uuid(),
          maintenanceTaskId,
          relationshipIdentifiers: [],
        };
        const entityIdentifier = {
          id: data.id,
          type: EntityType.maintenanceTaskPart,
        };
        parts.set(entityIdentifier, {
          data,
          dataWithoutChanges: data,
          entityIdentifier,
          validationErrors: null,
          validationWarnings: null,
        });
      });

      const processSteps = new Map<
        EntityIdentifier,
        MyMaintenanceTaskProcessStepInterface
      >();

      // eslint-disable-next-line array-callback-return
      maintenanceTaskTemplate.processSteps.map((rawProcessStep) => {
        let componentId = rawProcessStep.componentId ?? undefined;
        let { component } = rawProcessStep;
        if (originalMaintenanceTaskProcessStep?.componentId) {
          componentId = originalMaintenanceTaskProcessStep?.componentId;
          component = originalMaintenanceTaskProcessStep.component;
        }

        const data: MaintenanceTaskProcessStepInterface = {
          id: uuid(),
          articleId: rawProcessStep.articleId ?? undefined,
          type: rawProcessStep.type,
          name: rawProcessStep.name,
          component,
          componentId,
          checkResult: rawProcessStep.checkResult,
          description: rawProcessStep.description,
          measureName: rawProcessStep.measureName,
          measureUnit: rawProcessStep.measureUnit,
          measureValueMin: rawProcessStep.measureValueMin,
          measureValueMax: rawProcessStep.measureValueMax,
          quickFix: null,
          correctiveMaintenanceTask: null,
          maintenanceTaskId,
          relationshipIdentifiers: [],
        };
        const entityIdentifier = {
          id: data.id,
          type: EntityType.maintenanceTaskProcessStep,
        };
        processSteps.set(entityIdentifier, {
          data,
          dataWithoutChanges: data,
          entityIdentifier,
          validationErrors: null,
          validationWarnings: null,
        });
      });

      let { name } = maintenanceTaskTemplate;
      if (originalMaintenanceTaskProcessStep) {
        name = `${originalMaintenanceTaskProcessStep.component.join(
          " / "
        )} [${name}]`;
      }

      const data: MaintenanceTaskInterface = {
        id: maintenanceTaskId,
        type,
        state: MaintenanceTaskState.scheduled,
        source: "template",
        expectedEndDate: today,
        expectedStartDate: today,
        scheduledEndDate: today,
        scheduledStartDate: today,
        isBlockedDetails: {
          isBlocked: false,
          isBlockedBecauseItIsValidated: false,
          blockedByStaffId: null,
          isBlockedBecauseIsOutOfPMWindow: false,
          isBlockedByMeOnThisDevice: false,
          isBlockedByMeOnOtherDevice: false,
          isBlockedByOtherStaff: false,
          isBlockedBecauseStaffIsNotTrained: false,
          isBlockedBecauseStaffIsNotTrainedMessage: null,
        },
        attachments: [],
        personnelAssignments: [],
        openCloseHistory: [],
        name,
        color: maintenanceTaskTemplate.color,
        description: maintenanceTaskTemplate.description,
        parts: Array.from(parts.keys()),
        processSteps: Array.from(processSteps.keys()),
        relationshipIdentifiers: [
          ...Array.from(parts.keys()),
          ...Array.from(processSteps.keys()),
        ],
        relationships: mergeEntityMaps([parts, processSteps]),
        originalMaintenanceTaskProcessStepId:
          originalMaintenanceTaskProcessStep?.id,
      };

      const entityIdentifier = {
        type: EntityType.maintenanceTask,
        id: maintenanceTaskId,
      };

      const myEntity: MyMaintenanceTaskInterface = {
        entityIdentifier,
        data,
        dataWithoutChanges: data,
        validationErrors: null,
        validationWarnings: null,
      };

      const entityWithRelationship =
        transformEntityWithRelationshipsToEntityArray(myEntity);

      dispatch(
        addToMyEntities({
          entities: entityWithRelationship,
          staffId,
          systemShortName,
        })
      );

      validateMyEntities([entityIdentifier]);

      return maintenanceTaskId;
    },
    [
      dispatch,
      getMaintenanceTaskTemplateById,
      staffId,
      systemShortName,
      validateMyEntities,
    ]
  );

  return {
    canCreate,
    create,
  };
};

export default useMaintenanceTaskCreateFromTemplate;
