import { EntityType } from "module/entity/types";
import {
  MaintenanceTaskAssistantType,
  MaintenanceTaskToSyncInterface,
  MyMaintenanceTaskInterface,
} from "module/maintenanceTask/types";
import { MaintenanceTaskProcessStepInterface } from "module/maintenanceTaskProcessStep/types";
import { mapMaintenanceTaskProcessStepToSync } from "module/maintenanceTaskProcessStep/helper/mapMaintenanceTaskProcessStepToSync";
import { MaintenanceTaskProcessStepQuickFixInterface } from "module/maintenanceTaskProcessStepQuickFix/types";
import { MaintenanceTaskPartInterface } from "module/maintenanceTaskPart/types";
import { MyMaintenanceTaskPersonnelAssignmentInterface } from "module/maintenanceTaskPersonnelAssignment/types";
import { mapMaintenanceTaskPartToSync } from "module/maintenanceTaskPart/helper/mapMaintenanceTaskPartToSync";
import { mapMaintenanceTaskPersonnelAssignmentInternalStaffToSync } from "module/maintenanceTaskPersonnelAssignment/internalStaff/helper/mapMaintenanceTaskPersonnelAssignmentInternalStaffToSync";
import { mapMaintenanceTaskPersonnelAssignmentExternalStaffToSync } from "module/maintenanceTaskPersonnelAssignment/externalStaff/helper/mapMaintenanceTaskPersonnelAssignmentExternalStaffToSync";
import { mapAttachmentToSync } from "module/attachment/helper/mapAttachmentToSync";
import { AttachmentInterface } from "module/attachment/types";
import { mapMaintenanceTaskAssistantInternalStaffToSync } from "module/maintenanceTaskAssisstant/helper/mapMaintenanceTaskAssistantInternalStaffToSync";
import { mapMaintenanceTaskAssistantExternalStaffToSync } from "module/maintenanceTaskAssisstant/helper/mapMaintenanceTaskAssistantExternalStaffToSync";

export const mapMaintenanceTaskToSync = (
  myMaintenanceTask: MyMaintenanceTaskInterface,
  maintenanceTaskProcessSteps: MaintenanceTaskProcessStepInterface[],
  maintenanceTaskProcessStepsQuickFixesByMaintenanceTaskProcessStepId: {
    [
      maintenanceTaskProcessStepId: string
    ]: MaintenanceTaskProcessStepQuickFixInterface;
  },
  maintenanceTaskProcessStepsAttachmentsByMaintenanceTaskProcessStepId: {
    [maintenanceTaskProcessStepId: string]: AttachmentInterface[];
  },
  maintenanceTaskParts: MaintenanceTaskPartInterface[],
  myMaintenanceTaskPersonnelAssignments: MyMaintenanceTaskPersonnelAssignmentInterface[],
  attachments: AttachmentInterface[]
): MaintenanceTaskToSyncInterface => {
  const { data: maintenanceTask, raw } = myMaintenanceTask;

  let assistant = null;
  if (maintenanceTask.assistantType === MaintenanceTaskAssistantType.internal) {
    assistant = mapMaintenanceTaskAssistantInternalStaffToSync(maintenanceTask);
  } else if (
    maintenanceTask.assistantType === MaintenanceTaskAssistantType.external
  ) {
    assistant = mapMaintenanceTaskAssistantExternalStaffToSync(maintenanceTask);
  }

  return {
    identifier: {
      type: myMaintenanceTask.entityIdentifier
        .type as EntityType.maintenanceTask,
      id: myMaintenanceTask.entityIdentifier.backendId ?? null,
      frontendId: myMaintenanceTask.entityIdentifier.id,
    },
    data: {
      id: maintenanceTask.backendId,
      name: maintenanceTask.name,
      description: maintenanceTask.description,
      color: maintenanceTask.color,
      remarks: maintenanceTask.remarks,
      type: maintenanceTask.type,
      completionStartDate: maintenanceTask.completionStartDate as string,
      completionEndDate: maintenanceTask.completionEndDate as string,
      observationalIndicators: maintenanceTask.observationalIndicators,
      presumedCauses: maintenanceTask.presumedCauses,
      state: maintenanceTask.state,
      changedDateTime: maintenanceTask.changedDateTime,
      processSteps: maintenanceTaskProcessSteps.map(
        (maintenanceTaskProcessStep) =>
          mapMaintenanceTaskProcessStepToSync(
            maintenanceTaskProcessStep,
            maintenanceTaskProcessStepsAttachmentsByMaintenanceTaskProcessStepId[
              maintenanceTaskProcessStep.id
            ],
            maintenanceTaskProcessStepsQuickFixesByMaintenanceTaskProcessStepId[
              maintenanceTaskProcessStep.id
            ]
          )
      ),
      articleMovements: maintenanceTaskParts.map(mapMaintenanceTaskPartToSync),
      personnelAssignments: myMaintenanceTaskPersonnelAssignments.map(
        (myMaintenanceTaskPersonnelAssignment) => {
          if (
            myMaintenanceTaskPersonnelAssignment.entityIdentifier.type ===
            EntityType.maintenanceTaskPersonnelAssignmentInternalStaff
          ) {
            return mapMaintenanceTaskPersonnelAssignmentInternalStaffToSync(
              myMaintenanceTaskPersonnelAssignment.data
            );
          }
          return mapMaintenanceTaskPersonnelAssignmentExternalStaffToSync(
            myMaintenanceTaskPersonnelAssignment.data
          );
        }
      ),
      attachments: attachments.map(mapAttachmentToSync),
      assistant,
      maintainedAtValue: maintenanceTask.maintainedAtValue,
      expectedStartDate: maintenanceTask.expectedStartDate as string,
      expectedEndDate: maintenanceTask.expectedEndDate as string,
      scheduledStartDate: maintenanceTask.scheduledStartDate as string,
      scheduledEndDate: maintenanceTask.scheduledEndDate as string,
      openCloseHistory: maintenanceTask.openCloseHistory ?? [],
    },
    raw,
  };
};
