import { useCallback, useContext, useMemo } from "react";
import usePermissions from "module/permission/usePermissions";
import isMaintenanceTaskSyncAvailable from "module/maintenanceTask/sync/isMaintenanceTaskSyncAvailable";
import { useAppSelector } from "redux/hooks";
import _ from "lodash";
import OnlineDetectorContext from "module/onlineDetector/OnlineDetectorContext";
import { EntityIdentifier, EntityType } from "module/entity/types";
import selectHasEntityOrRelatedEntityError from "module/staff/redux/selector/selectHasEntityOrRelatedEntityError";
import { IsEntitySyncAvailableResult } from "module/entity/useEntityAbstractFactory";
import selectEntity from "module/staff/redux/selector/selectEntity";
import { MyMaintenanceTaskInterface } from "module/maintenanceTask/types";
import selectOpenItemsByEntityType from "../../staff/redux/selector/selectOpenItemsByEntityType";
import selectEntitiesByTypes from "../../staff/redux/selector/selectEntitiesByTypes";
import { filterMaintenanceTaskCreatedForOtherMaintenanceTaskProcessStep } from "../helper/filterMaintenanceTaskCreatedForOtherMaintenanceTaskProcessStep";

interface Result {
  isMaintenanceTaskSyncAvailable: (id: string) => IsEntitySyncAvailableResult;
}

const useMaintenanceTaskIsSyncAvailable = (): Result => {
  const { canEditMaintenanceTask } = usePermissions();
  const { isOnline } = useContext(OnlineDetectorContext);
  const openMaintenanceTasksEntityIdentifier = useAppSelector(
    selectOpenItemsByEntityType(EntityType.maintenanceTask)
  ) as EntityIdentifier[];
  const openMaintenanceTasksIds = openMaintenanceTasksEntityIdentifier.map(
    (maintenanceTaskEntityIdentifier) => maintenanceTaskEntityIdentifier.id
  );

  const selectMaintenanceTask = useAppSelector(selectEntity);
  const selectHasError = useAppSelector(selectHasEntityOrRelatedEntityError);

  const hasPermissions = canEditMaintenanceTask;

  const myMaintenanceTasks = useAppSelector(
    selectEntitiesByTypes([EntityType.maintenanceTask])
  ) as MyMaintenanceTaskInterface[];
  const maintenanceTasksCreatedForOtherMaintenanceTaskProcessStep = useMemo(
    () =>
      myMaintenanceTasks
        .map(({ data }) => data)
        .filter(filterMaintenanceTaskCreatedForOtherMaintenanceTaskProcessStep),
    [myMaintenanceTasks]
  );

  const isMaintenanceTaskSyncAvailableHelper = useCallback(
    (id: string): IsEntitySyncAvailableResult => {
      const entityIdentifier: EntityIdentifier = {
        id,
        type: EntityType.maintenanceTask,
      };
      const myEntity = selectMaintenanceTask(
        entityIdentifier
      ) as MyMaintenanceTaskInterface;
      if (myEntity == null) {
        return {
          isAvailable: false,
          unavailableReason: "unknown",
        };
      }
      const { data: maintenanceTask, dataWithoutChanges } = myEntity;

      const isValid = !selectHasError(entityIdentifier);
      const hasChanged = !_.isEqual(maintenanceTask, dataWithoutChanges);

      const isAnyMaintenanceTaskCreatedForMaintenanceTaskProcessStepsOpen =
        maintenanceTask.processSteps.reduce((result, { id: processStepId }) => {
          const maintenanceTaskCreatedForMaintenanceTaskProcessStep =
            maintenanceTasksCreatedForOtherMaintenanceTaskProcessStep.find(
              (maintenanceTaskCreatedForOtherMaintenanceTaskProcessStep) =>
                maintenanceTaskCreatedForOtherMaintenanceTaskProcessStep.originalMaintenanceTaskProcessStepId ===
                processStepId
            );
          if (
            maintenanceTaskCreatedForMaintenanceTaskProcessStep &&
            openMaintenanceTasksIds.includes(
              maintenanceTaskCreatedForMaintenanceTaskProcessStep.id
            )
          ) {
            return true;
          }
          return result;
        }, false);

      return isMaintenanceTaskSyncAvailable({
        maintenanceTask,
        isOnline,
        hasPermissions,
        isOpen:
          myEntity && openMaintenanceTasksIds.includes(entityIdentifier.id),
        isValid,
        hasChanged,
        isAnyMaintenanceTaskCreatedForMaintenanceTaskProcessStepsOpen,
      });
    },
    [
      hasPermissions,
      isOnline,
      maintenanceTasksCreatedForOtherMaintenanceTaskProcessStep,
      openMaintenanceTasksIds,
      selectHasError,
      selectMaintenanceTask,
    ]
  );

  return {
    isMaintenanceTaskSyncAvailable: isMaintenanceTaskSyncAvailableHelper,
  };
};

export default useMaintenanceTaskIsSyncAvailable;
