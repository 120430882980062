import React from "react";
import makeStyles from "@mui/styles/makeStyles";
import Grid from "@mui/material/Grid";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import { InventoryCheckArticleInterface } from "module/inventoryCheckArticle/types";
import StockArticleText from "module/stockArticle/enrichedComponent/StockArticleText";
import InventoryCheckArticleHasChangedFieldContainer from "module/inventoryCheckArticle/enrichedComponent/hasChangedtField/InventoryCheckArticleHasChangedFieldContainer";
import InventoryCheckArticleDescriptionFieldContainer from "module/inventoryCheckArticle/enrichedComponent/remarksField/InventoryCheckArticleDescriptionFieldContainer";
import InventoryCheckArticleNewAmountFieldContainer from "module/inventoryCheckArticle/enrichedComponent/newAmountField/InventoryCheckArticleDescriptionFieldContainer";

const useStyles = makeStyles({
  stock: {
    width: "150px",
  },
  article: {
    width: "300px",
  },
  amount: {
    width: "100px",
  },
});

interface Props {
  inventoryCheckArticle: InventoryCheckArticleInterface;
}

const InventoryCheckArticleListRow: React.FC<Props> = ({
  inventoryCheckArticle,
}) => {
  const classes = useStyles();

  return (
    <TableRow hover>
      <TableCell align="left" className={classes.article}>
        <StockArticleText
          {...{ stockArticleId: inventoryCheckArticle.stockArticleId }}
        />
      </TableCell>
      <TableCell align="left" className={classes.amount}>
        {inventoryCheckArticle.oldAmount}
      </TableCell>
      <TableCell align="left">
        <Grid container>
          <Grid item xs={9}>
            <InventoryCheckArticleHasChangedFieldContainer />
          </Grid>
          <Grid item xs={3}>
            <InventoryCheckArticleNewAmountFieldContainer />
          </Grid>
          <Grid item xs={12}>
            <InventoryCheckArticleDescriptionFieldContainer />
          </Grid>
        </Grid>
      </TableCell>
    </TableRow>
  );
};

export default React.memo(InventoryCheckArticleListRow);
