import React from "react";
import t from "module/translations";
import SettingsSuggestIcon from "@mui/icons-material/SettingsSuggest";
import IconButton from "@mui/material/IconButton";
import Button from "./Button";

interface Props {
  onClick: () => void;
  disabled?: boolean;
  withText?: boolean;
}

const GenerateButton: React.FC<Props> = ({
  onClick,
  disabled = false,
  withText = true,
}) => {
  if (withText) {
    return (
      <Button
        {...{
          onClick,
          icon: <SettingsSuggestIcon />,
          text: withText ? t.generateText : undefined,
          disabled,
        }}
      />
    );
  }
  return (
    <IconButton size="small" onClick={onClick}>
      <SettingsSuggestIcon />
    </IconButton>
  );
};

export default React.memo(GenerateButton);
