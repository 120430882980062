import React, { useEffect, useMemo } from "react";
import { useHistory, useParams, useRouteMatch } from "react-router-dom";
import routes from "routes/routes";
import { getPathToTrainingGroupDetailsOverview } from "module/training/helper/getPath";
import { EntityType } from "module/entity/types";
import MyTrainingGroupDetailsContainer from "pages/myTrainingGroupDetails/MyTrainingGroupDetailsContainer";
import MyTrainingGroupDetailsContextProvider from "pages/myTrainingGroupDetails/MyTrainingGroupDetailsContextProvider";
import useIsGroupEditPossible from "module/groupEdit/useIsGroupEditPossible";
import { toastWarning } from "layout/toast/helper";

const MyTrainingGroupDetailsPage: React.FC = () => {
  const { trainingsIds: trainingsIdsString } =
    useParams<{ trainingsIds: string }>();
  const history = useHistory();
  const match = useRouteMatch({
    path: routes.myTrainingGroupDetails.path,
    exact: true,
  });
  const { isGroupEditPossible } = useIsGroupEditPossible();

  const trainingsIds = useMemo(
    () => trainingsIdsString.split(","),
    [trainingsIdsString]
  );
  const entityIdentifiers = useMemo(
    () =>
      trainingsIds.map((trainingId) => ({
        id: trainingId,
        type: EntityType.training,
      })),
    [trainingsIds]
  );

  useEffect(() => {
    const isGroupEditPossibleHelper = isGroupEditPossible(entityIdentifiers);
    if (!isGroupEditPossibleHelper.areEntitiesSimilar) {
      toastWarning(isGroupEditPossibleHelper.entitiesAreNotSimilarReasonText);
      history.push(routes.myCompetencyManagementResources.path);
    }
  }, [entityIdentifiers, history, isGroupEditPossible, trainingsIds]);

  useEffect(() => {
    if (trainingsIds && match) {
      history.push(getPathToTrainingGroupDetailsOverview(trainingsIds));
    }
  }, [history, match, trainingsIds]);

  return (
    <MyTrainingGroupDetailsContextProvider {...{ entityIdentifiers }}>
      <MyTrainingGroupDetailsContainer />
    </MyTrainingGroupDetailsContextProvider>
  );
};

export default React.memo(MyTrainingGroupDetailsPage);
