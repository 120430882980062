import React, { useContext, useMemo } from "react";
import MaintenanceTaskContext from "module/maintenanceTask/context/MaintenanceTaskContext";
import { MaintenanceTaskInterface } from "module/maintenanceTask/types";
import { isObservationalIndicatorsAvailable } from "module/maintenanceTask/helper/isObservationalIndicatorsAvailable";
import { isPresumedCausesAvailable } from "module/maintenanceTask/helper/isPresumedCausesAvailable";
import { isMaintenanceTaskMaintainedAtValueAvailable } from "module/maintenanceTask/helper/isMaintenanceTaskMaintainedAtValueAvailable";
import { isSerializedArticleAvailable as isSerializedArticleAvailableHelper } from "module/maintenanceTask/helper/isSerializedArticleAvailable";
import MaintenanceTaskToBeSignedDetailsOverview from "pages/maintenanceTaskToBeSignedDetails/overview/MaintenanceTaskToBeSignedDetailsOverview";

const MaintenanceTaskToBeSignedDetailsOverviewContainer: React.FC = () => {
  const { entity } = useContext(MaintenanceTaskContext);

  const maintenanceTask = entity as MaintenanceTaskInterface;

  const areObservationalIndicatorsAvailable = useMemo(
    () => isObservationalIndicatorsAvailable(maintenanceTask),
    [maintenanceTask]
  );
  const arePresumedCausesAvailable = useMemo(
    () => isPresumedCausesAvailable(maintenanceTask),
    [maintenanceTask]
  );
  const isMaintainedAtValueAvailable = useMemo(
    () => isMaintenanceTaskMaintainedAtValueAvailable(maintenanceTask),
    [maintenanceTask]
  );
  const isSerializedArticleAvailable = useMemo(
    () => isSerializedArticleAvailableHelper(maintenanceTask),
    [maintenanceTask]
  );

  return (
    <MaintenanceTaskToBeSignedDetailsOverview
      {...{
        areObservationalIndicatorsAvailable,
        arePresumedCausesAvailable,
        isMaintainedAtValueAvailable,
        isSerializedArticleAvailable,
      }}
    />
  );
};

export default React.memo(MaintenanceTaskToBeSignedDetailsOverviewContainer);
