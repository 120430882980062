import React, { useContext, useCallback, useMemo } from "react";
import CompetencyManagementListTableRowAssessment from "pages/competencyManagementList/table/row/assessment/CompetencyManagementListTableRowAssessment";
import ToggleContext from "module/toggle/context/ToggleContext";
import { useAppSelector } from "redux/hooks";
import { selectUser } from "module/auth/redux/authSlice";
import { EntityType } from "module/entity/types";
import usePermissions from "module/permission/usePermissions";
import AssessmentContext from "module/assessment/context/AssessmentContext";
import { AssessmentInterface } from "module/assessment/types";

const CompetencyManagementListTableRowAssessmentContainer: React.FC = () => {
  const { entity } = useContext(AssessmentContext);
  const { isSelected: isSelectedHelper, toggleOne } = useContext(ToggleContext);

  const { id: staffId } = useAppSelector(selectUser);

  const { canEditAssessment } = usePermissions();

  const assessment = entity as AssessmentInterface;

  const isAssignedToMe = assessment.internalTrainerId === staffId;
  const toggleItem = useMemo(
    () => ({
      id: assessment.id,
      type: EntityType.assessment,
    }),
    [assessment.id]
  );
  const rowClick = useCallback(() => {
    if (canEditAssessment && !assessment.isBlockedDetails.isBlocked) {
      toggleOne(toggleItem);
    }
  }, [
    assessment.isBlockedDetails.isBlocked,
    canEditAssessment,
    toggleItem,
    toggleOne,
  ]);
  const isSelected = useMemo(
    () => isSelectedHelper(toggleItem),
    [isSelectedHelper, toggleItem]
  );

  return (
    <CompetencyManagementListTableRowAssessment
      {...{
        assessment,
        isSelected,
        rowClick,
        isHighlighted: isAssignedToMe,
        hasPermissionToDownloadAssessment: canEditAssessment,
      }}
    />
  );
};

export default React.memo(CompetencyManagementListTableRowAssessmentContainer);
