import { useContext, useCallback } from "react";
import usePermissions from "module/permission/usePermissions";
import { useAppSelector } from "redux/hooks";
import _ from "lodash";
import OnlineDetectorContext from "module/onlineDetector/OnlineDetectorContext";
import isTrainingSyncAvailable from "module/training/sync/isTrainingSyncAvailable";
import selectEntity from "module/staff/redux/selector/selectEntity";
import { EntityIdentifier, EntityType } from "module/entity/types";
import selectOpenItemByEntityType from "module/staff/redux/selector/selectOpenItemByEntityType";
import { IsEntitySyncAvailableResult } from "module/entity/useEntityAbstractFactory";
import selectHasEntityOrRelatedEntityError from "module/staff/redux/selector/selectHasEntityOrRelatedEntityError";

interface Result {
  isTrainingSyncAvailable: (id: string) => IsEntitySyncAvailableResult;
}

const useTrainingIsSyncAvailable = (): Result => {
  const { canEditTraining: hasPermissionsToSync } = usePermissions();
  const { isOnline } = useContext(OnlineDetectorContext);
  const openTrainingId = useAppSelector(
    selectOpenItemByEntityType(EntityType.training)
  )?.id;
  const selectTraining = useAppSelector(selectEntity);
  const selectHasError = useAppSelector(selectHasEntityOrRelatedEntityError);

  const isTrainingSyncAvailableHelper = useCallback(
    (id: string): IsEntitySyncAvailableResult => {
      const entityIdentifier: EntityIdentifier = {
        id,
        type: EntityType.training,
      };

      const myTraining = selectTraining(entityIdentifier);
      const { data, dataWithoutChanges } = myTraining;

      const isValid = !selectHasError(entityIdentifier);
      const hasChanged = !_.isEqual(data, dataWithoutChanges);

      return isTrainingSyncAvailable({
        hasPermissions: hasPermissionsToSync,
        isOnline,
        isOpen: myTraining && data.id === openTrainingId,
        isValid,
        hasChanged,
      });
    },
    [
      hasPermissionsToSync,
      isOnline,
      openTrainingId,
      selectHasError,
      selectTraining,
    ]
  );

  return {
    isTrainingSyncAvailable: isTrainingSyncAvailableHelper,
  };
};

export default useTrainingIsSyncAvailable;
