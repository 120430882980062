import React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import { EntityIdentifier } from "module/entity/types";
import { getEntityIdentifierKey } from "module/entity/helper/getEntityIdentifierKey";
import { useDownloadedMaintenanceTaskDetailsPersonnelAssignmentsInternalStyles } from "pages/myMaintenanceTaskDetails/personnelAssignment/internalList/styles";
import MaintenanceTaskPersonnelAssignmentInternalStaffContextProvider from "module/maintenanceTaskPersonnelAssignment/internalStaff/context/MaintenanceTaskPersonnelAssignmentInternalStaffContextProvider";
import MaintenanceTaskDetailsPersonnelAssignmentInternalStaffListTableRow from "module/maintenanceTaskPersonnelAssignment/internalStaff/enrichedComponent/table/MaintenanceTaskDetailsPersonnelAssignmentInternalStaffListTableRow";
import MaintenanceTaskProcessStepListTableHead from "module/maintenanceTaskPersonnelAssignment/internalStaff/enrichedComponent/table/MaintenanceTaskDetailsPersonnelAssignmentInternalStaffListTableHead";

interface Props {
  entityIdentifiers: EntityIdentifier[];
}

const MaintenanceTaskToBeSignedDetailsPersonnelAssignmentsInternalStaff: React.FC<Props> =
  ({ entityIdentifiers }) => {
    const classes =
      useDownloadedMaintenanceTaskDetailsPersonnelAssignmentsInternalStyles();
    const isActionAvailable = false;
    return (
      <Table className={classes.table}>
        <MaintenanceTaskProcessStepListTableHead {...{ isActionAvailable }} />
        <TableBody>
          {entityIdentifiers.map((entityIdentifier) => (
            <MaintenanceTaskPersonnelAssignmentInternalStaffContextProvider
              {...{
                key: getEntityIdentifierKey(entityIdentifier),
                entityIdentifier,
              }}
            >
              <MaintenanceTaskDetailsPersonnelAssignmentInternalStaffListTableRow
                {...{ isActionAvailable }}
              />
            </MaintenanceTaskPersonnelAssignmentInternalStaffContextProvider>
          ))}
        </TableBody>
      </Table>
    );
  };

export default React.memo(
  MaintenanceTaskToBeSignedDetailsPersonnelAssignmentsInternalStaff
);
