import React, { useMemo } from "react";
import { useRouteMatch } from "react-router-dom";
import NavigationBar from "component/navigationBar/NavigationBar";
import NavigationBarItemBack from "component/navigationBar/NavigationBarItemBack";
import NavigationBarItem from "component/navigationBar/NavigationBarItem";
import NavigationBarTabs from "component/navigationBar/NavigationBarTabs";
import t from "module/translations";

interface TrainingDetailsNavigationProps {
  listPath: string;
  overviewPath: string;
  sessionsPath: string;
}

const TrainingDetailsNavigation: React.FC<TrainingDetailsNavigationProps> = ({
  listPath,
  overviewPath,
  sessionsPath,
}) => {
  const matchedOverview = Boolean(
    useRouteMatch({
      path: overviewPath,
      exact: true,
    })
  );
  const matchedSessions = Boolean(
    useRouteMatch({
      path: sessionsPath,
      exact: true,
    })
  );

  const matchedList = false;

  const tabMap: boolean[] = useMemo(
    () => [matchedList, matchedOverview, matchedSessions],
    [matchedList, matchedOverview, matchedSessions]
  );

  return (
    <NavigationBar>
      <NavigationBarTabs {...{ tabMap }}>
        <NavigationBarItemBack
          {...{
            path: listPath,
            key: listPath,
          }}
        />
        <NavigationBarItem
          {...{
            key: overviewPath,
            path: overviewPath,
            title: t.competencyManagement.training.overviewTitleText,
          }}
        />
        <NavigationBarItem
          {...{
            key: sessionsPath,
            path: sessionsPath,
            title: t.competencyManagement.training.sessionsTitleText,
          }}
        />
      </NavigationBarTabs>
    </NavigationBar>
  );
};

export default React.memo(TrainingDetailsNavigation);
