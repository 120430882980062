import React, { useMemo } from "react";
import FormContainsErrors from "component/form/FormContainsErrors";
import routes from "routes/routes";
import { Route } from "react-router-dom";
import ButtonsContainer from "component/button/ButtonsContainer";
import OpenButton from "component/button/OpenButton";
import CloseButton from "component/button/CloseButton";
import {
  getPathToInventoryCheckDetailsArticles,
  getPathToInventoryCheckDetailsOverview,
} from "module/inventoryCheck/helper/getPath";
import InventoryCheckStateEditableFieldContainer from "module/inventoryCheck/enrichedComponent/stateField/InventoryCheckStateEditableFieldContainer";
import MyInventoryCheckDetailsNavigation, {
  MyInventoryCheckDetailsNavigationProps,
} from "./navigation/MyInventoryCheckDetailsNavigation";

interface Props {
  inventoryCheckId: string;
  hasError: boolean;
  open?: () => void;
  close?: () => void;
  isStateEditable: boolean;
}

const MyInventoryCheckDetails: React.FC<Props> = ({
  inventoryCheckId,
  hasError,
  open,
  close,
  isStateEditable,
}) => {
  const navigationProps: MyInventoryCheckDetailsNavigationProps =
    useMemo(() => {
      const result: MyInventoryCheckDetailsNavigationProps = {
        listPath: routes.myInventoryChecks.path,
        overviewPath: getPathToInventoryCheckDetailsOverview(inventoryCheckId),
        articlesPath: getPathToInventoryCheckDetailsArticles(inventoryCheckId),
      };

      return result;
    }, [inventoryCheckId]);

  return (
    <>
      {hasError && <FormContainsErrors />}
      <MyInventoryCheckDetailsNavigation {...navigationProps} />
      <Route
        {...{
          key: routes.myInventoryCheckDetailsOverview.path,
          path: routes.myInventoryCheckDetailsOverview.path,
          component: routes.myInventoryCheckDetailsOverview.mainComponent,
          exact: routes.myInventoryCheckDetailsOverview.exact,
        }}
      />
      <Route
        {...{
          key: routes.myInventoryCheckDetailsArticles.path,
          path: routes.myInventoryCheckDetailsArticles.path,
          component: routes.myInventoryCheckDetailsArticles.mainComponent,
          exact: routes.myInventoryCheckDetailsArticles.exact,
        }}
      />
      {(open || close) && (
        <ButtonsContainer>
          {open && <OpenButton onClick={open} />}
          {close && <CloseButton onClick={close} />}
          {isStateEditable && <InventoryCheckStateEditableFieldContainer />}
        </ButtonsContainer>
      )}
    </>
  );
};

export default React.memo(MyInventoryCheckDetails);
