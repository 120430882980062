import { useCallback } from "react";
import { toastError } from "layout/toast/helper";
import * as Sentry from "@sentry/react";
import t from "module/translations";
import _ from "lodash";
import { useAppDispatch, useAppSelector } from "redux/hooks";
import { selectSystem, selectUser } from "module/auth/redux/authSlice";
import { addToMyEntities } from "module/staff/redux/staffDataSlice";
import block from "api/block/block";
import {
  EntityIdentifier,
  EntityType,
  MyEntityInterface,
} from "module/entity/types";
import { mapBlockInterfaceToKey } from "module/block/helper";
import { transformEntityWithRelationshipsToEntityArray } from "module/entity/helper/transformEntityWithRelationshipsToEntityArray";
import useMyEntityValidation from "module/entity/useMyEntityValidation";
import { convertFileIntoBase64 } from "toolkit/convertFileIntoBase64";
import { MyAttachmentInterface } from "module/attachment/types";

interface DownloadResult {
  invalidMessages: string[];
  downloadedCount: number;
}

interface Result {
  download: (
    entitiesToDownload: Map<EntityIdentifier, MyEntityInterface>
  ) => Promise<DownloadResult>;
}

const useEntityDownload = (): Result => {
  const staffId = useAppSelector(selectUser).id;
  const systemShortName = useAppSelector(selectSystem).shortName;
  const dispatch = useAppDispatch();
  const { validateMyEntities } = useMyEntityValidation();

  const download = useCallback(
    async (
      entitiesToDownload: Map<EntityIdentifier, MyEntityInterface>
    ): Promise<DownloadResult> => {
      const entitiesKeysToDownload = Array.from(entitiesToDownload.keys());
      if (systemShortName === "OAC") {
        Sentry.withScope((scope) => {
          scope.setLevel("info");

          scope.setExtra("entitiesToDownload", entitiesKeysToDownload);
          Sentry.captureMessage("User is going to download entities");
        });
      }

      let invalidKeys: EntityIdentifier[] = [];
      let invalidMessages: string[] = [];
      let downloadedCount = 0;

      try {
        const { validationError, validationGeneralError } = await block(
          entitiesKeysToDownload
        );
        if (validationError) {
          invalidKeys = mapBlockInterfaceToKey(validationError);
          invalidMessages = validationGeneralError;
        }

        const validEntitiesToDownload: Map<
          EntityIdentifier,
          MyEntityInterface
        > = _.differenceWith(
          entitiesKeysToDownload,
          invalidKeys,
          _.isEqual
        ).reduce((result, key) => {
          result.set(key, entitiesToDownload.get(key));
          return result;
        }, new Map());

        downloadedCount = validEntitiesToDownload.size;

        if (downloadedCount) {
          const entities: MyEntityInterface[] = Array.from(
            validEntitiesToDownload.keys()
          ).reduce((result, entityIdentifier) => {
            const myEntity = validEntitiesToDownload.get(
              entityIdentifier
            ) as MyEntityInterface;

            const entityWithRelationship =
              transformEntityWithRelationshipsToEntityArray(myEntity);

            return [...result, ...entityWithRelationship];
          }, [] as MyEntityInterface[]);

          // download files and save as base64
          const postProcessedEntities = await Promise.all(
            entities.map(async (myEntity) => {
              if (myEntity.entityIdentifier.type === EntityType.attachment) {
                const { data: attachment } = myEntity as MyAttachmentInterface;
                const base64 = await convertFileIntoBase64(
                  attachment.path as string,
                  attachment.mimeType
                );
                return {
                  ...myEntity,
                  data: {
                    ...attachment,
                    base64,
                  },
                } as MyAttachmentInterface;
              }
              return myEntity;
            })
          );

          dispatch(
            addToMyEntities({
              entities: postProcessedEntities,
              staffId,
              systemShortName,
            })
          );

          validateMyEntities(Array.from(validEntitiesToDownload.keys()));
          if (systemShortName === "OAC") {
            Sentry.withScope((scope) => {
              scope.setLevel("info");

              scope.setExtra("entitiesToDownload", entitiesKeysToDownload);
              Sentry.captureMessage("User successfully downloaded entities");
            });
          }
        }
      } catch (error) {
        toastError(t.api.generalError);
        if (systemShortName === "OAC") {
          Sentry.withScope((scope) => {
            scope.setLevel("info");

            scope.setExtra("entitiesToDownload", entitiesKeysToDownload);
            Sentry.captureMessage("User entities download failed");
          });
        }
      }

      return {
        invalidMessages,
        downloadedCount,
      };
    },
    [dispatch, staffId, systemShortName, validateMyEntities]
  );
  return { download };
};

export default useEntityDownload;
