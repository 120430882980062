import React from "react";
import Grid from "@mui/material/Grid";
import makeStyles from "@mui/styles/makeStyles";

const useStyles = makeStyles((theme) => ({
  wrapper: {
    minHeight: "100vh",
    background: theme.palette.primary.main,
  },
}));

interface Props {
  children: React.ReactNode;
}

const FullScreenContainer: React.FC<Props> = ({ children }) => {
  const classes = useStyles();
  return (
    <Grid
      container
      direction="column"
      justifyContent="center"
      alignItems="center"
      className={classes.wrapper}
    >
      {children}
    </Grid>
  );
};

export default React.memo(FullScreenContainer);
