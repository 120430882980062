import { PayloadAction } from "@reduxjs/toolkit";
import { StaffDataState } from "module/staff/redux/types";
import { EntityIdentifier } from "module/entity/types";
import { getEntityIdentifierKey } from "module/entity/helper/getEntityIdentifierKey";

interface Props {
  staffId: number;
  systemShortName: string;
  baseEntityIdentifier: EntityIdentifier;
  propertyName: string;
  entityIdentifierToAdd: EntityIdentifier;
}

const addToEntityPropertyListAction = (
  state: StaffDataState,
  action: PayloadAction<Props>
): void => {
  const {
    staffId,
    systemShortName,
    baseEntityIdentifier,
    propertyName,
    entityIdentifierToAdd,
  } = action.payload;

  const { data } =
    state[staffId][systemShortName].entities[
      getEntityIdentifierKey(baseEntityIdentifier)
    ];

  const currentDataForProperty =
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    data[propertyName] === undefined ? [] : data[propertyName];

  const newData = {
    ...data,
    [propertyName]: [...currentDataForProperty, entityIdentifierToAdd],
    relationshipIdentifiers: [
      ...data.relationshipIdentifiers,
      entityIdentifierToAdd,
    ],
  };

  state[staffId][systemShortName].entities[
    getEntityIdentifierKey(baseEntityIdentifier)
  ].data = newData;
};

export default addToEntityPropertyListAction;
