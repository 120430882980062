import React, { useContext, useMemo } from "react";
import ToggleContext from "module/toggle/context/ToggleContext";
import { EntityIdentifier, EntityType } from "module/entity/types";
import TrainingCustomContext from "module/trainingCustom/context/TrainingCustomContext";
import { TrainingCustomInterface } from "module/trainingCustom/types";
import MyCompetencyManagementListTableRowTrainingCustom from "pages/myCompetencyManagementList/table/row/trainingCustom/MyCompetencyManagementListTableRowTrainingCustom";

const MyCompetencyManagementListTableRowTrainingCustomContainer: React.FC =
  () => {
    const { entity } = useContext(TrainingCustomContext);
    const { isSelected: isSelectedHelper } = useContext(ToggleContext);

    const trainingCustom = entity as TrainingCustomInterface;

    const entityIdentifier: EntityIdentifier = useMemo(
      () => ({
        id: trainingCustom.id,
        type: EntityType.trainingCustom,
      }),
      [trainingCustom.id]
    );

    const isSelected = useMemo(
      () => isSelectedHelper(entityIdentifier),
      [entityIdentifier, isSelectedHelper]
    );

    return (
      <MyCompetencyManagementListTableRowTrainingCustom
        {...{ trainingCustom, isSelected, entityIdentifier }}
      />
    );
  };

export default React.memo(
  MyCompetencyManagementListTableRowTrainingCustomContainer
);
