import React from "react";
import t from "module/translations";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import StickyPaper from "component/sticky/StickyPaper";
import MaintenanceTaskReportingNumberFieldContainer from "module/maintenanceTask/enrichedComponent/reportingNumberField/MaintenanceTaskReportingNumberFieldContainer";
import MaintenanceTaskDescriptionFieldContainer from "module/maintenanceTask/enrichedComponent/descriptionField/MaintenanceTaskDescriptionFieldContainer";
import MaintenanceTaskRemarksFieldContainer from "module/maintenanceTask/enrichedComponent/remarksField/MaintenanceTaskRemarksFieldContainer";
import MaintenanceTaskObservationalIndicatorsFieldContainer from "module/maintenanceTask/enrichedComponent/observationalIndicatorsField/MaintenanceTaskObservationalIndicatorsFieldContainer";
import MaintenanceTaskPresumedCausesFieldContainer from "module/maintenanceTask/enrichedComponent/presumedCauses/MaintenanceTaskPresumedCausesFieldContainer";
import MaintenanceTaskExpectedStartDateFieldContainer from "module/maintenanceTask/enrichedComponent/expectedStartDateField/MaintenanceTaskExpectedStartDateFieldContainer";
import MaintenanceTaskExpectedEndDateFieldContainer from "module/maintenanceTask/enrichedComponent/expectedEndDateField/MaintenanceTaskExpectedEndDateFieldContainer";
import MaintenanceTaskScheduledStartDateFieldContainer from "module/maintenanceTask/enrichedComponent/scheduledStartDateField/MaintenanceTaskScheduledStartDateFieldContainer";
import MaintenanceTaskScheduledEndDateFieldContainer from "module/maintenanceTask/enrichedComponent/scheduledEndDateField/MaintenanceTaskScheduledEndDateFieldContainer";
import MaintenanceTaskCompletionStartDateFieldContainer from "module/maintenanceTask/enrichedComponent/completionStartDateField/MaintenanceTaskCompletionStartDateFieldContainer";
import MaintenanceTaskCompletionEndDateFieldContainer from "module/maintenanceTask/enrichedComponent/completionEndDateField/MaintenanceTaskCompletionEndDateFieldContainer";
import MaintenanceTaskStateLabelFieldContainer from "module/maintenanceTask/enrichedComponent/stateField/MaintenanceTaskStateLabelFieldContainer";
import MaintenanceTaskNameLabelFieldContainer from "module/maintenanceTask/enrichedComponent/nameField/MaintenanceTaskNameLabelFieldContainer";
import MaintenanceTaskAttachmentListContainer from "module/maintenanceTaskAttachment/enrichedComponent/list/MaintenanceTaskAttachmentListContainer";
import MyMaintenanceTaskDetailsOverviewMaintainedAtValueContainer from "pages/myMaintenanceTaskDetails/overview/maintainedAtValue/MyMaintenanceTaskDetailsOverviewMaintainedAtValueContainer";
import MaintenanceTaskSerializedArticleFieldContainer from "module/maintenanceTask/enrichedComponent/serializedArticleField/MaintenanceTaskSerializedArticleFieldContainer";
import MaintenanceTaskSerializedArticleLocationFieldContainer from "module/maintenanceTask/enrichedComponent/serializedArticleLocationField/MaintenanceTaskSerializedArticleLocationFieldContainer";

interface Props {
  areObservationalIndicatorsAvailable: boolean;
  arePresumedCausesAvailable: boolean;
  isMaintainedAtValueAvailable: boolean;
  isSerializedArticleAvailable: boolean;
}

const MaintenanceTaskToBeSignedDetailsOverview: React.FC<Props> = ({
  areObservationalIndicatorsAvailable,
  arePresumedCausesAvailable,
  isMaintainedAtValueAvailable,
  isSerializedArticleAvailable,
}) => {
  return (
    <StickyPaper>
      <Grid container spacing={3}>
        <Grid item xs={6}>
          <MaintenanceTaskReportingNumberFieldContainer />
        </Grid>
        <Grid item xs={6}>
          <MaintenanceTaskNameLabelFieldContainer />
        </Grid>
      </Grid>
      {isSerializedArticleAvailable && (
        <Grid container spacing={3}>
          <Grid item xs={6}>
            <MaintenanceTaskSerializedArticleFieldContainer />
          </Grid>
          <Grid item xs={6}>
            <MaintenanceTaskSerializedArticleLocationFieldContainer />
          </Grid>
        </Grid>
      )}

      <MaintenanceTaskDescriptionFieldContainer />
      <MaintenanceTaskRemarksFieldContainer />
      {areObservationalIndicatorsAvailable && (
        <MaintenanceTaskObservationalIndicatorsFieldContainer />
      )}
      {arePresumedCausesAvailable && (
        <MaintenanceTaskPresumedCausesFieldContainer />
      )}
      <Typography variant="h6" gutterBottom>
        {t.maintenanceTask.scheduledDateHeader}
      </Typography>
      <Grid container spacing={3}>
        <Grid item xs={6}>
          <MaintenanceTaskExpectedStartDateFieldContainer />
        </Grid>
        <Grid item xs={6}>
          <MaintenanceTaskExpectedEndDateFieldContainer />
        </Grid>
        <Grid item xs={6}>
          <MaintenanceTaskScheduledStartDateFieldContainer />
        </Grid>
        <Grid item xs={6}>
          <MaintenanceTaskScheduledEndDateFieldContainer />
        </Grid>
      </Grid>
      <Typography variant="h6" gutterBottom>
        {t.maintenanceTask.completionText}
      </Typography>
      <Grid container spacing={3}>
        <Grid item xs={6}>
          <MaintenanceTaskCompletionStartDateFieldContainer />
        </Grid>
        <Grid item xs={6}>
          <MaintenanceTaskCompletionEndDateFieldContainer />
        </Grid>
      </Grid>
      {isMaintainedAtValueAvailable && (
        <MyMaintenanceTaskDetailsOverviewMaintainedAtValueContainer />
      )}
      <MaintenanceTaskStateLabelFieldContainer />
      <MaintenanceTaskAttachmentListContainer />
    </StickyPaper>
  );
};

export default React.memo(MaintenanceTaskToBeSignedDetailsOverview);
