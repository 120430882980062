import React, { useContext, useMemo } from "react";
import MaintenanceTaskContext from "module/maintenanceTask/context/MaintenanceTaskContext";
import { MaintenanceTaskInterface } from "module/maintenanceTask/types";
import XLabelField from "component/labelField/XLabelField";
import t from "module/translations";

const MaintenanceTaskSerializedArticleLocationFieldContainer: React.FC = () => {
  const { entity } = useContext(MaintenanceTaskContext);
  const { serializedArticleLocations } = entity as MaintenanceTaskInterface;

  const location = useMemo(
    () =>
      serializedArticleLocations == null
        ? undefined
        : serializedArticleLocations.join(" / "),
    [serializedArticleLocations]
  );

  return (
    <XLabelField
      {...{
        caption: t.maintenanceTask.serializedArticleLocationText,
        label: location,
      }}
    />
  );
};

export default React.memo(
  MaintenanceTaskSerializedArticleLocationFieldContainer
);
