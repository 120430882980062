import { useCallback } from "react";
import { useAppSelector } from "redux/hooks";
import { API_DATE_TIME_FORMAT } from "api/date";
import moment from "moment";
import useEntityOpenClose from "module/entity/useEntityOpenClose";
import { EntityIdentifier, EntityType } from "module/entity/types";
import selectOpenItemByEntityType from "module/staff/redux/selector/selectOpenItemByEntityType";
import useEntityChange from "module/entity/useEntityChange";
import { selectUser } from "module/auth/redux/authSlice";
import {
  TrainingCustomInterface,
  TrainingCustomState,
} from "module/trainingCustom/types";

interface Result {
  open?: () => void;
  close?: () => void;
}

const useMyTrainingCustomOpenClose = (
  trainingCustom: TrainingCustomInterface
): Result => {
  const entityIdentifier: EntityIdentifier = {
    id: trainingCustom.id,
    type: EntityType.trainingCustom,
  };
  const staffId = useAppSelector(selectUser).id;
  const { change } = useEntityChange(entityIdentifier);
  const {
    canOpen: canOpenEntity,
    open: openEntity,
    canClose: canCloseEntity,
    close: closeEntity,
  } = useEntityOpenClose(entityIdentifier);

  const isAnyAssessmentOpen =
    useAppSelector(selectOpenItemByEntityType(EntityType.assessment)) != null;
  const isAnyTrainingOpen =
    useAppSelector(selectOpenItemByEntityType(EntityType.training)) != null;
  const isAnyTrainingCustomOpen =
    useAppSelector(selectOpenItemByEntityType(EntityType.trainingCustom)) !=
    null;

  const handleOpen = useCallback(() => {
    const changes: Partial<TrainingCustomInterface> = {
      completionDate: undefined,
      internalTrainerId: staffId,
      externalTrainer: undefined,
    };

    openEntity();
    change(changes);
  }, [change, openEntity, staffId]);
  const canOpen =
    canOpenEntity &&
    !isAnyAssessmentOpen &&
    !isAnyTrainingOpen &&
    !isAnyTrainingCustomOpen;
  const open = canOpen ? handleOpen : undefined;

  const handleClose = useCallback(() => {
    closeEntity();
    if (trainingCustom.state === TrainingCustomState.completed) {
      change({
        completionDate: moment().format(API_DATE_TIME_FORMAT),
      });
    }
  }, [change, closeEntity, trainingCustom.state]);
  const close = canCloseEntity ? handleClose : undefined;

  return {
    open,
    close,
  };
};

export default useMyTrainingCustomOpenClose;
