import React from "react";
import TableHead from "@mui/material/TableHead";
import TableCell from "@mui/material/TableCell";
import t from "module/translations";
import TableHeadRow from "component/table/tableHeadRow/TableHeadRow";

const ToBeSignedListTableHead: React.FC = () => {
  return (
    <TableHead>
      <TableHeadRow>
        <TableCell align="left">{t.toBeSigned.entityTypeText}</TableCell>
        <TableCell align="left">{t.toBeSigned.nameText}</TableCell>
        <TableCell align="left">{t.toBeSigned.typeText}</TableCell>
        <TableCell>{t.toBeSigned.scheduledDateText}</TableCell>
      </TableHeadRow>
    </TableHead>
  );
};

export default React.memo(ToBeSignedListTableHead);
