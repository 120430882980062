import React, {
  useCallback,
  useContext,
  useEffect,
  useState,
  useMemo,
} from "react";
import FullScreenLoaderContext from "module/fullScreenLoader/context/FullScreenLoaderContext";
import SortContext from "module/sort/context/SortContext";
import FilterContext from "module/filter/context/FilterContext";
import { EntityIdentifier, MyEntityInterface } from "module/entity/types";
import { MyMaintenanceTaskInterface } from "module/maintenanceTask/types";
import MyEntityCollectionContextProvider from "module/entity/context/entityCollection/MyEntityCollectionContextProvider";
import EntityDownloadContextProvider from "module/entity/context/download/EntityDownloadContextProvider";
import PullToRefresh from "component/pullToRefresh/PullToRefresh";
import ToggleContextProvider from "module/toggle/context/ToggleContextProvider";
import MaintenanceTaskList from "pages/maintenanceTaskList/MaintenanceTaskList";
import getMaintenanceTasks from "api/maintenanceTask/getMaintenanceTasks";
import { MaintenanceTaskFilters } from "pages/maintenanceTaskList/types";
import { useAppSelector } from "redux/hooks";
import { selectUser } from "module/auth/redux/authSlice";
import { sortMaintenanceTasks } from "pages/maintenanceTaskList/sortMaintenanceTasks";
import { isApiMaintenanceTaskAssignedToStaff } from "pages/maintenanceTaskList/isApiMaintenanceTaskAssignedToStaff";
import { ApiMaintenanceTaskInterface } from "api/maintenanceTask/types";

const MaintenanceTaskListContainer: React.FC = () => {
  const { turnOn: turnLoaderOn, turnOff: turnLoaderOff } = useContext(
    FullScreenLoaderContext
  );
  const { sort } = useContext(SortContext);
  const { filters } = useContext(FilterContext);

  const { id: myStaffId } = useAppSelector(selectUser);

  const [myEntityCollection, setMyEntityCollection] = useState<
    Map<EntityIdentifier, MyEntityInterface>
  >(new Map());

  const load = useCallback(async () => {
    turnLoaderOn();
    const maintenanceTasksFromApi = await getMaintenanceTasks(
      filters as unknown as MaintenanceTaskFilters,
      sort
    );

    const maintenanceTasksIdsAssignedToMe: string[] = maintenanceTasksFromApi
      .filter((maintenanceTaskFromApi) =>
        isApiMaintenanceTaskAssignedToStaff(
          maintenanceTaskFromApi.raw as ApiMaintenanceTaskInterface,
          myStaffId
        )
      )
      .map((maintenanceTaskFromApi) => maintenanceTaskFromApi.data.id);

    const sortedMaintenanceTasksFromApi = sortMaintenanceTasks(
      maintenanceTasksFromApi,
      maintenanceTasksIdsAssignedToMe
    );
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    setMyEntityCollection(sortedMaintenanceTasksFromApi);
    turnLoaderOff();
  }, [filters, myStaffId, sort, turnLoaderOff, turnLoaderOn]);

  useEffect(() => {
    load();
  }, [load]);

  const itemsAllowedToToggle: EntityIdentifier[] = useMemo(
    () =>
      Array.from(myEntityCollection.keys()).reduce(
        (result, entityIdentifier) => {
          const myEntity = myEntityCollection.get(
            entityIdentifier
          ) as MyMaintenanceTaskInterface;
          if (!myEntity.data.isBlockedDetails?.isBlocked) {
            return [...result, entityIdentifier];
          }

          return result;
        },
        [] as EntityIdentifier[]
      ),
    [myEntityCollection]
  );

  const entityIdentifiers = useMemo(
    () => Array.from(myEntityCollection.keys()),
    [myEntityCollection]
  );

  return (
    <MyEntityCollectionContextProvider {...{ myEntityCollection }}>
      <EntityDownloadContextProvider {...{ load }}>
        <PullToRefresh refresh={load}>
          <ToggleContextProvider {...{ itemsAllowedToToggle }}>
            <MaintenanceTaskList
              {...{
                entityIdentifiers,
              }}
            />
          </ToggleContextProvider>
        </PullToRefresh>
      </EntityDownloadContextProvider>
    </MyEntityCollectionContextProvider>
  );
};

export default React.memo(MaintenanceTaskListContainer);
