import {
  MaintenanceTaskProcessStepInterface,
  MaintenanceTaskProcessStepToSyncInterface,
} from "module/maintenanceTaskProcessStep/types";
import { MaintenanceTaskProcessStepQuickFixInterface } from "module/maintenanceTaskProcessStepQuickFix/types";
import { mapMaintenanceTaskProcessStepQuickFixToSync } from "module/maintenanceTaskProcessStepQuickFix/helper/mapMaintenanceTaskProcessStepQuickFixToSync";
import { AttachmentInterface } from "module/attachment/types";
import { mapAttachmentToSync } from "module/attachment/helper/mapAttachmentToSync";

export const mapMaintenanceTaskProcessStepToSync = (
  maintenanceTaskProcessStep: MaintenanceTaskProcessStepInterface,
  attachments: AttachmentInterface[],
  maintenanceTaskProcessStepQuickFix?: MaintenanceTaskProcessStepQuickFixInterface
): MaintenanceTaskProcessStepToSyncInterface => {
  return {
    id: maintenanceTaskProcessStep.backendId as number,
    checkResult: maintenanceTaskProcessStep.checkResult,
    remarks: maintenanceTaskProcessStep.remarks ?? null,
    measureValue: maintenanceTaskProcessStep.measureValue ?? null,
    changedDateTime: maintenanceTaskProcessStep.changedDateTime,
    quickFix:
      maintenanceTaskProcessStepQuickFix == null
        ? null
        : mapMaintenanceTaskProcessStepQuickFixToSync(
            maintenanceTaskProcessStepQuickFix
          ),
    attachments: attachments.map(mapAttachmentToSync),
    articleId: maintenanceTaskProcessStep.articleId,
    name: maintenanceTaskProcessStep.name,
    description: maintenanceTaskProcessStep.description,
    type: maintenanceTaskProcessStep.type,
    measureName: maintenanceTaskProcessStep.measureName,
    measureUnit: maintenanceTaskProcessStep.measureUnit,
    measureValueMin: maintenanceTaskProcessStep.measureValueMin,
    measureValueMax: maintenanceTaskProcessStep.measureValueMax,
    componentId: maintenanceTaskProcessStep.componentId,
    correctiveMaintenanceTask: null, // is being done on a different layer
  };
};
