import React, { useContext } from "react";
import useDateFormat from "toolkit/useDateFormat";
import TrainingCustomContext from "module/trainingCustom/context/TrainingCustomContext";
import { TrainingCustomInterface } from "module/trainingCustom/types";
import t from "module/translations";
import XLabelField from "component/labelField/XLabelField";

const TrainingCustomCompletionDateFieldContainer: React.FC = () => {
  const { entity } = useContext(TrainingCustomContext);
  const { formatDate } = useDateFormat();

  const { completionDate } = entity as TrainingCustomInterface;

  return (
    <XLabelField
      {...{
        caption: t.competencyManagement.trainingCustom.completionDateText,
        label: completionDate ? formatDate(completionDate) : "",
      }}
    />
  );
};

export default React.memo(TrainingCustomCompletionDateFieldContainer);
