import React, { useContext, useMemo, useCallback } from "react";
import CompetencyManagementListTableRowTraining from "pages/competencyManagementList/table/row/training/CompetencyManagementListTableRowTraining";
import ToggleContext from "module/toggle/context/ToggleContext";
import { useAppSelector } from "redux/hooks";
import { selectUser } from "module/auth/redux/authSlice";
import { EntityType } from "module/entity/types";
import usePermissions from "module/permission/usePermissions";
import TrainingContext from "module/training/context/TrainingContext";
import { TrainingInterface } from "module/training/types";

const CompetencyManagementListTableRowTrainingContainer: React.FC = () => {
  const { entity } = useContext(TrainingContext);
  const { isSelected: isSelectedHelper, toggleOne } = useContext(ToggleContext);

  const { id: staffId } = useAppSelector(selectUser);

  const { canEditTraining } = usePermissions();

  const training = entity as TrainingInterface;
  const isAssignedToMe = training.internalTrainerId === staffId;
  const toggleItem = useMemo(
    () => ({
      id: training.id,
      type: EntityType.training,
    }),
    [training.id]
  );
  const rowClick = useCallback(() => {
    if (canEditTraining && !training.isBlockedDetails.isBlocked) {
      toggleOne(toggleItem);
    }
  }, [
    canEditTraining,
    toggleItem,
    toggleOne,
    training.isBlockedDetails.isBlocked,
  ]);
  const isSelected = useMemo(
    () => isSelectedHelper(toggleItem),
    [isSelectedHelper, toggleItem]
  );

  return (
    <CompetencyManagementListTableRowTraining
      {...{
        training,
        isSelected,
        rowClick,
        isHighlighted: isAssignedToMe,
        hasPermissionToDownloadTraining: canEditTraining,
      }}
    />
  );
};

export default React.memo(CompetencyManagementListTableRowTrainingContainer);
