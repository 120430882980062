import React, { useContext, useMemo } from "react";
import useMyMaintenanceTaskState from "module/maintenanceTask/state/useMyMaintenanceTaskState";
import { MaintenanceTaskInterface } from "module/maintenanceTask/types";
import MaintenanceTaskContext from "module/maintenanceTask/context/MaintenanceTaskContext";
import MaintenanceTaskStateContext, {
  MaintenanceTaskStateContextInterface,
} from "module/maintenanceTask/state/context/MaintenanceTaskStateContext";

interface Prop {
  children: React.ReactNode;
}

const MyMaintenanceTaskStateContextProvider: React.FC<Prop> = ({
  children,
}) => {
  const { entity } = useContext(MaintenanceTaskContext);

  const {
    isStateScheduledOrLower,
    isEditable: isStateEditable,
    options: stateOptions,
  } = useMyMaintenanceTaskState(entity as MaintenanceTaskInterface);

  const contextValue: MaintenanceTaskStateContextInterface = useMemo(
    () => ({
      isStateScheduledOrLower,
      isStateEditable,
      stateOptions,
    }),
    [isStateEditable, isStateScheduledOrLower, stateOptions]
  );

  return (
    <MaintenanceTaskStateContext.Provider value={contextValue}>
      {children}
    </MaintenanceTaskStateContext.Provider>
  );
};

export default MyMaintenanceTaskStateContextProvider;
