import React, { useContext } from "react";
import Offline from "module/onlineDetector/Offline";
import OnlineDetectorContext from "module/onlineDetector/OnlineDetectorContext";

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export default function withOnlineDetector<T>(
  WrappedComponent: React.ComponentType<T>
) {
  const displayName =
    WrappedComponent.displayName || WrappedComponent.name || "Component";

  const ComponentWithOnlineDetector = (props: T) => {
    const { isOnline } = useContext(OnlineDetectorContext);
    return !isOnline ? <Offline /> : <WrappedComponent {...(props as T)} />;
  };

  ComponentWithOnlineDetector.displayName = `withOnlineDetector(${displayName})`;

  return ComponentWithOnlineDetector;
}
