import { TrainingInterface, TrainingState } from "module/training/types";

export const canSignTraining = ({
  training,
  loggedInStaffId,
}: {
  training: TrainingInterface;
  loggedInStaffId: number;
}): boolean =>
  training.state === TrainingState.completed &&
  (training.assigneeId === loggedInStaffId ||
    training.internalTrainerId === loggedInStaffId);
