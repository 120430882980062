import React from "react";
import t from "module/translations";
import Button from "@mui/material/Button";
import PlayCircleFilledWhiteIcon from "@mui/icons-material/PlayCircleFilledWhite";

interface Props {
  onClick: () => void;
}

const OpenButton: React.FC<Props> = ({ onClick }) => {
  return (
    <Button
      variant="contained"
      color="primary"
      size="large"
      onClick={onClick}
      startIcon={<PlayCircleFilledWhiteIcon />}
    >
      {t.startText}
    </Button>
  );
};

export default React.memo(OpenButton);
