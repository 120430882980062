import React from "react";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";
import TrainingSessionAchievedFieldContainer from "module/trainingSession/enrichedComponent/achievedField/TrainingSessionAchievedFieldContainer";
import TrainingSessionRemarksFieldContainer from "module/trainingSession/enrichedComponent/remarksField/TrainingSessionRemarksFieldContainer";
import NestedElement from "component/nestedElement/NestedElement";

interface Props {
  name: string;
  level: number;
}

const TrainingSessionTableRowItem: React.FC<Props> = ({ name, level }) => {
  return (
    <TableRow hover>
      <TableCell align="left">
        <Typography variant="body2" gutterBottom>
          <NestedElement {...{ level }}>{name}</NestedElement>
        </Typography>
      </TableCell>
      <TableCell align="left">
        <TrainingSessionAchievedFieldContainer />
      </TableCell>
      <TableCell>
        <TrainingSessionRemarksFieldContainer />
      </TableCell>
    </TableRow>
  );
};

export default React.memo(TrainingSessionTableRowItem);
