import { createNestedPropertyIfNotExist } from "toolkit/createNestedPropertyIfNotExist";
import { XValidatorErrorsInterface } from "module/validation/types";
import { XRequiredValidator } from "module/validation/validator/requiredValidator";
import {
  InventoryCheckArticleHasChanged,
  InventoryCheckArticleInterface,
} from "module/inventoryCheckArticle/types";
import {
  InventoryCheckInterface,
  InventoryCheckState,
} from "module/inventoryCheck/types";
import {
  INVENTORY_CHECK_ARTICLE_HAS_CHANGED_FIELD_NAME,
  INVENTORY_CHECK_ARTICLE_NEW_AMOUNT_FIELD_NAME,
} from "module/inventoryCheckArticle/constants";
import { ValueMustBeDifferentThanValidator } from "module/validation/validator/valueMustBeDifferentThanValidator";

export interface InventoryCheckArticleValidationResult {
  errors: null | XValidatorErrorsInterface;
  warnings: null | XValidatorErrorsInterface;
}

export const validateInventoryCheckArticle = (
  inventoryCheck: InventoryCheckInterface,
  inventoryCheckArticle: InventoryCheckArticleInterface
): InventoryCheckArticleValidationResult => {
  let errors: XValidatorErrorsInterface = {};
  const warnings: XValidatorErrorsInterface = {};

  const isStateCompletedOrHigher =
    inventoryCheck.state !== InventoryCheckState.scheduled;

  if (
    isStateCompletedOrHigher &&
    inventoryCheckArticle.hasChanged === InventoryCheckArticleHasChanged.notSet
  ) {
    errors = createNestedPropertyIfNotExist(
      errors,
      INVENTORY_CHECK_ARTICLE_HAS_CHANGED_FIELD_NAME,
      []
    );
    errors[INVENTORY_CHECK_ARTICLE_HAS_CHANGED_FIELD_NAME].push(
      new XRequiredValidator().message
    );
  }

  if (
    isStateCompletedOrHigher &&
    inventoryCheckArticle.hasChanged === InventoryCheckArticleHasChanged.yes &&
    inventoryCheckArticle.newAmount == null
  ) {
    errors = createNestedPropertyIfNotExist(
      errors,
      INVENTORY_CHECK_ARTICLE_NEW_AMOUNT_FIELD_NAME,
      []
    );
    errors[INVENTORY_CHECK_ARTICLE_NEW_AMOUNT_FIELD_NAME].push(
      new XRequiredValidator().message
    );
  }

  if (
    isStateCompletedOrHigher &&
    inventoryCheckArticle.hasChanged === InventoryCheckArticleHasChanged.yes &&
    inventoryCheckArticle.newAmount === inventoryCheckArticle.oldAmount
  ) {
    errors = createNestedPropertyIfNotExist(
      errors,
      INVENTORY_CHECK_ARTICLE_NEW_AMOUNT_FIELD_NAME,
      []
    );
    errors[INVENTORY_CHECK_ARTICLE_NEW_AMOUNT_FIELD_NAME].push(
      new ValueMustBeDifferentThanValidator(
        `${inventoryCheckArticle.oldAmount}`
      ).message
    );
  }
  return {
    errors: Object.keys(errors).length ? errors : null,
    warnings: Object.keys(warnings).length ? warnings : null,
  };
};
