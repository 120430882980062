import React, { useMemo } from "react";
import MyMaintenanceTaskListContainer from "pages/myMaintenanceTaskList/MyMaintenanceTaskListContainer";
import SortContextProvider from "module/sort/context/SortContextProvider";
import { SortDirection } from "module/sort/types";
import {
  MaintenanceTaskDefinitionType,
  MaintenanceTaskSortProperty,
} from "pages/maintenanceTaskList/types";
import { useAppSelector } from "redux/hooks";
import selectEntitiesByTypes from "module/staff/redux/selector/selectEntitiesByTypes";
import { EntityType } from "module/entity/types";
import { filterOutMaintenanceTaskCreatedForOtherMaintenanceTaskProcessStep } from "module/maintenanceTask/helper/filterOutMaintenanceTaskCreatedForOtherMaintenanceTaskProcessStep";
import { MyMaintenanceTaskInterface } from "../../module/maintenanceTask/types";

const MyMaintenanceTaskListPage: React.FC = () => {
  const myMaintenanceTasks = useAppSelector(
    selectEntitiesByTypes([EntityType.maintenanceTask])
  ) as MyMaintenanceTaskInterface[];

  const maintenanceTasks = useMemo(
    () =>
      myMaintenanceTasks
        .filter(
          (myMaintenanceTask) =>
            myMaintenanceTask.data.definitionType == null ||
            myMaintenanceTask.data.definitionType ===
              MaintenanceTaskDefinitionType.system
        )
        .map(({ data }) => data)
        .filter(
          filterOutMaintenanceTaskCreatedForOtherMaintenanceTaskProcessStep
        ),
    [myMaintenanceTasks]
  );

  return (
    <SortContextProvider
      {...{
        initialValue: {
          fieldName: MaintenanceTaskSortProperty.scheduled,
          direction: SortDirection.asc,
        },
      }}
    >
      <MyMaintenanceTaskListContainer
        {...{
          maintenanceTasks,
        }}
      />
    </SortContextProvider>
  );
};

export default React.memo(MyMaintenanceTaskListPage);
