import React, { useContext } from "react";
import MaintenanceTaskContext from "module/maintenanceTask/context/MaintenanceTaskContext";
import { MaintenanceTaskInterface } from "module/maintenanceTask/types";
import MaintenanceTaskToBeSignedDetailsAssistant from "pages/maintenanceTaskToBeSignedDetails/personnelAssignment/assistant/MaintenanceTaskToBeSignedDetailsAssistant";

const MaintenanceTaskToBeSignedDetailsAssistantContainer: React.FC = () => {
  const { entity } = useContext(MaintenanceTaskContext);
  const maintenanceTask = entity as MaintenanceTaskInterface;

  const isSet = maintenanceTask.assistantType != null;

  return (
    <MaintenanceTaskToBeSignedDetailsAssistant
      {...{
        isSet,
        assistantType: maintenanceTask.assistantType,
      }}
    />
  );
};

export default React.memo(MaintenanceTaskToBeSignedDetailsAssistantContainer);
