import React, { useContext, useCallback, useMemo } from "react";
import MaintenanceTaskContext from "module/maintenanceTask/context/MaintenanceTaskContext";
import { MaintenanceTaskInterface } from "module/maintenanceTask/types";
import { EntityType } from "module/entity/types";
import useMaintenanceTaskPersonnelAssignmentInternalStaffAdd from "module/maintenanceTaskPersonnelAssignment/internalStaff/add/useMaintenanceTaskPersonnelAssignmentInternalStaffAdd";
import MyMaintenanceTaskDetailsPersonnelAssignmentsInternalStaff from "pages/myMaintenanceTaskDetails/personnelAssignment/internalList/MyMaintenanceTaskDetailsPersonnelAssignmentsInternalStaff";
import useMaintenanceTaskPersonnelAssignmentInternalStaffCanAdd from "module/maintenanceTaskPersonnelAssignment/internalStaff/add/useMaintenanceTaskPersonnelAssignmentInternalStaffCanAdd";

const MyMaintenanceTaskDetailsPersonnelAssignmentsInternalStaffContainer: React.FC =
  () => {
    const { entity } = useContext(MaintenanceTaskContext);
    const maintenanceTask = entity as MaintenanceTaskInterface;

    const { add: addHelper } =
      useMaintenanceTaskPersonnelAssignmentInternalStaffAdd();
    const add = useCallback(() => addHelper({}), [addHelper]);
    const { canAdd } =
      useMaintenanceTaskPersonnelAssignmentInternalStaffCanAdd();

    const entityIdentifiers = useMemo(
      () =>
        maintenanceTask.personnelAssignments.filter(
          (personnelAssignmentEntityIdentifier) =>
            personnelAssignmentEntityIdentifier.type ===
            EntityType.maintenanceTaskPersonnelAssignmentInternalStaff
        ),
      [maintenanceTask.personnelAssignments]
    );

    return (
      <MyMaintenanceTaskDetailsPersonnelAssignmentsInternalStaff
        {...{
          entityIdentifiers,
          canAdd,
          add,
        }}
      />
    );
  };

export default React.memo(
  MyMaintenanceTaskDetailsPersonnelAssignmentsInternalStaffContainer
);
